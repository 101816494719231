import React, { Component } from "react";
import "../index.css";
import DataTable from "../../../services/dataTable";
import VendorAssistanceColumnDefs from "../colDefs/VendorAssistanceColumnDefs.js";
import RangeSlider from "../../../services/RangeSlider";
import Tooltip from "@tippy.js/react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toMillionDollar } from "../../../helpers/formatters";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";

import buyOptimizationAction from "../../../actions/BuyOptimizationActions";

class VendorAssistance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gmGoal: null,
      isGBUpdateNegative: false,
      rangeValue: [],
      giveBackPercentage: props.vendorAssistanceGiveBack,
      totalBudget: null,
      rangeStep: null,
      minMax: []
    };
  }
  componentDidMount() {
    if (this.props.channelSummary) {
      this.setState(
        {
          totalBudget:
            this.props.channelSummary &&
            this.props.channelSummary.length &&
            this.props.channelSummary[8].Total
        },
        () => {
          this.setState({
            minMax: [
              this.state.totalBudget * 0.75,
              this.state.totalBudget * 1.6
            ]
          });
          this.setState({
            rangeValue: [
              this.state.totalBudget * 0.85,
              this.state.totalBudget * 1.2
            ]
          });
          this.setState({
            rangeStep: this.state.totalBudget / 100
          });
        }
      );
    }
  }
  // componentDidUpdate(oldProps) {
  //   if (oldProps !== this.props && this.props.channelSummary) {
  //     this.setState(
  //       {
  //         totalBudget:
  //           this.props.channelSummary &&
  //           this.props.channelSummary.length &&
  //           this.props.channelSummary[8].Total
  //       },
  //       () => {
  //         this.setState({
  //           minMax: [
  //             this.state.totalBudget * 0.75,
  //             this.state.totalBudget * 1.6
  //           ]
  //         });
  //         this.setState({
  //           rangeValue: [
  //             this.state.totalBudget * 0.85,
  //             this.state.totalBudget * 1.2
  //           ]
  //         });
  //         this.setState({
  //           rangeStep: this.state.totalBudget / 100
  //         });
  //       }
  //     );
  //   }
  // }
  onChangeGmGoal = e => {
    this.setState({
      gmGoal: e.target.value
    });
  };
  onChangeGiveBackPercentage = e => {
    this.props.setGiveBackPercentage(Number(e.target.value));
    this.setState({
      giveBackPercentage: e.target.value
    });
  };
  onVendorSimulate = () => {
    let payload = {
      gmGoal: Number(parseInt(this.state.gmGoal)),
      projectId: this.props.match.params.id,
      constrained: this.props.constrained ? 1 : 0,
      startBudgetLimit: parseInt(this.state.rangeValue[0]),
      endBudgetLimit: parseInt(this.state.rangeValue[1])
    };
    this.props.vendorSimulation(payload);
  };
  handleClose = () => {
    this.setState({
      isGBUpdateNegative: false
    });
  };
  onVendorUpdate = () => {
    if (this.state.giveBackPercentage < 0) {
      this.setState({
        isGBUpdateNegative: true
      });
    } else {
      var gb = this.state.giveBackPercentage;
      let payload = {
        projectId: this.props.match.params.id,
        giveBackPerc: parseFloat(gb) ? parseFloat(gb) : 0,
        constrained: this.props.constrained ? 1 : 0
      };
      this.props.vendorUpdate(payload).then(resp => {
        if (resp.success) {
          this.gridApi.redrawRows();
        }
      });
    }
  };
  changeRowColor = params => {
    if (params.node.rowIndex === this.props.nearestIndex) {
      return { background: " #F7CFA1 !important" };
    }
  };
  onRowSelected = params => {
    this.setState(
      {
        giveBackPercentage: params.data["Give Back %"]
      },
      () => {
        this.onVendorUpdate();
      }
    );
  };
  valueLabelFormat = x => {
    return toMillionDollar(x);
  };

  onSilderRangeChange = (a, b) => {
    this.setState({ rangeValue: b });
  };
  render() {
    return (
      <div style={{ marginTop: "1rem" }}>
        <div className="row">
          <div className="col-md-2">
            <label style={{ fontSize: "14px" }}>GM GOAL(%) *</label>
            <input
              controls="false"
              disabled={this.props.userDisableAccess}
              type="number"
              onChange={e => this.onChangeGmGoal(e)}
              className="form-control"
              value={this.state.gmGoal}
            />
          </div>
          <div className="col-md-3">
            <label style={{ fontSize: "14px", marginBottom: "16px" }}>
              Budget range for cost receipts
            </label>
            <RangeSlider
              value={this.state.rangeValue}
              min={this.state.minMax[0]}
              max={this.state.minMax[1]}
              disabled={this.props.userDisableAccess}
              step={this.state.rangeStep}
              onChange={this.onSilderRangeChange}
              valueLabelFormat={this.valueLabelFormat}
              getAriaValuesText={this.valueLabelFormat}
            />
            <div style={{ position: "relative" }}>
              <span
                style={{
                  position: "absolute",
                  left: "-22px",
                  bottom: "18px",
                  fontSize: "12px",
                  color: "#2D69EB"
                }}
              >
                {toMillionDollar(this.state.minMax[0])}
              </span>
              <span
                style={{
                  position: "absolute",
                  left: "calc(29.411764706% - 20px)",
                  bottom: "18px",
                  fontSize: "12px",
                  color: "#2D69EB"
                }}
              >
                {toMillionDollar(this.state.totalBudget)}
              </span>
              <span
                style={{
                  position: "absolute",
                  right: "-22px",
                  bottom: "18px",
                  fontSize: "12px",
                  color: "#2D69EB"
                }}
              >
                {toMillionDollar(this.state.minMax[1])}
              </span>
            </div>
          </div>
          <div className="col-md-2 offset-md-6">
            <Tooltip
              placement={"top"}
              content="Simulate for the selected budget and range"
            >
              <button
                type="button"
                disabled={this.props.userDisableAccess || !this.state.gmGoal}
                onClick={this.onVendorSimulate}
                className="btn btn-primary btn-square shadow-sm btnsimulate"
              >
                Simulate
              </button>
            </Tooltip>
          </div>
        </div>
        <Dialog
          open={this.state.isGBUpdateNegative}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Give Back cannot be a negative value
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                background: "#2D69EB",
                cursor: "pointer",
                color: "#2D69EB"
              }}
              onClick={this.handleClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              style={{
                background: "#2D69EB",
                cursor: "pointer",
                color: "#2D69EB"
              }}
              onClick={this.handleClose}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <div
          className="mt-3"
          style={{ height: "calc(100vh - 550px)", minHeight: "300px" }}
        >
          <DataTable
            sizeToFit={true}
            defaultExpand={true}
            height1="calc(100vh - 300px)"
            passGridAPI={api => {
              this.gridApi = api;
            }}
            columnDefs={VendorAssistanceColumnDefs.columnDefs}
            defaultColDef={VendorAssistanceColumnDefs.defaultColDef}
            rowData={this.props.vendorAssistanceSimulationData}
            getRowStyle={this.changeRowColor}
            onRowClicked={this.onRowSelected}
            rowSelection="single"
          />
        </div>
        <div className="row justify-content-end" style={{ marginTop: "12px" }}>
          <div className="col-md-2 offset-md-7">
            <label style={{ fontSize: "14px" }}>GIVE BACK(% of buy) *</label>
            <input
              disabled={
                this.props.userDisableAccess ||
                !this.props.vendorAssistanceSimulationData
              }
              type="number"
              value={this.state.giveBackPercentage}
              onChange={e => this.onChangeGiveBackPercentage(e)}
              className="form-control"
            />
          </div>
          <div className="col-md-2">
            <button
              type="button"
              disabled={!this.state.giveBackPercentage}
              className="btn btn-primary btn-square shadow-sm btngbupdate"
              onClick={this.onVendorUpdate}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    vendorAssistanceSimulationData:
      store.buyOptimizationReducer.vendorAssistanceSimulationData,
    nearestIndex: store.buyOptimizationReducer.nearestIndex,
    vendorAssistanceGiveBack:
      store.buyOptimizationReducer.vendorAssistanceGiveBack
  };
};
const mapActionsToProps = {
  vendorSimulation: buyOptimizationAction.vendorSimulation,
  vendorUpdate: buyOptimizationAction.vendorUpdate
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(VendorAssistance));

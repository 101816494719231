import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

const Schema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  changePassword: Yup.string().when("password", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    )
  })
});
export default function App(props) {
  return (
    <Formik
      initialValues={{
        password: "",
        changePassword: ""
      }}
      validationSchema={Schema}
      onSubmit={values => {
        let paylaod = {
          email: localStorage.getItem("userEmail"),
          password: values.changePassword
        };
        props.resetPassword(paylaod).then(resp => {
          if (resp.success) {
            props.onClose();
          }
        });
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        dirty,
        isSubmitting,
        touched,
        handleReset,
        handleChange,
        handleBlur
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email" style={{ display: "block" }}>
                New Password
              </label>
              <input
                id="password"
                className="form-control"
                // style={{ width: "215px" }}
                placeholder="Password"
                type="password"
                value={values.password}
                onChange={handleChange}
                validationSchema={Schema}
                onBlur={handleBlur}
                // className={
                //   errors.password && touched.password
                //     ? "text-input error"
                //     : "text-input"
                // }
              />
              {errors.password && touched.password && (
                <div className="input-feedback">{errors.password}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="email" style={{ display: "block" }}>
                Confirm Password
              </label>
              <input
                style={{ width: "215px" }}
                id="changePassword"
                placeholder="Confirm Password"
                type="password"
                className="form-control"
                value={values.changePassword}
                onChange={handleChange}
                // disabled={!values.password}
                onBlur={handleBlur}
                // className={
                //   errors.changePassword && touched.changePassword
                //     ? "text-input error"
                //     : "text-input"
                // }
              />
              {errors.changePassword && touched.changePassword && (
                <div className="input-feedback">{errors.changePassword}</div>
              )}
            </div>

            <button
              className="btn btn-primary btn-square shadow-sm btnsignin"
              type="submit"
              disabled={!values.changePassword && isSubmitting}
            >
              Reset Password
            </button>

            {/* <DisplayFormikState {...props} /> */}
          </form>
        );
      }}
    </Formik>
  );
}

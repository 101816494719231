import { sumBy } from "lodash";

export function calculateItemLevelAggregated(itemLevelSummary) {
  let itemLevelAggregateData = [
    {
      metric: "ALGO OPT",
      totalBuyUnits: null,
      totalBuyCost: null
    },
    {
      metric: "BUYER PROP",
      totalBuyUnits: null,
      totalBuyCost: null
    },
    {
      metric: "FINAL BUY",
      totalBuyUnits: null,
      totalBuyCost: null
    },
    {
      metric: "%Dev From Planned Budget",
      totalBuyUnits: null,
      totalBuyCost: null
    }
  ];
  if (itemLevelSummary) {
    itemLevelAggregateData[0].totalBuyUnits = sumBy(itemLevelSummary, o => {
      return o["Algorithm Optimized with VA Total Buy Units"];
    });
    itemLevelAggregateData[1].totalBuyUnits = sumBy(itemLevelSummary, o => {
      return o["Buyer Proposed Total Buy Units"];
    });
    let itemLevelTotalBuyUnit = sumBy(itemLevelSummary, o => {
      if (o.Action === "Algorithm Optimized") {
        return o["Algorithm Optimized with VA Total Buy Units"];
      } else {
        return o["Buyer Proposed Total Buy Units"];
      }
    });
    itemLevelAggregateData[2].totalBuyUnits = itemLevelTotalBuyUnit;
    // console.log(itemLevelTotalBuyUnit, "total buy unit");
    // total buy cost  sum
    itemLevelAggregateData[0].totalBuyCost = sumBy(itemLevelSummary, o => {
      return o["Algorithm Optimized with VA Total Buy Cost"];
    });
    itemLevelAggregateData[1].totalBuyCost = sumBy(itemLevelSummary, o => {
      return o["Buyer Proposed Total Buy Cost"];
    });
    let itemLevelBuyCost = sumBy(itemLevelSummary, o => {
      if (o.Action === "Algorithm Optimized") {
        return o["Algorithm Optimized with VA Total Buy Cost"];
      } else {
        return o["Buyer Proposed Total Buy Cost"];
      }
    });
    itemLevelAggregateData[2].totalBuyCost = itemLevelBuyCost;
    itemLevelAggregateData[3].totalBuyUnits =
      ((itemLevelAggregateData[1].totalBuyUnits -
        itemLevelAggregateData[2].totalBuyUnits) /
        itemLevelAggregateData[1].totalBuyUnits) *
      100;
    itemLevelAggregateData[3].totalBuyCost =
      ((itemLevelAggregateData[1].totalBuyCost -
        itemLevelAggregateData[2].totalBuyCost) /
        itemLevelAggregateData[1].totalBuyCost) *
      100;
  }
  return itemLevelAggregateData;
}

export function excelRankAverage(array, val) {
  const sortedArray = array.sort((a, b) => a - b);
  const index = sortedArray.findIndex(element => element === val);
  let repeatCount = 0;
  for (let i = index + 1; i < array.length; i++) {
    if (array[i] === val) {
      repeatCount = repeatCount + 1;
    }
  }
  return index + 1 + repeatCount / 2;
}

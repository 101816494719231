const clusteringReducerDefaultState = {
  elbowCurve: [],
  optimalClusters: null,
  optimalAttributeClusters: null,
  userSelectedClusters: null,
  clusterMapping: {},
  clusterResultsStoreLevel: [],
  attributeSelectionList: [],
  attributesGradeChart: null,
  attributeStoreMapping: {},
  notEnoughFillRate: false,
  unassignedClusterResultsStoreLevel: [],
  clusteringInputFields: {
    name: null,
    sellingPeriod: {
      sdate: null,
      edate: null
    },
    receiptRange: {
      sdate: null,
      edate: null
    },
    season: [],
    ecommFlag: false,
    isLostSales: false,
    salesFlag: true,
    rplFlag: false,
    isComp: true,
    is_season_level: true,
    otb_flag: false,
    fobId: [],
    dmmId: [],
    dcId: [],
    departmentId: [],
    classes: [],
    vendors: [],
    buyerId: [],
    buyerFromHindsight: [],
    lyFlag: true,
    reg_flag: true,
    sdfTableData: [],
    hasAttributeClustering: false,
  }
};

export default (state = clusteringReducerDefaultState, action) => {
  switch (action.type) {
    case "SET_ELBOW_CURVE":
      return {
        ...state,
        elbowCurve: action.payload
      };
    case "SET_OPTIMAL_CLUSTERS":
      return {
        ...state,
        optimalClusters: Number(action.payload)
      };
    case "SET_OPTIMAL_ATTRIBUTE_CLUSTERS":
      return {
        ...state,
        optimalAttributeClusters: +action.payload
      };
    case "SET_USER_SELECTED_CLUSTERS":
      return {
        ...state,
        userSelectedClusters: Number(action.payload)
      };
    case "SET_CLUSTER_MAPPING":
      return {
        ...state,
        clusterMapping: action.payload
      };
    case "SET_CLUSTER_RESULTS_STORE_LEVEL":
      return {
        ...state,
        clusterResultsStoreLevel: action.payload
      };
    case "SET_ATTRIBUTE_SELECTION_LIST":
      return {
        ...state,
        attributeSelectionList: action.payload
      };
      case "SET_ATTRIBUTE_NOT_ENOUGH_FIL_RATE":
        return {
          ...state,
          notEnoughFillRate: action.payload
        };
    case "SET_ATTRIBUTES_GRADE_CHART":
      return {
        ...state,
        attributesGradeChart: action.payload
      };
    case "SET_ATTRIBUTES_STORE_MAPPING":
      return {
        ...state,
        attributeStoreMapping: action.payload
      };
    case "SDF_SALES_SUMMARY":
      return {
        ...state,
        sdfTableData: [{ ...action.payload }]
      };
    case "SET_CLUSTERING_INPUT_FIELDS":
      return {
        ...state,
        clusteringInputFields: action.payload
      };
    case "CLEAR_CLUSTERING_DATA":
      return {
        ...clusteringReducerDefaultState
      };
    default:
      return state;
  }
};

/* eslint-disable no-loop-func */
import React, { Component } from "react";
// import { Fabric } from "office-ui-fabric-react/lib/Fabric";
// import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import XLSX from "xlsx";
import { make_cols } from "./MakeColumns";
// import { SheetJSFT } from "./types";
import { toast } from "react-toastify";

class ExcelReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data1: [],
      cols: []
    };
    this.handleFile = this.handleFile.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }
  newArr = [];

  handleFile(e) {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    const files = e.target.files;
    let file;
    if (files && files[0]) file = files[0];

    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true
      });

      /* Get second worksheet */

      let wsname = wb.SheetNames[1];
      let ws = wb.Sheets[wsname];
      // console.log("ref", ws, wb, wb.Sheets["Style Color level"]);
      const headers = [
        "Vendor name",
        "Custom Category",
        "Style color name",
        "APS",
        "Weeks",
        "No Of Sizes",
        "Min Per Size",
        "MSRP/ Ticket",
        "IMU",
        "Reg Discount %",
        "Production Min",
        "Max Quantity",
        "MOQ",
        "Surcharge %",
        "Max Store Count",
        "Reg ST %",
        "1st MD ST %",
        "1st MD Discount %",
        "2nd MD ST %",
        "2nd MD Discount %"
      ];
      var data;
      if (
        // ws !== undefined ||
        wb.Sheets["Style Color level"] !== undefined &&
        wb.Sheets["Style Color level"]["!ref"] !== undefined
      ) {
        data = XLSX.utils.sheet_to_json(ws, { raw: true });

        //  Convert array of arrays

        for (let i = 0; i < data.length; i++) {
          // eslint-disable-next-line no-loop-func
          let flag = headers.every(
            h =>
              Object.keys(data[i]).includes(h) &&
              data[i][h] !== "" &&
              data[i][h] !== " "
          );
          if (!flag) {
            toast.error(
              "The file has missing values. Please upload a correct file.",
              { autoClose: false }
            );
            return;
          }
          let initialSetOfData =
            this.props.boInputDataStyleColorLevel[i] &&
            this.props.boInputDataStyleColorLevel[i];
          const primaryAttrObj = {};

          if (
            this.props.selectedPrimaryAttributes &&
            this.props.selectedPrimaryAttributes.length
          ) {
            if (this.props.selectedPrimaryAttributes.length === 1) {
              ["NONE_2"].forEach(primaryAttr => {
                primaryAttrObj[primaryAttr] = "-";
              });
              this.props.selectedPrimaryAttributes.forEach(primaryAttr => {
                primaryAttrObj[primaryAttr] = initialSetOfData[primaryAttr] || '-';
              });
            } else if (this.props.selectedPrimaryAttributes.length === 2) {
              this.props.selectedPrimaryAttributes.forEach(primaryAttr => {
                primaryAttrObj[primaryAttr] = initialSetOfData[primaryAttr] || '-';
              });
            }
          } else {
            ["NONE_1", "NONE_2"].forEach(primaryAttr => {
              primaryAttrObj[primaryAttr] = "-";
            });
          }
          this.newArr.push({
            // "Vendor/Class ID": data[i]["class/vendor"],
            // classId: data[i]["Class ID"],
            "class/vendor": initialSetOfData["class/vendor"],
            subclass: initialSetOfData["subclass"],
            styleNameColor: initialSetOfData["styleNameColor"],
            ...primaryAttrObj,
            styleId: data[i]["Style ID"],
            aps: data[i]["APS"],
            weeks: data[i]["Weeks"],
            noOfSizes: data[i]["No Of Sizes"],
            minPerSize: data[i]["Min Per Size"],
            msrp: data[i]["MSRP/ Ticket"],
            imu: data[i]["IMU"],
            regDiscount: data[i]["Reg Discount %"],
            productionMin: data[i]["Production Min"],
            maxQuantity: data[i]["Max Quantity"],
            moq: data[i]["MOQ"],
            surchargePerc: data[i]["Surcharge %"],
            // season: data[i]["Season"],
            storeCount: data[i]["Max Store Count"],
            regSellThroughPerc: data[i]["Reg ST %"],
            firstMarkdownSellThrough: data[i]["1st MD ST %"],
            firstMarkdownDiscount: data[i]["1st MD Discount %"],
            secondMarkdownSellThrough: data[i]["2nd MD ST %"],
            secondMarkdownDiscount: data[i]["2nd MD Discount %"],
            combinationFlag: data[i]["Combination Flag"]
          });
        }

        //  Update state and Call parent props function
        this.setState(
          { data: this.newArr, cols: make_cols(ws["!ref"]) },
          () => {
            this.props.dataCapture(JSON.stringify(this.state.data, null, 2));
            this.newArr = [];
          }
        );

        wsname = wb.SheetNames[2];
        ws = wb.Sheets[wsname];
        //  Convert array of arrays
        data = XLSX.utils.sheet_to_json(ws);

        //  Update state and Call parent props function

        // CHECK IF THE FILE IS PROPER OR VALIDATE IT.
        // console.log("ref", ws);

        this.setState({ data1: data, cols: make_cols(ws["!ref"]) }, () => {
          this.props.dataCaptureSecond(
            JSON.stringify(this.state.data1, null, 2)
          );
        });
      } else {
        e.preventDefault();
        toast.error("Please upload correct file.", { autoClose: false });
      }
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
    this.fileInput.value = ""; // File holds cache of filename, By clearing it same file can be uploaded again
  }

  render() {
    return (
      // <span className="Upload-file-container">

      <input
        type="file"
        className="fileinput"
        id="upload-file"
        accept={"SheetJSFT"}
        onChange={this.handleFile}
        ref={ref => (this.fileInput = ref)}
      />
    );
  }
}

export default ExcelReader;

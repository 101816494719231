import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import "./ChartJS.css";

class BarChartComponent extends Component {
  state = {};

  render() {
    let lyOrLly = this.props.lyFlag ? "LY" : "LLY";
    let barDataSets = [
      {
        labels:
          // this.props.data &&
          this.props.data &&
          this.props.data["APS bands"].map((item, index) => {
            return item.APS_band;
          }),
        datasets: [
          {
            label: "TY Adjusted APS",
            backgroundColor: "rgb(79, 153, 171)",
            data:
              // this.props.data &&
              this.props.data &&
              this.props.data["APS bands"].map((item, index) => {
                return item["TY_Choices %TTL"];
              })
          },
          {
            label: `${lyOrLly} Adjusted aps`,
            backgroundColor: "#e681ff",
            data:
              // this.props.data &&
              this.props.data &&
              this.props.data["APS bands"].map((item, index) => {
                return item["LY_Choices %TTL"];
              })
          }
        ]
      }
    ];

    let getBarData = () => {
      let data;
      switch (this.props.chartName) {
        case "BO_buyinput":
          data = barDataSets[0];

          break;
        case "APT_Subclass":
          data = this.props.data;

          break;

        default:
          data = [];
          break;
      }

      return data;
    };
    return <Bar options={this.props.options} data={getBarData()} />;
  }
}

export default BarChartComponent;

import React, { Component } from "react";
import DataTable from "../../../services/dataTable";
import ItemLevelAggregationColumnDef from "../colDefs/ItemLevelAggregationColumnDef";
class ItemLevelAggregationTable extends Component {
  render() {
    return (
      <div
        className="mt-4"
        style={{ height: "180px", width: "50%", margin: "auto" }}
      >
        <DataTable
          sizeToFit={true}
          defaultExpand={true}
          passGridAPI={api => {
            this.gridApi = api;
          }}
          columnDefs={ItemLevelAggregationColumnDef.columnDefs}
          rowData={this.props.itemLevelAggregate}
          defaultColDef={ItemLevelAggregationColumnDef.defaultColDef}
        />
      </div>
    );
  }
}

export default ItemLevelAggregationTable;

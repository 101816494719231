import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import projectActions from "../../actions/ProjectActions";
import commonActions from "../../actions";
import IntelligentClustering from "../Clustering";
import ClusterFloatingComponent from "../ClusterFloatingComponent";
import "../TabContainer/TabContainer.css";

class TabContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabNum: 0,
      intitialActiveStep: 0,
      isClusterDisabled: false,
      isHindsightFlag: false
    };
  }
  componentDidMount() {
    if (this.props.match.params.id) {
      this.props
        .getClusteringDetailsById({
          clusterMasterId: this.props.match.params.id
        })
        .then(resp => {
          const subStage = Number(resp.data.clusterStage.split("-")[1]) - 1;

          /**
           * If user is on intelligent clustering,
           * we need project names to validate,
           * so move the user to dashboard.
           *
           * Right now commented, will check in future.
           */
          // if (mainStage === 0) {
          //   if (!this.props.projectsList) {
          //     this.props.history.replace("/dashboard");
          //   }
          // }

          this.setState({
            intitialActiveStep: subStage
          });
        });
    } else {
      if (!this.props.hindsightProjectNamesList) {
        this.props.history.replace("/hindsightCluster");
      }
    }
    this.setState({
      isHindsightFlag: true
    });
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  saveClusterStage = (activeTab, activeStep, clusterMasterId) => {
    var saveStage = this.props.callSaveClustertStage({
      stage: `${activeTab}-${activeStep}`,
      clusterMasterId:
        clusterMasterId ||
        this.props.clusterMasterId ||
        this.props.match.params.id
    });
    return saveStage;
  };
  updateActiveTab = (activeTab, activeStep) => {
    this.setState({
      selectedTabNum: activeTab,
      intitialActiveStep: activeStep - 1
    });
  };
  render() {
    return (
      <div className="MainTabConatiner">
        <div>
          {this.props.clusterMasterId ? (
            <ClusterFloatingComponent data={this.props.clusteringDetails} />
          ) : null}
        </div>

        <Tabs transition={false} id="noanim-tab-example">
          <Tab eventKey="clustering">
            {this.state.selectedTabNum === 0 && (
              <IntelligentClustering
                isHindsightFlag={this.state.isHindsightFlag}
                isClusterDisabled={this.state.isClusterDisabled}
                intitialActiveStep={this.state.intitialActiveStep}
                updateActiveTab={this.updateActiveTab}
                saveClusterStage={this.saveClusterStage}
              />
            )}
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    clusterMasterId: store.projectReducer.clusterMasterId,
    clusteringDetails: store.projectReducer.clusteringDetails,
    projectMainStage: store.projectReducer.projectMainStage,
    projectSubStage: store.projectReducer.projectSubStage,
    hindsightProjectNamesList: store.userReducer.hindsightProjectNamesList
  };
};
const mapActionsToProps = {
  getClusteringDetailsById: projectActions.getClusteringDetailsById,
  callSaveClustertStage: projectActions.callSaveClustertStage,
  clearData: commonActions.clearData
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(TabContainer);

export function arrToObjConverter(array, keys) {
  const obj = {};
  array.forEach(ele => {
    let key = "";
    keys.forEach(k => {
      key += ele[k];
    });
    obj[key] = ele;
  });
  return obj;
}

export function snakeToTitle(string) {
  let sentence = string.toLowerCase().split("_");
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(" ");
}

export function roundTo(num, inc) {
  const a = num / inc
  return Math.round(a) * inc
}

export function sumProduct(arr1, arr2) {
  if (arr1.length !== arr2.length) return;
  return arr1.reduce((acc, currValue, currIndex) => {
    return acc + currValue * arr2[currIndex];
  }, 0);
}

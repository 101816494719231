import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";

import "./App.css";
import Dashboard from "./components/Dashboard";
import Header from "./components/Header";
import Login from "./components/Login";
import Sidebar from "./components/SideBar";
import BuyRollup from "./components/BuyRollup";
import TabContainer from "./components/TabContainer";
import userActions from "./actions/userActions";
import commonActions from "./actions";
import HindsightDashboard from "./components/HindsightDasboard/HindsightDashboard";
import HindsightCluster from "./components/HindsightCluster/HindsightCluster.js";
import { RoleDefinedRoutes } from "./components/UserManagement/UserAccess.js"
import UserManagementDashboard from "./containers/UserManagementDashboard";
class App extends Component {
  constructor(props) {
    super(props);
    if (props && props.location && !window.localStorage.getItem("token")) {
      this.props.history.push("/login");
    } else {
      this.props.history.push(this.props.location.pathname);
    }
    console.log("Application Environment:", process.env.NODE_ENV);
  }

  componentDidMount() {
    window.addEventListener("storage", e => {
      if (e.key === "token" && e.oldValue && !e.newValue) {
        this.props.logout(this.props);
      }
    });
  }
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/login" component={Login} />
          <LoadingOverlay
            active={this.props.getOverlayLoaderState}
            spinner
            text={this.props.getOverlayLoaderText}
          >
            <div className="wrapper">
              <Sidebar clearData={this.props.clearData} />
              <Header />
              <div id="main-container">
                <Switch>
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route
                    exact
                    path="/hindsightdashboard"
                    component={HindsightDashboard}
                  />
                  <Route
                    exact
                    path="/buyrollup"
                    component={BuyRollup}
                  />
                  <RoleDefinedRoutes
                    rolevalue="admin"
                    exact
                    path="/usermanagement"
                    component={UserManagementDashboard}
                  />
                  <Route
                    path="/stepperContainer/:id?"
                    component={TabContainer}
                  />
                  <Route
                    path="/hindsightCluster/:id?"
                    component={HindsightCluster}
                  />
                  <Route
                    path="*"
                    render={() => {
                      if (!window.localStorage.getItem("token"))
                        this.props.history.push("/login");
                      else this.props.history.push("/dashboard");
                    }}
                  />
                </Switch>
              </div>
            </div>
          </LoadingOverlay>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    getOverlayLoaderState: store.mainReducer.overlayLoaderState,
    getOverlayLoaderText: store.mainReducer.loaderText
  };
};
const mapActionsToProps = {
  logout: userActions.logout,
  clearData: commonActions.clearData
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(App));

import Button from "@material-ui/core/Button";
import Tippy from "@tippy.js/react";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import { cloneDeep, isEqual, map, uniq } from "lodash";
import numeral from "numeral";
import React, { Component, Fragment } from "react";
import Modal from "react-awesome-modal";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, CardHeader } from "reactstrap";
import AssortmentPlanningActions from "../../../actions/AssortmentPlanningActions";
import {
  decimalFormatter,
  roundToTwoDecimal,
  toDollar,
  toPercentage,
  toUnit,
  toUnitNoData
} from "../../../helpers/formatters";
import DataTable from "../../../services/dataTable";
import Select from "../../../services/selectAll";
// import html2pdf from "html2pdf.js";
import { tar } from "../../../styles";
import AttributeGraph from "./AttributeGraph";
import averageDepthAndChoiceColumnDefs from "./columnDefs/averageDepthAndChoiceColumnDefs";
// import subclassLevelStylesColumnDefs from "./columnDefs/subclassLevelStylesColumnDefs";
import classLevelDepthAndChoiceCountColumnDefs from "./columnDefs/classLevelDepthAndChoiceCountColumnDefs";
import clusterSummaryColumnDefs from "./columnDefs/clusterSummaryColumnDefs";
import goodBestBetterColumnDefs from "./columnDefs/goodBestBetterColumnDefs";
import minMaxColumnDefs from "./columnDefs/minMaxColumnDefs";
import monthProportionColDefs from "./columnDefs/proportionsColumnDefs";
import styleLevelGroupAggregation from "./styleLevelGroupAggregation";

class Step5AutoApx extends Component {
  constructor(props) {
    super(props);

    let classOrVendorLabel, lineRcptTYKey;
    switch (props.lineReceiptlevel) {
      case "class":
        classOrVendorLabel = "Class";
        break;
      case "vendor":
        classOrVendorLabel = "Vendor";
        break;
      case "vendor_class":
        classOrVendorLabel = "Vendor/Class";
        break;
      case "stretch":
        classOrVendorLabel = "Stretch";
        break;
      default:
        break;
    }
    // Defining line reciept key for getting planner budget
    for (let i = 0; i < Object.keys(props.lineReceiptData[0]).length; i++) {
      const element = Object.keys(props.lineReceiptData[0])[i];
      if (element.startsWith(props.month) && element.endsWith("TY")) {
        lineRcptTYKey = element;
        break;
      }
    }
    console.log(lineRcptTYKey);
    // Creating Class/Vendor options from lineReceipt for adding subclass
    // (Taken into consideration Only TY Budget > 0 )
    this.state = {
      isUpdate: 0,
      minMaxRowData: [],
      isUpdate1: 0,
      classOptions: null,
      subClassOptions: null,
      minMaxEnteredKeys: [],
      class: null,
      subClass: null,
      updateComponent: false,
      clusterSummaryModalVisible: false,
      minMaxTableModalVisible: false,
      depthMultiplierModal: false,
      reviewMonthlyProportionsModal: false,
      subClassStyleRowData: [],
      depthAndChoiceRowData: [],
      selectedSubClassName: null,
      depthAndChoiceRollUps: null,
      classOrVendorOptions: [],
      classOrVendorLabel,
      styleLevelTopRowData: [],
      depthVarianceTable: {},
      eligibilityToggle: "depth",
      attrOptions: [],
      selectedAttr: {},
      graphData: {},
      minMaxTopFixedRow: []
    };
  }
  componentDidMount() {
    if (this.props.historicalParameterData.length) {
      let classes = uniq(
        map(this.props.historicalParameterData, e => e["class/vendor"])
      );
      const classOrVendorOptions = [];

      classes.forEach(ele => {
        classOrVendorOptions.push({
          label: ele,
          value: ele
        });
      });
      let depthAndChoiceRollUps = [];
      classes.forEach(className => {
        let obj1 = {};
        let obj2 = {};
        obj1["class/vendor"] = className;
        obj2["class/vendor"] = className;
        obj1["metric"] = "Total Choice Count";
        obj2["metric"] = "Avg Depth Count";
        this.props.clusters.forEach(cluster => {
          let numOfSubClasses = 0;
          let depthRollUp = 0;
          let choiceRollUp = 0;
          this.props.historicalParameterData.forEach(rowData => {
            if (rowData["class/vendor"] === className) {
              numOfSubClasses = numOfSubClasses + 1;
              if (typeof rowData[`depthCluster${cluster}`] !== "number") {
                rowData[`depthCluster${cluster}`] = Number(
                  rowData[`depthCluster${cluster}`]
                );
              }
              if (typeof rowData[`choiceCluster${cluster}`] !== "number") {
                rowData[`choiceCluster${cluster}`] = Number(
                  rowData[`choiceCluster${cluster}`]
                );
              }
              depthRollUp += rowData[`depthCluster${cluster}`];
              choiceRollUp += rowData[`choiceCluster${cluster}`];
            }
          });
          depthRollUp = depthRollUp / numOfSubClasses;
          obj1[`Cluster${cluster}`] = choiceRollUp;
          obj2[`Cluster${cluster}`] = depthRollUp;
        });
        depthAndChoiceRollUps.push(obj1);
        depthAndChoiceRollUps.push(obj2);
      });
      let defaultClass = {
        label: this.props.historicalParameterData[0]["class/vendor"],
        value: this.props.historicalParameterData[0]["class/vendor"]
      };
      let defaultSubClass = {
        label: this.props.historicalParameterData[0]["subclass"],
        value: this.props.historicalParameterData[0]["subclass"]
      };
      if (this.props.lineArchitectureData) {
        let tempClassObj = Object.keys(
          this.props.lineArchitectureData["Auto APX"]
        );
        let classoptions1 = uniq(
          tempClassObj.map(val => {
            return {
              value: val,
              label: val
            };
          })
        );
        this.setState({
          classOptions: classoptions1
        });
      }
      if (this.props.lineArchitectureData) {
        let subClassDataArray = this.props.lineArchitectureData["Auto APX"][
          defaultClass.value
        ];
        let subClassTemp =
          subClassDataArray &&
          subClassDataArray.map(val => {
            return {
              value: val,
              label: val
            };
          });
        this.setState({
          subClassOptions: subClassTemp
        });
      }
      let tempArr = [];
      let tempObj = {};
      let cardData = this.props.historicalParameterData[0].styleLevel;
      if (cardData && cardData.length) {
        tempObj = this.calFirstRowOfSummary(
          cardData,
          this.props.historicalParameterData[0]["TY ST%"]
        );
        if (tempObj) {
          tempObj["ST%"] = this.props.historicalParameterData[0]["TY ST%"];
        }
        tempArr.push(...cardData);
      }
      this.setState(
        {
          subClassStyleRowData: tempArr,
          minMaxRowData: this.props.historicalParameterData,
          minMaxTopFixedRow: this.setMinMaxRowData(),
          depthAndChoiceRowData: this.props.historicalParameterData[0]
            .depthAndChoiceData,
          class: defaultClass,
          subClass: defaultSubClass,
          depthAndChoiceRollUps: depthAndChoiceRollUps,
          classOrVendorOptions: classOrVendorOptions,
          selectedSubClassName: defaultSubClass.value
        },
        () => {
          this.onSubmit();
        }
      );
    }
    if (
      this.props.selectedPrimaryAttributes &&
      this.props.selectedPrimaryAttributes.length
    ) {
      const attrTypeChartOptions = this.props.selectedPrimaryAttributes.map(
        e => ({
          label: e.includes("_") ? e.split("_").join(" ") : e,
          value: e
        })
      );
      this.setState({
        attrOptions: attrTypeChartOptions,
        selectedAttr: attrTypeChartOptions[0]
      });
    }
    this.props.getOmniValues(this.props.projectId);
    this.generateDepthVarianceTable();
  }
  componentDidUpdate(oldprops, oldstate) {
    if (
      !isEqual(
        oldprops.historicalParameterData,
        this.props.historicalParameterData
      )
    ) {
      let defaultClass = {
        label:
          (oldstate.class && oldstate.class.label) ||
          this.props.historicalParameterData[0]["class/vendor"],
        value:
          (oldstate.class && oldstate.class.value) ||
          this.props.historicalParameterData[0]["class/vendor"]
      };
      let defaultSubClass = {
        label:
          (oldstate.subClass && oldstate.subClass.label) ||
          this.props.historicalParameterData[0]["subclass"],
        value:
          (oldstate.subClass && oldstate.subClass.value) ||
          this.props.historicalParameterData[0]["subclass"]
      };
      // let subClassTemp = this.props.lineArchitectureData["Auto APX"][
      //   defaultClass.value
      // ].map(val => {
      //   return {
      //     value: val,
      //     label: val
      //   };
      // }); // work to be done
      let tempArr = [];
      let tempObj = {};
      let cardData = this.props.historicalParameterData[0].styleLevel;
      if (cardData && cardData.length) {
        tempObj = this.calFirstRowOfSummary(
          cardData,
          this.props.historicalParameterData[0]["TY ST%"]
        );
        if (tempObj) {
          tempObj["ST%"] = this.props.historicalParameterData[0]["TY ST%"];
          // tempArr.push(tempObj);
        }
        tempArr.push(...cardData);
      }
      this.setState(
        {
          subClassStyleRowData: tempArr,
          minMaxRowData: this.props.historicalParameterData,
          minMaxTopFixedRow: this.setMinMaxRowData(),
          depthAndChoiceRowData: this.props.historicalParameterData[0]
            .depthAndChoiceData,
          class: defaultClass,
          subClass: defaultSubClass
          // subClassOptions: subClassTemp // work to be done
        },
        () => {
          this.onSubmit();
        }
      );
      this.setState({
        selectedSubClassName: defaultSubClass.value
      });
    }
    if (!oldprops.lineArchitectureData && this.props.lineArchitectureData) {
      let tempClassObj = Object.keys(
        this.props.lineArchitectureData["Auto APX"]
      );
      let classoptions1 = uniq(
        tempClassObj.map(val => {
          return {
            value: val,
            label: val
          };
        })
      );
      this.setState({
        classOptions: classoptions1
      });
    }
    if (
      this.props.selectedPrimaryAttributes &&
      this.props.selectedPrimaryAttributes.length &&
      !this.state.attrOptions.length
    ) {
      const attrTypeChartOptions = this.props.selectedPrimaryAttributes.map(
        e => ({
          label: e.includes("_") ? e.split("_").join(" ") : e,
          value: e
        })
      );
      this.setState({
        attrOptions: attrTypeChartOptions,
        selectedAttr: attrTypeChartOptions[0]
      });
    }
  }
  getColumnDefsForDepthVarianceTable = () => {
    const colDefs = [
      {
        headerName: "Class",
        field: "class",
        rowGroup: true,
        hide: true
      },
      {
        headerName: "Total",
        valueFormatter: toPercentage,
        aggFunc: data => {
          let actualUnits = 0;
          let plannedUnits = 0;
          data.forEach(e => {
            actualUnits += e.actualUnits || 0;
            plannedUnits += e.plannedUnits || 0;
          });
          return (
            actualUnits &&
            plannedUnits &&
            ((actualUnits / plannedUnits - 1) * 100 || 0)
          );
        },
        cellStyle: tar,
        width: 130,
        valueGetter: params => {
          if (!params.node.group) {
            const actualUnits = params.data.totalActuals;
            const plannedUnits = params.data.totalPlanned;
            return {
              actualUnits,
              plannedUnits,
              toString: () => {
                return (
                  actualUnits &&
                  plannedUnits &&
                  ((actualUnits / plannedUnits - 1) * 100 || 0)
                );
              }
            };
          }
        }
      }
    ];
    const { clusters } = this.props;
    clusters.forEach(cluster => {
      colDefs.push({
        headerName: +cluster === 0 ? "Ecom" : "CL " + cluster,
        valueFormatter: toPercentage,
        width: 90,
        aggFunc: data => {
          let actualUnits = 0;
          let plannedUnits = 0;
          data.forEach(e => {
            actualUnits += e.actualUnits || 0;
            plannedUnits += e.plannedUnits || 0;
          });
          return (
            actualUnits &&
            plannedUnits &&
            ((actualUnits / plannedUnits - 1) * 100 || 0)
          );
        },
        cellStyle: tar,
        valueGetter: params => {
          if (!params.node.group) {
            const actualUnits = params.data[cluster];
            const plannedUnits = params.data["planned" + cluster];
            return {
              actualUnits,
              plannedUnits,
              toString: () => {
                return (
                  actualUnits &&
                  plannedUnits &&
                  ((actualUnits / plannedUnits - 1) * 100 || 0)
                );
              }
            };
          }
        }
      });
    });
    return colDefs;
  };
  generateDepthVarianceTable = () => {
    const { clusters, historicalParameterData, clusterData } = this.props;
    const noOfDoors = clusterData[0];
    const depthVarianceTable = {};
    historicalParameterData.forEach(subClassObj => {
      const classSubclassKey = `${subClassObj["class/vendor"]}-${subClassObj["subclass"]}`;
      const obj = {
        class: subClassObj["class/vendor"],
        subclass: subClassObj["subclass"]
      };
      clusters.forEach(cluster => {
        const cc = subClassObj["choiceCluster" + cluster];
        const depth = subClassObj["depthCluster" + cluster];
        const noOfDoorsForCluster = noOfDoors[cluster];
        obj["planned" + cluster] = depth * cc * noOfDoorsForCluster;
      });
      depthVarianceTable[classSubclassKey] = {
        ...obj,
        ...this.calFirstRowOfSummary(
          subClassObj.styleLevel,
          subClassObj["TY ST%"]
        )
      };
      let totalPlannedDepthForSubClass = 0;
      let totalActualDepthForSubClass = 0;
      clusters.forEach(cluster => {
        totalPlannedDepthForSubClass +=
          depthVarianceTable[classSubclassKey]["planned" + cluster] || 0;
        totalActualDepthForSubClass +=
          depthVarianceTable[classSubclassKey][cluster] || 0;
      });
      depthVarianceTable[classSubclassKey][
        "totalPlanned"
      ] = totalPlannedDepthForSubClass;
      depthVarianceTable[classSubclassKey][
        "totalActuals"
      ] = totalActualDepthForSubClass;
    });
    this.setState({ depthVarianceTable });
  };
  generateAttrChart = () => {
    const { subClassStyleRowData } = this.state;
    const { clusters, selectedPrimaryAttributes, clusterData } = this.props;
    const [doorData] = clusterData;
    const total = {};
    const attrValueTotal = {};
    selectedPrimaryAttributes.forEach((attrKey, index) => {
      if (!attrValueTotal[attrKey]) attrValueTotal[attrKey] = {};

      clusters.forEach(clusterKey => {
        if (+clusterKey !== 0) {
          const attrCluster = clusterKey.substring(0, 1);
          if (!attrValueTotal[attrKey][attrCluster])
            attrValueTotal[attrKey][attrCluster] = {};

          subClassStyleRowData.forEach(style => {
            if (!total[attrCluster]) total[attrCluster] = 0;
            index === 0 &&
              (total[attrCluster] += style[clusterKey] * doorData[clusterKey]);

            const attrValue = style[attrKey];
            if (attrValue && !attrValueTotal[attrKey][attrCluster][attrValue])
              attrValueTotal[attrKey][attrCluster][attrValue] = 0;
            attrValueTotal[attrKey][attrCluster][attrValue] +=
              style[clusterKey] * doorData[clusterKey];
          });
        }
      });
    });
    selectedPrimaryAttributes.forEach(attrKey => {
      const attrClusters = Object.keys(attrValueTotal[attrKey]);
      for (const attrCluster of attrClusters) {
        const attrValuesObj = attrValueTotal[attrKey][attrCluster];
        const attrValueKeys = Object.keys(attrValuesObj);
        for (const attrValueKey of attrValueKeys) {
          attrValuesObj[attrValueKey] =
            attrValuesObj[attrValueKey] / total[attrCluster];
        }
      }
    });
    this.setState(
      {
        graphData: {
          ...this.state.graphData,
          TY: attrValueTotal
        }
      },
      () => {
        this.generateLyChartData();
      }
    );
  };
  generateLyChartData = () => {
    const { ly_ty_penetrations_optimized: ly_data } = this.props;
    const ly = {};
    const total = {};
    ly_data.forEach(e => {
      if (!ly[e.class_subclass]) ly[e.class_subclass] = {};
      if (!ly[e.class_subclass][e.attr_type])
        ly[e.class_subclass][e.attr_type] = {};
      if (!total[e.attr_type]) total[e.attr_type] = {};
      if (![0, "Subclass_Ideal", "0"].includes(e.attr_cluster)) {
        if (!ly[e.class_subclass][e.attr_type][e.attr_cluster])
          ly[e.class_subclass][e.attr_type][e.attr_cluster] = {};
        if (!ly[e.class_subclass][e.attr_type][e.attr_cluster][e.attr_value])
          ly[e.class_subclass][e.attr_type][e.attr_cluster][e.attr_value] = 0;
        ly[e.class_subclass][e.attr_type][e.attr_cluster][e.attr_value] +=
          e.ly_penetration;
        if (!total[e.attr_type][e.attr_cluster])
          total[e.attr_type][e.attr_cluster] = 0;
        total[e.attr_type][e.attr_cluster] += e.ly_penetration;
      }
    });
    this.setState({
      graphData: {
        ...this.state.graphData,
        LY: ly[`${this.state.class.value}-${this.state.subClass.value}`]
      }
    });
  };
  getColDefForAvgDepth_AvgChoice = () => {
    return averageDepthAndChoiceColumnDefs(
      this.props.clusterData,
      this.props.clusters
    );
  };
  getColDefForMinMaxTable = () => {
    return minMaxColumnDefs(
      this.props.clusterData,
      this.props.clusters,
      this.props.historicalParameterData,
      this.props.userAccessDisableFlag
    );
  };
  getColDefForDepthAndChoiceCountTable = () => {
    return classLevelDepthAndChoiceCountColumnDefs(
      this.props.clusterData,
      this.props.clusters
    );
  };
  getColDefForSubClassLevelStyles = () => {
    const {
      isSeasonLevel,
      userAccessDisableFlag,
      hasAttributeClustering
    } = this.props;
    let tempObj = [];
    tempObj.push({
      headerName: "STYLE #",
      width: 93,
      sortable: true,
      tooltipField: "category",
      menuTabs: [],
      rowGroup: isSeasonLevel,
      hide: isSeasonLevel,
      field: "category",
      pinned: "left",
      filter: "agTextColumnFilter",
      unSortIcon: true,
      cellStyle: function(params) {
        if (params.node.rowPinned && params.node.rowPinned === "top") {
          return { ...{ "background-color": "#d7d7db" }, ...tar };
        } else {
          return tar;
        }
      }
    });
    tempObj.push({
      headerName: "CATEGORY",
      menuTabs: [],
      width: 110,
      tooltipField: "subclass",
      sortable: true,
      field: "subclass",
      unSortIcon: true,
      pinned: "left",
      cellStyle: function(params) {
        if (params.node.rowPinned && params.node.rowPinned === "top") {
          return { "background-color": "#d7d7db" };
        }
      }
    });
    tempObj.push({
      headerName: "CHOICE COUNT",
      menuTabs: [],
      width: 180,
      sortable: true,
      field: "CC",
      unSortIcon: true,
      editable: params => {
        if (!params.node.rowPinned) return !userAccessDisableFlag && true;
      },
      cellStyle: params => {
        if (params.node.rowPinned && params.node.rowPinned === "top")
          return { ...{ "background-color": "#d7d7db" }, ...tar };
        return !params.node.group ? this.props.conditionalInput(params) : tar;
      }
    });
    tempObj.push({
      headerName: "Month",
      width: 85,
      hide: !isSeasonLevel,
      field: "month",
      cellStyle: function(params) {
        if (params.node.rowPinned && params.node.rowPinned === "top") {
          return { ...{ "background-color": "#d7d7db" }, ...tar };
        }
      }
    });
    tempObj.push({
      headerName: "Proportion",
      hide: !isSeasonLevel,
      aggFunc: "sum",
      width: 100,
      field: "proportion",
      valueFormatter: toPercentage,
      cellStyle: function(params) {
        if (params.node.rowPinned && params.node.rowPinned === "top") {
          return { ...{ "background-color": "#d7d7db" }, ...tar };
        } else {
          return tar;
        }
      }
    });
    tempObj.push({
      headerName: "RCPT AUR",
      width: 73,
      field: "rcpt_aur",
      sortable: true,
      menuTabs: [],
      valueFormatter: toDollar,
      unSortIcon: true,
      cellStyle: function(params) {
        if (params.node.rowPinned && params.node.rowPinned === "top") {
          return { ...{ "background-color": "#d7d7db" }, ...tar };
        } else {
          return tar;
        }
      }
    });
    tempObj.push({
      headerName: "TOTAL $",
      width: 82,
      field: "total$",
      menuTabs: [],
      valueFormatter: toDollar,
      unSortIcon: true,
      sortable: true,
      cellStyle: function(params) {
        if (params.node.rowPinned && params.node.rowPinned === "top") {
          return { ...{ "background-color": "#d7d7db" }, ...tar };
        } else {
          return tar;
        }
      }
    });
    tempObj.push({
      headerName: "Planned Units",
      hide: hasAttributeClustering,
      width: 120,
      field: "plannedUnits",
      valueFormatter: toUnit,
      cellStyle: function(params) {
        if (params.node.rowPinned && params.node.rowPinned === "top") {
          return { ...{ "background-color": "#d7d7db" }, ...tar };
        } else {
          return tar;
        }
      }
    });
    tempObj.push({
      headerName: "TOTAL U",
      width: 81,
      field: "total_u",
      valueFormatter: toUnit,
      menuTabs: [],
      unSortIcon: true,
      sortable: true,
      cellStyle: function(params) {
        if (params.node.rowPinned && params.node.rowPinned === "top") {
          return { ...{ "background-color": "#d7d7db" }, ...tar };
        } else {
          return tar;
        }
      }
    });
    tempObj.push({
      headerName: "% var to Plan",
      hide: hasAttributeClustering,
      cellStyle: function(params) {
        if (params.node.rowPinned && params.node.rowPinned === "top") {
          return { ...{ "background-color": "#d7d7db" }, ...tar };
        } else {
          return tar;
        }
      },
      width: 80,
      colId: "% var to Plan",
      valueFormatter: toPercentage,
      valueGetter: params => {
        if (!params.node.group) {
          const { total_u, plannedUnits } = params.data;
          return {
            total_u,
            plannedUnits,
            toString: () => {
              return (
                total_u && plannedUnits && (total_u / plannedUnits - 1) * 100
              );
            }
          };
        }
      }
    });
    this.props.hasAttributeClustering &&
      this.state.attrOptions &&
      this.state.attrOptions.length &&
      this.state.attrOptions.forEach((attr, ind) => {
        let setSelOptimalAtte = this.props.attributesGradeChart[
          this.props.optimalAttributeClusters
        ];
        let isKeysPresent =
          setSelOptimalAtte.hasOwnProperty(attr.value) &&
          setSelOptimalAtte[attr.value];
        var attrOptions = [];
        if (isKeysPresent) {
          attrOptions = Object.keys(isKeysPresent);
        }
        let filterNoKeys = attrOptions.filter(att => att !== "");
        tempObj.push({
          headerName: attr.label,
          width: 90,
          menuTabs: [],
          editable: params => {
            if (!params.node.rowPinned) return !userAccessDisableFlag && true;
          },
          sortable: true,
          unSortIcon: true,
          field: attr.value,
          // cellRenderer: "dropDownIcon",
          cellEditorSelector: function(params) {
            return {
              component: "agRichSelectCellEditor",
              params: {
                values: filterNoKeys
              }
            };
          },
          cellStyle: params => {
            if (params.node.rowPinned && params.node.rowPinned === "top")
              return { ...{ "background-color": "#d7d7db" }, ...tar };
            return !params.node.group
              ? this.props.conditionalInput(params)
              : tar;
          }
        });
      });
    if (this.props.clusters) {
      this.props.clusters.forEach(cluster => {
        tempObj.push({
          headerName: +cluster === 0 ? "Ecom" : `CL ${cluster}`,
          width: 120,
          menuTabs: [],
          valueFormatter: params => {
            if (!params.node.group) return toUnitNoData(params);
          },
          unSortIcon: true,
          hide: this.props.isSeasonLevel
            ? this.state.eligibilityToggle === "eligibility"
            : false,
          sortable: true,
          editable: params => {
            if (!params.node.rowPinned) return !userAccessDisableFlag && true;
          },
          cellStyle: params => {
            if (params.node.rowPinned && params.node.rowPinned === "top")
              return { ...{ "background-color": "#d7d7db" }, ...tar };
            return !params.node.group
              ? this.props.conditionalInput(params)
              : tar;
          },
          field: cluster
        });
      });
      this.props.clusters.forEach(cluster => {
        tempObj.push({
          headerName: +cluster === 0 ? "Ecom" : `CL ${cluster}`,
          width: 120,
          menuTabs: [],
          valueFormatter: toUnitNoData,
          unSortIcon: true,
          hide: this.props.isSeasonLevel
            ? this.state.eligibilityToggle === "depth"
            : true,
          sortable: true,
          editable: params => {
            if (!params.node.rowPinned) return !userAccessDisableFlag && true;
          },
          cellStyle: params => {
            if (params.node.rowPinned && params.node.rowPinned === "top")
              return { ...{ "background-color": "#d7d7db" }, ...tar };
            return !params.node.group
              ? this.props.conditionalInput(params)
              : tar;
          },
          field: `v_${cluster}`
        });
      });
    }
    tempObj.push({
      headerName: "DR COUNT",
      width: 89,
      sortable: true,
      aggFunc: "max",
      field: "DR COUNT",
      menuTabs: [],
      unSortIcon: true,
      cellStyle: function(params) {
        if (params.node.rowPinned && params.node.rowPinned === "top") {
          return { ...{ "background-color": "#d7d7db" }, ...tar };
        } else {
          return tar;
        }
      }
    });
    tempObj.push({
      headerName: "AVG DEPTH",
      menuTabs: [],
      width: 84,
      field: "AVG DEPTH",
      valueFormatter: toUnit,
      sortable: true,
      unSortIcon: true,
      cellStyle: function(params) {
        if (params.node.rowPinned && params.node.rowPinned === "top") {
          return { ...{ "background-color": "#d7d7db" }, ...tar };
        } else {
          return tar;
        }
      }
    });
    tempObj.push({
      headerName: "WKS REG",
      field: "WEEKS REG",
      menuTabs: [],
      sortable: true,
      width: 69,
      unSortIcon: true,
      valueFormatter: toUnitNoData,
      cellStyle: function(params) {
        if (params.node.rowPinned && params.node.rowPinned === "top") {
          return { ...{ "background-color": "#d7d7db" }, ...tar };
        } else {
          return tar;
        }
      }
    });
    tempObj.push({
      headerName: "ST%",
      width: 73,
      field: "ST%",
      menuTabs: [],
      sortable: true,
      valueFormatter: toPercentage,
      unSortIcon: true,
      cellStyle: function(params) {
        if (params.node.rowPinned && params.node.rowPinned === "top") {
          return { ...{ "background-color": "#d7d7db" }, ...tar };
        } else {
          return tar;
        }
      }
      // cellStyle: function(params) {
      //   const selectedHistObj = minMaxRowData.filter(
      //     histObj => histObj.subclass === selSubclass
      //   );
      //   if (params.node.rowPinned && params.node.rowPinned === "top") {
      //     return { ...{ "background-color": "#d7d7db" }, ...tar };
      //   } else {
      //     if (
      //       params.node.data &&
      //       params.node.data["subclass"] === selectedHistObj[0]["subclass"]
      //     ) {
      //       if (
      //         params.node.data["ST%"] <= selectedHistObj[0]["minST%"] ||
      //         params.node.data["ST%"] >= selectedHistObj[0]["maxST%"]
      //       ) {
      //         return { ...errorInput, ...tar };
      //       } else {
      //         return {
      //           ...tar
      //         };
      //       }
      //     } else {
      //       return {
      //         ...tar
      //       };
      //     }
      //   }
      // }
    });
    tempObj.push({
      headerName: "APS",
      menuTabs: [],
      width: 68,
      field: "APS",
      sortable: true,
      valueFormatter: decimalFormatter,
      unSortIcon: true,
      cellStyle: function(params) {
        if (params.node.rowPinned && params.node.rowPinned === "top") {
          return { ...{ "background-color": "#d7d7db" }, ...tar };
        } else {
          return tar;
        }
      }
    });
    return tempObj;
  };
  getColDefForClusterSummary = () => {
    return clusterSummaryColumnDefs(
      this.props.clusterData,
      this.props.clusters
    );
  };
  validateMinMax = function(min, max, str) {
    let message = "";
    let value = str === "min" ? min : max;
    if (min > max)
      message =
        "minimum sell through cannot be greater than maximum sell through";
    else if (min === max)
      message = "minimum sell through cannot be equal to maximum sell through";

    if (value < 0)
      message = "Input value out of range. Cannot be lesser than 0 %";
    else if (value > 100)
      message = "Input value out of range. Cannot be greater than 100 %";

    if (message !== "") {
      toast.error(message, { autoClose: false });
      return false;
    } else return true;
  };
  setMinMaxRowData = () => {
    let newRowInMinMax = {};
    let minMaxRowData = cloneDeep(this.props.historicalParameterData);
    let minMaxRowDataWithNewRow = [];
    newRowInMinMax = this.createNewRowInMinMax(minMaxRowData[0]);
    if (Object.keys(newRowInMinMax).length) {
      minMaxRowDataWithNewRow.push(newRowInMinMax);
    }
    return minMaxRowDataWithNewRow;
  };
  calFirstRowOfSummary = (data, TYST) => {
    if (data.length) {
      let tempObj = {
        total$: 0,
        total_u: 0,
        plannedUnits: 0,
        "DR COUNT": 0,
        "WEEKS REG": 0,
        rcpt_aur: null
      };
      this.props.clusters.forEach(cluster => {
        tempObj[cluster] = 0;
      });
      let tempDRCOUNT = [];
      data.forEach(ele => {
        tempObj.total$ += Number(ele.total$);
        tempObj.total_u += ele.total_u;
        tempObj.plannedUnits += ele.plannedUnits;
        tempObj["WEEKS REG"] += Number(ele["WEEKS REG"]);
        // tempObj["DR COUNT"] += ele["DR COUNT"];
        tempDRCOUNT.push(ele["DR COUNT"]);
        this.props.clusters.forEach(cluster => {
          tempObj[cluster] += ele[cluster];
        });
      });
      this.props.clusters.forEach(cluster => {
        tempObj[cluster] =
          tempObj[cluster] * this.props.clusterData[0][cluster];
      });
      // tempObj["DR COUNT"] = tempObj["DR COUNT"] / data.length;
      tempObj["DR COUNT"] = Math.max(...tempDRCOUNT); // formula changed on 28-11-2019
      tempObj.rcpt_aur = data.length && data[0].rcpt_aur;

      tempObj.CC = this.props.isSeasonLevel
        ? data.length / this.props.rcptMonths.length
        : data.length;
      tempObj.category = "";
      tempObj.subclass = data[0].subclass;
      tempObj["AVG DEPTH"] =
        tempObj.total_u / tempObj["DR COUNT"] / data.length;
      // this.props.historicalParameterData.forEach(ele => {
      //   tempRCPT_PEN += Number(ele["rcpt_pen%"]);
      //   tempTYST += Number(ele["TY ST%"]) * Number(ele["rcpt_pen%"]);
      // });
      // tempObj["ST%"] = tempTYST / tempRCPT_PEN;
      tempObj["WEEKS REG"] = tempObj["WEEKS REG"] / data.length;
      tempObj["APS"] =
        ((tempObj["total_u"] / tempObj["DR COUNT"] / tempObj["WEEKS REG"]) *
          (TYST / 100)) /
        data.length;
      tempObj["DR COUNT"] = +numeral(tempObj["DR COUNT"]).format("0,0");
      return tempObj;
    }
    return null;
  };
  createNewRowInMinMax = row => {
    let minMaxFirstRow = {};
    for (let key in row) {
      if (row.hasOwnProperty(key)) {
        if (key === "subclass") {
          minMaxFirstRow[key] = "Set input for all subclasses";
        } else {
          minMaxFirstRow[key] = "";
        }
      }
    }
    return minMaxFirstRow;
  };
  onClassChange = val => {
    if (val) {
      let subClass = this.props.lineArchitectureData["Auto APX"][val.value].map(
        val => {
          return {
            value: val,
            label: val
          };
        }
      );
      this.setState({
        subClassOptions: subClass,
        class: val,
        subClass: null
      });
    } else {
      this.setState({
        class: val,
        subClass: null,
        subClassOptions: null
      });
    }
  };
  onSubClassChange = val => {
    this.setState({
      subClass: val
    });
  };
  onCellValueChangedProportions = params => {
    const { value, oldValue, data, column } = params;
    const classSubclassKey = `${data["class/vendor"]}-${data["subclass"]}`;

    if (isNaN(value)) data[column.colId] = +oldValue;
    else data[column.colId] = +value;
    this.props.recalculateSubclassStyles("Seasonal", classSubclassKey);
  };
  onCellValueChangedOnMinMax = val => {
    // let historicalParameterData = cloneDeep(this.props.historicalParameterData);
    const classSubclassKey = `${val.data["class/vendor"]}-${val.data["subclass"]}`;
    function RoundTo(number, roundto) {
      return roundto * Math.round(number / roundto);
    }
    //value is not updating for newly reacted row
    if (!val.rowPinned) {
      switch (val.colDef.field) {
        case "MIN B&M":
          if (
            +val.value <
            this.props.historicalParameterData[val.rowIndex]["MAX B&M"]
          ) {
            this.props.historicalParameterData[val.rowIndex][
              "MIN B&M"
            ] = +val.value;
            val.data[val.colDef.field] = +val.value;
          } else {
            this.props.historicalParameterData[val.rowIndex][
              "MIN B&M"
            ] = +val.oldValue;
            val.data[val.colDef.field] = +val.oldValue;
          }
          break;
        case "MAX B&M":
          if (
            +val.value <
            this.props.historicalParameterData[val.rowIndex]["MIN B&M"]
          ) {
            this.props.historicalParameterData[val.rowIndex][
              "MAX B&M"
            ] = +val.oldValue;
            val.data[val.colDef.field] = +val.oldValue;
          } else {
            this.props.historicalParameterData[val.rowIndex][
              "MAX B&M"
            ] = +val.value;
            val.data[val.colDef.field] = +val.value;
          }
          this.props.historicalParameterData[val.rowIndex]["MAX B&M"] = RoundTo(
            Number(val.value),
            val.data["INCREMENTS B&M"]
          );
          break;
        case "MIN ECOM":
          if (
            +val.value >
            this.props.historicalParameterData[val.rowIndex]["MAX_ECOM"]
          ) {
            this.props.historicalParameterData[val.rowIndex][
              "MIN ECOM"
            ] = RoundTo(+val.oldValue, val.data["INCREMENTS ECOM"]);
            val.data[val.colDef.field] = +val.oldValue;
          } else {
            this.props.historicalParameterData[val.rowIndex][
              "MIN ECOM"
            ] = RoundTo(+val.value, val.data["INCREMENTS ECOM"]);
            val.data[val.colDef.field] = RoundTo(
              +val.value,
              val.data["INCREMENTS ECOM"]
            );
          }
          break;
        case "MAX_ECOM":
          if (
            +val.value <
            this.props.historicalParameterData[val.rowIndex]["MIN ECOM"]
          ) {
            this.props.historicalParameterData[val.rowIndex][
              "MAX_ECOM"
            ] = RoundTo(+val.oldValue, val.data["INCREMENTS ECOM"]);
            val.data[val.colDef.field] = +val.oldValue;
          } else {
            this.props.historicalParameterData[val.rowIndex][
              "MAX_ECOM"
            ] = RoundTo(+val.value, val.data["INCREMENTS ECOM"]);
            val.data[val.colDef.field] = RoundTo(
              +val.value,
              val.data["INCREMENTS ECOM"]
            );
          }
          break;
        case "minST%":
          if (
            this.validateMinMax(
              Number(val.value),
              this.props.historicalParameterData[val.rowIndex]["maxST%"],
              "min"
            )
          ) {
            this.props.historicalParameterData[val.rowIndex]["minST%"] = Number(
              val.value
            );
            val.data[val.colDef.field] = +val.value;
          } else {
            this.props.historicalParameterData[val.rowIndex]["minST%"] = Number(
              val.oldValue
            );
            val.data[val.colDef.field] = +val.oldValue;
          }
          break;
        case "maxST%":
          if (
            this.validateMinMax(
              this.props.historicalParameterData[val.rowIndex]["minST%"],
              Number(val.value),
              "max"
            )
          ) {
            this.props.historicalParameterData[val.rowIndex]["maxST%"] = Number(
              val.value
            );
            val.data[val.colDef.field] = +val.value;
          } else {
            this.props.historicalParameterData[val.rowIndex]["maxST%"] = Number(
              val.oldValue
            );
            val.data[val.colDef.field] = +val.oldValue;
          }

          break;
        case "INCREMENTS B&M":
          this.props.historicalParameterData[val.rowIndex][
            "INCREMENTS B&M"
          ] = Number(val.value);
          this.props.historicalParameterData[val.rowIndex]["MAX B&M"] = RoundTo(
            val.data["MAX B&M"],
            Number(val.value)
          );
          val.data["MAX B&M"] = this.props.historicalParameterData[
            val.rowIndex
          ]["MAX B&M"];
          break;
        case "INCREMENTS ECOM":
          this.props.historicalParameterData[val.rowIndex][
            "INCREMENTS ECOM"
          ] = Number(val.value);
          this.props.historicalParameterData[val.rowIndex][
            "MIN ECOM"
          ] = RoundTo(val.data["MIN ECOM"], Number(val.value));
          val.data["MIN ECOM"] = this.props.historicalParameterData[
            val.rowIndex
          ]["MIN ECOM"];
          this.props.historicalParameterData[val.rowIndex][
            "MAX_ECOM"
          ] = RoundTo(val.data["MAX_ECOM"], Number(val.value));
          val.data["MAX_ECOM"] = this.props.historicalParameterData[
            val.rowIndex
          ]["MAX_ECOM"];
          break;

        case "variancePerMonth":
          this.props.historicalParameterData[val.rowIndex][
            "variancePerMonth"
          ] = Number(val.value);
          break;
        case "variancePerCluster":
          this.props.historicalParameterData[val.rowIndex][
            "variancePerCluster"
          ] = Number(val.value);
          break;

        default:
          break;
      }
      // this.props.setMinMaxTableOnCellValueChanged(historicalParameterData);
      this.props.recalculateSubclassStyles(this.props.month, classSubclassKey);
      // val.api.refreshCells();
      // val.api.refreshClientSideRowModel("aggregate");
    } else {
      if (!this.state.minMaxEnteredKeys.includes(val.colDef.field)) {
        let rowTop = this.state.minMaxTopFixedRow[0];
        rowTop[val.colDef.field] = val.newValue;
        let keys = this.state.minMaxEnteredKeys;
        keys.push(val.colDef.field);
        this.setState({
          minMaxEnteredKeys: keys,
          minMaxTopFixedRow: [{ ...rowTop }]
        });
      }
    }
    this.generateDepthVarianceTable();
  };
  onCellValueChangedForGoodBetterBestTable = val => {
    // let goodBetterBestTable = cloneDeep(this.props.goodBetterBestTable);
    // switch (val.colDef.field) {
    //   case "Range1":
    //     goodBetterBestTable[val.rowIndex].Range1 = Number(val.value);
    //     break;
    //   case "Range2":
    //     goodBetterBestTable[val.rowIndex].Range2 = Number(val.value);
    //     break;
    //   case "Range3":
    //     goodBetterBestTable[val.rowIndex].Range3 = Number(val.value);
    //     break;
    //   case "Range4":
    //     goodBetterBestTable[val.rowIndex].Range4 = Number(val.value);
    //     break;
    //   case "Range5":
    //     goodBetterBestTable[val.rowIndex].Range5 = Number(val.value);
    //     break;

    //   default:
    //     break;
    // }
    // this.props.setGoodBetterBestTableOnCellValueChanged(goodBetterBestTable);
    // if (
    //   val.data.goodBetterBest === "Range Index(Avg Depth Multiplier)" &&
    //   val.colDef.field === "Range1"
    // ) {
    this.props.recalculateMinMaxSubclassStyles(this.props.month);
    // }
    // val.api.refreshCells();
    val.api.refreshClientSideRowModel("aggregate");
    this.setState({
      isUpdate: Math.random() * 100
    });
  };
  onCellValueChangedSubClassLevelStyles = param => {
    // eslint-disable-next-line react/no-direct-mutation-state
    // if (param.column.colId === param.colDef.headerName) {
    //   this.setState({
    //     selectedAttr: this.state.attrOptions[0]
    //   });
    // }
    if (
      this.props.clusters.includes(param.column.colDef.field) ||
      param.column.colDef.field.startsWith("v_")
    ) {
      param.data[param.column.colDef.field] = +param.newValue;
      // Setting appropriate values for eligibility
      if (this.props.isSeasonLevel) {
        if (param.data[param.column.colDef.field] > 0)
          param.data[`v_${param.column.colDef.field}`] = 1;
        else param.data[`v_${param.column.colDef.field}`] = 0;
      }
      if (param.column.colDef.field.startsWith("v_")) {
        if (+param.newValue >= 1) param.data[param.column.colDef.field] = 1;
        const validtitdyClusterNum = param.column.colDef.field.split("_")[1];

        if (+param.oldValue === 0) {
          if (+param.data[validtitdyClusterNum] > 0) {
            return;
          } else {
            param.data[param.column.colDef.field] = 0;
            return;
          }
        }

        if (+param.oldValue === 1 && +param.newValue === 0) {
          if (+param.data[validtitdyClusterNum] > 0) {
            const { clusterData } = this.props;
            const depthForRespCluster =
              clusterData[0][validtitdyClusterNum] *
              param.data[validtitdyClusterNum];
            param.data[validtitdyClusterNum] = 0;
            const totalDepth = this.props.clusters.reduce((acc, val) => {
              return acc + param.data[val] * clusterData[0][val];
            }, 0);
            this.props.clusters.forEach(cluster => {
              if (+cluster !== +validtitdyClusterNum) {
                const proportion =
                  (param.data[cluster] * clusterData[0][cluster]) / totalDepth;
                const depthForRespClusterPerStore =
                  depthForRespCluster / clusterData[0][cluster];
                param.data[cluster] =
                  param.data[cluster] +
                  proportion * depthForRespClusterPerStore;
              }
            });
          } else {
            return;
          }
        }
      }
      let total_u = 0;
      let DR_COUNT = 0;
      let TY_ST = 0;
      let histObj = this.props.historicalParameterData.filter(obj => {
        return (
          this.state.class.value === obj["class/vendor"] &&
          this.state.subClass.value === obj.subclass
        );
      });
      this.props.clusters.forEach(clusterIndex => {
        total_u += isNaN(
          this.props.clusterData[0][clusterIndex] * param.data[clusterIndex]
        )
          ? 0
          : this.props.clusterData[0][clusterIndex] * param.data[clusterIndex];
        if (param.data[clusterIndex]) {
          // included ecom_12-12-2019
          DR_COUNT += isNaN(this.props.clusterData[0][clusterIndex])
            ? 0
            : this.props.clusterData[0][clusterIndex];
        }
        TY_ST += isNaN(param.data[clusterIndex] * histObj["TY ST%"])
          ? 0
          : param.data[clusterIndex] * histObj["TY ST%"];
      });
      if (TY_ST < histObj["minST%"]) {
        param.data["ST%"] = histObj["minST%"];
      }
      if (TY_ST > histObj["maxST%"]) {
        param.data["ST%"] = histObj["maxST%"];
      }
      /************* formula calcluations *************/
      param.data["total_u"] = total_u;
      param.data["total$"] = param.data["rcpt_aur"] * total_u;
      param.data["DR COUNT"] = DR_COUNT;
      param.data["AVG DEPTH"] = param.data["total_u"] / param.data["DR COUNT"];
      param.data["APS"] =
        ((param.data["total_u"] /
          param.data["DR COUNT"] /
          param.data["WEEKS REG"]) *
          param.data["ST%"]) /
        100;
    }

    if (param.column.colDef.field === "CC") {
      let flag = this.state.subClassStyleRowData.some((ele, eleIndex) => {
        if (
          typeof ele["CC"] !== "number" &&
          param.data !== ele &&
          ele["CC"] === param.newValue
        ) {
          return true;
        }
        return false;
      });
      if (flag) {
        toast.error("Name already exist...", { autoClose: false });
        param.data[param.column.colDef.field] = param.oldValue;
      }
    }
    this.onSubmit();
    this.generateDepthVarianceTable();
  };
  handleClusterSummaryIcon = () => {
    const classSubclassKey = `${this.state.class.value}-${this.state.subClass.value}`;
    this.props.setSelectedAutoAPXClass(this.props.month, classSubclassKey);
    this.setState({
      clusterSummaryModalVisible: true
    });
  };
  handleMinMaxTable = () => {
    this.setState({
      minMaxTableModalVisible: true
    });
  };
  handleMonthlyProportions = () => {
    this.setState({
      reviewMonthlyProportionsModal: true
    });
  };
  handleDepthMultiplierModal = () => {
    this.setState({
      depthMultiplierModal: true
    });
  };
  closeClusterSummaryModal() {
    this.setState({ clusterSummaryModalVisible: false });
  }
  closeMinMaxTableModal() {
    this.setState({ minMaxTableModalVisible: false });
  }
  closeDepthMultiplierModal() {
    this.setState({ depthMultiplierModal: false });
  }
  closeMonthlyProportionsModal() {
    this.setState({ reviewMonthlyProportionsModal: false });
  }
  downloadPdfFile = () => {
    // var pdfsize = "a0";
    var doc = new jsPDF("l", "pt", "a0");
    var seasonLevelColums = this.props.isSeasonLevel
      ? [
          {
            title: "Month",
            dataKey: "month"
          },
          {
            title: "Proportion",
            dataKey: "proportion"
          }
        ]
      : [];
    var columns = [
      {
        title: "STYLE #",
        dataKey: "category"
      },
      {
        title: "CATEGORY",
        dataKey: "subclass"
      },
      {
        title: "CHOICE COUNT",
        dataKey: "CC"
      },
      ...seasonLevelColums,
      {
        title: "RCPT AUR",
        dataKey: "rcpt_aur"
      },
      {
        title: "TOTAL $",
        dataKey: "total$"
      },
      {
        title: "TOTAL U",
        dataKey: "total_u"
      }
    ];
    this.props.clusters.forEach(cluster => {
      if (cluster !== "0") {
        columns.push({
          title: `CL ${cluster}`,
          dataKey: `${cluster}`
        });
      }

      if (cluster === "0") {
        columns.push({
          title: "ECOM",
          dataKey: "0"
        });
      }
    });
    columns = [
      ...columns,
      {
        title: "DR COUNT",
        dataKey: "DR COUNT"
      },
      {
        title: "AVG DEPTH",
        dataKey: "AVG DEPTH"
      },
      {
        title: "WKS REG",
        dataKey: "WEEKS REG"
      },
      {
        title: "ST%",
        dataKey: "ST%"
      },
      {
        title: "APS",
        dataKey: "APS"
      }
    ];
    var table_columns = columns.map(item => item.title);
    let hisParamData = cloneDeep(this.props.historicalParameterData);
    hisParamData.forEach(ele => {
      doc.autoTable(
        [`Class-Subclass: ${ele["class/vendor"]}-${ele["subclass"]}`],
        [],
        {
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            halign: "left"
          },
          // margin: { top: 50},
          styles: { overflow: "linebreak", fontSize: 30 }
        }
      );

      var table_rows = [];
      const rows = ele.styleLevel;
      for (let i = 0; i < table_columns.length; i++) {
        for (let j = 0; j < rows.length; j++) {
          let row_list = [];
          if (columns[i].dataKey === "AVG DEPTH") {
            rows[j][columns[i].dataKey] = toUnit({
              value: rows[j][columns[i].dataKey]
            });
          }
          if (
            typeof rows[j][columns[i].dataKey] === "number" &&
            !Number.isInteger(rows[j][columns[i].dataKey])
          ) {
            rows[j][columns[i].dataKey] = roundToTwoDecimal({
              value: rows[j][columns[i].dataKey]
            });
          }
          if (columns[i].dataKey === "ST%") {
            rows[j][columns[i].dataKey] = toPercentage({
              value: rows[j][columns[i].dataKey]
            });
          }
          if (
            columns[i].dataKey === "rcpt_aur" ||
            columns[i].dataKey === "total$"
          ) {
            rows[j][columns[i].dataKey] = toDollar({
              value: rows[j][columns[i].dataKey]
            });
          }
          row_list.push(rows[j][columns[i].dataKey]);
          if (table_rows[j] && table_rows[j].length !== 0) {
            table_rows[j] = [...table_rows[j], ...row_list];
          } else {
            table_rows[j] = row_list;
          }
        }
      }
      doc.autoTable(table_columns, table_rows, {
        headStyles: {
          fillColor: [45, 105, 235],
          textColor: [255, 255, 255],
          halign: "center"
        },
        margin: { bottom: 100 },
        willDrawCell: function(cell, data) {
          if (
            (cell.column.raw.startsWith("CHOICE COUNT") ||
              cell.column.raw.startsWith("CL") ||
              cell.column.raw.startsWith("ECOM")) &&
            cell.row.section === "body"
          ) {
            if (Math.round(cell.cell.raw) <= 0) {
              doc.setFillColor(242, 142, 131);
            } else {
              doc.setFillColor(187, 237, 192);
            }
          }
        },
        styles: { overflow: "linebreak", fontSize: 20 },
        theme: "grid"
      });
    });
    doc.save("Assortment Wedge.pdf");
  };
  onSubmit = () => {
    const classSubclassKey = `${this.state.class.value}-${this.state.subClass.value}`;
    this.props.setSelectedAutoAPXClass(this.props.month, classSubclassKey);
    // console.log(
    //   "this.props.historicalParameterData",
    //   this.props.historicalParameterData
    // );
    let cardData = this.props.historicalParameterData.filter(
      // eslint-disable-next-line array-callback-return
      obj => {
        if (
          this.state.class.value === obj["class/vendor"] &&
          this.state.subClass.value === obj.subclass
        ) {
          return obj;
        }
      }
    );
    var pinTopRow = [];
    if (this.props.clusterData && this.props.clusterData.length) {
      const clusterDoors = { ...this.props.clusterData[0] };
      this.props.clusters.forEach(cluster => {
        clusterDoors[`v_${cluster}`] = clusterDoors[cluster];
      });
      pinTopRow.push(clusterDoors);
    }
    let tempArr = [];
    let tempObj = {};
    if (cardData[0] && cardData[0].styleLevel) {
      tempObj = this.calFirstRowOfSummary(
        cardData[0].styleLevel,
        cardData[0]["TY ST%"]
      );
      this.setState({
        depthVarianceTable: {
          ...this.state.depthVarianceTable,
          [classSubclassKey]: {
            ...this.state.depthVarianceTable[classSubclassKey],
            ...tempObj
          }
        }
      });
      if (tempObj) {
        tempObj.CC = this.props.isSeasonLevel
          ? cardData[0].styleLevel.length / this.props.rcptMonths.length
          : cardData[0].styleLevel.length;
        tempObj.category = "";
        tempObj.subclass = cardData[0].styleLevel[0].subclass;
        tempObj.rcpt_aur = cardData[0].styleLevel[0].rcpt_aur;
        tempObj["ST%"] = cardData[0]["TY ST%"];
        // tempArr.push(tempObj);
        pinTopRow.push(tempObj);
      }
      tempArr.push(...cardData[0].styleLevel);
    }
    this.setState(
      {
        selectedSubClassName: this.state.subClass.value,
        styleLevelTopRowData: cardData[0] && pinTopRow,
        subClassStyleRowData: cardData[0] && tempArr,
        depthAndChoiceRowData: cardData[0] && cardData[0].depthAndChoiceData
      },
      () => {
        this.props.hasAttributeClustering && this.generateAttrChart();
      }
    );
  };
  onInputMassAppy = () => {
    function RoundTo(number, roundto) {
      return roundto * Math.round(number / roundto);
    }
    let firstRow = this.state.minMaxTopFixedRow;
    if (this.state.minMaxEnteredKeys.length) {
      let histParaData = cloneDeep(this.props.historicalParameterData);
      for (const iterator of this.state.minMaxEnteredKeys) {
        histParaData.forEach(row => {
          if (row.hasOwnProperty(iterator)) {
            if (
              iterator === "INCREMENTS ECOM" ||
              iterator === "INCREMENTS B&M"
            ) {
              row[iterator] = +firstRow[0][iterator];
              if (iterator === "INCREMENTS ECOM") {
                row["MAX_ECOM"] = RoundTo(row["MAX_ECOM"], row[iterator]);
                row["MIN ECOM"] = RoundTo(row["MIN ECOM"], row[iterator]);
              }
              if (iterator === "INCREMENTS B&M") {
                row["MAX B&M"] = RoundTo(row["MAX B&M"], row[iterator]);
              }
            } else {
              row[iterator] = +firstRow[0][iterator];
            }
          }
        });
      }
      //Trasforming Historical parameter data into key value object
      const historicalParametersTransformObj = {};
      histParaData.forEach(respectiveObj => {
        historicalParametersTransformObj[
          `${respectiveObj["class/vendor"]}-${respectiveObj["subclass"]}`
        ] = respectiveObj;
      });
      histParaData = historicalParametersTransformObj;
      this.props.setHistoricalParamDataOnApplyAll(
        this.props.month,
        histParaData
      );
      this.setState({ minMaxEnteredKeys: [] });
    }
  };
  onResetMassApplyInput = () => {
    this.props.handleReset();
  };
  setValidityRadioValue = val => {
    this.setState(
      {
        eligibilityToggle: val
      },
      () => {
        this.gridApi.setColumnDefs(this.getColDefForSubClassLevelStyles());
      }
    );
  };
  scaleProportions = () => {
    const {
      rcptMonths,
      historicalParameterData,
      recalculateSubclassStyles
    } = this.props;
    for (const histParmObj of historicalParameterData) {
      const classSubclassKey = `${histParmObj["class/vendor"]}-${histParmObj["subclass"]}`;
      const proportionTotal = rcptMonths.reduce(
        (acc, mo) => acc + histParmObj[mo],
        0
      );
      rcptMonths.forEach(month => {
        histParmObj[month] = (histParmObj[month] / proportionTotal) * 100;
      });
      recalculateSubclassStyles("Seasonal", classSubclassKey, true);
    }
    this.onSubmit();
  };
  onHandleAttrChange = item => {
    this.setState({
      selectedAttr: item
    });
  };
  render() {
    const { rcptMonths } = this.props;
    return (
      <Fragment>
        <div className="card-header-background">
          <strong>
            {this.state.classOrVendorLabel} Level Total Choice Count and Average
            Depth Count
          </strong>
        </div>
        <div style={{ height: "250px" }}>
          <DataTable
            // sizeToFit={true}
            rowData={this.state.depthAndChoiceRollUps}
            columnDefs={this.getColDefForAvgDepth_AvgChoice()}
            defaultExpand={true}
            resizable={true}
            defaultColDef={{
              resizable: true
            }}
            groupColDef={{
              headerName: this.state.classOrVendorLabel,
              menuTabs: []
            }}
          />
        </div>
        <div className="card-header-background mt-4">
          <strong>Variation with respective to 2-3 screen</strong>
        </div>
        <div style={{ height: "250px" }}>
          <DataTable
            rowData={Object.values(this.state.depthVarianceTable)}
            columnDefs={this.getColumnDefsForDepthVarianceTable()}
            defaultExpand={true}
            resizable={true}
            defaultColDef={{
              resizable: true
            }}
            groupDefaultExpanded={false}
            groupColDef={{
              headerName: "SUB GRP",
              filter: "agTextColumnFilter",
              width: 200,
              field: "subclass",
              menuTabs: [],
              cellRenderer: "agGroupCellRenderer"
            }}
          />
        </div>
        <div className="card-header-background mt-4">
          <strong>Filters</strong>
        </div>
        <div className="row p-2 flexEnd">
          <div className="col-md-3">
            <label>Select {this.state.classOrVendorLabel}</label>
            <Select
              placeholder={`Select ${this.state.classOrVendorLabel}`}
              value={this.state.class}
              onChange={val => this.onClassChange(val)}
              options={this.state.classOrVendorOptions}
              isClearable
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="inputPassword4">Select Sub Grp</label>
            <Select
              isDisabled={!this.state.class}
              value={this.state.subClass}
              onChange={val => this.onSubClassChange(val)}
              options={this.state.subClassOptions}
              isClearable
            />
          </div>
          <div
            className="col-md-3"
            align="right"
            style={{ display: "flex", alignSelf: "flex-end" }}
          >
            <Button
              disabled={
                !(
                  this.state.class &&
                  this.state.class.value &&
                  this.state.subClass &&
                  this.state.subClass.value
                )
              }
              size="medium"
              style={{ textTransform: "capitalize", width: "50%" }}
              onClick={this.onSubmit}
            >
              Submit
            </Button>
          </div>

          <div className="col-md-3" style={{ alignSelf: "flex-end" }}>
            <Button
              size="small"
              style={{ textTransform: "capitalize", float: "right" }}
              onClick={this.props.downloadFile}
            >
              Save &amp; Download Wedge
            </Button>

            <Button
              size="small"
              style={{ textTransform: "capitalize", float: "right" }}
              onClick={this.downloadPdfFile}
            >
              Download Wedge in PDF
            </Button>
          </div>
        </div>
        <Modal
          visible={this.state.depthMultiplierModal}
          width="65%"
          height="30%"
          effect="fadeInUp"
          onClickAway={() => this.closeDepthMultiplierModal()}
        >
          <Card>
            <CardHeader style={{ background: "#2D69EB" }}>
              <strong>Depth Multiplier</strong>
              <Tippy
                placement={"left"}
                arrow={true}
                distance={10}
                content="Don't use the default values. This table spreads the average depth across choices based on LY performance"
              >
                <i
                  style={{
                    color: "#fff",
                    fontSize: "16px"
                  }}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                />
              </Tippy>
            </CardHeader>
            <CardBody>
              <div style={{ height: "115px" }}>
                <DataTable
                  sizeToFit={true}
                  defaultColDef={goodBestBetterColumnDefs.defaultColDef}
                  onCellValueChanged={
                    this.onCellValueChangedForGoodBetterBestTable
                  }
                  rowData={
                    this.props.goodBetterBestTable[
                      this.state.class && this.state.class.label
                    ]
                  }
                  columnDefs={goodBestBetterColumnDefs.columnDefs(
                    this.props.userAccessDisableFlag
                  )}
                />
              </div>
            </CardBody>
          </Card>
        </Modal>
        <div align="center" className="mt-3" style={{ marginBottom: "1rem" }}>
          <Button onClick={this.handleMinMaxTable}>
            Review Min Max Values
          </Button>
          <Button onClick={this.handleDepthMultiplierModal}>
            Depth Multiplier
          </Button>
          {this.props.isSeasonLevel && (
            <Button onClick={this.handleMonthlyProportions}>
              Review Monthly Proportions
            </Button>
          )}
        </div>
        <Modal
          visible={this.state.minMaxTableModalVisible}
          width="65%"
          height="50%"
          effect="fadeInUp"
          onClickAway={() => this.closeMinMaxTableModal()}
        >
          <Card>
            <CardHeader style={{ background: "#2D69EB" }}>
              Review Min Max
            </CardHeader>
            <CardBody>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div align="right">
                  <Button onClick={this.onInputMassAppy}>Apply all</Button>
                </div>
                <div align="right">
                  <Button onClick={this.onResetMassApplyInput}>Reset</Button>
                </div>
              </div>
              <div style={{ height: "245px" }}>
                <DataTable
                  defaultColDef={{
                    resizable: true
                  }}
                  onCellValueChanged={this.onCellValueChangedOnMinMax}
                  columnDefs={this.getColDefForMinMaxTable()}
                  rowData={this.state.minMaxRowData}
                  pinnedTopRowData={this.state.minMaxTopFixedRow}
                />
              </div>
            </CardBody>
          </Card>
        </Modal>
        <Modal
          visible={this.state.reviewMonthlyProportionsModal}
          width="65%"
          height="50%"
          effect="fadeInUp"
          onClickAway={() => this.closeMonthlyProportionsModal()}
        >
          <Card>
            <CardHeader style={{ background: "#2D69EB" }}>
              {" "}
              Review Monthly Proportions
            </CardHeader>
            <CardBody>
              <div style={{ height: "245px" }}>
                <DataTable
                  defaultColDef={{
                    resizable: true
                  }}
                  onCellValueChanged={this.onCellValueChangedProportions}
                  columnDefs={monthProportionColDefs(
                    rcptMonths,
                    this.props.lyFlag
                  )}
                  rowData={Object.values(this.props.historicalParameterData)}
                />
              </div>
              <div className="t-a-r mt-2">
                <Button onClick={this.scaleProportions}>Scale Up/Down</Button>
              </div>
            </CardBody>
          </Card>
        </Modal>

        <div className="card-header-background mt-4">
          <strong>
            {this.state.selectedSubClassName
              ? this.state.selectedSubClassName
              : "Sub - Class"}
          </strong>
        </div>
        <div
          className="mt-4"
          style={{
            height: "115px",
            left: "480px",
            position: "relative",
            width: "calc(100% - 480px)"
          }}
          // style={{ height: "115px !important", width: "50%", margin: "auto" }}
        >
          <DataTable
            defaultColDef={{
              resizable: true
            }}
            rowData={this.state.depthAndChoiceRowData}
            columnDefs={this.getColDefForDepthAndChoiceCountTable()}
          />
        </div>
        {this.props.isSeasonLevel && (
          <div>
            <ToggleButtonGroup
              type="radio"
              name="options"
              value={this.state.eligibilityToggle}
              onChange={this.setValidityRadioValue}
            >
              <ToggleButton value="eligibility">Eligibility</ToggleButton>
              <ToggleButton value="depth">Depth</ToggleButton>
            </ToggleButtonGroup>{" "}
          </div>
        )}
        <div
          className="mt-4"
          style={{ height: "calc(100vh - 500px)", minHeight: "300px" }}
        >
          <DataTable
            defaultColDef={{
              resizable: true
            }}
            onCellValueChanged={this.onCellValueChangedSubClassLevelStyles}
            rowData={
              this.state.subClassStyleRowData
                ? this.state.subClassStyleRowData
                : []
            }
            defaultExpand={true}
            passGridAPI={api => {
              this.gridApi = api;
            }}
            pinnedTopRowData={this.state.styleLevelTopRowData}
            columnDefs={
              this.state.selectedSubClassName
                ? this.getColDefForSubClassLevelStyles(
                    this.props.historicalParameterData &&
                      this.props.historicalParameterData.length
                      ? this.props.historicalParameterData
                      : [],
                    this.state.selectedSubClassName
                  )
                : []
            }
            legends={[{ color: "#f28e83", text: "Depth zero" }]}
            groupRowAggNodes={styleLevelGroupAggregation(
              this.props.clusters,
              this.props.hasAttributeClustering
            )}
            groupDefaultExpanded={false}
          />
        </div>
        {this.props.hasAttributeClustering ? (
          <AttributeGraph
            graphData={this.state.graphData}
            selectedAttr={this.state.selectedAttr}
            attrOptions={this.state.attrOptions}
            onHandleAttrChange={this.onHandleAttrChange}
            lyFlag={this.props.lyFlag}
          />
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    projectId: store.projectReducer.projectId
  };
};

const mapActionsToProps = {
  setSelectedAutoAPXClass: AssortmentPlanningActions.setSelectedAutoAPXClass,
  setHistoricalParamDataOnApplyAll:
    AssortmentPlanningActions.setHistoricalParamDataOnApplyAll,
  setHistoricalParamSubClassData:
    AssortmentPlanningActions.setHistoricalParamSubClassData,
  recalculateSubclassStyles:
    AssortmentPlanningActions.recalculateSubclassStyles,
  recalculateMinMaxSubclassStyles:
    AssortmentPlanningActions.recalculateMinMaxSubclassStyles,
  getOmniValues: AssortmentPlanningActions.getOmniValues
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(Step5AutoApx));

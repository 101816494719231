import React, { Component } from "react";
import { connect } from "react-redux";
import DataTable from "../../../services/dataTable";
import BuyerInputStyleLevelColumnDefs from "../colDefs/StyleLevelColumnDefs.js";
import "../index.css";

class BuyerInputStyleLevel extends Component {
  onCellValueChanged = val => {
    switch (val.colDef.field) {
      case "Style_Id":
        val.data.Style_Id = Number(val.value);
        break;
      case "Production_min":
        val.data.Production_min = Number(val.value);
        break;
      case "moq":
        val.data.moq = Number(val.value);
        break;
      case "surcharge":
        val.data.surcharge = Number(val.value);
        break;
      default:
        break;
    }
    // BuyerInputStyleLevelColumnDefs.rowData[val.rowIndex] = val.data;
    this.props.boInputDataStyleLevel[val.rowIndex] = val.data;
    val.api.refreshCells();
  };
  render() {
    return (
      <div style={{ height: "calc(100vh - 500px)", minHeight: "300px" }}>
        <DataTable
          sizeToFit={true}
          defaultExpand={true}
          height1="calc(100vh - 300px)"
          columnDefs={BuyerInputStyleLevelColumnDefs.columnDefs(this.props.userDisableAccess)}
          defaultColDef={BuyerInputStyleLevelColumnDefs.defaultColDef}
          rowData={this.props.data}
          onCellValueChanged={this.onCellValueChanged}
        />
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    "BuyerInputStyleLevelColumnDefs.rowData": store.mainReducer.buyTable
  };
};

export default connect(mapStateToProps)(BuyerInputStyleLevel);

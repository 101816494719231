import React, { Component } from "react";
import store from "../../store";

export default class ChildMessageRenderer extends Component {
  constructor(props) {
    super(props);
    this.tableData = store.getState().state.buyOptimization.Item_Level_Summary;
    this.state = { itemLevelValue: props.value };
  }

  handleChange = event => {
    this.setState({ itemLevelValue: event.target.value });
    this.props.data.Action = event.target.value;
    store.getState().buyOptimizationReducer.buyOptimization.Item_Level_Summary[
      this.props.rowIndex
    ] = this.props.data;
    // val.api.refreshCells()
  };

  render() {
    return (
      <select value={this.state.itemLevelValue} onChange={this.handleChange}>
        <option value="algorithmOptimized">Algorithm Optimized</option>
        <option value="buyerProposed">Buyer Proposed</option>
      </select>
    );
  }
}

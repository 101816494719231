export const PRODUCTION_ENDPOINT = "/api";

export const DEVELOPMENT_ENDPOINT = "/api";

// General : "/api"
// Local : "http://localhost:5000/api"
// Dev : "https://belk-as-dev.iaproducts.ai/api"
// Stage:"https://belk-as-staging.iaproducts.ai/api"
// prod : "https://belk-assortment-suite.iaproducts.ai/api"
// month-level p2: "https://belk-as-dev-p2.iaproducts.ai/api"

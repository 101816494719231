const inputCss = {
  "background-color": "#BBEDC0",
  "border-left": "1px solid grey",
  "border-right": "1px solid grey",
  "text-align": "right"
};

const errorInput = {
  "background-color": "#f28e83",
  "border-left": "1px solid grey",
  "border-right": "1px solid grey",
  "text-align": "right"
};

const tar = {
  "text-align": "right"
};
const tac = {
  "background-color": "#BBEDC0",
  "border-left": "1px solid grey",
  "border-right": "1px solid grey"
};
export { inputCss, errorInput, tar, tac };

import { tar } from "../../../../styles";
import { toUnit } from "../../../../helpers/formatters";

export default function classLevelDepthAndChoiceColumnDefs(
  clusterData,
  clusters
) {
  let tempObj = [];
  tempObj.push({
    headerName: "",
    width: 140,
    field: "key",
    menuTabs: []
  });
  if (clusterData.length && clusters.includes("0")) {
    tempObj.push({
      headerName: "ECOM",
      menuTabs: [],
      width: 85,
      valueFormatter: toUnit,
      field: "0",
      sortable: true,
      unSortIcon: true
      // cellStyle: params => {
      //   return inputCss;
      // },
      // editable: true
    });
    clusters.forEach(cluster => {
      if (cluster !== "0") {
        tempObj.push({
          headerName: `CL ${cluster}`,
          sortable: true,
          menuTabs: [],
          width: 85,
          cellStyle: tar,
          valueFormatter: toUnit,
          unSortIcon: true,
          // editable: true,
          // cellStyle: params => {
          //   return inputCss;
          // },
          field: `${cluster}`
        });
      }
    });
  } else {
    clusters.forEach(cluster => {
      tempObj.push({
        headerName: `CL ${cluster}`,
        sortable: true,
        menuTabs: [],
        cellStyle: tar,
        width: 85,
        valueFormatter: toUnit,
        unSortIcon: true,
        // editable: true,
        // cellStyle: params => {
        //   return inputCss;
        // },
        field: `${cluster}`
      });
    });
  }
  return tempObj;
}

import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import Select from "../../../services/selectAll";
import DataTable from "../../../services/dataTable";
import Button from "@material-ui/core/Button";
import columnDefs from "./columnDefs";

class MannualClustering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reassignedCluster: null,
      isNewCluster: false,
      attrCluster: null
    };
  }

  generateAttrOptions = () => {
    const attrOptions = ["A", "B", "C", "D", "E", "F", "G"];
    const { optimalAttributeClusters } = this.props;
    const options = [];
    for (let i = 0; i < optimalAttributeClusters; i++) {
      const value = attrOptions[i];
      options.push({ value, label: value });
    }
    return options;
  };

  disableAddStores = () => {
    const isAttrClusSelected =
      this.state.attrCluster && this.state.attrCluster.value;

    const isReAssignClusSelected =
      this.state.reassignedCluster && this.state.reassignedCluster.value;

    if (this.state.isNewCluster) {
      if (this.props.hasAttributeClustering) {
        return !isAttrClusSelected;
      }
      return false;
    } else {
      if (this.props.hasAttributeClustering) {
        return !(isReAssignClusSelected && isAttrClusSelected);
      } else {
        return !isReAssignClusSelected;
      }
    }
  };

  render() {
    return (
      <Card>
        <CardHeader
          style={{ background: "#2D69EB", padding: "0.25rem 0.25rem" }}
        >
          <i
            className="faactions fa fa-plus-circle p-2"
            style={{ fontSize: "1.25rem" }}
          />
          Add Stores/Clusters
        </CardHeader>
        <CardBody>
          <LoadingOverlay
            // active={this.state.modalLoader}
            spinner
          >
            <div className="addExisting-container-filter">
              <form>
                <div className="form-row">
                  <div className="form-group px-1 col-md-4">
                    <FormGroup check style={{ paddingTop: "0.5rem" }}>
                      <Input
                        type="radio"
                        name="assignStoreToCluster"
                        checked={!this.state.isNewCluster}
                        onChange={() => this.setState({ isNewCluster: false })}
                      />
                      <Label check style={{ marginLeft: "1rem",width: "12rem" }}>
                        Add Unassigned Stores to an existing cluster
                      </Label>
                      {!this.state.isNewCluster && (
                        <div style={{width: "15rem"}}>
                        <Select
                          placeholder="Select Cluster"
                          value={this.state.reassignedCluster}
                          options={Array(this.props.userSelectedClusters)
                            .fill(0)
                            .map((e, i) => {
                              return { value: i + 1, label: i + 1 };
                            })}
                          onChange={val =>
                            this.setState({ reassignedCluster: val })
                          }
                        />
                        </div>
                      )}
                    </FormGroup>
                  </div>
                  {this.props.userSelectedClusters < 15 && (
                    <div className="form-group px-1 col-md-4">
                      <FormGroup check style={{ paddingTop: "0.5rem" }}>
                        <Input
                          type="radio"
                          name="assignStoreToCluster"
                          disabled={
                            this.props.optimalAttributeClusters *
                              (this.props.userSelectedClusters + 1) >
                            15
                          }
                          checked={this.state.isNewCluster}
                          onChange={() => this.setState({ isNewCluster: true })}
                        />
                        <Label check style={{ marginLeft: "1rem",width: "12rem"  }}>
                          Add Unassigned Stores to new cluster (Cluster
                          {this.props.userSelectedClusters + 1})
                        </Label>
                        {this.props.hasAttributeClustering && (
                          <div style={{width: "15rem"}}>
                          <Select
                            placeholder="Select Attribute Cluster"
                            value={this.state.attrCluster}
                            options={this.generateAttrOptions()}
                            onChange={val =>
                              this.setState({ attrCluster: val })
                            }
                          />
                          </div>
                        )}
                      </FormGroup>
                    </div>
                  )}
                </div>
              </form>
              <hr style={{ margin: 0, borderTop: "1px solid #cdc6c6" }} />
            </div>
            <div
              className="addExisting-container-body"
              style={{ height: "calc(100vh - 300px)" }}
            >
              <div
                className="pt-2"
                style={{
                  width: "65%",
                  margin: "auto",
                  height: "calc(100vh - 375px)"
                }}
              >
                <DataTable
                sizeToFit={true}
                  columnDefs={
                    columnDefs(false, false, true).unassignedStoresColumnDefs
                  }
                  rowData={this.props.clusterResultsStoreLevel}
                  passGridAPI={api => {
                    this.gridApi = api;
                  }}
                  floatingFilter={true}
                  rowSelection="multiple"
                />
              </div>
            </div>
            <div align="right">
              <Button
                onClick={() => {
                  this.props.changeCluster(
                    this.state.isNewCluster,
                    this.gridApi.getSelectedRows().map(e => e),
                    this.state.reassignedCluster,
                    this.state.attrCluster && this.state.attrCluster.value
                  ); this.props.calculateAggregate() }
                }
                disabled={this.disableAddStores()}
              >
                Add
              </Button>
            </div>
          </LoadingOverlay>
        </CardBody>
      </Card>
    );
  }
}

export default MannualClustering;

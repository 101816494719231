import { toUnit, toPercentage } from "../../../helpers/formatters";
import { tar } from "./inputCss";
export default {
  defaultColDef: {
    animateRows: true,
    resizable: true,
    menuTabs: []
  },
  columnDefs: [
    {
      headerName: "",
      field: "metric",
      width: 157
    },
    {
      headerName: "TOTAL BUY UNITS",
      valueFormatter: function(params) {
        if (params.data.metric === "%Dev From Planned Budget") {
          return toPercentage(params);
        } else {
          return toUnit(params);
        }
      },
      cellStyle: tar,
      field: "totalBuyUnits",
      sortable: true,
      unSortIcon: true,
      width: 113
    },
    {
      headerName: "TOTAL BUY COST",
      valueFormatter: function(params) {
        if (params.data.metric === "%Dev From Planned Budget") {
          return toPercentage(params);
        } else {
          return toUnit(params);
        }
      },
      cellStyle: tar,
      field: "totalBuyCost",
      width: 113,
      sortable: true,
      unSortIcon: true
    }
  ]
};

// import { percentageFormatter } from '../../helpers/formatters'
import { inputCss } from "../../../styles";

export default {
  defaultColDef: {
    resizable: true,
    menuTabs: [],
    //   filter: true,
    sortable: true,
    unSortIcon: true
  },
  columnDefs: function(userDisableAccess) {
    return [
      {
        headerName: "STYLE # ",
        width: 131,
        editable: !userDisableAccess && true,
        field: "Style_Id",
        cellStyle: inputCss
      },
      {
        resizable: true,
        headerName: "PROD MIN",
        width: 127,
        cellEditor: "numericCellEditor",
        editable: !userDisableAccess && true,
        field: "Production_min",
        cellStyle: inputCss
      },
      {
        resizable: true,

        editable: !userDisableAccess && true,
        headerName: "MOQ",
        width: 72,
        cellEditor: "numericCellEditor",
        field: "moq",
        cellStyle: inputCss
      },
      {
        resizable: true,
        cellEditor: "numericCellEditor",
        editable: !userDisableAccess && true,
        // valueFormatter: percentageFormatter,
        headerName: "SURCHARGE %",
        cellStyle: inputCss,
        field: "surcharge %",
        width: 126
      }
    ];
  }
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import DataTable from "../../services/dataTable";
import HindsightColDefs from "./colDefs";
import userRoles from "../../constants/userRoles";
import Alert from "../../services/alert";
import Tippy from "@tippy.js/react";

import projectActions from "../../actions/ProjectActions";
class HindsightDashboard extends Component {
  state = {
    hindsightProjects: [],
    userType: window.localStorage.getItem("userType"),
    isClusterDeleteVisible: false
  };
  componentDidMount() {
    this.props.getHindsightProjects().then(resp => {
      if (resp) {
        let hindsightCluster = resp && resp.data && resp.data.map(rowData1 => {
          if(rowData1.ecommFlag) {
            rowData1.ecommFlag = "Y"
          } else {
            rowData1.ecommFlag = "N"
          }
          return rowData1;
        })
        this.setState({
          hindsightProjects: hindsightCluster
        });
      }
    });
  }

  onCellClicked = event => {
    if (event.colDef.headerName === "CLUSTER NAME") {
      if (event && event.data && event.data.id) {
        this.props.history.push(`/hindsightCluster/${event.data.id}`);
      }
    }
  };
  onCreatePlan = () => {
    this.props.history.replace(`/hindsightCluster`);
  };
  onDeleteHindsightCluster = () => {
    this.gridApi.getSelectedRows().length &&
      this.setState({
        isClusterDeleteVisible: true
      });
  };
  handleAccept = () => {
    var selectedIds = [];
    this.gridApi.getSelectedRows().forEach(hindsightRow => {
      selectedIds.push(hindsightRow.id);
      this.state.hindsightProjects.forEach((hindsihghtClusterObj, index) => {
        if (hindsightRow.id === hindsihghtClusterObj.id) {
          this.state.hindsightProjects.push(
            this.state.hindsightProjects.splice(index, 1)[0]
          );
        }
      });
    });
    this.setState({
      isClusterDeleteVisible: false
    });
    let payload = {
      projectIds: [],
      clusterIds: selectedIds
    };
    this.props.deleteProjectOrClusterById(payload).then(resp => {
      resp &&
        this.setState({
          hindsightProjects: resp.data
        });
    });
  };
  render() {
    return (
      <div className="card">
        <div className="cardHeaderStep4 dashboardLabelAndButton">
          <label style={{ paddingTop: "0.95rem" }}>Hindsight Cluster Dashboard</label>
          {userRoles[this.state.userType]["1"]["1"].WRITE && (
            <div className="actionSectionRightAligned">
              <span style={{ paddingTop: "0.95rem", fontSize: "14px" }}>
                Actions:{" "}
              </span>
              <Button
                style={{ width: "12rem" }}
                size="medium"
                onClick={this.onCreatePlan}
              >
                Create Cluster Plan
              </Button>
              <Tippy
                placement={"top"}
                arrow={true}
                distance={10}
                content={"Please select a Cluster Pan to use this option"}
              >
                <Button
                  style={{ width: "5rem" }}
                  size="medium"
                  onClick={() => this.onDeleteHindsightCluster()}
                >
                  Delete
                </Button>
              </Tippy>
            </div>
          )}
        </div>
        <div className="card-body">
          <div
            style={{
              height: "calc(100vh - 200px)"
            }}
          >
            <DataTable
              floatingFilter={true}
              sizeToFit={true}
              suppressColumnVirtualisation={true}
              columnDefs={HindsightColDefs.columnDefs}
              defaultColDef={HindsightColDefs.defaultColDef}
              rowData={this.state.hindsightProjects}
              onCellClicked={this.onCellClicked}
              rowSelection="multiple"
              passGridAPI={api => {
                this.gridApi = api;
              }}
            />
          </div>
          <Alert
            open={this.state.isClusterDeleteVisible}
            title="Alert"
            isResetRequired={false}
            content="Are you sure you want to delete the hindsight cluster(s)?"
            handleAccept={this.handleAccept}
            actionButtonText="Yes"
            cancelButtonText="No"
            handleClose={() => {
              this.setState({
                isClusterDeleteVisible: false
              });
            }}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getHindsightProjects: projectActions.getHindsightProjects,
  deleteProjectOrClusterById: projectActions.deleteProjectOrClusterById
};

export default connect(null, mapDispatchToProps)(HindsightDashboard);

import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import userRoles from "../../constants/userRoles";
import { uniqBy } from "lodash";

// import createAssortment from "../../actions/ClusteringActions";
import projectActions from "../../actions/ProjectActions";
import commonActions from "../../actions";
import buyOptimizationAction from "../../actions/BuyOptimizationActions";

import AssortmentPlanning from "../AssortmentPlanning";
import BuyOptimization from "../BuyOptimization";
import Clustering from "../Clustering";
import FloatingComponent from "../FloatingComponent";

import "./TabContainer.css";

let tabArr = ["clustering", "assortment", "optimization"];
class TabContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: window.localStorage.getItem("userType"),
      selectedTabNum: 0,
      intitialActiveStep: 0,
      isClusterDisabled: false
    };
  }
  componentDidMount() {
    if (this.props.match.params.id) {
      this.props
        .getProjectById({ projectId: this.props.match.params.id })
        .then(resp => {
          if (resp.success) {
            const mainStage = Number(resp.data.projectStage.split("-")[0]) - 1;
            const subStage = Number(resp.data.projectStage.split("-")[1]) - 1;

            /**
             * If user is on intelligent clustering,
             * we need project names to validate,
             * so move the user to dashboard.
             *
             * Right now commented, will check in future.
             */
            // if (mainStage === 0) {
            //   if (!this.props.projectsList) {
            //     this.props.history.replace("/dashboard");
            //   }
            // }
            this.setState({
              selectedTabNum: mainStage,
              intitialActiveStep: subStage
            });
            if (parseInt(resp.data.projectStage.split("-")[0]) > 1) {
              this.setState({
                isClusterDisabled: true
              });
            }
          }
        });
    } else {
      if (!this.props.projectsList) {
        this.props.history.replace("/stepperContainer");
      }
    }
  }
  componentDidUpdate(oldProps, oldState) {
    if (oldProps !== this.props) {
      if (oldProps.projectMainStage !== this.props.projectMainStage) {
        this.setState({
          isClusterDisabled: this.props.projectMainStage === 1 ? false : true
        });
      }
    }
  }
  componentWillUnmount() {
    this.props.clearData();
  }
  onSelectTab = val => {
    this.setState({
      selectedTabNum: tabArr.indexOf(val),
      intitialActiveStep:
        this.props.isBuyOptSkipped && val === "optimization" ? 2 : 0
    });
  };
  saveProjectStage = (activeTab, activeStep, projectId, isSubmitted) => {
    // if (
    //   activeTab !== 1 ||
    //   ((activeTab === this.props.projectMainStage && activeStep > this.props.projectSubStage) ||
    //     (activeTab > this.props.projectMainStage && activeStep === 1))
    // ) {
    var saveStage = this.props.callSaveProjectStage({
      stage: `${activeTab}-${activeStep}`,
      projectId: projectId || this.props.projectId,
      isSubmitted: isSubmitted || false
    });
    return saveStage;
    // } else {
    //   return new Promise((resolve, reject) => {
    //     resolve({ success: true });
    //   });
    // }
  };
  updateActiveTab = (activeTab, activeStep) => {
    this.setState({
      selectedTabNum: activeTab,
      intitialActiveStep: activeStep - 1
    });
  };

  runOmniBuyAlgo = (skipBuy, stateOmniIncr, stateOmniRcpt) => {
    let payload1 = {
      projectId: this.props.match.params.id,
      omniIncr: stateOmniIncr,
      omniRcpt: stateOmniRcpt,
      isBuyOptSkipped: skipBuy
    };
    return this.props.runOmniAlgo(payload1).then(res => {
      if (res.success) {
        if (
          this.props.lineReceiptlevel !== "class" &&
          this.props.selectedFilters &&
          this.props.selectedFilters.hasOwnProperty("vendors")
        ) {
          let getUniqClassVendors = uniqBy(
            res.data.data_to_be_saved,
            "class_vendor"
          );
          let vendorFilters =
            this.props.selectedFiltersTarget &&
            this.props.selectedFiltersTarget.vendors
              ? this.props.selectedFiltersTarget.vendors
              : this.props.selectedFilters.vendors;
          let filteredVendorData = [];
          getUniqClassVendors.forEach(vendorObj => {
            vendorFilters.forEach(vendorFilterObj => {
              if (vendorObj["class_vendor"].includes(vendorFilterObj.label)) {
                if (!res.data.is_fc_approved) {
                  vendorFilterObj.action = "Store Only";
                } else {
                  vendorFilterObj.action = "FC Approved";
                }
                filteredVendorData.push(vendorFilterObj);
              }
            });
          });
          let payload2 = {
            projectId: this.props.match.params.id,
            data: filteredVendorData.map(item => {
              return {
                action: item.action,
                vendor_id: item.value,
                vendor_desc: item.label
              };
            })
          };
          this.props.setVendorForFcBuy(payload2).then(resp1 => {
            if (resp1.success) {
              this.saveProjectStage(3, 3).then(resp2 => {
                if (resp2.success) {
                  this.setState({
                    selectedTabNum: 2,
                    intitialActiveStep: 2
                  });
                  this.props.callSaveSkipBuyFlag(skipBuy);
                }
              });
            }
          });
        } else {
          this.saveProjectStage(3, 3).then(resp2 => {
            if (resp2.success) {
              this.setState({
                selectedTabNum: 2,
                intitialActiveStep: 2
              });
              this.props.callSaveSkipBuyFlag(skipBuy);
            }
          });
        }
      }
      this.props.setOmniSalesPlanIncrement(stateOmniIncr)
      return res;
    });
  };
  render() {
    return (
      <div className="MainTabConatiner">
        <div>
          {this.props.projectId ? (
            <FloatingComponent data={this.props.projectDetails} />
          ) : null}
        </div>

        <Tabs
          activeKey={tabArr[this.state.selectedTabNum]}
          onSelect={this.onSelectTab}
          transition={false}
          id="noanim-tab-example"
        >
          <Tab eventKey="clustering" title="Intelligent Clustering">
            {this.state.selectedTabNum === 0 && (
              <Clustering
                userAccess={userRoles[this.state.userType]["1"]}
                isClusterDisabled={this.state.isClusterDisabled}
                intitialActiveStep={this.state.intitialActiveStep}
                updateActiveTab={this.updateActiveTab}
                saveProjectStage={this.saveProjectStage}
              />
            )}
          </Tab>
          <Tab
            eventKey="assortment"
            disabled={this.props.projectMainStage < 2}
            title="Assortment Planning"
          >
            {this.state.selectedTabNum === 1 && (
              <AssortmentPlanning
                userAccess={userRoles[this.state.userType]["2"]}
                intitialActiveStep={this.state.intitialActiveStep}
                updateActiveTab={this.updateActiveTab}
                saveProjectStage={this.saveProjectStage}
                runOmniBuyAlgo={this.runOmniBuyAlgo}
              />
            )}
          </Tab>
          <Tab
            eventKey="optimization"
            disabled={
              this.props.projectMainStage < 3 || this.props.deleteMapping
            }
            title="Buy Optimization"
          >
            {this.state.selectedTabNum === 2 && (
              <BuyOptimization
                userAccess={userRoles[this.state.userType]["3"]}
                intitialActiveStep={this.state.intitialActiveStep}
                updateActiveTab={this.updateActiveTab}
                saveProjectStage={this.saveProjectStage}
                runOmniBuyAlgo={this.runOmniBuyAlgo}
              />
            )}
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    projectId: store.projectReducer.projectId,
    projectDetails: store.projectReducer.projectDetails,
    projectMainStage: store.projectReducer.projectMainStage
      ? store.projectReducer.projectMainStage
      : 1,
    projectSubStage: store.projectReducer.projectSubStage,
    projectsList: store.userReducer.projectNamesList,
    omniIncr: store.buyOptimizationReducer.omniIncr,
    omniRcpt: store.buyOptimizationReducer.omniRcpt,
    lineReceiptlevel: store.assortmentPlanningReducer.lineReceiptlevel,
    selectedFilters:
      store.projectReducer.projectDetails &&
      store.projectReducer.projectDetails.selected_filters,
    selectedFiltersTarget:
      store.projectReducer.projectDetails &&
      store.projectReducer.projectDetails.selected_filters_target,
    isComp:
      store.projectReducer.projectDetails &&
      store.projectReducer.projectDetails.isComp,
    itemLevelSummary: store.buyOptimizationReducer.itemLevelSummary,
    isBuyOptSkipped:
      store.projectReducer.projectDetails &&
      store.projectReducer.projectDetails.isBuyOptSkipped,
    deleteMapping: store.assortmentPlanningReducer.deleteMapping
  };
};
const mapActionsToProps = {
  getProjectById: projectActions.getProjectById,
  callSaveSkipBuyFlag: projectActions.callSaveSkipBuyFlag,
  callSaveProjectStage: projectActions.callSaveProjectStage,
  clearData: commonActions.clearData,
  runOmniAlgo: buyOptimizationAction.runOmniAlgo,
  setVendorForFcBuy: buyOptimizationAction.setVendorForFcBuy,
  setOmniSalesPlanIncrement: buyOptimizationAction.setOmniSalesPlanIncrement
};
export default connect(mapStateToProps, mapActionsToProps)(TabContainer);

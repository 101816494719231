import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import AssortmentPlanningActions from "../../../actions/AssortmentPlanningActions";
import {
  decimalFormatterwithRound$,
  toPercentage,
  toUnit
} from "../../../helpers/formatters";
import DataTable from "../../../services/dataTable";
import { inputCss, tar } from "../../../styles";
import { cloneDeep } from "lodash";

class DepthCountTable extends Component {
  getColDefForDepth = () => {
    let arr = [];
    arr.push({
      headerName: "Total",
      sortable: true,
      unSortIcon: true,
      menuTabs: [],
      field: "Financial_year",
      valueFormatter: val => {
        return "Total";
      },
      rowGroup: true,
      hide: true
    });
    arr.push({
      headerName: "Class/Vendor",
      menuTabs: [],
      rowGroup: true,
      hide: true,
      sortable: true,
      width: 200,
      unSortIcon: true,
      field: "class/vendor",
      pinned: "left"
    });
    arr.push({
      headerName: "SUBCLASS/GROUPING",
      field: "subclass",
      filter: "agTextColumnFilter",
      pinned: "left",
      width: 250,
      hide: true,
      unSortIcon: true,
      sortable: true,
      menuTabs: []
    });
    arr.push({
      headerName: "RCPT PEN%",
      width: 83,
      field: "rcpt_pen%",
      cellStyle: params => {
        return tar;
      },
      unSortIcon: true,
      valueFormatter: toPercentage,
      sortable: true,
      menuTabs: []
    });
    arr.push({
      headerName: "AUR",
      width: 72,
      cellStyle: tar,
      menuTabs: [],
      field: "aur",
      valueFormatter: decimalFormatterwithRound$,
      unSortIcon: true,
      sortable: true
    });
    if (this.props.clusterData.length && this.props.clusters.includes("0")) {
      this.props.clusters.forEach(cluster => {
        if (cluster !== "0") {
          arr.push({
            headerName: `CL ${cluster}`,
            children: [
              {
                headerName: `TY`,
                width: 60,
                sortable: true,
                valueFormatter: toUnit,
                menuTabs: [],
                unSortIcon: true,
                editable: !this.props.userAccessDisableFlag && true,
                cellStyle: params => {
                  if (params.node.group) {
                    return {
                      "background-color": "white",
                      "text-align": "right"
                    };
                  } else {
                    return inputCss;
                  }
                },
                field: `depthCluster${cluster}`
              },
              {
                headerName: this.props.lyFlag ? `LY` : `LLY`,
                cellStyle: tar,
                menuTabs: [],
                width: this.props.lyFlag ? 60 : 70,
                sortable: true,
                valueFormatter: toUnit,
                unSortIcon: true,
                field: `lyDepthCluster${cluster}`
              }
            ]
          });
        }
      });
      arr.push({
        headerName: "Ecom",
        children: [
          {
            headerName: "TY",
            sortable: true,
            width: 60,
            unSortIcon: true,
            menuTabs: [],
            valueFormatter: toUnit,
            field: "depthCluster0",
            cellStyle: params => {
              if (params.node.group) {
                return { "background-color": "white", "text-align": "right" };
              } else {
                return inputCss;
              }
            },
            editable: !this.props.userAccessDisableFlag && true
          },
          {
            headerName: this.props.lyFlag ? `LY` : `LLY`,
            sortable: true,
            width: 60,
            menuTabs: [],
            unSortIcon: true,
            valueFormatter: toUnit,
            field: "lyDepthCluster0"
          }
        ]
      });
    } else {
      this.props.clusters.forEach(cluster => {
        arr.push({
          headerName: `CL ${cluster}`,
          children: [
            {
              headerName: `TY`,
              width: 60,
              sortable: true,
              menuTabs: [],
              valueFormatter: toUnit,
              unSortIcon: true,
              editable: !this.props.userAccessDisableFlag && true,
              cellStyle: params => {
                if (params.node.group) {
                  return { "background-color": "white", "text-align": "right" };
                } else {
                  return inputCss;
                }
              },
              field: `depthCluster${cluster}`
            },
            {
              headerName: `LY`,
              cellStyle: tar,
              width: 60,
              sortable: true,
              menuTabs: [],
              valueFormatter: toUnit,
              unSortIcon: true,
              field: `lyDepthCluster${cluster}`
            }
          ]
        });
      });
    }
    // arr.push({
    //   headerName: "MIN",
    //   width: 68,
    //   field: "min",
    //   // cellStyle: tar,
    //   unSortIcon: true,
    //   menuTabs: [],
    //   editable: !this.props.userAccessDisableFlag && true,
    //   sortable: true,
    //   cellStyle: params => {
    //     if (params.node.group) {
    //       return { "background-color": "white", "text-align": "right" };
    //     } else {
    //       return inputCss;
    //     }
    //   }
    // });
    let tempObj = {
      groupRowAggNodes: nodes => {
        var obj = {
          aur: null,
          "rcpt_pen%": null,
          min: null
        };
        if (this.props.historicalParameterData.length) {
          this.props.clusters.forEach(cluster => {
            obj[`depthCluster${cluster}`] = null;
            obj[`lyDepthCluster${cluster}`] = null;
            obj[`choiceCluster${cluster}`] = null;
            obj[`lyChoiceCluster${cluster}`] = null;
          });
        }
        let context = this;
        obj["subClasslevelCount"] = nodes.length;
        let count = 0;
        nodes.forEach(function(node, index) {
          var data = node.group ? node.aggData : node.data;
          if (typeof Number(data["rcpt_pen%"]) === "number") {
            obj["rcpt_pen%"] += Number(data["rcpt_pen%"]);
          }
          if (typeof Number(data["aur"]) === "number") {
            obj["aur"] += Number(data["aur"]) * Number(data["rcpt_pen%"]);
            count = index;
          }
          if (context.props.historicalParameterData) {
            context.props.clusters.forEach(cluster => {
              if (typeof Number(data[`choiceCluster${cluster}`]) === "number") {
                obj[`choiceCluster${cluster}`] += Number(
                  data[`choiceCluster${cluster}`]
                );
              }
              if (
                typeof Number(data[`lyChoiceCluster${cluster}`]) === "number"
              ) {
                obj[`lyChoiceCluster${cluster}`] +=
                  Number(data[`lyChoiceCluster${cluster}`]) || 0;
              }
              if (typeof Number(data[`depthCluster${cluster}`]) === "number") {
                obj[`depthCluster${cluster}`] +=
                  Number(data[`depthCluster${cluster}`]) *
                    Number(data[`choiceCluster${cluster}`]) || 0;
              }
              if (
                typeof Number(data[`lyDepthCluster${cluster}`]) === "number"
              ) {
                obj[`lyDepthCluster${cluster}`] +=
                  Number(data[`lyDepthCluster${cluster}`]) *
                    Number(data[`lyChoiceCluster${cluster}`]) || 0;
              }
            });
          }
          if (typeof Number(data["min"]) === "number") {
            obj["min"] += Number(data["min"]) * Number(data["rcpt_pen%"]);
          }
        });
        if (count + 1 === obj["subClasslevelCount"]) {
          obj["aur"] = obj["aur"] / obj["rcpt_pen%"];
          obj["min"] = obj["min"] / obj["rcpt_pen%"];
        }
        if (this.props.historicalParameterData.length) {
          this.props.clusters.forEach(cluster => {
            obj[`depthCluster${cluster}`] =
              obj[`depthCluster${cluster}`] / obj[`choiceCluster${cluster}`];
            obj[`lyDepthCluster${cluster}`] =
              obj[`lyDepthCluster${cluster}`] /
              obj[`lyChoiceCluster${cluster}`];
          });
        }
        obj["min"] = Math.round(obj["min"]);
        return obj;
      },
      columnDefs: arr
    };
    return tempObj;
  };
  calDepthChoiceSubclsLvl = (
    historicalParameterObj,
    selRowData,
    cluster,
    classSubclassKey
  ) => {
    let changedInput = Number(selRowData.newValue);
    let aur = historicalParameterObj["aur"];

    historicalParameterObj[`choiceCluster${cluster}`] = !isFinite(
      Math.round(
        ((this.props.subClassRcptPenObject[classSubclassKey][`TY${cluster}`] /
          100) *
          (this.props.subClassRcptPenObject[classSubclassKey]["rcpt_pen%"] /
            100) *
          this.props.buyerTotalBudget) /
          (this.props.clusterData[0][cluster] * aur * changedInput)
      )
    )
      ? 0
      : Math.round(
          ((this.props.subClassRcptPenObject[classSubclassKey][`TY${cluster}`] /
            100) *
            (this.props.subClassRcptPenObject[classSubclassKey]["rcpt_pen%"] /
              100) *
            this.props.buyerTotalBudget) /
            (this.props.clusterData[0][cluster] * aur * changedInput)
        );
    /*
      Check for MATCH ECOM CC TO CL 1 ? Y : N
      if "Y" 
      choiceCount of Ecom = choiceCount of Cluster1
      depthCount of Ecom = (subClassRcpt% * subClassCluster% * buyerTotalBudget) / (storeCount * AUR * cc)
    */
    if (cluster === "1" && historicalParameterObj[`ecom`] === "Y") {
      historicalParameterObj[`choiceCluster0`] =
        historicalParameterObj["choiceCluster1"];
      historicalParameterObj["depthCluster0"] = !isFinite(
        Math.round(
          ((this.props.subClassRcptPenObject[classSubclassKey][`TY0`] / 100) *
            (this.props.subClassRcptPenObject[classSubclassKey]["rcpt_pen%"] /
              100) *
            this.props.buyerTotalBudget) /
            (this.props.clusterData[0][0] *
              aur *
              historicalParameterObj[`choiceCluster0`])
        )
      )
        ? 0
        : Math.round(
            ((this.props.subClassRcptPenObject[classSubclassKey][`TY0`] / 100) *
              (this.props.subClassRcptPenObject[classSubclassKey]["rcpt_pen%"] /
                100) *
              this.props.buyerTotalBudget) /
              (this.props.clusterData[0][0] *
                aur *
                historicalParameterObj[`choiceCluster0`])
          );
    }
    /* updating depth 0 value after calculations */
    selRowData.data[`depthCluster0`] = historicalParameterObj["depthCluster0"];
    let tempArr = [];
    let ACT_RCPT$ = 0;
    this.props.clusters.forEach(e => {
      this.props.clusterData.forEach(clusterObj => {
        if (clusterObj.keys === "# OF DOORS") {
          ACT_RCPT$ += isNaN(
            selRowData.data[`choiceCluster${e}`] *
              Number(selRowData.data[`depthCluster${e}`]) *
              clusterObj[e]
          )
            ? 0
            : selRowData.data[`choiceCluster${e}`] *
              Number(selRowData.data[`depthCluster${e}`]) *
              clusterObj[e];
        }
      });
      tempArr.push(
        isNaN(historicalParameterObj[`choiceCluster${e}`])
          ? 0
          : Number(historicalParameterObj[`choiceCluster${e}`])
      );
    });
    ACT_RCPT$ = ACT_RCPT$ * selRowData.data["aur"];
    let vartoPlan = ACT_RCPT$ - selRowData.data["plan_rcpt$"];
    let planCC = selRowData.data["ty_cc"];
    let CC = Math.max(...tempArr);
    historicalParameterObj["ACT RCPT $"] = ACT_RCPT$;
    historicalParameterObj["VAR TO PLAN"] = vartoPlan;
    historicalParameterObj["PLAN CC"] = planCC;
    historicalParameterObj["CC"] = CC;
    return historicalParameterObj;
  };
  onCellValueChangedDepthCount = selRowData => {
    if (!isNaN(parseInt(selRowData.newValue, 10))) {
      const classSubclassKey = `${selRowData.data["class/vendor"]}-${selRowData.data["subclass"]}`;
      let historicalParameterObj = cloneDeep(
        this.props.historicalParameterObject[classSubclassKey]
      );
      let cluster = selRowData.colDef.field.slice(12);
      var changedInput = null;
      //setting changed value to the respective cluster
      if (+selRowData.newValue < historicalParameterObj["min"]) {
        changedInput = historicalParameterObj["min"];
        historicalParameterObj[`${selRowData.colDef.field}`] = changedInput;
      } else {
        changedInput = Number(selRowData.newValue);
        historicalParameterObj[`${selRowData.colDef.field}`] = changedInput;
      }

      // checking condition,
      // if "min" is changed, recalculate depth and choice count in subclass level,
      // else - calculate choice count according to respective depth change.
      if (selRowData.colDef.field === "min") {
        this.props.clusters.forEach(e => {
          if (historicalParameterObj[`depthCluster${e}`] < changedInput) {
            historicalParameterObj[`depthCluster${e}`] = changedInput;
            historicalParameterObj = this.calDepthChoiceSubclsLvl(
              historicalParameterObj,
              selRowData,
              (cluster = e),
              classSubclassKey
            );
          }
        });
      } else {
        historicalParameterObj = this.calDepthChoiceSubclsLvl(
          historicalParameterObj,
          selRowData,
          cluster,
          classSubclassKey
        );
      }

      //calling actions to update the state.
      this.props.setDepthCountOnCellValueChanged(
        this.props.month,
        classSubclassKey,
        historicalParameterObj
      );

      selRowData.api.refreshCells();
      selRowData.api.refreshClientSideRowModel("aggregate");
    }
  };
  // getRowIdDepth = () => {
  //   let id =
  //     this.gridApiDepth &&
  //     this.gridApiDepth.focusedCellController &&
  //     this.gridApiDepth.focusedCellController.focusedCellPosition &&
  //     this.gridApiDepth.focusedCellController.focusedCellPosition.rowIndex;
  //   return id;
  // };
  render() {
    return (
      <Fragment>
        <div style={{ height: "350px", width: "100%" }}>
          <DataTable
            defaultColDef={{
              resizable: true
            }}
            groupColDef={{
              headerName: "SUB GRP",
              filter: "agTextColumnFilter",
              width: 200,
              field: "subclass",
              menuTabs: [],
              cellRenderer: "agGroupCellRenderer"
            }}
            // deltaRowDataMode={true}
            // getRowNodeId={this.getRowIdDepth}
            // passGridAPI={api => {
            //   this.gridApiDepth = api;
            // }}
            groupRowAggNodes={this.getColDefForDepth().groupRowAggNodes}
            floatingFilter={true}
            suppressColumnVirtualisation={true}
            onCellValueChanged={this.onCellValueChangedDepthCount}
            defaultExpand={true}
            columnDefs={
              this.props.historicalParameterData.length
                ? this.getColDefForDepth().columnDefs
                : null
            }
            rowData={this.props.historicalParameterData}
          />
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = store => {
  return {
    lyFlag: store.projectReducer.lyFlag
  };
};
const mapActionsToProps = {
  setDepthCountOnCellValueChanged:
    AssortmentPlanningActions.setDepthCountOnCellValueChanged,
  recalculateStep5OnCellValueChanged:
    AssortmentPlanningActions.recalculateStep5OnCellValueChanged
};
export default connect(mapStateToProps, mapActionsToProps)(DepthCountTable);

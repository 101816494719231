import {
  decimalFormatter,
  percentageFormatter,
  decimalFormatterwith$,
  decimalFormatterwithRound$,
  toDollar,
  toUnitNoData
} from "../../../helpers/formatters";
import store from "../../../store";
import { tac } from "../../../styles";

export default function(
  hasAttributeClustering,
  isClusterDisabled,
  isAddStores,
  isHindsightFlag
) {
  return {
    defaultColDef: {
      resizable: true,
      // filter: true,
      sortable: true,
      unSortIcon: true,
      menuTabs: []
    },
    columnDefs: [
      // {
      //   headerName: "STR #",
      //   // cellStyle: { "text-align": "right" },
      //   sortable: true,
      //   resizable: true,
      //   filter: "agNumberColumnFilter",
      //   pinned: "left",
      //   field: "loc_id",
      //   tooltipField: "loc_id",
      //   width: 80
      // },
      {
        headerName: "STORE NAME",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter",
        tooltipField: "store_name",
        pinned: "left",
        field: "store_name",
        width: 200,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: params => params.data.clusters !== 0
      },
      {
        headerName: "Attr CL",
        width: 70,
        cellStyle: { "text-align": "right" },
        resizable: true,
        hide: !hasAttributeClustering,
        pinned: "left",
        filter: "agTextColumnFilter",
        field: "attribute_clusters",
        cellRenderer: !isClusterDisabled && "attrClusterCellRenderer"
      },
      {
        headerName: "Vol CL",
        width: 70,
        cellStyle: { "text-align": "right" },
        resizable: true,
        sort: "asc",
        pinned: "left",
        filter: "agNumberColumnFilter",
        field: "clusters",
        cellRenderer: !isClusterDisabled && "clusterStep4CellRender"
      },
      {
        headerName: "Final CL",
        width: 70,
        cellStyle: { "text-align": "right" },
        hide: !hasAttributeClustering,
        resizable: true,
        pinned: "left",
        filter: "agTextColumnFilter",
        valueGetter: params => {
          return +params.data.clusters
            ? params.data.attribute_clusters + params.data.clusters
            : "Ecom";
        }
      },
      {
        headerName: "DIV",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter",
        field: "division",
        tooltipField: "division",
        width: 70
      },
      {
        headerName: "REGION",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter",
        // filter: "agSetColumnFilter",
        field: "region",
        tooltipField: "region",
        width: 130
      },
      {
        headerName: "MALL TYPE",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter",
        // filter: "agTextColumnFilter",
        field: "mall_type",
        tooltipField: "mall_type",
        width: 110
      },
      {
        headerName: "CUSTOM FIELD",
        sortable: true,
        // sort: "asc",
        filter: "agTextColumnFilter",
        editable: params => {
          const state = store.getState();
          if (state.projectReducer.projectMainStage > 1) {
            return false;
          } else {
            return true;
          }
        },
        resizable: true,
        // filter: "agTextColumnFilter",
        field: "custom_field",
        cellStyle: params => {
          const state = store.getState();
          if (state.projectReducer.projectMainStage > 1) {
            return;
          } else {
            return tac;
          }
        },
        width: 94
      },
      {
        headerName: "URBAN",
        field: "urban",
        tooltipField: "urban",
        filter: "agTextColumnFilter",
        width: 87
      },
      {
        headerName: "WEATHER TYPE",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter",
        // filter: "agTextColumnFilter",
        field: "weather_type",
        tooltipField: "weather_type",
        width: 115
      },
      {
        headerName: "SALES RETAIL$",
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        valueFormatter: toDollar,
        width: 93,
        field: "sales_rtl"
      },
      {
        headerName: "LOST SALES / DR",
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        hide: isHindsightFlag,
        valueFormatter: toDollar,
        width: 93,
        field: "sales_rtl_lost_sales"
      },
      {
        headerName: "U Rcpt Depth",
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        valueFormatter: toUnitNoData,
        width: 93,
        field: "u_rec_depth"
      },
      {
        headerName: "U SLS",
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        field: "sales_u",
        valueFormatter: toUnitNoData,
        width: 70
      },
      {
        headerName: "GM $",
        sortable: true,
        cellStyle: { "text-align": "right" },
        resizable: true,
        valueFormatter: toDollar,
        field: "gm",
        width: 100
      },
      {
        headerName: "GM ASST$",
        sortable: true,
        cellStyle: { "text-align": "right" },
        resizable: true,
        valueFormatter: toDollar,
        field: "gm_asst",
        width: 81
      },
      {
        headerName: "GM RATE",
        width: 75,
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        valueFormatter: percentageFormatter,
        field: "gm_rate"
      },
      {
        headerName: "GM ASST RATE",
        width: 98,
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        valueFormatter: percentageFormatter,
        field: "gm_asst_rate"
      },
      {
        headerName: "APS",
        width: 69,
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        valueFormatter: decimalFormatter,
        field: "aps"
      },
      {
        headerName: "APS GM$",
        width: 70,
        sortable: true,
        cellStyle: { "text-align": "right" },
        resizable: true,
        valueFormatter: decimalFormatterwith$,
        field: "aps_gm"
      },
      {
        headerName: "APS GM ASST$",
        width: 91,
        sortable: true,
        cellStyle: { "text-align": "right" },
        resizable: true,
        valueFormatter: decimalFormatterwith$,
        field: "aps_gm_asst"
      },
      {
        headerName: "ST%",
        width: 70,
        sortable: true,
        resizable: true,
        cellStyle: { "text-align": "right" },
        valueGetter: params => {
          if (params.data) {
            let stPerCal =
              (100 * params.data.sales_u) /
              (params.data.bop_u + params.data.rcpt_qty_st);
            //freezing st_per between 0 to 100%
            let condBaseOutput =
              stPerCal < 0 ? 0 : stPerCal > 100 ? 100 : stPerCal;
            return condBaseOutput;
          }
          return null;
        },
        valueFormatter: percentageFormatter,
        field: "sell_through"
      },
      {
        headerName: "AUR $",
        cellStyle: { "text-align": "right" },
        width: 80,
        sortable: true,
        resizable: true,
        valueFormatter: decimalFormatterwithRound$,
        // valueFormatter: roundToTwoDecimal,
        field: "aur"
      },
      {
        headerName: "U TURN",
        width: 77,
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        valueFormatter: decimalFormatter,
        field: "u_turn"
      },
      {
        headerName: "U RCPT",
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        field: "rcpt_u",
        valueFormatter: toUnitNoData,
        width: 75
      },
      {
        headerName: "C RCPT",
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        valueFormatter: toDollar,
        width: 74,
        field: "rcpt_cst"
      },
      {
        headerName: "RCPT RETAIL$",
        sortable: true,
        cellStyle: { "text-align": "right" },
        resizable: true,
        valueFormatter: toDollar,
        field: "rcpt_rtl",
        width: 93
      },
      {
        headerName: "TOTAL CC",
        sortable: true,
        cellStyle: { "text-align": "right" },
        resizable: true,
        hide: isAddStores,
        valueFormatter: toUnitNoData,
        field: "choice",
        width: 83
      },
      {
        headerName: "U BOP",
        sortable: true,
        resizable: true,
        cellStyle: { "text-align": "right" },
        field: "bop_u",
        valueFormatter: toUnitNoData,
        width: 68
      },
      {
        headerName: "U EOP",
        sortable: true,
        resizable: true,
        cellStyle: { "text-align": "right" },
        field: "eop_u",
        valueFormatter: toUnitNoData,
        width: 68
      },
      {
        headerName: "OH TOTAL INVENTORY",
        sortable: true,
        resizable: true,
        cellStyle: { "text-align": "right" },
        field: "oh_tot",
        valueFormatter: toUnitNoData,
        width: 115
      },
      // {
      //   headerName: "TOTAL WEEKS ACTIVE",
      //   sortable: true,
      //   cellStyle: { "text-align": "right" },
      //   resizable: true,
      //   valueFormatter: toUnitNoData,
      //   field: "weeks_active",
      //   width: 105
      // },
      {
        headerName: "AVG WKS ACTIVE",
        sortable: true,
        cellStyle: { "text-align": "right" },
        resizable: true,
        valueFormatter: toUnitNoData,
        field: "avg_weeks_active",
        width: 100
      }
    ],
    unassignedStoresColumnDefs: [
      {
        headerName: "STORE NAME",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter",
        tooltipField: "store_name",
        // pinned: "left",
        field: "store_name",
        width: 135,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },
      {
        headerName: "DIV",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter",
        field: "division",
        tooltipField: "division",
        width: 100
      },
      {
        headerName: "REGION",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter",
        // filter: "agSetColumnFilter",
        field: "region",
        tooltipField: "region",
        width: 130
      },
      {
        headerName: "MALL TYPE",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter",
        // filter: "agTextColumnFilter",
        field: "mall_type",
        tooltipField: "mall_type",
        width: 130
      },
      {
        headerName: "URBAN",
        field: "urban",
        tooltipField: "urban",
        filter: "agTextColumnFilter",
        width: 130
      },
      {
        headerName: "WEATHER TYPE",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter",
        // filter: "agTextColumnFilter",
        field: "weather_type",
        tooltipField: "weather_type",
        width: 130
      },
      {
        headerName: "TOTAL CC",
        sortable: true,
        cellStyle: { "text-align": "right" },
        resizable: true,
        hide: isAddStores,
        valueFormatter: toUnitNoData,
        field: "choice",
        width: 83
      },
      {
        headerName: "AVG WKS ACTIVE",
        sortable: true,
        cellStyle: { "text-align": "right" },
        resizable: true,
        valueFormatter: toUnitNoData,
        hide: isAddStores,
        field: "avg_weeks_active",
        width: 100
      },
      {
        headerName: "U RCPT",
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        field: "rcpt_u",
        valueFormatter: toUnitNoData,
        hide: isAddStores,
        width: 75
      },
      {
        headerName: "RCPT RETAIL$",
        sortable: true,
        cellStyle: { "text-align": "right" },
        resizable: true,
        valueFormatter: toDollar,
        field: "rcpt_rtl",
        hide: isAddStores,
        width: 93
      },
      {
        headerName: " C RCPT",
        cellStyle: { "text-align": "right" },
        sortable: true,
        hide: isAddStores,
        resizable: true,
        valueFormatter: toDollar,
        width: 74,
        field: "rcpt_cst"
      },
      {
        headerName: "U Sls",
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        field: "sales_u",
        hide: isAddStores,
        valueFormatter: toUnitNoData,
        width: 60
      },
      {
        headerName: "SALES RETAIL$",
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        valueFormatter: toDollar,
        hide: isAddStores,
        width: 93,
        field: "sales_rtl"
      },
      {
        headerName: "GROSS MARGIN$",
        sortable: true,
        cellStyle: { "text-align": "right" },
        resizable: true,
        hide: isAddStores,
        valueFormatter: toDollar,
        field: "gm",
        width: 100
      },
      {
        headerName: "GM ASST$",
        sortable: true,
        hide: isAddStores,
        cellStyle: { "text-align": "right" },
        resizable: true,
        valueFormatter: toDollar,
        field: "gm_asst",
        width: 81
      },
      {
        headerName: "U EOP",
        sortable: true,
        hide: isAddStores,
        resizable: true,
        cellStyle: { "text-align": "right" },
        field: "eop_u",
        valueFormatter: toUnitNoData,
        width: 68
      },
      {
        headerName: "U BOP",
        sortable: true,
        hide: isAddStores,
        resizable: true,
        cellStyle: { "text-align": "right" },
        field: "bop_u",
        valueFormatter: toUnitNoData,
        width: 68
      },
      {
        headerName: "OH TOTAL INVENTORY",
        sortable: true,
        hide: isAddStores,
        resizable: true,
        cellStyle: { "text-align": "right" },
        field: "oh_tot",
        valueFormatter: toUnitNoData,
        width: 115
      },
      {
        headerName: "U TURN",
        width: 77,
        hide: isAddStores,
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        valueFormatter: decimalFormatter,
        field: "u_turn"
      },
      {
        headerName: "GM RATE",
        width: 75,
        hide: isAddStores,
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        valueFormatter: percentageFormatter,
        field: "gm_rate"
      },
      {
        headerName: "GM ASST RATE",
        width: 98,

        hide: isAddStores,
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        valueFormatter: percentageFormatter,
        field: "gm_asst_rate"
      },
      {
        headerName: "AUR $",
        cellStyle: { "text-align": "right" },
        width: 80,
        hide: isAddStores,
        sortable: true,
        resizable: true,
        valueFormatter: decimalFormatterwithRound$,
        // valueFormatter: roundToTwoDecimal,
        field: "aur"
      },
      {
        headerName: "APS",
        width: 69,
        hide: isAddStores,
        cellStyle: { "text-align": "right" },
        sortable: true,
        resizable: true,
        valueFormatter: decimalFormatter,
        field: "aps"
      },
      {
        headerName: "APS GM$",
        width: 70,
        hide: isAddStores,
        sortable: true,
        cellStyle: { "text-align": "right" },
        resizable: true,
        valueFormatter: decimalFormatterwith$,
        field: "aps_gm"
      },
      {
        headerName: "APS GM ASST$",
        width: 91,
        hide: isAddStores,
        sortable: true,
        cellStyle: { "text-align": "right" },
        resizable: true,
        valueFormatter: decimalFormatterwith$,
        field: "aps_gm_asst"
      },
      {
        headerName: "ST%",
        width: 70,
        hide: isAddStores,
        sortable: true,
        resizable: true,
        cellStyle: { "text-align": "right" },
        valueFormatter: percentageFormatter,
        field: "sell_through"
      }
    ]
  };
}

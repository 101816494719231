import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
const Schema = Yup.object().shape({
  // password: Yup.string()
  //   .required("Please Enter your password")
  //   .test(
  //     "regex",
  //     "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase",
  //     val => {
  //       let regExp = new RegExp(
  //         "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
  //       );
  //       console.log(regExp.test(val), regExp, val);
  //       return regExp.test(val);
  //     }
  //   ),
  password: Yup.string().required("This field is required"),
  email: Yup.string().required("This field is required")
});

export default class LoginForm extends Component {
  updateForgotPasswordFormFlag = () => {
    this.props.forgotPasswordFormToggler(true);
  };
  render() {
    return (
      <div>
        <div id="signinform" style={{ width: "62%", margin: "62px auto" }}>
          <p className="signIn">
            Sign in to{" "}
            <strong style={{ color: "#2D69EB" }}>Assortment Suite</strong>
          </p>
          <p className="enterDetails">Enter your details below </p>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={Schema}
            onSubmit={values => {
              let paylaod = {
                email: values.email,
                password: values.password
              };
              this.props.login(paylaod);
            }}
          >
            {({
              values,
              errors,
              handleSubmit,
              touched,
              handleChange,
              handleBlur
            }) => (
              <form className="formWidth" onSubmit={handleSubmit}>
                <div className="form-group">
                  <p className="emailAddress">EMAIL ADDRESS</p>

                  <input
                    id="email"
                    className="form-control inputBorder"
                    placeholder="Email"
                    type="text"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email && (
                    <div className="input-feedback">{errors.email}</div>
                  )}
                </div>
                <div className="form-group">
                  <p className="emailPassword">PASSWORD</p>
                  <input
                    id="password"
                    className="form-control inputBorder"
                    placeholder="Password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.password && touched.password && (
                    <div className="input-feedback">{errors.password}</div>
                  )}
                  <div className="row">
                    <div className="col-md-6">
                      {" "}
                      <span className="loginErrors" />
                    </div>
                    <div className="col-md-6">
                      <span id="forgtpas">
                        <small className="d-block">
                          <button
                            onClick={this.updateForgotPasswordFormFlag}
                            type="button"
                            className="btn  btn-square btnforgotpass"
                          >
                            Forgot Password?
                          </button>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
                <div id="btndiv2">
                  <button
                    type="submit"
                    className="btn btn-primary btn-square shadow-sm btnsignin"
                  >
                    SIGN IN
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

import {errorInput, inputCss, tar} from '../../../../styles'
import numeral from "numeral";

function clusterPlanFormatter(params) {
  if (params) {
    if (params.data.goodBetterBest === "Bottom of Range") {
      return numeral(params.value / 100).format("0.00%");
    } else {
      return numeral(params.value).format("0.00");
    }
  }
}
function clusterPlanFormatterForRange5(params) {
  if (params) {
    if (
      params.node.rowIndex === 0 &&
      params.data.goodBetterBest === "Bottom of Range"
    ) {
      return numeral(params.value / 100).format("0");
    } else {
      return numeral(params.value).format("0.00");
    }
  }
}

export default {
  defaultColDef: {
    resizable: true,
    animateRows: true,
    menuTabs: [],
    // sortable: true,
    // unSortIcon: true
  },
  animateRows: true,
  suppressCellSelection: true,
  cellClass: "no-border",
  columnDefs: function(userAccessDisableFlag) {
    return [
      {
        headerName: "",
        // sortable: true,
        // unSortIcon: true,
        field: "goodBetterBest"
      },
      {
        headerName: "RANGE 1(Top)",
        field: "Range1",
        sortable: true,
        width: 144,
        unSortIcon: true,
        valueFormatter: clusterPlanFormatter,
        editable: params => {
          if (params.node.rowIndex !== 0) return !userAccessDisableFlag && true;
        },
        // cellStyle: function(params) {
        //   if (
        //     params.value !== undefined &&
        //     params.data.goodBetterBest === "Bottom of Range"
        //   ) {
        //     if (
        //       Number(params.value) < params.data.Range2 &&
        //       params.data.Range2 < params.data.Range3 &&
        //       params.data.Range3 < params.data.Range4 &&
        //       params.data.Range4 < params.data.Range5
        //     ) {
        //       return inputCss;
        //     } else {
        //       return errorInput;
        //     }
        //   } else {
        //     return inputCss;
        //   }
        // }
        cellStyle: function(params) {
          if (params.node.rowIndex === 1) {
            return inputCss;
          } else {
            return tar;
          }
        }
      },
      {
        headerName: "RANGE 2",
        field: "Range2",
        width: 144,
        sortable: true,
        unSortIcon: true,
        valueFormatter: clusterPlanFormatter,
        editable: params => {
          if (params.node.rowIndex !== 0) return !userAccessDisableFlag && true;
        },
        // cellStyle: function(params) {
        //   if (
        //     params.value !== undefined &&
        //     params.data.goodBetterBest === "Bottom of Range"
        //   ) {
        //     if (
        //       params.data.Range1 < Number(params.value) &&
        //       Number(params.value) < params.data.Range3 &&
        //       params.data.Range3 < params.data.Range4 &&
        //       params.data.Range4 < params.data.Range5
        //     ) {
        //       return inputCss;
        //     } else {
        //       return errorInput;
        //     }
        //   } else {
        //     return inputCss;
        //   }
        // }
        cellStyle: function(params) {
          if (params.node.rowIndex === 1) {
            return inputCss;
          } else {
            return tar;
          }
        }
      },
      {
        headerName: "RANGE 3",
        field: "Range3",
        width: 144,
        sortable: true,
        unSortIcon: true,
        valueFormatter: clusterPlanFormatter,
        editable: params => {
          if (params.node.rowIndex !== 0) return !userAccessDisableFlag && true;
        },
        // cellStyle: function(params) {
        //   if (
        //     params.value !== undefined &&
        //     params.data.goodBetterBest === "Bottom of Range"
        //   ) {
        //     if (
        //       params.data.Range1 < params.data.Range2 &&
        //       params.data.Range2 < Number(params.value) &&
        //       Number(params.value) < params.data.Range4 &&
        //       params.data.Range4 < params.data.Range5
        //     ) {
        //       return inputCss;
        //     } else {
        //       return errorInput;
        //     }
        //   } else {
        //     return inputCss;
        //   }
        // }
        cellStyle: function(params) {
          if (params.node.rowIndex === 1) {
            return inputCss;
          } else {
            return tar;
          }
        }
      },
      {
        headerName: "RANGE 4",
        width: 144,
        field: "Range4",
        sortable: true,
        unSortIcon: true,
        valueFormatter: clusterPlanFormatter,
        editable: params => {
          if (params.node.rowIndex !== 0) return !userAccessDisableFlag && true;
        },
        // cellStyle: function(params) {
        //   if (
        //     params.value !== undefined &&
        //     params.data.goodBetterBest === "Bottom of Range"
        //   ) {
        //     if (
        //       params.data.Range1 < params.data.Range2 &&
        //       params.data.Range2 < params.data.Range3 &&
        //       params.data.Range3 < Number(params.value) &&
        //       Number(params.value) < params.data.Range5
        //     ) {
        //       return inputCss;
        //     } else {
        //       return errorInput;
        //     }
        //   } else {
        //     return inputCss;
        //   }
        // }
        cellStyle: function(params) {
          if (params.node.rowIndex === 1) {
            return inputCss;
          } else {
            return tar;
          }
        }
      },
      {
        headerName: "RANGE 5(Bottom)",
        field: "Range5",
        width: 144,
        sortable: true,
        unSortIcon: true,
        valueFormatter: clusterPlanFormatterForRange5,
        editable: params => {
          if (params.node.rowIndex !== 0) return !userAccessDisableFlag && true;
        },
        cellStyle: function(params) {
          if (params.node.rowIndex === 1) {
            return inputCss;
          } else {
            return tar;
          }
        }
      }
    ];
  }
};

const inputCss = {
  "background-color": "#BBEDC0",
  "border-left": "1px solid grey",
  "border-right": "1px solid grey",
  "text-align": "right"
};

const errorInput = {
  "background-color": "#f28e83",
  "text-align": "right"
};

const agGridRowBorder = {
  "border-color": "#d9dcde",
  "border-width": "0.5px 0 0"
};
const agGridCellBgColor = {
  "background-color": "#fff"
};
const massApplyCell = {
  "border-color": "#BBEDC0",
  "border-width": "0.5px",
  "text-align": "right"
};
const tar = {
  "text-align": "right"
};

const tac = {
  "background-color": "#BBEDC0",
  "border-left": "1px solid grey",
  "border-right": "1px solid grey"
};
const greyCss = {
  "background-color": "#d7d7db",
  "text-align": "right"
};

export {
  inputCss,
  errorInput,
  agGridRowBorder,
  agGridCellBgColor,
  tar,
  tac,
  greyCss,
  massApplyCell
};

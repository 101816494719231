import * as FileSaver from "file-saver";
import Excel from "exceljs";

export const downloadTable = (sheets, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const worksheets = [];
  var workbook = new Excel.Workbook();

  for (let i = 0; i < sheets.length; i++) {
    const sheetHeader =
      typeof sheets[i].sheetHeader === "string" &&
      sheets[i].sheetHeader.trim().length > 0
        ? sheets[i].sheetHeader.trim()
        : undefined;

    worksheets[i] = workbook.addWorksheet(sheets[i].sheet_name);

    const cols = Object.keys(sheets[i].sheet_data[0]).map(c => {
      return { header: c, key: c, width: 20 };
    });

    worksheets[i].columns = cols;
    sheets[i].column_format &&
      sheets[i].column_format.forEach((item, index) => {
        worksheets[i].getColumn(index + 1).numFmt = item;
      });

    // true, only for buy optimization input 3-1
    // false for all other cases
    if (sheetHeader) {
      // title is sent
      // const colHeaders = cols.map(c => c.header)
      const guideLines = sheetHeader.split("\n");
      const guideLinesLength = guideLines.length;

      worksheets[i].addRows(sheets[i].sheet_data);

      worksheets[i].getRow(1).values = cols.map(c => c.header);

      worksheets[i].getRow(1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF2C6AE8" }
      };

      worksheets[i].getRow(1).font = {
        name: "Sans-Serif",
        color: { argb: "FFFFFFFF" },
        family: 2,
        size: 11,
        bold: false
      };

      for (let ii = 0; ii < guideLinesLength; ++ii) {
        worksheets[i].getRow(ii + 3).getCell(7).value = guideLines[ii];

        if (ii === 0) {
          worksheets[i].getRow(ii + 3).getCell(7).font = {
            bold: true
          };
        }
      }
    } else {
      // title is not sent
      worksheets[i].addRows(sheets[i].sheet_data);

      worksheets[i].getRow(1).values = cols.map(c => c.header);

      worksheets[i].getRow(1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF2C6AE8" }
      };

      worksheets[i].getRow(1).font = {
        name: "Sans-Serif",
        color: { argb: "FFFFFFFF" },
        family: 2,
        size: 11,
        bold: false
      };
    }

    if (sheets[i].column_width) {
      Object.keys(sheets[i].column_width).forEach(item => {
        let set_col = worksheets[i].getColumn(item);
        set_col.width = sheets[i].column_width[item];
      });
    }
  }

  workbook.xlsx.writeBuffer().then(function(buffer) {
    let data = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  });
};

// import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
// export const downloadTable = (sheets,fileName) => {

//     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//     const fileExtension = '.xlsx';

//     const worksheets=[];

//     // setting width for 1st column
//     var wscols = [{wpx: 200}];

//     // constructing worksheet with headertitle and sheetdata and then storing it in an array called worksheets
//     for(let i=0;i<sheets.length;i++){
//         worksheets[i]=XLSX.utils.json_to_sheet([{}], {
//             header: [sheets[i].title],
//           });
//         XLSX.utils.sheet_add_json(worksheets[i], sheets[i].sheet_data, { origin: 'A3' });
//         worksheets[i]['!cols'] = wscols;
//     }

//     // sheet_obj is constructed such that key is the sheet name and value is the worksheet that was created above .
//     const sheet_obj = sheets.reduce((acc,item,index)=>{return {...acc,[item['sheet_name']]:worksheets[index]};},{});

//     const sheet_names = sheets.map(item=>item.sheet_name);

//     // finally workbook data is constructed which comprises of all the sheets .
//     const work_book = {
//             Sheets: sheet_obj,
//             SheetNames: sheet_names
//     };

//     const excelBuffer = XLSX.write(work_book, {type: 'array' });
//     const data = new Blob([excelBuffer], {type: fileType});
//     FileSaver.saveAs(data, fileName + fileExtension);

//   }

import React, { Component } from "react";

class ChildMessageRenderer extends Component {
  render() {
    return (
      <span>
        <button
          style={{ width: "100%", height: 20, lineHeight: 0.5, background: "#2C6AE7", color: "white" }}
          className="btn btn-info"
        >
          Apply
        </button>
      </span>
    );
  }
}

export default ChildMessageRenderer;

import { toast } from "react-toastify";
import api from "../axios";

const ClusteringActions = {
  getAllocators: () => {
    return () => {
      return api({
        url: "/getListOfAllocators",
        method: "GET"
      })
        .then(resp => {
          return resp;
        })
        .catch(err => {
          console.log(err);
        });
    };
  },
  runIntelligentClustering: payload => {
    payload.ecommFlag = !payload.ecommFlag;
    // payload.isLostSales = !payload.isLostSales;
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text:
            "This may take sometime. Fetching sales data at store-style level, running clustering algorithm and optimizing number of clusters."
        }
      });
      return api({
        url: "/initClustering",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          //Converting Include Ecom Flag to Exclude Ecom Flag as Backend is handling include ecom flag and frontend is of exclude ecom
          resp.data.clusteringDetails.clusteringInputFields.ecommFlag = !resp
            .data.clusteringDetails.clusteringInputFields.ecommFlag;
          // resp.data.clusteringDetails.clusteringInputFields.isLostSales = !resp
          //   .data.clusteringDetails.clusteringInputFields.isLostSales;
          dispatch({
            type: "SET_CLUSTERING_INPUT_FIELDS",
            payload: resp.data.clusteringDetails.clusteringInputFields
          });
          dispatch({
            type: "SET_CLUSTER_RESULTS_STORE_LEVEL",
            payload: resp.data.clusteringDetails.clusterResultsStoreLevel
          });
          dispatch({
            type: "SDF_SALES_SUMMARY",
            payload: resp.data.clusteringDetails.clusterResultsStoreLevel
          });
          dispatch({
            type: "SET_CLUSTER_MAPPING",
            payload: resp.data.clusteringDetails.clusterMapping
          });
          dispatch({
            type: "SET_OPTIMAL_CLUSTERS",
            payload: resp.data.clusteringDetails.optimalClusters
          });
          dispatch({
            type: "SET_USER_SELECTED_CLUSTERS",
            payload: resp.data.clusteringDetails.optimalClusters
          });
          dispatch({
            type: "SET_PROJECT_DETAILS",
            payload: resp.data.projectDetails
          });
          dispatch({
            type: "SET_CLUSTER_DETAILS",
            payload: resp.data.clusteringDetails.clusteringInputFields
          });
          dispatch({
            type: "SET_ELBOW_CURVE",
            payload: resp.data.clusteringDetails.elbowCurve
          });
          dispatch({
            type: "SET_PROJECT_ID",
            payload: resp.data.projectId
          });
          dispatch({
            type: "SET_CLUSTER_MASTER_ID",
            payload: resp.data.clusterMasterId
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  getStoreClusterData: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/getStoreClusterData",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_CLUSTER_RESULTS_STORE_LEVEL",
            payload: resp.data
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  runAttributeClustering: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text: "This may take some time. Thank you for your patience."
        }
      });
      return api({
        url: "/runAttributeClustering",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_CLUSTER_RESULTS_STORE_LEVEL",
            payload: [...resp.data.clusterResultsStoreLevel]
          });
          dispatch({
            type: "SET_ATTRIBUTES_GRADE_CHART",
            payload: resp.data.attributesGradeChart
          });
          dispatch({
            type: "SET_ATTRIBUTES_STORE_MAPPING",
            payload: resp.data.attributesStoreMapping
          });
          dispatch({
            type: "SET_OPTIMAL_ATTRIBUTE_CLUSTERS",
            payload: resp.data.optimalAttributeClusters
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  getAttributesSelection: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      api({
        url: `/getAttributesSelection/${payload.clusterMasterId}`,
        method: "POST"
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_ATTRIBUTE_SELECTION_LIST",
            payload: resp.data
          });
          dispatch({
            type: "SET_ATTRIBUTE_NOT_ENOUGH_FIL_RATE",
            payload: false
          });
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          if (
            err.message ===
              "Volumetric clustering will run since there is not enough fill rate" ||
            err.message ===
              "No attribute data available for the selection."
          ) {
            dispatch({
              type: "SET_ATTRIBUTE_NOT_ENOUGH_FIL_RATE",
              payload: true
            });
          }
        });
    };
  },
  updateClusterMapping: payload => {
    return dispatch => {
      // console.log(payload, "updateClusterMapping");
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/updateClusterMapping",
        method: "POST",
        data: payload
      })
        .then(resp => {
          if (resp.success && resp.data.url) {
            var file_path = resp.data.url;
            var a = document.createElement("A");
            a.href = file_path;
            a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  changeUserSelectedClusters: payload => {
    return dispatch => {
      dispatch({
        type: "SET_USER_SELECTED_CLUSTERS",
        payload: payload
      });
    };
  },
  setAttributeSelectedClusters: payload => {
    return dispatch => {
      dispatch({
        type: "SET_OPTIMAL_ATTRIBUTE_CLUSTERS",
        payload: payload
      });
    };
  },
  setClusterResultStoreLevel: payload => {
    return dispatch => {
      dispatch({
        type: "SET_CLUSTER_RESULTS_STORE_LEVEL",
        payload
      });
    };
  }
};

export default ClusteringActions;

import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import Tippy from "@tippy.js/react";
import { withRouter } from "react-router-dom";
import buyOptimizationAction from "../../../actions/BuyOptimizationActions";
import BarChart from "../../../services/ChartJS/Apt_Bar_LineArchitecture";
import LineChart from "../../../services/ChartJS/LineChart";
import DataTable from "../../../services/dataTable";
// import Switch from "../../../services/Switch";
import Switch from "@material-ui/core/Switch";
import BuyInputsColumnDefs from "../colDefs/BuyInputsColumnDefs";
import BuyerInputStyleColorLevel from "./StyleColorLevelTable";
import BuyerInputStyleLevel from "./StyleLevelTable";
import ExcelReader from "./ExcelReader";
import exportToExcel from "./exportToExcel";
import { toast } from "react-toastify";

class BuyInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCompareBtnDisabledLabel: false,
      data: [],
      excelToJson: [],
      omniIncr: props.omniIncr,
      omniRcpt: props.omniRcpt
    };

    this.compareAPS = React.createRef();
  }

  arrayForOrderedHeaders = []; // To hold the ordered headers for excel download order format

  LinechartOptions = {
    tooltips: {
      mode: "label",
      intersect: true,
      callbacks: {
        title: function(data) {
          return `APS Bands: ${data[0].xLabel}`;
        },
        label: function(val, val1) {
          if (val.datasetIndex === 0 || val.datasetIndex === 1)
            return `${
              val1.datasets[val.datasetIndex].label
            }: ${val.yLabel.toFixed(2)}`;
          else if (val.datasetIndex === 2)
            return `${
              val1.datasets[val.datasetIndex].label
            }: ${val.yLabel.toFixed(0)}`;
        }
      }
    },
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "top"
      // labels: {
      // boxWidth: 10
      //   fontColor: "black"
      // }
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Style Rank"
          },
          gridLines: {
            display: false
          },
          ticks: {
            // autoSkip: false,
            // maxRotation: 0,
            // minRotation: 0,
            fontSize: 12
          }
        }
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "APS"
          },
          gridLines: {
            display: false
          },
          ticks: {
            min: 0
          }
        }
      ]
    },

    responsive: true
    // title: {
    //   display: true,
    //   position: "top",
    //   text: "Line Graph",
    //   fontSize: 18,
    //   fontColor: "#111"
    // }
  };
  excelToJsonData = [];
  excelToJsonDataforFlow = [];
  BarChartOptions = {
    tooltips: {
      mode: "label",
      intersect: true,
      callbacks: {
        title: function(data) {
          return `APS Bands: ${data[0].xLabel}`;
        },
        label: function(val, val1) {
          if (val.datasetIndex === 0 || val.datasetIndex === 1)
            return `${
              val1.datasets[val.datasetIndex].label
            }: ${val.yLabel.toFixed(2)}`;
          else if (val.datasetIndex === 2)
            return `${
              val1.datasets[val.datasetIndex].label
            }: ${val.yLabel.toFixed(0)}`;
        }
      }
    },
    maintainAspectRatio: false,
    barValueSpacing: 2,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "%  of choices"
          },
          ticks: {
            min: 0
          },
          gridLines: {
            display: false
          }
        }
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "APS bands"
          },
          gridLines: {
            display: false
          },
          ticks: {
            min: 0
          }
        }
      ]
      // xAxes: [
      //   {
      //     ticks: {
      //       min: 0
      //     }
      //   }
      // ]
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.isCompareBtnDisabledLabel === true &&
      this.props.boGraphDataProp !== null
    ) {
      this.setState({
        isCompareBtnDisabledLabel: !prevState.isCompareBtnDisabledLabel // false
      });
    }
    if (this.state.data) {
      this.props.enableOptimizeBtn();
    }
  }

  // CHECK IF CELLS ARE EMPTY TO DISABLE COMPARE APS BUTTON
  checkCompareApsData = data => {
    const { selectedPrimaryAttributes } = this.props.projectDetails;
    const excludedColumnsToCheck = [...(selectedPrimaryAttributes || [])];
    return new Promise(function(resolve, reject) {
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        for (var key in obj) {
          if (!excludedColumnsToCheck.includes(key)) {
            if (obj[key] === null || obj[key].length === 0) {
              // toast.error("Please provide all the mandatory fields.", { autoClose: false });
              toast.error(
                `${key} field is empty, Please provide all the mandatory fields.`,
                { autoClose: false }
              );
              resolve(false);
              return false;
            }
          }
        }
      }
      resolve(true);
      return true;
    });
  };

  //CALLED ON UPLOAD
  ExcelToJsonData = data => {
    this.excelToJsonData.push(JSON.parse(data));
    if (this.excelToJsonData.length === 2) {
      this.setState(
        {
          excelToJson: this.excelToJsonData
        },
        () => {
          this.excelToJsonData = []; // clear it for new upload
          this.props.assignDataAfterfileUpload({
            StyleColorLevel: this.state.excelToJson[0],
            StyleLevel: this.state.excelToJson[1]
          });

          // console.log("upload setstate", this.state);

          // FILE UPLOADED SUCCESSFULLY, SHOW INFO.
          if (
            this.props.boInputDataStyleColorLevel &&
            this.props.boInputDataStyleLevel
          ) {
            toast.info(`Uploaded successfully.`, { autoClose: 2000 });
          } else {
            toast.error(`Upload Failed.`, { autoClose: false });
          }
        }
      );
    }
  };

  ExcelToJsonDataForFlow = data => {
    this.excelToJsonDataforFlow.push(data);

    if (this.excelToJsonDataforFlow.length === 2) {
      this.excelToJsonDataforFlow[0] = this.returnOrderedHeaders(
        this.excelToJsonDataforFlow[0]
      );
      exportToExcel(this.excelToJsonDataforFlow);
      this.excelToJsonDataforFlow = [];
    }

    // if (this.excelToJsonDataforFlow.length === 2) {
    //   console.log("arrayForOrderedHeaders", this.props);
    //   this.arrayForOrderedHeaders = this.props.boInputDataProp;
    //   console.log("arrayForOrderedHeaders", this.arrayForOrderedHeaders);
    //   // PUT props DATA INTO this.arrayForOrderedHeaders
    //   this.arrayForOrderedHeaders[0] = this.returnOrderedHeaders(
    //     this.excelToJsonDataforFlow[0]
    //   );

    //   this.returnOrderedHeaders(this.excelToJsonDataforFlow[0]);
    //   console.log(
    //     "arrayForOrderedHeaders",
    //     this.arrayForOrderedHeaders,
    //     this.excelToJsonDataforFlow
    //   );
    //   console.log("arrayForOrderedHeaders", this.props);
    //   exportToExcel(this.arrayForOrderedHeaders);
    //   this.excelToJsonDataforFlow = [];
    // }
  };

  updateIsConstraint = val => {
    this.props.setConstrained(val ? 1 : 0);
  };
  onStyleLevelColorCellValueChanged = val => {
    switch (val.colDef.field) {
      case "class/vendor":
        val.data["class/vendor"] = val.value;
        break;
      case "subclass":
        val.data.subclass = val.value;
        break;
      case "styleNameColor":
        val.data.styleNameColor = val.value;
        break;
      case "styleId":
        val.data.styleId = val.value;
        break;
      case "aps":
        val.data.aps = val.value = Number(val.value) || null;
        break;
      case "weeks":
        val.data.weeks = val.value = Number(val.value) || null;
        break;
      case "noOfSizes":
        val.data.noOfSizes = val.value = Number(val.value) || null;
        break;
      case "caseSize":
        val.data.caseSize = val.value = Number(val.value) || null;
        break;
      case "minPerSize":
        val.data.minPerSize = val.value = Number(val.value) || null;
        break;
      case "msrp":
        val.data.msrp = val.value = Number(val.value) || null;
        break;
      case "imu":
        val.data.imu = val.value = Number(val.value) || null;
        break;
      case "regDiscount":
        val.data.regDiscount = val.value = Number(val.value) || null;
        break;
      case "productionMin":
        val.data.productionMin = val.value = Number(val.value) || null;
        break;
      case "maxQuantity":
        val.data.maxQuantity = val.value = Number(val.value) || null;
        break;
      case "moq":
        val.data.moq = val.value = Number(val.value) || null;
        break;
      case "surchargePerc":
        val.data.surchargePerc = val.value = Number(val.value) || null;
        break;
      case "Season":
        val.data.Season = val.value;
        break;
      case "storeCount":
        val.data.storeCount = val.value = Number(val.value) || null;
        break;
      case "regSellThroughPerc":
        val.data.regSellThroughPerc = val.value = Number(val.value) || null;
        break;
      case "firstMarkdownSellThrough":
        val.data.firstMarkdownSellThrough = val.value =
          Number(val.value) || null;
        break;
      case "firstMarkdownDiscount":
        val.data.firstMarkdownDiscount = val.value = Number(val.value) || null;
        break;
      case "secondMarkdownSellThrough":
        val.data.secondMarkdownSellThrough = val.value =
          Number(val.value) || null;
        break;
      case "secondMarkdownDiscount":
        val.data.secondMarkdownDiscount = val.value = Number(val.value) || null;
        break;
      case "combinationFlag":
        val.data.combinationFlag = val.value = Number(val.value) || null;
        break;
      default:
        break;
    }
    this.props.boInputDataStyleColorLevel[val.rowIndex] = val.data;
    val.api.refreshCells();
  };

  onCompareAps = () => {
    this.setState(
      prevState => ({
        isCompareBtnDisabledLabel: !prevState.isCompareBtnDisabledLabel //true
      }),
      () => {
        this.checkCompareApsData(this.props.boInputDataStyleColorLevel).then(
          check => {
            if (check) {
              // this.setState({
              //   isCompareBtnDisabledLabel: !prevState.isCompareBtnDisabledLabel
              // });
              let payload = {
                projectId: this.props.match.params.id,
                boInputData: {
                  StyleColorLevel: this.props.boInputDataStyleColorLevel,

                  StyleLevel: this.props.boInputDataStyleLevel,
                  omniIncr: !this.props.omniRcptPlanDollarFlag ? this.state.omniIncr : 0,
                  omniRcpt: this.state.omniRcpt || 0
                }
              };
              // console.log("compareaps payload", payload);
              this.props.runCompareAPSAlgo(payload).then(resp => {
                if (resp.success) this.props.saveProjectStage(3, 1);
                this.setState({
                  isCompareBtnDisabledLabel: false
                });
              });
            }
          }
        );
      }
    );

    // let payload = {
    //   projectId: this.props.match.params.id,
    //   boInputData: {
    //     StyleColorLevel: this.state.isFileUpload
    //       ? this.state.excelToJson[0]
    //       : this.props.boInputDataStyleColorLevel,
    //     StyleLevel: this.state.isFileUpload
    //       ? this.state.excelToJson[1]
    //       : this.props.boInputDataStyleLevel
    //   }
    // };
    // // console.log("compareaps payload", payload);
    // this.props.runCompareAPSAlgo(payload).then(resp => {
    //   if (resp.success) this.props.saveProjectStage(3, 1);
    // });
  };

  returnOrderedHeaders = data => {
    const { selectedPrimaryAttributes } = this.props.projectDetails;
    // TAKES EXCEL HEADERS AND RETURNS INTO ORDERED ARRAY OF OBJECT FOR EXCEL GENERATE
    let newObject = [];
    for (let i = 0; i < data.length; i++) {
      const primaryAttrObj = {};
      selectedPrimaryAttributes &&
        selectedPrimaryAttributes.length &&
        selectedPrimaryAttributes.forEach(primaryAttr => {
          primaryAttrObj[primaryAttr] = data[i][primaryAttr];
        });
      newObject.push({
        "Vendor name": data[i]["class/vendor"],
        // "Class ID": data[i].classId,
        "Custom Category": data[i].subclass,
        "Style color name": data[i].styleNameColor,
        APS: data[i].aps,
        "Min Per Size": data[i].minPerSize,
        "MSRP/ Ticket": data[i].msrp,
        IMU: data[i].imu,
        "Max Store Count": data[i].storeCount,
        "Reg ST %": data[i].regSellThroughPerc,
        Weeks: data[i].weeks,

        ...primaryAttrObj,
        "Style ID": data[i].styleId,
        "No Of Sizes": data[i].noOfSizes,

        "Reg Discount %": data[i].regDiscount,
        "Production Min": data[i].productionMin,
        "Max Quantity": data[i].maxQuantity,
        MOQ: data[i].moq,
        "Surcharge %": data[i].surchargePerc,
        // Season: data[i].season,

        "1st MD ST %": data[i].firstMarkdownSellThrough,
        "1st MD Discount %": data[i].firstMarkdownDiscount,
        "2nd MD ST %": data[i].secondMarkdownSellThrough,
        "2nd MD Discount %": data[i].secondMarkdownDiscount,
        "Combination Flag": data[i].combinationFlag
      });
    }
    return newObject;
  };

  exportExcelFunc = () => {
    // if (this.state.excelToJson.length > 0) {
    //   // PUT EXCEL UPLOADED DATA INTO this.arrayForOrderedHeaders
    //   console.log("array", this.arrayForOrderedHeaders, this.props.boInputDataProp);
    //   this.arrayForOrderedHeaders = this.state.excelToJson;

    //   let data = this.state.excelToJson[0];
    //   this.arrayForOrderedHeaders[0] = this.returnOrderedHeaders(data);
    //   console.log("array", this.arrayForOrderedHeaders, this.state);
    //   exportToExcel(this.arrayForOrderedHeaders);
    // } else {

    this.ExcelToJsonDataForFlow(this.props.boInputDataStyleColorLevel);
    this.ExcelToJsonDataForFlow(this.props.boInputDataStyleLevel);
    // }
  };
  render() {
    return (
      <div>
        <div className="card p-3" style={{ marginBottom: "12px" }}>
          <div className="card-header-background">
            <strong>Buyer Input</strong>
            <Tippy
              placement={"left"}
              arrow={true}
              distance={10}
              content="Partially filled with auto-populated values from previous steps. Enter additional data like MOQ, Production min, etc. to run buy optimization"
            >
              <i
                style={{
                  color: "#fff",
                  fontSize: "16px"
                }}
                className="fa fa-info-circle"
                aria-hidden="true"
              />
            </Tippy>
          </div>
          <div className="mt-2">
            <div style={{ fontSize: "14px" }}>
              <strong>OMNI Plan(%)</strong>
              <Switch
                disabled={false}
                checked={this.props.omniRcptPlanDollarFlag}
                onChange={e =>
                  this.props.isOmniRcptPlanDollarFlag(e.target.checked)
                }
                value={this.props.omniRcptPlanDollarFlag}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <strong>OMNI RCPT Plan($)</strong>
            </div>
            {!this.props.omniRcptPlanDollarFlag ? (
              <label className="omni-input">
                OMNI Plan(%)
                <input
                  className="form-control"
                  type="number"
                  value={this.state.omniIncr ? this.state.omniIncr : (this.props.deptPenetration &&
                    this.props.deptPenetration.toFixed(2))}
                  onChange={val => {
                    this.setState({ omniIncr: val.currentTarget.value });
                  }}
                />
              </label>
            ) : (
              <label className="omni-input">
                OMNI RCPT Plan($)
                <input
                  className="form-control"
                  type="number"
                  value={this.state.omniRcpt}
                  onChange={val => {
                    this.setState({ omniRcpt: val.currentTarget.value });
                  }}
                />
              </label>
            )}
          </div>
          <div className="actionSectionRightAligned">
            <label className="fileContainer" htmlFor="upload-file">
              <input
                value="Download Buyer Input"
                type="button"
                id="upload-file"
                onClick={() => {
                  this.exportExcelFunc();
                }}
              />
            </label>
            <label className="fileContainer" htmlFor="upload-file">
              Upload Buyer Input
              <ExcelReader
                selectedPrimaryAttributes={
                  this.props.projectDetails.selectedPrimaryAttributes
                }
                boInputDataStyleColorLevel={
                  this.props.boInputDataStyleColorLevel
                }
                dataCapture={this.ExcelToJsonData}
                dataCaptureSecond={this.ExcelToJsonData}
              />
            </label>
          </div>
          <Tabs
            defaultActiveKey="styleColorLevel"
            transition={false}
            id="noanim-tab-example"
          >
            <Tab
              eventKey="styleColorLevel"
              title={
                <div>
                  <span>Style Color Level *</span>
                </div>
              }
            >
              <div>
                <BuyerInputStyleColorLevel
                  selectedPrimaryAttributes={
                    this.props.projectDetails.selectedPrimaryAttributes
                  }
                  userDisableAccess={this.props.userDisableAccess}
                  data={this.props.boInputDataStyleColorLevel}
                  onStyleLevelColorCellValueChanged={
                    this.onStyleLevelColorCellValueChanged
                  }
                />
              </div>
            </Tab>
            <Tab eventKey="styleLevel" title="Style Level Input">
              <BuyerInputStyleLevel
                userDisableAccess={this.props.userDisableAccess}
                data={
                  this.state.excelToJson.length > 0
                    ? this.state.excelToJson[1]
                    : this.props.boInputDataStyleLevel
                }
              />
            </Tab>
          </Tabs>

          <div className="t-a-r mt-3">
            <button
              style={{ background: "#2D69EB", color: "white" }}
              type="button"
              // disabled={BO_BuyerInputStyleColorLevelColDefs.rowData == null}
              disabled={this.props.userDisableAccess}
              className="btn btn-primary btn-square shadow-sm"
              onClick={this.onCompareAps}
            >
              Compare APS
            </button>
          </div>
        </div>
        {/**
         * Commented as constrained is not required, but need to be removed completely after buffer time
         */}

        {/* {this.props.boGraphData && (
          <div className="card p-3">
            <Switch
              userDisableAccess={this.props.userDisableAccess}
              isConstrainedUpdate={this.updateIsConstraint}
              constrained={this.props.constrained}
            />
          </div>
        )} */}
        {this.props.boGraphData && (
          <div ref={this.compareAPS}>
            {this.state.data && (
              <div className="card p-3 BuyInput-card2">
                <div className="card-header-background">
                  <strong>APS Comparison</strong>
                </div>
                <div
                  className="mt-3"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div className="pr-3" style={{ width: "40%" }}>
                    <Tabs
                      defaultActiveKey="Chart2"
                      transition={false}
                      id="noanim-tab-example"
                    >
                      <Tab
                        eventKey="Chart2"
                        title={
                          this.props.lyFlag ? "Adjusted APS" : "Adjusted APS"
                        }
                      >
                        <div
                          className="mt-3"
                          style={{ height: "300px", width: "100%" }}
                        >
                          <LineChart
                            chartName="ly_adjusted_aps"
                            data={this.props.boGraphData}
                            options={this.LinechartOptions}
                            lyFlag={this.props.lyFlag}
                          />
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Chart1"
                        title={
                          this.props.lyFlag ? "Original APS" : "Original APS"
                        }
                      >
                        <div
                          className="mt-3"
                          style={{ height: "300px", width: "100%" }}
                        >
                          <LineChart
                            chartName="ly_aps"
                            data={this.props.boGraphData}
                            options={this.LinechartOptions}
                            lyFlag={this.props.lyFlag}
                          />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                  {/* <div className="BuyInput-chart" /> */}
                  <div className="pl-3" style={{ width: "60%" }}>
                    <Tabs
                      defaultActiveKey="Chart1"
                      transition={false}
                      id="noanim-tab-example"
                    >
                      <Tab eventKey="Chart1" title="Chart">
                        <div
                          className="mt-3"
                          style={{ height: "300px", width: "100%" }}
                        >
                          <BarChart
                            chartName="BO_buyinput"
                            options={this.BarChartOptions}
                            data={this.props.boGraphData}
                            lyFlag={this.props.lyFlag}
                          />
                        </div>
                      </Tab>
                      <Tab eventKey="Chart2" title="Table">
                        <div className="mt-3" style={{ height: "300px" }}>
                          <DataTable
                            columnDefs={BuyInputsColumnDefs.columnDefs(
                              this.props.lyFlag
                            )}
                            sizeToFit={true}
                            defaultColDef={BuyInputsColumnDefs.defaultColDef}
                            rowData={
                              this.props.boGraphData &&
                              this.props.boGraphData["APS bands"]
                            }
                          />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    boGraphData: store.buyOptimizationReducer.boGraphData,
    omniIncr: store.buyOptimizationReducer.omniIncr,
    omniRcpt: store.buyOptimizationReducer.omniRcpt,
    projectDetails: store.projectReducer.projectDetails

    // constrained: store.buyOptimizationReducer.constrained
  };
};
const mapActionsToProps = {
  runCompareAPSAlgo: buyOptimizationAction.runCompareAPSAlgo,
  assignDataAfterfileUpload:
    buyOptimizationAction.assignDataAfterFileUploadBuyInut,
  setConstrained: buyOptimizationAction.setConstrained
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(BuyInput));

const commonActions = {
  clearData: () => {
    return dispatch => {
      dispatch({
        type: "CLEAR_PROJECT_DATA"
      });
      dispatch({
        type: "CLEAR_CLUSTERING_DATA"
      });
      dispatch({
        type: "CLEAR_ASSORTMENT_PLANNING_DATA"
      });
      dispatch({
        type: "CLEAR_BUY_OPTIMIZATION_DATA"
      });
      dispatch({
        type: "SET_PROJECT_STAGE",
        payload: "1-1"
      });
    };
  }
};

export default commonActions;

import React, { Component } from "react";
import moment from "moment";

const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
const nrfWksPatternForAYr = [4, 4, 5, 4, 4, 5, 4, 4, 5, 4, 4, 5];
const moJanIdx = 0;
const moFebIdx = 1;
const _3rdDt = 3;
const _4thDt = 4;
const _4Wks = 4;
const _5Wks = 5;
const nrfCalYrs = {};

function generateNrfCalYrs(yr, nrfCalYrs) {
  const noOfDaysInAWk = days.length;
  const noOfMosInAYr = nrfWksPatternForAYr.length;
  const nrfCalDays = [];
  const stDt = moment()
    .year(yr)
    .month(moFebIdx)
    .date(_4thDt)
    .startOf("week");

  const isPrevYrLeapYr =
    moment(stDt)
      .subtract(1, "year")
      .year() %
      4 ===
    0;

  const doesJanHaveFiveWks =
    stDt.date() === _4thDt || (stDt.date() === _3rdDt && isPrevYrLeapYr);

  doesJanHaveFiveWks && (nrfWksPatternForAYr[moJanIdx] = _5Wks);

  // Inserting Dates by reverse calculating from the start date
  let prevDt = moment(stDt);
  for (let moIdx = moFebIdx - 1; moIdx >= 0; moIdx--) {
    nrfCalDays[moIdx] = [];
    for (let nrfWks = nrfWksPatternForAYr[moIdx] - 1; nrfWks >= 0; nrfWks--) {
      for (let dayIdx = noOfDaysInAWk - 1; dayIdx >= 0; dayIdx--) {
        if (dayIdx === noOfDaysInAWk - 1) {
          nrfCalDays[moIdx][nrfWks] = [];
        }
        prevDt = prevDt.subtract(1, "days");
        nrfCalDays[moIdx][nrfWks].unshift(moment(prevDt));
      }
    }
  }
  // Inserting Dates by forward calculating from the start date
  let nxtDt = moment(stDt);
  for (let moIdx = moFebIdx; moIdx < noOfMosInAYr; moIdx++) {
    nrfCalDays[moIdx] = [];
    for (let nrfWks = 0; nrfWks < nrfWksPatternForAYr[moIdx]; nrfWks++) {
      for (let dayIdx = 0; dayIdx < noOfDaysInAWk; dayIdx++) {
        if (dayIdx === 0) {
          nrfCalDays[moIdx][nrfWks] = [];
        }
        nrfCalDays[moIdx][nrfWks].push(moment(nxtDt));
        nxtDt = nxtDt.add(1, "days");
      }
    }
  }

  // Setting no of weeks for Jan back to default
  doesJanHaveFiveWks && (nrfWksPatternForAYr[moJanIdx] = _4Wks);

  nrfCalYrs[yr] = nrfCalDays;
  console.log(nrfCalYrs);
  return nrfCalYrs;
}

function getStrtWkNo(moIdx) {
  let strtWkNo = 1;
  // As week 1 starts from feb, weeks in Jan are pushed to end
  if (moIdx === 0) moIdx = 12;
  for (let _moIdx = moFebIdx + 1; _moIdx <= moIdx; _moIdx++) {
    strtWkNo += nrfWksPatternForAYr[_moIdx - 1];
  }
  return strtWkNo;
}

const Calendar = function(props) {
  const getCalendarCellStyle = (dt, stDt, endDt) => {
    let className = "calendar-date_default";

    if (props.startDate && !props.endDate) {
      dt.isSame(props.startDate) && (className = "selected-date");
      dt.isAfter(props.startDate) &&
        dt.isSameOrBefore(props.mouseOveredDt) &&
        (className = "calendar-date_hovered");
    } else if (props.startDate && props.endDate) {
      (dt.isSame(props.startDate) || dt.isSame(props.endDate)) &&
        (className = "selected-date");
      dt.isAfter(props.startDate) &&
        dt.isBefore(props.endDate) &&
        (className = "calendar-date_hovered");
      props.mouseOveredDt &&
        (dt.isBefore(props.startDate) || dt.isAfter(props.endDate)) &&
        props.mouseOveredDt.isBetween(
          moment(stDt).subtract(1, "days"),
          moment(endDt).add(1, "days")
        ) &&
        (className = "calendar-date_default-hover");
    } else {
      props.mouseOveredDt &&
        props.mouseOveredDt.isBetween(
          moment(stDt).subtract(1, "days"),
          moment(endDt).add(1, "days")
        ) &&
        (className = "calendar-date_default-hover");
    }
    return className;
  };
  return (
    <div>
      <div className="t-a-c mt-2">
        {props.month.format("MMM")} {props.month.format("YYYY")}
      </div>
      <table className="m-3">
        <tr>
          <th></th>
          {days.map(day => (
            <th className="calendar-cell-size calendar-day">{day}</th>
          ))}
        </tr>
        {props.nrfCalDays.map((week, idx) => (
          <tr>
            <th className="calendar-cell-size calendar-date">
              {props.strtWkNo + idx}
            </th>
            {week.map(dt => {
              return (
                <td
                  onClick={() => {
                    if (!props.startDate) {
                      props.onClick(week[0]);
                    } else {
                      if (dt.isBefore(props.startDate)) {
                        props.onClick(week[0]);
                      } else {
                        props.onClick(week[6]);
                      }
                    }
                  }}
                  onMouseOver={() => {
                    if (props.startDate) {
                      if (dt.isSameOrAfter(props.startDate)) {
                        props.onMouseOver(week[6]);
                      } else {
                        props.onMouseOver(week[0]);
                      }
                    } else {
                      props.onMouseOver(week[0]);
                    }
                  }}
                  className={`calendar-cell-size ${getCalendarCellStyle(
                    dt,
                    week[0],
                    week[6]
                  )} calendar-date`}
                >
                  {dt.date()}
                </td>
              );
            })}
          </tr>
        ))}
      </table>
    </div>
  );
};

class MultipleCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOveredDt: null
    };
  }
  render() {
    return (
      <div style={{ display: "flex" }}>
        {this.props.months.map(month => {
          return (
            <Calendar
              month={month}
              onClick={dt => {
                this.props.onClick(dt);
              }}
              onMouseOver={dt => {
                this.setState({
                  mouseOveredDt: dt
                });
              }}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              strtWkNo={getStrtWkNo(month.month())}
              mouseOveredDt={this.state.mouseOveredDt}
              nrfCalDays={this.props.nrfCalYrs[month.year()][month.month()]}
            />
          );
        })}
      </div>
    );
  }
}

class RetailCalendar extends Component {
  constructor(props) {
    super(props);
    const curDt = props.startDate || moment();
    const dispMosCnt = 2; //min:1, max:12
    const width = { width: `${344 * dispMosCnt}px` };
    let dispMos = [];
    for (let i = 0; i < dispMosCnt; i++) {
      dispMos.push(moment(curDt).add(i, "month"));
    }
    generateNrfCalYrs(curDt.year(), nrfCalYrs);
    generateNrfCalYrs(curDt.year() + 1, nrfCalYrs);
    this.state = {
      dispMos,
      curDt,
      width
    };
  }

  onPrevMoClick = () => {
    const fstMo = this.state.dispMos[0];
    if (fstMo.month() === 0) {
      this.onPrevYrClick(fstMo.year());
    }
    const sftdMosBkByOneMo = this.state.dispMos.map(month => {
      return month.subtract(1, "month");
    });
    this.setState({ dispMos: sftdMosBkByOneMo });
  };
  onNxtMoClick = () => {
    const dspMos = this.state.dispMos;
    const lstMo = dspMos[dspMos.length - 1];
    if (lstMo.month() === 11) {
      this.onNxtYrClick(lstMo.year());
    }
    const sftdMosFwdByOneMo = this.state.dispMos.map(month => {
      return month.add(1, "month");
    });
    this.setState({ dispMos: sftdMosFwdByOneMo });
  };
  onPrevYrClick = yr => {
    const prevYr = yr - 1;
    const nxtYr = yr + 1;
    generateNrfCalYrs(prevYr, nrfCalYrs);
    if (nrfCalYrs.hasOwnProperty(nxtYr)) delete nrfCalYrs[nxtYr];
  };
  onNxtYrClick = yr => {
    const prevYr = yr - 1;
    const nxtYr = yr + 1;
    generateNrfCalYrs(nxtYr, nrfCalYrs);
    if (nrfCalYrs.hasOwnProperty(prevYr)) delete nrfCalYrs[prevYr];
  };
  render() {
    return (
      <div className="m-3" style={{ ...this.state.width }}>
        <div className="arrow left-arrow">
          <i
            onClick={this.onPrevMoClick}
            className="calendar-month-arrow fa fa-angle-left"
            aria-hidden="true"
          ></i>
        </div>
        <div className="arrow right-arrow">
          <i
            onClick={this.onNxtMoClick}
            className="calendar-month-arrow fa fa-angle-right"
            aria-hidden="true"
          ></i>
        </div>
        <MultipleCalendar
          months={this.state.dispMos}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          nrfCalYrs={nrfCalYrs}
          onClick={dt => {
            this.props.onClick(dt);
          }}
        />
      </div>
    );
  }
}

export default RetailCalendar;

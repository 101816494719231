import {
  percentageFormatter,
  roundToTwoDecimal,
  toUnit
} from "../../../helpers/formatters";
import { tar } from "./inputCss";

export default {
  defaultColDef: {
    animateRows: true,
    sortable: true,
    unSortIcon: true,
    resizable: true,
    menuTabs: []
  },
  animateRows: true,
  sortable: true,
  cellClass: "no-border",
  columnDefs: function(lyFlag) {
    let lyOrLly = lyFlag ? "LY" : "LLY";

    return [
      {
        headerName: "APS BANDS",
        field: "APS_band",
        cellStyle: tar,
        width: 100
      },

      {
        headerName: "CHOICES",
        children: [
          {
            headerName: "TY BUY",
            cellStyle: tar,
            field: "TY_Choice_Counts",
            width: 78
          },
          {
            headerName: `${lyOrLly} BUY`,
            width: 78,
            cellStyle: tar,
            field: "LY_Choice_Counts"
          }
        ]
      },
      {
        headerName: "CHOICES %TTL",
        children: [
          {
            headerName: "TY BUY",
            field: "TY_Choices %TTL",
            cellStyle: tar,
            width: 78,
            valueFormatter: percentageFormatter
          },
          {
            headerName: `${lyOrLly} BUY`,
            cellStyle: tar,
            width: 78,
            valueFormatter: percentageFormatter,
            field: "LY_Choices %TTL"
          }
        ]
      },
      {
        headerName: `U SLS ${lyOrLly}`,
        width: 90,
        cellStyle: tar,
        valueFormatter: toUnit,
        field: "LY U Sls"
      },
      {
        headerName: `U SLS %TTL ${lyOrLly}`,
        width: 87,
        cellStyle: tar,
        valueFormatter: percentageFormatter,
        field: "LY U Sls % TTL"
      },
      {
        headerName: "SLS U APS TY",
        width: 89,
        cellStyle: tar,
        field: "TY Sls U APS",
        valueFormatter: roundToTwoDecimal
      },
      {
        headerName: `SLS U APS ${lyOrLly}`,
        width: 89,
        cellStyle: tar,
        field: "LY Sls U APS",
        valueFormatter: roundToTwoDecimal
      }
    ];
  }
};

import axios from "axios";
import pako from "pako";
import {
  PRODUCTION_ENDPOINT,
  DEVELOPMENT_ENDPOINT
} from "../constants/endpoints";

function getDefaultUrl(env = "development") {
  switch (env.toLowerCase()) {
    case "production":
      return PRODUCTION_ENDPOINT;
    case "development":
      return DEVELOPMENT_ENDPOINT;
    default:
      return DEVELOPMENT_ENDPOINT;
  }
}

axios.defaults.baseURL = getDefaultUrl(process.env.NODE_ENV);
axios.defaults.transformRequest = axios.defaults.transformRequest.concat(
  function(data, headers) {
    // compress strings if over 1KB
    if (typeof data === "string" && data.length > 1024) {
      headers["Content-Encoding"] = "gzip";
      return pako.gzip(data);
    } else {
      return data;
    }
  }
);

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.token = token;
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    const errorObject = {
      code: error.response.status,
      ...error.response.data
    };
    if (
      errorObject.code === 403 ||
      errorObject.message === "Token is not valid"
    ) {
      window.localStorage.removeItem("userName");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("emailId");
      window.localStorage.removeItem("status");
      window.localStorage.removeItem("name");
      window.localStorage.removeItem("siteData");
      window.localStorage.removeItem("loggedin");
      window.location.pathname = "/login";
      // route to /
    }
    // Do something with response error
    return Promise.reject(errorObject);
  }
);

export default function api({ url, method, data = null, params = {} }) {
  const requestConfig =
    data === null ? { url, method, params } : { url, method, data, params };
  console.log(`API Name : ${url} with data`);
  console.log(data);
  return new Promise((resolve, reject) => {
    axios
      .request(requestConfig)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
}

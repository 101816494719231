export const classNameKey = "class/vendor";
export const subClassNameKey = "subclass";
export const aurKey = "aur";
export const actRcptDollarKey = "ACT RCPT $";
export const tyApsKey = "TY APS";
export const lyApsKey = "LY APS";
export const tyStPercentKey = "TY ST%";
export const lyStPercentKey = "LY ST%";
export const depthClusterPartialKey = "depthCluster";
export const choiceClusterPartialKey = "choiceCluster";
export const lyDepthClusterPartialKey = "lyDepthCluster";
export const lyChoiceClusterPartialKey = "lyChoiceCluster";
export const ecomKey = "ecom";
export const rcptPenePercentKey = "rcpt_pen%";
export const varToPlanKey = "VAR TO PLAN";
export const palnCCKey = "PLAN CC";
export const planRcptDollarKey = "plan_rcpt$";
export const tyCCKey = "ty_cc";
export const lyCCKey = "ly_cc";
export const maxCCKey = "CC";
export const rcptDollarKey = "rcpt$";
export const rcptUnitsKey = "rcpt_u";
export const lYRcptDollarKey = "ly_rcpt$";
export const subGrpRcptWeightagePercentKey = "%_class/vendor_rcpts";
export const lysubGrpRcptWeightagePercentKey = "%_class/vendor_rcpts_ly";
export const lyRcptPercentChangeKey = "%chg_ly";
export const tyAvgBuyKey = "ty_avg_buy";
export const tyStyleCountKey = "ty_sty_count";
export const lyStyleCountKey = "ly_sty_count";
export const percentChangeStyleCountKey = "%chg_sty_count";
export const percentChangeCCKey = "%chg_cc";

export const tySubStringKey = "%TY";
export const tyTotalKey = "TYTotal";

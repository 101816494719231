import { inputCss, tar } from "../../../styles";
import {
  decimalToPercentage,
  toPercentage,
  toUnitNoData,
  toDollar,
  decimalFormatter$,
  toUnit
} from "../../../helpers/formatters";
export default {
  defaultColDef: {
    animateRows: true,
    resizable: true,
    sortable: true,
    width: 100,
    menuTabs: []
  },
  animateRows: true,
  sortable: true,
  filter: true,
  unSortIcon: true,
  suppressCellSelection: true,
  cellClass: "no-border",
  columnDefs: function(selectedPrimaryAttributes, omniRcptDollarFlag) {
    const columns = [
      {
        headerName: "APX INPUT",
        children: [
          {
            width: 200,
            headerName: "Class/Vendor",
            filter: "agTextColumnFilter",
            field: "class_vendor"
          },
          {
            width: 200,
            headerName: "Sub Group",
            filter: "agTextColumnFilter",
            field: "sub_group"
          },
          {
            width: 200,
            headerName: "Style Color Choice",
            filter: "agTextColumnFilter",
            field: "style_color_choice"
          },
          {
            width: 100,
            headerName: "MSRP",
            filter: "agTextColumnFilter",
            cellStyle: tar,
            field: "msrp",
            valueFormatter: decimalFormatter$
          },
          {
            width: 100,
            cellStyle: tar,
            headerName: "AUC",
            filter: "agTextColumnFilter",
            field: "auc",
            valueFormatter: decimalFormatter$
          },
          {
            width: 100,
            headerName: "FC-MBQ",
            filter: "agTextColumnFilter",
            cellStyle: tar,
            field: "fc_mbq",
            valueFormatter: toUnitNoData
          },
          {
            width: 100,
            headerName: "Door Count",
            filter: "agTextColumnFilter",
            cellStyle: tar,
            field: "door_count",
            valueFormatter: toUnit
          },
          // {
          //   width: 100,
          //   headerName: "Override Case Pack",
          //   filter: "agTextColumnFilter",
          //   field: "override_case_pack",
          //   editable: true,
          //   cellStyle: inputCss,
          //   valueFormatter: toUnit
          // },
          {
            width: 100,
            headerName: "FC Unit Override",
            filter: "agTextColumnFilter",
            field: "fc_unit_override",
            editable: true,
            cellStyle: inputCss,
            valueFormatter: toUnit
          }
          // {
          //   width: 100,
          //   headerName: "FC % to B&M",
          //   filter: "agTextColumnFilter",
          //   field: "fc_perc_to_bm",
          //   valueFormatter: toPercentage
          // },
        ]
      },
      {
        headerName: "INFO - REFER TO 2-4 TOTAL BUY",
        headerTooltip: "INFO - REFER TO 2-4 TOTAL BUY",
        children: [
          {
            width: 100,
            headerName: "Total Buy Units",
            cellStyle: tar,
            filter: "agTextColumnFilter",
            field: "total_buy",
            valueFormatter: toUnit
          }
        ]
      },
      {
        headerName: "(ADJ TY RCPT PLN/PLN %)",
        children: [
          {
            width: 100,
            headerName: "B&M Buy Units",
            cellStyle: tar,
            filter: "agTextColumnFilter",
            field: "orig_bm_buy",
            valueFormatter: toUnit
          },
          {
            width: 100,
            headerName: omniRcptDollarFlag ? "FC Unit Buy" : "FC Unit Buy",
            filter: "agTextColumnFilter",
            field: "orig_fc_buy",
            valueFormatter: toUnitNoData
          }
        ]
      },
      {
        headerName: omniRcptDollarFlag ? "RESPREAD" : "RESPREAD",
        children: [
          {
            width: 100,
            headerName: "B&M Buy Units",
            cellStyle: tar,
            filter: "agTextColumnFilter",
            field: "bm_buy_units",
            valueFormatter: toUnit
          },
          {
            width: 100,
            headerName: omniRcptDollarFlag ? "FC Unit Buy" : "FC Unit Buy",
            filter: "agTextColumnFilter",
            field: "fc_unit_buy_adj_plan",
            cellStyle: params => {
              console.log(params);
              if (+params.value < +params.data.fc_mbq) {
                return { backgroundColor: "rgb(242, 142, 131)", ...tar };
              }
              return tar;
            },
            valueFormatter: toUnitNoData
          },
          {
            width: 100,
            headerName: omniRcptDollarFlag ? "FC $ Buy" : "FC $ Buy",
            filter: "agTextColumnFilter",
            cellStyle: tar,
            field: "fc_buy",
            valueFormatter: toDollar
          },
          {
            width: 100,
            headerName: "FC Unit Buy Reflow",
            cellStyle: tar,
            filter: "agTextColumnFilter",
            field: "fc_reflow",
            valueFormatter: toUnit
          }
        ]
      },
      {
        headerName: "(INFO - after applying increments and min-max)",
        headerTooltip: "INFO - after applying increments and min-max",
        children: [
          {
            width: 100,
            headerName: "B&M Unit Buy",
            filter: "agTextColumnFilter",
            field: "Total_BM_buy_adj",
            valueFormatter: toUnit
          }
        ]
      },
      {
        width: 120,
        headerName: "Action",
        filter: "agTextColumnFilter",
        field: "action",
        editable: true,
        cellStyle: function(params) {
          if (params.value) {
            return {
              "background-color": "#C1D5FF"
            };
          } else {
            return {
              "background-color": "#f28e83"
            };
          }
        },
        cellEditorSelector: function(params) {
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: ["FC Approved", "Store Only"]
            }
          };
        }
      }
    ];
    if (selectedPrimaryAttributes && selectedPrimaryAttributes.length) {
      const primaryAttrColumns = [];
      selectedPrimaryAttributes.forEach(element => {
        primaryAttrColumns.push({
          headerName: element,
          width: 137,
          valueGetter: params => {
            return params.data.primary_attributes[element];
          }
        });
      });
      columns[0].children.splice(3, 0, ...primaryAttrColumns);
    }
    return columns;
  }
};

import { cloneDeep, map } from "lodash";
import { toast } from "react-toastify";
import {
  actRcptDollarKey,
  aurKey,
  choiceClusterPartialKey,
  depthClusterPartialKey,
  ecomKey,
  lyApsKey,
  lyChoiceClusterPartialKey,
  lyDepthClusterPartialKey,
  lyStPercentKey,
  maxCCKey,
  palnCCKey,
  planRcptDollarKey,
  rcptPenePercentKey,
  tyApsKey,
  tyCCKey,
  tyStPercentKey,
  varToPlanKey
} from "../constants/keys";
import api from "../axios";
import wedgeBalancing from "../services/wedgeBalancing";

function calculateMinMaxTable(goodBetterBestTable, vHistParamObj, clusters) {
  const histParamObj = cloneDeep(vHistParamObj);
  /*******Min Max Table Calculations *****************/
  function RoundTo(number, roundto) {
    return roundto * Math.round(number / roundto);
  }
  histParamObj["MIN B&M"] = histParamObj["min"] || 3;
  // histParamObj["MIN ST%"] = 50;
  // histParamObj["MAX ST%"] = 80;
  histParamObj["INCREMENTS B&M"] = 4;
  histParamObj["INCREMENTS ECOM"] = 3;
  histParamObj["variancePerMonth"] = 10;
  histParamObj["variancePerCluster"] = 30;
  histParamObj["INCREMENTS ECOM"] = 3;
  // histParamObj["styleLevel"] = [];
  // histParamObj["depthAndChoiceData"] = [];

  const maxDepth = clusters.reduce((acc, currCluster, index) => {
    if (+currCluster === 0) return acc;
    const currClusterDepth = histParamObj[depthClusterPartialKey + currCluster];
    return Math.max(acc, currClusterDepth);
  }, 0);

  histParamObj["MAX B&M"] = RoundTo(
    maxDepth * goodBetterBestTable[1].Range1,
    histParamObj["INCREMENTS B&M"]
  );
  if (histParamObj["MAX B&M"] < histParamObj["MIN B&M"])
    histParamObj["MAX B&M"] = histParamObj["MIN B&M"];
  histParamObj["MIN ECOM"] =
    histParamObj.min_buy_qty ||
    RoundTo(
      histParamObj.depthCluster0 * goodBetterBestTable[1].Range5,
      histParamObj["INCREMENTS ECOM"]
    );
  histParamObj["MAX_ECOM"] = RoundTo(
    histParamObj.depthCluster0 * goodBetterBestTable[1].Range1,
    histParamObj["INCREMENTS ECOM"]
  );
  if (histParamObj["MAX_ECOM"] < histParamObj["MIN ECOM"])
    histParamObj["MAX_ECOM"] = histParamObj["MIN ECOM"];
  return histParamObj;
}

function getDepthMuliplierValue(goodBetterBestTable, value) {
  let depthMultiplier;
  const rangeObj = goodBetterBestTable[0];
  const depthMulipliersObj = goodBetterBestTable[1];
  var sortableRange = [];
  for (var range in rangeObj) {
    sortableRange.push([range, rangeObj[range]]);
  }
  sortableRange.sort(function(a, b) {
    return a[1] - b[1];
  });
  for (const range of sortableRange) {
    if (value <= +range[1]) {
      depthMultiplier = depthMulipliersObj[range[0]];
      break;
    }
  }
  return depthMultiplier;
}

function calculateStyleLevelDepth(
  clusters,
  clusterData,
  goodBetterBestTable,
  histParamObj
) {
  const styleLevelDepth = {};
  const storesObj = clusterData[0];
  for (let styleNo = 1; styleNo <= Math.round(histParamObj["CC"]); styleNo++) {
    styleLevelDepth[styleNo] = {};
    let totalPlannedUnits = 0;
    for (let cluster of clusters) {
      const percent =
        (styleNo * 100) / histParamObj.depthAndChoiceData[1][cluster];
      const depthMultiplier =
        percent <= 100
          ? getDepthMuliplierValue(goodBetterBestTable, percent)
          : 0;
      const plannedDepth =
        histParamObj.depthAndChoiceData[0][cluster] * depthMultiplier;
      styleLevelDepth[styleNo][`depthMultiplier${cluster}`] = depthMultiplier;
      styleLevelDepth[styleNo][`plannedDepth${cluster}`] = plannedDepth;
    }
    styleLevelDepth[styleNo][`totalPlannedUnits`] = totalPlannedUnits;
  }

  clusters.forEach(cluster => {
    let totalPlannedUnitsPerCLuster = Object.values(styleLevelDepth).reduce(
      (acc, style) => {
        return acc + style[`plannedDepth${cluster}`];
      },
      0
    );
    for (const styleNo in styleLevelDepth) {
      const plannedUnitsPerCluster =
        histParamObj.depthAndChoiceData[0][cluster] *
        histParamObj.depthAndChoiceData[1][cluster];
      styleLevelDepth[styleNo][`plannedDepth${cluster}`] =
        (styleLevelDepth[styleNo][`plannedDepth${cluster}`] /
          totalPlannedUnitsPerCLuster) *
          plannedUnitsPerCluster || 0;
    }
  });

  for (const styleNo in styleLevelDepth) {
    let totalPlannedUnits = 0;
    for (let cluster of clusters) {
      totalPlannedUnits +=
        styleLevelDepth[styleNo][`plannedDepth${cluster}`] * storesObj[cluster];
    }
    styleLevelDepth[styleNo][`totalPlannedUnits`] = totalPlannedUnits;
  }
  return styleLevelDepth;
}

function setDepthAndChoiceDataStyleLevel(histParamObj, clusters) {
  let tempDepthObj = {};
  let tempChoiceObj = {};
  const depthAndChoiceData = [];
  for (let cluster of clusters) {
    tempDepthObj.key = "Avg Depth Rec";
    tempChoiceObj.key = "CC Count Rec";
    tempDepthObj[cluster] = histParamObj[`depthCluster${cluster}`];
    tempChoiceObj[cluster] = histParamObj[`choiceCluster${cluster}`];
  }
  depthAndChoiceData.push(tempDepthObj);
  depthAndChoiceData.push(tempChoiceObj);
  return depthAndChoiceData;
}

function generateSubClassStyles({
  clusters,
  clusterData,
  vHistParamObj,
  vRcptMonths,
  isSeasonLevel,
  month,
  goodBetterBestTable
}) {
  const styleLevel = {};
  const styleLevelArray = [];
  const histParamObj = cloneDeep(vHistParamObj);
  const clusterDoorCountObj = clusterData[0];
  const styleLevelPlannedDepth = histParamObj.styleLevelDepth;
  const maxVariancePerMonth = isSeasonLevel
    ? histParamObj.variancePerMonth / 100
    : 0; // $AH$37
  const maxVariancePerCluster = histParamObj.variancePerCluster / 100; // $AH$38
  const storeMin = histParamObj["MIN B&M"];
  const ecomMin = histParamObj["MIN ECOM"];
  const storeMax = histParamObj["MAX B&M"];
  const ecomMax = histParamObj["MAX_ECOM"];
  const increments = histParamObj["INCREMENTS B&M"];
  for (let styleNo = 1; styleNo <= Math.round(histParamObj["CC"]); styleNo++) {
    const len = isSeasonLevel ? vRcptMonths.length : 1;
    for (let moIdx = 0; moIdx < len; moIdx++) {
      var tempObj = (styleLevel[`${styleNo}-${vRcptMonths[moIdx]}`] = {});
      tempObj["month"] = isSeasonLevel ? vRcptMonths[moIdx] : month;
      if (isSeasonLevel) {
        tempObj["proportion"] = histParamObj[vRcptMonths[moIdx]];
      }
      tempObj["category"] = styleNo;
      tempObj["subclass"] = histParamObj.subclass;
      const styleName =
        histParamObj["class/vendor"] +
        " " +
        histParamObj.subclass +
        " Style " +
        styleNo;
      tempObj["style_name"] = styleName;
      tempObj["CC"] =
        styleName + (isSeasonLevel ? ` ${vRcptMonths[moIdx]}` : ` ${month}`);
      tempObj["rcpt_aur"] = histParamObj.aur;
      const monthlyPene = isSeasonLevel ? tempObj["proportion"] / 100 : 1;
      const totalPlannedUnitsForMonth =
        styleLevelPlannedDepth[styleNo].totalPlannedUnits * monthlyPene;
      tempObj["plannedUnits"] = totalPlannedUnitsForMonth;
      clusters.sort((a, b) => {
        if (+a && +b) {
          return a - b;
        }
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });

      for (let index = 0; index < clusters.length; index++) {
        const cluster = clusters[index];
        const repectiveClusterDoorCount = clusterDoorCountObj[cluster];
        const respectiveClusterPlannedDepth =
          styleLevelPlannedDepth[styleNo][`plannedDepth${cluster}`];
        tempObj[`plannedDepth${cluster}`] = respectiveClusterPlannedDepth;
        const depthForMonthSoFar = [];
        const storeCountSoFar = [];
        const clustersSoFar = clusters.slice(0, index);
        for (const clus of clustersSoFar) {
          depthForMonthSoFar.push(tempObj[clus]);
          storeCountSoFar.push(clusterDoorCountObj[clus]);
        }
        const depthForClusterSoFar = [];
        for (let moIdxSoFar = 0; moIdxSoFar < moIdx; moIdxSoFar++) {
          depthForClusterSoFar.push(
            styleLevel[`${styleNo}-${vRcptMonths[moIdxSoFar]}`][cluster]
          );
        }
        tempObj[cluster] = wedgeBalancing({
          maxVariancePerMonth,
          maxVariancePerCluster,
          storeMin,
          ecomMin,
          increments,
          repectiveClusterDoorCount,
          respectiveClusterPlannedDepth,
          monthlyPene,
          totalPlannedUnitsForMonth,
          depthForMonthSoFar,
          storeCountSoFar,
          depthForClusterSoFar,
          cluster,
          isSeasonLevel
        });

        // checking max condition start
        if (isSeasonLevel) {
          if (cluster > 0) {
            tempObj[cluster] > storeMax && (tempObj[cluster] = storeMax);
          } else {
            tempObj[cluster] > ecomMax && (tempObj[cluster] = ecomMax);
          }
        }
        // checking max condition end
      }

      styleLevelArray.push(tempObj);
    }
  }
  histParamObj["styleLevel"] = styleLevelArray;

  histParamObj.styleLevel.forEach((tempObj, index) => {
    let total_u = 0;
    let DR_COUNT = 0;
    let styleClusterUnitsCount = 0;
    for (let j of clusters) {
      total_u += isNaN(clusterData[0][j] * tempObj[j])
        ? 0
        : clusterData[0][j] * tempObj[j];

      if (tempObj[j]) {
        // included ecom_12-12-2019
        DR_COUNT += isNaN(clusterData[0][j]) ? 0 : clusterData[0][j];
      }
      tempObj[j] > 0 && styleClusterUnitsCount++;

      // Adding validity values
      if (isSeasonLevel) {
        +tempObj[j] > 0 ? (tempObj[`v_${j}`] = 1) : (tempObj[`v_${j}`] = 0);
      }
    }
    tempObj["styleCountMultiplier"] = (index + 1) * styleClusterUnitsCount;
    tempObj["total_u"] = total_u;
    tempObj["total$"] = tempObj["rcpt_aur"] * tempObj["total_u"];
    tempObj["DR COUNT"] = DR_COUNT;
    tempObj["AVG DEPTH"] = tempObj["total_u"] / tempObj["DR COUNT"] || 0;
    tempObj["WEEKS REG"] = histParamObj.reg_week;
  });
  histParamObj.styleLevel.forEach((style, index) => {
    const totalStylesCount = histParamObj.styleLevel.length;
    const choiceCount = histParamObj["CC"];
    const monthCount = totalStylesCount / choiceCount;
    const styleNum = Math.ceil((index + 1) / monthCount);
    const depthMultiplierCheck = styleNum / choiceCount;
    let num = 0;
    let count1 = 0;
    for (let k in goodBetterBestTable[0]) {
      if (k !== "goodBetterBest") {
        if (goodBetterBestTable[0][k] / 100 <= depthMultiplierCheck) {
          num += isNaN(count1 + 1) ? 0 : count1 + 1;
        }
      }
    }
    let Count = num;
    let avg_depth_multiplier_final = 0;
    avg_depth_multiplier_final = Number(
      goodBetterBestTable[1][`Range${Count + 1}`]
    );
    let TY_ST = 0;
    TY_ST += isNaN(avg_depth_multiplier_final * histParamObj["TY ST%"])
      ? 0
      : avg_depth_multiplier_final * histParamObj["TY ST%"];
    if (TY_ST < histParamObj["minST%"]) {
      style["ST%"] = histParamObj["minST%"];
    } else if (TY_ST > histParamObj["maxST%"]) {
      style["ST%"] = histParamObj["maxST%"];
    } else {
      style["ST%"] = TY_ST;
    }
    style["APS"] =
      ((style["total_u"] / style["DR COUNT"] / style["WEEKS REG"]) *
        style["ST%"]) /
        100 || 0;
  });
  if (histParamObj["styleLevel"].length === 1) {
    histParamObj["styleLevel"][0]["ST%"] = histParamObj["TY ST%"];
  }
  return histParamObj;
}

function subClassLevelStyleCount(
  clusters,
  clusterData,
  goodBetterBestTable,
  vHistParamObj,
  vRcptMonths,
  isSeasonLevel,
  month,
  selectedPrimaryAttributes
) {
  const styleLevelKey = "styleLevel";
  const histParamObj = cloneDeep(vHistParamObj);
  /******* style level subclass calculations ************/
  histParamObj[styleLevelKey] = [];
  for (let i = 1; i <= Math.round(histParamObj["CC"]); i++) {
    const len = isSeasonLevel ? vRcptMonths.length : 1;
    for (let moIdx = 0; moIdx < len; moIdx++) {
      var tempObj = {};
      tempObj["month"] = isSeasonLevel ? vRcptMonths[moIdx] : month;
      if (isSeasonLevel) {
        tempObj["proportion"] = histParamObj[vRcptMonths[moIdx]];
      }
      tempObj["category"] = i;
      tempObj["subclass"] = histParamObj.subclass;
      const styleName =
        histParamObj["class/vendor"] +
        " " +
        histParamObj.subclass +
        " Style " +
        i;
      tempObj["style_name"] = styleName;
      tempObj["CC"] =
        styleName + (isSeasonLevel ? ` ${vRcptMonths[moIdx]}` : ` ${month}`);
      tempObj["rcpt_aur"] = histParamObj.aur;
      for (let j of clusters) {
        let n = i;
        let m = n / histParamObj.depthAndChoiceData[1][j];
        let num = 0;
        let count1 = 0;
        for (let k in goodBetterBestTable[0]) {
          if (k !== "goodBetterBest") {
            if (goodBetterBestTable[0][k] / 100 <= m) {
              num += isNaN(count1 + 1) ? 0 : count1 + 1;
            }
          }
        }
        let Count = num;
        let avg_depth_multiplier_final = 0;
        avg_depth_multiplier_final = Number(
          goodBetterBestTable[1][`Range${Count + 1}`]
        );

        // Proportions
        if (isSeasonLevel) {
          avg_depth_multiplier_final =
            avg_depth_multiplier_final * (tempObj["proportion"] / 100);
        }

        if (n > Number(histParamObj.depthAndChoiceData[1][j])) {
          tempObj[j] = 0;
        } else {
          function RoundTo(number, roundto) {
            return roundto * Math.round(number / roundto);
          }
          let depth_of_cluster = 0;
          depth_of_cluster = RoundTo(
            avg_depth_multiplier_final * histParamObj.depthAndChoiceData[0][j],
            histParamObj["INCREMENTS B&M"]
          );
          tempObj[j] = depth_of_cluster;
          if (!isSeasonLevel || tempObj["proportion"] > 0) {
            if (j === "0") {
              if (depth_of_cluster < histParamObj["MIN ECOM"]) {
                tempObj[j] = histParamObj["MIN ECOM"];
              } else if (depth_of_cluster > histParamObj["MAX_ECOM"]) {
                tempObj[j] = histParamObj["MAX_ECOM"];
              } else {
                tempObj[j] = depth_of_cluster;
              }
            } else {
              if (depth_of_cluster < histParamObj["MIN B&M"]) {
                tempObj[j] = histParamObj["MIN B&M"];
              } else if (depth_of_cluster > histParamObj["MAX B&M"]) {
                tempObj[j] = histParamObj["MAX B&M"];
              } else {
                tempObj[j] = depth_of_cluster;
              }
            }
          }
        }
      }

      histParamObj[styleLevelKey].push(tempObj);
    }
  }
  if (histParamObj.stylesAttrMapping && selectedPrimaryAttributes) {
    histParamObj[styleLevelKey] = mapNewStylesData({
      styleData: histParamObj[styleLevelKey],
      clusters,
      stylesAttrMapping: histParamObj.stylesAttrMapping,
      selectedPrimaryAttributes
    });
  }

  histParamObj.styleLevel.forEach((tempObj, index) => {
    let total_u = 0;
    let DR_COUNT = 0;
    let styleClusterUnitsCount = 0;
    for (let j of clusters) {
      total_u += isNaN(clusterData[0][j] * tempObj[j])
        ? 0
        : clusterData[0][j] * tempObj[j];

      if (tempObj[j]) {
        // included ecom_12-12-2019
        DR_COUNT += isNaN(clusterData[0][j]) ? 0 : clusterData[0][j];
      }
      tempObj[j] > 0 && styleClusterUnitsCount++;

      // Adding validity values
      if (isSeasonLevel) {
        +tempObj[j] > 0 ? (tempObj[`v_${j}`] = 1) : (tempObj[`v_${j}`] = 0);
      }
    }
    tempObj["styleCountMultiplier"] = (index + 1) * styleClusterUnitsCount;
    tempObj["total_u"] = total_u;
    tempObj["total$"] = tempObj["rcpt_aur"] * tempObj["total_u"];
    tempObj["DR COUNT"] = DR_COUNT;
    tempObj["AVG DEPTH"] = tempObj["total_u"] / tempObj["DR COUNT"] || 0;
    tempObj["WEEKS REG"] = histParamObj.reg_week;
  });
  histParamObj.styleLevel.forEach((style, index) => {
    const totalStylesCount = histParamObj.styleLevel.length;
    const choiceCount = histParamObj["CC"];
    const monthCount = totalStylesCount / choiceCount;
    const styleNum = Math.ceil((index + 1) / monthCount);
    const depthMultiplierCheck = styleNum / choiceCount;
    let num = 0;
    let count1 = 0;
    for (let k in goodBetterBestTable[0]) {
      if (k !== "goodBetterBest") {
        if (goodBetterBestTable[0][k] / 100 <= depthMultiplierCheck) {
          num += isNaN(count1 + 1) ? 0 : count1 + 1;
        }
      }
    }
    let Count = num;
    let avg_depth_multiplier_final = 0;
    avg_depth_multiplier_final = Number(
      goodBetterBestTable[1][`Range${Count + 1}`]
    );
    let TY_ST = 0;
    TY_ST += isNaN(avg_depth_multiplier_final * histParamObj["TY ST%"])
      ? 0
      : avg_depth_multiplier_final * histParamObj["TY ST%"];
    if (TY_ST < histParamObj["minST%"]) {
      style["ST%"] = histParamObj["minST%"];
    } else if (TY_ST > histParamObj["maxST%"]) {
      style["ST%"] = histParamObj["maxST%"];
    } else {
      style["ST%"] = TY_ST;
    }
    style["APS"] =
      ((style["total_u"] / style["DR COUNT"] / style["WEEKS REG"]) *
        style["ST%"]) /
        100 || 0;
  });

  // ST % calculation extra layer start
  // const stPercentSumProductWithTotalUnits = histParamObj.styleLevel.reduce(
  //   (acc, style) => acc + ((+style.total_u * +style["ST%"]) / 100 || 0),
  //   0
  // );
  // const totalUnits = histParamObj.styleLevel.reduce(
  //   (acc, style) => acc + (+style.total_u || 0),
  //   0
  // );
  // const stPercentAvgSumProduct = stPercentSumProductWithTotalUnits / totalUnits;

  // histParamObj.styleLevel.forEach(style => {
  //   const tyStPercent = histParamObj["TY ST%"] / 100;
  //   const stPercent = style["ST%"] / 100;
  //   style["ST%"] = (tyStPercent / stPercentAvgSumProduct) * stPercent;
  //   style["ST%"] = style["ST%"] * 100;
  //   const minStPercent = 0;
  //   const maxStPercent = 100;
  //   style["ST%"] = Math.min(Math.max(style["ST%"], minStPercent), maxStPercent); // Capping to 100%
  // });
  // ST % calculation extra layer end

  if (histParamObj[styleLevelKey].length === 1) {
    histParamObj[styleLevelKey][0]["ST%"] = histParamObj["TY ST%"];
  }
  return histParamObj;
}

function getClusterSummaryTable(clusters, clusterData, vHistParamData) {
  const historicalParameterData = cloneDeep(vHistParamData);
  let arr = [];
  let clustersLength = clusters.includes("0")
    ? clusters.length - 1
    : clusters.length;
  clusterData.forEach(obj => {
    if (obj.keys === "# OF DOORS") {
      arr.push(obj);
    }
    if (obj.keys === "CUM DR COUNT") {
      arr.push(obj);
    }
  });
  let tempObj = {};
  let tempMax = 0;
  let classSubClass = Object.keys(historicalParameterData);
  clusters.forEach(obj => {
    let total_CC_Count = 0;
    classSubClass.forEach(classSubclassval => {
      // historicalParameterData[classSubclassval].forEach(historicalParameterRow => {
      total_CC_Count += isNaN(
        historicalParameterData[classSubclassval][`choiceCluster${obj}`]
      )
        ? 0
        : historicalParameterData[classSubclassval][`choiceCluster${obj}`];
      // });
    });
    tempObj[obj] = total_CC_Count;
    if (tempMax < total_CC_Count) tempMax = total_CC_Count;
    tempObj.keys = "Total CC Count";
  });
  tempObj.Total = tempMax;
  arr.push(tempObj);

  // BY Varsha
  let tempOBjCL = {};
  let tempObj$ = {};
  let ecom_TotalCL = 0;
  let ecom_Total$ = 0;
  for (let ind = 1; ind <= Number(clustersLength); ind++) {
    tempOBjCL[clusters[ind]] = 0;
    tempOBjCL.Total = 0;
    tempObj$[clusters[ind]] = 0;
    tempObj$.Total = 0;
  }
  let tempKey = Object.keys(historicalParameterData[classSubClass[0]]);

  // if (historicalParameterData.length) {
  classSubClass.forEach(classSubClassVal => {
    // historicalParameterData[classSubClassVal].forEach(ele2 => {
    let tempObj1 = {};
    let tempObj2 = {};
    let tempEcom_TotalCL = 0;
    let tempEcom_Total$ = 0;
    for (let ind = 1; ind <= Number(clustersLength); ind++) {
      tempObj1[clusters[ind]] = 0;
      tempObj2[clusters[ind]] = 0;
    }
    historicalParameterData &&
      historicalParameterData[classSubClassVal] &&
      historicalParameterData[classSubClassVal].styleLevel &&
      historicalParameterData[classSubClassVal].styleLevel.length &&
      historicalParameterData[classSubClassVal].styleLevel.forEach(ele => {
        for (let ind = 1; ind <= Number(clustersLength); ind++) {
          tempObj1[clusters[ind]] += ele[clusters[ind]];
          tempObj2[clusters[ind]] += ele[clusters[ind]];
        }

        if ("0" in tempKey) {
          tempEcom_TotalCL =
            tempEcom_TotalCL + (isNaN(ele["0"]) ? 0 : ele["0"]);
          tempEcom_Total$ = tempEcom_Total$ + (isNaN(ele["0"]) ? 0 : ele["0"]);
        }
      });
    for (let ind = 1; ind <= Number(clustersLength); ind++) {
      tempOBjCL[clusters[ind]] +=
        tempObj1[clusters[ind]] * clusterData[0][clusters[ind]];
      tempObj$[clusters[ind]] +=
        tempObj2[clusters[ind]] *
        clusterData[0][clusters[ind]] *
        historicalParameterData[classSubClassVal].aur;
      // tempOBjCL.Total += tempOBjCL[clusters[ind]];
      // tempObj$.Total += tempObj$[clusters[ind]];
    }
    if ("0" in tempKey) {
      ecom_Total$ =
        ecom_Total$ +
        (isNaN(tempEcom_Total$) ? 0 : tempEcom_Total$) *
          historicalParameterData[classSubClassVal].aur;
      ecom_TotalCL += tempEcom_TotalCL;
    }
  });
  // })
  tempOBjCL.Total = 0;
  for (let ind = 1; ind <= Number(clustersLength); ind++) {
    tempOBjCL.Total += tempOBjCL[clusters[ind]];
    tempObj$.Total += tempObj$[clusters[ind]];
  }
  if ("0" in tempKey) {
    tempOBjCL.Total += isNaN(ecom_TotalCL) ? 0 : ecom_TotalCL;
    tempObj$.Total += isNaN(ecom_Total$) ? 0 : ecom_Total$;
  }
  // }
  // arr[0]["0"] = arr[0]["0"] && ecom_Total;
  let tempObjRCPT = { ...tempOBjCL };
  tempObjRCPT.keys = "Total RCPT Units";
  let tempObjRCPT$ = { ...tempObj$ };
  tempObjRCPT$.keys = "Total RCPT $";
  if ("0" in tempKey) {
    tempObjRCPT["0"] = ecom_TotalCL;
    tempObjRCPT$["0"] = ecom_Total$;
  }
  arr.push(tempObjRCPT);
  arr.push(tempObjRCPT$);
  return arr;
}

function calculateDepthAndChoice(
  clusters,
  clusterData,
  subClassRcptPenObj,
  buyerTotalBudget,
  vHistParamObj,
  budgetProportions,
  lyBudgetProportions
) {
  const histParamObj = cloneDeep(vHistParamObj);
  histParamObj[aurKey] = Math.round(histParamObj[aurKey]);

  let tempArr = [];
  histParamObj[actRcptDollarKey] = 0;
  // Looping over cluster to calculate cluster level choice and depth count
  clusters.forEach(clusterNumber => {
    // calculating adjustedAPS
    // Formula (TY APS / LY APS) * APS
    const adjustedAps = +histParamObj[lyApsKey]
      ? (+histParamObj[tyApsKey] / +histParamObj[lyApsKey]) *
        histParamObj[clusterNumber].APS
      : +histParamObj[tyApsKey];

    // calculating adjustedSellThru
    // Formula (TY ST% / LY ST%) * Sell_through
    const adjustedSellThru = +histParamObj[lyStPercentKey]
      ? (+histParamObj[tyStPercentKey] / +histParamObj[lyStPercentKey]) *
        histParamObj[clusterNumber].Sell_through
      : +histParamObj[tyStPercentKey] / 100;

    // Depth value calculation for respective cluster
    // Formula (adjustedAps * reg_week) / adjustedSellThru

    histParamObj[`${depthClusterPartialKey}${clusterNumber}`] = Math.round(
      (adjustedAps * +histParamObj.reg_week) / adjustedSellThru
    );

    // Checking infinity and replacing with zero
    if (!isFinite(histParamObj[`${depthClusterPartialKey}${clusterNumber}`])) {
      histParamObj[`${depthClusterPartialKey}${clusterNumber}`] = 0;
    }

    // Inserting LY choice and depth cluster to respective data tables

    histParamObj[`${lyDepthClusterPartialKey}${clusterNumber}`] =
      histParamObj[clusterNumber].ly_depth_data;
    histParamObj[`${lyChoiceClusterPartialKey}${clusterNumber}`] =
      histParamObj[clusterNumber].ly_choice_data;

    // Checking min value for depth and replacing if required
    if (
      histParamObj[`${depthClusterPartialKey}${clusterNumber}`] <
      histParamObj.min
    ) {
      histParamObj[`${depthClusterPartialKey}${clusterNumber}`] =
        histParamObj.min;
    }

    /* 
      Choice Count Calculation
      Formula: (subClassRcpt% * subClassCluster% * buyerTotalBudget) / (storeCount * AUR * depth)
    */
    histParamObj[`${choiceClusterPartialKey}${clusterNumber}`] = Math.round(
      ((subClassRcptPenObj[`TY${clusterNumber}`] / 100) *
        (subClassRcptPenObj[rcptPenePercentKey] / 100) *
        buyerTotalBudget) /
        (clusterData[0][clusterNumber] *
          histParamObj[aurKey] *
          histParamObj[`${depthClusterPartialKey}${clusterNumber}`])
    );

    // Checking infinity and replacing with zero
    if (!isFinite(histParamObj[`${choiceClusterPartialKey}${clusterNumber}`])) {
      histParamObj[`${choiceClusterPartialKey}${clusterNumber}`] = 0;
    }
  });

  /*
    Check for MATCH ECOM CC TO CL 1 ? Y : N
    if "Y" 
    choiceCount of Ecom = choiceCount of Cluster1
    depthCount of Ecom = (subClassRcpt% * subClassCluster% * buyerTotalBudget) / (storeCount * AUR * cc)
  */
  if (histParamObj[ecomKey] === "Y") {
    histParamObj[`${choiceClusterPartialKey}0`] =
      histParamObj[`${choiceClusterPartialKey}${clusters[0]}`];
    histParamObj[`${depthClusterPartialKey}0`] =
      ((subClassRcptPenObj[`TY0`] / 100) *
        (subClassRcptPenObj[rcptPenePercentKey] / 100) *
        buyerTotalBudget) /
      (clusterData[0][0] *
        histParamObj[aurKey] *
        histParamObj[`${choiceClusterPartialKey}0`]);
  }

  // Checking infinity and replacing with zero
  if (!isFinite(histParamObj[`${depthClusterPartialKey}0`])) {
    histParamObj[`${depthClusterPartialKey}0`] = 0;
  }

  /* Calculation for ACT RCPT Dollar and maxCC */
  clusters.forEach(clusterNumber => {
    /* 
        ACT RCPT Dollar Calculation
        Formula: choiceCount * depthCount * numOfDoors
      */
    const actRcptDollar =
      histParamObj[`${choiceClusterPartialKey}${clusterNumber}`] *
      histParamObj[`${depthClusterPartialKey}${clusterNumber}`] *
      clusterData[0][clusterNumber];

    // choiceCountData[index][actRcptDollarKey] += actRcptDollar || 0;
    histParamObj[actRcptDollarKey] += actRcptDollar || 0; //need to remove

    //Pushing Choice data for clusters other than ecom to get max of these and ecom should be pushed after some calculation
    /* removed ecom condition for Max CC
    // if (Number(clusterNumber) !== 0) {
    // }
    */
    tempArr.push(
      isNaN(histParamObj[`${choiceClusterPartialKey}${clusterNumber}`])
        ? 0
        : histParamObj[`${choiceClusterPartialKey}${clusterNumber}`]
    );
  });

  histParamObj[actRcptDollarKey] =
    histParamObj[actRcptDollarKey] * histParamObj.aur;
  histParamObj[varToPlanKey] =
    histParamObj[actRcptDollarKey] - histParamObj[planRcptDollarKey];
  histParamObj[palnCCKey] = histParamObj[tyCCKey];

  histParamObj[maxCCKey] = Math.max(...tempArr);
  // Adding Line Recipt Budget Proportions
  return {
    ...histParamObj,
    ...((budgetProportions &&
      budgetProportions[histParamObj["class/vendor"]]) ||
      {}),
    ...((lyBudgetProportions &&
      lyBudgetProportions[
        `${histParamObj["class/vendor"]}-${histParamObj.subclass}`
      ]) ||
      {})
  };
}

function mapNewStylesData({
  styleData,
  clusters,
  stylesAttrMapping,
  selectedPrimaryAttributes
}) {
  const styleAttrMappingObj = {};
  stylesAttrMapping.forEach(style => {
    styleAttrMappingObj[`${style.style_name} ${style.month}`] = style;
  });

  const styleDataObj = {};
  styleData.forEach(style => {
    styleDataObj[`${style.style_name} ${style.month}`] = style;
  });

  const newStylesData = styleData.map(style => {
    const newMappedStyle = { ...style };
    const styleMonthKey = `${style.style_name} ${style.month}`;
    clusters.forEach(cluster => {
      const newMappedStyleMonthKey =
        styleAttrMappingObj[styleMonthKey][cluster];
      newMappedStyle[cluster] = styleDataObj[newMappedStyleMonthKey][cluster];
    });
    if (selectedPrimaryAttributes && selectedPrimaryAttributes.length) {
      selectedPrimaryAttributes.forEach(primaryAttr => {
        newMappedStyle[primaryAttr] =
          styleAttrMappingObj[styleMonthKey][primaryAttr] || "";
      });
      if (selectedPrimaryAttributes.length === 1) {
        newMappedStyle["PRIMARY_ATTR_NONE_1"] = "";
      }
    }
    return newMappedStyle;
  });
  return newStylesData;
}
const assortmentPlanningActions = {
  // Line Receipt Actions
  setLineReceiptOnCellValueChanged: payload => {
    return dispatch => {
      dispatch({
        type: "SET_LINE_RECEIPT_DATA",
        payload
      });
    };
  },
  setUELineReceiptOnCellValueChanged: payload => {
    return dispatch => {
      dispatch({
        type: "SET_USER_EDITABLE_LINE_RECEIPT_DATA",
        payload
      });
    };
  },
  setLineReceiptGrandTotal: payload => {
    return dispatch => {
      dispatch({
        type: "SET_LINE_RECEIPT_GRAND_TOTAL",
        payload
      });
    };
  },
  setLineReceiptLevel: payload => {
    return dispatch => {
      dispatch({
        type: "SET_LINE_RECEIPT_LEVEL",
        payload
      });
    };
  },
  setLineReceiptHighLevel: payload => {
    return dispatch => {
      dispatch({
        type: "SET_LINE_RECEIPT_HIGH_LEVEL",
        payload
      });
    };
  },
  setLineReceiptVenGroupLevel: payload => {
    return dispatch => {
      dispatch({
        type: "SET_LINE_RECEIPT_VEN_GROUP_LEVEL",
        payload
      });
    };
  },
  retriveLineReceiptPlanData: reqObj => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text: "This may take some time. Thank you for your patience."
        }
      });
      return api({
        url: "/runLineReceiptAlgo",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          dispatch({
            type: "SET_LINE_RECEIPT_OTB_MONTHS",
            payload: resp.data.otb_months
          });
          dispatch({
            type: "SET_LINE_RECEIPT_MONTHS",
            payload: resp.data.levelData.months
          });
          dispatch({
            type: "SET_LINE_RECEIPT_DATA",
            isCloneNeeded: false,
            payload: resp.data.levelData.data
          });
          dispatch({
            type: "SET_LINE_RECEIPT_PLANNED_BUDGET",
            isCloneNeeded: false,
            payload: resp.data.levelData.planned_budget
          });
          dispatch({
            type: "SET_USER_EDITABLE_LINE_RECEIPT_DATA",
            isCloneNeeded: false,
            payload: resp.data.levelData.userEditableData
              ? resp.data.levelData.userEditableData
              : []
          });
          dispatch({
            type: "SET_LINE_RECEIPT_MAPPING_DATA",
            isCloneNeeded: false,
            payload: resp.data.mapping_data
          });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.error, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  saveLineReceiptPlan: reqObj => {
    reqObj.data.data.forEach(classRcpt => {
      const keys = Object.keys(classRcpt);
      keys.forEach(key => {
        if (key !== reqObj.level) {
          classRcpt[key] = +classRcpt[key];
        }
      });
    });
    reqObj.data.userEditableData.forEach(classRcpt => {
      const keys = Object.keys(classRcpt);
      keys.forEach(key => {
        if (key !== reqObj.level) {
          classRcpt[key] = +classRcpt[key];
        }
      });
    });
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/saveLineReceiptPlan",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  getOmniValues: projectId => {
    return dispatch => {
      return api({
        url: `/getOmniValue/${projectId}`,
        method: "POST"
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_IS_FC_APPROVED",
            payload: resp.data[2]
          });
          dispatch({
            type: "SET_AUC_THRESHOLD",
            payload: resp.data[1]
          });
          dispatch({
            type: "SET_DEPT_PENETRATION",
            payload: resp.data[3]
          });
          dispatch({
            type: "SET_SEASON",
            payload: resp.data[4]
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  runStoreDownload: reqObj => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/runStoreDownload",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          var file_path = resp.data.url;
          var a = document.createElement("A");
          a.href = file_path;
          a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  sendPlanAndRunArchAlgo: reqObj => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text:
            "This may take sometime. Fetching sales, inventory data at style level and computing required fields for all subgroups. Thank you for your patience."
        }
      });
      return api({
        url: "/runArchAlgoAndSendPlan",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_DELETE_MAPPING_FLAG",
            payload: false
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  getBuyers: () => {
    return () => {
      return api({
        url: "/users/getAllBuyers",
        method: "GET"
      })
        .then(resp => {
          return resp;
        })
        .catch(err => {
          console.log(err);
        });
    };
  },

  // Line Architecture Actions
  setLineArchitectureData: (month, lineArchitectureData) => {
    return dispatch => {
      dispatch({
        type: "SET_LINE_ARCHITECTURE_DATA",
        payload: { month, value: lineArchitectureData }
      });
    };
  },
  setMainLineArchitectureData: (month, mainLineArchitectureData) => {
    return dispatch => {
      dispatch({
        type: "SET_MAIN_LINE_ARCHITECTURE_DATA",
        payload: { month, value: mainLineArchitectureData }
      });
    };
  },
  setAddedSubClass: (month, payload) => {
    return (dispatch, getState) => {
      let {
        data: lineArchitectureData,
        main_data: mainLineArchitectureData
      } = getState().assortmentPlanningReducer.lineArchitectureDetails[month];
      lineArchitectureData["Line_Architechture"] = payload.lineArchitectureData;
      lineArchitectureData["Parameters"] = payload.parameterData;
      lineArchitectureData["Auto APX"] = payload.autoApx;
      mainLineArchitectureData["Line_Architechture"] =
        payload.mainLineArchitectureData;
      mainLineArchitectureData["Parameters"] = payload.mainParameterData;
      dispatch({
        type: "SET_LINE_ARCHITECTURE_DATA",
        payload: { month, value: lineArchitectureData }
      });
      dispatch({
        type: "SET_MAIN_LINE_ARCHITECTURE_DATA",
        payload: { month, value: mainLineArchitectureData }
      });
    };
  },
  setStylesDataForClass: reqObj => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      dispatch({
        type: "GET_STYLES_DATA_FOR_CLASS",
        payload: []
      });
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: false
        }
      });
    };
  },
  getStylesDataForClass: reqObj => {
    let level = reqObj.level;

    return (dispatch, getState) => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      if (level === "class") {
        reqObj.vendors = getState().clusteringReducer.clusteringInputFields.vendors.map(
          oneVendor => oneVendor["value"].toString()
        );
      }
      if (level === "vendor" || level === "stretch") {
        reqObj.classes = getState().clusteringReducer.clusteringInputFields.classes.map(
          oneClass => oneClass["value"].toString()
        );
      }
      return api({
        url: "/getStylesDataForClass",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "GET_STYLES_DATA_FOR_CLASS",
            payload: resp.data
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
        });
    };
  },
  getMetricsForNewSubClass: reqObj => {
    let level = reqObj.level;
    const apiMonth = reqObj.month;
    return (dispatch, getState) => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      if (level === "class") {
        reqObj.vendors = map(
          getState().clusteringReducer.clusteringInputFields.vendors,
          "value"
        );
      }
      if (level === "vendor" || level === "stretch") {
        reqObj.classes = map(
          getState().clusteringReducer.clusteringInputFields.classes,
          "value"
        );
      }

      return api({
        url: "/getMetricsForNewSubClass",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          if (resp.success) {
            dispatch({
              type: "LOADER_STATE",
              payload: {
                status: false
              }
            });
            dispatch({
              type: "GET_METRICS_FOR_NEW_SUBCLASS",
              payload: resp.data
            });

            const lineArchitectureDetails = getState().assortmentPlanningReducer
              .lineArchitectureDetails;

            Object.keys(lineArchitectureDetails).forEach(key => {
              if (key === apiMonth) {
                resp.data[key] &&
                  resp.data[key].lineArchitectureData[0] &&
                  lineArchitectureDetails[key].data["Line_Architechture"].push(
                    resp.data[key].lineArchitectureData[0]
                  );
                resp.data[key] &&
                  resp.data[key].parameterData[0] &&
                  lineArchitectureDetails[key].data["Parameters"].push(
                    resp.data[key].parameterData[0]
                  );
              } else {
                resp.data[key] &&
                  resp.data[key].lineArchitectureData[0] &&
                  lineArchitectureDetails[key].main_data[
                    "Line_Architechture"
                  ].push(resp.data[key].lineArchitectureData[0]);
                resp.data[key] &&
                  resp.data[key].parameterData[0] &&
                  lineArchitectureDetails[key].main_data["Parameters"].push(
                    resp.data[key].parameterData[0]
                  );
              }
            });
          }
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  saveLineArchitectureData: reqObj => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text: "This may take some time. Thank you for your patience."
        }
      });
      return api({
        url: "/saveLineArchitectureData",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  runClusteringAndParameter: reqObj => {
    return (dispatch, getState) => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text:
            "This may take sometime. Optimizing penetration for subgroup-cluster combinations and computing depth and choice counts across months. Thank you for your patience."
        }
      });
      return api({
        url: "/runClusteringAndParameterAlgo",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          const data = resp.data;
          for (const month in data) {
            if (data.hasOwnProperty(month)) {
              const monthData = data[month];

              // Sorting Clusters
              monthData.clusters.sort((a, b) => {
                if (+a && +b) {
                  return a - b;
                }
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
              });

              if (+monthData.clusters[0] === 0) {
                monthData.clusters.push(monthData.clusters.shift(1));
              }

              //Trasforming Historical parameter data into key value object
              const historicalParametersTransformObj = {};
              monthData.historicalParameterData.forEach(respectiveObj => {
                historicalParametersTransformObj[
                  `${respectiveObj["class/vendor"]}-${respectiveObj["subclass"]}`
                ] = respectiveObj;
              });
              monthData.historicalParameterData = historicalParametersTransformObj;
              //Trasforming Sub-Class RCPT Pen data into key value object
              const subClassRcptPenTableTransformObj = {};
              monthData.subClassRcptPenTable.forEach(subClassRcptPene => {
                subClassRcptPenTableTransformObj[
                  `${subClassRcptPene["class/vendor"]}-${subClassRcptPene["subclass"]}`
                ] = subClassRcptPene;
              });
              monthData.subClassRcptPenTable = subClassRcptPenTableTransformObj;

              const {
                historicalParameterData: histParamData,
                ly_subclass_month_budget_penetrations
              } = monthData;

              /* Converting LY penetration proportion as required */
              const lyBudgetProportions = {};
              ly_subclass_month_budget_penetrations.forEach(e => {
                if (!lyBudgetProportions[`${e["class/vendor"]}-${e.subclass}`])
                  lyBudgetProportions[
                    `${e["class/vendor"]}-${e.subclass}`
                  ] = {};

                lyBudgetProportions[`${e["class/vendor"]}-${e.subclass}`][
                  `ly_${e.mps_yrmo_desc}`
                ] = e.rcpt_penetration * 100;
              });
              /* Convertion End */

              for (const classSubClassKey in histParamData) {
                const {
                  budgetProportions
                } = getState().assortmentPlanningReducer;

                if (histParamData.hasOwnProperty(classSubClassKey)) {
                  histParamData[classSubClassKey] = calculateDepthAndChoice(
                    monthData.clusters,
                    monthData.clusterData,
                    monthData.subClassRcptPenTable[classSubClassKey],
                    monthData.buyerTotalBudget,
                    histParamData[classSubClassKey],
                    budgetProportions,
                    lyBudgetProportions
                  );
                }
              }
            }
          }
          dispatch({
            type: "SET_CLUSTER_AND_PARAMETER_DETAILS",
            payload: data
          });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  setBuyerTotalBudget: (month, buyerBudget) => {
    return (dispatch, getState) => {
      dispatch({
        type: "SET_BUYER_TOTAL_BUDGET",
        payload: { month, value: buyerBudget }
      });
    };
  },
  //Cluster And Parameter Actions
  changeMinMaxSellThroughValue: (minSellThrough, maxSellThrough, month) => {
    return (dispatch, getState) => {
      let { clusterAndParameterDetails } = getState().assortmentPlanningReducer;
      let { historicalParameterData } = clusterAndParameterDetails[month];
      let histParamSubclassObj;
      const histParamData = cloneDeep(historicalParameterData);
      for (const classSubClassKey in histParamData) {
        histParamSubclassObj = histParamData[classSubClassKey];
        histParamSubclassObj["minST%"] = minSellThrough;
        histParamSubclassObj["maxST%"] = maxSellThrough;
      }
      dispatch({
        type: "SET_HISTORICAL_PARAMETERS_DATA",
        payload: { month, value: histParamData }
      });
    };
  },
  setClusterDataOnCellValueChanged: (month, clusterData) => {
    return dispatch => {
      dispatch({
        type: "SET_CLUSTER_DATA",
        payload: { month, value: cloneDeep(clusterData) }
      });
    };
  },
  setHistoricalParamSubClassData: (
    month,
    classSubClassKey,
    histParamSubclassObj
  ) => {
    return dispatch => {
      dispatch({
        type: "SET_HISTORICAL_PARAM_SUBCLASS",
        payload: { month, classSubClassKey, value: histParamSubclassObj }
      });
    };
  },
  recalculateStep4OnCellValueChanged: (month, classSubClassKey, histData) => {
    return (dispatch, getState) => {
      let {
        lineArchitectureDetails,
        clusterAndParameterDetails,
        budgetProportions
      } = getState().assortmentPlanningReducer;
      let { buyerTotalBudget } = lineArchitectureDetails[month];
      let {
        clusterData,
        historicalParameterData,
        clusters,
        subClassRcptPenTable
      } = clusterAndParameterDetails[month];
      let histParamSubclassObj;
      histParamSubclassObj = calculateDepthAndChoice(
        clusters,
        clusterData,
        subClassRcptPenTable[classSubClassKey],
        buyerTotalBudget,
        histData || historicalParameterData[classSubClassKey],
        budgetProportions
      );
      dispatch({
        type: "SET_HISTORICAL_PARAM_SUBCLASS",
        payload: { month, classSubClassKey, value: histParamSubclassObj }
      });
    };
  },
  saveClusterAndParameterData: reqObj => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/saveClusterAndParameterData",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });

          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  runDepthMultiplier: payload => {
    return (dispatch, getState) => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/runDepthMultiplier",
        method: "POST",
        data: payload
      })
        .then(resp => {
          const respData = resp.data.depthMultiplier.Final_Data_DM_json;
          let {
            clusterAndParameterDetails,
            rcptMonths
          } = getState().assortmentPlanningReducer;
          let {
            projectDetails: {
              is_season_level: isSeasonLevel,
              hasAttributeClustering
            }
          } = getState().projectReducer;
          const data = cloneDeep(clusterAndParameterDetails);
          for (const month in data) {
            if (data.hasOwnProperty(month)) {
              const monthData = data[month];
              monthData.goodBetterBestTable = { ...respData[month] };
              const histParamData = monthData.historicalParameterData;
              for (const classSubClassKey in histParamData) {
                if (histParamData.hasOwnProperty(classSubClassKey)) {
                  if (
                    monthData.goodBetterBestTable.hasOwnProperty(
                      histParamData[classSubClassKey]["class/vendor"]
                    )
                  ) {
                    histParamData[classSubClassKey] = calculateMinMaxTable(
                      monthData.goodBetterBestTable[
                        histParamData[classSubClassKey]["class/vendor"]
                      ],
                      histParamData[classSubClassKey],
                      monthData.clusters
                    );

                    histParamData[classSubClassKey][
                      "depthAndChoiceData"
                    ] = setDepthAndChoiceDataStyleLevel(
                      histParamData[classSubClassKey],
                      monthData.clusters
                    );
                    histParamData[classSubClassKey][
                      "styleLevelDepth"
                    ] = calculateStyleLevelDepth(
                      monthData.clusters,
                      monthData.clusterData,
                      monthData.goodBetterBestTable[
                        histParamData[classSubClassKey]["class/vendor"]
                      ],
                      histParamData[classSubClassKey]
                    );
                    if (!hasAttributeClustering) {
                      histParamData[classSubClassKey] = generateSubClassStyles({
                        clusters: monthData.clusters,
                        clusterData: monthData.clusterData,
                        vHistParamObj: histParamData[classSubClassKey],
                        vRcptMonths: rcptMonths,
                        isSeasonLevel,
                        month,
                        goodBetterBestTable:
                          monthData.goodBetterBestTable[
                            histParamData[classSubClassKey]["class/vendor"]
                          ]
                      });
                    } else {
                      try {
                        histParamData[
                          classSubClassKey
                        ] = subClassLevelStyleCount(
                          monthData.clusters,
                          monthData.clusterData,
                          monthData.goodBetterBestTable[
                            histParamData[classSubClassKey]["class/vendor"]
                          ],
                          histParamData[classSubClassKey],
                          rcptMonths,
                          isSeasonLevel,
                          month
                        );
                      } catch (err) {
                        console.log(err);
                      }
                    }
                  }
                  histParamData[classSubClassKey]["styleLevel"] =
                    histParamData[classSubClassKey].styleLevel || [];
                  histParamData[classSubClassKey][
                    "optimalStyleLevel"
                  ] = cloneDeep(histParamData[classSubClassKey].styleLevel);
                }
              }
            }
          }
          dispatch({
            type: "SET_CLUSTER_AND_PARAMETER_DETAILS",
            payload: data
          });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  runAttrOptimizerWedgeAlgo: payload => {
    return (dispatch, getState) => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: `/runAttrOptimizerWedgeAlgo/${payload.projectId}`,
        method: "POST"
      })
        .then(resp => {
          if (resp.success) {
            try {
              const { data } = resp;
              let { rcptMonths } = getState().assortmentPlanningReducer;
              let {
                projectDetails: {
                  is_season_level: isSeasonLevel,
                  selectedPrimaryAttributes
                }
              } = getState().projectReducer;
              for (const month in data) {
                if (data.hasOwnProperty(month)) {
                  const monthData = getState().assortmentPlanningReducer
                    .clusterAndParameterDetails[month];
                  const histParamData = monthData.historicalParameterData;
                  const stylesData = data[month];
                  for (const classSubClassKey in stylesData) {
                    if (stylesData.hasOwnProperty(classSubClassKey)) {
                      const stylesAttrMapping = JSON.parse(
                        stylesData[classSubClassKey]
                      );
                      histParamData[classSubClassKey][
                        "stylesAttrMapping"
                      ] = stylesAttrMapping;
                      histParamData[classSubClassKey] = subClassLevelStyleCount(
                        monthData.clusters,
                        monthData.clusterData,
                        monthData.goodBetterBestTable[
                          histParamData[classSubClassKey]["class/vendor"]
                        ],
                        histParamData[classSubClassKey],
                        rcptMonths,
                        isSeasonLevel,
                        month,
                        selectedPrimaryAttributes
                      );
                    }
                  }
                }
              }
            } catch (error) {
              console.log(error);
            }
            return resp;
          }
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  setMinSellThrough: payload => {
    return dispatch => {
      dispatch({
        type: "SET_MIN_SELL_THROUGH",
        payload
      });
    };
  },
  setMaxSellThrough: payload => {
    return dispatch => {
      dispatch({
        type: "SET_MAX_SELL_THROUGH",
        payload
      });
    };
  },

  //Depth And Choice Count
  setDepthCountOnCellValueChanged: (
    month,
    classSubClassKey,
    histParamSubclassObj
  ) => {
    return dispatch => {
      dispatch({
        type: "SET_HISTORICAL_PARAM_SUBCLASS",
        payload: { month, classSubClassKey, value: histParamSubclassObj }
      });
    };
  },
  setChoiceCountOnCellValueChanged: (
    month,
    classSubClassKey,
    histParamSubclassObj
  ) => {
    return dispatch => {
      dispatch({
        type: "SET_HISTORICAL_PARAM_SUBCLASS",
        payload: { month, classSubClassKey, value: histParamSubclassObj }
      });
    };
  },

  // setGoodBetterBestTableOnCellValueChanged: goodBetterBestTable => {
  //   return dispatch => {
  //     dispatch({
  //       type: "SET_GOOD_BETTER_BEST_TABLE",
  //       payload: cloneDeep(goodBetterBestTable)
  //     });
  //   };
  // },
  setHistoricalParamDataOnApplyAll: (month, histParamData) => {
    return (dispatch, getState) => {
      let {
        clusterAndParameterDetails,
        rcptMonths
      } = getState().assortmentPlanningReducer;
      let {
        clusterData,
        clusters,
        goodBetterBestTable
      } = clusterAndParameterDetails[month];
      let {
        projectDetails: {
          is_season_level: isSeasonLevel,
          selectedPrimaryAttributes,
          hasAttributeClustering
        }
      } = getState().projectReducer;
      for (const classSubClassKey in histParamData) {
        if (histParamData.hasOwnProperty(classSubClassKey)) {
          if (!hasAttributeClustering) {
            histParamData[classSubClassKey] = generateSubClassStyles({
              clusters,
              clusterData,
              vHistParamObj: histParamData[classSubClassKey],
              vRcptMonths: rcptMonths,
              isSeasonLevel,
              month,
              goodBetterBestTable:
                goodBetterBestTable[
                  histParamData[classSubClassKey]["class/vendor"]
                ]
            });
          } else {
            histParamData[classSubClassKey] = subClassLevelStyleCount(
              clusters,
              clusterData,
              goodBetterBestTable[
                histParamData[classSubClassKey]["class/vendor"]
              ],
              histParamData[classSubClassKey],
              rcptMonths,
              isSeasonLevel,
              month,
              selectedPrimaryAttributes
            );
          }
        }
      }
      dispatch({
        type: "SET_HISTORICAL_PARAMETERS_DATA",
        payload: { month, value: histParamData }
      });
    };
  },
  recalculateSubclassStyles: (month, classSubClassKey, needToCreateDepth) => {
    return (dispatch, getState) => {
      let {
        clusterAndParameterDetails,
        rcptMonths
      } = getState().assortmentPlanningReducer;
      let {
        clusterData,
        historicalParameterData,
        clusters,
        goodBetterBestTable
      } = clusterAndParameterDetails[month];
      let {
        projectDetails: {
          is_season_level: isSeasonLevel,
          selectedPrimaryAttributes,
          hasAttributeClustering
        }
      } = getState().projectReducer;
      let histParamSubclassObj;
      if (!hasAttributeClustering) {
        histParamSubclassObj = generateSubClassStyles({
          clusters,
          clusterData,
          vHistParamObj: historicalParameterData[classSubClassKey],
          vRcptMonths: rcptMonths,
          isSeasonLevel,
          month,
          goodBetterBestTable:
            goodBetterBestTable[
              historicalParameterData[classSubClassKey]["class/vendor"]
            ]
        });
      } else {
        histParamSubclassObj = subClassLevelStyleCount(
          clusters,
          clusterData,
          goodBetterBestTable[
            historicalParameterData[classSubClassKey]["class/vendor"]
          ],
          historicalParameterData[classSubClassKey],
          rcptMonths,
          isSeasonLevel,
          month,
          selectedPrimaryAttributes
        );
      }
      dispatch({
        type: "SET_HISTORICAL_PARAM_SUBCLASS",
        payload: { month, classSubClassKey, value: histParamSubclassObj }
      });
    };
  },
  recalculateMinMaxSubclassStyles: month => {
    return (dispatch, getState) => {
      let {
        clusterAndParameterDetails,
        rcptMonths
      } = getState().assortmentPlanningReducer;
      let {
        projectDetails: {
          is_season_level: isSeasonLevel,
          selectedPrimaryAttributes,
          hasAttributeClustering
        }
      } = getState().projectReducer;
      let {
        clusterData,
        historicalParameterData,
        clusters,
        goodBetterBestTable
      } = clusterAndParameterDetails[month];
      let histParamSubclassObj;
      for (const classSubClassKey in historicalParameterData) {
        if (historicalParameterData.hasOwnProperty(classSubClassKey)) {
          historicalParameterData[classSubClassKey][
            "styleLevelDepth"
          ] = calculateStyleLevelDepth(
            clusters,
            clusterData,
            goodBetterBestTable[
              historicalParameterData[classSubClassKey]["class/vendor"]
            ],
            historicalParameterData[classSubClassKey]
          );
          histParamSubclassObj = calculateMinMaxTable(
            goodBetterBestTable[
              historicalParameterData[classSubClassKey]["class/vendor"]
            ],
            historicalParameterData[classSubClassKey],
            clusters
          );
          if (!hasAttributeClustering) {
            histParamSubclassObj = generateSubClassStyles({
              clusters: clusters,
              clusterData: clusterData,
              vHistParamObj: histParamSubclassObj,
              vRcptMonths: rcptMonths,
              isSeasonLevel,
              month,
              goodBetterBestTable:
                goodBetterBestTable[
                  historicalParameterData[classSubClassKey]["class/vendor"]
                ]
            });
          } else {
            histParamSubclassObj = subClassLevelStyleCount(
              clusters,
              clusterData,
              goodBetterBestTable[
                historicalParameterData[classSubClassKey]["class/vendor"]
              ],
              histParamSubclassObj,
              rcptMonths,
              isSeasonLevel,
              month,
              selectedPrimaryAttributes
            );
          }
          dispatch({
            type: "SET_HISTORICAL_PARAM_SUBCLASS",
            payload: { month, classSubClassKey, value: histParamSubclassObj }
          });
        }
      }
      // dispatch({
      //   type: "SET_HISTORICAL_PARAMETERS_DATA",
      //   payload: historicalParameterData
      // });
      // dispatch({
      //   type: "SET_CLUSTER_SUMMARY_TABLE",
      //   payload: clusterSummaryTable
      // });
      // dispatch({
      //   type: "SET_GOOD_BETTER_BEST_TABLE",
      //   payload: goodBetterBestTable
      // });
    };
  },
  downloadAutoAPX: projectId => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: `/downloadAssortmentWedgeData/${projectId}`,
        method: "POST"
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
        });
    };
  },
  setSelectedAutoAPXClass: (month, classSubClassKey) => {
    return (dispatch, getState) => {
      let { clusterAndParameterDetails } = getState().assortmentPlanningReducer;
      let {
        clusterData,
        historicalParameterData,
        clusters
      } = clusterAndParameterDetails[month];

      let clusterSummaryTable = getClusterSummaryTable(
        clusters,
        clusterData,
        historicalParameterData
      );
      dispatch({
        type: "SET_SELECTED_AUTO_APX_CLASS",
        payload: classSubClassKey
      });
      dispatch({
        type: "SET_CLUSTER_SUMMARY_TABLE",
        payload: clusterSummaryTable
      });
    };
  },
  runmonthlevelAggAlgo: reqObj => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: `/runmonthlevelAggAlgo`,
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },

  // Reset Actions
  resetLineReceiptScreen: payload => {
    return dispatch => {
      dispatch({
        type: "SET_LINE_RECEIPT_DATA",
        payload: cloneDeep(payload.lineReceiptData)
      });
      dispatch({
        type: "SET_LINE_RECEIPT_LEVEL",
        payload: payload.lineReceiptlevel
      });
      dispatch({
        type: "SET_LINE_RECEIPT_HIGH_LEVEL",
        payload: payload.lineReceiptHighlevel
      });
      dispatch({
        type: "SET_LINE_RECEIPT_VEN_GROUP_LEVEL",
        payload: payload.lineReceiptVenGrouplevel
      });
      dispatch({
        type: "SET_LINE_RECEIPT_PLANNED_BUDGET",
        payload: payload.plannedBudget
      });
      dispatch({
        type: "SET_USER_EDITABLE_LINE_RECEIPT_DATA",
        payload: payload.userEditableLineReceipt
      });
    };
  },
  resetLineArchitectureScreen: payload => {
    return dispatch => {
      dispatch({
        type: "SET_LINE_ARCHITECTURE_DETAILS",
        payload: cloneDeep(payload.lineArchitectureDetails)
      });
    };
  },

  dataChangedInAssortment1: payload => {
    return dispatch => {
      dispatch({
        type: "SET_DATA_CHANGED_IN_ASSORTMENT_1",
        payload: payload
      });
    };
  },
  dataChangedInAssortment2: payload => {
    return dispatch => {
      dispatch({
        type: "SET_DATA_CHANGED_IN_ASSORTMENT_2",
        payload: payload
      });
    };
  },
  dataChangedInAssortment3: payload => {
    return dispatch => {
      dispatch({
        type: "SET_DATA_CHANGED_IN_ASSORTMENT_3",
        payload: payload
      });
    };
  },
  dataChangedInAssortment4: payload => {
    return dispatch => {
      dispatch({
        type: "SET_DATA_CHANGED_IN_ASSORTMENT_4",
        payload: payload
      });
    };
  },

  resetClusterPlanAndParametersScreen: payload => {
    return dispatch => {
      dispatch({
        type: "SET_CLUSTER_AND_PARAMETER_DETAILS",
        payload: cloneDeep(payload.clusterAndParameterDetails)
      });
    };
  },

  checkStyleDuplication: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/checkStyleDuplication",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          toast.error(err.message, { autoClose: false });
          return err;
        });
    };
  },

  getSubGroupStyleMapping: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/getSubGroupStyleMapping",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          toast.error(err.message, { autoClose: false });
          return err;
        });
    };
  },

  getVenGroupOptions: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/getVenGroupData",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "SET_VEN_GROUP_OPTIONS",
            payload: resp.data.venGroupData
          });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          toast.error(err.message, { autoClose: false });
          return err;
        });
    };
  },

  getMappingForLineReceipt: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/getMappingForLineReceipt",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "SET_MAPPING_FOR_SOURCE",
            payload: resp.data.source
          });
          dispatch({
            type: "SET_MAPPING_FOR_TARGET",
            payload: resp.data.target
          });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          toast.error(err.message, { autoClose: false });
          return err;
        });
    };
  },
  saveMappingForLineReceipt: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/saveMappingForLineReceipt",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_DELETE_MAPPING_FLAG",
            payload: false
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          toast.error(err.message, { autoClose: false });
          return err;
        });
    };
  },
  deleteMappingForLineReceipt: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/deleteMappingForLineReceipt",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_DELETE_MAPPING_FLAG",
            payload: true
          });
          dispatch({
            type: "SET_USER_EDITABLE_LINE_RECEIPT_DATA",
            payload: []
          });
          dispatch({
            type: "SET_LINE_RECEIPT_DATA",
            payload: []
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          toast.error(err.message, { autoClose: false });
          return err;
        });
    };
  }
};

export default assortmentPlanningActions;

import { dateFormatter } from "../../helpers/formatters";
export default {
  defaultColDef: {
    resizable: true,
    animateRows: true,
    sortable: true,
    unSortIcon: true,
    menuTabs: []
  },
  animateRows: true,
  sortable: true,
  filter: true,
  unSortIcon: true,
  suppressCellSelection: true,
  cellClass: "no-border",
  rowSelection: "multiple",

  columnDefs: [
    {
      headerName: "ASSORTMENT PLAN NAME",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: { checkbox: true },
      width: 178,
      filter: "agTextColumnFilter",
      field: "name",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      selectable: false,
      pinned: "left",
      cellClassRules: "readonly-cell",
      suppressCellSelection: true,
      cellClass: "no-border",
      cellStyle: {
        cursor: "pointer",
        color: "#5285EB"
      }
    },
    {
      width: 150,
      headerName: "DEPT ID",
      filter: "agTextColumnFilter",
      field: "department_name"
    },
    {
      headerName: "TIME PERIOD",
      filter: "agTextColumnFilter",
      field: "time_period"
    },
    {
      headerName: "CREATION DATE",
      width: 125,
      filter: "agTextColumnFilter",
      valueFormatter: dateFormatter,
      field: "created_at"
    },
    {
      width: 125,
      headerName: "LAST EDITED ON",
      valueFormatter: dateFormatter,
      filter: "agTextColumnFilter",
      field: "updated_at"
    },
    {
      headerName: "CURRENT STAGE",
      width: 117,
      filter: "agTextColumnFilter",
      field: "project_stage"
    },
    {
      width: 120,
      headerName: "USER NAME",
      filter: "agTextColumnFilter",
      field: "username"
    }
  ]
};

import {
  toDollar,
  percentageFormatter,
  toUnit
} from "../../../helpers/formatters";
import { tar } from "./inputCss";

export default {
  defaultColDef: {
    resizable: true,
    menuTabs: [],
    //   filter: true,
    sortable: true,
    unSortIcon: true
  },
  columnDefs: [
    {
      resizable: true,
      valueFormatter: toDollar,
      cellStyle: tar,
      headerName: "TOTAL BUY COST ($)",
      field: "Total Budget ($)",
      width: 110
    },
    {
      headerName: "TOTAL BUY UNITS",
      valueFormatter: toUnit,
      cellStyle: tar,
      field: "Total Buy Units",
      width: 120
    },
    {
      headerName: "REG TOTAL MARGIN ($)",
      valueFormatter: toDollar,
      cellStyle: tar,
      field: "Reg Total Margin ($)",
      width: 120
    },
    {
      headerName: "LIFETIME RETAIL SALES ($)",
      valueFormatter: toDollar,
      cellStyle: tar,
      field: "Lifetime Retail Sales ($)",
      width: 138
    },
    {
      headerName: "LIFETIME MARGIN ($)",
      field: "Lifetime Margin ($)",
      cellStyle: tar,
      valueFormatter: toDollar,
      width: 112
    },
    {
      headerName: "LIFETIME UNASSISTED GM %",
      valueFormatter: percentageFormatter,
      cellStyle: tar,
      field: "Unassisted GM%",
      width: 154
    },
    {
      headerName: "LIFETIME ASSISTED GM %",
      valueFormatter: percentageFormatter,
      cellStyle: tar,
      field: "Assisted GM%",
      width: 134
    },
    {
      headerName: "VA %",
      valueFormatter: percentageFormatter,
      cellStyle: tar,
      field: "VA %",
      width: 76
    },
    {
      headerName: "VA VALUE ($)",
      valueFormatter: toDollar,
      cellStyle: tar,
      field: "VA Value ($)",
      width: 118
    },
    {
      headerName: "GIVE BACK %",
      valueFormatter: percentageFormatter,
      cellStyle: tar,
      field: "Give Back %",
      width: 118
    },
    {
      headerName: "LIFETIME MARGIN (Adding VA) ($)",
      valueFormatter: toDollar,
      cellStyle: tar,
      width: 150,
      field: "Lifetime margin (Adding VA) ($)"
    }
  ]
};

import Tippy from "@tippy.js/react";
import React, { Component } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import ClusteringActions from "../../../actions/ClusteringActions";
import DataTable from "../../../services/dataTable";
import Modal from "../../../services/modal";
import Select from "../../../services/selectAll";
import ClusterLegends from "./ClusterLegends";
import VolumeGroupData from "./columnDefs";
import DeviationChart from "./DeviationChart";
import ScatterChart from "./ScatterChart";
import SDFSaleSummary from "./sdfColDef";
import volumeGroupCalculations from "./volumeGroupCalculations";
import volumeGroupCalculations1 from "./volumeGroupCalculations1";
import VolumeGroupPerformancePlot from "./VolumeGroupPerformancePlot";

let performancePlotOptionsHindSight = [
  { value: "sales_rtl", label: "Sales Retail" },
  { value: "sales_u", label: "Sales Units" },
  { value: "gm", label: "Gross Margin" },
  { value: "sell_through", label: "Sell Through" },
  { value: "aps", label: "APS" },
  { value: "u_turn", label: "U Turn" },
  { value: "choice", label: "Choice Count" },
  { value: "gm_rate", label: "GM Rate" }
]

let performancePlotOptions = [
...performancePlotOptionsHindSight,
  { value: "sales_rtl_lost_sales", label: "Lost Sales" },
];


const chartBackgroundColor = [
  "#ec0000",
  "#2892ff",
  "#12a700",
  "#a900bd",
  "#ff8508",
  "#808080",
  "#49fff7",
  "#793200",
  "#ff93ca",
  "#004226",
  "#d8ce55",
  "#301c9c",
  "#576c5e",
  "#bff349",
  "#801336"
];
class Step3VolumeGroups extends Component {
  constructor(props) {
    super(props);
    let clusterSlectionFlags = {};
    let userSelectedClusters = props.userSelectedClusters;
    let availableClusterOptions = [];
    if (props.clusterMapping) {
      availableClusterOptions = Object.keys(props.clusterMapping).map(
        cluster => {
          return { value: cluster, label: cluster };
        }
      );
      if (props.hasAttributeClustering) {
        const maxVolumeClusterAllowed = props.getMaxVolumeClusterAllowed(
          props.optimalAttributeClusters
        );
        availableClusterOptions = availableClusterOptions.filter(
          e => +e.value <= maxVolumeClusterAllowed
        );
      }
      if (userSelectedClusters) {
        const selectedClusterMapping =
          props.clusterMapping[userSelectedClusters];
        const clusters = Object.keys(selectedClusterMapping);
        for (let i = 0; i < clusters.length; i++) {
          const element = clusters[i];
          console.log(element, Number(element) !== 999);

          if (
            Number(element) !== 999 &&
            selectedClusterMapping[element].length
          ) {
            element === "0"
              ? (clusterSlectionFlags[element] = false)
              : (clusterSlectionFlags[element] = true);
          }
        }
      }
    }

    this.state = {
      modalVisible: false,
      scatterModalVisible: false,
      selectValueX: { label: "Choice Count", value: "choice" },
      selectValueY: { label: "Sales Retail", value: "sales_rtl" },
      selectedClusterNum: { value: null, label: null },
      storeData: props.clusterResultsStoreLevel,
      modalScatterData: {},
      modalScatterOptions: {},
      availableClusterOptions,
      clusterSlectionFlags,
      pinnedBottomRow: []
    };
  }

  componentDidMount() {
    let obj = {
      choice: null,
      weeks_active: null,
      sales_u: null,
      tot_sls_qty: null,
      sales_rtl: null,
      gm: null,
      gm_asst: null,
      oh_tot: null,
      rcpt_u: null,
      rcpt_qty_st: null,
      bop_u: null,
      eop_u: null,
      u_turn_denom: null,
      sales_rtl_lost_sales: null,
      u_rec_depth: null,
      clusters: null,
      loc_count: null,
      // weeks_active: null,
      rcpts_per_door_per_cc: null,
      u_turn: null,
      sls_u_per_door: null,
      rtl_per_door: null,
      gm_per_door: null,
      gm_rate: null,
      gm_asst_per_door: null,
      gm_asst_rate: null,
      aur: null,
      aps: null,
      aps_gm: null,
      aps_gm_asst: null,
      sell_through: null,
      loc_id: null
    };
    let storeData =
      this.state.storeData &&
      this.state.storeData.length &&
      this.state.storeData.filter(
        e =>
          !this.props.clusterMapping[this.props.userSelectedClusters][
            this.props.unassignedStoresIndex
          ].includes(`${+e.loc_id}`)
      );
    obj.loc_count = storeData.length - 1;
    storeData.forEach((row, ind) => {
      if (row.clusters !== 0) {
        volumeGroupCalculations(true, obj, row, ind);
      }
    });
    let storeLength = storeData.length - 1;
    obj.clusters = `Total CL's ${this.props.userSelectedClusters} (${storeLength}) `;
    this.setState({
      pinnedBottomRow: [{ ...volumeGroupCalculations1(obj) }]
    });
  }
  componentDidUpdate(oldProps, oldState) {
    if (oldProps.userSelectedClusters !== this.props.userSelectedClusters) {
      let obj = {
        choice: null,
        weeks_active: null,
        sales_u: null,
        tot_sls_qty: null,
        sales_rtl: null,
        gm: null,
        gm_asst: null,
        oh_tot: null,
        rcpt_u: null,
        rcpt_qty_st: null,
        bop_u: null,
        eop_u: null,
        u_turn_denom: null,
        sales_rtl_lost_sales: null,
        u_rec_depth: null,
        clusters: null,
        loc_count: null,
        // weeks_active: null,
        rcpts_per_door_per_cc: null,
        u_turn: null,
        sls_u_per_door: null,
        rtl_per_door: null,
        gm_per_door: null,
        gm_rate: null,
        gm_asst_per_door: null,
        gm_asst_rate: null,
        aur: null,
        aps: null,
        aps_gm: null,
        aps_gm_asst: null,
        sell_through: null,
        loc_id: null
      };
      let storeData =
        this.state.storeData &&
        this.state.storeData.length &&
        this.state.storeData.filter(
          e =>
            !this.props.clusterMapping[this.props.userSelectedClusters][
              this.props.unassignedStoresIndex
            ].includes(`${+e.loc_id}`)
        );
      obj.loc_count = storeData.length - 1;
      storeData.forEach((row, ind) => {
        if (row.clusters !== 0) {
          volumeGroupCalculations(true, obj, row, ind);
        }
      });
      obj.clusters = `Total CL's ${
        this.props.userSelectedClusters
      } (${storeData.length - 1}) `;
      this.setState({
        pinnedBottomRow: [{ ...volumeGroupCalculations1(obj) }]
      });
      let clusterSlectionFlags = {};
      const selectedClusterMapping = this.props.clusterMapping[
        this.props.userSelectedClusters
      ];
      const clusters = Object.keys(selectedClusterMapping);
      for (let i = 0; i < clusters.length; i++) {
        const element = clusters[i];
        if (Number(element) !== 999 && selectedClusterMapping[element].length) {
          element === "0"
            ? (clusterSlectionFlags[element] = false)
            : (clusterSlectionFlags[element] = true);
        }
        this.setState({
          clusterSlectionFlags
        });
      }
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.userSelectedClusters &&
      nextProps.userSelectedClusters !== prevState.selectedClusterNum.value
    ) {
      return {
        selectedClusterNum: {
          value: nextProps.userSelectedClusters,
          label: nextProps.userSelectedClusters
        }
      };
    }
    return null;
  }
  closeModal() {
    this.setState({ modalVisible: false });
  }

  handleChangeX = e => {
    this.setState({ selectValueX: e.target.value });
  };
  handleChangeY = e => {
    this.setState({ selectValueY: e.target.value });
  };
  // updateClusterAsMapping = clusters => {
  //   //TODO: optimize this code
  //   let storeDataCopy = cloneDeep(this.state.storeData);
  //   let mapping = Object.keys(this.props.clusterMapping[clusters]);
  //   for (let i = 0; i < mapping.length; i++) {
  //     for (
  //       let j = 0;
  //       j < this.props.clusterMapping[clusters][mapping[i]].length;
  //       j++
  //     ) {
  //       for (let k = 0; k < storeDataCopy.length; k++) {
  //         if (
  //           storeDataCopy[k].loc_id ===
  //           this.props.clusterMapping[clusters][mapping[i]][j]
  //         ) {
  //           storeDataCopy[k].clusters = parseInt(mapping[i]);
  //           break;
  //         }
  //       }
  //     }
  //   }
  //   this.setState({ storeData: storeDataCopy });
  // };
  onChangeCluster = async val => {
    this.setState({ selectedClusterNum: val });
    this.props
      .getStoreClusterData({
        clusterMasterId: this.props.clusterMasterId,
        selected_cluster_id: val.value || val
      })
      .then(resp => {
        this.setState({ storeData: resp.data, selectedClusterNum: val });
      });
    await this.props.changeUserSelectedClusters(val.value || val);
    // this.updateClusterAsMapping(val.value);
  };

  showScatterChart = index => {
    this.setState({
      scatterModalVisible: true,
      selectValueY: performancePlotOptions[index]
    });
  };

  transformStoreLevelDataToPerformancePlot = filteredPlotOptions => {
    const plotData = {};
    filteredPlotOptions.forEach(option => {
      // Getting data points for all stores
      const groupedStores = {};
      this.props.clusterResultsStoreLevel.forEach(element => {
        if (!groupedStores[element.clusters]) {
          groupedStores[element.clusters] = [];
        }
        if (element[option.value] && element[this.state.selectValueY.value]) {
          var obj = {
            x: element[option.value],
            y: element[this.state.selectValueY.value],
            xLabel: option.label,
            yLabel: this.state.selectValueY.label,
            storeName: element.store_name
          };
          groupedStores[element.clusters].push(obj);
        }
      });
      // Looping over cluster and setting data sets a required for charjs
      const allDataSets = {
        datasets: []
      };
      for (var key in groupedStores) {
        if (parseInt(key) !== this.props.unassignedStoresIndex) {
          allDataSets.datasets.push({
            label:
              key > 0
                ? `Cluster ${key} (${groupedStores[key].length})`
                : "Ecom (1)",
            pointRadius: 4,
            hidden: !this.state.clusterSlectionFlags[key],
            pointHoverRadius: 10,
            borderColor: "#ffffff",
            datalabels: {
              display: false
            },
            backgroundColor: chartBackgroundColor[key],
            data: groupedStores[key]
          });
        }
      }
      plotData[option.value] = allDataSets;
    });
    return plotData;
  };

  expandScatterPlot = x => {
    const filteredPlotOptions = performancePlotOptions.filter(
      option => option.value !== this.state.selectValueY.value
    );
    this.setState({
      scatterModalVisible: true,
      modalScatterData: this.transformStoreLevelDataToPerformancePlot(
        filteredPlotOptions
      )[x.value],
      modalScatterOptions: {
        legend: { display: true, labels: { boxWidth: 20 } },
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return `${
                data.datasets[tooltipItem[0].datasetIndex].data[
                  tooltipItem[0].index
                ].storeName
              }`;
            },
            label: function(tooltipItem, data) {
              return ` ${
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                  .xLabel
              }: ${tooltipItem.xLabel.toFixed(2)}, ${
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                  .yLabel
              }: ${tooltipItem.yLabel.toFixed(2)}`;
            }
          }
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: x.label
              },
              gridLines: {
                lineWidth: 0,
                zeroLineWidth: 1,
                drawBorder: false
              }
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this.state.selectValueY.label
              },
              gridLines: {
                lineWidth: 0,
                zeroLineWidth: 1,
                drawBorder: false
              }
            }
          ]
        }
      }
    });
  };

  onClusterCheckChange = (cluster, flag) => {
    this.setState({
      clusterSlectionFlags: {
        ...this.state.clusterSlectionFlags,
        [cluster]: flag
      }
    });
  };

  render() {
    let optionFilter = this.props.isHindsightFlag ? performancePlotOptionsHindSight : performancePlotOptions
    const filteredPlotOptions = optionFilter.filter(
      option => option.value !== this.state.selectValueY.value
    );
    return (
      <div className="card">
        <div className="card-body">
          <div className="card p-3 mt-3">
            <div className="card-header-background">
              <strong>Volume Groups Performance Plot</strong>
            </div>
            <div>
              <Row className="mt-3">
                <Col md={3}>
                  <label htmlFor="allocationName">
                    Select number of clusters
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id="elbow-tooltip">
                          <strong>Cluster Count Variation</strong>.
                        </Tooltip>
                      }
                    >
                      <i
                        style={{
                          cursor: "pointer",
                          color: "#2D69EB"
                        }}
                        onClick={() => this.setState({ modalVisible: true })}
                        className="fa fa-line-chart ml-3"
                        aria-hidden="true"
                      />
                    </OverlayTrigger>
                  </label>
                  <Select
                    isDisabled={this.props.isClusterDisabled}
                    value={this.state.selectedClusterNum}
                    options={this.state.availableClusterOptions}
                    onChange={val => this.onChangeCluster(val)}
                  />
                </Col>
                <Col md={{ span: 3, offset: 6 }}>
                  <label htmlFor="allocationName">Select Y-axis:</label>
                  <Select
                    value={this.state.selectValueY}
                    options={this.props.isHindsightFlag ? performancePlotOptionsHindSight : performancePlotOptions}
                    onChange={val => this.setState({ selectValueY: val })}
                  />
                </Col>
              </Row>
              <div className="m-3">
                <ClusterLegends
                  chartBackgroundColor={chartBackgroundColor}
                  flags={this.state.clusterSlectionFlags}
                  onClusterCheckChange={this.onClusterCheckChange}
                ></ClusterLegends>
                <VolumeGroupPerformancePlot
                  allDataSets={this.transformStoreLevelDataToPerformancePlot(
                    filteredPlotOptions
                  )}
                  filteredPlotOptions={filteredPlotOptions}
                  selectedMetric={this.state.selectValueY.value}
                  expandScatterPlot={this.expandScatterPlot}
                />
              </div>
            </div>
          </div>
          <div className="card p-3 mt-3">
            <div className="card-header-background mt-4">
              {this.props.lyFlag ? (
                <strong>Volume Groups Data (LY actuals)</strong>
              ) : (
                <strong>Volume Groups Data (LLY actuals)</strong>
              )}
            </div>
            <div
              style={{
                height: "calc(100vh - 300px)"
                // height: Object.keys(groupedStores).length
                //   ? `${(Object.keys(groupedStores).length + 2) * 30 + 13}px`
                //   : "223px"
              }}
            >
              <DataTable
                groupColDef={{
                  headerName: "Group",
                  width: 170,
                  field: "clusters"
                }}
                floatingFilter={true}
                rowData={this.state.storeData.filter(
                  e =>
                    !this.props.clusterMapping[this.props.userSelectedClusters][
                      this.props.unassignedStoresIndex
                    ].includes(`${+e.loc_id}`)
                )}
                groupRowAggNodes={VolumeGroupData.groupRowAggNodes}
                columnDefs={VolumeGroupData.columnDefs(this.props.isHindsightFlag)}
                defaultColDef={VolumeGroupData.defaultColDef}
                suppressColumnVirtualisation={true}
                pinnedBottomRowData={this.state.pinnedBottomRow}
              />
            </div>
            <div className="card-header-background mt-4">
              <strong>SDF sales summary</strong>
            </div>
            <div
              style={{
                height: "80px"
                // height: Object.keys(groupedStores).length
                //   ? `${(Object.keys(groupedStores).length + 2) * 30 + 13}px`
                //   : "223px"
              }}
            >
              <DataTable
                rowData={this.props.sdfSalesSummary}
                columnDefs={SDFSaleSummary.columnDefs}
                defaultColDef={SDFSaleSummary.defaultColDef}
                sizeToFit={true}
              />
            </div>
          </div>
        </div>
        <Modal
          open={this.state.modalVisible}
          onClose={() => this.closeModal()}
          title="Cluster Count Variation- Explained Variation v/s Number of Clusters"
          fullWidth={true}
        >
          <div>
            <div className="t-a-r">
              <i>Equation</i>
              <Tippy
                placement={"left"}
                arrow={true}
                content={
                  <div>
                    <div>
                      <strong>Average deviation from mean</strong> =
                      [summation(abs(value – cluster avg)) for all columns] /
                      #columns for normalized data
                    </div>
                    <div>{""}</div>
                    <div>-----------------And-----------------</div>
                    <div>{""}</div>
                    <div>
                      <strong>Silhouette score</strong> = (b - a) / max(a, b)
                      <div>{""}</div>a ={">"} mean intra-cluster distance
                      <div>{""}</div>b ={">"} mean nearest-cluster distance
                    </div>
                  </div>
                }
              >
                <i
                  style={{
                    color: "#2D69EB"
                  }}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                />
              </Tippy>
            </div>
            <DeviationChart data={this.props.elbowCurve} />
            <i>
              *Optimal number of clusters are chosen using Silhouette score
              which also take into account inter cluster difference across
              variables.
            </i>
          </div>
        </Modal>
        <Modal
          open={this.state.scatterModalVisible}
          onClose={() => this.setState({ scatterModalVisible: false })}
          fullWidth={true}
          maxWidth="lg"
        >
          <div>
            <ScatterChart
              options={this.state.modalScatterOptions}
              data={this.state.modalScatterData}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    lyFlag: store.projectReducer.lyFlag
  };
};
const mapDispatchToProps = {
  getStoreClusterData: ClusteringActions.getStoreClusterData,
  changeUserSelectedClusters: ClusteringActions.changeUserSelectedClusters
};
export default connect(mapStateToProps, mapDispatchToProps)(Step3VolumeGroups);

import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import mainReducer from "../reducers";
import userReducer from "../reducers/userReducer";
import buyRollUpReducers from "../reducers/buyRollUpReducers";
import filtersReducer from "../reducers/filtersReducer";
import buyOptimizationReducer from "../reducers/buyOptimizationReducer";
import clusteringReducer from "../reducers/clusteringReducer";
import assortmentPlanningReducer from "../reducers/assortmentPlanningReducer";
import projectReducer from "../reducers/projectReducer";

const middleware = [thunk];

export default () => {
  const store = createStore(
    combineReducers({
      mainReducer,
      userReducer,
      buyRollUpReducers,
      buyOptimizationReducer,
      assortmentPlanningReducer,
      clusteringReducer,
      filtersReducer,
      projectReducer
    }),
    applyMiddleware(...middleware)
  );
  return store;
};

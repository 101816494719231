import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup, Input, Label } from "reactstrap";
import Switch from "@material-ui/core/Switch";
import { isEqual } from "lodash";
import moment from "moment";
import Alert from "../../../services/alert";
import Tippy from "@tippy.js/react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import FilterActions from "../../../actions/FilterActions";
import MonthPicker from "../../../services/monthPicker";
import Select from "../../../services/selectAll";
import RetailCalendar from "../../../services/RetailCalendar";

class Step1AllocationBasics extends Component {
  constructor(props) {
    super(props);
    this.props.getFobData();
    this.props.getDepartmentsData();
    this.state = {
      selectedMonthRange: null,
      name: props.clusteringInputFields.name,
      fobId: props.clusteringInputFields.fobId,
      dmmId: props.clusteringInputFields.dmmId,
      buyerId: props.clusteringInputFields.buyerFromHindsight,
      dcId: props.clusteringInputFields.dcId,
      departmentId: props.clusteringInputFields.departments,
      classes: props.clusteringInputFields.classes,
      vendors: props.clusteringInputFields.vendors,
      departmentIdTarget: props.clusteringInputFields.departmentid_target,
      classesTarget: props.clusteringInputFields.classes_target,
      vendorsTarget: props.clusteringInputFields.vendors_target,
      season: props.clusteringInputFields.season,
      sellingStartDate: props.clusteringInputFields.sellingPeriod.sdate
        ? moment(props.clusteringInputFields.sellingPeriod.sdate)
        : null,
      sellingEndDate: props.clusteringInputFields.sellingPeriod.edate
        ? moment(props.clusteringInputFields.sellingPeriod.edate)
        : null,
      receiptStartDate: props.clusteringInputFields.receiptRange.sdate
        ? moment(props.clusteringInputFields.receiptRange.sdate)
        : null,
      receiptEndDate: props.clusteringInputFields.receiptRange.edate
        ? moment(props.clusteringInputFields.receiptRange.edate)
        : null,
      clusteringBasis: props.clusteringInputFields.salesFlag,
      isOtb: props.clusteringInputFields.otb_flag,
      includeEcom: props.clusteringInputFields.ecommFlag,
      includeLostSales: props.clusteringInputFields.isLostSales,
      includeFashionReplenishment: props.clusteringInputFields.rplFlag,
      seasonLevelFlag: props.clusteringInputFields.is_season_level,
      isIncludeRegularStyles: props.clusteringInputFields["reg_flag"],
      attributeClusturingFlag:
        props.clusteringInputFields.hasAttributeClustering,
      ClusterBasisRegular: true,
      ssds: [],
      isRunClusteringDisable: false,
      isEcommAlertVisible: false,
      isLostSalesAlertVisible: false,
      lyFlag: props.lyFlag,
      monthPickerIsOpen: false,
      monthPickerStartDateSelected: true,
      monthPickerEndDateSelected: false,
      isComp: props.clusteringInputFields.isComp
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname === "/hindsightCluster") {
      if (
        !state.name ||
        // !state.buyerId.value ||
        !(
          state.departmentId &&
          (state.departmentId.length || Object.keys(state.departmentId).length)
        ) ||
        !(state.classes && state.classes.length) ||
        !(state.vendors && state.vendors.length) ||
        !(state.season && state.season.length) ||
        !state.receiptStartDate ||
        !state.receiptEndDate ||
        !state.sellingStartDate ||
        !state.sellingEndDate ||
        !(state.includeFashionReplenishment || state.isIncludeRegularStyles)
      ) {
        props.runClusteringDisableToggler(true);
      } else {
        props.runClusteringDisableToggler(false);
      }
    } else {
      if (
        !state.name ||
        // !state.buyerId.value ||
        !(
          state.departmentId &&
          (state.departmentId.length || Object.keys(state.departmentId).length)
        ) ||
        (!state.isComp &&
          !(
            state.departmentIdTarget &&
            (state.departmentIdTarget.length ||
              Object.keys(state.departmentIdTarget).length)
          )) ||
        !(state.classes && state.classes.length) ||
        !(state.vendors && state.vendors.length) ||
        !(state.season && state.season.length) ||
        !state.receiptStartDate ||
        !state.receiptEndDate ||
        !state.sellingStartDate ||
        !state.sellingEndDate
      ) {
        props.runClusteringDisableToggler(true);
      } else {
        props.runClusteringDisableToggler(false);
      }
    }
    return null;
  }
  componentDidMount() {
    // if (this.state.buyerId) {
    //   this.props.getDepartmentsData({
    //     buyerId: this.state.buyerId.map(e => e.value)
    //   });
    // }
    if (this.state.departmentId) {
      this.props.getClassesData({
        deptID: this.state.departmentId.map(e => e.value)
      });
      if (this.state.classes.length) {
        this.props.getVendorsData({
          deptID: this.state.departmentId.map(e => e.value),
          classIDs: this.state.classes.map(e => e.value)
        });
        if (this.state.vendors.length) {
          this.props.getSeasonData({
            deptID: this.state.departmentId.map(e => e.value),
            classIds: this.state.classes.map(e => e.value),
            vendorIds: this.state.vendors.map(e => e.value)
          });
        }
      }
    }
  }

  componentDidUpdate(oldProps) {
    if (
      this.props.clusteringInputFields &&
      !isEqual(oldProps.clusteringInputFields, this.props.clusteringInputFields)
    ) {
      let clusteringInputFields = this.props.clusteringInputFields;
      this.setState({
        name: clusteringInputFields.name,
        fobId: clusteringInputFields.fobId,
        dmmId: clusteringInputFields.dmmId,
        buyerId: clusteringInputFields.buyerFromHindsight,
        dcId: clusteringInputFields.dcId,
        departmentId:
          clusteringInputFields.departments &&
          clusteringInputFields.departments[0],
        classes: clusteringInputFields.classes,
        vendors: clusteringInputFields.vendors,
        departmentIdTarget: clusteringInputFields.departmentid_target,
        classesTarget: clusteringInputFields.classes_target,
        vendorsTarget: clusteringInputFields.vendors_target,
        sellingStartDate: clusteringInputFields.sellingPeriod
          ? moment(clusteringInputFields.sellingPeriod.sdate)
          : null,
        sellingEndDate: clusteringInputFields.sellingPeriod
          ? moment(clusteringInputFields.sellingPeriod.edate)
          : null,
        receiptStartDate: clusteringInputFields.receiptRange
          ? moment(clusteringInputFields.receiptRange.sdate)
          : null,
        receiptEndDate: clusteringInputFields.receiptRange
          ? moment(clusteringInputFields.receiptRange.edate)
          : null,
        season: clusteringInputFields.season,
        clusteringBasis: clusteringInputFields.salesFlag,
        isOtb: clusteringInputFields.otb_flag,
        includeEcom: clusteringInputFields.ecommFlag,
        includeFashionReplenishment: clusteringInputFields.rplFlag,
        attributeClusturingFlag: clusteringInputFields.hasAttributeClustering,
        seasonLevelFlag: clusteringInputFields.is_season_level,
        isIncludeRegularStyles: clusteringInputFields["reg_flag"],
        includeLostSales: clusteringInputFields.isLostSales,
        lyFlag: this.props.lyFlag,
        isComp: clusteringInputFields.isComp
      });
    }
  }

  handleNameChange = e => {
    const name = e.currentTarget.value;
    this.setState({ name });
    this.props.updateClusteringDetails("name", name);
  };

  onReceiptDatesChange = dateObject => {
    this.setState({
      receiptStartDate: dateObject.startDate,
      receiptEndDate: dateObject.endDate,
      sellingStartDate: dateObject.startDate,
      sellingEndDate: null
    });

    let sellingPeriod = {
      sdate: dateObject.startDate,
      edate: null
    };
    let receiptRange = {
      sdate: dateObject.startDate,
      edate: dateObject.endDate
    };
    this.props.updateClusteringDetails("receiptRange", receiptRange);
    this.props.updateClusteringDetails("sellingPeriod", sellingPeriod);
  };
  onSellingDatesChange = dateObject => {
    this.setState({
      sellingStartDate: dateObject.startDate,
      sellingEndDate: dateObject.endDate
    });

    let sellingPeriod = {
      sdate: dateObject.startDate,
      edate: dateObject.endDate
    };

    this.props.updateClusteringDetails("sellingPeriod", sellingPeriod);
  };
  onFobChange = val => {
    if (val && val.length) {
      this.props.getDmmData({
        fobIds: val.map(e => Number(e.value))
      });
      this.props.updateClusteringDetails("fobId", val);
    }
    if (val && val.length === 0) {
      this.props.updateClusteringDetails("dmmId", []);
      this.props.updateClusteringDetails("buyerId", []);
      this.props.updateClusteringDetails("dcId", []);
      this.props.updateClusteringDetails("departmentId", []);
      this.props.updateClusteringDetails("classes", []);
      this.props.updateClusteringDetails("vendors", []);
      this.props.updateClusteringDetails("season", []);
      this.setState({
        dmmId: [],
        buyerId: [],
        dcId: [],
        departmentId: [],
        classes: [],
        vendors: [],
        season: []
      });
    }
    this.setState({
      fobId: val
    });
    this.props.updateClusteringDetails("dmmId", []);
    this.props.updateClusteringDetails("buyerId", []);
    this.props.updateClusteringDetails("dcId", []);
    this.props.updateClusteringDetails("departmentId", []);
    this.props.updateClusteringDetails("classes", []);
    this.props.updateClusteringDetails("vendors", []);
    this.props.updateClusteringDetails("season", []);
  };
  onDmmChange = val => {
    if (val && val.length) {
      this.props.getBuyerDataBuyRollup({
        dmmIds: val.map(e => Number(e.value))
      });
      this.props.updateClusteringDetails("dmmId", val);
    }
    if (val && val.length === 0) {
      this.props.updateClusteringDetails("buyerId", []);
      this.props.updateClusteringDetails("dcId", []);
      this.props.updateClusteringDetails("departmentId", []);
      this.props.updateClusteringDetails("classes", []);
      this.props.updateClusteringDetails("vendors", []);
      this.props.updateClusteringDetails("season", []);
      this.setState({
        buyerId: [],
        dcId: [],
        departmentId: [],
        classes: [],
        vendors: [],
        season: []
      });
    }
    this.setState({
      dmmId: val
    });
    this.props.updateClusteringDetails("buyerId", []);
    this.props.updateClusteringDetails("dcId", []);
    this.props.updateClusteringDetails("departmentId", []);
    this.props.updateClusteringDetails("classes", []);
    this.props.updateClusteringDetails("vendors", []);
    this.props.updateClusteringDetails("season", []);
  };
  onBuyerChange = val => {
    if (val && val.length) {
      this.props.getDcData({
        buyerIds: val.map(e => Number(e.value))
      });
      this.props.updateClusteringDetails("buyerFromHindsight", val);
    }
    if (val && val.length === 0) {
      this.props.updateClusteringDetails("dcId", []);
      this.props.updateClusteringDetails("departmentId", []);
      this.props.updateClusteringDetails("classes", []);
      this.props.updateClusteringDetails("vendors", []);
      this.props.updateClusteringDetails("season", []);
      this.setState({
        dcId: [],
        departmentId: [],
        classes: [],
        vendors: [],
        season: []
      });
    }
    this.setState({
      buyerId: val
    });
    this.props.updateClusteringDetails("dcId", []);
    this.props.updateClusteringDetails("departmentId", []);
    this.props.updateClusteringDetails("classes", []);
    this.props.updateClusteringDetails("vendors", []);
    this.props.updateClusteringDetails("season", []);
  };
  onDcChange = val => {
    if (val && val.length) {
      this.props.getDepartmentsAccToDCData({
        dcIds: val.map(e => Number(e.value)),
        fobIds: this.state.fobId.map(e => Number(e.value)),
        buyerIds: this.state.buyerId.map(e => Number(e.value)),
        dmmIds: this.state.dmmId.map(e => Number(e.value)),
      });
      this.props.updateClusteringDetails("dcId", val);
    }
    if (val && val.length === 0) {
      this.props.updateClusteringDetails("departmentId", []);
      this.props.updateClusteringDetails("classes", []);
      this.props.updateClusteringDetails("vendors", []);
      this.props.updateClusteringDetails("season", []);
      this.setState({
        departmentId: [],
        classes: [],
        vendors: [],
        season: []
      });
    }
    this.setState({
      dcId: val
    });
    this.props.updateClusteringDetails("departmentId", []);
    this.props.updateClusteringDetails("classes", []);
    this.props.updateClusteringDetails("vendors", []);
    this.props.updateClusteringDetails("season", []);
  };

  onDepartmentChange = val => {
    if (val && val.length) {
      this.props.getClassesData({
        deptID: val.map(e => Number(e.value))
      });
      this.props.updateClusteringDetails(
        "departmentId",
        val.map(e => e.value)
      );
    }
    if (val && val.length === 0) {
      this.props.updateClusteringDetails("classes", []);
      this.props.updateClusteringDetails("vendors", []);
      this.props.updateClusteringDetails("season", []);
      this.setState({
        departmentId: [],
        classes: [],
        vendors: [],
        season: []
      });
    }
    this.setState({
      departmentId: val
    });
    this.props.updateClusteringDetails("classes", []);
    this.props.updateClusteringDetails("vendors", []);
    this.props.updateClusteringDetails("season", []);
  };
  onDepartmentSelect = val => {
    if (val) {
      if (val && val.length) {
        this.props.getClassesData({ deptID: val.map(e => e.value) });
        this.props.updateClusteringDetails(
          "departmentId",
          val.map(e => e.value)
        );
      } else {
        val = [val];
        this.props.getClassesData({ deptID: val.map(e => e.value) });
        this.props.updateClusteringDetails(
          "departmentId",
          val.map(e => e.value)
        );
      }
      this.props.updateClusteringDetails("classes", []);
      this.props.updateClusteringDetails("vendors", []);
      this.props.updateClusteringDetails("season", []);
      this.setState({
        departmentId: val,
        departmentIdTarget: [],
        classes: [],
        classesTarget: [],
        vendors: [],
        vendorsTarget: [],
        season: []
      });
    } else {
      this.props.updateClusteringDetails("departmentId", []);
      this.props.updateClusteringDetails("classes", []);
      this.props.updateClusteringDetails("vendors", []);
      this.setState({
        departmentId: [],
        departmentIdTarget: [],
        classes: [],
        classesTarget: [],
        vendors: [],
        vendorsTarget: [],
        season: []
      });
    }
  };

  onDepartmentSelectTarget = val => {
    if (val) {
      if (val && val.length) {
        this.props.getClassesDataTarget({ deptID: val.map(e => e.value) });
        this.props.updateClusteringDetails(
          "departmentIdTarget",
          val.map(e => e.value)
        );
      } else {
        val = [val];
        this.props.getClassesDataTarget({ deptID: val.map(e => e.value) });
        this.props.updateClusteringDetails(
          "departmentIdTarget",
          val.map(e => e.value)
        );
      }
      this.props.updateClusteringDetails("classesTarget", []);
      this.props.updateClusteringDetails("vendorsTarget", []);
      this.setState({
        departmentIdTarget: val,
        classesTarget: [],
        vendorsTarget: []
      });
    } else {
      this.props.updateClusteringDetails("departmentIdTarget", []);
      this.props.updateClusteringDetails("classesTarget", []);
      this.props.updateClusteringDetails("vendorsTarget", []);
      this.setState({
        departmentIdTarget: [],
        classesTarget: [],
        vendorsTarget: []
      });
    }
  };
  onClassSelect = val => {
    if (val && val.length) {
      this.props.getVendorsData({
        deptID: this.state.departmentId.map(e => e.value),
        classIDs: val.map(e => e.value)
      });
      this.props.updateClusteringDetails(
        "classes",
        val.map(e => e.value)
      );
      this.props.updateClusteringDetails("vendors", []);
      this.props.updateClusteringDetails("season", []);
      this.setState({
        classes: val,
        classesTarget: [],
        vendors: [],
        vendorsTarget: [],
        season: []
      });
    } else {
      this.props.updateClusteringDetails("classes", []);
      this.props.updateClusteringDetails("vendors", []);
      this.props.updateClusteringDetails("season", []);
      this.setState({
        classes: [],
        classesTarget: [],
        vendors: [],
        vendorsTarget: [],
        season: []
      });
    }
  };

  onClassSelectTarget = val => {
    if (val && val.length) {
      this.props.getVendorsDataTarget({
        deptID: this.state.departmentIdTarget.map(e => e.value),
        classIDs: val.map(e => e.value)
      });
      this.props.updateClusteringDetails(
        "classesTarget",
        val.map(e => e.value)
      );
      this.props.updateClusteringDetails("vendorsTarget", []);
      this.setState({
        classesTarget: val,
        vendorsTarget: []
      });
    } else {
      this.props.updateClusteringDetails("classesTarget", []);
      this.props.updateClusteringDetails("vendorsTarget", []);
      this.setState({
        classesTarget: [],
        vendorsTarget: []
      });
    }
  };
  onVendorSelect = val => {
    if (val && val.length) {
      this.props.getSeasonData({
        deptID: this.state.departmentId.map(e => e.value),
        classIds: this.state.classes.map(e => e.value),
        vendorIds: val.map(e => e.value)
      });
      this.props.updateClusteringDetails(
        "vendors",
        val.map(e => e.value)
      );
      this.props.updateClusteringDetails("season", []);
      this.setState({
        vendors: val,
        vendorsTarget: [],
        season: []
      });
    } else {
      this.props.updateClusteringDetails("vendors", []);
      this.props.updateClusteringDetails("season", []);
      this.setState({
        vendors: [],
        vendorsTarget: [],
        season: []
      });
    }
    // this.props.updateClusteringDetails("vendors", val.map(e => e.value));
    // this.setState({ vendors: val });
  };

  onVendorSelectTarget = val => {
    if (val && val.length) {
      this.props.updateClusteringDetails(
        "vendorsTarget",
        val.map(e => e.value)
      );
      this.setState({
        vendorsTarget: val
      });
    } else {
      this.props.updateClusteringDetails("vendorsTarget", []);
      this.setState({
        vendorsTarget: []
      });
    }
  };
  onSeasonSelect = val => {
    this.props.updateClusteringDetails(
      "season",
      val.map(e => e.value)
    );
    this.setState({ season: val });
  };
  handleAccept = () => {
    this.props.updateClusteringDetails("ecommFlag", true);
    this.setState({
      includeEcom: true,
      isEcommAlertVisible: false
    });
  };
  handleAcceptLostSales = () => {
    this.props.updateClusteringDetails("isLostSales", true);
    this.setState({
      includeLostSales: true,
      isLostSalesAlertVisible: false
    });
  };
  // handleClose = () => {
  //   this.props.updateClusteringDetails("ecommFlag", false);
  //   this.setState({
  //     includeEcom: false,
  //     isEcommAlertVisible: false
  //   });
  // };
  onLostSalesFlagChange = val => {
    if (val.target.checked) {
      this.setState({ isLostSalesAlertVisible: true });
    } else {
      this.props.updateClusteringDetails(
        "isLostSales",
        val.currentTarget.checked
      );
      this.setState({
        includeLostSales: val.currentTarget.checked
        // isEcommAlertVisible: true
      });
    }
  };
  onEcommFlagChange = val => {
    if (val.target.checked) {
      this.setState({ isEcommAlertVisible: true });
    } else {
      this.props.updateClusteringDetails(
        "ecommFlag",
        val.currentTarget.checked
      );
      this.setState({
        includeEcom: val.currentTarget.checked
        // isEcommAlertVisible: true
      });
    }
  };
  onEcommFlagChangeMain = e => {
    if (!this.state.includeEcom) {
      this.setState({ isEcommAlertVisible: true });
    } else {
      this.setState(
        {
          includeEcom: false
        },
        () => {
          this.props.updateClusteringDetails("ecommFlag", false);
        }
      );
    }
  };
  onFashionReplenishmentFlag = val => {
    this.props.updateClusteringDetails("rplFlag", val.currentTarget.checked);
    this.setState({
      includeFashionReplenishment: val.currentTarget.checked
    });
  };
  onFashionReplenishmentFlagMain = e => {
    if (!this.state.includeFashionReplenishment) {
      this.setState(
        {
          includeFashionReplenishment: true
        },
        () => {
          this.props.updateClusteringDetails("rplFlag", true);
        }
      );
    } else {
      this.setState(
        {
          includeFashionReplenishment: false
        },
        () => {
          this.props.updateClusteringDetails("rplFlag", false);
        }
      );
    }
  };
  onAttributeClusturingFlag = val => {
    if (val.target.name === "volAttrCluster") {
      this.props.updateClusteringDetails("hasAttributeClustering", true);
      this.setState({
        attributeClusturingFlag: true
      });
    } else {
      this.props.updateClusteringDetails("hasAttributeClustering", false);
      this.setState({
        attributeClusturingFlag: false
      });
    }
  };
  onAttributeClusturingFlagMain = e => {
    if (!this.state.attributeClusturingFlag) {
      this.setState(
        {
          attributeClusturingFlag: true
        },
        () => {
          this.props.updateClusteringDetails("hasAttributeClustering", true);
        }
      );
    } else {
      this.setState(
        {
          attributeClusturingFlag: false
        },
        () => {
          this.props.updateClusteringDetails("hasAttributeClustering", false);
        }
      );
    }
  };
  onRegularStyleFlag = val => {
    this.props.updateClusteringDetails("reg_flag", val.currentTarget.checked);
    this.setState({
      isIncludeRegularStyles: val.currentTarget.checked
    });
  };
  onClusteringBasisChanges = val => {
    if (val.target.name === "regular") {
      this.props.updateClusteringDetails("salesFlag", true);
      this.setState({
        clusteringBasis: true
      });
    } else {
      this.props.updateClusteringDetails("salesFlag", false);
      this.setState({
        clusteringBasis: false
      });
    }
  };

  onClusteringBasisChangesMain = e => {
    if (!this.state.clusteringBasis) {
      this.setState(
        {
          clusteringBasis: true
        },
        () => {
          this.props.updateClusteringDetails("salesFlag", true);
        }
      );
    } else {
      this.setState(
        {
          clusteringBasis: false
        },
        () => {
          this.props.updateClusteringDetails("salesFlag", false);
        }
      );
    }
  };

  onSeasonLevelChanges = val => {
    if (val.target.name === "month") {
      this.props.updateClusteringDetails("is_season_level", false);
      this.setState({
        seasonLevelFlag: false
      });
    } else {
      this.props.updateClusteringDetails("is_season_level", true);
      this.setState({
        seasonLevelFlag: true
      });
    }
  };
  onSeasonLevelChangesMain = e => {
    if (!this.state.seasonLevelFlag) {
      this.setState(
        {
          seasonLevelFlag: true
        },
        () => {
          this.props.updateClusteringDetails("is_season_level", true);
        }
      );
    } else {
      this.setState(
        {
          seasonLevelFlag: false
        },
        () => {
          this.props.updateClusteringDetails("is_season_level", false);
        }
      );
    }
  };
  onUseLLYDataForComparisonChange = val => {
    this.props.updateClusteringDetails("lyFlag", val);
    this.setState({
      lyFlag: val
    });
  };
  onChangeOtb = e => {
    let resetFields = {
      sellingStartDate: null,
      sellingEndDate: null,
      receiptStartDate: null,
      receiptEndDate: null
    };
    if (!this.state.isOtb) {
      this.setState(
        {
          isOtb: true,
          ...resetFields
        },
        () => {
          if (this.state.isOtb) {
            this.props.updateClusteringDetails("otb_flag", true);
          }
        }
      );
    } else {
      this.setState(
        {
          isOtb: false,
          ...resetFields,
          selectedMonthRange: null
        },
        () => {
          this.props.updateClusteringDetails("otb_flag", false);
          let receiptRange = {
            sdate: null,
            edate: null
          };
          this.props.updateClusteringDetails("receiptRange", receiptRange);
        }
      );
    }
  };

  onChangeComp = () => {
    if (!this.state.isComp) {
      this.setState(
        {
          isComp: true
        },
        () => {
          this.props.updateClusteringDetails("isComp", true);
        }
      );
    } else {
      this.setState(
        {
          isComp: false
        },
        () => {
          this.props.updateClusteringDetails("isComp", false);
        }
      );
    }
  };
  onSelectedMonthRange = date => {
    if (!date) {
      this.setState({
        selectedMonthRange: date,
        receiptStartDate: null,
        receiptEndDate: null
      });
      let receiptRange = {
        sdate: null,
        edate: null
      };
      this.props.updateClusteringDetails("receiptRange", receiptRange);
    } else {
      this.setState({
        selectedMonthRange: date,
        receiptStartDate: moment(date[0]),
        receiptEndDate: moment(date[1])
      });
      let receiptRange = {
        sdate: moment(date[0]),
        edate: moment(date[1])
      };
      this.props.updateClusteringDetails("receiptRange", receiptRange);
    }
    this.onSellingDatesChange({ startDate: null, endDate: null });
  };
  onMonthClicked = date => {
    if (this.state.monthPickerStartDateSelected) {
      this.setState({
        monthPickerStartDateSelected: false,
        monthPickerEndDateSelected: true,
        receiptStartDate: moment(date),
        sellingStartDate: moment(date),
        receiptEndDate: null,
        monthPickerIsOpen: true
      });
    } else {
      const startDate = this.state.receiptStartDate;
      const endDate = moment(date);
      let sd = startDate;
      let ed = endDate;

      if (new Date(endDate) < new Date(startDate)) {
        sd = endDate;
        ed = startDate;
      }

      this.setState(
        {
          monthPickerStartDateSelected: true,
          monthPickerEndDateSelected: false,
          receiptStartDate: sd,
          receiptEndDate: ed,
          monthPickerIsOpen: false
        },
        () => {
          let receiptRange = {
            sdate: this.state.receiptStartDate,
            edate: this.state.receiptEndDate
          };
          this.props.updateClusteringDetails("receiptRange", receiptRange);
        }
      );
    }
    // this.onSellingDatesChange({ startDate: null, endDate: null });
  };

  clearMonthPickerData = e => {
    e.preventDefault();
    this.setState({
      monthPickerIsOpen: false,
      monthPickerStartDateSelected: true,
      monthPickerEndDateSelected: false,
      receiptStartDate: null,
      receiptEndDate: null
    });
  };

  receiptDateRangeComponent = () => {
    return (
      <RetailCalendar
        startDateId="your_unique_start_date_id_rp"
        receiptDateRangeComponent
        endDateId="your_unique_end_date_id_rp"
        startDate={this.state.receiptStartDate}
        disabled={this.props.isClusterDisabled}
        endDate={this.state.receiptEndDate}
        onDatesChange={({ startDate, endDate }) => {
          this.onReceiptDatesChange({ startDate, endDate });
        }}
      />
    );
  };
  sellingDateRangeComponent = () => {
    return (
      <RetailCalendar
        startDate={this.state.sellingStartDate}
        endDate={this.state.sellingEndDate}
        startDateId="your_unique_start_date_id_sp"
        endDateId="your_unique_end_date_id_sp"
        disabled={this.props.isClusterDisabled || !this.state.receiptStartDate}
        onDatesChange={({ startDate, endDate }) => {
          this.onSellingDatesChange({ startDate, endDate });
        }}
      />
    );
  };

  render() {
    return (
      <div className="card">
        <div className="card-body">
          {this.props.isHindsightFlag ? (
            <form>
              <div className="form-row">
                <div className="form-group px-5 col-md-4">
                  <label>Cluster Plan Name *</label>
                  <input
                    style={{ minHeight: "38px" }}
                    className="form-control"
                    disabled={this.props.isClusterDisabled}
                    value={this.state.name ? this.state.name : ""}
                    placeholder="Cluster Name"
                    onChange={val => this.handleNameChange(val)}
                  />
                </div>
                <div className="form-group px-5 col-md-4">
                  <label>Select FOB *</label>
                  <Select
                    isMulti={true}
                    value={this.state.fobId}
                    closeMenuOnSelect={false}
                    allowSelectAll={true}
                    onChange={val => this.onFobChange(val)}
                    options={this.props.fobList || []}
                    isDisabled={this.props.isClusterDisabled}
                    isClearable
                  />
                </div>
                <div className="form-group px-5 col-md-4">
                  <label>Select DMM *</label>
                  <Select
                    isMulti={true}
                    value={this.state.dmmId}
                    isDisabled={
                      (this.state.fobId && this.state.fobId.length === 0) ||
                      this.props.isClusterDisabled
                    }
                    closeMenuOnSelect={false}
                    allowSelectAll={true}
                    onChange={val => this.onDmmChange(val)}
                    options={this.props.dmmList || []}
                    isClearable
                  />
                </div>
                <div className="form-group px-5 col-md-4">
                  <label>Select Buyer *</label>
                  <Select
                    isMulti={true}
                    value={this.state.buyerId}
                    isDisabled={
                      (this.state.dmmId && this.state.dmmId.length === 0) ||
                      this.props.isClusterDisabled
                    }
                    closeMenuOnSelect={false}
                    allowSelectAll={true}
                    onChange={val => this.onBuyerChange(val)}
                    options={this.props.buyerListBuyRollup || []}
                    isClearable
                  />
                </div>
                <div className="form-group px-5 col-md-4">
                  <label>Select DC *</label>
                  <Select
                    isMulti={true}
                    value={this.state.dcId}
                    isDisabled={
                      (this.state.buyerId && this.state.buyerId.length === 0) ||
                      this.props.isClusterDisabled
                    }
                    closeMenuOnSelect={false}
                    allowSelectAll={true}
                    onChange={val => this.onDcChange(val)}
                    options={this.props.dcList || []}
                    isClearable
                  />
                </div>
                <div className="form-group px-5 col-md-4">
                  <label>Select Department *</label>
                  <Select
                    isMulti={true}
                    isClearable
                    allowSelectAll={true}
                    closeMenuOnSelect={false}
                    isDisabled={
                      (this.state.dcId && this.state.dcId.length === 0) ||
                      this.props.isClusterDisabled
                    }
                    onChange={val => this.onDepartmentChange(val)}
                    options={this.props.departmentsListAccDc || []}
                    value={this.state.departmentId}
                  />
                </div>
              </div>
              <div className="form-row mt-2">
                <div className="form-group px-5 col-md-4">
                  <label htmlFor="inputPassword4">Select Class *</label>
                  <Select
                    isDisabled={
                      !(
                        this.state.departmentId &&
                        this.state.departmentId.length
                      ) || this.props.isClusterDisabled
                    }
                    isMulti={true}
                    closeMenuOnSelect={false}
                    allowSelectAll={true}
                    value={this.state.classes}
                    options={this.props.classesList || []}
                    onChange={val => this.onClassSelect(val)}
                  />
                </div>
                <div className="form-group px-5 col-md-4">
                  <label htmlFor="inputPassword4">Select Vendor *</label>
                  <Select
                    isDisabled={
                      (this.state.classes && this.state.classes.length === 0) ||
                      this.props.isClusterDisabled
                    }
                    isMulti={true}
                    closeMenuOnSelect={false}
                    allowSelectAll={true}
                    value={this.state.vendors}
                    options={this.props.vendorsList || []}
                    onChange={val => this.onVendorSelect(val)}
                  />
                </div>
                <div className="form-group px-5 col-md-4">
                  <label htmlFor="inputPassword4">Select Season Code *</label>
                  <Select
                    isDisabled={
                      (this.state.vendors && this.state.vendors.length === 0) ||
                      this.props.isClusterDisabled
                    }
                    isMulti={true}
                    closeMenuOnSelect={false}
                    allowSelectAll={true}
                    isClearable
                    value={this.state.season}
                    options={this.props.seasonList || []}
                    onChange={val => this.onSeasonSelect(val)}
                  />
                </div>
              </div>
              <div className="form-row mt-2">
                <div className="form-group px-5 col-md-4">
                  <FormGroup tag="fieldset">
                    <label>Clustering Basis</label>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="regular"
                        checked={this.state.clusteringBasis}
                        onChange={this.onClusteringBasisChanges}
                        disabled={this.props.isClusterDisabled}
                      />
                      <Label check style={{ marginLeft: "1rem" }}>
                        Regular
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="total"
                        checked={!this.state.clusteringBasis}
                        onChange={this.onClusteringBasisChanges}
                        disabled={this.props.isClusterDisabled}
                      />
                      <Label check style={{ marginLeft: "1rem" }}>
                        Total (Regular + Clearance)
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </div>
                <div className="form-group px-5 col-md-4">
                  <label htmlFor="inputEmail4">
                    Select Future Receipt Period *
                    <Tippy
                      placement={"top"}
                      arrow={true}
                      distance={10}
                      content="Recommend OTB Month selections for instances where actual receipts did not hit the intended receipt month.The date range shown is the calendar period."
                      // content={
                      //   <div>
                      //     If the Receipt Start Date exceeds 25th of any month,
                      //     or if the Receipt End Date falls before the 5th of any
                      //     month, that month's data will not be considered in the
                      //     Line Receipt plan
                      //   </div>
                      // }
                    >
                      <i
                        style={{
                          color: "#2D69EB"
                        }}
                        className="fa fa-info-circle"
                        aria-hidden="true"
                      />
                    </Tippy>
                    <div style={{ fontSize: "14px" }}>
                      Buying Date Range
                      <Switch
                        disabled={this.props.isClusterDisabled}
                        checked={this.state.isOtb}
                        onClick={this.onChangeOtb}
                        value={this.state.isOtb}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      PO OTB Month
                    </div>
                  </label>
                  {this.state.isOtb ? (
                    <MonthPicker
                      disabled={this.props.isClusterDisabled}
                      onSelectedMonthRange={this.onSelectedMonthRange}
                      onMonthClicked={this.onMonthClicked}
                      isOpen={this.state.monthPickerIsOpen}
                      clearMonthPickerData={this.clearMonthPickerData}
                      date={[
                        this.state.receiptStartDate,
                        this.state.receiptEndDate
                      ]}
                    />
                  ) : (
                    this.receiptDateRangeComponent()
                  )}
                </div>
                <div className="form-group px-5 col-md-4">
                  <label htmlFor="inputPassword4">
                    Select Future Selling Period *
                    <Tippy
                      placement={"top"}
                      arrow={true}
                      distance={10}
                      content="Intended date range for Buy sales period.The date range shown is the calendar period."
                    >
                      <i
                        style={{
                          color: "#2D69EB"
                        }}
                        className="fa fa-info-circle"
                        aria-hidden="true"
                      />
                    </Tippy>
                  </label>
                  {this.sellingDateRangeComponent()}
                </div>
              </div>
              <div className="form-row mt-2">
                <div className="form-group px-5 col-md-4">
                  <FormGroup tag="fieldset">
                    <label>Attribute Clustering Basis</label>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="volCluster"
                        checked={!this.state.attributeClusturingFlag}
                        onChange={this.onAttributeClusturingFlag}
                        disabled={this.props.isClusterDisabled}
                      />
                      <Label check style={{ marginLeft: "1rem" }}>
                        Volumetric Clustering
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="volAttrCluster"
                        checked={this.state.attributeClusturingFlag}
                        onChange={this.onAttributeClusturingFlag}
                        disabled={true}
                      />
                      <Label check style={{ marginLeft: "1rem" }}>
                        (Volumetric + Attribute) Clustering
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </div>
                <div className="form-group px-5 col-md-4">
                    <FormGroup
                      tag="fieldset"
                      style={{ display: "inline-flex" }}
                    >
                      <label>Compare data with</label>
                      <FormGroup check>
                        <Input
                          type="radio"
                          name="ly"
                          checked={this.state.lyFlag}
                          onChange={() =>
                            this.onUseLLYDataForComparisonChange(true)
                          }
                          disabled={this.props.isClusterDisabled}
                        />
                        <Label check style={{ marginLeft: "1rem" }}>
                          LY
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          type="radio"
                          name="lly"
                          checked={!this.state.lyFlag}
                          onChange={() =>
                            this.onUseLLYDataForComparisonChange(false)
                          }
                          disabled={this.props.isClusterDisabled}
                        />
                        <Label check style={{ marginLeft: "1rem" }}>
                          LLY
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </div>
              </div>
              <div className="form-row mt-2">
                <div className="form-group px-5 col-md-4">
                  <label>Select Styles*</label>
                  <div className="main">
                    <label style={{ marginBottom: "0" }}>
                      Includes only Rpln Styles:
                      <input
                        type="checkbox"
                        disabled={this.props.isClusterDisabled}
                        onChange={val => this.onFashionReplenishmentFlag(val)}
                        checked={this.state.includeFashionReplenishment}
                      />
                      <span
                        className={
                          !this.props.isClusterDisabled
                            ? "geekmark geekmarkEnabledBG"
                            : "geekmark geekmarkDisabledBG"
                        }
                      />
                    </label>
                  </div>
                  <div className="main step1">
                    <label style={{ marginBottom: "0" }}>
                      Includes only Fashion Styles:
                      <input
                        type="checkbox"
                        disabled={this.props.isClusterDisabled}
                        onChange={val => this.onRegularStyleFlag(val)}
                        checked={this.state.isIncludeRegularStyles}
                      />
                      <span
                        className={
                          !this.props.isClusterDisabled
                            ? "geekmark geekmarkEnabledBG"
                            : "geekmark geekmarkDisabledBG"
                        }
                      />
                    </label>
                  </div>
                </div>
                <div className="form-group px-5 col-md-4">
                  <br />
                  <br />
                  <div className="main">
                    <label>
                      Exclude ECOMM:
                      <input
                        type="checkbox"
                        disabled={this.props.isClusterDisabled}
                        onChange={val => this.onEcommFlagChange(val)}
                        // checked={
                        //   this.props.match.params.id && this.state.includeEcom
                        //     ? !this.state.includeEcom
                        //     : this.state.includeEcom
                        // }
                        checked={this.state.includeEcom}
                      />
                      <span
                        className={
                          !this.props.isClusterDisabled
                            ? "geekmark geekmarkEnabledBG"
                            : "geekmark geekmarkDisabledBG"
                        }
                      />
                    </label>
                  </div>
                </div>
                {/* <div className="form-group px-5 col-md-4">
                  <br />
                  <br />
                  <div className="main">
                    <label>
                      Exclude Lost Sales:
                      <input
                        type="checkbox"
                        disabled={this.props.isClusterDisabled}
                        onChange={val => this.onLostSalesFlagChange(val)}
                        checked={this.state.includeLostSales}
                      />
                      <span
                        className={
                          !this.props.isClusterDisabled
                            ? "geekmark geekmarkEnabledBG"
                            : "geekmark geekmarkDisabledBG"
                        }
                      />
                    </label>
                  </div>
                </div> */}
              </div>
            </form>
          ) : (
            <form>
              <div className="form-row">
                <div className="form-group px-5 col-md-4">
                  <label>Plan Name *</label>
                  <input
                    style={{ minHeight: "38px" }}
                    className="form-control"
                    disabled={this.props.isClusterDisabled}
                    value={this.state.name ? this.state.name : ""}
                    placeholder="Plan Name"
                    onChange={val => this.handleNameChange(val)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div
                  style={{ fontSize: "14px" }}
                  className="form-group px-5 col-md-4"
                >
                  <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                    COMP
                  </span>
                  <Switch
                    disabled={this.props.isClusterDisabled}
                    checked={!this.state.isComp}
                    onClick={this.onChangeComp}
                    value={this.state.isComp}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />

                  <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                    NON - COMP
                  </span>
                </div>
              </div>
              <div className="form-row" style={{ marginBottom: "1rem" }}>
                <p style={{ fontSize: "20px", fontWeight: "600" }}>
                  Product Hierarchy
                </p>
              </div>
              {!this.state.isComp ? (
                <div className="form-row">
                  <div
                    className="form-group px-5 col-md-4"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Source
                  </div>
                  <div
                    className="form-group px-5 col-md-4"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Target
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="form-row">
                <div className="form-group px-5 col-md-4">
                  <label htmlFor="inputPassword4">Select Department ID *</label>
                  <Select
                    value={this.state.departmentId}
                    options={this.props.departmentsList || []}
                    isClearable
                    isDisabled={this.props.isClusterDisabled}
                    onChange={val => this.onDepartmentSelect(val)}
                  />
                </div>
                {!this.state.isComp ? (
                  <>
                    <div className="form-group px-5 col-md-4">
                      <label htmlFor="inputPassword4">
                        Select Department ID *
                      </label>
                      <Select
                        value={this.state.departmentIdTarget}
                        options={this.props.departmentsList || []}
                        isClearable
                        isDisabled={!this.state.departmentId || this.props.isClusterDisabled}
                        onChange={val => this.onDepartmentSelectTarget(val)}
                      />
                    </div>
                    <div className="form-group px-5 col-md-4"></div>{" "}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="form-row">
                <div className="form-group px-5 col-md-4">
                  <label htmlFor="inputPassword4">Select Class *</label>
                  <Select
                    isDisabled={
                      !this.state.departmentId || this.props.isClusterDisabled
                    }
                    isMulti={true}
                    closeMenuOnSelect={false}
                    allowSelectAll={true}
                    value={this.state.classes}
                    options={this.props.classesList || []}
                    onChange={val => this.onClassSelect(val)}
                  />
                </div>
                {!this.state.isComp ? (
                  <>
                    <div className="form-group px-5 col-md-4">
                      <label htmlFor="inputPassword4">Select Class</label>
                      <Select
                        isDisabled={
                          (this.state.classes &&
                            this.state.classes.length === 0) ||
                          this.props.isClusterDisabled
                        }
                        isMulti={true}
                        closeMenuOnSelect={false}
                        allowSelectAll={true}
                        value={this.state.classesTarget}
                        options={this.props.classesListTarget || []}
                        onChange={val => this.onClassSelectTarget(val)}
                      />
                    </div>
                    <div className="form-group px-5 col-md-4"></div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="form-row">
                <div className="form-group px-5 col-md-4">
                  <label htmlFor="inputPassword4">Select Vendor *</label>
                  <Select
                    isDisabled={
                      (this.state.classes && this.state.classes.length === 0) ||
                      this.props.isClusterDisabled
                    }
                    isMulti={true}
                    closeMenuOnSelect={false}
                    allowSelectAll={true}
                    value={this.state.vendors}
                    options={this.props.vendorsList || []}
                    onChange={val => this.onVendorSelect(val)}
                  />
                </div>
                {!this.state.isComp ? (
                  <>
                    <div className="form-group px-5 col-md-4">
                      <label htmlFor="inputPassword4">Select Vendor</label>
                      <Select
                        isDisabled={
                          (this.state.vendors &&
                            this.state.vendors.length === 0) ||
                          this.props.isClusterDisabled
                        }
                        isMulti={true}
                        closeMenuOnSelect={false}
                        allowSelectAll={true}
                        value={this.state.vendorsTarget}
                        options={this.props.vendorsListTarget || []}
                        onChange={val => this.onVendorSelectTarget(val)}
                      />
                    </div>
                    <div className="form-group px-5 col-md-4"></div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="form-row">
                <div className="form-group px-5 col-md-4">
                  <label htmlFor="inputPassword4">Select Season Code *</label>
                  <Select
                    isDisabled={
                      (this.state.vendors && this.state.vendors.length === 0) ||
                      this.props.isClusterDisabled
                    }
                    isMulti={true}
                    closeMenuOnSelect={false}
                    allowSelectAll={true}
                    isClearable
                    value={this.state.season}
                    options={this.props.seasonList || []}
                    onChange={val => this.onSeasonSelect(val)}
                  />
                </div>
              </div>
              <div style={{ marginTop: "1rem" }}>
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    marginBottom: "1rem",
                    display: "block"
                  }}
                >
                  Buy Plan Date Range
                </span>
                <div className="form-row mt-2">
                  <div
                    style={{ fontSize: "14px" }}
                    className="form-group px-5 col-md-4"
                  >
                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                      Buying Date Range
                    </span>
                    <Switch
                      disabled={this.props.isClusterDisabled}
                      checked={this.state.isOtb}
                      onClick={this.onChangeOtb}
                      value={this.state.isOtb}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                      {" "}
                      PO OTB Month
                    </span>
                  </div>
                  <div className="form-group px-5 col-md-4">
                    <FormGroup
                      tag="fieldset"
                      style={{ display: "inline-flex" }}
                    >
                      <label>Compare data with</label>
                      <FormGroup check>
                        <Input
                          type="radio"
                          name="ly"
                          checked={this.state.lyFlag}
                          onChange={() =>
                            this.onUseLLYDataForComparisonChange(true)
                          }
                          disabled={this.props.isClusterDisabled}
                        />
                        <Label check style={{ marginLeft: "1rem" }}>
                          LY
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          type="radio"
                          name="lly"
                          checked={!this.state.lyFlag}
                          onChange={() =>
                            this.onUseLLYDataForComparisonChange(false)
                          }
                          disabled={this.props.isClusterDisabled}
                        />
                        <Label check style={{ marginLeft: "1rem" }}>
                          LLY
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </div>
                  <div className="form-group px-5 col-md-4"></div>
                  <div className="form-group px-5 col-md-4">
                    <label htmlFor="inputEmail4">
                      Select Future Receipt Period *
                      <Tippy
                        placement={"top"}
                        arrow={true}
                        distance={10}
                        content="Recommend OTB Month selections for instances where actual receipts did not hit the intended receipt month.The date range shown is the calendar period."
                      >
                        <i
                          style={{
                            color: "#2D69EB"
                          }}
                          className="fa fa-info-circle"
                          aria-hidden="true"
                        />
                      </Tippy>
                    </label>
                    {this.state.isOtb ? (
                      <MonthPicker
                        disabled={this.props.isClusterDisabled}
                        onSelectedMonthRange={this.onSelectedMonthRange}
                        onMonthClicked={this.onMonthClicked}
                        isOpen={this.state.monthPickerIsOpen}
                        clearMonthPickerData={this.clearMonthPickerData}
                        date={[
                          this.state.receiptStartDate,
                          this.state.receiptEndDate
                        ]}
                      />
                    ) : (
                      this.receiptDateRangeComponent()
                    )}
                  </div>
                  <div className="form-group px-5 col-md-4">
                    <label htmlFor="inputPassword4">
                      Select Future Selling Period *
                      <Tippy
                        placement={"top"}
                        arrow={true}
                        distance={10}
                        content="Intended date range for Buy sales period.The date range shown is the calendar period."
                      >
                        <i
                          style={{
                            color: "#2D69EB"
                          }}
                          className="fa fa-info-circle"
                          aria-hidden="true"
                        />
                      </Tippy>
                    </label>
                    {this.sellingDateRangeComponent()}
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "1rem" }}>
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    marginBottom: "1rem",
                    display: "block"
                  }}
                >
                  Controls
                </span>
                <div className="form-row">
                  <div className="form-group px-5 mt-2 col-md-2 main">
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center"
                      }}
                      className="form-group"
                    >
                      <Switch
                        disabled={this.props.isClusterDisabled}
                        checked={!this.state.clusteringBasis}
                        onClick={this.onClusteringBasisChangesMain}
                        value={this.state.clusteringBasis}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {" "}
                        Include CLR
                      </span>
                    </div>
                  </div>
                  <div className="form-group px-5 mt-2 col-md-2 main">
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center"
                      }}
                      className="form-group"
                    >
                      <Switch
                        disabled={this.props.isClusterDisabled}
                        checked={!this.state.seasonLevelFlag}
                        onClick={this.onSeasonLevelChangesMain}
                        value={this.state.seasonLevelFlag}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {" "}
                        Monthly APT
                      </span>
                    </div>
                  </div>
                  <div className="form-group px-5 mt-2 col-md-2 main">
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center"
                      }}
                      className="form-group"
                    >
                      <Switch
                        disabled={true}
                        checked={this.state.attributeClusturingFlag}
                        onClick={this.onAttributeClusturingFlagMain}
                        value={this.state.attributeClusturingFlag}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {" "}
                        Attribute Clustering
                      </span>
                    </div>
                  </div>
                  <div className="form-group px-5 mt-2 col-md-2 main">
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center"
                      }}
                      className="form-group"
                    >
                      <Switch
                        disabled={this.props.isClusterDisabled}
                        checked={this.state.includeFashionReplenishment}
                        onClick={this.onFashionReplenishmentFlagMain}
                        value={this.state.includeFashionReplenishment}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {" "}
                        Include REPLEN
                      </span>
                    </div>
                  </div>
                  <div className="form-group px-5 mt-2 col-md-2 main">
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center"
                      }}
                      className="form-group"
                    >
                      <Switch
                        disabled={this.props.isClusterDisabled}
                        checked={this.state.includeEcom}
                        onClick={this.onEcommFlagChangeMain}
                        value={this.state.includeEcom}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {" "}
                        Exclude ECOMM
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}

          <Alert
            open={this.state.isEcommAlertVisible}
            title="Alert"
            isResetRequired={false}
            content="Are you sure you want to exclude ECOMM?"
            handleAccept={this.handleAccept}
            actionButtonText="Yes"
            cancelButtonText="No"
            handleClose={() => {
              this.setState({
                isEcommAlertVisible: false
              });
            }}
          />
          <Alert
            open={this.state.isLostSalesAlertVisible}
            title="Alert"
            isResetRequired={false}
            content="Are you sure you want to exclude LOST SALES?"
            handleAccept={this.handleAcceptLostSales}
            actionButtonText="Yes"
            cancelButtonText="No"
            handleClose={() => {
              this.setState({
                isLostSalesAlertVisible: false
              });
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    fobList: store.filtersReducer.fobList,
    dmmList: store.filtersReducer.dmmList,
    buyerListBuyRollup: store.filtersReducer.buyerListBuyRollup,
    dcList: store.filtersReducer.dcList,
    departmentsListAccDc: store.filtersReducer.departmentsListAccDc,
    departmentsList: store.filtersReducer.departmentsList,
    classesList: store.filtersReducer.classesList,
    classesListTarget: store.filtersReducer.classesListTarget,
    vendorsList: store.filtersReducer.vendorsList,
    vendorsListTarget: store.filtersReducer.vendorsListTarget,
    seasonList: store.filtersReducer.seasonList,
    buyerList: store.filtersReducer.buyerList,
    clusteringInputFields: store.clusteringReducer.clusteringInputFields,
    projectNamesList: store.userReducer.projectNamesList,
    departmentsListAccToBuyer: store.filtersReducer.departmentsListAccToBuyer,
    lyFlag: store.projectReducer.lyFlag
  };
};

const mapDispatchToProps = {
  getFobData: FilterActions.getFobData,
  getDmmData: FilterActions.getDmmData,
  getBuyerDataBuyRollup: FilterActions.getBuyerDataBuyRollup,
  getDcData: FilterActions.getDcData,
  getDepartmentsAccToDCData: FilterActions.getDepartmentsAccToDCData,
  getDepartmentsData: FilterActions.getDepartmentsData,
  getClassesData: FilterActions.getClassesData,
  getClassesDataTarget: FilterActions.getClassesDataTarget,
  getVendorsData: FilterActions.getVendorsData,
  getVendorsDataTarget: FilterActions.getVendorsDataTarget,
  getSeasonData: FilterActions.getSeasonData,
  getDepartmentsAccToBuyerData: FilterActions.getDepartmentsAccToBuyerData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Step1AllocationBasics));

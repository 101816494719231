import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

export default function ResponsiveDialog(props) {
  return (
    <div>
      <Dialog {...props} aria-labelledby="responsive-dialog-title">
        {props.title && (
          <DialogTitle id="responsive-dialog-title">
            {props.title}
            {props.onClose && (
              <IconButton
                aria-label="close"
                onClick={props.onClose}
                className="pull-right"
              >
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>
        )}
        <DialogContent>{props.children}</DialogContent>
      </Dialog>
    </div>
  );
}

import Button from "@material-ui/core/Button";
import Tippy from "@tippy.js/react";
import { remove } from "lodash";
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import ClusteringActions from "../../../actions/ClusteringActions";
import { snakeToTitle } from "../../../helpers";
import BarChart from "../../../services/ChartJS/BarChart";
import DataTable from "../../../services/dataTable";
import Select from "../../../services/selectAll";
import volumeGroupCalculations from "./../Step3VolumeGroups/volumeGroupCalculations";
import volumeGroupCalculations1 from "./../Step3VolumeGroups/volumeGroupCalculations1";
import VolumeGroupData from "./clusterColumnDefs";
import AttributeColDef from "./columnDefs";

class Step2AttributeBand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAttrCluster: {},
      selectedAttrTypeForChart: {},
      attrClusterOptions: [],
      attrTypeChartOptions: [],
      selectedAttrClusterNum: {
        value: props.optimalAttributeClusters,
        label: props.optimalAttributeClusters
      },
      options: {
        tooltips: {
          callbacks: {
            label: function(val, val1) {
              return `${val.yLabel.toFixed(2)} %`;
            }
          }
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Attribute Clusters"
              },
              stacked: true,
              barPercentage: 0.2
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "% Receipt units"
              },
              stacked: true,

              ticks: {
                max: 100
              }
            }
          ]
        }
      },
      chartData: {},
      selectedArributes: [],
      selectedPrimaryArributes: [],
      pinnedBottomRow: []
    };
  }

  componentDidMount() {
    !this.props.attributesGradeChart &&
      this.props.getAttributesSelection({
        clusterMasterId: this.props.clusterMasterId
      });
    if (this.props.clusterResultsStoreLevel.length) this.calculateAggregate();
    const {
      attributesGradeChart,
      optimalAttributeClusters,
      attributeStoreMapping
    } = this.props;
    if (attributesGradeChart && optimalAttributeClusters) {
      this.setAttValue(attributesGradeChart, optimalAttributeClusters);
    }
    this.props.setAttributeSelectedClusters(optimalAttributeClusters);
    let attrClusterOptions = this.setAttrClusterOptions(attributeStoreMapping);
    this.setState({
      attrClusterOptions
    });
  }

  onAttributeRowSelected = params => {
    this.setState({
      selectedArributes: params.api.getSelectedRows().map(e => e.Attribute),
      selectedPrimaryArributes: []
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      // if (
      //   this.props.optimalAttributeClusters !==
      //   prevProps.optimalAttributeClusters
      // ) {
      //   const { attributesGradeChart, optimalAttributeClusters } = this.props;
      //   this.setAttValue(attributesGradeChart, optimalAttributeClusters);
      // }
      if (
        prevProps.attributeStoreMapping !== this.props.attributeStoreMapping
      ) {
        let attrClusterOptions = this.setAttrClusterOptions(
          this.props.attributeStoreMapping
        );
        this.setState({
          attrClusterOptions
        });
      }
      if (this.props.clusterResultsStoreLevel.length) this.calculateAggregate();
    }
  }

  setAttrClusterOptions = attributeStoreMapping => {
    if (attributeStoreMapping) {
      let num = Object.keys(attributeStoreMapping);
      let attOptions = [];
      for (let i = 0; i < num.length; i++) {
        attOptions.push({ label: num[i], value: num[i] });
      }
      return attOptions;
    }
  };

  setAttValue = (attributesGradeChart, optimalAttributeClusters) => {
    if (attributesGradeChart) {
      const attrTypeChartOptions = Object.keys(
        attributesGradeChart[optimalAttributeClusters]
      ).map(e => ({
        label: e.includes("_") ? e.split("_").join(" ") : e,
        value: e
      }));
      this.setState(
        {
          attrTypeChartOptions,
          selectedAttrTypeForChart: attrTypeChartOptions[0]
        },
        () => {
          this.convertAttrChartData(
            attributesGradeChart,
            attrTypeChartOptions[0].value,
            optimalAttributeClusters
          );
        }
      );
    }
  };

  calculateAggregate = () => {
    let obj = {
      choice: null,
      weeks_active: null,
      sales_u: null,
      tot_sls_qty: null,
      sales_rtl: null,
      gm: null,
      gm_asst: null,
      oh_tot: null,
      rcpt_u: null,
      rcpt_qty_st: null,
      bop_u: null,
      eop_u: null,
      u_turn_denom: null,
      sales_rtl_lost_sales: null,
      u_rec_depth: null,
      clusters: null,
      loc_count: null,
      // weeks_active: null,
      rcpts_per_door_per_cc: null,
      u_turn: null,
      sls_u_per_door: null,
      rtl_per_door: null,
      gm_per_door: null,
      gm_rate: null,
      gm_asst_per_door: null,
      gm_asst_rate: null,
      aur: null,
      aps: null,
      aps_gm: null,
      aps_gm_asst: null,
      sell_through: null,
      loc_id: null
    };
    let storeData = this.props.clusterResultsStoreLevel;
    let originalStoreData = this.props.clusterResultsStoreLevel.filter(e => {
      return !this.props.clusterMapping[this.props.userSelectedClusters][
        this.props.unassignedStoresIndex
      ].includes(`${+e.loc_id}`);
    });
    obj.loc_count = storeData.length - 1;
    storeData.forEach((row, ind) => {
      if (row.clusters !== 0) {
        volumeGroupCalculations(true, obj, row, ind);
      }
    });
    let storeLength = originalStoreData && originalStoreData.length - 1;
    obj.clusters = `Total CL's  ${this.props.optimalAttributeClusters} (${storeLength}) `;
    this.setState({
      pinnedBottomRow: [{ ...volumeGroupCalculations1(obj) }]
    });
  };

  convertAttrChartData(
    attributesGradeChart,
    selectedAttrTypeForChart,
    selectedAttrClusterNum
  ) {
    const slectedAttrChartData =
      attributesGradeChart[selectedAttrClusterNum][selectedAttrTypeForChart];
    const chartData = {
      datasets: []
    };
    const chartColors = [
      "#646BC1",
      "#56CCB1",
      "#DDC76C",
      "#DFE3EB",
      "#F2797A",
      "#D66BDE",
      "#6CDEF8",
      "#71CB79"
    ];
    let count = 0;
    chartData["labels"] = Object.keys(Object.values(slectedAttrChartData)[0]);
    for (const key in slectedAttrChartData) {
      if (slectedAttrChartData.hasOwnProperty(key)) {
        const attrClusterData = slectedAttrChartData[key];
        for (const key in attrClusterData) {
          if (attrClusterData.hasOwnProperty(key)) {
            if (attrClusterData[key] <= 1)
              attrClusterData[key] = attrClusterData[key] * 100;
          }
        }
        const dataset = {
          label: key.includes("_") ? key.split("_").join(" ") : key,
          data: Object.values(attrClusterData),
          backgroundColor: chartColors[count]
        };
        chartData.datasets.push(dataset);
        count++;
      }
    }
    this.setState({
      chartData
    });
  }

  onChangeAttrCluster = val => {
    this.setState(
      {
        selectedAttrTypeForChart: val
      },
      () => {
        const { attributesGradeChart, optimalAttributeClusters } = this.props;
        const { selectedAttrTypeForChart } = this.state;
        this.convertAttrChartData(
          attributesGradeChart,
          selectedAttrTypeForChart.value,
          optimalAttributeClusters
        );
      }
    );
  };

  onChangeAttrClusterNum = val => {
    this.props.setAttributeSelectedClusters(val.value);
    this.setState({
      selectedAttrClusterNum: val
    });
    this.props
      .getStoreClusterData({
        clusterMasterId: this.props.clusterMasterId,
        selected_cluster_id: val.value,
        isClusterChangeForAttr: true
      })
      .then(resp => {
        const {
          attributesGradeChart,
          userSelectedClusters,
          getStoreClusterData
        } = this.props;
        const { selectedAttrTypeForChart, selectedAttrClusterNum } = this.state;
        this.convertAttrChartData(
          attributesGradeChart,
          selectedAttrTypeForChart.value,
          selectedAttrClusterNum.value
        );
        const maxVolumeClusterAllowed = this.props.getMaxVolumeClusterAllowed(
          val.value
        );
        if (userSelectedClusters > maxVolumeClusterAllowed) {
          getStoreClusterData({
            clusterMasterId: this.props.clusterMasterId,
            selected_cluster_id: maxVolumeClusterAllowed
          });
          this.props.changeUserSelectedClusters(maxVolumeClusterAllowed);
        }
      });
  };

  onAddPrimaryAttr = (e, val) => {
    let selAttr = this.state.selectedPrimaryArributes;
    let foundAttr = selAttr.includes(val);
    if (foundAttr) {
      remove(selAttr, item => item === val);
    } else {
      selAttr.push(val);
    }
    this.setState({
      selectedPrimaryArributes: selAttr
    });
  };

  runAttributeClustering = () => {
    let selectedRows = this.gridApi.getSelectedRows();
    let attrNames = selectedRows.map(el => el.Attribute);
    selectedRows &&
      selectedRows.length > 0 &&
      selectedRows.length <= 5 &&
      this.props
        .runAttributeClustering({
          clusterMasterId: this.props.clusterMasterId,
          selectedAttributeNames: attrNames,
          selectedPrimaryAttributes: this.state.selectedPrimaryArributes
        })
        .then(resp => {
          const { attributesGradeChart, optimalAttributeClusters } = resp.data;
          const { userSelectedClusters, getStoreClusterData } = this.props;
          if (resp.success) {
            this.setAttValue(attributesGradeChart, optimalAttributeClusters);
            const maxVolumeClusterAllowed = this.props.getMaxVolumeClusterAllowed(
              optimalAttributeClusters
            );
            if (userSelectedClusters > maxVolumeClusterAllowed) {
              getStoreClusterData({
                clusterMasterId: this.props.clusterMasterId,
                selected_cluster_id: maxVolumeClusterAllowed
              });
              this.props.changeUserSelectedClusters(maxVolumeClusterAllowed);
            }
          }
        });
  };
  render() {
    return (
      <div className="card">
        <div className="card-body">
          {!this.props.optimalAttributeClusters ? (
            <div
              className="card attr-sel-container"
              style={{ margin: "1.5rem", padding: "2rem 0" }}
            >
              <div className="attr-sel-table">
                <div
                  className="card-header-background"
                  style={{ width: "70%", margin: "auto", padding: "0.75rem" }}
                >
                  <strong>Select Product Attribute</strong>
                  <span>
                    <Tippy
                      placement={"left"}
                      arrow={true}
                      distance={10}
                      content={
                        <div>
                          Minimum 2 attributes and Maximum 5 attributes should
                          be selected to run Attribute Based Clustering
                          Algorithm
                        </div>
                      }
                    >
                      <i
                        style={{
                          color: "#fff"
                        }}
                        className="fa fa-info-circle"
                        aria-hidden="true"
                      />
                    </Tippy>
                  </span>
                </div>
                <div
                  style={{ height: "220px", width: "45%", margin: "2rem auto" }}
                >
                  <DataTable
                    rowData={this.props.attributeSelectionList}
                    columnDefs={AttributeColDef.columnDefs}
                    defaultColDef={AttributeColDef.defaultColDef}
                    sizeToFit={true}
                    onRowSelected={this.onAttributeRowSelected}
                    rowSelection="multiple"
                    passGridAPI={api => {
                      this.gridApi = api;
                    }}
                  />
                </div>

                <div
                  className="card-header-background"
                  style={{ width: "70%", margin: "auto", padding: "0.75rem" }}
                >
                  <strong>Select Primary Attributes</strong>
                  <span>
                    <Tippy
                      placement={"left"}
                      arrow={true}
                      distance={10}
                      content={
                        <div>
                          Minimum 1 attributes and Maximum 2 attributes should
                          be selected to run Attribute Based Clustering
                          Algorithm
                        </div>
                      }
                    >
                      <i
                        style={{
                          color: "#fff"
                        }}
                        className="fa fa-info-circle"
                        aria-hidden="true"
                      />
                    </Tippy>
                  </span>
                </div>
                <div
                  style={{ height: "50px", width: "45%", margin: "1rem auto" }}
                >
                  <Form>
                    <div key={`inline-checkbox`} className="mb-3">
                      {this.state.selectedArributes.map((attr, idx) => (
                        <Form.Check
                          inline
                          onChange={e => this.onAddPrimaryAttr(e, attr)}
                          label={snakeToTitle(attr)}
                          type="checkbox"
                          id={`inline-checkbox-${idx}`}
                          checked={this.state.selectedPrimaryArributes.includes(
                            attr
                          )}
                        />
                      ))}
                    </div>
                  </Form>
                </div>
              </div>
              <div className="attr-sel-btn mt-2" style={{ margin: "0 auto" }}>
                <Button
                  disabled={
                    this.state.selectedArributes.length >= 2 &&
                    this.state.selectedArributes.length <= 5 &&
                    this.state.selectedPrimaryArributes.length >= 1 &&
                    this.state.selectedPrimaryArributes.length <= 2
                      ? false
                      : true
                  }
                  size="medium"
                  style={{ textTransform: "capitalize" }}
                  onClick={this.runAttributeClustering}
                >
                  Run Attribute Clustering
                </Button>
              </div>
            </div>
          ) : (
            ""
          )}
          {this.props.attributesGradeChart && (
            <>
              <div className="card p-3 mt-3">
                <div className="card-header-background">
                  <strong>Attribute Grades</strong>
                </div>
                <div className="mt-3">
                  <label htmlFor="inputPassword4" style={{ margin: "0.5rem" }}>
                    Select the number of attribute cluster
                  </label>
                  <Select
                    className="attribute-clusturing-select"
                    isDisabled={this.props.isClusterDisabled}
                    value={{
                      label: this.props.optimalAttributeClusters,
                      value: this.props.optimalAttributeClusters
                    }}
                    options={this.state.attrClusterOptions}
                    onChange={val => this.onChangeAttrClusterNum(val)}
                  />
                </div>

                <div
                  className="mt-3"
                  style={{
                    width: "60%",
                    margin: "0 auto",
                    border: "1px solid #ececec",
                    borderRadius: "8px"
                  }}
                >
                  <div
                    className="mt-2"
                    style={{
                      margin: "auto",
                      width: "90%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end"
                    }}
                  >
                    <label
                      htmlFor="inputPassword4"
                      style={{ margin: "0.5rem" }}
                    >
                      Attribute
                    </label>
                    <Select
                      className="attribute-clusturing-select"
                      value={this.state.selectedAttrTypeForChart}
                      options={this.state.attrTypeChartOptions}
                      onChange={val => this.onChangeAttrCluster(val)}
                    />
                  </div>
                  <div
                    style={{
                      width: "90%",
                      margin: "2rem auto",
                      minHeight: "4rem"
                    }}
                  >
                    <BarChart
                      type="bar"
                      data={this.state.chartData}
                      options={this.state.options}
                    />
                  </div>
                </div>

                <div
                  className="card-header-background mt-3"
                  style={{
                    padding: "0.75rem"
                  }}
                >
                  <strong>Attribute Groups</strong>
                </div>
                <div
                  style={{
                    height: "335px"
                  }}
                >
                  <DataTable
                    groupColDef={{
                      headerName: "Group",
                      width: 170,
                      sort: "asc",
                      field: "clusters"
                    }}
                    floatingFilter={true}
                    rowData={this.props.clusterResultsStoreLevel.filter(e => {
                      return !this.props.clusterMapping[
                        this.props.userSelectedClusters
                      ][this.props.unassignedStoresIndex].includes(
                        `${+e.loc_id}`
                      );
                    })}
                    groupRowAggNodes={VolumeGroupData.groupRowAggNodes}
                    columnDefs={VolumeGroupData.columnDefs}
                    defaultColDef={VolumeGroupData.defaultColDef}
                    suppressColumnVirtualisation={true}
                    pinnedBottomRowData={this.state.pinnedBottomRow}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    attributesGradeChart: store.clusteringReducer.attributesGradeChart,
    attributeStoreMapping: store.clusteringReducer.attributeStoreMapping,
    attributeSelectionList: store.clusteringReducer.attributeSelectionList,
    optimalAttributeClusters: store.clusteringReducer.optimalAttributeClusters,
    projectId: store.projectReducer.projectId,
    clusterResultsStoreLevel: store.clusteringReducer.clusterResultsStoreLevel,
    clusterMasterId: store.projectReducer.clusterMasterId,
    attributeSelectedClusters: store.clusteringReducer.attributeSelectedClusters
  };
};
const mapDispatchToProps = {
  getAttributesSelection: ClusteringActions.getAttributesSelection,
  setAttributeSelectedClusters: ClusteringActions.setAttributeSelectedClusters,
  runAttributeClustering: ClusteringActions.runAttributeClustering,
  getStoreClusterData: ClusteringActions.getStoreClusterData,
  changeUserSelectedClusters: ClusteringActions.changeUserSelectedClusters
};
export default connect(mapStateToProps, mapDispatchToProps)(Step2AttributeBand);

import React, { Component } from "react";
import { Line } from "react-chartjs-2";

class DeviationChart extends Component {
  state = {};

  data = {
    labels: this.props.data.map(e => e.k),
    datasets: [
      {
        backgroundColor: "#173f5f",
        borderColor: "#173f5f",
        fill: false,
        label: "Deviation from mean",
        data: this.props.data.map(e => e.dist)
      }
    ]
  };

  options = {
    maintainAspectRaio: false,
    title: {
      display: true
      //   text: 'Chart.js Line Chart'
    },

    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          return tooltipItem.yLabel.toFixed(2) + " %";
        }
      }
    },

    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: "Number of Clusters"
          }
        }
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: "% Avg Deviation"
          }
        }
      ]
    }
  };
  render() {
    return (
      <div>
        <Line
          data={this.data}
          options={this.options}
          width={400}
          height={300}
        />
      </div>
    );
  }
}

export default DeviationChart;

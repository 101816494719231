import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import AssortmentPlanningActions from "../../../actions/AssortmentPlanningActions";
import {
  decimalFormatterwithRound$,
  toDollar,
  toPercentage,
  toUnit
} from "../../../helpers/formatters";
import DataTable from "../../../services/dataTable";
import { inputCss, tar } from "../../../styles";

class ChoiceCountTable extends Component {
  getColDefForChoice = () => {
    let arr = [];
    arr.push({
      headerName: "Total",
      sortable: true,
      menuTabs: [],
      unSortIcon: true,
      field: "Financial_year",
      rowGroup: true,
      valueFormatter: val => {
        return "Total";
      },
      hide: true
    });
    arr.push({
      headerName: "Class/Vendor",
      menuTabs: [],
      rowGroup: true,
      hide: true,
      sortable: true,
      width: 250,
      unSortIcon: true,
      field: "class/vendor",
      pinned: "left"
    });
    arr.push({
      headerName: "SUBCLASS/GROUPING",
      field: "subclass",
      filter: "agTextColumnFilter",
      pinned: "left",
      menuTabs: [],
      width: 250,
      hide: true,
      unSortIcon: true,
      sortable: true
    });
    arr.push({
      headerName: "RCPT PEN%",
      field: "rcpt_pen%",
      cellStyle: tar,
      width: 83,
      menuTabs: [],
      valueFormatter: toPercentage,
      unSortIcon: true,
      sortable: true
    });
    arr.push({
      headerName: "AUR",
      field: "aur",
      cellStyle: tar,
      menuTabs: [],
      width: 72,
      // valueFormatter: toDollar,
      valueFormatter: decimalFormatterwithRound$,
      sortable: true,
      unSortIcon: true
    });
    if (this.props.clusterData.length && this.props.clusters.includes("0")) {
      this.props.clusters.forEach(cluster => {
        if (cluster !== "0") {
          arr.push({
            headerName: `CL ${cluster}`,
            children: [
              {
                headerName: `TY`,
                editable: !this.props.userAccessDisableFlag && true,
                menuTabs: [],
                width: 60,
                sortable: true,
                unSortIcon: true,
                valueFormatter: toUnit,
                field: `choiceCluster${cluster}`,
                cellStyle: params => {
                  if (params.node.group) {
                    return {
                      "background-color": "white",
                      "text-align": "right"
                    };
                  } else {
                    return inputCss;
                  }
                }
              },
              {
                headerName: this.props.lyFlag ? `LY` : `LLY`,
                cellStyle: tar,
                width: this.props.lyFlag ? 60 : 70,
                menuTabs: [],
                sortable: true,
                unSortIcon: true,
                valueFormatter: toUnit,
                field: `lyChoiceCluster${cluster}`
              }
            ]
          });
        }
      });
      arr.push(
        {
          headerName: "ECOM",
          children: [
            {
              headerName: "TY",
              valueFormatter: toUnit,
              field: "choiceCluster0",
              menuTabs: [],
              sortable: true,
              width: 60,
              unSortIcon: true,
              cellStyle: params => {
                if (params.node.group) {
                  return { "background-color": "white", "text-align": "right" };
                } else {
                  return inputCss;
                }
              },
              editable: !this.props.userAccessDisableFlag && true
            }
          ]
        },
        {
          headerName: this.props.lyFlag ? `LY` : `LLY`,
          menuTabs: [],
          valueFormatter: toUnit,
          field: "lyChoiceCluster0",
          sortable: true,
          width: this.props.lyFlag ? 60 : 70,
          unSortIcon: true
        }
      );
    } else {
      this.props.clusters.forEach(cluster => {
        arr.push({
          headerName: `CL ${cluster}`,
          children: [
            {
              headerName: `TY`,
              menuTabs: [],
              editable: !this.props.userAccessDisableFlag && true,
              width: 60,
              sortable: true,
              unSortIcon: true,
              valueFormatter: toUnit,
              field: `choiceCluster${cluster}`,
              cellStyle: params => {
                if (params.node.group) {
                  return {
                    "background-color": "white",
                    "text-align": "right"
                  };
                } else {
                  return inputCss;
                }
              }
            },
            {
              headerName: this.props.lyFlag ? `LY` : `LLY`,
              cellStyle: tar,
              width: this.props.lyFlag ? 60 : 70,
              sortable: true,
              unSortIcon: true,
              menuTabs: [],
              valueFormatter: toUnit,
              field: `lyChoiceCluster${cluster}`
            }
          ]
        });
      });
    }
    arr.push({
      headerName: "ACT RCPT $",
      menuTabs: [],
      field: "ACT RCPT $",
      width: 86,
      cellStyle: tar,
      unSortIcon: true,
      valueFormatter: toDollar,
      sortable: true
    });
    arr.push({
      headerName: "VAR TO PLAN",
      cellStyle: tar,
      menuTabs: [],
      width: 91,
      field: "VAR TO PLAN",
      valueFormatter: toDollar,
      unSortIcon: true,
      sortable: true
    });
    arr.push({
      headerName: "PLAN CC",
      cellStyle: tar,
      menuTabs: [],
      width: 77,
      field: "PLAN CC",
      unSortIcon: true,
      sortable: true
    });
    arr.push({
      headerName: "MAX CC",
      cellStyle: tar,
      menuTabs: [],
      width: 72,
      field: "CC",
      valueFormatter: toUnit,
      unSortIcon: true,
      sortable: true
    });
    let tempObj = {
      groupRowAggNodes: nodes => {
        var obj = {
          CC: null,
          "PLAN CC": null,
          "VAR TO PLAN": null,
          "ACT RCPT $": null,
          aur: null,
          "rcpt_pen%": null
        };
        if (this.props.historicalParameterData.length) {
          this.props.clusters.forEach(cluster => {
            obj[`choiceCluster${cluster}`] = null;
            obj[`lyChoiceCluster${cluster}`] = null;
          });
        }
        let context = this;
        obj["subClasslevelCount"] = nodes.length;
        let count = 0;
        nodes.forEach(function(node, index) {
          var data = node.group ? node.aggData : node.data;
          if (typeof Number(data["rcpt_pen%"]) === "number") {
            obj["rcpt_pen%"] += Number(data["rcpt_pen%"]);
          }
          if (typeof Number(data["aur"]) === "number") {
            obj["aur"] += Number(data["aur"]) * Number(data["rcpt_pen%"]);
            count = index;
          }
          if (context.props.historicalParameterData) {
            context.props.clusters.forEach(cluster => {
              if (typeof Number(data[`choiceCluster${cluster}`]) === "number") {
                obj[`choiceCluster${cluster}`] += Number(
                  data[`choiceCluster${cluster}`]
                );
              }
              if (
                typeof Number(data[`lyChoiceCluster${cluster}`]) === "number"
              ) {
                obj[`lyChoiceCluster${cluster}`] += Number(
                  data[`lyChoiceCluster${cluster}`]
                );
              }
            });
          }
          if (typeof Number(data["ACT RCPT $"]) === "number") {
            obj["ACT RCPT $"] += Number(data["ACT RCPT $"]);
          }
          if (typeof Number(data["VAR TO PLAN"]) === "number") {
            obj["VAR TO PLAN"] += Number(data["VAR TO PLAN"]);
          }
          if (typeof Number(data["PLAN CC"]) === "number") {
            obj["PLAN CC"] += Number(data["PLAN CC"]);
          }
          if (typeof Number(data["CC"]) === "number") {
            obj["CC"] += Number(data["CC"]);
          }
        });
        if (count + 1 === obj["subClasslevelCount"]) {
          obj["aur"] = obj["aur"] / obj["rcpt_pen%"];
        }
        return obj;
      },
      columnDefs: arr
    };
    return tempObj;
  };
  onCellValueChangedChoiceCount = val => {
    // let historicalParameterData = cloneDeep(this.props.historicalParameterData);
    const classSubclassKey = `${val.data["class/vendor"]}-${val.data["subclass"]}`;
    let historicalParameterObj = this.props.historicalParameterObject[
      classSubclassKey
    ];

    historicalParameterObj[`${val.colDef.field}`] = Number(val.newValue);
    /*
      Check for MATCH ECOM CC TO CL 1 ? Y : N
      if "Y" 
      choiceCount of Ecom = choiceCount of Cluster1
      depthCount of Ecom = (subClassRcpt% * subClassCluster% * buyerTotalBudget) / (storeCount * AUR * cc)
    */
    if (
      (val.colDef.field.slice(13) === "1" || val.colDef.field.slice(13) === this.props.clusters[0]) &&
      historicalParameterObj[`ecom`] === "Y"
    ) {
      historicalParameterObj[`choiceCluster0`] = Number(val.newValue);
      historicalParameterObj["depthCluster0"] = !isFinite(
        Math.round(
          ((this.props.subClassRcptPenObject[classSubclassKey][`TY0`] / 100) *
            (this.props.subClassRcptPenObject[classSubclassKey]["rcpt_pen%"] /
              100) *
            this.props.buyerTotalBudget) /
            (this.props.clusterData[0][0] *
              historicalParameterObj["aur"] *
              historicalParameterObj[`choiceCluster0`])
        )
      )
        ? 0
        : Math.round(
            ((this.props.subClassRcptPenObject[classSubclassKey][`TY0`] / 100) *
              (this.props.subClassRcptPenObject[classSubclassKey]["rcpt_pen%"] /
                100) *
              this.props.buyerTotalBudget) /
              (this.props.clusterData[0][0] *
                historicalParameterObj["aur"] *
                historicalParameterObj[`choiceCluster0`])
          );
    }
    let tempArr = [];
    let ACT_RCPT$ = 0;
    this.props.clusters.forEach(e => {
      this.props.clusterData.forEach(clusterObj => {
        if (clusterObj.keys === "# OF DOORS") {
          ACT_RCPT$ += isNaN(
            val.data[`choiceCluster${e}`] *
              val.data[`depthCluster${e}`] *
              clusterObj[e]
          )
            ? 0
            : val.data[`choiceCluster${e}`] *
              val.data[`depthCluster${e}`] *
              clusterObj[e];
        }
      });
      tempArr.push(
        isNaN(val.data[`choiceCluster${e}`])
          ? 0
          : Number(val.data[`choiceCluster${e}`])
      );
    });
    // if (!isFinite(historicalParameterObj[`$depthCluster${val.colDef.field.slice(13)}`])) {
    //   historicalParameterObj[`$depthCluster${val.colDef.field.slice(13)}`] = 0;
    // }
    ACT_RCPT$ = ACT_RCPT$ * val.data["aur"];
    let vartoPlan = ACT_RCPT$ - val.data["plan_rcpt$"];
    let planCC = val.data["ty_cc"];
    let CC = Math.max(...tempArr);
    historicalParameterObj["ACT RCPT $"] = ACT_RCPT$;
    historicalParameterObj["VAR TO PLAN"] = vartoPlan;
    historicalParameterObj["PLAN CC"] = planCC;
    historicalParameterObj["CC"] = CC;
    this.props.setChoiceCountOnCellValueChanged(
      this.props.month,
      classSubclassKey,
      historicalParameterObj
    );
    // this.props.recalculateStep5OnCellValueChanged(
    //   this.props.month,
    //   classSubclassKey
    // );
    val.api.refreshCells();
    val.api.refreshClientSideRowModel("aggregate");
  };
  render() {
    return (
      <Fragment>
        <div
          className="choice-count-table"
          style={{ height: "350px", width: "100%" }}
        >
          <DataTable
            defaultColDef={{
              resizable: true
            }}
            groupColDef={{
              filter: "agTextColumnFilter",
              headerName: "SUB GRP",
              width: 200,
              menuTabs: [],
              field: "subclass",
              cellRenderer: "agGroupCellRenderer"
            }}
            groupRowAggNodes={this.getColDefForChoice().groupRowAggNodes}
            floatingFilter={true}
            suppressColumnVirtualisation={true}
            onCellValueChanged={this.onCellValueChangedChoiceCount}
            defaultExpand={true}
            columnDefs={
              this.props.historicalParameterData.length
                ? this.getColDefForChoice().columnDefs
                : null
            }
            rowData={this.props.historicalParameterData}
            legends={[{ color: "#fb7e4f", text: "Important" }]}
          />
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = store => {
  return {
    lyFlag: store.projectReducer.lyFlag
  };
};
const mapActionsToProps = {
  setChoiceCountOnCellValueChanged:
    AssortmentPlanningActions.setChoiceCountOnCellValueChanged,
  recalculateStep5OnCellValueChanged:
    AssortmentPlanningActions.recalculateStep5OnCellValueChanged
};
export default connect(mapStateToProps, mapActionsToProps)(ChoiceCountTable);

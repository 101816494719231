import React, { Component } from "react";
import "./Login.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import userActions from "../../actions/userActions";
import LoginForm from "../LoginForm";
import ForgotPassword from "../ForgotPassword";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: null,
      isForgotPasswordFormVisible: false
    };
    // this.onSubmit = this.onSubmit.bind(this);
  }
  forgotPasswordFormToggler = isForgotPasswordFormVisible => {
    this.setState({ isForgotPasswordFormVisible });
  };
  componentDidUpdate(oldProps, oldState) {
    if (localStorage.getItem("token")) {
      this.props.history.push("/dashboard");
    }
  }
  onSubmit = () => {
    this.props.login({
      email: this.state.email,
      password: this.state.password
    });
  };
  onChangeEmail = e => {
    this.setState({
      email: e.target.value
    });
  };
  onChangePassword = e => {
    this.setState({
      password: e.target.value
    });
  };
  render() {
    console.log(this.state.isForgotPasswordFormVisible);

    return (
      <div style={{ height: "100vh", display: "flex" }}>
        <div className="col-md-4 leftBgImg">
          <img
            id="logoImgLeft"
            src="./img/Logo - IA.png"
            alt="Logo of the company"
          />
          <div className="divleftContent">
            <div className="text-white d-md-flex">
              <div className="logoareaLeftText " style={{ width: "100%" }}>
                <h6 className="assortmentTextLeft" style={{ color: "white" }}>
                  Assortment Suite{" "}
                </h6>
              </div>
            </div>
            <div className="assortmentDiv">
              <p className="assortmentDescription">Enterprise Edition</p>
            </div>
            {/* <button
              type="button"
              className="btn btn-primary btn-square shadow-sm btncontact"
            >
              CONTACT US
            </button> */}
          </div>
          <div className="footer-copyright text-center text-white py-3">
            copyright@impactanalytics2019
          </div>
        </div>
        <div id="divRight" className="col-md-8 h-md-100 loginarea">
          {/* <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col-md-3 offset-1">
              <img src="/img/Belk.png" alt="Logo of the company" />
            </div>
            <div className="col-md-7">
              <div className="row" style={{ float: "right" }}>
                <a className="text-account" href="/">
                  Don't Have An Account?
                </a>
                <div className="col-md-4">
                  <button className="btn btn-primary btn-square shadow-sm getStartedbtn">
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div> */}
          {this.state.isForgotPasswordFormVisible ? (
            <ForgotPassword
              forgotPasswordFormToggler={this.forgotPasswordFormToggler}
              forgotPassword={this.props.forgotPassword}
            />
          ) : (
            <LoginForm
              login={this.props.login}
              forgotPasswordFormToggler={this.forgotPasswordFormToggler}
            />
          )}
        </div>
      </div>
    );
  }
}
const mapActionsToProps = {
  login: userActions.login,
  forgotPassword: userActions.forgotPassword
};

const mapStateToProps = store => {
  return {
    userData: store.userReducer.userData,
    status: store.userReducer.status
  };
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Login));

import { toPercentage } from "../../../helpers/formatters";

export default function(clusters,hasAttributeClustering) {
  return nodes => {
    var result = {
      rcpt_aur: 0,
      proportion: 0,
      total$: 0,
      plannedUnits: 0,
      total_u: 0,
      "DR COUNT": null,
      "AVG DEPTH": 0,
      "ST%": 0,
      "WEEKS REG": 0,
      "% var to Plan": 0,
      APS: 0
    };
    clusters.forEach(cluster => {
      result[cluster] = 0;
    });
    nodes.forEach(node => {
      var data = node.group ? node.aggData : node.data;
      // console.log(data);
      result.rcpt_aur += +data.rcpt_aur;
      result.proportion += +data.proportion;
      result.total$ += +data.total$;
      result.total_u += +data.total_u;
      result.plannedUnits += +data.plannedUnits;
      if (data["DR COUNT"] > result["DR COUNT"])
        result["DR COUNT"] = +data["DR COUNT"];
      result["AVG DEPTH"] += +data["AVG DEPTH"];
      result["APS"] += +data["APS"];
      result["WEEKS REG"] += +data["WEEKS REG"];
      result["ST%"] += +data["ST%"];
      clusters.forEach(cluster => {
        result[cluster] += data[cluster];
        result[`plannedDepth${cluster}`] = data[`plannedDepth${cluster}`];
      });
    });
    !hasAttributeClustering && clusters.forEach(cluster => {
      const totalDepth = result[cluster];
      const plannedDepth = result[`plannedDepth${cluster}`];
      const variance =
        plannedDepth && ((totalDepth / plannedDepth - 1) * 100).toFixed(2);
      result[cluster] = `${totalDepth} (${toPercentage({ value: variance })})`;
    });
    result.rcpt_aur = result.rcpt_aur / nodes.length;
    result["WEEKS REG"] = result["WEEKS REG"] / nodes.length;
    result["ST%"] = result["ST%"] / nodes.length;
    result["% var to Plan"] = (result.total_u / result.plannedUnits - 1) * 100;
    return result;
  };
}

import api from "../axios";
// import { SAVE_ITEM_LEVEL, SIMULATEANDFINALIZE_URL_DOWNLOAD } from "./types";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";

const buyOptimizationActions = {
  runCompareAPSAlgo: reqObj => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text: "This may take some time. Thank you for your patience."
        }
      });
      // console.log(reqObj);
      return api({
        url: "/runCompareAPSAlgo",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          const { boInputData, ...boGraphData } = resp.data;
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_BO_GRAPH_DATA",
            payload: boGraphData,
            status: true,
            message: resp.message
          });
          dispatch({
            type: "SET_OMNI_SALES_PLAN_INCREMENT",
            payload: boInputData.omniIncr
          });
          dispatch({
            type: "SET_OMNI_RCPT_PLAN",
            payload: boInputData.omniRcpt
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  setOmniSalesPlanIncrement: payload => {
    return dispatch => {
      dispatch({
        type: "SET_OMNI_SALES_PLAN_INCREMENT",
        payload: payload
      });
    };
  },
  setOmniRcptPlan: payload => {
    return dispatch => {
      dispatch({
        type: "SET_OMNI_RCPT_PLAN",
        payload: payload
      });
    };
  },
  setConstrained: payload => {
    return dispatch => {
      dispatch({
        type: "SET_CONSTRAINED",
        payload
      });
    };
  },
  assignDataAfterFileUploadBuyInut: data => {
    return dispatch => {
      dispatch({
        type: "SET_BO_INPUT_DATA_STYLE_COLOR_LEVEL",
        payload: data.StyleColorLevel
      });
      dispatch({
        type: "SET_BO_INPUT_DATA_STYLE_LEVEL",
        payload: data.StyleLevel
      });
      if (data.omniIncr) {
        dispatch({
          type: "SET_OMNI_SALES_PLAN_INCREMENT",
          payload: data.omniIncr
        });
      }
      if (data.omniRcpt) {
        dispatch({
          type: "SET_OMNI_RCPT_PLAN",
          payload: data.omniRcpt
        });
      }
    };
  },
  downloadSimulateAndFinalize: projectId => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: `/buyOptimizationExcelDownload/${projectId}`,
        method: "POST"
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SIMULATEANDFINALIZE_URL_DOWNLOAD",
            payload: resp.data,
            status: true
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  downloadAutoAPX: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: `/downloadAutoAPX`,
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_TOTAL_BM_BUY",
            payload: resp.data.total_BM_buy
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },

  runOptimizeBuyAlgo: reqObj => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text: "This may take some time. Thank you for your patience."
        }
      });
      return api({
        url: "/runOptimizeBuyAlgo",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          // toast.success(resp.message)
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });

          dispatch({
            type: "SET_BRICK_AND_MORTAR_DATA",
            payload: resp.data.brickAndMortarSummary
          });
          dispatch({
            type: "SET_PROGRAM_LEVEL_DATA",
            payload: resp.data.programLevelSummary
          });
          dispatch({
            type: "SET_ITEM_LEVEL_DATA",
            payload: resp.data.itemLevelSummary
          });
          dispatch({
            type: "SET_CHANNEL_SUMMARY_DATA",
            payload: resp.data.channelSummary
          });
          dispatch({
            type: "SET_VENDOR_ASSISTANCE_SIMULATION_DATA",
            payload: [],
            status: true,
            message: resp.message
          });

          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  channelSummaryBudgetSimulation: reqObj => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text: "This may take some time. Thank you for your patience."
        }
      });
      api({
        url: "/runBudgetSimulationAlgo",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          // toast.success(resp.message)
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_BUDGET_SIMULATION_DATA",
            payload: resp.data.budgetSimulationData,
            status: true,
            message: resp.message
          });
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  vendorSimulation: reqObj => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text: "This may take some time. Thank you for your patience."
        }
      });
      api({
        url: "/runVendorAssistanceSimulate",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          // toast.success(resp.message)
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_VENDOR_ASSISTANCE_SIMULATION_DATA",
            payload: resp.data.vendorAssistanceSimulate,
            status: true,
            message: resp.message
          });
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  vendorUpdate: reqObj => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text: "This may take some time. Thank you for your patience."
        }
      });
      return api({
        url: "/runVendorAssistanceUpdate",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          // toast.success("Vendor Updated !");
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_BRICK_AND_MORTAR_DATA",
            payload: resp.data.brickAndMortarSummary
          });
          dispatch({
            type: "SET_PROGRAM_LEVEL_DATA",
            payload: resp.data.programLevelSummary
          });
          dispatch({
            type: "SET_ITEM_LEVEL_DATA",
            payload: resp.data.itemLevelSummary
          });
          dispatch({
            type: "SET_CHANNEL_SUMMARY_DATA",
            payload: resp.data.channelSummary
          });
          dispatch({
            type: "SET_NEAREST_INDEX",
            payload: resp.data.nearestIndex
          });
          dispatch({
            type: "SET_VENDOR_ASSISTANCE_GIVE_BACK",
            payload: resp.data.giveBackPerc
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  totalBudgetUpdate: reqObj => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/runUpdateTotalBudget",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          // toast.success(resp.message)
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_BRICK_AND_MORTAR_DATA",
            payload: resp.data.brickAndMortarSummary
          });
          dispatch({
            type: "SET_PROGRAM_LEVEL_DATA",
            payload: resp.data.programLevelSummary
          });
          dispatch({
            type: "SET_ITEM_LEVEL_DATA",
            payload: resp.data.itemLevelSummary
          });
          dispatch({
            type: "SET_CHANNEL_SUMMARY_DATA",
            payload: resp.data.channelSummary
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  eCommPeneUpdate: reqObj => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/runECommPen",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          // toast.success(resp.message)
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_BRICK_AND_MORTAR_DATA",
            payload: resp.data.brickAndMortarSummary
          });
          dispatch({
            type: "SET_PROGRAM_LEVEL_DATA",
            payload: resp.data.programLevelSummary
          });
          dispatch({
            type: "SET_ITEM_LEVEL_DATA",
            payload: resp.data.itemLevelSummary
          });
          dispatch({
            type: "SET_CHANNEL_SUMMARY_DATA",
            payload: resp.data.channelSummary
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  saveItemLevel: reqObj => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text: "This may take some time. Thank you for your patience."
        }
      });
      return api({
        url: "/saveItemLevelSummaryData",
        method: "POST",
        data: reqObj
      })
        .then(resp => {
          // toast.success(resp.message)
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SAVE_ITEM_LEVEL",
            payload: resp,
            status: true,
            message: resp.message
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  setItemLevelDataAction: payload => {
    return dispatch => {
      dispatch({
        type: "SET_ITEM_LEVEL_DATA",
        payload: cloneDeep(payload)
      });
    };
  },
  setOmniBuyEstimation: payload => {
    return dispatch => {
      dispatch({
        type: "SET_OMNI_BUY_ESTIMATION",
        payload: payload
      });
    };
  },
  setVendorForFcBuy: req => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text: "This may take some time. Thank you for your patience."
        }
      });
      return api({
        url: "/saveVendorsForFCBuy",
        method: "POST",
        data: req
      })
        .then(resp => {
          dispatch({
            type: "SET_VENDOR_FOR_FC_BUY",
            payload: resp.data
          });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  runOmniAlgo: req => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text: "This may take some time. Thank you for your patience."
        }
      });
      return api({
        url: "/runOmniAlgo",
        method: "POST",
        data: req
      })
        .then(resp => {
          dispatch({
            type: "SET_OMNI_BUY_ESTIMATION",
            payload: resp.data.data_to_be_saved
          });
          dispatch({
            type: "SET_IS_FC_APPROVED",
            payload: resp.data.is_fc_approved
          });
          dispatch({
            type: "SET_AUC_THRESHOLD",
            payload: resp.data.auc_threshold
          });
          dispatch({
            type: "SET_DEPT_PENETRATION",
            payload: resp.data.dept_penetration
          });
          dispatch({
            type: "SET_SEASON",
            payload: resp.data.season
          });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  saveOmniBuyEstimation: req => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text: "This may take some time. Thank you for your patience."
        }
      });
      return api({
        url: "/saveOmniData",
        method: "POST",
        data: req
      })
        .then(res => {
          dispatch({
            type: "SET_OMNI_BUY_ESTIMATION",
            payload: res.data.data_to_be_saved
          });
          dispatch({
            type: "SET_IS_FC_APPROVED",
            payload: res.data.is_fc_approved
          });
          dispatch({
            type: "SET_AUC_THRESHOLD",
            payload: res.data.auc_threshold
          });
          dispatch({
            type: "SET_DEPT_PENETRATION",
            payload: res.data.dept_penetration
          });
          dispatch({
            type: "SET_SEASON",
            payload: res.data.season
          });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return res;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  }
};

export default buyOptimizationActions;

import { dateFormatter } from "../../helpers/formatters";
function levelFormatter(val) {
  return val.value ? "Season" : "Month";
}
function clusterTypeFormatter(val) {
  return val.value ? "Attribute" : "Volumetric";
}
function otbFormatter(val) {
  return val.value ? "Y" : "N";
}
export default {
  defaultColDef: {
    animateRows: true,
    sortable: true,
    unSortIcon: true,
    resizable: true,
    menuTabs: []
  },
  animateRows: true,
  sortable: true,
  filter: true,
  unSortIcon: true,
  suppressCellSelection: true,
  cellClass: "no-border",
  columnDefs: [
    {
      resizable: true,
      headerName: "CLUSTER NAME",
      width: 178,
      filter: "agTextColumnFilter",
      field: "name",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: { checkbox: true },
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      // selectable: false,
      pinned: "left",
      cellClassRules: "readonly-cell",
      suppressCellSelection: true,
      cellClass: "no-border",
      cellStyle: {
        cursor: "pointer",
        color: "#5285EB"
      }
      // cellRenderer: 'agGroupCellRenderer'
    },
    {
      resizable: true,
      // width: 180,
      headerName: "DEPT NAME",
      tooltipField: "departments",
      filter: "agTextColumnFilter",
      field: "departments"
    },
    {
      resizable: true,
      headerName: "RCPT PERIOD",
      filter: "agTextColumnFilter",
      field: "receipt_date"
    },
    {
      resizable: true,
      headerName: "SALES PERIOD",
      // width: 125,
      filter: "agTextColumnFilter",
      field: "selling_date"
    },
    // {
    //   resizable: true,
    //   headerName: "ECOMM",
    //   filter: "agTextColumnFilter",
    //   field: "ecommFlag",
    //   width: 100
    // },
    {
      resizable: true,
      width: 125,
      headerName: "CREATED ON",
      valueFormatter: dateFormatter,
      filter: "agTextColumnFilter",
      field: "created_on"
    },
    // {
    //   resizable: true,
    //   headerName: "CREATED BY",
    //   filter: "agTextColumnFilter",
    //   field: "created_by",
    //   width: 125
    // },
    {
      resizable: true,
      headerName: "LAST UPDATED ON",
      width: 117,
      valueFormatter: dateFormatter,
      filter: "agTextColumnFilter",
      field: "updated_on"
    },
    // {
    //   resizable: true,
    //   width: 120,
    //   headerName: "LAST UPDTAED BY",
    //   filter: "agTextColumnFilter",
    //   field: "updated_by"
    // },
    {
      headerName: "OTB",
      width: 95,

      valueFormatter: otbFormatter,
      filter: "agTextColumnFilter",
      field: "otb_flag"
    },
    {
      headerName: "LEVEL",
      width: 125,
      valueFormatter: levelFormatter,
      filter: "agTextColumnFilter",
      field: "is_season_level"
    },
    {
      headerName: "CLUSTERING TYPE",
      width: 125,
      valueFormatter: clusterTypeFormatter,
      filter: "agTextColumnFilter",
      field: "has_attribute_clustering"
    }
  ]
};

import { tar, inputCss } from "../../../../styles";
import { toPercentage } from "../../../../helpers/formatters";
export default function(rcptMonths, lyFlag) {
  const colDefs = [
    { headerName: "Class", field: "class/vendor" },
    { headerName: "Sub Class", field: "subclass" },
    {
      headerName: "Total",
      valueGetter: function(params) {
        return Math.round(
          rcptMonths.reduce((acc, mo) => acc + +params.data[mo], 0)
        );
      },
      width: 80,
      cellStyle: function(params) {
        return params.value !== 100
          ? { backgroundColor: "rgb(242, 142, 131)", ...tar }
          : { backgroundColor: "#fff", ...tar };
      }
    }
  ];
  rcptMonths.forEach(mo => {
    colDefs.push({
      headerName: mo,
      children: [
        {
          headerName: "TY",
          field: mo,
          width: 80,
          editable: true,
          cellStyle: inputCss,
          valueFormatter: toPercentage,
          valueSetter: function(params) {
            params.data[mo] = isNaN(+params.newValue)
              ? +params.oldValue
              : +params.newValue;
            return true;
          }
        },
        {
          headerName: lyFlag ? "LY" : "LLY",
          field: `ly_${mo}`,
          width: 80,
          valueFormatter: toPercentage,
          valueSetter: function(params) {
            params.data[mo] = isNaN(+params.newValue)
              ? +params.oldValue
              : +params.newValue;
            return true;
          }
        }
      ]
    });
  });
  return colDefs;
}

import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import "./ChartJS.css";

class BarChartComponent extends Component {
  state = {};

  render() {
    let lyOrLly = this.props.lyFlag ? "LY" : "LLY";
    let barDataSets = [
      {
        labels:
          this.props.data &&
          this.props.data["budget"].map((item, index) => {
            return index;
          }),
        datasets: [
          {
            label: "TY",
            subClasses:
              this.props.data &&
              this.props.data["budget"].map((item, index) => {
                return item["subclass"];
              }),
            backgroundColor: "rgb(79, 153, 171)",
            data:
              this.props.data &&
              this.props.data["budget"].map((item, index) => {
                return item["Total_Buy_Cost_TY"]
                  ? item["Total_Buy_Cost_TY"].toFixed(2)
                  : 0;
              })
          },
          {
            label: `${lyOrLly}`,
            backgroundColor: "#e681ff",
            data:
              this.props.data &&
              this.props.data["budget"].map((item, index) => {
                return this.props.lyFlag
                  ? item["Total_Buy_Cost_LY"]
                    ? item["Total_Buy_Cost_LY"].toFixed(2)
                    : 0
                  : item["Total_Buy_Cost_LLY"]
                  ? item["Total_Buy_Cost_LLY"].toFixed(2)
                  : 0;
              })
          }
        ]
      },
      {
        labels:
          this.props.data &&
          this.props.data["unit"].map((item, index) => {
            return index;
          }),
        datasets: [
          {
            label: "TY",
            subClasses:
              this.props.data &&
              this.props.data["unit"].map((item, index) => {
                return item["subclass"];
              }),
            backgroundColor: "rgb(79, 153, 171)",
            data:
              this.props.data &&
              this.props.data["unit"].map((item, index) => {
                return item["Total_Buy_Units_TY"]
                  ? item["Total_Buy_Units_TY"].toFixed(2)
                  : 0;
              })
          },
          {
            label: `${lyOrLly}`,
            backgroundColor: "#e681ff",
            data:
              this.props.data &&
              this.props.data["unit"].map((item, index) => {
                return this.props.lyFlag
                  ? item["Total_Buy_Units_LY"]
                    ? item["Total_Buy_Units_LY"].toFixed(2)
                    : 0
                  : item["Total_Buy_Units_LLY"]
                  ? item["Total_Buy_Units_LLY"].toFixed(2)
                  : 0;
              })
          }
        ]
      },
      {
        labels:
          this.props.data &&
          this.props.data["choiceCount"].map((item, index) => {
            return index;
          }),
        datasets: [
          {
            label: "TY",
            subClasses:
              this.props.data &&
              this.props.data["choiceCount"].map((item, index) => {
                return item["subclass"];
              }),
            backgroundColor: "rgb(79, 153, 171)",
            data:
              this.props.data &&
              this.props.data["choiceCount"].map((item, index) => {
                return item["CC_TY"] ? item["CC_TY"].toFixed(2) : 0;
              })
          },
          {
            label: `${lyOrLly}`,
            backgroundColor: "#e681ff",
            data:
              this.props.data &&
              this.props.data["choiceCount"].map((item, index) => {
                return this.props.lyFlag
                  ? item["CC_LY"]
                    ? item["CC_LY"].toFixed(2)
                    : 0
                  : item["CC_LLY"]
                  ? item["CC_LLY"].toFixed(2)
                  : 0;
              })
          }
        ]
      },
      {
        labels:
          this.props.data &&
          this.props.data["doorCount"].map((item, index) => {
            return index;
          }),
        datasets: [
          {
            label: "TY",
            subClasses:
              this.props.data &&
              this.props.data["doorCount"].map((item, index) => {
                return item["subclass"];
              }),
            backgroundColor: "rgb(79, 153, 171)",
            data:
              this.props.data &&
              this.props.data["doorCount"].map((item, index) => {
                return item["Avg_Store_Count_TY"]
                  ? item["Avg_Store_Count_TY"].toFixed(2)
                  : 0;
              })
          },
          {
            label: `${lyOrLly}`,
            backgroundColor: "#e681ff",
            data:
              this.props.data &&
              this.props.data["doorCount"].map((item, index) => {
                return this.props.lyFlag
                  ? item["Avg_Store_Count_LY"]
                    ? item["Avg_Store_Count_LY"].toFixed(2)
                    : 0
                  : item["Avg_Store_Count_LLY"]
                  ? item["Avg_Store_Count_LLY"].toFixed(2)
                  : 0;
              })
          }
        ]
      },
      {
        labels:
          this.props.data &&
          this.props.data["aps"].map((item, index) => {
            return index;
          }),
        datasets: [
          {
            label: "TY",
            subClasses:
              this.props.data &&
              this.props.data["aps"].map((item, index) => {
                return item["subclass"];
              }),
            backgroundColor: "rgb(79, 153, 171)",
            data:
              this.props.data &&
              this.props.data["aps"].map((item, index) => {
                return item["APS_TY"] ? item["APS_TY"].toFixed(2) : 0;
              })
          },
          {
            label: `${lyOrLly}`,
            backgroundColor: "#e681ff",
            data:
              this.props.data &&
              this.props.data["aps"].map((item, index) => {
                return this.props.lyFlag
                  ? item["APS_LY"]
                    ? item["APS_LY"].toFixed(2)
                    : 0
                  : item["APS_LLY"]
                  ? item["APS_LLY"].toFixed(2)
                  : 0;
              })
          }
        ]
      },
      {
        labels:
          this.props.data &&
          this.props.data["depth"].map((item, index) => {
            return index;
          }),
        datasets: [
          {
            label: "TY",
            subClasses:
              this.props.data &&
              this.props.data["depth"].map((item, index) => {
                return item["subclass"];
              }),
            backgroundColor: "rgb(79, 153, 171)",
            data:
              this.props.data &&
              this.props.data["depth"].map((item, index) => {
                return item["Depth_TY"] ? item["Depth_TY"].toFixed(0) : 0;
              })
          },
          {
            label: `${lyOrLly}`,
            backgroundColor: "#e681ff",
            data:
              this.props.data &&
              this.props.data["depth"].map((item, index) => {
                return this.props.lyFlag
                  ? item["Depth_LY"]
                    ? item["Depth_LY"].toFixed(0)
                    : 0
                  : item["Depth_LLY"]
                  ? item["Depth_LLY"].toFixed(0)
                  : 0;
              })
          }
        ]
      }
    ];

    let getBarData = () => {
      let data;
      switch (this.props.chartName) {
        case "budget":
          data = barDataSets[0];

          break;
        case "unit":
          data = barDataSets[1];

          break;
        case "choiceCount":
          data = barDataSets[2];

          break;
        case "doorCount":
          data = barDataSets[3];

          break;
        case "aps":
          data = barDataSets[4];

          break;
        case "depth":
          data = barDataSets[5];

          break;

        default:
          data = [];
          break;
      }

      return data;
    };
    return <Bar options={this.props.options} data={getBarData()} />;
  }
}

export default BarChartComponent;

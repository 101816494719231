import {
  Button,
  Step,
  StepButton,
  Stepper,
  Typography
} from "@material-ui/core";
import { cloneDeep, isEqual, map, isEmpty } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import ClusteringActions from "../../actions/ClusteringActions";
import ProjectActions from "../../actions/ProjectActions";
import Alert from "../../services/alert";
import "./index.css";
import Step1ClusterInputs from "./Step1ClusterInputs";
import Step2AttributeBand from "./Step2AttributeBand";
import CustomSelectAll from "../../services/customSelectAll";
// import Modal from "../../services/modal";
import Modal from "react-awesome-modal";

import Step3VolumeGroups from "./Step3VolumeGroups";
import Step4Clusters from "./Step4Clusters";

class IntelligentClustering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedAllocatorEmailId: null,
      modalVisible: false,
      activeStep: props.intitialActiveStep,
      isRunClusteringDisable: false,
      allocatorsList: [],
      steps: ["Inputs", "Attribute Bands", "Volume Groups", "Clusters"],
      isFinalStepAlert: false,
      projectDetails: cloneDeep(props.clusteringInputFields) || {},
      clusteringInputFields: cloneDeep(props.clusteringInputFields) || {},
      modalVisibleForUnassignedStores: false
    };
    this.unassignedStoresIndex = 999;
  }
  closeModal = () => {
    this.setState({ modalVisible: false });
  };
  componentDidMount() {
    this.props.getAllocators().then(resp => {
      resp.success &&
        this.setState({
          allocatorsList: resp.data.allocatorList.map(allocator => {
            return { label: allocator.email, value: allocator.id };
          })
        });
    });
  }
  componentDidUpdate(oldProps, oldState) {
    if (this.props !== oldProps) {
      if (this.props.clusteringInputFields !== oldProps.clusteringInputFields) {
        this.setState({
          projectDetails: cloneDeep(this.props.clusteringInputFields),
          clusteringInputFields: cloneDeep(this.props.clusteringInputFields)
        });
      }
      if (oldProps.intitialActiveStep !== this.props.intitialActiveStep) {
        this.setState({
          activeStep: this.props.intitialActiveStep
        });
      }
      if(this.props.clusterMapping && Object.keys(this.props.clusterMapping).length && this.props.userSelectedClusters && this.props.clusterMapping[
        this.props.userSelectedClusters
      ][999]){
      let tempclusterResultsStoreLevel = cloneDeep(
        this.props.clusterResultsStoreLevel.filter(
          e =>
            !this.props.clusterMapping[
              this.props.userSelectedClusters
            ][999].includes(`${+e.loc_id}`)
        )
      );
      this.setState({
        tempclusterResultsStoreLevel
      });
    }
  }
  }

  runClusteringDisableToggler = val => {
    this.setState({
      isRunClusteringDisable: val
    });
  };

  getMaxVolumeClusterAllowed = optimalAttributeClusters => {
    const maxClustersCount = 15;
    const maxVolumeClusters = Math.floor(
      maxClustersCount / optimalAttributeClusters
    );
    return maxVolumeClusters;
  };

  updateClusteringDetails = (key, value) => {
    let clusteringInputFields = this.state.clusteringInputFields;
    clusteringInputFields[key] = value;
    this.setState({
      clusteringInputFields
    });
  };
  switchModal = () => {
    this.setState(prevState => ({
      modalVisibleForUnassignedStores: !prevState.modalVisibleForUnassignedStores
    }));
  };

  handleInputChange = val => {
    this.setState({ selectedAllocatorEmailId: val });
  };
  onSendPlan = () => {
    this.beforeStep2();
    this.setState({ modalVisible: false });
  };
  getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <Step1ClusterInputs
            // clusteringInputFields={step1InputsObj}
            isHindsightFlag={this.props.isHindsightFlag}
            updateClusteringDetails={this.updateClusteringDetails}
            isClusterDisabled={
              this.props.isClusterDisabled || !!this.props.match.params.id
            }
            isSkipClustering={
              !isEmpty(this.props.projectDetails) &&
              this.props.projectDetails.isClusteringSkipped
            }
            runClusteringDisableToggler={this.runClusteringDisableToggler}
          />
        );
      case 1:
        return (
          <Step2AttributeBand
            clusterMasterId={this.props.clusterMasterId}
            projectMainStage={this.props.projectMainStage}
            projectSubStage={this.props.projectSubStage}
            unassignedStoresIndex={this.unassignedStoresIndex}
            getMaxVolumeClusterAllowed={this.getMaxVolumeClusterAllowed}
            clusterMapping={this.props.clusterMapping}
            isClusterDisabled={this.props.isClusterDisabled}
            userSelectedClusters={
              this.props.userSelectedClusters || this.props.optimalClusters
            }
          />
        );
      case 2:
        return (
          <Step3VolumeGroups
            clusterMasterId={this.props.clusterMasterId}
            isHindsightFlag={this.props.isHindsightFlag}
            projectId={this.props.projectId}
            sdfSalesSummary={this.props.sdfSalesSummary}
            hasAttributeClustering={
              this.props.clusteringInputFields.hasAttributeClustering
            }
            getMaxVolumeClusterAllowed={this.getMaxVolumeClusterAllowed}
            optimalAttributeClusters={this.props.optimalAttributeClusters}
            userSelectedClusters={
              this.props.userSelectedClusters || this.props.optimalClusters
            }
            elbowCurve={this.props.elbowCurve}
            clusterMapping={this.props.clusterMapping}
            clusterResultsStoreLevel={this.props.clusterResultsStoreLevel}
            isClusterDisabled={this.props.isClusterDisabled}
            unassignedStoresIndex={this.unassignedStoresIndex}
          />
        );
      case 3:
        return (
          <Step4Clusters
            projectMainStage={this.props.projectMainStage}
            isHindsightFlag={this.props.isHindsightFlag}
            projectDetails={this.props.projectDetails}
            clusterMasterId={this.props.clusterMasterId}
            clusterResultsStoreLevel={this.props.clusterResultsStoreLevel}
            isClusterDisabled={this.props.isClusterDisabled}
            clusterMapping={this.props.clusterMapping}
            userSelectedClusters={this.props.userSelectedClusters}
            optimalAttributeClusters={this.props.optimalAttributeClusters}
            hasAttributeClustering={
              this.props.clusteringInputFields.hasAttributeClustering
            }
            changeCluster={this.changeCluster}
            unassignedStoresIndex={this.unassignedStoresIndex}
            modalVisibleForUnassignedStores={
              this.state.modalVisibleForUnassignedStores
            }
            switchModal={this.switchModal}
            unassignStores={this.unassignStores}
          />
        );
      default:
        return;
    }
  };
  //
  //
  /*=====================================BEFORE STEPS FUNCTIONS=========================================*/
  //
  //
  beforeStep2 = isSkipClustering => {
    let compareFlag = !isEqual(
      this.state.projectDetails,
      this.state.clusteringInputFields
    );

    /* Transforming object as per API requirement */
    let payload = cloneDeep(this.state.clusteringInputFields);
    if (!(this.props.match.url === "/hindsightCluster")) {
      delete payload["dcId"];
      delete payload["dmmId"];
      delete payload["fobId"];
    }

    payload["allocatorId"] = !isSkipClustering ? 2 : undefined;
    // Checking weather the project is in edit flow
    if (this.props.match.params.id) {
      // Check for project name
      // let isSame = this.state.projectDetails.name === payload.name;
      // if (
      //   !isSame &&
      //   this.props.projectNamesList.find(
      //     projectName => projectName === payload.name
      //   )
      // ) {
      //   toast.error("Name Already Exists.", { autoClose: false });
      //   return;
      // }
      // Checking weather any changes done to the from by user
      if (compareFlag || !this.props.clusterResultsStoreLevel.length) {
        if (payload.buyerFromHindsight) {
          payload.buyerFromHindsight = map(payload.buyerFromHindsight);
          // payload.buyerId = payload.buyerId.map(e => parseInt(e, 10));
        } else {
          payload.buyerId = [];
          payload.departmentId = map(payload.departments, "value");
          payload.classes = map(payload.classes, "value");
          payload.vendors = map(payload.vendors, "value");
          payload.season = map(payload.season, "value");
        }
        if (payload.otb_flag) {
          payload.receiptRange.sdate = moment(
            payload.receiptRange.sdate
          ).format("YYYY-MM");
          payload.receiptRange.edate = moment(
            payload.receiptRange.edate
          ).format("YYYY-MM");
        }
        payload.sellingPeriod.sdate = moment(
          payload.sellingPeriod.sdate
        ).format("YYYY-MM-DD");
        payload.sellingPeriod.edate = moment(
          payload.sellingPeriod.edate
        ).format("YYYY-MM-DD");
        payload.receiptRange.sdate = moment(payload.receiptRange.sdate).format(
          "YYYY-MM-DD"
        );
        payload.receiptRange.edate = moment(payload.receiptRange.edate).format(
          "YYYY-MM-DD"
        );
        payload["clusterMasterId"] = parseInt(this.props.match.id);
        payload["projectId"] = parseInt(this.props.match.params.id);
        payload["lyFlag"] = this.props.lyFlag;
        if (!payload.departmentId) {
          // payload.buyerId = map(payload.buyerId, "value");
          payload.departmentId = map(payload.departments, "value")[0];
          payload.classes = map(payload.classes, "value");
          payload.vendors = map(payload.vendors, "value");
          payload.season = map(payload.season, "value");
        }
        this.props.runIntelligentClustering(payload).then(resp => {
          if (resp.success) {
            if (payload.hasAttributeClustering) {
              if (this.props.isHindsightFlag) {
                this.props.saveClusterStage(1, 2).then(resp => {
                  resp.success &&
                    this.props.history.push("/hindsightdashboard");
                });
              } else {
                this.props.saveProjectStage(1, 2).then(resp => {
                  resp.success && this.props.history.push("/dashboard");
                });
              }
            } else {
              if (this.props.isHindsightFlag) {
                this.props.saveClusterStage(1, 3).then(resp => {
                  resp.success &&
                    this.props.history.push("/hindsightdashboard");
                });
              } else {
                this.props.saveProjectStage(1, 3).then(resp => {
                  resp.success && this.props.history.push("/dashboard");
                });
              }
            }
          }
        });
      } else {
        this.setState({
          activeStep: 1
        });
      }
    } else {
      // Check for project name
      // if (
      //   this.props.projectNamesList &&
      //   this.props.projectNamesList.find(
      //     projectName => projectName === payload.name
      //   )
      // ) {
      //   toast.error("Name Already Exists.", { autoClose: false });
      //   return;
      // }
      // handling departments in future for multi select
      // payload["departments"] = [];
      if (payload.buyerFromHindsight) {
        payload.buyerFromHindsight = map(payload.buyerFromHindsight);
        // payload.buyerId = payload.buyerId.map(e => parseInt(e, 10));
        payload.departmentId = payload.departmentId.map(e => parseInt(e, 10));
      }
      payload.sellingPeriod.sdate = moment(payload.sellingPeriod.sdate).format(
        "YYYY-MM-DD"
      );
      payload.sellingPeriod.edate = moment(payload.sellingPeriod.edate).format(
        "YYYY-MM-DD"
      );
      payload.receiptRange.sdate = moment(payload.receiptRange.sdate).format(
        "YYYY-MM-DD"
      );
      payload.receiptRange.edate = moment(payload.receiptRange.edate).format(
        "YYYY-MM-DD"
      );
      payload["skipClustering"] = isSkipClustering;
      this.props
        .runIntelligentClustering(payload)
        .then(resp => {
          if (resp.success) {
            this.setState({
              projectDetails: cloneDeep(this.state.clusteringInputFields)
            });

            if (this.props.isHindsightFlag) {
              if (payload.hasAttributeClustering) {
                this.props
                  .saveClusterStage(1, 2, resp.data.clusterMasterId)
                  .then(resp => {
                    resp.success &&
                      this.props.history.push("/hindsightdashboard");
                  });
              } else {
                this.props
                  .saveClusterStage(1, 3, resp.data.clusterMasterId)
                  .then(resp => {
                    resp.success &&
                      this.props.history.push("/hindsightdashboard");
                  });
              }
              this.props.history.replace(
                `/hindsightCluster/${resp.data.clusterMasterId}`
              );
            } else {
              if (isSkipClustering) {
                this.props
                  .updateHasClusteringDone({
                    projectId: resp.data.projectId,
                    isClusteringSkipped: true
                  })
                  .then(resp => {
                    resp.success &&
                      this.props.saveProjectStage(2, 1).then(resp => {
                        resp.success && this.props.history.push("/dashboard");
                      });
                  });
              } else if (!payload.hasAttributeClustering) {
                this.props.saveProjectStage(1, 3).then(resp => {
                  resp.success && this.props.history.push("/dashboard");
                });
                this.props.history.replace(
                  `/stepperContainer/${resp.data.projectId}`
                );
              } else {
                this.props
                  .saveProjectStage(1, 2, resp.data.projectId)
                  .then(resp => {
                    resp.success && this.props.history.push("/dashboard");
                  });
              }
            }
          }
        })
        .catch(err => console.log(err));
    }
  };

  beforeStep3 = () => {
    if (this.props.projectMainStage > 1) {
      this.setState({ activeStep: 2 });
    } else {
      if (this.props.isHindsightFlag) {
        this.props.saveClusterStage(1, 3).then(resp => {
          resp.success && this.setState({ activeStep: 2 });
        });
      } else {
        this.props.saveProjectStage(1, 3).then(resp => {
          resp.success && this.setState({ activeStep: 2 });
        });
      }
    }
  };

  beforeStep4() {
    if (this.props.isHindsightFlag) {
      this.props.saveClusterStage(1, 4).then(resp => {
        resp.success && this.setState({ activeStep: 3 });
      });
    } else {
      this.props.saveProjectStage(1, 4).then(resp => {
        resp.success && this.setState({ activeStep: 3 });
      });
    }
  }
  onSaveAndDownload = () => {
    const { optimalAttributeClusters, attributeStoreMapping } = this.props;
    const {
      hasAttributeClustering,
      salesFlag
    } = this.props.clusteringInputFields;
    let tempPropNum =
      this.props.userSelectedClusters || this.props.optimalClusters;
    let newObj = { [tempPropNum]: this.props.clusterMapping[tempPropNum] };

    let payload = {
      clusterMasterId: this.props.clusterMasterId,
      // projectId: this.props.clusterMasterId,
      is_downloadable: 1,
      is_hindsightCall: this.props.isHindsightFlag ? true : false,
      mapping: newObj,
      hasAttributeClustering,
      attributeClusterMapping: hasAttributeClustering && {
        [optimalAttributeClusters]:
          attributeStoreMapping[optimalAttributeClusters]
      },
      clusterResultsStoreLevel: this.props.clusterResultsStoreLevel,
      sales_f: salesFlag
    };
    this.props.updateClusterMapping(payload);
  };
  OnProceedHindsight = () => {
    const { optimalAttributeClusters, attributeStoreMapping } = this.props;
    const {
      hasAttributeClustering,
      salesFlag
    } = this.props.clusteringInputFields;
    let tempPropNum =
      this.props.userSelectedClusters || this.props.optimalClusters;
    let newObj = { [tempPropNum]: this.props.clusterMapping[tempPropNum] };
    let payload = {
      clusterMasterId: this.props.clusterMasterId,
      // projectId: this.props.clusterMasterId,
      hasAttributeClustering,
      is_downloadable: 0,
      is_hindsightCall: true,
      attributeClusterMapping: hasAttributeClustering && {
        [optimalAttributeClusters]:
          attributeStoreMapping[optimalAttributeClusters]
      },
      mapping: newObj,
      clusterResultsStoreLevel: this.props.clusterResultsStoreLevel,
      sales_f: salesFlag
    };
    const context = this;
    this.props.updateClusterMapping(payload).then(resp => {
      if (resp.success) {
        if (this.props.isHindsightFlag) {
          context.props.saveClusterStage(1, 4).then(resp => {
            if (resp.success) {
              context.props.history.push("/hindsightdashboard");
            }
          });
        }
        context.props.updateHasClusteringDone({
          projectId: this.props.match.params.id,
          isClusteringSkipped: false
        });
      }
    });
  };
  OnProceed = () => {
    if (!this.props.isClusterDisabled) {
      this.setState({
        isFinalStepAlert: true
      });
    } else {
      this.props.updateActiveTab(1, 1);
    }
  };

  changeCluster = (
    isNewCluster,
    selectedStores,
    reassignedCluster,
    attrCluster
  ) => {
    if (selectedStores.length) {
      const { hasAttributeClustering } = this.props.clusteringInputFields;
      let selectedClusterMapping = this.props.clusterMapping[
        this.props.userSelectedClusters
      ];
      let selectedAttrClusterMapping =
        hasAttributeClustering &&
        this.props.attributeStoreMapping[this.props.optimalAttributeClusters];
      if (isNewCluster) {
        this.props.clusterMapping[
          this.props.userSelectedClusters + 1
        ] = cloneDeep(
          this.props.clusterMapping[this.props.userSelectedClusters]
        );
        selectedClusterMapping = this.props.clusterMapping[
          this.props.userSelectedClusters + 1
        ];
        selectedClusterMapping[this.props.userSelectedClusters + 1] = [];
        this.props.changeUserSelectedClusters(
          this.props.userSelectedClusters + 1
        );
      }
      let newCluster = isNewCluster
        ? this.props.userSelectedClusters + 1
        : reassignedCluster.value;
      selectedStores.forEach(e => {
        const selectedStore = selectedClusterMapping[
          this.unassignedStoresIndex
        ].splice(
          selectedClusterMapping[this.unassignedStoresIndex].indexOf(
            `${+e.loc_id}`
          ),
          1
        )[0];
        selectedClusterMapping[newCluster].push(selectedStore);
        e.clusters = newCluster;
        if (hasAttributeClustering) {
          selectedAttrClusterMapping[attrCluster].push(selectedStore);
          e.attribute_clusters = attrCluster;
        }
      });
      toast.info(
        "Mapping updated! Please save the changes to keep current mapping."
      );
      this.switchModal();
    } else {
      toast.error("Please select atleast one store", { autoClose: false });
    }
  };

  unassignStores = selectedStores => {
    const { hasAttributeClustering } = this.props.clusteringInputFields;
    let selectedClusterMapping = this.props.clusterMapping[
      this.props.userSelectedClusters
    ];
    let selectedAttrClusterMapping =
      hasAttributeClustering &&
      this.props.attributeStoreMapping[this.props.optimalAttributeClusters];
    selectedStores.forEach(e => {
      if (e.clusters !== 0) {
        selectedClusterMapping[this.unassignedStoresIndex].push(
          selectedClusterMapping[e.clusters].splice(
            selectedClusterMapping[e.clusters].indexOf(`${+e.loc_id}`),
            1
          )[0]
        );
        e.clusters = this.unassignedStoresIndex;
        if (hasAttributeClustering) {
          selectedAttrClusterMapping[e.attribute_clusters].splice(
            selectedAttrClusterMapping[e.attribute_clusters].indexOf(
              `${+e.loc_id}`
            ),
            1
          );
          e.attribute_clusters = "";
        }
      }
    });
    toast.info(
      "Mapping updated! Please save the changes to keep current mapping."
    );
  };

  handleAccept = () => {
    this.setState({ isFinalStepAlert: false });
    let tempPropNum =
      this.props.userSelectedClusters || this.props.optimalClusters;
    let newObj = { [tempPropNum]: this.props.clusterMapping[tempPropNum] };
    const { optimalAttributeClusters, attributeStoreMapping } = this.props;
    const {
      hasAttributeClustering,
      salesFlag
    } = this.props.clusteringInputFields;
    let payload = {
      clusterMasterId: this.props.clusterMasterId,
      // projectId: this.props.clusterMasterId,
      is_downloadable: 0,
      is_hindsightCall: this.props.isHindsightFlag ? true : false,
      mapping: newObj,
      hasAttributeClustering,
      attributeClusterMapping: hasAttributeClustering && {
        [optimalAttributeClusters]:
          attributeStoreMapping[optimalAttributeClusters]
      },
      clusterResultsStoreLevel: this.props.clusterResultsStoreLevel,
      sales_f: salesFlag
    };
    const context = this;
    this.hasDataChanged()
      ? this.props.updateClusterMapping(payload).then(resp => {
          if (resp.success) {
            if (this.props.isHindsightFlag) {
              context.props.saveClusterStage(1, 4).then(resp => {
                if (resp.success) {
                  context.props.history.push("/hindsightdashboard");
                }
              });
            }
            context.props
              .updateHasClusteringDone({
                projectId: this.props.match.params.id,
                isClusteringSkipped: false
              })
              .then(resp => {
                if (resp.success) {
                  if (
                    Object.keys(context.props.lineArchitectureDetails).length
                  ) {
                    context.props.saveProjectStage(2, 2).then(resp => {
                      resp.success && context.props.history.push("/dashboard");
                    });
                  } else {
                    context.props.saveProjectStage(2, 1).then(resp => {
                      resp.success && context.props.history.push("/dashboard");
                    });
                  }
                }
              });
          }
        })
      : context.props
          .updateHasClusteringDone({
            projectId: this.props.match.params.id,
            isClusteringSkipped: false
          })
          .then(resp => {
            if (resp.success) {
              if (Object.keys(context.props.lineArchitectureDetails).length) {
                context.props.saveProjectStage(2, 2).then(resp => {
                  resp.success && context.props.history.push("/dashboard");
                });
              } else {
                context.props.saveProjectStage(2, 1).then(resp => {
                  resp.success && context.props.history.push("/dashboard");
                });
              }
            }
          });
  };

  /*=====================================STEPPER FUNCTIONS=======================================*/
  //
  //
  hasDataChanged = () => {
    let hasDataChanged = false;
    hasDataChanged = !isEqual(
      this.state.tempclusterResultsStoreLevel,
      this.props.clusterResultsStoreLevel.filter(
        e =>
          !this.props.clusterMapping[this.props.userSelectedClusters][
            this.unassignedStoresIndex
          ].includes(`${+e.loc_id}`)
      )
    );
    return hasDataChanged;
  };

  handleNext = () => {
    switch (this.state.activeStep) {
      case 0:
        this.beforeStep2();
        break;
      case 1:
        this.beforeStep3();
        break;
      case 2:
        this.beforeStep4();
        break;
      default:
        break;
    }
  };
  isCreateFlow = () => {
    let isCreateFlowFlag =
      this.props.projectMainStage === 1 &&
      this.state.activeStep === this.props.projectSubStage - 1;
    return isCreateFlowFlag;
  };
  onNextClick = () => {
    if (this.state.activeStep === 0) {
      this.props.clusterResultsStoreLevel.length
        ? !this.state.clusteringInputFields.hasAttributeClustering
          ? this.setState({ activeStep: 2 })
          : this.setState({ activeStep: this.state.activeStep + 1 })
        : this.handleNext();
    } else {
      this.isCreateFlow()
        ? this.handleNext()
        : this.setState({ activeStep: this.state.activeStep + 1 });
    }
  };
  isStepDisabled = index => {
    const {
      projectMainStage,
      projectSubStage,
      clusterResultsStoreLevel
    } = this.props;
    const { hasAttributeClustering } = this.props.clusteringInputFields;
    if (index === 1 && !hasAttributeClustering) {
      return true;
    }

    if (projectMainStage === 1) {
      if (index >= projectSubStage) {
        return true;
      }
      return index >= projectSubStage;
    } else if (!clusterResultsStoreLevel.length) {
      return true;
    }
  };
  isNextDisabledforUser = () => {
    const { attributeStoreMapping, userAccess, notEnoughFillRate } = this.props;
    const { activeStep } = this.state;
    const attrClustersSetCount =
      attributeStoreMapping && Object.keys(attributeStoreMapping).length;
    if (notEnoughFillRate && activeStep === 1) {
      return false;
    }
    if (this.isCreateFlow() && !attrClustersSetCount && activeStep === 1) {
      return true;
    }

    return (
      this.isCreateFlow() &&
      userAccess &&
      !userAccess[`${activeStep + 1}`].WRITE
    );
  };
  skipClustering = () => {
    this.beforeStep2(true);
  };

  handleBack = () => {
    if (
      this.state.activeStep === 2 &&
      !this.props.clusteringInputFields.hasAttributeClustering
    ) {
      this.setState({
        activeStep: 0
      });
      return;
    }
    this.setState({ activeStep: Number(this.state.activeStep) - 1 });
  };

  handleStep = step => {
    this.setState({ activeStep: step });
  };

  render() {
    return (
      <div style={{ width: "100%" }}>
        <Stepper
          alternativeLabel
          nonLinear
          activeStep={this.state.activeStep}
          style={{ backgroundColor: "transparent" }}
        >
          {this.state.steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepButton
                  disabled={this.isStepDisabled(index)}
                  onClick={() => this.handleStep(index)}
                >
                  {label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        <div>
          <Typography component={"span"}>
            {this.getStepContent(this.state.activeStep)}
          </Typography>
          <div className="mt-3">
            {this.state.activeStep > 0 ? (
              <Button onClick={this.handleBack}>Back</Button>
            ) : null}
            {this.state.activeStep === 0 && (
              <Button
                variant="contained"
                color="primary"
                disabled={
                  this.state.isRunClusteringDisable ||
                  this.isNextDisabledforUser()
                }
                onClick={this.onNextClick}
              >
                {!this.props.match.params.id
                  ? "Run Intelligent Clustering"
                  : !isEqual(
                      this.state.clusteringInputFields,
                      this.state.projectDetails
                    )
                  ? "Run Intelligent Clustering"
                  : !this.props.clusterResultsStoreLevel.length
                  ? "Run Intelligent Clustering"
                  : "Next"}

                <i className="fa fa-chevron-right ml-2" aria-hidden="true" />
              </Button>
            )}
            {/* {!this.props.match.params.id && !this.props.isHindsightFlag && (
              <Button
                variant="contained"
                color="primary"
                disabled={
                  this.state.isRunClusteringDisable ||
                  this.isNextDisabledforUser()
                }
                onClick={this.skipClustering}
              >
                Skip Clustering
              </Button>
            )} */}

            {(this.state.activeStep === 1 || this.state.activeStep === 2) && (
              <Button
                variant="contained"
                color="primary"
                disabled={this.isNextDisabledforUser()}
                onClick={this.onNextClick}
              >
                Next
                <i
                  className="fa fa-angle-right innerIcoTop2"
                  aria-hidden="true"
                />
              </Button>
            )}
            {this.props.isHindsightFlag
              ? this.state.activeStep === 3 && (
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.isNextDisabledforUser()}
                      onClick={this.OnProceedHindsight}
                    >
                      Finish
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.isNextDisabledforUser()}
                      onClick={this.onSaveAndDownload}
                    >
                      Save & Download Cluster Summary
                    </Button>
                  </span>
                )
              : this.state.activeStep === 3 && (
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.isNextDisabledforUser()}
                      onClick={this.OnProceed}
                    >
                      Next
                      <i
                        className="fa fa-chevron-right ml-2"
                        aria-hidden="true"
                      />
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.isNextDisabledforUser()}
                      onClick={this.onSaveAndDownload}
                    >
                      Save & Download Cluster Summary
                    </Button>
                  </span>
                )}
          </div>
          <Alert
            open={this.state.isFinalStepAlert}
            title="Alert"
            isResetRequired={false}
            cancelButtonText="Cancel"
            actionButtonText="Continue"
            content="Clustering will be locked and cannot be altered in future"
            handleAccept={this.handleAccept}
            handleClose={() => {
              this.setState({
                isFinalStepAlert: false
              });
            }}
          />
        </div>
        <Modal
          visible={this.state.modalVisible}
          effect="fadeInUp"
          width="40%"
          height="30%"
          onClickAway={() => this.setState({ modalVisible: false })}
        >
          <div className="p-4">
            <div className="row">
              <div className="col-md-5 mt-2" align="right">
                <label htmlFor="inputPassword4">
                  Select an Allocator Email ID:
                </label>
              </div>
              <div className="col-md-6">
                <CustomSelectAll
                  isClearable
                  disabled={!this.state.selectedAllocatorEmailId}
                  value={this.state.allocator}
                  options={this.state.allocatorsList}
                  onChange={val => this.handleInputChange(val)}
                  placeholder="Select Email"
                />
              </div>
            </div>
            <div align="center" className="mt-4">
              <Button
                style={{ textTransform: "capitalize", marginTop: "1rem" }}
                onClick={this.onSendPlan}
                disabled={!this.state.selectedAllocatorEmailId}
              >
                Send Plan To Allocator
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    projectId: store.projectReducer.projectId,
    clusterMasterId: store.projectReducer.clusterMasterId,
    projectMainStage: store.projectReducer.projectMainStage,
    projectDetails: store.projectReducer.projectDetails,
    projectSubStage: store.projectReducer.projectSubStage,
    projectNamesList: store.userReducer.projectNamesList,

    userSelectedClusters: store.clusteringReducer.userSelectedClusters,
    clusteringInputFields: store.clusteringReducer.clusteringInputFields,
    optimalClusters: store.clusteringReducer.optimalClusters,
    optimalAttributeClusters: store.clusteringReducer.optimalAttributeClusters,
    clusterMapping: store.clusteringReducer.clusterMapping,
    attributeStoreMapping: store.clusteringReducer.attributeStoreMapping,
    notEnoughFillRate: store.clusteringReducer.notEnoughFillRate,
    elbowCurve: store.clusteringReducer.elbowCurve,
    clusterResultsStoreLevel: store.clusteringReducer.clusterResultsStoreLevel,
    sdfSalesSummary: store.clusteringReducer.sdfTableData,

    lineArchitectureDetails:
      store.assortmentPlanningReducer.lineArchitectureDetails,
    lyFlag: store.projectReducer.lyFlag
  };
};
const mapActionsToProps = {
  getAllocators: ClusteringActions.getAllocators,
  runIntelligentClustering: ClusteringActions.runIntelligentClustering,
  updateClusterMapping: ClusteringActions.updateClusterMapping,
  updateHasClusteringDone: ProjectActions.updateHasClusteringDone,
  changeUserSelectedClusters: ClusteringActions.changeUserSelectedClusters
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(IntelligentClustering));

import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
const Schema = Yup.object().shape({
  email: Yup.string().required("This field is required")
});

export default class ForgotPassword extends Component {
  updateForgotPasswordFormFlag = () => {
    this.props.forgotPasswordFormToggler(false);
  };
  render() {
    return (
      <div>
        <div id="signinform" style={{ width: "62%", margin: "62px auto" }}>
          {/* <p className="signIn">
            Sign in to{" "}
            <strong style={{ color: "#2D69EB" }}>Assortment Suite</strong>
          </p> */}
          {/* <p className="enterDetails">Enter your details below </p> */}
          <Formik
            initialValues={{ email: "" }}
            validationSchema={Schema}
            onSubmit={values => {
              let paylaod = {
                email: values.email
              };
              this.props.forgotPassword(paylaod);
            }}
          >
            {({
              values,
              errors,
              handleSubmit,
              touched,
              handleChange,
              handleBlur
            }) => (
              <form className="formWidth" onSubmit={handleSubmit}>
                <div className="form-group">
                  <p className="emailAddress">EMAIL ADDRESS</p>
                  <input
                    id="email"
                    className="form-control inputBorder"
                    placeholder="Email"
                    type="text"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email && (
                    <div className="input-feedback">{errors.email}</div>
                  )}
                </div>
                <div id="btndiv3">
                  <button
                    type="button"
                    onClick={this.updateForgotPasswordFormFlag}
                    className="btn btn-primary btn-square shadow-sm btnsignin"
                  >
                    BACK
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary btn-square shadow-sm btnsignin"
                  >
                    Reset my password
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default (flag, obj, node, index) => {
  var data;
  if (flag) {
    data = node;
  } else {
    data = node.group ? node.aggData : node.data;
  }
  if (typeof data.clusters === "number") {
    obj.clusters = data.clusters;
  }
  if (typeof data.choice === "number") {
    obj.choice += data.choice;
  }
  if (typeof data.weeks_active === "number") {
    obj.weeks_active += data.weeks_active;
  }
  if (typeof data.sales_u === "number") {
    obj.sales_u += data.sales_u;
  }
  if (typeof data.sales_rtl_lost_sales === "number") {
    obj.sales_rtl_lost_sales += data.sales_rtl_lost_sales;
  }
  if (typeof data.u_rec_depth === "number") {
    obj.u_rec_depth += data.u_rec_depth;
  }
  if (typeof data.sales_rtl === "number") {
    obj.sales_rtl += data.sales_rtl;
  }
  if (typeof data.gm === "number") {
    obj.gm += data.gm;
  }
  if (typeof data.gm_asst === "number") {
    obj.gm_asst += data.gm_asst;
  }
  if (typeof data.oh_tot === "number") {
    obj.oh_tot += data.oh_tot;
  }
  if (typeof data.rcpt_u === "number") {
    obj.rcpt_u += data.rcpt_u;
  }
  if (typeof data.bop_u === "number") {
    obj.bop_u += data.bop_u;
  }
  if (typeof data.rcpt_qty_st === "number") {
    obj.rcpt_qty_st += data.rcpt_qty_st;
  }
  if (typeof data.eop_u === "number") {
    obj.eop_u += data.eop_u;
  }
  if (typeof data.tot_sls_qty === "number") {
    obj.tot_sls_qty += data.tot_sls_qty;
  }
  if (typeof data.u_turn_denom === "number") {
    if (index === 0) {
      obj.u_turn_denom = data.u_turn_denom;
    }
    if (data.u_turn_denom > obj.u_turn_denom)
      obj.u_turn_denom = data.u_turn_denom;
  }

  if (!flag) {
    if (!node.group && typeof data.rcpt_u === "number") {
      data.rcpts_per_door_per_cc = data.rcpt_u;
    }
    if (!node.group && typeof data.sales_u === "number") {
      data.sls_u_per_door = data.sales_u;
    }
    if (!node.group && typeof data.gm === "number") {
      data.gm_per_door = data.gm;
    }
    if (!node.group && typeof data.gm_asst === "number") {
      data.gm_asst_per_door = data.gm_asst;
    }
    if (!node.group && typeof data.sales_rtl === "number") {
      data.rtl_per_door = data.sales_rtl;
    }
  }
  return obj;
};

import Tippy from "@tippy.js/react";
import moment from "moment";
import React, { Component } from "react";

class FloatingComponent extends Component {
  state = {
    class: "defaultSidebarMini",
    display: "none"
  };

  onHover = () => {
    this.setState({
      class: "active",
      display: "inline-block"
    });
  };

  onLeaveHover = () => {
    this.setState({
      class: "",
      display: ""
    });
  };

  render() {
    const receiptSdateFormat = this.props.data.otb_flag
      ? moment(
          this.props.data &&
            this.props.data.receiptRange &&
            this.props.data.receiptRange.sdate
        ).format("MM/YYYY")
      : moment(
          this.props.data &&
            this.props.data.receiptRange &&
            this.props.data.receiptRange.sdate
        ).format("MM/DD/YYYY");
    const receiptEdateFormat = this.props.data.otb_flag
      ? moment(
          this.props.data &&
            this.props.data.receiptRange &&
            this.props.data.receiptRange.edate
        ).format("MM/DD/YYYY")
      : moment(
          this.props.data &&
            this.props.data.receiptRange &&
            this.props.data.receiptRange.edate
        ).format("MM/DD/YYYY");
    const sellingSdateFormat = moment(
      this.props.data &&
        this.props.data.sellingPeriod &&
        this.props.data.sellingPeriod.sdate
    ).format("MM/DD/YYYY");
    const sellingEdateFormat = moment(
      this.props.data &&
        this.props.data.sellingPeriod &&
        this.props.data.sellingPeriod.edate
    ).format("MM/DD/YYYY");
    return (
      <div className="wrapper2">
        <nav
          id="sidebarMini"
          className={`${this.state.class} defaultSidebarMini`}
          onMouseEnter={e => this.onHover(e)}
          onMouseLeave={e => this.onLeaveHover(e)}
        >
          {/* <div className="sidebar-header" /> */}
          <ul className="list-unstyled components sideBarList2">
            <li className={"active"}>
              {/* <a href="/ComparePromotion"> */}
              <div
                style={{
                  backgroundColor: "#2d69eb",
                  borderRadius: "5px 0px 0px 5px"
                }}
              >
                <i
                  className="fa fa-chevron-left icon-styling"
                  aria-hidden="true"
                />
                <span
                  className="fontstyling FloatingComponent-Container"
                  style={{ height: "380", overflowY: "auto", width: "150%" }}
                >
                  <div className="FloatingComponent-inputHeader">
                    <div>View Filters</div>
                  </div>
                  <div className="FloatingComponent-ContentContainer">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Cluster Plan Name</div>
                          <input
                            value={
                              (this.props.data && this.props.data.name) || ""
                            }
                            disabled
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Select Buyer ID</div>
                          <Tippy
                            placement={"left"}
                            arrow={true}
                            distance={10}
                            content={
                              <div>
                                {this.props.data &&
                                  this.props.data.buyerFromHindsight &&
                                  this.props.data.buyerFromHindsight.map(
                                    (item, index) => {
                                      return (
                                        <div key={index}>{item.label}</div>
                                      );
                                    }
                                  )}
                              </div>
                            }
                          >
                          <div className="FloatingComponent-tippy-input">
                          <input
                            value={
                              this.props.data &&
                              this.props.data.buyerFromHindsight &&
                              this.props.data.buyerFromHindsight.length > 1
                                ? `${this.props.data &&
                                    this.props.data.buyerFromHindsight[0] &&
                                    this.props.data.buyerFromHindsight[0].label} + ${this
                                    .props.data &&
                                    this.props.data.buyerFromHindsight.length - 1}`
                                : (this.props.data &&
                                    this.props.data.buyerFromHindsight &&
                                    this.props.data.buyerFromHindsight[0] &&
                                    this.props.data.buyerFromHindsight[0].label) ||
                                  ""
                            }
                            disabled
                            type="text"
                          />
                          </div>
                          </Tippy>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Select Department IDs</div>
                          <Tippy
                            placement={"left"}
                            arrow={true}
                            distance={10}
                            content={
                              <div>
                                {this.props.data &&
                                  this.props.data.departments &&
                                  this.props.data.departments.map(
                                    (item, index) => {
                                      return (
                                        <div key={index}>{item.label}</div>
                                      );
                                    }
                                  )}
                              </div>
                            }
                          >
                            <div className="FloatingComponent-tippy-input">
                              <input
                                disabled
                                value={
                                  this.props.data &&
                                  this.props.data.departments &&
                                  this.props.data.departments.length > 1
                                    ? `${this.props.data &&
                                        this.props.data.departments[0] &&
                                        this.props.data.departments[0]
                                          .label} + ${this.props.data &&
                                        this.props.data.departments.length - 1}`
                                    : (this.props.data &&
                                        this.props.data.departments &&
                                        this.props.data.departments[0] &&
                                        this.props.data.departments[0].label) ||
                                      ""
                                }
                                type="text"
                              />{" "}
                              {/* <i
                              class="fa fa-info-circle errspan"
                              aria-hidden="true"
                            /> */}
                            </div>
                          </Tippy>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Select Class</div>
                          <Tippy
                            placement={"left"}
                            arrow={true}
                            distance={10}
                            content={
                              <div>
                                {this.props.data &&
                                  this.props.data.classes &&
                                  this.props.data.classes.map((item, index) => {
                                    return <div key={index}>{item.label}</div>;
                                  })}
                              </div>
                            }
                          >
                            <div className="FloatingComponent-tippy-input">
                              <input
                                disabled
                                value={
                                  this.props.data &&
                                  this.props.data.classes &&
                                  this.props.data.classes.length > 1
                                    ? `${this.props.data &&
                                        this.props.data.classes[0] &&
                                        this.props.data.classes[0]
                                          .label} + ${this.props.data.classes
                                        .length - 1}`
                                    : (this.props.data &&
                                        this.props.data &&
                                        this.props.data.classes &&
                                        this.props.data.classes[0].label) ||
                                      ""
                                }
                                type="text"
                              />
                              {/* <i
                              class="fa fa-info-circle errspan"
                              aria-hidden="true"
                            /> */}
                            </div>
                          </Tippy>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Select Vendor</div>
                          <Tippy
                            placement={"left"}
                            arrow={true}
                            distance={10}
                            content={
                              <div>
                                {this.props.data &&
                                  this.props.data &&
                                  this.props.data.vendors &&
                                  this.props.data.vendors.map((item, index) => {
                                    return <div key={index}>{item.label}</div>;
                                  })}
                              </div>
                            }
                          >
                            <div className="FloatingComponent-tippy-input">
                              <input
                                disabled
                                value={
                                  this.props.data &&
                                  this.props.data.vendors &&
                                  this.props.data.vendors.length > 1
                                    ? `${this.props.data &&
                                        this.props.data.vendors[0] &&
                                        this.props.data.vendors[0]
                                          .label} + ${this.props.data &&
                                        this.props.data.vendors.length - 1}`
                                    : (this.props.data &&
                                        this.props.data &&
                                        this.props.data.vendors &&
                                        this.props.data.vendors[0] &&
                                        this.props.data.vendors[0].label) ||
                                      ""
                                }
                                type="text"
                              />{" "}
                              {/* <i
                              class="fa fa-info-circle errspan"
                              aria-hidden="true"
                            /> */}
                            </div>
                          </Tippy>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Select Season Code</div>
                          <Tippy
                            placement={"left"}
                            arrow={true}
                            distance={10}
                            content={
                              <div>
                                {this.props.data &&
                                  this.props.data &&
                                  this.props.data.season &&
                                  this.props.data.season.map((item, index) => {
                                    return <div key={index}>{item.label}</div>;
                                  })}
                              </div>
                            }
                          >
                            <div className="FloatingComponent-tippy-input">
                              <input
                                disabled
                                value={
                                  this.props.data &&
                                  this.props.data.season &&
                                  this.props.data.season.length > 1
                                    ? `${this.props.data &&
                                        this.props.data.season[0] &&
                                        this.props.data.season[0]
                                          .label} + ${this.props.data &&
                                        this.props.data.season.length - 1}`
                                    : (this.props.data &&
                                        this.props.data &&
                                        this.props.data.season &&
                                        this.props.data.season[0] &&
                                        this.props.data.season[0].label) ||
                                      ""
                                }
                                type="text"
                              />{" "}
                              {/* <i
                              class="fa fa-info-circle errspan"
                              aria-hidden="true"
                            /> */}
                            </div>
                          </Tippy>
                        </div>
                      </div>
                      {/* <div className="FloatingComponent-inputContainer">
                        <div>Select SSD (Optional)</div>
                        <input disabled type="text" />
                      </div>
                      <div className="FloatingComponent-inputContainer">
                        <div>Select primary attribute</div>
                        <input disabled value="Sleeve length +2" type="text" />
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Select Future Receipt Period</div>
                          <Tippy
                            placement={"left"}
                            arrow={true}
                            distance={10}
                            content={
                              <div>
                                {receiptSdateFormat} - {receiptEdateFormat}
                              </div>
                            }
                          >
                            <div className="FloatingComponent-tippy-input">
                              <input
                                disabled
                                value={`${receiptSdateFormat} - ${receiptEdateFormat}`}
                                type="text"
                              />
                            </div>
                          </Tippy>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Select Future Selling Period</div>
                          <Tippy
                            placement={"left"}
                            arrow={true}
                            distance={10}
                            content={
                              <div>
                                {sellingSdateFormat} - {sellingEdateFormat}
                              </div>
                            }
                          >
                            <div className="FloatingComponent-tippy-input">
                              <input
                                disabled
                                value={`${sellingSdateFormat} - ${sellingEdateFormat}`}
                                type="text"
                              />
                            </div>
                          </Tippy>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Include Repl ?</div>
                          <input
                            value={
                              this.props.data && this.props.data.rpl_f
                                ? "Yes"
                                : "No"
                            }
                            disabled
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Exclude Ecom ?</div>
                          <input
                            value={
                              this.props.data && this.props.data.ecommFlag
                                ? "Yes"
                                : "No"
                            }
                            disabled
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Exclude Lost Sales ?</div>
                          <input
                            value={
                              this.props.data && this.props.data.isLostSales
                                ? "Yes"
                                : "No"
                            }
                            disabled
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
              {/* </a> */}
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default FloatingComponent;

import { toast } from "react-toastify";
import api from "../axios";

const buyRollUpActions = {
  getRollUpDetails: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/buyRollUpDataForUI",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_ROLL_UP_DETAILS",
            payload: resp.data.Final_Data_Buy_Roll_Up
          });
          dispatch({
            type: "SET_DOWNLOAD_DATA_FIRST",
            payload: resp.data.url
          });
          // dispatch({
          //   type: "SET_DOWNLOAD_DATA_SECOND",
          //   payload: resp.data.url2
          // });
          // dispatch({
          //   type: "SET_DOWNLOAD_DATA_THIRD",
          //   payload: resp.data.url3
          // });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },

  clearRollUpDetails: () => {
    return dispatch => {
      dispatch({
        type: "CLEAR_ROLL_UP_DETAILS"
      });
    };
  },
  getBuyRollUpPlan: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/getProjectsDataBuyRollUp",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_PROJECT_LIST",
            payload: resp.data
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  }
};

export default buyRollUpActions;

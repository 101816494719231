import { toUnitEmptyData } from "../../../../helpers/formatters";

export default function averageDepthAndChoiceColumnDefs(clusterData, clusters) {
  let arr = [];
  arr.push({
    rowGroup: true,
    headerName: "CLASS",
    field: "class/vendor",
    filter: "agTextColumnFilter",
    menuTabs: [],
    pinned: "left",
    width: 150,
    hide: true,
    unSortIcon: true,
    sortable: true
  });
  arr.push({
    headerName: "METRIC",
    field: "metric",
    filter: "agTextColumnFilter",
    menuTabs: [],
    width: 130,
    tooltipField: "metric",
    unSortIcon: true,
    sortable: true
  });

  clusters.forEach(cluster => {
    arr.push({
      headerName: +cluster === 0 ? "Ecom" : `CL ${cluster}`,
      width: 85,
      sortable: true,
      menuTabs: [],
      unSortIcon: true,
      valueFormatter: toUnitEmptyData,
      field: `Cluster${cluster}`
    });
  });
  return arr;
}

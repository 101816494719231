import React, { Component } from "react";
import MonthPicker from "@wojtekmaj/react-daterange-picker";

class MonthRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null
    };
  }
  
  componentDidMount() {
    const clearButton = document.querySelector('.react-daterange-picker__clear-button')

    if (clearButton) {
      clearButton.addEventListener('click', this.handleMonthPickerClearButtonClick)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.props.clearMonthPickerData)
  }
  
  render() {
    return (
      <MonthPicker
        disabled={this.props.disabled}
        onChange={this.props.onSelectedMonthRange}
        // format="MMM/y"
        value={this.props.date}
        selectRange={true}
        view="year"
        isOpen={this.props.isOpen}
        onClickMonth={(val, event) => {
          // this.setState({date: val})
          this.props.onMonthClicked(val)
        }}
        style={{ width: "450px", height: "36" }}
        returnValue="range"
        maxDetail="year"
        monthPlaceholder="Month"
        yearPlaceholder="Year"
      />
    );
  }
}

export default MonthRangePicker;
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import Alert from "../../services/alert";
import DataTable from "../../services/dataTable";
import DashboardColDefs from "./columnDefs";
import userRoles from "../../constants/userRoles";
import Tippy from "@tippy.js/react";
import { stages } from "../../constants/planStages";

import projectActions from "../../actions/ProjectActions";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    if (!window.localStorage.getItem("userType")) {
      props.history.push("/login");
      window.localStorage.removeItem("token");
    }
  }
  state = {
    projects: [],
    userType: window.localStorage.getItem("userType"),
    isDashboardDeleteVisible: false
  };
  componentDidMount() {
    this.props.getProjects().then(resp => {
      if (resp) {
        resp.data.map(
          d => (d.project_stage += ` (${stages[d.project_stage]})`)
        );
        this.setState({
          projects: resp.data
        });
      }
    });
  }

  onCellClicked = event => {
    if (event.colDef.headerName === "ASSORTMENT PLAN NAME") {
      if (event && event.data && event.data.id) {
        this.props.history.push(`/stepperContainer/${event.data.id}`);
      }
    }
  };

  onCreatePlan = () => {
    this.props.history.replace(`/stepperContainer`);
  };
  onDeleteDashboardProjects = () => {
    this.gridApi.getSelectedRows().length &&
      this.setState({
        isDashboardDeleteVisible: true
      });
  };
  handleAccept = () => {
    var selectedIds = [];
    this.gridApi.getSelectedRows().forEach(dashboardProjectRow => {
      selectedIds.push(dashboardProjectRow.id);
      this.state.projects.forEach((dashboardProjectObj, index) => {
        if (dashboardProjectRow.id === dashboardProjectObj.id) {
          this.state.projects.push(this.state.projects.splice(index, 1)[0]);
        }
      });
    });
    this.setState({
      isDashboardDeleteVisible: false
    });
    let payload = {
      projectIds: selectedIds,
      clusterIds: []
    };
    this.props.deleteProjectOrClusterById(payload).then(resp => {
      if (resp) {
        resp.data.map(
          d => (d.project_stage += ` (${stages[d.project_stage]})`)
        );
        this.setState({
          projects: resp.data
        });
      }
    });
  };

  render() {
    return (
      <div className="card">
        <div className="cardHeaderStep4 dashboardLabelAndButton">
          <label style={{ paddingTop: "0.95rem" }}>Dashboard</label>
          {this.state.userType &&
            userRoles[this.state.userType]["1"]["1"].WRITE && (
              <div className="actionSectionRightAligned">
                <span style={{ paddingTop: "0.95rem", fontSize: "14px" }}>
                  Actions:{" "}
                </span>
                <Button
                  style={{ width: "10rem" }}
                  size="medium"
                  onClick={this.onCreatePlan}
                >
                  Create Plan
                </Button>
                <Tippy
                  placement={"top"}
                  arrow={true}
                  distance={10}
                  content={
                    "Please select an Assortment Plan to use this option"
                  }
                >
                  <Button
                    style={{ width: "5rem" }}
                    size="medium"
                    onClick={() => this.onDeleteDashboardProjects()}
                  >
                    Delete
                  </Button>
                </Tippy>
                {/* <i
              className="faactions fa fa-trash p-2 pointer"
              aria-hidden="true"
              style={{ color: "red", marginTop: "1rem" }}
            /> */}
              </div>
            )}
        </div>
        <div className="card-body">
          <div
            // className="mt-4"
            style={{
              height: "calc(100vh - 200px)"
            }}
          >
            <DataTable
              floatingFilter={true}
              sizeToFit={true}
              suppressColumnVirtualisation={true}
              // height1="calc(100vh - 300px)"
              columnDefs={DashboardColDefs.columnDefs}
              defaultColDef={DashboardColDefs.defaultColDef}
              rowData={this.state.projects}
              onCellClicked={this.onCellClicked}
              passGridAPI={api => {
                this.gridApi = api;
              }}
              rowSelection="multiple"
            />
          </div>
          <Alert
            open={this.state.isDashboardDeleteVisible}
            title="Alert"
            isResetRequired={false}
            content="Are you sure you want to delete the Assortment project(s)?"
            handleAccept={this.handleAccept}
            actionButtonText="Yes"
            cancelButtonText="No"
            handleClose={() => {
              this.setState({
                isDashboardDeleteVisible: false
              });
            }}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getProjects: projectActions.getProjects,
  deleteProjectOrClusterById: projectActions.deleteProjectOrClusterById
};

export default connect(null, mapDispatchToProps)(Dashboard);

import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import VendorAssistance from "./VendorAssistance";
import BrickAndMortor from "./BrickAndMortor";
import ProgramLevel from "./ProgramLevel";
import ItemLevel from "./ItemLevel";
// import ChannelSummarySimulation from "./ChannelSummarySimulation";
import buyOptimizationAction from "../../../actions/BuyOptimizationActions";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class SimulateAndFinalize extends Component {
  state = {
    giveBackPercentage: 0
  };
  setGiveBackPercentage = val => {
    this.setState({
      giveBackPercentage: val
    });
  };

  downloadExcelSAF = () => {
    let payload = {
      projectId: this.props.match.params.id,
      data: this.props.itemLevelSummary
    };
    if (this.props.match.params.id) {
      this.props.saveItemLevel(payload).then(resp => {
        if (resp.success) {
          this.props
            .downloadSimulateAndFinalize(this.props.projectId)
            .then(resp => {
              if (resp.success) {
                // console.log(resp);
                let a2 = document.createElement("A");
                a2.href = this.props.downloadSimulateAndFinalizeURL.url;
                // a2.setAttribute("target", "_blank");
                document.body.appendChild(a2);
                a2.click();
                document.body.removeChild(a2);
              }
            });
        }
      });
    }
  };
  render() {
    return (
      <div className="card p-3">
        <div className="card-header-background mb-3">
          <strong>Simulate & Finalize</strong>
        </div>
        {/* <div className="actionItemLevelButton">
          <label className="fileContainer" htmlFor="upload-file">
            <input
              value=" Save & Download"
              type="button"
              id="upload-file"
              onClick={() => this.downloadExcelSAF()}
            />

            <i
              className="faactions fa fa-download p-2 pointer"
              aria-hidden="true"
            />
          </label>
        </div> */}
        <Tabs
          defaultActiveKey="vendorAssistance"
          transition={false}
          id="noanim-tab-example"
        >
          <Tab
            eventKey="vendorAssistance"
            title="Vendor Assistance"
            className="p-3"
          >
            <VendorAssistance
              userDisableAccess={this.props.userDisableAccess}
              channelSummary={this.props.channelSummary}
              setGiveBackPercentage={this.setGiveBackPercentage}
              constrained={this.props.constrained}
            />
          </Tab>
          <Tab eventKey="brickAndMortor" title="B & M" className="p-3">
            <div>
              <BrickAndMortor
                brickAndMortarSummary={this.props.brickAndMortarSummary}
                lyFlag={this.props.lyflag}
              />
            </div>
          </Tab>
          <Tab eventKey="programLevel" title="Subgroup Level" className="p-3">
            <div>
              {/* <center>WIP2</center> */}
              <ProgramLevel
                programLevelSummary={this.props.programLevelSummary}
              />
            </div>
          </Tab>
          <Tab eventKey="itemLevel" title="Item Level" className="p-3">
            <div>
              <ItemLevel
                selectedPrimaryAttributes={this.props.selectedPrimaryAttributes}
                userDisableAccess={this.props.userDisableAccess}
                itemLevelSummary={this.props.itemLevelSummary}
              />
            </div>
          </Tab>
          {/* <Tab
            eventKey="channelSummary"
            title="Channel Summary"
            className="p-3"
          >
            <ChannelSummary
              userDisableAccess={this.props.userDisableAccess}
              channelSummary={this.props.channelSummary}
              giveBackPercentage={this.state.giveBackPercentage}
              constrained={this.props.constrained}
            />
          </Tab> */}
        </Tabs>
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    downloadSimulateAndFinalizeURL:
      store.buyOptimizationReducer.simulateAndFinalizeDownloadURL,
    projectId: store.projectReducer.projectId,
    selectedPrimaryAttributes:
      store.projectReducer.projectDetails.selectedPrimaryAttributes,

    brickAndMortarSummary: store.buyOptimizationReducer.brickAndMortarSummary,
    programLevelSummary: store.buyOptimizationReducer.programLevelSummary,
    itemLevelSummary: store.buyOptimizationReducer.itemLevelSummary,
    channelSummary: store.buyOptimizationReducer.channelSummary
  };
};
const mapActionsToProps = {
  downloadSimulateAndFinalize:
    buyOptimizationAction.downloadSimulateAndFinalize,
  saveItemLevel: buyOptimizationAction.saveItemLevel
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(SimulateAndFinalize));

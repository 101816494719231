import React from "react";
import { Line } from "react-chartjs-2";
import "./ChartJS.css";

const LineChartComponent = props => {
  // console.log("------------------------------LineChart props", props);
  let lyOrLly = props.lyFlag ? "LY" : "LLY";
  let lineDataAPS = {
    labels: props.data && props.data.ly_aps && props.data.ly_aps.Style_Rank,
    datasets: [
      {
        pointRadius: 1,
        pointHoverRadius: 1,
        borderColor: "rgb(79, 153, 171)",
        backgroundColor: "rgb(79, 153, 171)",
        label: "TY APS",
        data: props.data && props.data.ly_aps && props.data.ly_aps["TY APS"],
        fill: false
      },
      {
        borderColor: "#e681ff",
        backgroundColor: "#e681ff",
        pointRadius: 1,
        pointHoverRadius: 1,
        label: `${lyOrLly} APS`,
        data: props.data && props.data.ly_aps && props.data.ly_aps["LY_aps"],
        fill: false
      }
    ]
  };

  let lineDataAdjustedAPS = {
    labels:
      props.data &&
      props.data.ly_adjusted_aps &&
      props.data.ly_adjusted_aps &&
      props.data.ly_adjusted_aps.Style_Rank,

    datasets: [
      {
        pointRadius: 1,
        pointHoverRadius: 1,

        backgroundColor: "rgb(79, 153, 171)",
        borderColor: "rgb(79, 153, 171)",
        label: "TY Adjusted APS",
        data:
          props.data &&
          props.data.ly_adjusted_aps &&
          props.data.ly_adjusted_aps &&
          props.data.ly_adjusted_aps["TY Adjusted APS"],
        fill: false
      },
      {
        pointRadius: 1,
        pointHoverRadius: 1,
        borderColor: "#e681ff",
        backgroundColor: "#e681ff",
        label: `${lyOrLly} Adjusted APS`,
        data:
          props.data &&
          props.data.ly_adjusted_aps &&
          props.data.ly_adjusted_aps &&
          props.data.ly_adjusted_aps["LY Adjusted aps"],
        fill: false
      }
    ]
  };
  let getLineData = () => {
    // console.log("called", props.chartName);
    switch (props.chartName) {
      case "ly_aps":
        return lineDataAPS;
      case "ly_adjusted_aps":
        return lineDataAdjustedAPS;
      default:
        return props.data;
    }
  };
  return <Line options={props.options} data={getLineData()} />;
};

export default LineChartComponent;

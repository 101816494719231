export default obj => {
  obj.rcpts_per_door_per_cc = parseInt(
    parseFloat(obj.rcpt_u / obj.loc_count)
      .toString()
      .split(".")[0],
    10
  );
  obj.u_turn = obj.sales_u / (obj.oh_tot / obj.u_turn_denom);
  obj.sls_u_per_door = parseInt(
    parseFloat(obj.sales_u / obj.loc_count)
      .toString()
      .split(".")[0],
    10
  );
  obj.rtl_per_door = parseInt(
    parseFloat(obj.sales_rtl / obj.loc_count)
      .toString()
      .split(".")[0],
    10
  );
  obj.sales_rtl_lost_sales = parseInt(
    parseFloat(obj.sales_rtl_lost_sales / obj.loc_count)
      .toString()
      .split(".")[0],
    10
  );
  obj.u_rec_depth = parseInt(
    parseFloat(obj.u_rec_depth / obj.loc_count)
      .toString()
      .split(".")[0],
    10
  );

  obj.gm_per_door = parseInt(
    parseFloat(obj.gm / obj.loc_count)
      .toString()
      .split(".")[0],
    10
  );
  obj.gm_rate = (obj.gm / obj.sales_rtl) * 100;
  obj.gm_asst_per_door = parseInt(
    parseFloat(obj.gm_asst / obj.loc_count)
      .toString()
      .split(".")[0],
    10
  );
  obj.gm_asst_rate = (obj.gm_asst / obj.sales_rtl) * 100;
  obj.aur = obj.sales_rtl / obj.sales_u;
  obj.aps = obj.sales_u / obj.weeks_active;
  obj.aps_gm = obj.gm / obj.weeks_active;
  obj.aps_gm_asst = obj.gm_asst / obj.weeks_active;
  // obj.sell_through = (100 * obj.sales_u) / (obj.eop_u + obj.sales_u);
  obj.sell_through = (100 * obj.sales_u) / (obj.bop_u + obj.rcpt_qty_st);
  obj.avg_weeks_active = parseInt(
    parseFloat(obj.weeks_active / obj.choice)
      .toString()
      .split(".")[0],
    10
  );
  obj.choice = parseInt(
    parseFloat(obj.choice / obj.loc_count)
      .toString()
      .split(".")[0],
    10
  );

  //Condition Checks
  obj.rcpt_u = obj.rcpt_u <= 0 ? 1 : obj.rcpt_u;
  obj.u_turn_denom = obj.u_turn_denom <= 0 ? 1 : obj.u_turn_denom;
  obj.u_turn = obj.u_turn < 0 ? 0 : obj.u_turn;
  obj.oh_tot = obj.oh_tot <= 0 ? 0 : obj.oh_tot;
  obj.bop_u = obj.bop_u <= 0 ? 0 : obj.bop_u;
  obj.eop_u = obj.eop_u <= 0 ? 0 : obj.eop_u;
  obj.sales_u = obj.sales_u <= 0 ? 0 : obj.sales_u;
  obj.sales_rtl = obj.sales_rtl < 0 ? 0 : obj.sales_rtl;
  obj.gm = obj.gm < 0 ? 0 : obj.gm;
  obj.gm_rate = obj.gm_rate < 0 ? 0 : obj.gm_rate > 100 ? 100 : obj.gm_rate;
  obj.gm_asst = obj.gm_asst < 0 ? 0 : obj.gm_asst;
  obj.gm_asst_rate =
    obj.gm_asst_rate < 0 ? 0 : obj.gm_asst_rate > 100 ? 100 : obj.gm_asst_rate;
  obj.choice = obj.choice <= 0 ? 1 : obj.choice;
  obj.weeks_active = obj.weeks_active <= 0 ? 1 : obj.weeks_active;
  obj.aur = obj.aur < 0 ? 0 : obj.aur;
  obj.aps = obj.aps < 0 ? 0 : obj.aps;
  obj.aps_gm = obj.aps_gm < 0 ? 0 : obj.aps_gm;
  obj.aps_gm_asst = obj.aps_gm_asst < 0 ? 0 : obj.aps_gm_asst;
  obj.sell_through =
    obj.sell_through < 0 ? 0 : obj.sell_through > 100 ? 100 : obj.sell_through;
  return obj;
};

const assortmentPlanningReducerDefaultState = {
  lineReceiptData: [],
  dataChanged1: false,
  dataChanged2: false,
  dataChanged3: false,
  dataChanged4: false,
  deleteMapping: false,
  lineReceiptMappingData: [],
  budgetProportions: {},
  plannedBudget: [],
  userEditableLineReceipt: [],
  rcptMonths: [],
  lineReceiptlevel: "",
  lineReceiptHighlevel: "",
  lineReceiptVenGrouplevel: "",
  lineReceiptOtbMonths: [],
  lineReceiptGrandTotal: {},

  lineArchitectureDetails: {},

  // plannerTotalBudget: null,
  styleDataForClass: [],

  clusterAndParameterDetails: {},

  // clusterData: [],
  // subClassRcptPenTable: [],
  historicalParameterData: {},
  clusters: [],
  maxSellThrough: 80,
  minSellThrough: 50,
  // goodBetterBestTable: [],

  selectedAutoAPXClass: null,
  clusterSummaryTable: [],
  sourceMapping: [],
  targetMapping: [],
  savedSourceMapping: [],
  savedTargetMapping: [],
  nonCompLevel: "",
  venGroupOptions: []
};

export default (state = assortmentPlanningReducerDefaultState, action) => {
  switch (action.type) {
    case "SET_USER_EDITABLE_LINE_RECEIPT_DATA":
      return {
        ...state,
        userEditableLineReceipt: action.payload
      };
    case "SET_LINE_RECEIPT_DATA":
      return {
        ...state,
        lineReceiptData: action.payload
      };
    case "SET_LINE_RECEIPT_MAPPING_DATA":
      return {
        ...state,
        lineReceiptMappingData: action.payload
      };
    case "SET_LINE_RECEIPT_BUDGET_PROPORTIONS":
      return {
        ...state,
        budgetProportions: action.payload
      };
    case "SET_LINE_RECEIPT_PLANNED_BUDGET":
      return {
        ...state,
        plannedBudget: action.payload
      };
    case "SET_LINE_RECEIPT_MONTHS":
      return {
        ...state,
        rcptMonths: action.payload
      };
    case "SET_LINE_RECEIPT_LEVEL":
      return {
        ...state,
        lineReceiptlevel: action.payload
      };
    case "SET_LINE_RECEIPT_HIGH_LEVEL":
      return {
        ...state,
        lineReceiptHighlevel: action.payload
      };
    case "SET_LINE_RECEIPT_VEN_GROUP_LEVEL":
      return {
        ...state,
        lineReceiptVenGrouplevel: action.payload
      };
    case "SET_LINE_RECEIPT_OTB_MONTHS":
      return {
        ...state,
        lineReceiptOtbMonths: action.payload
      };
    case "SET_LINE_RECEIPT_GRAND_TOTAL":
      return {
        ...state,
        lineReceiptGrandTotal: action.payload
          ? action.payload
          : state.lineReceiptGrandTotal
      };

    case "SET_LINE_ARCHITECTURE_DETAILS":
      return {
        ...state,
        lineArchitectureDetails: action.payload
      };
    case "SET_BUYER_TOTAL_BUDGET":
      return {
        ...state,
        lineArchitectureDetails: {
          ...state.lineArchitectureDetails,
          [action.payload.month]: {
            ...state.lineArchitectureDetails[action.payload.month],
            buyerTotalBudget: action.payload.value
          }
        }
      };
    // case "SET_PLANNER_TOTAL_BUDGET":
    //   return {
    //     ...state,
    //     plannerTotalBudget: action.payload
    //   };
    case "SET_LINE_ARCHITECTURE_DATA":
      return {
        ...state,
        lineArchitectureDetails: {
          ...state.lineArchitectureDetails,
          [action.payload.month]: {
            ...state.lineArchitectureDetails[action.payload.month],
            data: action.payload.value
          }
        }
      };
    case "SET_MAIN_LINE_ARCHITECTURE_DATA":
      return {
        ...state,
        lineArchitectureDetails: {
          ...state.lineArchitectureDetails,
          [action.payload.month]: {
            ...state.lineArchitectureDetails[action.payload.month],
            main_data: action.payload.value
          }
        }
      };
    case "GET_STYLES_DATA_FOR_CLASS":
      return {
        ...state,
        styleDataForClass: action.payload
      };
    case "SET_CLUSTER_AND_PARAMETER_DETAILS":
      return {
        ...state,
        clusterAndParameterDetails: action.payload
      };
    case "SET_CLUSTER_DATA":
      return {
        ...state,
        clusterAndParameterDetails: {
          ...state.clusterAndParameterDetails,
          [action.payload.month]: {
            ...state.clusterAndParameterDetails[action.payload.month],
            clusterData: action.payload.value
          }
        }
      };
    // case "SET_SUB_CLASS_RCPT_PENE_TABLE":
    //   return {
    //     ...state,
    //     subClassRcptPenTable: action.payload
    //   };
    case "SET_HISTORICAL_PARAMETERS_DATA":
      return {
        ...state,
        clusterAndParameterDetails: {
          ...state.clusterAndParameterDetails,
          [action.payload.month]: {
            ...state.clusterAndParameterDetails[action.payload.month],
            historicalParameterData: action.payload.value
          }
        }
      };
    // case "SET_CLUSTERS":
    //   return {
    //     ...state,
    //     clusters: action.payload
    //   };
    case "SET_MAX_SELL_THROUGH":
      return {
        ...state,
        clusterAndParameterDetails: {
          ...state.clusterAndParameterDetails,
          [action.payload.month]: {
            ...state.clusterAndParameterDetails[action.payload.month],
            maxSellThrough: action.payload.value
          }
        }
      };
    case "SET_MIN_SELL_THROUGH":
      return {
        ...state,
        clusterAndParameterDetails: {
          ...state.clusterAndParameterDetails,
          [action.payload.month]: {
            ...state.clusterAndParameterDetails[action.payload.month],
            minSellThrough: action.payload.value
          }
        }
      };
    case "SET_HISTORICAL_PARAM_SUBCLASS":
      return {
        ...state,
        clusterAndParameterDetails: {
          ...state.clusterAndParameterDetails,
          [action.payload.month]: {
            ...state.clusterAndParameterDetails[action.payload.month],
            historicalParameterData: {
              ...state.clusterAndParameterDetails[action.payload.month]
                .historicalParameterData,
              [action.payload.classSubClassKey]: action.payload.value
            }
          }
        }
      };
    // case "SET_GOOD_BETTER_BEST_TABLE":
    //   return {
    //     ...state,
    //     goodBetterBestTable: action.payload
    //   };
    case "SET_SELECTED_AUTO_APX_CLASS":
      return {
        ...state,
        selectedAutoAPXClass: action.payload
      };
    case "SET_CLUSTER_SUMMARY_TABLE":
      return {
        ...state,
        clusterSummaryTable: action.payload
      };
    case "CLEAR_ASSORTMENT_PLANNING_DATA":
      return {
        ...assortmentPlanningReducerDefaultState
      };
    case "SET_MAPPING_FOR_SOURCE":
      return {
        ...state,
        sourceMapping: action.payload
      };
    case "SET_MAPPING_FOR_TARGET":
      return {
        ...state,
        targetMapping: action.payload
      };
    case "SET_NON_COMP_SOURCE_DATA":
      return {
        ...state,
        savedSourceMapping: action.payload
      };
    case "SET_NON_COMP_TARGET_DATA":
      return {
        ...state,
        savedTargetMapping: action.payload
      };
      case "SET_NON_COMP_LEVEL":
      return {
        ...state,
        nonCompLevel: action.payload
      };
    case "SET_DELETE_MAPPING_FLAG":
      return {
        ...state,
        deleteMapping: action.payload
      };
    case "SET_VEN_GROUP_OPTIONS":
      return {
        ...state,
        venGroupOptions: action.payload
      };
    case "SET_DATA_CHANGED_IN_ASSORTMENT_1":
      return {
        ...state,
        dataChanged1: action.payload
      };
    case "SET_DATA_CHANGED_IN_ASSORTMENT_2":
      return {
        ...state,
        dataChanged2: action.payload
      };
    case "SET_DATA_CHANGED_IN_ASSORTMENT_3":
      return {
        ...state,
        dataChanged3: action.payload
      };
    case "SET_DATA_CHANGED_IN_ASSORTMENT_4":
      return {
        ...state,
        dataChanged4: action.payload
      };
    default:
      return state;
  }
};

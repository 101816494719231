import Button from "@material-ui/core/Button";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";
import { isEqual } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import buyOptimizationAction from "../../actions/BuyOptimizationActions";
import Alert from "../../services/alert";
import BuyInput from "./Step1BuyInput";
import SimulateAndFinalize from "./Step2SimulateAndFinalize";
import OmniBuyEstimation from "./Step3OmniBuyEstimation";

class BuyOptimization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: props.intitialActiveStep,
      constrained: false,
      isNextAlertVisible: false,
      steps: ["Buy Input", "Simulate & Finalize Budget", "Omni Buy Estimation"],
      optimizeBtnEnable: true,
      omniRcptPlanDollarFlag: !props.omniRcptPlanDollarFlag ? false : true
    };
  }
  componentDidUpdate(oldProps) {
    if (this.props.stage && oldProps.stage !== this.props.stage) {
      this.setState({
        activeStep: this.props.stage === 3 ? this.props.subStage - 1 : 0
      });
    }
  }
  enableOptimizeBtn = () => {
    if (this.state.optimizeBtnEnable === true) {
      this.setState({
        optimizeBtnEnable: false
      });
    }
  };
  isOmniRcptPlanDollarFlag = flag => {
    this.setState({
      omniRcptPlanDollarFlag: flag
    });
  };
  isCreateFlow = () => {
    return (
      this.props.stage === 3 &&
      this.state.activeStep === this.props.subStage - 1
    );
  };
  isNextDisabledforUser = () => {
    return (
      this.isCreateFlow() &&
      !this.props.userAccess[`${this.state.activeStep + 1}`].WRITE
    );
  };
  onNextClick = () => {
    this.isCreateFlow()
      ? this.handleNext()
      : this.state.activeStep === 2
      ? this.props.history.push("/dashboard")
      : this.setState({ activeStep: this.state.activeStep + 1 });
  };
  handleNext = () => {
    switch (this.state.activeStep) {
      case 0:
        this.optimizeBuy().then(resp => {
          if (resp.success) {
            this.props.saveProjectStage(3, 2).then(resp => {
              resp.success && this.setState({ activeStep: 1 });
            });
          }
        });
        break;
      case 1:
        this.saveItemTable().then(resp => {
          if (resp.success) {
            this.props.runOmniBuyAlgo(false).then(resp => {
              resp.success && this.setState({ activeStep: 2 });
            });
          }
        });
        break;
      case 2:
        if (!this.props.omniBuyEstimation.every(e => e.action)) {
          toast.error("Please assign action to all styles", {
            autoClose: false
          });
        } else {
          this.props
            .saveOmniBuyEstimation({
              projectId: this.props.match.params.id,
              data: this.props.omniBuyEstimation,
              omniIncr: +this.props.omniIncr || 0,
              omniRcpt: +this.props.omniRcpt || 0,
              isBuyOptSkipped: this.props.isBuyOptSkipped
            })
            .then(resp => {
              if (resp.success) {
                this.downloadAutoAPX().then(resp => {
                  if (resp.success) {
                    this.props.saveProjectStage(3, 3, null, true).then(resp => {
                      resp.success && this.props.history.push("/dashboard");
                    });
                  }
                });
              }
            });
        }
        break;
      default:
        break;
    }
  };
  hasDataChanged = () => {
    let hasDataChanged = false;
    switch (this.state.activeStep) {
      case 0:
        hasDataChanged = !isEqual(
          this.props.tempBoGraphDataProp,
          this.props.boGraphDataProp
        );
        break;
      case 1:
        hasDataChanged = false;
        break;
      default:
        break;
    }
    return hasDataChanged;
  };
  handleBack = () => {
    // this.setState({ activeStep: this.state.activeStep - 1 });
    if (this.props.isBuyOptSkipped) {
      this.props.updateActiveTab(1, 4);
    }
    if (this.state.activeStep !== 0) {
      this.setState({ activeStep: parseInt(this.state.activeStep) - 1 });
    } else this.props.updateActiveTab(1, 4);
  };
  handleStep = step => {
    this.setState({ activeStep: step });
  };
  optimizeBuy = () => {
    let payload = {
      projectId: this.props.match.params.id,
      constrained: !!this.props.constrained ? 1 : 0,
      omni_rcpt_plan_dollar: this.state.omniRcptPlanDollarFlag
    };
    // console.log(payload, "opt");
    return this.props.runOptimizeBuyAlgo(payload, "opt");
  };
  saveItemTable = () => {
    let payload = {
      projectId: this.props.match.params.id,
      data: this.props.itemLevelSummary
    };
    return this.props.saveItemLevel(payload);
  };
  getStepContent = stepIndex => {
    const userDisableAccess = !this.props.userAccess[
      `${this.state.activeStep + 1}`
    ].WRITE;

    switch (stepIndex) {
      case 0:
        return (
          <BuyInput
            userDisableAccess={userDisableAccess}
            boInputDataStyleColorLevel={this.props.boInputDataStyleColorLevel}
            boInputDataStyleLevel={this.props.boInputDataStyleLevel}
            constrained={this.props.constrained}
            boGraphData={this.props.boGraphData}
            enableOptimizeBtn={this.enableOptimizeBtn}
            saveProjectStage={this.props.saveProjectStage}
            omniIncr={this.props.omniIncr}
            omniRcpt={this.props.omniRcpt}
            lyFlag={this.props.lyFlag}
            omniRcptPlanDollarFlag={this.state.omniRcptPlanDollarFlag}
            isOmniRcptPlanDollarFlag={this.isOmniRcptPlanDollarFlag}
          />
        );
      case 1:
        return (
          <SimulateAndFinalize
            userDisableAccess={userDisableAccess}
            constrained={this.props.constrained}
            lyFlag={this.props.lyFlag}
          />
        );
      case 2:
        return (
          <OmniBuyEstimation
            lineReceiptlevel={this.props.lineReceiptlevel}
            isFcApproved={this.props.isFcApproved}
            omniRcptPlanDollarFlag={this.state.omniRcptPlanDollarFlag}
            aucThreshold={this.props.aucThreshold}
            downloadAutoAPX={this.downloadAutoAPX}
            isBuyOptSkipped={this.props.isBuyOptSkipped}
          />
        );
      default:
        return "Uknown stepIndex";
    }
  };
  downloadAutoAPX = () => {
    let payload = {
      projectId: this.props.match.params.id,
      omniIncr: +this.props.omniIncr || 0,
      omniRcpt: +this.props.omniRcpt || 0,
      hasAttributeClustering: this.props.hasAttributeClustering,
      isBuyOptSkipped: this.props.isBuyOptSkipped
    };
    return this.props.downloadAutoAPX(payload);
  };

  runOmniBuyAlgo = () => {
    let skipBuy = false;
    this.props.runOmniBuyAlgo(skipBuy);
  };

  disabledStepButton = index => {
    if (
      (this.props.stage === 3 && index >= this.props.subStage) ||
      (this.props.isBuyOptSkipped && this.state.activeStep === 1) ||
      (this.props.isBuyOptSkipped && this.state.activeStep === 2)
    )
      return true;
  };
  render() {
    return (
      <div>
        <Stepper
          nonLinear
          activeStep={this.state.activeStep}
          alternativeLabel
          style={{ backgroundColor: "transparent" }}
        >
          {this.state.steps.map((label, index) => (
            <Step key={label}>
              <StepButton
                disabled={this.disabledStepButton(index)}
                onClick={() => this.handleStep(index)}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          {/* <Typography className="mt-3"> */}
          {this.getStepContent(this.state.activeStep)}
          {/* </Typography> */}
          <div className="mt-3">
            <Button
              // disabled={this.state.activeStep === 0}
              onClick={this.handleBack}
            >
              Back
            </Button>
            {(this.props.isBuyOptSkipped ? true : this.props.boGraphData) && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.onNextClick}
                disabled={
                  this.props.isBuyOptSkipped
                    ? false
                    : !this.props.boGraphData || this.isNextDisabledforUser()
                }
              >
                {
                  {
                    0: this.isCreateFlow() ? "Optimize Buy" : "Next",
                    1: "Next",
                    2: "Finish"
                  }[this.state.activeStep]
                }
              </Button>
            )}
          </div>
        </div>
        <Alert
          open={this.state.isNextAlertVisible}
          title="Alert"
          isResetRequired={false}
          content="Changes have been noticed. Clicking on continue preceding steps data will be cleared."
          handleAccept={this.handleNext}
          // handleReset={this.handleReset}
          cancelButtonText="Cancel"
          actionButtonText="Continue"
          handleClose={() => {
            this.setState({
              isNextAlertVisible: false
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    boInputDataStyleColorLevel:
      store.buyOptimizationReducer.boInputDataStyleColorLevel,
    boInputDataStyleLevel: store.buyOptimizationReducer.boInputDataStyleLevel,
    boGraphData: store.buyOptimizationReducer.boGraphData,
    constrained: store.buyOptimizationReducer.constrained,
    stage: store.projectReducer.projectMainStage,
    subStage: store.projectReducer.projectSubStage,
    tempBoGraphDataProp: store.mainReducer.tempCompareAPSGraphData,
    itemLevelSummary: store.buyOptimizationReducer.itemLevelSummary,
    omniIncr: store.buyOptimizationReducer.omniIncr,
    omniRcpt: store.buyOptimizationReducer.omniRcpt,
    omniBuyEstimation: store.buyOptimizationReducer.omniBuyEstimation,
    isFcApproved: store.buyOptimizationReducer.isFcApproved,
    aucThreshold: store.buyOptimizationReducer.aucThreshold,
    lyFlag: store.projectReducer.lyFlag,
    hasAttributeClustering:
      store.projectReducer.projectDetails.hasAttributeClustering,
    isBuyOptSkipped: store.projectReducer.projectDetails.isBuyOptSkipped,
    lineReceiptlevel: store.assortmentPlanningReducer.lineReceiptlevel,
    omniRcptPlanDollarFlag:
      store.projectReducer.projectDetails.omni_rcpt_plan_dollar,
    selectedFilters: store.projectReducer.projectDetails.selected_filters
  };
};
const mapActionsToProps = {
  runOptimizeBuyAlgo: buyOptimizationAction.runOptimizeBuyAlgo,
  saveItemLevel: buyOptimizationAction.saveItemLevel,
  runOmniAlgo: buyOptimizationAction.runOmniAlgo,
  setVendorForFcBuy: buyOptimizationAction.setVendorForFcBuy,
  downloadAutoAPX: buyOptimizationAction.downloadAutoAPX,
  saveOmniBuyEstimation: buyOptimizationAction.saveOmniBuyEstimation
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(BuyOptimization));

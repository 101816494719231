import {
  decimalFormatter$_withZero,
  percentageFormatterWithZero,
  toUnit
} from "../../../helpers/formatters";

export default {
  defaultColDef: {
    resizable: true,
    sortable: true,
    unSortIcon: true
  },
  columnDefs: [
    {
      headerName: "LOC ID",
      cellStyle: { "text-align": "center" },
      field: "loc_id"
    },
    {
      headerName: "SDF REG SLS",
      cellStyle: { "text-align": "center" },
      valueFormatter: decimalFormatter$_withZero,
      field: "sdf_reg_sls"
    },
    {
      headerName: "SDF REG SLSU",
      cellStyle: { "text-align": "center" },
      valueFormatter: toUnit,
      field: "sdf_reg_slsu"
    },
    {
      headerName: "SDF GM",
      cellStyle: { "text-align": "center" },
      valueFormatter: decimalFormatter$_withZero,
      field: "sdf_gm"
    },
    {
      headerName: "AUR",
      cellStyle: { "text-align": "center" },
      valueFormatter: decimalFormatter$_withZero,
      field: "aur"
    },
    {
      headerName: "GM RATE",
      cellStyle: { "text-align": "center" },
      valueFormatter: percentageFormatterWithZero,
      field: "gm_rate"
    }
  ]
};

export const miniScatterChartOptions = {
  legend: { display: false },
  tooltips: {
    callbacks: {
      title: function(tooltipItem, data) {
        return `${
          data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index]
            .storeName
        }`;
      },
      label: function(tooltipItem, data) {
        return ` ${
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].xLabel
        }: ${tooltipItem.xLabel.toFixed(2)}, ${
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].yLabel
        }: ${tooltipItem.yLabel.toFixed(2)}`;
      }
    }
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          lineWidth: 0,
          zeroLineWidth: 1,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          lineWidth: 0,
          zeroLineWidth: 1,
          drawBorder: false
        }
      }
    ]
  }
};

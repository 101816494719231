import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { LicenseManager } from "ag-grid-enterprise";
import { ToastContainer, toast } from "react-toastify";

import store from "./store";
import App from "./App";

import "react-toastify/dist/ReactToastify.css";
import "ag-grid-enterprise";
import "./index.css";
import "./style.scss";
import "./axios";
import "bootstrap/dist/css/bootstrap.min.css";

// AG-Grid Data Table Enterprise License
LicenseManager.setLicenseKey(
  "Evaluation_License-_Not_For_Production_Valid_Until_12_October_2019__MTU3MDgzNDgwMDAwMA==1581fb9d18de5a15031cbc2d036db827"
);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

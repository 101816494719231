import React, { Component } from "react";
import DataTable from "../../../services/dataTable";
import Tippy from "@tippy.js/react";
import "tippy.js/themes/light.css";
import { toast } from "react-toastify";
import update from "immutability-helper";
import Columns from "./colDefs";
import Columns_FCBuy from "./colDef_FCBuy";
import { connect } from "react-redux";
import buyOptimizationAction from "../../../actions/BuyOptimizationActions";
import { withRouter } from "react-router-dom";
import { cloneDeep, isEqual, merge, keyBy, values } from "lodash";
import _ from "lodash";
import DesignRespread from "../../../assests/design_4_respread.PNG";

class OmniBuyEstimation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overRideClicked: false,
      tempOmniBuyEstimation: cloneDeep(this.props.omniBuyEstimation)
    };
  }

  componentDidMount() {
    this.setTotalBMBuy();
  }

  componentDidUpdate(prevProps) {
    if (this.props.omniBuyEstimation !== prevProps.omniBuyEstimation) {
      this.setState({
        tempOmniBuyEstimation: cloneDeep(this.props.omniBuyEstimation)
      });
    }
  }

  setTempOmniBuyEstimation = () => {
    let totalBMArray = this.props.totalBMBuy;
    let merged = merge(
      keyBy(this.state.tempOmniBuyEstimation, "style_color_choice"),
      keyBy(totalBMArray, "CC")
    );
    let value = values(merged);
    this.props.setOmniBuyEstimation(value);
  };

  setTotalBMBuy = () => {
    this.props.downloadAutoAPX().then(resp => {
      if (resp.success) {
        this.setTempOmniBuyEstimation();
      }
    });
  };

  hasDataChanged = () => {
    let hasDataChanged = false;
    hasDataChanged = !isEqual(
      this.state.tempOmniBuyEstimation,
      this.props.omniBuyEstimation
    );
    return hasDataChanged;
  };

  downloadAutoAPX = () => {
    this.props.downloadAutoAPX().then(resp => {
      if (resp.success) {
        let a2 = document.createElement("A");
        a2.href = resp.data.url;
        // a2.setAttribute("target", "_blank");
        document.body.appendChild(a2);
        a2.click();
        document.body.removeChild(a2);

        this.setTempOmniBuyEstimation();
      }
    });
  };

  download = () => {
    let dataChanged = true //for now removing check.have to work on it later
    if (this.props.match.params.id) {
      if (!this.props.omniBuyEstimation.every(e => e.action)) {
        toast.error("Please assign action to all styles", {
          autoClose: false
        });
        return;
      }
      if (dataChanged) {
        this.props
          .saveOmniBuyEstimation({
            projectId: this.props.match.params.id,
            data: this.props.omniBuyEstimation,
            omniIncr: +this.props.omniIncr || 0,
            omniRcpt: +this.props.omniRcpt || 0,
            isBuyOptSkipped: this.props.isBuyOptSkipped
          })
          .then(resp => {
            if (resp.success) {
              this.downloadAutoAPX();
            }
          });
      } else {
        this.downloadAutoAPX();
      }
    }
  };

  onCellValueChangedVendorFcBuy = params => {
    if (params.colDef.field === "action") {
      let payload = [...this.props.omniBuyEstimation];
      const { isFcApproved } = this.props;
      this.props.omniBuyEstimation.forEach((element, idx) => {
        if (element.class_vendor.includes(params.data.vendor_desc)) {
          if (!isFcApproved) {
            const fcExceptionMBQFlag =
              +element.fc_unit_buy_adj_plan > +element.fc_mbq;
            if (fcExceptionMBQFlag) {
              payload = update(payload, {
                [idx]: {
                  action: { $set: params.newValue }
                }
              });
            }
          }
        }
      });
      this.props.setOmniBuyEstimation(payload);
    }
  };
  onCellValueChangedOmniBuy = params => {
    if (params.colDef.field === "fc_unit_override") {
      const { isFcApproved, omniRcptPlanDollarFlag } = this.props;
      if (omniRcptPlanDollarFlag) {
        params.data.fc_unit_buy_adj_plan = +params.newValue;
      } else {
        params.data.fc_unit_buy = +params.newValue;
      }
      if (!isFcApproved) {
        const fcExceptionMBQFlag =
          +params.data.fc_unit_buy_adj_plan > +params.data.fc_mbq;
        if (fcExceptionMBQFlag) {
          params.data.action = "FC Approved";
        }
      }

      params.api.refreshCells();
    }
    this.setState({
      overRideClicked: true
    });
  };
  saveOmniBuyEstimation = () => {
    if (!this.props.omniBuyEstimation.every(e => e.action)) {
      toast.error("Please assign action to all styles", {
        autoClose: false
      });
      return;
    }
    this.props
      .saveOmniBuyEstimation({
        projectId: this.props.match.params.id,
        data: this.props.omniBuyEstimation,
        omniIncr: +this.props.omniIncr || 0,
        omniRcpt: +this.props.omniRcpt || 0,
        isBuyOptSkipped: this.props.isBuyOptSkipped
      })
      .then(resp => this.setTotalBMBuy());
  };

  copyOverrideCasePack = e => {
    if (!this._delayedClick) {
      this._delayedClick = _.debounce(this.doClick, 1000);
    }
    if (this.clickedOnce) {
      this._delayedClick.cancel();
      this.clickedOnce = false;
    } else {
      this._delayedClick(e);
      this.clickedOnce = true;
    }
  };
  doClick(e) {
    this.clickedOnce = undefined;
    let omniData = cloneDeep(this.props.omniBuyEstimation);
    let copyValue = omniData[0].override_case_pack;
    omniData.map(e => (e.override_case_pack = copyValue));
    if (omniData && omniData.length > 1) {
      this.props.setOmniBuyEstimation(omniData);
      this.setTotalBMBuy();
    }
  }

  render() {
    return (
      <div className="card p-3" style={{ marginBottom: "12px" }}>
        {this.props.lineReceiptlevel !== "class" ? (
          <>
            <div className="card-header-background">
              <strong>Vendor Exception Selection</strong>
            </div>
            <div style={{ height: "220px", width: "53%", margin: "2rem auto" }}>
              <DataTable
                rowData={this.props.vendorForFCBuy}
                sizeToFit={true}
                defaultColDef={{ resizable: true }}
                columnDefs={Columns_FCBuy.columnDefs(this.props.isFcApproved)}
                onCellValueChanged={this.onCellValueChangedVendorFcBuy}
              />
            </div>
          </>
        ) : (
          ""
        )}
        <div className="flex-actions-omni-buy">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>
              <label>Max AUC</label>
              <input
                className="form-control"
                style={{ width: "7rem" }}
                disabled={true}
                placeholder="AUC Threshold"
                value={
                  this.props.aucThreshold ? "$ " + this.props.aucThreshold : ""
                }
              />
            </div>
            <div style={{ marginRight: "10px" }}>
              <label>FC approved</label>
              <input
                className="form-control"
                style={{ width: "7rem" }}
                disabled={true}
                placeholder="FC approved"
                value={this.props.isFcApproved ? "True" : "False"}
              />
            </div>
            <div style={{ marginRight: "10px" }}>
              <label>Dept. Penetration</label>
              <input
                className="form-control"
                style={{ width: "7rem" }}
                disabled={true}
                placeholder="Dept. Penetration"
                value={
                  this.props.deptPenetration &&
                  this.props.deptPenetration.toFixed(2) + "%"
                }
              />
            </div>
            <div style={{ marginRight: "10px" }}>
              <label>Season</label>
              <input
                className="form-control"
                style={{ width: "7rem" }}
                disabled={true}
                placeholder="Season"
                value={this.props.season ? this.props.season : ""}
              />
            </div>
            <div style={{ marginRight: "10px" }}>
              <label>
                {+this.props.omniIncr ? "Omni Increment" : "Omni Receipt"}
              </label>
              <input
                className="form-control"
                style={{ width: "7rem" }}
                disabled={true}
                placeholder={
                  +this.props.omniIncr ? "Omni Increment" : "Omni Receipt"
                }
                value={
                  (+this.props.omniIncr
                    ? +this.props.omniIncr + "%"
                    : "$ " + +this.props.omniRcpt) ||
                  (!this.props.omniRcptPlanDollarFlag
                    ? this.props.deptPenetration &&
                      this.props.deptPenetration.toFixed(2) + "%"
                    : +this.props.omniRcpt)
                }
              />
            </div>
            <div>
              <Tippy
                placement={"left"}
                arrow={true}
                distance={10}
                content={
                  <div>
                    Last month of the buy determines season that will drive if
                    FC Approved on Store Only for Buys that cross seasons
                  </div>
                }
              >
                <i
                  style={{
                    color: "#2d69eb"
                  }}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                />
              </Tippy>
            </div>
          </div>
          <div className="actionSectionRightAligned">
            <div
              style={{
                marginRight: "1rem",
                display: "flex",
                alignItems: "center"
              }}
            >
              <div>FC Respread Logic</div>
              <Tippy
                placement={"bottom"}
                arrow={true}
                theme={"light"}
                maxWidth={400}
                distance={10}
                content={
                  <div>
                    <img src={DesignRespread} alt="image of respread" />
                  </div>
                }
              >
                <i
                  style={{
                    color: "#2d69eb"
                  }}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                />
              </Tippy>
            </div>

            {/* <label className="fileContainer mr-2">
              <input
                value="Mass Override Case Pack"
                type="button"
                onClick={this.copyOverrideCasePack}
                title="Copy first Case Pack value to all rows"
                disabled={!this.state.overRideClicked}
              />
              <Tippy
                placement={"left"}
                arrow={true}
                distance={10}
                content={
                  <div>
                    On clicking first row value of override case pack applies to
                    all following rows
                  </div>
                }
              >
                <i
                  style={{
                    color: "#fff"
                  }}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                />
              </Tippy>
            </label> */}
            <label className="fileContainer mr-2">
              <input
                value="Respread to B&M And Save"
                type="button"
                onClick={this.saveOmniBuyEstimation}
                title="Save changes and recalculate"
              />
            </label>
            <label className="fileContainer">
              <input
                value="Download FC/B&M APX"
                type="button"
                onClick={this.download}
                title="Download"
              />
            </label>
          </div>
        </div>

        <div className="mt-3" style={{ height: "400px" }}>
          <DataTable
            rowData={this.props.omniBuyEstimation}
            columnDefs={Columns.columnDefs(
              this.props.selectedPrimaryAttributes,
              this.props.omniRcptDollarFlag
            )}
            defaultColDef={Columns.defaultColDef}
            resizable={true}
            passGridAPI={api => {
              this.gridApi = api;
            }}
            onCellValueChanged={this.onCellValueChangedOmniBuy}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    omniBuyEstimation: store.buyOptimizationReducer.omniBuyEstimation,
    totalBMBuy: store.buyOptimizationReducer.totalBMBuy,
    omniIncr: store.buyOptimizationReducer.omniIncr,
    omniRcpt: store.buyOptimizationReducer.omniRcpt,
    selectedPrimaryAttributes:
      store.projectReducer.projectDetails.selectedPrimaryAttributes,
    hasAttributeClustering:
      store.projectReducer.projectDetails.hasAttributeClustering,
    omniRcptDollarFlag:
      store.projectReducer.projectDetails.omni_rcpt_plan_dollar,
    vendorForFCBuy: store.buyOptimizationReducer.vendorForFCBuy,
    isFcApproved: store.buyOptimizationReducer.isFcApproved,
    aucThreshold: store.buyOptimizationReducer.aucThreshold,
    deptPenetration: store.buyOptimizationReducer.deptPenetration,
    season: store.buyOptimizationReducer.season
  };
};
const mapActionsToProps = {
  saveOmniBuyEstimation: buyOptimizationAction.saveOmniBuyEstimation,
  setOmniBuyEstimation: buyOptimizationAction.setOmniBuyEstimation
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(OmniBuyEstimation));

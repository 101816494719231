import api from "../axios";

const filterActions = {
  getDepartmentsData: () => {
    return dispatch => {
      api({
        url: "/getDepartments",
        method: "GET"
      })
        .then(resp => {
          dispatch({
            type: "GET_DEPARTMENTS_LIST",
            payload: resp.data.departmentData
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  },
  getDepartmentsAccToBuyerData: buyerId => {
    return dispatch => {
      api({
        url: "/getDepartmentsAccordingToBuyer",
        method: "POST",
        data: buyerId
      })
        .then(resp => {
          dispatch({
            type: "GET_DEPARTMENTS_LIST_ACC_TO_BUYER",
            payload: resp.data.departmentData
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  },
  getClassesData: payload => {
    return dispatch => {
      api({
        url: "/getClasses",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "GET_CLASSES_LIST",
            payload: resp.data.classData
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  },
  getClassesDataTarget: payload => {
    return dispatch => {
      api({
        url: "/getClasses",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "GET_CLASSES_LIST_TARGET",
            payload: resp.data.classData
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  },
  getBuyerData: payload => {
    return dispatch => {
      api({
        url: "/getBuyerListFilter",
        method: "GET",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "GET_BUYERS_LIST",
            payload: resp.data.buyerData
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  },
  getFobData: payload => {
    return dispatch => {
      api({
        url: "/getFob",
        method: "GET",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "GET_FOB_LIST",
            payload: resp.data.fobData
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  },
  clearFobData: () => {
    return dispatch => {
      dispatch({
        type: "CLEAR_FOB_DATA"
      });
    };
  },
  getDmmData: payload => {
    return dispatch => {
      api({
        url: "/getDMM",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "GET_DMM_LIST",
            payload: resp.data.dmmData
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  },
  clearDmmData: () => {
    return dispatch => {
      dispatch({
        type: "CLEAR_DMM_DATA"
      });
    };
  },
  getBuyerDataBuyRollup: payload => {
    return dispatch => {
      api({
        url: "/getBuyers",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "GET_BUYERS_LIST_BUY_ROLLUP",
            payload: resp.data.buyersData
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  },
  clearBuyerDataBuyRollup: () => {
    return dispatch => {
      dispatch({
        type: "CLEAR_BUYER_DATA_BUY_ROLLUP"
      });
    };
  },
  getDcData: payload => {
    return dispatch => {
      api({
        url: "/getDC",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "GET_DC_LIST",
            payload: resp.data.dcData
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  },
  clearDcData: () => {
    return dispatch => {
      dispatch({
        type: "CLEAR_DC_DATA"
      });
    };
  },
  getDepartmentsAccToDCData: payload => {
    return dispatch => {
      api({
        url: "/getDepartmentsAccordingToDC",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "GET_DEPARTMENTS_LIST_ACC_TO_DC",
            payload: resp.data.deptData
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  },
  clearDepartmentsAccToDCData: () => {
    return dispatch => {
      dispatch({
        type: "CLEAR_DEPARTMENT_ACC_TO_DC_DATA"
      });
    };
  },
  getVendorsData: payload => {
    return dispatch => {
      api({
        url: "/getVendors",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "GET_VENDORS_LIST",
            payload: resp.data.vendorData
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  },
  getVendorsDataTarget: payload => {
    return dispatch => {
      api({
        url: "/getVendors",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "GET_VENDORS_LIST_TARGET",
            payload: resp.data.vendorData
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  },
  getSeasonData: payload => {
    return dispatch => {
      api({
        url: "/getSeason",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "SET_SEASON_LIST",
            payload: resp.data.seasonData
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  }
};

export default filterActions;

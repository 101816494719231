import {
  toDollar,
  decimalFormatter,
  toUnit
} from "../../../helpers/formatters";
import { tar } from "./inputCss";

export default {
  defaultColDef: {
    animateRows: true,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    menuTabs: []
  },
  animateRows: true,
  sortable: true,
  //   filter: true,
  //   unSortIcon: true,
  //   suppressCellSelection: true,
  cellClass: "no-border",
  columnDefs: [
    {
      headerName: "SUBGROUP LEVEL",
      filter: "agTextColumnFilter",
      tooltipField: "Custom_Category",
      width: 132,
      pinned: "left",
      field: "Custom_Category"
    },
    {
      headerName: "COUNT OF STYLES",
      width: 89,
      pinned: "left",
      cellStyle: tar,
      field: "Count of Styles"
    },
    {
      headerName: "REG APS",
      children: [
        // {
        //   headerName: "ALGO OPT",
        //   field: "Algorithm Optimized Reg APS",
        //   valueFormatter: decimalFormatter
        // },
        {
          headerName: "ALGO OPT",
          cellStyle: tar,
          width: 80,
          field: "Algorithm Optimized with VA Reg APS",
          valueFormatter: decimalFormatter
        },
        {
          headerName: "BUYER PROP",
          cellStyle: tar,
          width: 87,
          field: "Buyer Proposed Reg APS",
          valueFormatter: decimalFormatter
        },
        {
          headerName: "BUYER PROP RECALC",
          width: 121,
          field: "Buyer Proposed - Recalculated Reg APS",
          cellStyle: tar,
          valueFormatter: decimalFormatter
        }
      ]
    },
    {
      headerName: "LIFETIME UNIT SALES",
      children: [
        {
          headerName: "ALGO OPT",
          width: 80,
          valueFormatter: toUnit,
          cellStyle: tar,
          field: "Algorithm Optimized with VA Lifetime Unit Sales"
        },
        {
          headerName: "BUYER PROP",
          width: 87,
          cellStyle: tar,
          valueFormatter: toUnit,
          field: "Buyer Proposed Lifetime Unit Sales"
        },
        {
          headerName: "BUYER PROP RECALC",
          width: 121,
          cellStyle: tar,
          valueFormatter: toUnit,
          field: "Buyer Proposed - Recalculated Lifetime Unit Sales"
        }
        // {
        //   headerName: "ALGO OPT WITH VA",
        //   valueFormatter: toUnit,
        //   field: "Algorithm Optimized with VA Lifetime Unit Sales"
        // }
      ]
    },
    {
      headerName: "LIFETIME MARGIN ($)",
      children: [
        // {
        //   headerName: "ALGO OPT",
        //   valueFormatter: toDollar,
        //   field: "Algorithm Optimized Lifetime Margin($)"
        // },
        {
          headerName: "ALGO OPT",
          width: 80,
          cellStyle: tar,
          valueFormatter: toDollar,
          field: "Algorithm Optimized with VA Lifetime Margin($)"
        },
        {
          headerName: "BUYER PROP",
          valueFormatter: toDollar,
          width: 87,
          cellStyle: tar,
          field: "Buyer Proposed Lifetime Margin($)"
        },
        {
          headerName: "BUYER PROP RECALC",
          valueFormatter: toDollar,
          cellStyle: tar,
          width: 121,
          field: "Buyer Proposed - Recalculated Lifetime Margin($)"
        }
      ]
    },
    {
      headerName: "UNIT RECEIPTS",
      children: [
        // {
        //   headerName: "ALGO OPT",
        //   valueFormatter: toUnit,
        //   field: "Algorithm Optimized Unit Receipts"
        // },
        {
          headerName: "ALGO OPT",
          width: 80,
          valueFormatter: toUnit,
          cellStyle: tar,
          field: "Algorithm Optimized with VA Unit Receipts"
        },
        {
          headerName: "BUYER PROP",
          width: 87,
          valueFormatter: toUnit,
          cellStyle: tar,
          field: "Buyer Proposed Unit Receipts"
        },
        {
          headerName: "BUYER PROP RECALC",
          valueFormatter: toUnit,
          cellStyle: tar,
          width: 121,
          field: "Buyer Proposed - Recalculated Unit Receipts"
        }
      ]
    },
    {
      headerName: "COST RECEIPT ($)",
      children: [
        // {
        //   headerName: "ALGO OPT",
        //   valueFormatter: toDollar,
        //   field: "Algorithm Optimized Cost Receipts($)"
        // },
        {
          headerName: "ALGO OPT",
          width: 80,
          valueFormatter: toDollar,
          cellStyle: tar,
          field: "Algorithm Optimized with VA Cost Receipts($)"
        },
        {
          headerName: "BUYER PROP",
          width: 87,
          valueFormatter: toDollar,
          cellStyle: tar,
          field: "Buyer Proposed Cost Receipts($)"
        },
        {
          headerName: "BUYER PROP RECALC",
          valueFormatter: toDollar,
          cellStyle: tar,
          width: 121,
          field: "Buyer Proposed - Recalculated Cost Receipts($)"
        }
      ]
    }

    // {
    //   headerName: "Reg ST%",
    //   children: [
    //     {
    //       headerName: "Algorithm Optimized",
    //       field: "Algorithm Optimized Reg ST%"

    //     },
    //     {
    //       headerName: "Buyer Proposed",

    //       field: "Buyer Proposed Reg ST%"

    //     },
    //     {
    //       headerName: "Buyer Proposed - Recalculated",
    //       field: "Buyer Proposed - Recalculated Reg ST%"

    //     },
    //     {
    //       headerName: "Algorithm Optimized with VA",
    //       field: "Algorithm Optimized with VA Reg ST%"

    //     }
    //   ]
    // }
  ]
};

import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";

import ScatterChart from "./ScatterChart";
import { miniScatterChartOptions } from "./scatterChartOptions";

function flatToTwoDArray(flatArray, innerArrayLength) {
  const twoDArray = [];
  let innerArray = [];
  for (let index = 0; index < flatArray.length; index++) {
    const element = flatArray[index];
    innerArray.push(element);
    if (innerArray.length % innerArrayLength === 0) {
      twoDArray.push(innerArray);
      innerArray = [];
    }
  }
  if (innerArray.length) {
    twoDArray.push(innerArray);
    innerArray = [];
  }
  return twoDArray;
}

export default function miniScatterChart(props) {
  const noOfItemsInGrid = 4;
  const twoDOptionsArray = flatToTwoDArray(
    props.filteredPlotOptions,
    noOfItemsInGrid
  );
  return (
    <Fragment>
      {twoDOptionsArray.map((innerOptions, index) => (
        <Row key={index}>
          {innerOptions.map((option, index) => {
            return (
              <Col key={index} md={12 / noOfItemsInGrid}>
                <div className="t-a-c mini-scatter-plot">
                  <span>{option.label}</span>
                  <span
                    className="fa fa-arrows-alt pull-right pointer"
                    onClick={() => props.expandScatterPlot(option)}
                  ></span>
                  <ScatterChart
                    options={miniScatterChartOptions}
                    data={props.allDataSets[option.value]}
                  ></ScatterChart>
                </div>
              </Col>
            );
          })}
        </Row>
      ))}
    </Fragment>
  );
}

import React from "react";
// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import ValueLabel from "@material-ui/core/Slider/ValueLabel";

const StyledValueLabel = withStyles({
  offset: {
    top: "-50px !important",
    left: "calc(-50% + -12px) !important",
    fontSize: "0.65rem !important"
  },
  circle: {
    width: " 50px !important",
    height: " 50px !important"
  }
})(ValueLabel);

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: "relative",
    borderRight: "1px solid #000",
    borderLeft: "1px solid #000",
    height: "20px"
  },
  mark: {
    border: "0.5px solid black",
    height: "20px",
    width: "0px",
    position: "absolute",
    left: "calc(29.411765% - 0.5px)"
  }
});

export default function RangeSlider(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.mark} />
      <Slider
        {...props}
        valueLabelDisplay="auto"
        ValueLabelComponent={StyledValueLabel}
        aria-labelledby="range-slider"
      />
    </div>
  );
}

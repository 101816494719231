import Button from "@material-ui/core/Button";
import {
  subGrpRcptWeightagePercentCalc,
  lysubGrpRcptWeightagePercentCalc,
  rcptDollarCalc,
  planRcptDollarCalc,
  lyRcptPercentChangeCalc,
  rcptUnitsCalc,
  tYAvgBuyCalc,
  rcptPenePercentCalc,
  percentChangeStyleCountCalc,
  percentChangeCC
} from "../../../helpers/formulae";
// import "./index.css";
import Tippy from "@tippy.js/react";
import { map, uniq } from "lodash";
import numeral from "numeral";
import React, { Component, Fragment } from "react";
import Modal from "react-awesome-modal";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import {
  subclassCopySuccessMsg,
  zeroMonthWarningMsg,
  onlyOneMonthInfoMsg,
  zeroSubClassWarningMsg
} from "../../../constants/messages";
import AssortmentPlanningActions from "../../../actions/AssortmentPlanningActions";
import { arrToObjConverter } from "../../../helpers";
import Alert from "../../../services/alert";
// import CustomSelectAll from "../../services/customSelectAll";
import DataTable from "../../../services/dataTable";
import Select from "../../../services/selectAll";
import { downloadTable } from "../../../utils/exportxlsx";
import "../index.css";
import LineArchitectureColDefs from "./columnDefs";
import excelStyle from "./excelStyle";
import Apt_StyleLevel from "./styleLevelColumnDefs";
import SubclassesCopyToMonthsDialog from "./SubclassesCopyToMonthsDialog";
import {
  classNameKey,
  subClassNameKey,
  subGrpRcptWeightagePercentKey,
  lysubGrpRcptWeightagePercentKey,
  rcptDollarKey,
  planRcptDollarKey,
  aurKey,
  rcptUnitsKey,
  tyAvgBuyKey,
  lYRcptDollarKey,
  lyRcptPercentChangeKey,
  rcptPenePercentKey,
  tyCCKey,
  tyStyleCountKey,
  percentChangeStyleCountKey,
  percentChangeCCKey
} from "../../../constants/keys";

class LineArchitecture extends Component {
  constructor(props) {
    super(props);

    // Setting Class or Vendor of Vendor/Class labels based in respective line reciept level
    let classOrVendorLabel;
    switch (props.lineReceiptlevel) {
      case "class":
        classOrVendorLabel = "Class";
        break;
      case "vendor":
        classOrVendorLabel = "Vendor";
        break;
      case "vendor_class":
        classOrVendorLabel = "Vendor/Class";
        break;
      case "stretch":
        classOrVendorLabel = "Stretch";
        break;
      default:
        break;
    }
    const classOrVendorOptions = [];

    const activeMonths =
      props.month === "Seasonal" ? props.rcptMonths : [props.month];
    const lineRcptTYKeys = [];

    // Defining line reciept key for getting planner budget
    activeMonths.forEach(activeMonth => {
      for (let i = 0; i < Object.keys(props.lineReceiptData[0]).length; i++) {
        const element = Object.keys(props.lineReceiptData[0])[i];
        if (element.startsWith(activeMonth) && element.endsWith("TY")) {
          lineRcptTYKeys.push(element);
          break;
        }
      }
    });

    // Creating Class/Vendor options from lineReceipt for adding subclass
    // (Taken into consideration Only TY Budget > 0 )
    props.lineReceiptData.forEach(ele => {
      let hasRcpt = false;
      for (let i = 0; i < lineRcptTYKeys.length; i++) {
        const key = lineRcptTYKeys[i];
        if (+ele[key] > 0) {
          hasRcpt = true;
          break;
        }
      }
      if (hasRcpt) {
        classOrVendorOptions.push({
          label: ele[props.lineReceiptlevel],
          value: ele[props.lineReceiptlevel]
        });
      }
    });

    this.state = {
      isScaleUp: false,
      noOfClasses: null,
      isDeleteSubclass: false,
      isNextAlertVisible: false,
      classesDone: null,
      remainingClasses: null,
      classOrVendorLabel,
      modalLoader: false,
      selectedClassVendor: null,
      lineArchitectureRowData: [],
      isAddExisting: true,
      removeLineArchitectureRow: false,
      // GraphData: null,
      classOrVendorOptions,
      subClassOptions: null,
      class: null,
      subClass: null,
      aur: null,
      imuPerc: null,
      rcptPenPerc: null,
      tycc: null,
      tyStyCount: null,
      comments: null,
      level: null,
      subClassesBucket: props.mainLineArchitectureData.Line_Architechture,
      selectedSubClassesBucket: props.lineArchitectureData.Line_Architechture,
      subClassesParametersBucket: props.mainLineArchitectureData.Parameters,
      selectedSubClassesParametersBucket: props.lineArchitectureData.Parameters,
      modalVisibleForAddRow: false,
      targetReceiptPlanner: props.plannerTotalBudget,
      targetReceiptBuyer: props.buyerTotalBudget,
      selectedMetric: { value: "Choice", label: "Choice" },
      classVendorSelected: { value: null, label: null },
      newSubClass: null,
      lineReceiptDataObj: arrToObjConverter(props.lineReceiptData, [
        props.lineReceiptlevel
      ]),
      lineRcptTYKeys,
      openCopySubclassMonthDialog: false,
      copySubclassSelectedMonths: []
    };
    this.child = React.createRef();
    this.exportFunc = null;
    // this.CalulateGraphMetric.bind(this);
    this.TargetInput = null;
  }
  componentDidMount() {
    this.getTotalNDoneClass();
  }
  componentDidUpdate(oldProps) {
    this.TargetInput.value = numeral(this.props.buyerTotalBudget).format("0,0");
    if (this.props !== oldProps) {
      if (this.props.buyerTotalBudget !== oldProps.buyerTotalBudget) {
        this.setState({
          targetReceiptPlanner: this.props.plannerTotalBudget,
          targetReceiptBuyer: this.props.buyerTotalBudget
        });
      }
      if (this.props.lineArchitectureData !== oldProps.lineArchitectureData) {
        this.setState(
          {
            subClassesBucket: this.props.mainLineArchitectureData
              .Line_Architechture,
            selectedSubClassesBucket: this.props.lineArchitectureData
              .Line_Architechture,
            subClassesParametersBucket: this.props.mainLineArchitectureData
              .Parameters,
            selectedSubClassesParametersBucket: this.props.lineArchitectureData
              .Parameters
          },
          () => {
            this.reCalculateRcptWeightagePercent();
            this.getTotalNDoneClass();
          }
        );
      }
    }
  }
  onAddExistOrNewTgle = val => {
    this.setState({
      class: null,
      subClass: null
    });
    if (val.target.name === "Add Existing Sub Grp") {
      this.setState({
        isAddExisting: true
      });
    } else {
      this.setState({
        isAddExisting: false
      });
    }
  };
  monthlyBuyerTotalBudget = () => {
    const monthlyBuyerTotalBudget = {};
    for (const month in this.props.lineArchitectureDetails) {
      if (this.props.lineArchitectureDetails.hasOwnProperty(month)) {
        const element = this.props.lineArchitectureDetails[month];
        if (this.props.lineArchitectureDetails[month] === this.props.month) {
          monthlyBuyerTotalBudget[month] = this.props.lineArchitectureData
            .buyerTotalBudget
            ? this.props.lineArchitectureData.buyerTotalBudget
            : this.props.lineArchitectureData.Line_Architechture.reduce(
                (acc, obj) => acc + obj.rcpt$,
                0
              );
        } else {
          monthlyBuyerTotalBudget[month] = element.buyerTotalBudget;
        }
      }
    }
    return monthlyBuyerTotalBudget;
  };
  onClickAddSubClass = () => {
    if (this.state.isAddExisting) {
      this.setState({ modalLoader: true });
      let subgroup_list = this.state.selectedSubClassesBucket.map(item => {
        let obj = {};
        obj["class"] = item["class/vendor"];
        obj["subclass"] = item["subclass"];
        return obj;
      });
      this.state.subClass.length &&
        this.state.subClass.forEach(subClass => {
          this.state.subClassesBucket.forEach((subClassObj, index) => {
            if (
              subClass.value === subClassObj.subclass &&
              subClassObj["class/vendor"] === this.state.class.value
            ) {
              this.state.selectedSubClassesBucket.push(
                this.state.subClassesBucket.splice(index, 1)[0]
              );
              let ob = {};
              ob["class"] = subClassObj["class/vendor"];
              ob["subclass"] = subClassObj["subclass"];
              subgroup_list.push(ob);
              // selectedSubClassesForLineArchitecture.push(obj);
            }
          });
          this.state.subClassesParametersBucket.forEach(
            (subClassObj, index) => {
              if (
                subClass.value === subClassObj.subclass &&
                subClassObj["class/vendor"] === this.state.class.value
              ) {
                this.state.selectedSubClassesParametersBucket.push(
                  this.state.subClassesParametersBucket.splice(index, 1)[0]
                );
                // selectedSubClassesForLineArchitecture.push(obj);
              }
            }
          );
        });
      // this.props.setLineArchitectureData(
      //   this.props.month,
      //   this.props.lineArchitectureData
      // );
      let payload = {
        month: this.props.month,
        projectID: Number(this.props.match.params.id),
        class_subgroups: subgroup_list
      };
      this.props.checkStyleDuplication(payload).then(resp => {
        if (resp.success) {
          if (resp.data.message !== "")
            toast.warning(resp.data.message, {
              className: "warningfont"
            });
        }
      });

      this.setState({
        modalVisibleForAddRow: false,
        newSubClass: ""
      });
      setTimeout(() => {
        this.setState({ modalLoader: false, subClass: null, class: null });
      }, 2000);
      this.reCalculateRcptWeightagePercent();
      this.getTotalNDoneClass();
    } else {
      if (this.props.stage > 2 || this.props.subStage > 2) {
        this.setState({
          isNextAlertVisible: true
        });
      } else {
        this.handleNext();
      }
    }
  };
  handleNext = () => {
    this.setState({ modalLoader: true, isNextAlertVisible: false });
    let selectedStyles = this.gridApiModal
      .getSelectedRows()
      .map(ele => ele.styleid);
    let payload = {};
    let growth = 0;
    this.state.subClassesParametersBucket.forEach(obj => {
      if (
        growth === 0 &&
        obj["class/vendor"] === this.state.class.value &&
        obj.growth
      ) {
        growth = obj.growth;
      }
    });
    if (!growth) {
      this.state.selectedSubClassesParametersBucket.forEach(obj => {
        if (
          growth === 0 &&
          obj["class/vendor"] === this.state.class.value &&
          obj.growth
        ) {
          growth = obj.growth;
        }
      });
    }

    let buyerBudgets = {};
    Object.keys(this.props.lineArchitectureDetails).forEach(key => {
      buyerBudgets[`${key}`] =
        this.props.lineArchitectureDetails[`${key}`].buyerTotalBudget || 0;
    });

    payload = {
      projectId: Number(this.props.match.params.id),
      styleId: selectedStyles || [],
      levelId: this.selectedClassVendor[0],
      levelDesc: this.state.class.value,
      level: this.props.lineReceiptlevel,
      month: this.props.month,
      departmentId: this.props.selectedFilters.departments[0].value,
      buyerTotalBudget: this.props.lineArchitectureData.buyerTotalBudget
        ? this.props.lineArchitectureData.buyerTotalBudget
        : this.props.lineArchitectureData.Line_Architechture.reduce(
            (acc, obj) => acc + obj.rcpt$,
            0
          ), // need to delete and add this.monthlyBuyerTotalBudget() after......
      growth: growth,
      monthLevelBuyerBudget: buyerBudgets,
      subClass: this.state.newSubClass
    };
    if (this.state.newSubClass && this.selectedClassVendor[0]) {
      this.props.getMetricsForNewSubClass(payload).then(resp => {
        if (resp.success) {
          const isStyleDataNotPresent = resp.data[this.props.month].message;
          this.reCalculateRcptWeightagePercent();
          this.props.setStylesDataForClass();
          this.getTotalNDoneClass();
          this.props
            .saveLineArchitectureData({
              projectId: this.props.match.params.id,
              payload: this.props.lineArchitectureDetails,
              subData: this.props.userEditableLineReceipt
            })
            .then(resp => {
              this.props.saveProjectStage(2, 2).then(res => {
                this.props.history.push("/dashboard");
                this.props.history.push(
                  `/stepperContainer/${this.props.match.params.id}`
                );
              });
            });

          this.setState({
            modalVisibleForAddRow: false,
            isAddExisting: true,
            newSubClass: null
          });
          // this.closeModalForAddRow();
          if (isStyleDataNotPresent)
            toast.warning(isStyleDataNotPresent, { autoClose: false });
        }
        this.setState({
          modalLoader: false
        });
      });
    } else {
      toast.error("Please enter Sub Grp name", { autoClose: false });
      this.setState({ modalLoader: false });
    }
    this.getTotalNDoneClass();
  };

  onDeleteSubClass = () => {
    this.gridApi.getSelectedRows().forEach(subClassRow => {
      this.state.selectedSubClassesBucket.forEach((subClassObj, index) => {
        if (
          subClassRow["class/vendor"] === subClassObj["class/vendor"] &&
          subClassRow.subclass === subClassObj.subclass
        ) {
          this.state.subClassesBucket.push(
            this.state.selectedSubClassesBucket.splice(index, 1)[0]
          );
        }
      });
      this.state.selectedSubClassesParametersBucket.forEach(
        (subClassObj, index) => {
          if (
            subClassRow["class/vendor"] === subClassObj["class/vendor"] &&
            subClassRow.subclass === subClassObj.subclass
          ) {
            this.state.subClassesParametersBucket.push(
              this.state.selectedSubClassesParametersBucket.splice(index, 1)[0]
            );
          }
        }
      );
    });
    this.reCalculateRcptWeightagePercent();
    this.getTotalNDoneClass();
    this.props.setLineArchitectureData(
      this.props.month,
      this.props.lineArchitectureData
    );
    this.setState({ isDeleteSubclass: false });
  };
  getTotalNDoneClass = () => {
    let noOfClasses = this.state.classOrVendorOptions.length;
    let classesDone = uniq(
      map(this.state.selectedSubClassesBucket, e => e["class/vendor"])
    ).length;
    let remainingClasses = noOfClasses - classesDone;
    this.setState({
      noOfClasses,
      classesDone,
      remainingClasses
    });
  };
  handleDeleteSubclassAlert = () => {
    this.gridApi.getSelectedRows().length &&
      this.setState({
        isDeleteSubclass: true
      });
  };
  getPlanRcptDollar = (class_vendor, lineRcptTYKeys) => {
    const lineRcptClassObj = this.state.lineReceiptDataObj[class_vendor];
    const planRcptDollar = lineRcptTYKeys.reduce((total, key) => {
      return total + lineRcptClassObj[key];
    }, 0);
    return planRcptDollar;
  };
  onMassUpdate = () => {
    this.state.selectedSubClassesBucket.forEach(subClass => {
      subClass[rcptDollarKey] = rcptDollarCalc(
        subClass,
        this.state.targetReceiptBuyer
      );
      subClass[lyRcptPercentChangeKey] = lyRcptPercentChangeCalc(subClass);
      this.onRcptDollarUpdate(subClass);
    });
    this.reCalculateRcptWeightagePercent();
    this.props.setBuyerTotalBudget(
      this.props.month,
      this.state.targetReceiptBuyer
    );
    this.gridApi.refreshClientSideRowModel("aggregate");
  };
  reCalculateRcptWeightagePercent() {
    this.state.selectedSubClassesBucket.forEach(subClass => {
      const {
        totalClassRcptDollar,
        lyTotalClassRcptDollar
      } = this.getTotalRcptDollarValue(subClass);
      if (subClass[lYRcptDollarKey]) {
        subClass[
          lysubGrpRcptWeightagePercentKey
        ] = lysubGrpRcptWeightagePercentCalc(subClass, lyTotalClassRcptDollar);
      }
      subClass[subGrpRcptWeightagePercentKey] = subGrpRcptWeightagePercentCalc(
        subClass,
        totalClassRcptDollar
      );
      subClass[planRcptDollarKey] = planRcptDollarCalc(
        subClass,
        this.getPlanRcptDollar(
          subClass[classNameKey],
          this.state.lineRcptTYKeys
        )
      );
    });
  }
  onRcptDollarUpdate(subClass) {
    if (subClass[aurKey]) {
      subClass[rcptUnitsKey] = rcptUnitsCalc(subClass);
      subClass[tyAvgBuyKey] = tYAvgBuyCalc(subClass);
    }
  }
  getTotalRcptDollarValue(subClass) {
    let totalClassRcptDollar = 0;
    let lyTotalClassRcptDollar = 0;
    this.state.selectedSubClassesBucket.forEach(obj => {
      if (subClass[classNameKey] === obj[classNameKey]) {
        totalClassRcptDollar += +obj[rcptDollarKey];
        lyTotalClassRcptDollar += +obj[lYRcptDollarKey];
      }
    });
    return { totalClassRcptDollar, lyTotalClassRcptDollar };
  }
  onCellValueChanged = val => {
    if (!isNaN(val.newValue) && val.oldValue !== val.newValue) {
      const subClass = val.data;
      switch (val.colDef.field) {
        case lyRcptPercentChangeKey:
          val.data[rcptPenePercentKey] = rcptPenePercentCalc(
            val.data,
            this.state.targetReceiptBuyer
          );
          subClass[rcptDollarKey] = rcptDollarCalc(
            subClass,
            this.state.targetReceiptBuyer
          );
          this.onRcptDollarUpdate(subClass);
          this.reCalculateRcptWeightagePercent();
          break;
        case rcptPenePercentKey:
          subClass[rcptDollarKey] = rcptDollarCalc(
            subClass,
            this.state.targetReceiptBuyer
          );
          subClass[lyRcptPercentChangeKey] = lyRcptPercentChangeCalc(subClass);
          this.onRcptDollarUpdate(subClass);
          this.reCalculateRcptWeightagePercent();
          break;
        case aurKey:
          subClass[rcptUnitsKey] = rcptUnitsCalc(subClass);
          subClass[tyAvgBuyKey] = tYAvgBuyCalc(subClass);
          break;
        case tyCCKey:
          subClass[percentChangeCCKey] = percentChangeCC(subClass);
          subClass[tyAvgBuyKey] = tYAvgBuyCalc(subClass);
          break;
        case tyStyleCountKey:
          subClass[percentChangeStyleCountKey] = percentChangeStyleCountCalc(
            subClass
          );
          break;
        default:
          break;
      }
      this.props.setLineArchitectureData(
        this.props.month,
        this.props.lineArchitectureData
      );
      val.api.refreshCells();
      val.api.refreshClientSideRowModel("aggregate");
    }
  };
  handleForAddRow = () => {
    this.onClearRowInputs();
    this.setState({
      modalVisibleForAddRow: true
    });
  };
  onClearRowInputs = () => {
    this.setState({
      class: null,
      subClass: null
    });
  };
  closeModalForAddRow() {
    this.props.setStylesDataForClass();
    this.setState({
      modalVisibleForAddRow: false,
      class: null,
      subClass: null,
      newSubClass: ""
    });
  }
  onClassChange = val => {
    if (val) {
      const subClassOptions = this.state.subClassesBucket
        .map(subClassObj => {
          if (subClassObj["class/vendor"] === val.value) {
            return {
              value: subClassObj.subclass,
              label: subClassObj.subclass
            };
          }
          return null;
        })
        .filter(obj => obj);
      this.setState({
        subClassOptions,
        class: val
      });
    } else {
      this.setState({
        class: val,
        subClass: null
      });
    }
    if (!this.state.isAddExisting && val) {
      let sourceMap = this.props.savedSourceMapping;
      let targetMap = this.props.savedTargetMapping;
      let tarIndex = targetMap.findIndex(x => x.value === val.value);
      let sourceVal = sourceMap[tarIndex];

      this.setState({ modalLoader: true });
      this.selectedClassVendor = null;
      // find class/vendor ID's
      let context = this;
      function filterSelectedFilerId(level) {
        context.selectedClassVendor = context.props.selectedFilters[level]
          .filter(obj => {
            return (
              obj.label ===
              (!(sourceMap && sourceMap.length) ? val.value : sourceVal.value)
            );
          })
          .map(val1 => {
            return val1.value;
          });
      }
      if (
        this.props.lineReceiptlevel === "vendor" ||
        this.props.lineReceiptlevel === "stretch"
      ) {
        filterSelectedFilerId("vendors");
      } else if (this.props.lineReceiptlevel === "class") {
        filterSelectedFilerId("classes");
      } else {
        // Since, we don't have vendor_class in our selected filters, so directly sending the vendor_class_name/desc
        this.selectedClassVendor = [val.value];

        // filterSelectedFilerId("vendor_class");
      }
      let monthSplitBudget = {};
      this.props.rcptMonths.forEach(month => {
        monthSplitBudget[month] = this.props.lineReceiptData.reduce(
          (prev, curr) => {
            let sum = 0;
            Object.entries(curr).forEach(arr => {
              if (arr[0].startsWith(month) && arr[0].endsWith("TY")) {
                sum += arr[1];
              }
            });
            return prev + sum;
          },
          0
        );
        if (monthSplitBudget[month] <= 0) {
          delete monthSplitBudget[month];
        }
      });

      let payload = {
        projectId: Number(this.props.match.params.id),
        levelId: tarIndex >= 0 ? [sourceVal.value] : [val.value],
        departmentId: this.props.selectedFilters.departments[0].value,
        level: this.props.lineReceiptlevel,
        month: this.props.month,
        plannerTotalBudget: monthSplitBudget
      };
      this.props.getStylesDataForClass(payload).then(resp => {
        this.setState({ modalLoader: false });
        if (resp && resp.data && !resp.data.length) {
          toast.warning(resp.message, {
            autoClose: 5000
          });
        }
      });
    } else {
      this.props.setStylesDataForClass();
    }
  };
  myFunction = x => {
    this.TargetInput.value = Number(
      numeral(this.TargetInput.value).format("0.0")
    );
  };
  myFunction2 = () => {
    this.setState({
      targetReceiptBuyer: +this.TargetInput.value
    });
    this.TargetInput.value = numeral(this.TargetInput.value).format("0,0");
  };
  onClick = () => {
    this.child.current.getAlert();
  };
  onSubClassChange = val => {
    this.setState({
      subClass: val
    });
  };
  handleAccept = () => {
    // Getting total to calculate the respective subclass recp pene % ratio
    const rcptPenePercentTotal = this.state.selectedSubClassesBucket.reduce(
      (acc, obj) => {
        return acc + obj["rcpt_pen%"];
      },
      0
    );
    this.state.selectedSubClassesBucket.forEach(selectedSubClassesObj => {
      selectedSubClassesObj["rcpt_pen%"] =
        (selectedSubClassesObj["rcpt_pen%"] * 100) / rcptPenePercentTotal || 0;
    });
    this.onMassUpdate();
    this.setState({
      isScaleUp: false
    });
  };
  onScaleUp = () => {
    this.setState({
      isScaleUp: true
    });
  };
  getSubGroupStyleMapping = ({ month, projectID, fileName }) => {
    this.props
      .getSubGroupStyleMapping({ month, projectID })
      .then(data => {
        let sheets = data.data.subGroupStyleMapping;
        if (sheets instanceof Array && sheets.length !== 0)
          downloadTable(sheets, fileName);
      })
      .catch(error => {
        throw error;
      });
  };
  handleCopySubclassSelectedMonthsChange = event => {
    this.setState({ copySubclassSelectedMonths: event.target.value });
  };
  handleOpenCopySubclassMonthDialog = () => {
    if (this.props.otherMonths.length === 0)
      return toast.info(onlyOneMonthInfoMsg);

    if (this.gridApi.getSelectedRows().length === 0)
      return toast.warning(zeroSubClassWarningMsg);

    this.setState({ openCopySubclassMonthDialog: true });
  };
  handleCloseCopySubclassMonthDialog = () => {
    this.setState({
      openCopySubclassMonthDialog: false,
      copySubclassSelectedMonths: []
    });
  };
  handleCopySubclasses = () => {
    if (this.state.copySubclassSelectedMonths.length) {
      const selectSubclasses = this.gridApi.getSelectedRows().map(subClass => {
        return `${subClass[classNameKey]}${subClass[subClassNameKey]}`;
      });
      this.state.copySubclassSelectedMonths.forEach(month => {
        const lineRcptTYKey = Object.keys(this.props.lineReceiptData[0]).filter(
          key => {
            return key.startsWith(month) && key.endsWith("TY");
          }
        );
        const mainLineArchData = this.props.lineArchitectureDetails[month]
          .main_data.Line_Architechture;
        const mainParameterData = this.props.lineArchitectureDetails[month]
          .main_data.Parameters;
        const lineArchData = this.props.lineArchitectureDetails[month].data
          .Line_Architechture;
        const parameterData = this.props.lineArchitectureDetails[month].data
          .Parameters;

        const requiredKeysArr = [classNameKey, subClassNameKey];

        const lineArchMainDataObj = arrToObjConverter(
          mainLineArchData,
          requiredKeysArr
        );
        const paramMainDataObj = arrToObjConverter(
          mainParameterData,
          requiredKeysArr
        );

        selectSubclasses.forEach(selectedSubClass => {
          if (lineArchMainDataObj.hasOwnProperty(selectedSubClass)) {
            const plannedBudget = this.getPlanRcptDollar(
              lineArchMainDataObj[selectedSubClass][classNameKey],
              lineRcptTYKey
            );
            if (plannedBudget) {
              lineArchData.push(lineArchMainDataObj[selectedSubClass]);
              delete lineArchMainDataObj[selectedSubClass];
              parameterData.push(paramMainDataObj[selectedSubClass]);
              delete paramMainDataObj[selectedSubClass];
            }
          }
        });
        this.props.setMainLineArchitectureData(month, {
          ...this.props.mainLineArchitectureData,
          Line_Architechture: Object.values(lineArchMainDataObj),
          Parameters: Object.values(paramMainDataObj)
        });
        this.props.setLineArchitectureData(month, {
          ...this.props.lineArchitectureData,
          Line_Architechture: lineArchData,
          Parameters: parameterData
        });
        this.gridApi.deselectAll();
        this.handleCloseCopySubclassMonthDialog();
      });
      toast.success(subclassCopySuccessMsg);
    } else {
      return toast.warning(zeroMonthWarningMsg);
    }
  };
  render() {
    return (
      <Fragment>
        <SubclassesCopyToMonthsDialog
          open={this.state.openCopySubclassMonthDialog}
          selectedMonths={this.state.copySubclassSelectedMonths}
          handleClose={this.handleCloseCopySubclassMonthDialog}
          months={this.props.otherMonths}
          handleChange={this.handleCopySubclassSelectedMonthsChange}
          handleCopy={this.handleCopySubclasses}
        ></SubclassesCopyToMonthsDialog>
        <Alert
          open={this.state.isScaleUp}
          title="Alert"
          isResetRequired={false}
          content="Are you sure you want to scale up/down to 100% ?"
          handleAccept={this.handleAccept}
          actionButtonText="Yes"
          cancelButtonText="No"
          handleClose={() => {
            this.setState({
              isScaleUp: false
            });
          }}
        />
        <Alert
          open={this.state.isDeleteSubclass}
          title="Alert"
          isResetRequired={false}
          content="Are you sure you want to delete the subclass ?"
          handleAccept={this.onDeleteSubClass}
          actionButtonText="Yes"
          cancelButtonText="No"
          handleClose={() => {
            this.setState({
              isDeleteSubclass: false
            });
          }}
        />
        <Alert
          open={this.state.isNextAlertVisible}
          title="Alert"
          isResetRequired={false}
          content={
            "Changes have been noticed. Clicking on continue preceding steps data will be cleared."
          }
          handleAccept={this.handleNext}
          cancelButtonText="Cancel"
          actionButtonText="Continue"
          handleClose={() => {
            this.setState({
              isNextAlertVisible: false
            });
          }}
        />

        <div className="row p-2">
          <div className="col-md-3">
            <label htmlFor="inputPassword4">Target Rcpt$ (Planner)</label>
            <input
              // style={{
              //   width: "18rem"
              // }}
              className="form-control"
              disabled
              placeholder="Target Receipt(Planner)"
              value={numeral(this.state.targetReceiptPlanner).format("0,0")}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="inputPassword4">Target Rcpt$ (Buyer)</label>
            <input
              className="form-control"
              disabled={this.props.userAccessDisableFlag}
              placeholder="Target Receipt (Buyer)"
              onFocus={() => this.myFunction()}
              onBlur={() => this.myFunction2()}
              ref={input => (this.TargetInput = input)}
            />
          </div>
          <div
            className="col-md-2"
            align="right"
            style={{ display: "flex", alignSelf: "flex-end" }}
          >
            <Button
              size="small"
              style={{ textTransform: "capitalize" }}
              disabled={this.props.userAccessDisableFlag}
              onClick={this.onMassUpdate}
            >
              Apply
            </Button>
          </div>
        </div>
        <div className="actionSectionRightAlignedLA">
          <div>
            <span>
              Total {this.state.noOfClasses} {this.state.classOrVendorLabel}
              ,&nbsp;
            </span>
            <span>
              {this.state.classesDone} {this.state.classOrVendorLabel} Done,
              &nbsp;
            </span>
            <span>
              {this.state.remainingClasses} {this.state.classOrVendorLabel}{" "}
              Remaining
            </span>
            <Tippy
              placement={"left"}
              arrow={true}
              distance={10}
              content={
                <div>
                  Each Class should have at least 1 Sub Grp. in each month
                </div>
              }
            >
              <i
                style={{
                  color: "#2D69EB"
                }}
                className="fa fa-info-circle"
                aria-hidden="true"
              />
            </Tippy>
          </div>
          <div>
            <span className="p-1">Actions: </span>
            {this.state.selectedSubClassesBucket &&
            this.state.selectedSubClassesBucket.length ? (
              <>
                <Tippy
                  placement={"left"}
                  arrow={true}
                  distance={10}
                  content={<div>Scale up or Scale down</div>}
                >
                  <i
                    className="fa fa-arrows-v p-2 pointer"
                    style={{
                      cursor: "pointer",
                      fontSize: "15px",
                      pointerEvents: this.props.userAccessDisableFlag && "none",
                      color: "#2D69EB"
                    }}
                    aria-hidden="true"
                    onClick={() => this.onScaleUp()}
                  />
                </Tippy>
                {!this.props.isSeasonLevel && (
                  <Tippy
                    placement={"left"}
                    arrow={true}
                    distance={10}
                    content={
                      <div>Copy selected subclasses to other months</div>
                    }
                  >
                    <i
                      className="faactions fa fa-clone"
                      aria-hidden="true"
                      onClick={this.handleOpenCopySubclassMonthDialog}
                      style={{
                        cursor: "pointer",
                        color: "#2D69EB",
                        pointerEvents:
                          this.props.userAccessDisableFlag && "none"
                      }}
                    ></i>
                  </Tippy>
                )}
                <Tippy
                  placement={"left"}
                  arrow={true}
                  distance={10}
                  content={
                    <div>Delete selected sub groups from Line Architecture</div>
                  }
                >
                  <i
                    className="faactions fa fa-trash p-2 pointer"
                    aria-hidden="true"
                    onClick={() => this.handleDeleteSubclassAlert()}
                    style={{
                      color: "red",
                      pointerEvents: this.props.userAccessDisableFlag && "none"
                    }}
                  />
                </Tippy>
                <Tippy
                  placement={"left"}
                  arrow={true}
                  distance={10}
                  content={<div>Download Line Architecture</div>}
                >
                  <i
                    onClick={() => this.exportFunc()}
                    className="faactions fa fa-download p-2 pointer"
                    style={{
                      cursor: "pointer",
                      color: "#2D69EB",
                      pointerEvents: this.props.userAccessDisableFlag && "none"
                    }}
                    aria-hidden="true"
                  />
                </Tippy>
              </>
            ) : (
              <span></span>
            )}

            <Tippy
              placement={"left"}
              arrow={true}
              distance={10}
              content={<div>Add sub group</div>}
            >
              <i
                style={{
                  cursor: "pointer",
                  color: "#2D69EB",
                  pointerEvents: this.props.userAccessDisableFlag && "none"
                }}
                className="faactions fa fa-plus-circle p-2 pointer"
                onClick={this.handleForAddRow}
              />
            </Tippy>
          </div>
        </div>
        <div className="mt-2">
          <div className="card-header-background">
            <strong>Line Architecture</strong>
          </div>
          <div style={{ height: "calc(100vh - 500px)", minHeight: "300px" }}>
            <DataTable
              overlayNoRowsTemplate={`<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">To Proceed, Click on the '+' icon to add a Sub Grp.${
                this.props.isSeasonLevel
                  ? "<br>Please Note: Should have at least 1 Sub Grp.</span>"
                  : "<br>Please Note: Should have at least 1 Sub Grp. in each month</span>"
              }`}
              excelRefs={funref => {
                this.exportFunc = funref;
              }}
              excelStyles={excelStyle}
              floatingFilter={true}
              onCellValueChanged={this.onCellValueChanged}
              suppressColumnVirtualisation={true}
              groupColDef={{
                headerName: "Group",
                width: 250,
                filter: "agTextColumnFilter",
                field: "subclass",
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: { checkbox: true }
              }}
              passGridAPI={api => {
                this.gridApi = api;
              }}
              columnDefs={LineArchitectureColDefs.columnDefs(
                this.props.userAccessDisableFlag,
                this.props.lyFlag
              )}
              defaultColDef={LineArchitectureColDefs.defaultColDef}
              groupRowAggNodes={LineArchitectureColDefs.groupRowAggNodes}
              rowData={[...this.state.selectedSubClassesBucket]}
              onCellClicked={this.onCellClicked}
              defaultExpand={true}
              legends={[
                {
                  color: "#f28e83",
                  text: "Total Receipt Penetration is not equal to 100%"
                }
              ]}
            />
          </div>
        </div>
        <div className={this.state.modalVisibleForAddRow ? "subgroup22" : ""}>
          <Modal
            visible={this.state.modalVisibleForAddRow}
            width="70%"
            height="95%"
            effect="fadeInUp"
            onClickAway={() => this.closeModalForAddRow()}
          >
            <Card>
              <CardHeader
                style={{ background: "#2D69EB", padding: "0.25rem 0.25rem" }}
              >
                {" "}
                <i
                  className="faactions fa fa-plus-circle p-2 pointer"
                  style={{ fontSize: "1.25rem" }}
                />
                Add Sub Group
              </CardHeader>
              <CardBody>
                <LoadingOverlay
                  active={this.state.modalLoader}
                  spinner
                  // text="This step may take some time. Thank you for your patience."
                >
                  <div className="addExisting-container-filter">
                    <form>
                      <div className="form-row">
                        <div className="form-group px-1 col-md-4">
                          <FormGroup check style={{ paddingTop: "0.5rem" }}>
                            <Input
                              type="radio"
                              name="Add Existing Sub Grp"
                              checked={this.state.isAddExisting}
                              onChange={this.onAddExistOrNewTgle}
                              disabled={this.props.isAddExisting}
                            />
                            <Label check style={{ marginLeft: "1rem" }}>
                              Add Existing Sub Grp
                            </Label>
                          </FormGroup>
                        </div>
                        <div className="form-group px-1 col-md-4">
                          <FormGroup check style={{ paddingTop: "0.5rem" }}>
                            <Input
                              type="radio"
                              name="Create New Sub Grp"
                              checked={!this.state.isAddExisting}
                              onChange={this.onAddExistOrNewTgle}
                              disabled={this.props.isAddExisting}
                            />
                            <Label check style={{ marginLeft: "1rem" }}>
                              Create New Sub Grp
                            </Label>
                          </FormGroup>
                        </div>
                      </div>
                    </form>
                    <hr style={{ margin: 0, borderTop: "1px solid #cdc6c6" }} />
                  </div>
                  <div
                    className="addExisting-container-body"
                    style={{ height: "calc(100vh - 300px)" }}
                  >
                    {this.state.isAddExisting ? (
                      <div className="m-t-3">
                        <div className="form-group col-md-4">
                          <label>Select {this.state.classOrVendorLabel}</label>
                          <Select
                            placeholder={`Select ${this.state.classOrVendorLabel}`}
                            value={this.state.class}
                            onChange={val => this.onClassChange(val)}
                            options={this.state.classOrVendorOptions}
                            isClearable
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label>Select Sub Grp</label>
                          <Select
                            isMulti={true}
                            placeholder="Select"
                            isClearable
                            allowSelectAll={true}
                            closeMenuOnSelect={false}
                            isDisabled={!this.state.class}
                            onChange={val => this.onSubClassChange(val)}
                            options={this.state.subClassOptions || []}
                            value={this.state.subClass}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="m-t-3">
                        <div className="row">
                          <div className="form-group col-md-4">
                            <label>
                              Select {this.state.classOrVendorLabel}
                            </label>
                            <Select
                              placeholder={`Select ${this.state.classOrVendorLabel}`}
                              value={this.state.class}
                              onChange={val => this.onClassChange(val)}
                              options={this.state.classOrVendorOptions}
                              isClearable
                            />
                          </div>
                        </div>
                        <div
                          className="pt-2"
                          style={{ width: "80%", margin: "auto" }}
                        >
                          <label>Sub-Class</label>
                          <input
                            className="form-control"
                            value={this.state.newSubClass}
                            // ref={val => (this.newSubClass = val)}
                            placeholder="Enter Sub Grp Name*"
                            onChange={e => {
                              this.setState({
                                newSubClass: e.target.value
                              });
                            }}
                          />
                        </div>
                        <div
                          className="pt-2"
                          style={{
                            height: "calc(100vh - 475px)",
                            width: "90%",
                            margin: "auto"
                          }}
                          // style={{
                          //   height: "calc(100vh - 375px)"
                          // }}
                        >
                          <DataTable
                            columnDefs={
                              Apt_StyleLevel(
                                this.props.rcptMonths,
                                this.props.isSeasonLevel
                              ).columnDefs
                            }
                            defaultColDef={Apt_StyleLevel().defaultColDef}
                            // rowData={styleData}
                            passGridAPI={api => {
                              this.gridApiModal = api;
                            }}
                            rowData={this.props.styleDataForClass}
                            sizeToFit={true}
                            floatingFilter={true}
                            rowSelection="multiple"
                            cssEffects={{
                              selectAllHover: true
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div align="right">
                    <Button
                      disabled={
                        this.state.isAddExisting
                          ? !(
                              this.state.class &&
                              !Object.keys(this.state.class).length === false &&
                              this.state.subClass &&
                              !this.state.subClass.length === false
                            )
                          : !this.state.class
                      }
                      onClick={this.onClickAddSubClass}
                    >
                      Add Sub Grp
                    </Button>
                  </div>
                </LoadingOverlay>
              </CardBody>
              <CardFooter>
                <div className="subClassState">
                  <div>
                    Total &nbsp;
                    {this.state.noOfClasses}
                    &nbsp; {this.state.classOrVendorLabel}
                  </div>
                  <div>
                    {this.state.classesDone}
                    &nbsp; {this.state.classOrVendorLabel} Done
                  </div>
                  <div>
                    {this.state.remainingClasses}{" "}
                    {this.state.classOrVendorLabel} Remaining
                  </div>
                </div>
                <div align="center">
                  <small>
                    To Proceed to the Cluster and Parameters section, atleast
                    one Sub Grp need to be added
                  </small>
                </div>
              </CardFooter>
            </Card>
          </Modal>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = store => {
  return {
    lyFlag: store.projectReducer.lyFlag,
    lineArchitectureDetails:
      store.assortmentPlanningReducer.lineArchitectureDetails,
    userEditableLineReceipt:
      store.assortmentPlanningReducer.userEditableLineReceipt
  };
};
const mapActionsToProps = {
  setStylesDataForClass: AssortmentPlanningActions.setStylesDataForClass,
  setMainLineArchitectureData:
    AssortmentPlanningActions.setMainLineArchitectureData,
  setLineArchitectureData: AssortmentPlanningActions.setLineArchitectureData,
  saveLineArchitectureData: AssortmentPlanningActions.saveLineArchitectureData,
  setAddedSubClass: AssortmentPlanningActions.setAddedSubClass,
  setBuyerTotalBudget: AssortmentPlanningActions.setBuyerTotalBudget,
  getStylesDataForClass: AssortmentPlanningActions.getStylesDataForClass,
  getMetricsForNewSubClass: AssortmentPlanningActions.getMetricsForNewSubClass,
  getSubGroupStyleMapping: AssortmentPlanningActions.getSubGroupStyleMapping,
  checkStyleDuplication: AssortmentPlanningActions.checkStyleDuplication
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(LineArchitecture));

import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import { cloneDeep, filter } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import buyRollUpActions from "../../actions/BuyRollUpActions";
import FilterActions from "../../actions/FilterActions";
import RollUp1 from "../../assests/rollUp1.svg";
import RollUp10 from "../../assests/rollUp10.svg";
import RollUp11 from "../../assests/rollUp11.svg";
import RollUp12 from "../../assests/rollUp12.svg";
import RollUp2 from "../../assests/rollUp2.svg";
import RollUp4 from "../../assests/rollUp4.svg";
import RollUp5 from "../../assests/rollUp5.svg";
import RollUp6 from "../../assests/rollUp6.svg";
import RollUp8 from "../../assests/rollUp8.svg";
import RollUp9 from "../../assests/rollUp9.svg";
import { decimalFormatterNormal, toUnitNormal } from "../../helpers/formatters";
import BarChartComponent from "../../services/ChartJS/BuyRollUpChart";
import DataTable from "../../services/dataTable";
import MonthPicker from "../../services/monthPicker";
import Select from "../../services/selectAll";
import {
  ApsOptions,
  BudgetOptions,
  ChoiceCountOptions,
  DepthOptions,
  DoorCountOptions,
  UnitOptions
} from "./BarChartOptions";
import DashboardColDefs from "./columnDefs";
import excelStyle from "./excelStyle";
import BuyRollUpColDefs from "./keyItemsColumnDef";
import RollupCard from "./RollUpCard";

class BuyRollup extends Component {
  constructor(props) {
    super(props);
    this.props.getFobData();
    this.state = {
      fobIds: [],
      dmmIds: [],
      buyerIds: [],
      dcIds: [],
      departmentIds: [],
      selectedMonthRange: null,
      receiptStartDate: null,
      receiptEndDate: null,
      monthPickerIsOpen: false,
      monthPickerStartDateSelected: true,
      monthPickerEndDateSelected: false,
      salesDataCard: [],
      recieptDataCard: [],
      productivityDataCard: [],
      isLy: true,
      secFob: [],
      secDepartment: [],
      secSubGroup: [],
      secMonth: [],
      modalVisibleForAddRow: false,
      planArr: [],
      graphData: null,
      filterData: cloneDeep(props.rollUpData),
      showGraph: false
    };
    this.exportFunc = null;
  }

  excelDataBuyFirst = [];
  // excelDataBuySecond = []

  onResetClick = () => {
    this.props.clearFobData();
    this.props.clearDmmData();
    this.props.clearBuyerDataBuyRollup();
    this.props.clearDcData();
    this.props.clearDepartmentsAccToDCData();
    this.setState(
      {
        fobIds: [],
        dmmIds: [],
        buyerIds: [],
        dcIds: [],
        departmentIds: [],
        selectedMonthRange: null,
        receiptStartDate: null,
        receiptEndDate: null,
        monthPickerIsOpen: false,
        monthPickerStartDateSelected: true,
        monthPickerEndDateSelected: false,
        salesDataCard: [],
        recieptDataCard: [],
        productivityDataCard: [],
        isLy: true,
        secFob: [],
        secDepartment: [],
        secSubGroup: [],
        secMonth: [],
        modalVisibleForAddRow: false,
        planArr: [],
        graphData: null,
        filterData: cloneDeep(this.props.rollUpData),
        showGraph: false
      },
      () => {
        this.props.getFobData();
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.rollUpData !== this.props.rollUpData) {
      this.getGraphData();
      this.getFilters();
      this.getCardData();
      this.setState({
        showGraph: false,
        secFob: [],
        secDepartment: [],
        secSubGroup: [],
        secMonth: [],
        showDownload: false,
        filterData: cloneDeep(this.props.rollUpData)
      });
    }
  }

  getCardData = () => {
    let data = this.props.rollUpData || [];
    let salesData = [];
    let sales1 = [];
    let sales2 = [];
    let sales3 = [];
    let sales4 = [];
    let recieptData = [];
    let reciept1 = [];
    let reciept2 = [];
    let reciept3 = [];
    let reciept4 = [];
    let productivityData = [];
    let productivity1 = [];
    let productivity2 = [];
    let productivity3 = [];
    let productivity4 = [];

    let tyUnitSales = 0,
      lyUnitSales = 0,
      llyUnitSales = 0;
    let tyRevenueSales = 0,
      lyRevenueSales = 0,
      llyRevenueSales = 0;
    let tyMargin$Sales = 0,
      lyMargin$Sales = 0,
      llyMargin$Sales = 0;
    let tyMarginPerSales = 0,
      lyMarginPerSales = 0,
      llyMarginPerSales = 0;

    let tyRtlReciept = 0,
      lyRtlReciept = 0,
      llyRtlReciept = 0;
    let tyCostReciept = 0,
      lyCostReciept = 0,
      llyCostReciept = 0;
    let tyUnitReciept = 0,
      lyUnitReciept = 0,
      llyUnitReciept = 0;
    let tyIMUReciept = 0,
      lyIMUReciept = 0,
      llyIMUReciept = 0;

    let tyCCProductivity = 0,
      lyCCProductivity = 0,
      llyCCProductivity = 0;
    let tyAURProductivity = 0,
      lyAURProductivity = 0,
      llyAURProductivity = 0;
    let tyAPSProductivity = 0,
      lyAPSProductivity = 0,
      llyAPSProductivity = 0;
    let tySTProductivity = 0,
      lySTProductivity = 0,
      llySTProductivity = 0;

    let bop_oh_LY = 0;
    let rcpt_qty_st_LY = 0;

    for (let i = 0; i < data.length; i++) {
      if (
        data[i]["Units_Sold_TY"] ||
        data[i]["Units_Sold_LY"] ||
        data[i]["Units_Sold_LLY"]
      ) {
        tyUnitSales += Number(data[i]["Units_Sold_TY"]);
        lyUnitSales += Number(data[i]["Units_Sold_LY"]);
        llyUnitSales += Number(data[i]["Units_Sold_LLY"]);
      }
      if (
        data[i]["Revenue_TY"] ||
        data[i]["Revenue_LY"] ||
        data[i]["Revenue_LLY"]
      ) {
        tyRevenueSales += Number(data[i]["Revenue_TY"]);
        lyRevenueSales += Number(data[i]["Revenue_LY"]);
        llyRevenueSales += Number(data[i]["Revenue_LLY"]);
      }
      if (
        data[i]["Reg_Margin_TY"] ||
        data[i]["Reg_Margin_LY"] ||
        data[i]["Reg_Margin_LLY"]
      ) {
        tyMargin$Sales += Math.abs(Number(data[i]["Reg_Margin_TY"]));
        lyMargin$Sales += Math.abs(Number(data[i]["Reg_Margin_LY"]));
        llyMargin$Sales += Math.abs(Number(data[i]["Reg_Margin_LLY"]));
      }

      if (
        data[i]["Reciepts_Rtl_TY"] ||
        data[i]["Reciepts_Rtl_LY"] ||
        data[i]["Reciepts_Rtl_LLY"]
      ) {
        tyRtlReciept += Number(data[i]["Reciepts_Rtl_TY"]);
        lyRtlReciept += Number(data[i]["Reciepts_Rtl_LY"]);
        llyRtlReciept += Number(data[i]["Reciepts_Rtl_LLY"]);
      }
      if (
        data[i]["Total_Buy_Cost_TY"] ||
        data[i]["Total_Buy_Cost_LY"] ||
        data[i]["Total_Buy_Cost_LLY"]
      ) {
        tyCostReciept += Number(data[i]["Total_Buy_Cost_TY"]);
        lyCostReciept += Number(data[i]["Total_Buy_Cost_LY"]);
        llyCostReciept += Number(data[i]["Total_Buy_Cost_LLY"]);
      }
      if (
        data[i]["Total_Buy_Units_TY"] ||
        data[i]["Total_Buy_Units_LY"] ||
        data[i]["Total_Buy_Units_LLY"]
      ) {
        tyUnitReciept += Number(data[i]["Total_Buy_Units_TY"]);
        lyUnitReciept += Number(data[i]["Total_Buy_Units_LY"]);
        llyUnitReciept += Number(data[i]["Total_Buy_Units_LLY"]);
      }
      if (data[i]["IMU_TY"] || data[i]["IMU_LY"] || data[i]["IMU_LLY"]) {
        tyIMUReciept +=
          Number(data[i]["IMU_TY"]) * Number(data[i]["Total_Buy_Units_TY"]);
        lyIMUReciept +=
          Number(data[i]["IMU_LY"]) * Number(data[i]["Total_Buy_Units_LY"]);
        llyIMUReciept +=
          Number(data[i]["IMU_LLY"]) * Number(data[i]["Total_Buy_Units_LLY"]);
      }

      if (data[i]["CC_TY"] || data[i]["CC_LY"] || data[i]["CC_LLY"]) {
        tyCCProductivity += Number(data[i]["CC_TY"]);
        lyCCProductivity += Number(data[i]["CC_LY"]);
        llyCCProductivity += Number(data[i]["CC_LLY"]);
      }
      if (data[i]["APS_TY"] || data[i]["APS_LY"] || data[i]["APS_LLY"]) {
        tyAPSProductivity += Number(data[i]["APS_TY"]);
        lyAPSProductivity += Number(data[i]["APS_LY"]);
        llyAPSProductivity += Number(data[i]["APS_LLY"]);
      }

      if (data[i]["bop_oh_LY"]) {
        bop_oh_LY += Number(data[i]["bop_oh_LY"]);
      }
      if (data[i]["rcpt_qty_st_LY"]) {
        rcpt_qty_st_LY += Number(data[i]["rcpt_qty_st_LY"]);
      }
    }

    tySTProductivity =
      (tyUnitSales / tyUnitReciept) * 100 > 100
        ? 100
        : (tyUnitSales / tyUnitReciept) * 100;
    lySTProductivity =
      (lyUnitSales / (bop_oh_LY + rcpt_qty_st_LY)) * 100 > 100
        ? 100
        : (lyUnitSales / (bop_oh_LY + rcpt_qty_st_LY)) * 100;
    llySTProductivity =
      (llyUnitSales / llyUnitReciept) * 100 > 100
        ? 100
        : (llyUnitSales / llyUnitReciept) * 100;

    tyMarginPerSales =
      (tyMargin$Sales / tyRevenueSales) * 100 > 100
        ? 100
        : (tyMargin$Sales / tyRevenueSales) * 100;
    lyMarginPerSales =
      (lyMargin$Sales / lyRevenueSales) * 100 > 100
        ? 100
        : (lyMargin$Sales / lyRevenueSales) * 100;
    llyMarginPerSales =
      (llyMargin$Sales / llyRevenueSales) * 100 > 100
        ? 100
        : (llyMargin$Sales / llyRevenueSales) * 100;

    //AUR=Receipt Rtl $/Total Buy Units For TY, LY and LLY
    tyAURProductivity = tyRtlReciept / tyUnitReciept;
    lyAURProductivity = lyRtlReciept / lyUnitReciept;
    llyAURProductivity = llyRtlReciept / llyUnitReciept;
    //IMU in cards = (IMU_1 * TOTAL BUY UNITS_1+IMU_2*TOTAL BUY UNITS_2...)/SUM(TOTAL BUY UNITS)
    tyIMUReciept = tyIMUReciept / tyUnitReciept;
    lyIMUReciept = lyIMUReciept / lyUnitReciept;
    llyIMUReciept = llyIMUReciept / llyUnitReciept;

    sales1.push({
      title: "Sales Units",
      ty: toUnitNormal(tyUnitSales.toFixed()),
      lyorlly: toUnitNormal(
        (this.state.isLy ? lyUnitSales : llyUnitSales).toFixed()
      ),
      icon: RollUp1,
      percentageChange: (
        Math.abs(
          ((this.state.isLy ? lyUnitSales : llyUnitSales) - tyUnitSales) /
            (this.state.isLy ? lyUnitSales : llyUnitSales)
        ) * 100
      ).toFixed(0)
    });
    sales2.push({
      title: "Sales $",
      ty: toUnitNormal(tyRevenueSales.toFixed()),
      lyorlly: toUnitNormal(
        (this.state.isLy ? lyRevenueSales : llyRevenueSales).toFixed()
      ),
      icon: RollUp2,
      percentageChange: (
        Math.abs(
          ((this.state.isLy ? lyRevenueSales : llyRevenueSales) -
            tyRevenueSales) /
            (this.state.isLy ? lyRevenueSales : llyRevenueSales)
        ) * 100
      ).toFixed(0)
    });
    sales3.push({
      title: "Reg Margin $",
      ty: toUnitNormal(tyMargin$Sales.toFixed()),
      lyorlly: toUnitNormal(
        (this.state.isLy ? lyMargin$Sales : llyMargin$Sales).toFixed()
      ),
      infoIcon: "infoRollUp3",
      percentageChange: (
        Math.abs(
          ((this.state.isLy ? lyMargin$Sales : llyMargin$Sales) -
            tyMargin$Sales) /
            (this.state.isLy ? lyMargin$Sales : llyMargin$Sales)
        ) * 100
      ).toFixed(0)
    });
    sales4.push({
      title: "Reg Margin %",
      ty: decimalFormatterNormal(tyMarginPerSales.toFixed(2)),
      lyorlly: decimalFormatterNormal(
        (this.state.isLy ? lyMarginPerSales : llyMarginPerSales).toFixed(2)
      ),
      icon: RollUp4,
      percentageChange: (
        Math.abs(
          ((this.state.isLy ? lyMarginPerSales : llyMarginPerSales) -
            tyMarginPerSales) /
            (this.state.isLy ? lyMarginPerSales : llyMarginPerSales)
        ) * 100
      ).toFixed(0)
    });

    reciept1.push({
      title: "Reciept Rtl $",
      ty: toUnitNormal(tyRtlReciept.toFixed()),
      lyorlly: toUnitNormal(
        (this.state.isLy ? lyRtlReciept : llyRtlReciept).toFixed()
      ),
      icon: RollUp5,
      percentageChange: (
        Math.abs(
          ((this.state.isLy ? lyRtlReciept : llyRtlReciept) - tyRtlReciept) /
            (this.state.isLy ? lyRtlReciept : llyRtlReciept)
        ) * 100
      ).toFixed(0)
    });
    reciept2.push({
      title: "Budget $",
      ty: toUnitNormal(tyCostReciept.toFixed()),
      lyorlly: toUnitNormal(
        (this.state.isLy ? lyCostReciept : llyCostReciept).toFixed()
      ),
      icon: RollUp6,
      percentageChange: (
        Math.abs(
          ((this.state.isLy ? lyCostReciept : llyCostReciept) - tyCostReciept) /
            (this.state.isLy ? lyCostReciept : llyCostReciept)
        ) * 100
      ).toFixed(0)
    });
    reciept3.push({
      title: "Units",
      ty: toUnitNormal(tyUnitReciept.toFixed()),
      lyorlly: toUnitNormal(
        (this.state.isLy ? lyUnitReciept : llyUnitReciept).toFixed()
      ),
      infoIcon: "infoRollUp7",
      percentageChange: (
        Math.abs(
          ((this.state.isLy ? lyUnitReciept : llyUnitReciept) - tyUnitReciept) /
            (this.state.isLy ? lyUnitReciept : llyUnitReciept)
        ) * 100
      ).toFixed(0)
    });
    reciept4.push({
      title: "IMU%",
      ty: decimalFormatterNormal(tyIMUReciept.toFixed(2)),
      lyorlly: decimalFormatterNormal(
        (this.state.isLy ? lyIMUReciept : llyIMUReciept).toFixed(2)
      ),
      icon: RollUp8,
      percentageChange: (
        Math.abs(
          ((this.state.isLy ? lyIMUReciept : llyIMUReciept) - tyIMUReciept) /
            (this.state.isLy ? lyIMUReciept : llyIMUReciept)
        ) * 100
      ).toFixed(0)
    });

    productivity1.push({
      title: "CC",
      ty: toUnitNormal(tyCCProductivity.toFixed()),
      lyorlly: toUnitNormal(
        (this.state.isLy ? lyCCProductivity : llyCCProductivity).toFixed()
      ),
      icon: RollUp9,
      percentageChange: (
        Math.abs(
          ((this.state.isLy ? lyCCProductivity : llyCCProductivity) -
            tyCCProductivity) /
            (this.state.isLy ? lyCCProductivity : llyCCProductivity)
        ) * 100
      ).toFixed(0)
    });
    productivity2.push({
      title: "AUR",
      ty: toUnitNormal(tyAURProductivity.toFixed()),
      lyorlly: toUnitNormal(
        (this.state.isLy ? lyAURProductivity : llyAURProductivity).toFixed()
      ),
      icon: RollUp10,
      percentageChange: (
        Math.abs(
          ((this.state.isLy ? lyAURProductivity : llyAURProductivity) -
            tyAURProductivity) /
            (this.state.isLy ? lyAURProductivity : llyAURProductivity)
        ) * 100
      ).toFixed(0)
    });
    productivity3.push({
      title: "APS",
      ty: decimalFormatterNormal((tyAPSProductivity / data.length).toFixed(2)),
      lyorlly: decimalFormatterNormal(
        (
          (this.state.isLy ? lyAPSProductivity : llyAPSProductivity) /
          data.length
        ).toFixed(2)
      ),
      icon: RollUp11,
      percentageChange: (
        Math.abs(
          ((this.state.isLy ? lyAPSProductivity : llyAPSProductivity) -
            tyAPSProductivity) /
            (this.state.isLy ? lyAPSProductivity : llyAPSProductivity)
        ) * 100
      ).toFixed(0)
    });
    productivity4.push({
      title: "ST%",
      ty: decimalFormatterNormal(tySTProductivity.toFixed(2)),
      lyorlly: decimalFormatterNormal(
        (this.state.isLy ? lySTProductivity : llySTProductivity).toFixed(2)
      ),
      icon: RollUp12,
      percentageChange: (
        Math.abs(
          ((this.state.isLy ? lySTProductivity : llySTProductivity) -
            tySTProductivity) /
            (this.state.isLy ? lySTProductivity : llySTProductivity)
        ) * 100
      ).toFixed(0)
    });

    salesData.push(...sales1, ...sales2, ...sales3, ...sales4);
    recieptData.push(...reciept1, ...reciept2, ...reciept3, ...reciept4);
    productivityData.push(
      ...productivity1,
      ...productivity2,
      ...productivity3,
      ...productivity4
    );

    this.setState({
      salesDataCard: salesData,
      recieptDataCard: recieptData,
      productivityDataCard: productivityData
    });
  };

  getGraphData = () => {
    const data = this.state.filterData;

    let budgetData = [];
    let unitData = [];
    let choiceCountData = [];
    let doorCountData = [];
    let apsData = [];
    let depthData = [];

    for (let i = 0; i < data.length; i++) {
      if (data[i]["Total_Buy_Cost_TY"]) {
        budgetData.push({
          dept_nm: data[i]["dept_nm"],
          subclass: data[i]["subclass"],
          mps_yrmo_desc: data[i]["mps_yrmo_desc"],
          Total_Buy_Cost_TY: data[i]["Total_Buy_Cost_TY"],
          Total_Buy_Cost_LY: data[i]["Total_Buy_Cost_LY"],
          Total_Buy_Cost_LLY: data[i]["Total_Buy_Cost_LLY"]
        });
      }
      if (data[i]["Total_Buy_Units_TY"]) {
        unitData.push({
          dept_nm: data[i]["dept_nm"],
          subclass: data[i]["subclass"],
          mps_yrmo_desc: data[i]["mps_yrmo_desc"],
          Total_Buy_Units_TY: data[i]["Total_Buy_Units_TY"],
          Total_Buy_Units_LY: data[i]["Total_Buy_Units_LY"],
          Total_Buy_Units_LLY: data[i]["Total_Buy_Units_LLY"]
        });
      }
      if (data[i]["CC_TY"]) {
        choiceCountData.push({
          dept_nm: data[i]["dept_nm"],
          subclass: data[i]["subclass"],
          mps_yrmo_desc: data[i]["mps_yrmo_desc"],
          CC_TY: data[i]["CC_TY"],
          CC_LY: data[i]["CC_LY"],
          CC_LLY: data[i]["CC_LLY"]
        });
      }
      if (data[i]["Avg_Store_Count_TY"]) {
        doorCountData.push({
          dept_nm: data[i]["dept_nm"],
          subclass: data[i]["subclass"],
          mps_yrmo_desc: data[i]["mps_yrmo_desc"],
          Avg_Store_Count_TY: data[i]["Avg_Store_Count_TY"],
          Avg_Store_Count_LY: data[i]["Avg_Store_Count_LY"],
          Avg_Store_Count_LLY: data[i]["Avg_Store_Count_LLY"]
        });
      }
      if (data[i]["APS_TY"]) {
        apsData.push({
          dept_nm: data[i]["dept_nm"],
          subclass: data[i]["subclass"],
          mps_yrmo_desc: data[i]["mps_yrmo_desc"],
          APS_TY: data[i]["APS_TY"],
          APS_LY: data[i]["APS_LY"],
          APS_LLY: data[i]["APS_LLY"]
        });
      }
      if (data[i]["Depth_TY"]) {
        depthData.push({
          dept_nm: data[i]["dept_nm"],
          subclass: data[i]["subclass"],
          mps_yrmo_desc: data[i]["mps_yrmo_desc"],
          Depth_TY: data[i]["Depth_TY"],
          Depth_LY: data[i]["Depth_LY"],
          Depth_LLY: data[i]["Depth_LLY"]
        });
      }
    }
    let finalObj = {
      budget: budgetData,
      unit: unitData,
      choiceCount: choiceCountData,
      doorCount: doorCountData,
      aps: apsData,
      depth: depthData
    };

    this.setState({
      graphData: finalObj
    });
  };

  getFilters = () => {
    let { rollUpData: data } = this.props;

    let fobArray = [];
    let deptArray = [];
    let subGroupArray = [];
    let monthArray = [];

    for (let i = 0; i < data.length; i++) {
      if (data[i]["FOB"]) {
        if (!fobArray.includes(data[i]["FOB"])) {
          fobArray.push(data[i]["FOB"]);
        }
      }
      if (data[i]["dept_nm"]) {
        if (!deptArray.includes(data[i]["dept_nm"])) {
          deptArray.push(data[i]["dept_nm"]);
        }
      }
      if (data[i]["subclass"]) {
        if (!subGroupArray.includes(data[i]["subclass"])) {
          subGroupArray.push(data[i]["subclass"]);
        }
      }
      if (data[i]["mps_yrmo_desc"]) {
        if (!monthArray.includes(data[i]["mps_yrmo_desc"])) {
          monthArray.push(data[i]["mps_yrmo_desc"]);
        }
      }
    }

    let fobArrObj = [];
    let deptArrObj = [];
    let subArrObj = [];
    let monthArrObj = [];

    fobArray.forEach((ele, index) =>
      fobArrObj.push({ label: ele, value: ele })
    );
    deptArray.forEach((ele, index) =>
      deptArrObj.push({ label: ele, value: ele })
    );

    subGroupArray.forEach((ele, index) =>
      subArrObj.push({ label: ele, value: ele })
    );

    monthArray.forEach((ele, index) =>
      monthArrObj.push({ label: ele, value: ele })
    );

    this.setState({
      fobArrObj,
      deptArrObj,
      subArrObj,
      monthArrObj
    });
  };

  onFobChange = val => {
    if (val && val.length) {
      this.props.getDmmData({
        fobIds: val.map(e => Number(e.value))
      });
    }
    this.setState({
      fobIds: val,
      dmmIds: [],
      buyerIds: [],
      dcIds: [],
      departmentIds: []
    });
  };
  onDmmChange = val => {
    if (val && val.length) {
      this.props.getBuyerDataBuyRollup({
        dmmIds: val.map(e => Number(e.value))
      });
    }
    if (val && val.length === 0) {
      this.setState({
        buyerIds: [],
        dcIds: [],
        departmentIds: []
      });
    }
    this.setState({
      dmmIds: val
    });
  };
  onBuyerChange = val => {
    if (val && val.length) {
      this.props.getDcData({
        buyerIds: val.map(e => Number(e.value))
      });
    }
    if (val && val.length === 0) {
      this.setState({
        dcIds: [],
        departmentIds: []
      });
    }
    this.setState({
      buyerIds: val
    });
  };
  onDcChange = val => {
    if (val && val.length) {
      this.props.getDepartmentsAccToDCData({
        dcIds: val.map(e => Number(e.value)),
        fobIds: this.state.fobIds.map(e => Number(e.value)),
        buyerIds: this.state.buyerIds.map(e => Number(e.value)),
        dmmIds: this.state.dmmIds.map(e => Number(e.value)),
      });
    }
    if (val && val.length === 0) {
      this.setState({
        departmentIds: []
      });
    }
    this.setState({
      dcIds: val
    });
  };

  onDepartmentChange = val => {
    this.setState({
      departmentIds: val
    });
  };

  onSelectedMonthRange = date => {
    if (!date) {
      this.setState({
        selectedMonthRange: date,
        receiptStartDate: null,
        receiptEndDate: null
      });
    } else {
      this.setState({
        selectedMonthRange: date,
        receiptStartDate: moment(date[0]),
        receiptEndDate: moment(date[1])
      });
    }
  };

  onMonthClicked = date => {
    if (this.state.monthPickerStartDateSelected) {
      this.setState({
        monthPickerStartDateSelected: false,
        monthPickerEndDateSelected: true,
        receiptStartDate: moment(date),
        receiptEndDate: null,
        monthPickerIsOpen: true
      });
    } else {
      const startDate = this.state.receiptStartDate;
      const endDate = moment(date);
      let sd = startDate;
      let ed = endDate;

      if (new Date(endDate) < new Date(startDate)) {
        sd = endDate;
        ed = startDate;
      }

      this.setState({
        monthPickerStartDateSelected: true,
        monthPickerEndDateSelected: false,
        receiptStartDate: sd,
        receiptEndDate: ed,
        monthPickerIsOpen: false
      });
    }
  };

  clearMonthPickerData = e => {
    e.preventDefault();
    this.setState({
      monthPickerIsOpen: false,
      monthPickerStartDateSelected: true,
      monthPickerEndDateSelected: false,
      receiptStartDate: null,
      receiptEndDate: null
    });
  };

  onSecFOBChange = val => {
    this.setState({
      secFob: val
    });
  };
  onSecDepartmentChange = val => {
    this.setState({
      secDepartment: val
    });
  };

  onSecSubGroupChange = val => {
    this.setState({
      secSubGroup: val
    });
  };

  onSecMonthChange = val => {
    this.setState({
      secMonth: val
    });
  };

  filterData = () => {
    const { secFob, secDepartment, secSubGroup, secMonth } = this.state;
    const filteredData = filter(this.props.rollUpData, o => {
      const fobFlag =
        secFob && secFob.length
          ? !!filter(secFob, { label: o.FOB }).length
          : true;
      const deptFlag =
        secDepartment && secDepartment.length
          ? !!filter(secDepartment, { label: o.dept_nm }).length
          : true;
      const subClassFlag =
        secSubGroup && secSubGroup.length
          ? !!filter(secSubGroup, { label: o.subclass }).length
          : true;
      const monthFlag =
        secMonth && secMonth.length
          ? !!filter(secMonth, { label: o.mps_yrmo_desc }).length
          : true;
      return fobFlag && deptFlag && subClassFlag && monthFlag;
    });

    this.setState(
      {
        filterData: filteredData,
        showGraph: true,
        showDownload: true
      },
      () => this.getGraphData()
    );
  };

  onRetriveRollUp = () => {
    let { planArr } = this.state;
    this.props.getRollUpDetails({
      projectId: planArr
    });
  };

  onChangeLy = () => {
    let { planArr } = this.state;
    planArr &&
      planArr.length &&
      this.props.getRollUpDetails({
        projectId: planArr
      });
    if (!this.state.isLy) {
      this.setState(
        {
          isLy: true
        },
        () => this.getCardData()
      );
    } else {
      this.setState(
        {
          isLy: false
        },
        () => this.getCardData()
      );
    }
  };

  openPlanPopup = () => {
    let deptIds = this.state.departmentIds.map(e => Number(e.value));
    let start =
      this.state.receiptStartDate &&
      this.state.receiptStartDate.format("YYYY-MM-DD");
    let end =
      this.state.receiptEndDate &&
      this.state.receiptEndDate.format("YYYY-MM-DD");
    this.props.getBuyRollUpPlan({
      deptIds: deptIds,
      startDate: start,
      endDate: end
    });
    this.setState({
      modalVisibleForAddRow: true
    });
  };

  closeModalForAddRow() {
    this.setState({
      modalVisibleForAddRow: false
    });
  }

  onSelectPlan = () => {
    let selectedStyles = this.gridApiModal.getSelectedRows().map(ele => ele.id);

    this.setState({
      planArr: selectedStyles,
      modalVisibleForAddRow: false
    });
  };

  componentWillUnmount() {
    this.props.clearRollUpDetails();
  }

  downloadDataFile = () => {
    // this.exportFunc();
    // this.exportExcelFunc();
    this.downloadCSV();
  };

  downloadCSV = () => {
    let a2 = document.createElement("A");
    a2.href = this.props.buyRollUpDataFirst;
    document.body.appendChild(a2);
    a2.click();
    document.body.removeChild(a2);
  };

  // exportExcelFunc = () => {
  //   this.ExcelDataBuyFirst(this.props.rollUpData);
  //   this.ExcelDataBuyFirst(this.props.buyRollUpDataFirst);
  //   this.ExcelDataBuyFirst(this.props.buyRollUpDataSecond);
  // };

  // ExcelDataBuyFirst = data => {
  //   this.excelDataBuyFirst.push(data);
  //   if (this.excelDataBuyFirst.length === 3) {
  //     exportToExcelFirst(this.excelDataBuyFirst);
  //     this.excelDataBuyFirst = [];
  //   }
  // };

  // ExcelDataBuySecond = data => {
  //   this.excelDataBuySecond.push(data);
  //   exportToExcelSecond(this.excelDataBuySecond);
  //   this.excelDataBuySecond = [];
  // };

  render() {
    let {
      salesDataCard,
      recieptDataCard,
      productivityDataCard,
      showGraph,
      graphData,
      receiptStartDate,
      receiptEndDate
    } = this.state;
    let { rollUpData } = this.props;
    return (
      <>
        <div className="card">
          <label style={{ margin: "2rem 2rem" }}>Buy Rollup</label>
          <div
            style={{
              margin: "0 2rem",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div style={{ width: "14rem" }}>
              <label>FOB *</label>
              <Select
                isMulti={true}
                placeholder="Select"
                value={this.state.fobIds}
                closeMenuOnSelect={false}
                allowSelectAll={true}
                onChange={val => this.onFobChange(val)}
                options={this.props.fobList || []}
                isClearable
              />
            </div>
            <div style={{ width: "14rem" }}>
              <label>DMM *</label>
              <Select
                isMulti={true}
                placeholder="Select"
                value={this.state.dmmIds}
                isDisabled={this.state.fobIds && this.state.fobIds.length === 0}
                closeMenuOnSelect={false}
                allowSelectAll={true}
                onChange={val => this.onDmmChange(val)}
                options={this.props.dmmList || []}
                isClearable
              />
            </div>
            <div style={{ width: "14rem" }}>
              <label>Buyer *</label>
              <Select
                isMulti={true}
                placeholder="Select"
                value={this.state.buyerIds}
                isDisabled={this.state.dmmIds && this.state.dmmIds.length === 0}
                closeMenuOnSelect={false}
                allowSelectAll={true}
                onChange={val => this.onBuyerChange(val)}
                options={this.props.buyerListBuyRollup || []}
                isClearable
              />
            </div>
            <div style={{ width: "14rem" }}>
              <label>DC *</label>
              <Select
                isMulti={true}
                placeholder="Select"
                value={this.state.dcIds}
                isDisabled={
                  this.state.buyerIds && this.state.buyerIds.length === 0
                }
                closeMenuOnSelect={false}
                allowSelectAll={true}
                onChange={val => this.onDcChange(val)}
                options={this.props.dcList || []}
                isClearable
              />
            </div>
            <div style={{ width: "14rem" }}>
              <label>Department *</label>
              <Select
                isMulti={true}
                placeholder="Select"
                isClearable
                allowSelectAll={true}
                closeMenuOnSelect={false}
                isDisabled={this.state.dcIds && this.state.dcIds.length === 0}
                onChange={val => this.onDepartmentChange(val)}
                options={this.props.departmentsListAccDc || []}
                value={this.state.departmentIds}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                margin: "0 2rem",
                display: "flex",
                marginTop: "1.5rem"
              }}
            >
              <div style={{ marginRight: "3rem" }}>
                <label>Months *</label>
                <MonthPicker
                  disabled={false}
                  onSelectedMonthRange={this.onSelectedMonthRange}
                  onMonthClicked={this.onMonthClicked}
                  isOpen={this.state.monthPickerIsOpen}
                  clearMonthPickerData={this.clearMonthPickerData}
                  date={[
                    this.state.receiptStartDate,
                    this.state.receiptEndDate
                  ]}
                />
              </div>

              <div
                className="mt-4"
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  marginRight: "3rem"
                }}
              >
                LY vs TY
                <Switch
                  disabled={false}
                  checked={!this.state.isLy}
                  onClick={this.onChangeLy}
                  value={this.state.isLy}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                LLY vs TY
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <span
                    style={{
                      display: "block",
                      fontSize: "12px",
                      marginBottom: "8px",
                      fontWeight: "600"
                    }}
                  >
                    *{" "}
                    {this.state.planArr && this.state.planArr.length > 1
                      ? this.state.planArr.length + " Plans"
                      : this.state.planArr.length + " plan"}{" "}
                    Selected
                  </span>
                  <Button
                    onClick={this.openPlanPopup}
                    disabled={
                      (this.state.buyerIds && this.state.buyerIds.length === 0) ||
                      (this.state.departmentIds &&
                        this.state.departmentIds.length === 0) ||
                      !receiptStartDate ||
                      !receiptEndDate
                    }
                    style={{ width: "8rem" }}
                  >
                    Plans *
                  </Button>
                </div>
                <Modal
                  visible={this.state.modalVisibleForAddRow}
                  width="70%"
                  height="80%"
                  effect="fadeInUp"
                  onClickAway={() => this.closeModalForAddRow()}
                >
                  <div style={{ padding: "2rem 4rem" }}>
                    <p style={{ fontSize: "20px", fontWeight: "600" }}>
                      Select Plans
                    </p>
                    <div
                      style={{
                        height: "calc(100vh - 400px)",
                        width: "100%",
                        marginTop: "2rem"
                      }}
                    >
                      <DataTable
                        floatingFilter={true}
                        sizeToFit={true}
                        suppressColumnVirtualisation={true}
                        columnDefs={DashboardColDefs.columnDefs}
                        defaultColDef={DashboardColDefs.defaultColDef}
                        rowData={this.props.projectList}
                        passGridAPI={api => {
                          this.gridApiModal = api;
                        }}
                        rowSelection="multiple"
                      />
                    </div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      onClick={this.onSelectPlan}
                      disabled={false}
                      style={{ width: "8rem" }}
                    >
                      Select
                    </Button>
                  </div>
                </Modal>
                <div className="mt-4">
                  <Button
                    onClick={this.onRetriveRollUp}
                    disabled={
                      (this.state.buyerId && this.state.buyerId.length === 0) ||
                      (this.state.departmentId &&
                        this.state.departmentId.length === 0) ||
                      (this.state.planArr && this.state.planArr.length === 0)
                    }
                    style={{ width: "8rem" }}
                  >
                    Roll Up
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "3rem", marginRight: "2rem" }}>
              <Button
                style={{ width: "8rem" }}
                color="primary"
                variant="outlined"
                onClick={this.onResetClick}
              >
                Reset
              </Button>
            </div>
          </div>
          <hr />
          {rollUpData && rollUpData.length > 0 ? (
            <div>
              <h4 style={{ margin: "0 2rem", fontWeight: "600" }}>
                Sales & Margin
              </h4>
              <div
                style={{
                  display: "flex",
                  margin: "0 1rem",
                  justifyContent: "space-between"
                }}
              >
                {salesDataCard &&
                  salesDataCard.map(ele => {
                    return (
                      <RollupCard
                        title={ele.title}
                        ty={ele.ty}
                        lyorlly={ele.lyorlly}
                        icon={ele.icon}
                        infoIcon={ele.infoIcon}
                        percentageChange={ele.percentageChange}
                        lyFlag={this.state.isLy}
                      />
                    );
                  })}
              </div>
              <h4 style={{ margin: "2rem 2rem 0", fontWeight: "600" }}>
                Reciept & IMU
              </h4>
              <div
                style={{
                  display: "flex",
                  margin: "0 1rem",
                  justifyContent: "space-between"
                }}
              >
                {recieptDataCard &&
                  recieptDataCard.map(ele => {
                    return (
                      <RollupCard
                        title={ele.title}
                        ty={ele.ty}
                        lyorlly={ele.lyorlly}
                        icon={ele.icon}
                        infoIcon={ele.infoIcon}
                        percentageChange={ele.percentageChange}
                        lyFlag={this.state.isLy}
                      />
                    );
                  })}
              </div>
              <h4 style={{ margin: "2rem 2rem 0", fontWeight: "600" }}>
                Choice Count & Productivity
              </h4>
              <div
                style={{
                  display: "flex",
                  margin: "0 1rem",
                  justifyContent: "space-between"
                }}
              >
                {productivityDataCard &&
                  productivityDataCard.map(ele => {
                    return (
                      <RollupCard
                        title={ele.title}
                        ty={ele.ty}
                        lyorlly={ele.lyorlly}
                        icon={ele.icon}
                        infoIcon={ele.infoIcon}
                        percentageChange={ele.percentageChange}
                        lyFlag={this.state.isLy}
                      />
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {rollUpData && rollUpData.length > 0 ? (
          <div className="card" style={{ marginTop: "2rem" }}>
            <div className="row p-2" style={{ margin: "2rem 1rem 0" }}>
              <div className="form-group col-md-2">
                <label>FOB</label>
                <Select
                  placeholder="Select"
                  isMulti={true}
                  isClearable
                  onChange={val => this.onSecFOBChange(val)}
                  options={this.state.fobArrObj || []}
                  value={this.state.secFob}
                />
              </div>
              <div className="form-group col-md-2">
                <label>Department</label>
                <Select
                  placeholder="Select"
                  isMulti={true}
                  isClearable
                  onChange={val => this.onSecDepartmentChange(val)}
                  options={this.state.deptArrObj || []}
                  value={this.state.secDepartment}
                />
              </div>
              <div className="form-group col-md-2">
                <label>Sub Group</label>
                <Select
                  isMulti={true}
                  placeholder="Select"
                  isClearable
                  allowSelectAll={true}
                  closeMenuOnSelect={false}
                  onChange={val => this.onSecSubGroupChange(val)}
                  options={this.state.subArrObj || []}
                  value={this.state.secSubGroup}
                />
              </div>
              <div className="form-group col-md-2">
                <label>Month</label>
                <Select
                  placeholder="Select"
                  isMulti={true}
                  isClearable
                  onChange={val => this.onSecMonthChange(val)}
                  options={this.state.monthArrObj || []}
                  value={this.state.secMonth}
                />
              </div>
              <div
                className="form-group col-md-2 mt-4"
                style={{ textAlign: "center" }}
              >
                <Button onClick={this.filterData} style={{ width: "8rem" }}>
                  Filter
                </Button>
              </div>
              {/* {this.state.showDownload ? ( */}
              <div
                className="form-group col-md-2 mt-4"
                style={{ fontSize: "1.2rem" }}
              >
                <Button
                  onClick={this.downloadDataFile}
                  disabled={false}
                  style={{ width: "8rem" }}
                >
                  <span style={{ marginLeft: "0.25rem" }}>Download</span>
                  <i
                    className="fa fa-download p-2 pointer"
                    style={{
                      cursor: "pointer",
                      color: "#fff"
                    }}
                    aria-hidden="true"
                  ></i>
                </Button>
              </div>
              {/* ) : (
                ""
              )} */}
            </div>
            <hr />
            {showGraph ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "0 2rem",
                  justifyContent: "space-between"
                }}
              >
                <div
                  style={{
                    width: "48%",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    marginBottom: "2rem"
                  }}
                >
                  <h5
                    style={{
                      backgroundColor: "#2c6ae8",
                      padding: "0.75rem 2rem",
                      color: "#fff",
                      fontWeight: "600",
                      marginTop: "-0.2rem",
                      borderRadius: "10px 10px 0 0"
                    }}
                  >
                    {this.state.isLy ? "LY" : "LLY"} vs TY Budget
                  </h5>
                  <div
                    style={{
                      width: "90%",
                      margin: "0 auto",
                      minHeight: "22rem"
                    }}
                  >
                    {graphData &&
                    graphData["budget"] &&
                    graphData["budget"].length === 0 ? (
                      <div style={{ textAlign: "center", fontWeight: "600" }}>
                        No Data Available
                      </div>
                    ) : (
                      <BarChartComponent
                        options={BudgetOptions}
                        chartName="budget"
                        data={this.state.graphData}
                        lyFlag={this.state.isLy}
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "48%",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    marginBottom: "2rem"
                  }}
                >
                  <h5
                    style={{
                      backgroundColor: "#2c6ae8",
                      padding: "0.75rem 2rem",
                      color: "#fff",
                      fontWeight: "600",
                      marginTop: "-0.2rem",
                      borderRadius: "10px 10px 0 0"
                    }}
                  >
                    {this.state.isLy ? "LY" : "LLY"} vs TY Units
                  </h5>
                  <div
                    style={{
                      width: "90%",
                      margin: "0 auto",
                      minHeight: "22rem"
                    }}
                  >
                    {graphData &&
                    graphData["unit"] &&
                    graphData["unit"].length === 0 ? (
                      <div style={{ textAlign: "center", fontWeight: "600" }}>
                        No Data Available
                      </div>
                    ) : (
                      <BarChartComponent
                        options={UnitOptions}
                        chartName="unit"
                        data={this.state.graphData}
                        lyFlag={this.state.isLy}
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "48%",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    marginBottom: "2rem"
                  }}
                >
                  <h5
                    style={{
                      backgroundColor: "#2c6ae8",
                      padding: "0.75rem 2rem",
                      color: "#fff",
                      fontWeight: "600",
                      marginTop: "-0.2rem",
                      borderRadius: "10px 10px 0 0"
                    }}
                  >
                    {this.state.isLy ? "LY" : "LLY"} vs TY Choice Count
                  </h5>
                  <div
                    style={{
                      width: "90%",
                      margin: "0 auto",
                      minHeight: "22rem"
                    }}
                  >
                    {graphData &&
                    graphData["choiceCount"] &&
                    graphData["choiceCount"].length === 0 ? (
                      <div style={{ textAlign: "center", fontWeight: "600" }}>
                        No Data Available
                      </div>
                    ) : (
                      <BarChartComponent
                        options={ChoiceCountOptions}
                        chartName="choiceCount"
                        data={this.state.graphData}
                        lyFlag={this.state.isLy}
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "48%",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    marginBottom: "2rem"
                  }}
                >
                  <h5
                    style={{
                      backgroundColor: "#2c6ae8",
                      padding: "0.75rem 2rem",
                      color: "#fff",
                      fontWeight: "600",
                      marginTop: "-0.2rem",
                      borderRadius: "10px 10px 0 0"
                    }}
                  >
                    {this.state.isLy ? "LY" : "LLY"} vs TY Door Count
                  </h5>
                  <div
                    style={{
                      width: "90%",
                      margin: "0 auto",
                      minHeight: "22rem"
                    }}
                  >
                    {graphData &&
                    graphData["doorCount"] &&
                    graphData["doorCount"].length === 0 ? (
                      <div style={{ textAlign: "center", fontWeight: "600" }}>
                        No Data Available
                      </div>
                    ) : (
                      <BarChartComponent
                        options={DoorCountOptions}
                        chartName="doorCount"
                        data={this.state.graphData}
                        lyFlag={this.state.isLy}
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "48%",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    marginBottom: "2rem"
                  }}
                >
                  <h5
                    style={{
                      backgroundColor: "#2c6ae8",
                      padding: "0.75rem 2rem",
                      color: "#fff",
                      fontWeight: "600",
                      marginTop: "-0.2rem",
                      borderRadius: "10px 10px 0 0"
                    }}
                  >
                    {this.state.isLy ? "LY" : "LLY"} vs TY APS
                  </h5>
                  <div
                    style={{
                      width: "90%",
                      margin: "0 auto",
                      minHeight: "22rem"
                    }}
                  >
                    {graphData &&
                    graphData["aps"] &&
                    graphData["aps"].length === 0 ? (
                      <div style={{ textAlign: "center", fontWeight: "600" }}>
                        No Data Available
                      </div>
                    ) : (
                      <BarChartComponent
                        options={ApsOptions}
                        chartName="aps"
                        data={this.state.graphData}
                        lyFlag={this.state.isLy}
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "48%",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    marginBottom: "2rem"
                  }}
                >
                  <h5
                    style={{
                      backgroundColor: "#2c6ae8",
                      padding: "0.75rem 2rem",
                      color: "#fff",
                      fontWeight: "600",
                      marginTop: "-0.2rem",
                      borderRadius: "10px 10px 0 0"
                    }}
                  >
                    {this.state.isLy ? "LY" : "LLY"} vs TY Depth
                  </h5>
                  <div
                    style={{
                      width: "90%",
                      margin: "0 auto",
                      minHeight: "22rem"
                    }}
                  >
                    {graphData &&
                    graphData["depth"] &&
                    graphData["depth"].length === 0 ? (
                      <div style={{ textAlign: "center", fontWeight: "600" }}>
                        No Data Available
                      </div>
                    ) : (
                      <BarChartComponent
                        options={DepthOptions}
                        chartName="depth"
                        data={this.state.graphData}
                        lyFlag={this.state.isLy}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {showGraph ? (
              <div
                style={{
                  height: "calc(100vh - 500px)",
                  minHeight: "300px",
                  margin: "2rem 1.5rem"
                }}
              >
                <DataTable
                  excelRefsRollup={funref => {
                    this.exportFunc = funref;
                  }}
                  excelStyles={excelStyle}
                  autoGroupColumnDef={{
                    headerName: "Key Items",
                    width: 250,
                    pinned: "left",
                    field: "mps_yrmo_desc",
                    cellRenderer: "agGroupCellRenderer"
                  }}
                  suppressColumnVirtualisation={true}
                  columnDefs={BuyRollUpColDefs.columnDefs(this.state.isLy)}
                  defaultColDef={BuyRollUpColDefs.defaultColDef}
                  rowData={this.state.filterData}
                  groupDefaultExpanded={2}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapStateToProps = store => {
  return {
    fobList: store.filtersReducer.fobList,
    dmmList: store.filtersReducer.dmmList,
    buyerListBuyRollup: store.filtersReducer.buyerListBuyRollup,
    dcList: store.filtersReducer.dcList,
    departmentsListAccDc: store.filtersReducer.departmentsListAccDc,
    projectList: store.buyRollUpReducers.projectList,
    rollUpData: store.buyRollUpReducers.rollUpData,
    buyRollUpDataFirst: store.buyRollUpReducers.buyRollUpDataFirst
    // buyRollUpDataSecond: store.buyRollUpReducers.buyRollUpDataSecond,
    // buyRollUpDataThird: store.buyRollUpReducers.buyRollUpDataThird
  };
};

const mapDispatchToProps = {
  getFobData: FilterActions.getFobData,
  getDmmData: FilterActions.getDmmData,
  getBuyerDataBuyRollup: FilterActions.getBuyerDataBuyRollup,
  getDcData: FilterActions.getDcData,
  getDepartmentsAccToDCData: FilterActions.getDepartmentsAccToDCData,
  clearFobData: FilterActions.clearFobData,
  clearDmmData: FilterActions.clearDmmData,
  clearBuyerDataBuyRollup: FilterActions.clearBuyerDataBuyRollup,
  clearDcData: FilterActions.clearDcData,
  clearDepartmentsAccToDCData: FilterActions.clearDepartmentsAccToDCData,
  getRollUpDetails: buyRollUpActions.getRollUpDetails,
  getBuyRollUpPlan: buyRollUpActions.getBuyRollUpPlan,
  clearRollUpDetails: buyRollUpActions.clearRollUpDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BuyRollup));

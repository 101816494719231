import { toPercentage, toUnit } from "../../../../helpers/formatters";
import { inputCss } from "../../../../styles";

export default function classLevelDepthAndChoiceColumnDefs(
  clusterData,
  clusters,
  historicalParameterData,
  userAccessDisableFlag
) {
  let tempObj = [];
  if (historicalParameterData.length) {
    tempObj.push({
      headerName: "CLASS",
      menuTabs: [],
      sortable: true,
      tooltipField: "class/vendor",
      width: 200,
      resizable: true,
      pinned: "left",
      unSortIcon: true,
      field: "class/vendor"
    });
    tempObj.push({
      headerName: "SUBCLASS/GROUPING",
      field: "subclass",
      sortable: true,
      pinned: "left",
      width: 200,
      resizable: true,
      tooltipField: "subclass",
      menuTabs: [],
      unSortIcon: true
    });
    tempObj.push({
      headerName: "MIN B&M",
      headerTooltip: "Rounded to the nearest increment multiple",
      width: 71,
      field: "MIN B&M",
      sortable: true,
      unSortIcon: true,
      menuTabs: [],
      valueFormatter: toUnit,
      editable: !userAccessDisableFlag && true,
      cellStyle: function(params) {
        // if (params.node.rowIndex !== 0) {
        return inputCss;
        // } else {
        //   return massApplyCell;
        // }
      }
    });
    tempObj.push({
      headerName: "MAX B&M",
      width: 72,
      menuTabs: [],
      field: "MAX B&M",
      sortable: true,
      unSortIcon: true,
      valueFormatter: toUnit,
      headerTooltip:
        "Max depth across all the clusters * max Depth multiplier value",
      editable: !userAccessDisableFlag && true,
      cellStyle: function(params) {
        // if (params.node.rowIndex !== 0) {
        return inputCss;
        // } else {
        //   return massApplyCell;
        // }
      }
    });
    if (clusterData.length && clusters.includes("0")) {
      tempObj.push({
        headerName: "MIN ECOM",
        headerTooltip: "Rounded to the nearest increment multiple",
        field: "MIN ECOM",
        width: 79,
        menuTabs: [],
        sortable: true,
        unSortIcon: true,
        valueFormatter: toUnit,
        editable: !userAccessDisableFlag && true,
        cellStyle: function(params) {
          // if (params.node.rowIndex !== 0) {
          return inputCss;
          // } else {
          //   return massApplyCell;
          // }
        }
      });
      tempObj.push({
        headerName: "MAX ECOM",
        field: "MAX_ECOM",
        sortable: true,
        width: 79,
        menuTabs: [],
        unSortIcon: true,
        valueFormatter: toUnit,
        headerTooltip: "Depth for ecom * max Depth multiplier value",
        editable: !userAccessDisableFlag && true,
        cellStyle: function(params) {
          // if (params.node.rowIndex !== 0) {
          return inputCss;
          // } else {
          //   return massApplyCell;
          // }
        }
      });
    }
    tempObj.push({
      headerName: "MIN ST%",
      width: 73,
      field: "minST%",
      sortable: true,
      unSortIcon: true,
      valueFormatter: toUnit,
      menuTabs: [],
      editable: !userAccessDisableFlag && true,
      cellStyle: function(params) {
        // if (params.node.rowIndex !== 0) {
        return inputCss;
        // } else {
        //   return massApplyCell;
        // }
      }
    });
    tempObj.push({
      headerName: "MAX ST%",
      field: "maxST%",
      menuTabs: [],
      width: 72,
      sortable: true,
      unSortIcon: true,
      valueFormatter: toUnit,
      editable: !userAccessDisableFlag && true,
      cellStyle: function(params) {
        // if (params.node.rowIndex !== 0) {
        return inputCss;
        // } else {
        //   return massApplyCell;
        // }
      }
    });
    tempObj.push({
      headerName: "INCREMENTS B&M",
      field: "INCREMENTS B&M",
      width: 123,
      sortable: true,
      unSortIcon: true,
      valueFormatter: toUnit,
      menuTabs: [],
      editable: !userAccessDisableFlag && true,
      cellStyle: function(params) {
        // if (params.node.rowIndex !== 0) {
        return inputCss;
        // } else {
        //   return massApplyCell;
        // }
      }
    });
    if (clusters.includes("0")) {
      tempObj.push({
        headerName: "INCREMENTS ECOM",
        field: "INCREMENTS ECOM",
        width: 123,
        sortable: true,
        unSortIcon: true,
        menuTabs: [],
        valueFormatter: toUnit,
        editable: !userAccessDisableFlag && true,
        cellStyle: function(params) {
          // if (params.node.rowIndex !== 0) {
          return inputCss;
          // } else {
          //   return massApplyCell;
          // }
        }
      });
    }
    tempObj.push({
      headerName: "Variance Per Month",
      field: "variancePerMonth",
      width: 123,
      sortable: true,
      unSortIcon: true,
      menuTabs: [],
      valueFormatter: toPercentage,
      editable: !userAccessDisableFlag && true,
      cellStyle: inputCss
    });
    tempObj.push({
      headerName: "Variance Per Cluster",
      field: "variancePerCluster",
      width: 123,
      sortable: true,
      unSortIcon: true,
      menuTabs: [],
      valueFormatter: toPercentage,
      editable: !userAccessDisableFlag && true,
      cellStyle: inputCss
    });
  }
  return tempObj;
}

import {
  Button,
  Step,
  StepButton,
  Stepper,
  Typography
} from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import Tippy from "@tippy.js/react";
import Switch from "@material-ui/core/Switch";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { cloneDeep, isEqual, map, uniq } from "lodash";
import numeral from "numeral";
import React, { Component } from "react";
import Modal from "react-awesome-modal";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import AssortmentPlanningActions from "../../actions/AssortmentPlanningActions";
import BuyOptimizationActions from "../../actions/BuyOptimizationActions";
import Alert from "../../services/alert";
import Select from "../../services/selectAll";
import DataTable from "../../services/dataTable";
import { errorInput, inputCss, tar } from "../../styles";
import Step1ReceiptPlanner from "./Step1ReceiptPlanner";
import Step2LineArchitecture from "./Step2LineArchitecture";
import Step3ClusterAndParameters from "./Step3ClusterAndParameters";
import Step5AutoApx from "./Step5AutoApx";
import {
  classNameKey,
  subClassNameKey,
  rcptPenePercentKey,
  aurKey
} from "../../constants/keys";
import { downloadTable } from "../../utils/exportxlsx";
let classOptionsAPX = null;
let subClassOptionsAPX = null;

class AssortmentPlanning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleRollup: false,
      selectedMonth: null,
      selectedBuyerEmailId: null,
      modalVisible: false,
      activeStep: props.intitialActiveStep,
      isNextAlertVisible: false,
      isDepthChoiceAlertVisible: false,
      isRcptPeneAlertVisible: false,
      isSaveAlertVisible: false,
      completed: new Set(),
      skipped: new Set(),
      buyersList: [],
      steps: [
        "Receipt Plan",
        "Line Architecture",
        "Cluster, Depth and Choice Counts",
        "Assortment Wedge"
      ],

      // All temporary data
      templineReceiptData: cloneDeep(this.props.lineReceiptData),
      tempPlannedBudget: cloneDeep(this.props.plannedBudget),
      templineReceiptlevel: this.props.lineReceiptlevel,
      templineReceiptHighlevel: this.props.lineReceiptHighlevel,
      tempUserEditableLineReceipt: cloneDeep(
        this.props.userEditableLineReceipt
      ),

      tempplannerTotalBudget: this.props.plannerTotalBudget,
      templineArchitectureDetails: cloneDeep(
        this.props.lineArchitectureDetails
      ),
      tempclusterAndParameterDetails: cloneDeep(
        this.props.clusterAndParameterDetails
      ),

      tempclusterData: cloneDeep(this.props.clusterData),
      temphistoricalParameterData: cloneDeep(
        this.props.historicalParameterData
      ),
      tempclusters: cloneDeep(this.props.clusters),
      tempmaxSellThrough: this.props.maxSellThrough,
      tempminSellThrough: this.props.minSellThrough,
      tempgoodBetterBestTable: cloneDeep(this.props.goodBetterBestTable),
      tempclusterSummaryTable: cloneDeep(this.props.clusterSummaryTable),
      grandTotal: [],
      omniRcpt: 0,
      omniIncr: 0,
      omniRcptPlanDollarFlag: !props.omniRcptPlanDollarFlag ? false : true
    };
  }
  componentDidMount() {
    if (this.props.lineReceiptData && this.props.lineReceiptData.length) {
      if (!Object.keys(this.props.lineReceiptGrandTotal).length) {
        this.setGrandAndSubclassTotal({});
      } else {
        this.setGrandAndSubclassTotal(this.props.lineReceiptGrandTotal);
      }
    }
    this.props.getBuyers().then(resp => {
      resp.success &&
        this.setState({
          buyersList: resp.data.map(buyer => {
            return { label: buyer.email, value: buyer.id };
          })
        });
    });
    this.depthAndChoiceSum();
  }
  componentDidUpdate(oldProps, oldState) {
    if (
      oldProps.historicalParameterData !== this.props.historicalParameterData
    ) {
      let classoptions = uniq(
        map(this.props.historicalParameterData, e => e["class/vendor"])
      ).map(val => {
        return {
          value: val,
          label: val
        };
      });
      let subClass = uniq(
        map(this.props.historicalParameterData, e => e["subclass"])
      ).map(val => {
        return {
          value: val,
          label: val
        };
      });
      classOptionsAPX = classoptions;
      subClassOptionsAPX = subClass;
    }

    if (this.props.stage === 2 && this.props.subStage !== oldProps.subStage) {
      this.setState({
        activeStep: this.props.subStage - 1
      });
    }

    if (
      oldProps.clusterAndParameterDetails !==
      this.props.clusterAndParameterDetails
    ) {
      this.depthAndChoiceSum();
    }
  }
  componentWillUnmount() {
    this.props.dataChangedInAssortment3(false);
    this.props.dataChangedInAssortment4(false);
  }
  depthAndChoiceSum = () => {
    let hist_param = Object.values(this.props.clusterAndParameterDetails);
    let depthSum = 0;
    let choiceSum = 0;
    hist_param.forEach(histItem => {
      for (const subClass in histItem.historicalParameterData) {
        for (const clusItem of histItem.clusters) {
          choiceSum =
            choiceSum +
            histItem.historicalParameterData[subClass][
              `choiceCluster${clusItem}`
            ];
          depthSum =
            depthSum +
            histItem.historicalParameterData[subClass][
              `depthCluster${clusItem}`
            ];
        }
      }
    });
    this.setState({
      depthSum: depthSum,
      choiceSum: choiceSum
    });
  };
  setSubclassTotal = (lineReceiptData, isReset) => {
    let [subclassTotal] = cloneDeep(this.state.grandTotal);
    if (isReset) {
      subclassTotal = this.getTotalDataObject(lineReceiptData);
    } else {
      subclassTotal = this.getTotalDataObject(this.props.lineReceiptData);
    }
    this.setState({ grandTotal: [subclassTotal] });
  };
  getTotalDataObject = (data, isFinalTotal) => {
    let tempObj = {};
    if (data.length) {
      let tempKeyArr = Object.keys(data[0]);
      tempKeyArr.forEach(ele => {
        if (ele !== "Financial_year" || ele !== this.props.lineReceiptlevel) {
          tempObj[ele] = 0;
        }
      });
      data.forEach(ele => {
        if (ele)
          for (let key in tempObj) {
            if (
              // Check the condition if this function is getting call for getting total of user data
              isFinalTotal ||
              // && ele[this.props.lineReceiptlevel] !== "ECOMMERCE" &&
              // ele[this.props.lineReceiptlevel] !== "REPLENISHMENT"
              (ele[this.props.lineReceiptlevel] === "OTHERS" &&
                this.props.projectDetails.ecomm_f) || //removed ecomm_f flag condition for now as per requirement explained by akshay
              (ele[this.props.lineReceiptlevel] === "REPLENISHMENT" &&
                this.props.projectDetails.rpl_f) //removed rpl_f flag condition for now as per requirement explained by akshay
            ) {
              // Adding if and only if:
              // 1. isFinalTotal = true &&
              // 2. in REPLENISHMENT case REPLENISHMENT should not  be included &&
              // 3. In Ecommerce case  ECOMMERCE should be excluded
              tempObj[key] += ele[key];
            } else if (!isFinalTotal) {
              // Adding if and only if isFinalTotal flag is false
              tempObj[key] += ele[key];
            }
          }
      });
      tempObj[this.props.lineReceiptlevel] =
        tempObj[this.props.lineReceiptlevel] ===
        "0DROP SHIPRESERVEREPLENISHMENTOTHERS"
          ? "TOTAL RECEIPTS HELD"
          : "TOTAL RECEIPTS PLANNED";
    }
    return tempObj;
  };
  setGrandAndSubclassTotal = lineReceiptGrandTotal => {
    let subTotalClass = this.getTotalDataObject(this.props.lineReceiptData);

    // Passing true flag in getTotalData function call for chchecking Ecomm and REPLENISHMENT condition
    if (
      this.props.lineReceiptGrandTotal &&
      Object.keys(this.props.lineReceiptGrandTotal).length &&
      this.props.lineReceiptGrandTotal[this.props.lineReceiptlevel]
    ) {
      this.setState({
        grandTotal: [subTotalClass] //this.props.lineReceiptGrandTotal,
      });
    } else {
      let finalObj = {};
      let subTotalUserData = this.getTotalDataObject(
        this.props.userEditableLineReceipt,
        true
      );
      let tempKeyArr = Object.keys(subTotalClass);
      tempKeyArr.forEach(ele => {
        if (
          (!this.props.projectDetails.ecomm_f && ele !== "Financial_year") ||
          ele !== this.props.lineReceiptlevel
        ) {
          finalObj[ele] = subTotalClass[ele] - subTotalUserData[ele];
        }
      });
      finalObj[this.props.lineReceiptlevel] = "GRAND TOTAL";
      this.setState({
        grandTotal: [subTotalClass] //finalObj
      });
      // this.props.setLineReceiptGrandTotal(finalObj);
    }
  };
  updateClusterAndParameterScreenTempData(clusterAndParameterDetails) {
    this.setState({
      tempclusterAndParameterDetails: cloneDeep(clusterAndParameterDetails)
    });
  }
  createInputForDepthMultiplier = () => {
    //API feed for runDepthMultiplier
    let depthMulInputObj = {};
    const histParamData = cloneDeep(this.props.clusterAndParameterDetails);

    //Looping on month level to get class and subclass data
    for (let month in histParamData) {
      const subclassData = Object.values(
        histParamData[month].historicalParameterData
      );
      const classData = uniq(
        subclassData.map(classKey => classKey["class/vendor"])
      );

      // class level arr after calculating averages on subclass level.
      let classLevelAvgAttValues = [];

      //calculating averages for APS, ST%, Reg-Weeks in class level and summing the CC values in subclass level for respective class.
      classData.forEach(classKey => {
        let classObj = {};
        let subclassInClassLevelCount = 0;
        let aps = 0;
        let stPer = 0;
        let regWeeks = 0;
        let maxCC = 0;
        subclassData.forEach(subclassObj => {
          if (classKey === subclassObj["class/vendor"]) {
            aps += +subclassObj["TY APS"];
            stPer += +subclassObj["TY ST%"];
            regWeeks += +subclassObj["reg_week"];
            maxCC += +subclassObj["CC"];
            subclassInClassLevelCount += 1;
            classObj["class"] = subclassObj["class/vendor"];
          }
        });
        classObj["avgAps"] =
          subclassInClassLevelCount && aps / subclassInClassLevelCount;
        classObj["avgST%"] =
          subclassInClassLevelCount && stPer / subclassInClassLevelCount;
        classObj["avgRegWeek"] =
          subclassInClassLevelCount && regWeeks / subclassInClassLevelCount;
        classObj["maxCC"] = maxCC;
        classLevelAvgAttValues.push(classObj);
      });

      //creating month as key and value as class level array.
      depthMulInputObj[month] = classLevelAvgAttValues;
    }
    return this.props.runDepthMultiplier({
      depthMultiplierInput: depthMulInputObj,
      projectId: +this.props.match.params.id
    });
  };
  conditionalInput(params) {
    if (!params.value) {
      return errorInput;
    } else {
      return inputCss;
    }
  }
  rollUpDataFormatter = params => {
    if (params) {
      if (
        params.data.header === "VARIATION(%)" ||
        ((params.data.header === "TY" ||
          params.data.header === "LY" ||
          params.data.header === "LLY" ||
          params.data.header === "TY(2-3)" ||
          params.data.header === "DIFFERENCE") &&
          params.colDef.field.includes("imu"))
      )
        return numeral((params.value / 100).toFixed(10)).format("0.00%") ===
          "0.00%"
          ? "-"
          : numeral((params.value / 100).toFixed(10)).format("0.00%");
      else if (params.colDef.field.includes("budget"))
        return numeral(params.value).format("$0,0");
      else return numeral(params.value).format("0,0");
    }
  };
  redefineColDef = obj => {
    let tempColDef_dummy = [];
    let months = Object.keys(this.props.lineArchitectureDetails);
    let monthArr = this.getFeasibleMonths();

    //setting month split for seasons
    if (months[0] === "Seasonal" && this.state.activeStep === 3) {
      months = this.props.rcptMonths;
      monthArr = months;
    }
    months && months.sort((a, b) => monthArr.indexOf(a) - monthArr.indexOf(b));
    if (months) {
      let tempMonthObj_Budget = [];
      let tempMonthObj_Unit = [];
      let tempMonthObj_Imu = [];
      tempColDef_dummy.push({
        headerName: "",
        width: 120,
        resizable: true,
        field: "header"
      });

      //setting month split for seasons
      if (
        Object.keys(this.props.lineArchitectureDetails)[0] === "Seasonal" &&
        this.state.activeStep === 3
      ) {
        tempMonthObj_Budget.push({
          field: `Seasonal_budget`,
          headerName: "SEASONAL",
          width: 150,
          resizable: true,
          cellStyle: tar,
          valueFormatter: val => this.rollUpDataFormatter(val)
        });
        tempMonthObj_Unit.push({
          headerName: "SEASONAL",
          cellStyle: tar,
          width: 150,
          resizable: true,
          valueFormatter: val => this.rollUpDataFormatter(val),
          field: `Seasonal_unit`
        });
        tempMonthObj_Imu.push({
          headerName: "SEASONAL",
          cellStyle: tar,
          width: 150,
          resizable: true,
          valueFormatter: val => this.rollUpDataFormatter(val),
          field: `Seasonal_imu`
        });
      }
      months.forEach((month, index) => {
        tempMonthObj_Budget.push({
          field: `${month}_budget`,
          headerName: month.toUpperCase(),
          width: 150,
          resizable: true,
          cellStyle: tar,
          valueFormatter: val => this.rollUpDataFormatter(val)
        });
        tempMonthObj_Unit.push({
          headerName: month.toUpperCase(),
          cellStyle: tar,
          width: 150,
          resizable: true,
          valueFormatter: val => this.rollUpDataFormatter(val),
          field: `${month}_unit`
        });
        tempMonthObj_Imu.push({
          headerName: month.toUpperCase(),
          cellStyle: tar,
          width: 150,
          resizable: true,
          valueFormatter: val => this.rollUpDataFormatter(val),
          field: `${month}_imu`
        });
      });
      if (!this.props.projectDetails.is_season_level) {
        tempMonthObj_Budget.push({
          headerName: "TOTAL",
          width: 100,
          resizable: true,
          valueFormatter: val => this.rollUpDataFormatter(val),
          field: "total_budget"
        });
        tempMonthObj_Unit.push({
          headerName: "TOTAL",
          width: 100,
          resizable: true,
          valueFormatter: val => this.rollUpDataFormatter(val),
          field: "total_unit"
        });
        tempMonthObj_Imu.push({
          headerName: "WEIGHTED AVG",
          width: 110,
          resizable: true,
          valueFormatter: val => this.rollUpDataFormatter(val),
          field: "total_imu"
        });
      }
      if (this.state.activeStep === 3) {
        tempColDef_dummy.push({
          headerName: "BUDGET($)",
          width: 100,
          resizable: true,
          children: tempMonthObj_Budget
        });
        tempColDef_dummy.push({
          headerName: "UNITS",
          width: 100,
          resizable: true,
          children: tempMonthObj_Unit
        });
      } else {
        tempColDef_dummy.push({
          headerName: "BUDGET($)",
          width: 100,
          resizable: true,
          children: tempMonthObj_Budget
        });
        tempColDef_dummy.push({
          headerName: "UNITS",
          width: 100,
          resizable: true,
          children: tempMonthObj_Unit
        });
        // tempColDef_dummy.push({
        //   headerName: "IMU",
        //   width: 100,
        //   resizable: true,
        //   children: tempMonthObj_Imu
        // });
      }
    }
    return tempColDef_dummy;
  };
  getRollUpTable = () => {
    return this.props.projectDetails.hasAttributeClustering &&
      this.state.activeStep === 3 ? (
      ""
    ) : (
      <div className="my-4">
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Receipt Summary
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  minWidth: "300px"
                }}
              >
                <div
                  style={{
                    height: "223px",
                    minHeight: "223px"
                  }}
                >
                  <DataTable
                    columnDefs={this.redefineColDef(this.state.activeStep)}
                    rowData={this.getRollUpData()}
                  />
                </div>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  };
  getRollUpData = () => {
    /**
     This function is for getting roll up data for particular tab, depending on active step.
     */
    let tempRowData = [
      {
        header: "TY",
        total_budget: 0,
        total_unit: 0,
        total_imu: 0,
        total_imu_temp: 0
      },
      {
        header:
          this.state.activeStep === 3
            ? "TY(2-3)"
            : this.props.lyFlag
            ? "LY"
            : "LLY",
        total_budget: 0,
        total_unit: 0,
        total_imu: 0,
        total_imu_temp: 0
      },
      {
        header: "DIFFERENCE",
        total_budget: 0,
        total_unit: 0,
        total_imu: 0
      },
      {
        header: "VARIATION(%)",
        total_budget: 0,
        total_unit: 0,
        total_imu: 0
      }
    ];
    let tempMonthArr = null;
    let tempRCPTPen = 0;
    switch (this.state.activeStep) {
      case 1:
        /*-------------Formulae-------------*/
        /* 
        Budget: 
          TY: total RCPT$
          LY: total ly_rcpt$
        Unit:
          TY: total u_rcpt
          LY: total ly_rcpt_u 
        */
        tempMonthArr = Object.keys(this.props.lineArchitectureDetails);
        tempMonthArr.forEach(month => {
          let tempObj = {
            TY_Budget: 0,
            LY_Budget: 0,
            TY_Unit: 0,
            LY_Unit: 0,
            TY_Imu: 0,
            LY_Imu: 0
          };
          tempRCPTPen = 0;
          if (this.props.lineArchitectureDetails[month])
            this.props.lineArchitectureDetails[
              month
            ].data.Line_Architechture.forEach(ele => {
              tempObj.TY_Budget += ele.rcpt$;
              tempObj.LY_Budget += ele.ly_rcpt$;
              tempObj.TY_Unit += ele.rcpt_u;
              tempObj.LY_Unit += ele.ly_rcpt_u;
              tempObj.TY_Imu += ele["TY_imu%"] * ele["rcpt_pen%"];
              tempObj.LY_Imu += ele["LY_imu%"] * ele["rcpt_pen%"];
              if (ele["TY_imu%"]) tempRCPTPen += ele["rcpt_pen%"];
            });
          if (tempRCPTPen) {
            tempObj.TY_Imu = tempObj.TY_Imu / tempRCPTPen;
            tempObj.LY_Imu = tempObj.LY_Imu / tempRCPTPen;
          }
          this.getRowData(tempRowData, tempObj, month);
        });
        break;
      case 2:
        /*-------------Formulae-------------*/
        /* 
        Budget: 
          TY: total ACT RCPT$
          LY: total ly_rcpt$ from Line Architecture details
        Unit:
          TY: (Budget TY / total AUR from Choice Count)
          LY: total ly_rcpt_u from Line Architecture details
        */
        tempMonthArr = Object.keys(this.props.clusterAndParameterDetails);
        tempMonthArr.forEach(month => {
          let tempObj = {
            TY_Budget: 0,
            LY_Budget: 0,
            TY_Unit: 0,
            LY_Unit: 0,
            TY_Imu: 0,
            LY_Imu: 0
          };
          tempRCPTPen = 0;
          let tempRCPT = 0;
          let clusterHistoricalData = this.props.clusterAndParameterDetails[
            month
          ].historicalParameterData;
          for (let subClass in clusterHistoricalData) {
            tempObj.TY_Budget += clusterHistoricalData[subClass]["ACT RCPT $"];
            tempObj.TY_Unit +=
              clusterHistoricalData[subClass]["aur"] *
              clusterHistoricalData[subClass]["rcpt_pen%"];
            tempRCPT += clusterHistoricalData[subClass]["rcpt_pen%"];
          }
          tempObj.TY_Unit = tempObj.TY_Budget / (tempObj.TY_Unit / tempRCPT);
          this.props.lineArchitectureDetails[
            month
          ].data.Line_Architechture.forEach(ele => {
            tempObj.LY_Budget += ele.ly_rcpt$;
            tempObj.LY_Unit += ele.ly_rcpt_u;
            tempObj.TY_Imu += ele["TY_imu%"] * ele["rcpt_pen%"];
            tempObj.LY_Imu += ele["LY_imu%"] * ele["rcpt_pen%"];
            if (ele["TY_imu%"]) tempRCPTPen += ele["rcpt_pen%"];
          });
          if (tempRCPTPen) {
            tempObj.TY_Imu = tempObj.TY_Imu / tempRCPTPen;
            tempObj.LY_Imu = tempObj.LY_Imu / tempRCPTPen;
          }
          this.getRowData(tempRowData, tempObj, month);
        });
        break;
      case 3:
        /*-------------Formulae-------------*/
        /* 
        Budget: 
          TY: total RCPT$
          LY: total ly_rcpt$ from Line Architecture details
        Unit:
          TY: total u_rcpt
          LY: total ly_rcpt_u from Line Architecture details
        */
        tempMonthArr = Object.keys(this.props.clusterAndParameterDetails);
        // tempMonthArr.forEach(month => {
        //   let clusterHistoricalData = this.props.clusterAndParameterDetails[
        //     month
        //   ].historicalParameterData;
        //   for (let subClass in clusterHistoricalData) {
        //     tybudgetcase2 += clusterHistoricalData[subClass]["ACT RCPT $"];
        //     tyunitcase2 +=
        //       clusterHistoricalData[subClass]["aur"] *
        //       clusterHistoricalData[subClass]["rcpt_pen%"];
        //     tyrcptcase2 += clusterHistoricalData[subClass]["rcpt_pen%"];
        //   }
        //   tyunitcase2 = tybudgetcase2 / (tyunitcase2 / tyrcptcase2);
        // });
        tempMonthArr.forEach(month => {
          let tempObj = {
            TY_Budget: 0,
            LY_Budget: 0,
            TY_Unit: 0,
            LY_Unit: 0,
            TY_Imu: 0,
            LY_Imu: 0
          };
          tempRCPTPen = 0;
          let tybudgetcase2 = 0;
          let tyunitcase2 = 0;
          let tyrcptcase2 = 0;
          let clusterHistoricalData = this.props.clusterAndParameterDetails[
            month
          ].historicalParameterData;
          let tempTotalBudgetUnit = this.getAutoAPXRollUpData(
            this.props.clusterAndParameterDetails[month].clusters,
            this.props.clusterAndParameterDetails[month].clusterData,
            clusterHistoricalData
          );
          tempObj.TY_Budget = tempTotalBudgetUnit.TY_Budget;
          tempObj.TY_Unit = tempTotalBudgetUnit.TY_Unit;
          for (let subClass in clusterHistoricalData) {
            tybudgetcase2 += clusterHistoricalData[subClass]["ACT RCPT $"];
            tyunitcase2 +=
              clusterHistoricalData[subClass]["aur"] *
              clusterHistoricalData[subClass]["rcpt_pen%"];
            tyrcptcase2 += clusterHistoricalData[subClass]["rcpt_pen%"];
          }
          tyunitcase2 = tybudgetcase2 / (tyunitcase2 / tyrcptcase2);
          this.props.lineArchitectureDetails[
            month
          ].data.Line_Architechture.forEach(ele => {
            tempObj.TY_Imu += ele["TY_imu%"] * ele["rcpt_pen%"];
            tempObj.LY_Imu += ele["LY_imu%"] * ele["rcpt_pen%"];
            if (ele["TY_imu%"]) tempRCPTPen += ele["rcpt_pen%"];
          });
          if (tempRCPTPen) {
            tempObj.TY_Imu = tempObj.TY_Imu / tempRCPTPen;
            tempObj.LY_Imu = tempObj.LY_Imu / tempRCPTPen;
          }
          tempObj.LY_Budget = tybudgetcase2;
          tempObj.LY_Unit = tyunitcase2;
          this.getRowData(tempRowData, tempObj, month);
        });
        if (tempMonthArr[0] === "Seasonal") {
          this.props.rcptMonths.forEach(monthly => {
            let months = this.props.rcptMonths;
            let seasonHistData = this.props.clusterAndParameterDetails[
              "Seasonal"
            ].historicalParameterData;
            const monthLevelForSeason = this.setMonthLevelForSeasonSummary(
              seasonHistData,
              months
            );
            // let seasonTYBudget = tempRowData[0]["Seasonal_budget"];
            // let seasonLYBudget = tempRowData[1]["Seasonal_budget"];
            // let seasonTYUnit = tempRowData[0]["Seasonal_unit"];
            // let seasonLYUnit = tempRowData[1]["Seasonal_unit"];
            // let seasonTYImu = tempRowData[0]["Seasonal_imu"];
            // let seasonLYImu = tempRowData[1]["Seasonal_imu"];
            for (const month in monthLevelForSeason) {
              let tempObj = {
                TY_Budget: 0,
                LY_Budget: 0,
                TY_Unit: 0,
                LY_Unit: 0,
                TY_Imu: 0,
                LY_Imu: 0
              };
              if (monthLevelForSeason) {
                tempObj.TY_Budget = monthLevelForSeason["totalDollar"][monthly];
                tempObj.LY_Budget = monthLevelForSeason["totalU"][monthly];
                tempObj.TY_Unit = monthLevelForSeason["totalUnit"][monthly];
                tempObj.LY_Unit = monthLevelForSeason["totalUnitU"][monthly];
                // tempObj.TY_Imu = (seasonTYImu * monthLevelForSeason[month]) / 100;
                // tempObj.LY_Imu = (seasonLYImu * monthLevelForSeason[month]) / 100;
                this.getRowData(tempRowData, tempObj, monthly);
              }
            }
          });
        }
        break;
      default:
        break;
    }
    this.getVariation(tempRowData);
    return tempRowData;
  };
  setMonthLevelForSeasonSummary = (subClassObjs, rcptMonths) => {
    const totalDollar = {};
    const totalU = {};
    const totalUnit = {};
    const totalUnitU = {};
    rcptMonths.forEach(mon => {
      totalDollar[mon] = 0;
      totalU[mon] = 0;
      totalUnit[mon] = 0;
      totalUnitU[mon] = 0;
    });

    for (const property in subClassObjs) {
      const styles = subClassObjs[property].styleLevel;
      styles &&
        styles.forEach(style => {
          const month = style.month;
          totalDollar[month] += style.total$;
          totalUnit[month] += style.total_u;
          totalUnitU[month] += style.plannedUnits ? style.plannedUnits : 0;
          totalU[month] += style.plannedUnits
            ? style.plannedUnits * style.rcpt_aur
            : 0;
        });
    }
    return { totalDollar, totalU, totalUnit, totalUnitU };
  };
  getRowData = (tempRowData, tempObj, month) => {
    /* This function is used for calculate row data for Rollup table. 
      It is taking 3 arguements:1.Final row data object
                                2. temporary object for particular month
                                3. particular month
      It will add each month value for particular row on each function call through object reference 
      and will update final row data object.
    */
    //  first row for TY
    tempRowData[0][`${month}_budget`] = tempObj.TY_Budget;
    tempRowData[0][`${month}_unit`] = tempObj.TY_Unit;
    tempRowData[0][`${month}_imu`] = tempObj.TY_Imu;
    tempRowData[0].total_budget += tempObj.TY_Budget;
    tempRowData[0].total_unit += tempObj.TY_Unit;
    tempRowData[0].total_imu_temp += tempObj.TY_Budget * tempObj.TY_Imu;
    tempRowData[0].total_imu =
      tempRowData[0].total_imu_temp / tempRowData[0].total_budget;

    // second row for LY
    tempRowData[1][`${month}_budget`] = tempObj.LY_Budget;
    tempRowData[1].total_budget += tempObj.LY_Budget;
    tempRowData[1][`${month}_unit`] = tempObj.LY_Unit;
    tempRowData[1].total_unit += tempObj.LY_Unit;
    tempRowData[1][`${month}_imu`] = tempObj.LY_Imu;
    tempRowData[1].total_imu_temp += tempObj.LY_Budget * tempObj.LY_Imu;
    tempRowData[1].total_imu =
      tempRowData[1].total_imu_temp / tempRowData[1].total_budget;

    // third row for difference
    tempRowData[2][`${month}_budget`] = tempObj.TY_Budget - tempObj.LY_Budget;
    tempRowData[2].total_budget += tempObj.TY_Budget - tempObj.LY_Budget;
    tempRowData[2][`${month}_unit`] = tempObj.TY_Unit - tempObj.LY_Unit;
    tempRowData[2].total_unit += tempObj.TY_Unit - tempObj.LY_Unit;
    tempRowData[2][`${month}_imu`] = tempObj.TY_Imu - tempObj.LY_Imu;
    tempRowData[2].total_imu += tempObj.TY_Imu - tempObj.LY_Imu;

    // forth row for variation
    tempRowData[3][`${month}_budget`] =
      ((tempObj.TY_Budget - tempObj.LY_Budget) / tempObj.LY_Budget) * 100;
    // tempRowData[3].total_budget +=
    //   ((tempObj.TY_Budget - tempObj.LY_Budget) / tempObj.LY_Budget) * 100;
    tempRowData[3][`${month}_unit`] =
      ((tempObj.TY_Unit - tempObj.LY_Unit) / tempObj.LY_Unit) * 100;
    // tempRowData[3].total_unit +=
    //   ((tempObj.TY_Unit - tempObj.LY_Unit) / tempObj.LY_Unit) * 100;
    tempRowData[3][`${month}_imu`] =
      ((tempObj.TY_Imu - tempObj.LY_Imu) / tempObj.LY_Imu) * 100;
  };
  getVariation = tempRowData => {
    tempRowData[3].total_budget =
      ((tempRowData[0].total_budget - tempRowData[1].total_budget) /
        tempRowData[1].total_budget) *
      100;
    tempRowData[3].total_unit =
      ((tempRowData[0].total_unit - tempRowData[1].total_unit) /
        tempRowData[1].total_unit) *
      100;
    tempRowData[3].total_imu =
      ((tempRowData[0].total_imu - tempRowData[1].total_imu) /
        tempRowData[1].total_imu) *
      100;
  };
  getAutoAPXRollUpData = (clusters, clusterData, vHistParamData) => {
    /**
     * This function is for calculating AutoAPX data for each month
     */

    const historicalParameterData = cloneDeep(vHistParamData);
    let clustersLength = !this.props.isEcomm
      ? clusters.length - 1
      : clusters.includes("0")
      ? clusters.length - 1
      : clusters.length;
    let classSubClass = Object.keys(historicalParameterData);

    let finalObjUnit = {};
    let finalObjBudget = {};
    let ecomTotalUnit = 0;
    let ecomTotalBudget = 0;
    // Initializing each cluster and total valur to 0
    for (let ind = 0; ind <= Number(clustersLength); ind++) {
      finalObjUnit[clusters[ind]] = 0;
      finalObjUnit.Total = 0;
      finalObjBudget[clusters[ind]] = 0;
      finalObjBudget.Total = 0;
    }

    // Fetching all class-subclass keys
    let tempKey = Object.keys(historicalParameterData[classSubClass[0]]);

    classSubClass.forEach(classSubClassVal => {
      let tempObjUnit = {};
      let tempObjBudget = {};
      let tempEcomTotalUnit = 0;
      let tempEcomTotalBudget = 0;
      // Initializing each cluster and total valur to 0 in tempOBj
      for (let ind = 0; ind <= Number(clustersLength); ind++) {
        tempObjUnit[clusters[ind]] = 0;
        tempObjBudget[clusters[ind]] = 0;
      }

      // Adding each cluster value on style level
      historicalParameterData[classSubClassVal].styleLevel &&
        historicalParameterData[classSubClassVal].styleLevel.forEach(ele => {
          for (let ind = 0; ind <= Number(clustersLength); ind++) {
            if (clusters[ind] !== "0") {
              tempObjUnit[clusters[ind]] += ele[clusters[ind]];
              tempObjBudget[clusters[ind]] += ele[clusters[ind]];
            }
          }

          // Adding ecomm value if its there
          if ("0" in tempKey) {
            tempEcomTotalUnit =
              tempEcomTotalUnit + (isNaN(ele["0"]) ? 0 : ele["0"]);
            tempEcomTotalBudget =
              tempEcomTotalBudget + (isNaN(ele["0"]) ? 0 : ele["0"]);
          }
        });

      // unit of cluster = sum of all cluster in a class * door count of that cluster
      // budget of cluster = sum of all cluster in a class * door count of that cluster * aur of that class
      for (let ind = 0; ind <= Number(clustersLength); ind++) {
        if (clusters[ind] !== "0") {
          finalObjUnit[clusters[ind]] +=
            tempObjUnit[clusters[ind]] * clusterData[0][clusters[ind]];
          finalObjBudget[clusters[ind]] +=
            tempObjBudget[clusters[ind]] *
            clusterData[0][clusters[ind]] *
            historicalParameterData[classSubClassVal].aur;
        }
      }
      if ("0" in tempKey) {
        ecomTotalBudget =
          ecomTotalBudget +
          (isNaN(tempEcomTotalBudget) ? 0 : tempEcomTotalBudget) *
            historicalParameterData[classSubClassVal].aur;
        ecomTotalUnit += tempEcomTotalUnit;
      }
    });
    finalObjUnit.Total = 0;
    for (let ind = 0; ind <= Number(clustersLength); ind++) {
      if (clusters[ind] !== "0") {
        finalObjUnit.Total += finalObjUnit[clusters[ind]];
        finalObjBudget.Total += finalObjBudget[clusters[ind]];
      }
    }
    if ("0" in tempKey) {
      finalObjUnit.Total += isNaN(ecomTotalUnit) ? 0 : ecomTotalUnit;
      finalObjBudget.Total += isNaN(ecomTotalBudget) ? 0 : ecomTotalBudget;
    }
    return {
      TY_Budget: finalObjBudget.Total,
      TY_Unit: finalObjUnit.Total
    };
  };
  getSubGroupStyleMapping = ({ fileName, months, projectID }) => {
    const monthClassSubgroupMapping = [];
    for (const month in this.props.lineArchitectureDetails) {
      if (this.props.lineArchitectureDetails.hasOwnProperty(month)) {
        const element = this.props.lineArchitectureDetails[month].data;
        element.Line_Architechture.forEach(subClass => {
          monthClassSubgroupMapping.push(
            `${month}-${subClass[classNameKey]}-${subClass[subClassNameKey]}`
          );
        });
      }
    }
    this.props
      .getSubGroupStyleMapping({ months, projectID, monthClassSubgroupMapping })
      .then(data => {
        if (data.success) {
          let sheets = data.data.subGroupStyleMapping;
          if (sheets instanceof Array && sheets.length !== 0)
            downloadTable(sheets, fileName);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  getFeasibleMonths = () => {
    let feasibleMonths = [];
    if (
      this.props.rcptMonths &&
      Object.keys(this.props.lineArchitectureDetails).length
    ) {
      feasibleMonths = this.props.rcptMonths.filter(month => {
        return (
          this.props.lineArchitectureDetails[month] &&
          this.props.lineArchitectureDetails[month].plannerTotalBudget > 0
        );
      });
    }
    return this.props.projectDetails.is_season_level
      ? ["Seasonal"]
      : feasibleMonths;
  };
  runStoreDownload = () => {
    const { stage, subStage } = this.props;
    // if (+stage === 2 && +subStage === 1) {
    let dataChanged = this.hasDataChanged();
    let step = this.state.activeStep;
    if (dataChanged && step === 0) {
      this.props
        .saveLineReceiptPlan({
          projectId: this.props.match.params.id,
          data: {
            data: this.props.lineReceiptData,
            planned_budget: this.props.plannedBudget,
            months: this.props.rcptMonths,
            budgetProportions: this.getProportions(),
            userEditableData: this.props.userEditableLineReceipt,
            grandTotal: this.props.lineReceiptGrandTotal
          },
          otb_months: this.props.lineReceiptOtbMonths,
          level: this.props.lineReceiptlevel,
          highLevel: this.props.lineReceiptHighlevel,
          venGroup: this.props.lineReceiptVenGrouplevel,
          mapping_data: this.props.lineReceiptMappingData
        })
        .then(resp => {
          if (resp.success)
            this.props.runStoreDownload({
              projectId: this.props.match.params.id
            });
          if (+stage >= 2 && +subStage > 1) {
            this.props.saveProjectStage(2, 1);
          }
        });
    } else {
      this.props.runStoreDownload({
        projectId: this.props.match.params.id
      });
    }
  };
  getStepContent = step => {
    const userAccessDisableFlag = !this.props.userAccess[`${step + 1}`].WRITE;
    const feasibleMonths = this.getFeasibleMonths();
    switch (step) {
      case 0:
        return (
          <Step1ReceiptPlanner
            userAccessDisableFlag={userAccessDisableFlag}
            buyersList={this.state.buyersList}
            setSubclassTotal={this.setSubclassTotal}
            setGrandAndSubclassTotal={this.setGrandAndSubclassTotal}
            grandTotal={this.state.grandTotal}
            lineReceiptData={this.props.lineReceiptData}
            plannedBudget={this.props.plannedBudget}
            lineReceiptGrandTotal={this.props.lineReceiptGrandTotal}
            rcptMonths={this.props.rcptMonths}
            lineReceiptlevel={this.props.lineReceiptlevel}
            lineReceiptHighlevel={this.props.lineReceiptHighlevel}
            lineReceiptVenGrouplevel={this.props.lineReceiptVenGrouplevel}
            projectDetails={this.props.projectDetails}
            userEditableLineReceipt={this.props.userEditableLineReceipt}
            runStoreDownload={this.runStoreDownload}
            isClusteringSkipped={this.props.projectDetails.isClusteringSkipped}
          />
        );
      case 1:
        return (
          <div className="card" style={{ margin: "0.5rem 0 1rem 0" }}>
            <div className="card-body">
              {this.getRollUpTable()}
              <Button
                size="small"
                style={{ textTransform: "capitalize", float: "right" }}
                onClick={e =>
                  this.getSubGroupStyleMapping({
                    fileName: `Subgroup Style Mapping`.toUpperCase(),
                    months: this.props.rcptMonths,
                    projectID: Number(this.props.match.params.id)
                  })
                }
              >
                Download SubGroup - Style Mapping
              </Button>
              <Tabs
                defaultActiveKey={feasibleMonths[0]}
                transition={false}
                id="noanim-tab-example"
              >
                {Object.keys(this.props.lineArchitectureDetails).length &&
                  feasibleMonths.map(month => {
                    // Getting remaining months
                    const otherMonths = feasibleMonths.filter(function(ele) {
                      return ele !== month;
                    });
                    return (
                      <Tab
                        eventKey={month}
                        title={month}
                        className="p-3"
                        key={month}
                      >
                        <Step2LineArchitecture
                          rcptMonths={this.props.rcptMonths}
                          isSeasonLevel={
                            this.props.projectDetails.is_season_level
                          }
                          lineReceiptlevel={this.props.lineReceiptlevel}
                          // handleReset={this.handleReset}
                          lineReceiptData={this.props.lineReceiptData}
                          month={month}
                          otherMonths={otherMonths}
                          selectedFilters={this.props.selectedFilters}
                          userAccessDisableFlag={userAccessDisableFlag}
                          lineArchitectureDetails={
                            this.props.lineArchitectureDetails
                          }
                          styleDataForClass={this.props.styleDataForClass}
                          buyerTotalBudget={
                            this.props.lineArchitectureDetails[month] &&
                            this.props.lineArchitectureDetails[month]
                              .buyerTotalBudget
                          }
                          plannerTotalBudget={
                            this.props.lineArchitectureDetails[month] &&
                            this.props.lineArchitectureDetails[month]
                              .plannerTotalBudget
                          }
                          lineArchitectureData={
                            this.props.lineArchitectureDetails[month].data
                          }
                          mainLineArchitectureData={
                            this.props.lineArchitectureDetails[month].main_data
                          }
                          isComp={this.props.isComp}
                          savedSourceMapping={this.props.savedSourceMapping}
                          savedTargetMapping={this.props.savedTargetMapping}
                          saveProjectStage={this.props.saveProjectStage}
                          stage={this.props.stage}
                          subStage={this.props.subStage}
                        />
                      </Tab>
                    );
                  })}
              </Tabs>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="card" style={{ margin: "0.5rem 0 1rem 0" }}>
            <div className="card-body">
              {this.getRollUpTable()}
              <Tabs
                defaultActiveKey={feasibleMonths[0]}
                transition={false}
                id="noanim-tab-example"
              >
                {Object.keys(this.props.clusterAndParameterDetails).length &&
                  feasibleMonths.map(month => {
                    return (
                      <Tab
                        eventKey={month}
                        title={month}
                        className="p-3"
                        key={month}
                      >
                        <Step3ClusterAndParameters
                          month={month}
                          userAccessDisableFlag={userAccessDisableFlag}
                          buyerTotalBudget={
                            this.props.clusterAndParameterDetails[month]
                              .buyerTotalBudget
                          }
                          clusterData={
                            this.props.clusterAndParameterDetails[month]
                              .clusterData
                          }
                          subClassRcptPenTable={Object.values(
                            this.props.clusterAndParameterDetails[month]
                              .subClassRcptPenTable
                          )}
                          subClassRcptPenObject={
                            this.props.clusterAndParameterDetails[month]
                              .subClassRcptPenTable
                          }
                          historicalParameterObject={
                            this.props.clusterAndParameterDetails[month]
                              .historicalParameterData
                          }
                          hasAttributeClustering={
                            this.props.projectDetails.hasAttributeClustering
                          }
                          historicalParameterData={Object.values(
                            this.props.clusterAndParameterDetails[month]
                              .historicalParameterData
                          )}
                          clusters={
                            this.props.clusterAndParameterDetails[month]
                              .clusters
                          }
                          maxSellThrough={
                            this.props.clusterAndParameterDetails[month]
                              .maxSellThrough
                          }
                          minSellThrough={
                            this.props.clusterAndParameterDetails[month]
                              .minSellThrough
                          }
                        />
                      </Tab>
                    );
                  })}
              </Tabs>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="card" style={{ margin: "0.5rem 0 1rem 0" }}>
            <div className="card-body">
              {this.getRollUpTable()}
              <Tabs
                defaultActiveKey={feasibleMonths[0]}
                transition={false}
                id="noanim-tab-example"
              >
                {Object.keys(this.props.clusterAndParameterDetails).length &&
                  feasibleMonths.map(month => {
                    return (
                      <Tab
                        eventKey={month}
                        title={month}
                        className="p-3"
                        key={month}
                      >
                        <Step5AutoApx
                          handleReset={this.handleReset}
                          userAccessDisableFlag={userAccessDisableFlag}
                          clusterSummaryTable={this.props.clusterSummaryTable}
                          month={month}
                          lineArchitectureData={
                            this.props.lineArchitectureDetails[month].data
                          }
                          goodBetterBestTable={
                            this.props.clusterAndParameterDetails[month]
                              .goodBetterBestTable
                          }
                          clusterData={
                            this.props.clusterAndParameterDetails[month]
                              .clusterData
                          }
                          ly_ty_penetrations_optimized={
                            this.props.clusterAndParameterDetails[month]
                              .ly_ty_penetrations_optimized
                          }
                          historicalParameterData={Object.values(
                            this.props.clusterAndParameterDetails[month]
                              .historicalParameterData
                          )}
                          clusters={
                            this.props.clusterAndParameterDetails[month]
                              .clusters
                          }
                          isSeasonLevel={
                            this.props.projectDetails.is_season_level
                          }
                          selectedPrimaryAttributes={
                            this.props.projectDetails.selectedPrimaryAttributes
                          }
                          hasAttributeClustering={
                            this.props.projectDetails.hasAttributeClustering
                          }
                          classOptionsAPX={classOptionsAPX}
                          subClassOptionsAPX={subClassOptionsAPX}
                          conditionalInput={this.conditionalInput}
                          downloadFile={this.downloadFile}
                          rcptMonths={this.props.rcptMonths}
                          lineReceiptData={this.props.lineReceiptData}
                          lineReceiptlevel={this.props.lineReceiptlevel}
                          optimalAttributeClusters={
                            this.props.optimalAttributeClusters
                          }
                          attributesGradeChart={this.props.attributesGradeChart}
                          lyFlag={this.props.lyFlag}
                        />
                      </Tab>
                    );
                  })}
              </Tabs>
            </div>
          </div>
        );
      default:
        return;
    }
  };

  getProportions = () => {
    const { rcptMonths, lineReceiptData, lineReceiptlevel } = this.props;
    const reqKeys = rcptMonths.map(mo => {
      return Object.keys(lineReceiptData[0]).filter(key => {
        return key.startsWith(mo) && key.endsWith("TY");
      })[0];
    });
    const proportions = {};
    lineReceiptData.forEach(o => {
      const total = reqKeys.reduce((acc, key) => acc + +o[key], 0);
      const rtObj = {};
      reqKeys.forEach((val, idx) => {
        rtObj[rcptMonths[idx]] = (+o[val] / +total) * 100 || 0;
      });
      proportions[o[lineReceiptlevel]] = rtObj;
    });
    return proportions;
  };

  rcptPenePctCheck = () => {
    let flag = false;
    const lineArchDets = this.props.lineArchitectureDetails;
    for (const month in lineArchDets) {
      if (lineArchDets.hasOwnProperty(month)) {
        const lineArchData = lineArchDets[month].data.Line_Architechture;
        for (const iterator of lineArchData) {
          if (iterator[rcptPenePercentKey] <= 0) {
            toast.error(
              "Please make sure Reciept Penetration for all subclasses is greater than zero",
              { autoClose: false }
            );
            flag = true;
            break;
          }
          if (iterator[aurKey] <= 0) {
            console.log(iterator);
            toast.error(
              "Please make sure AUR value for all subclasses is greater than zero",
              { autoClose: false }
            );
            flag = true;
            break;
          }
        }
      }
      if (flag) break;
    }
    return flag;
  };

  beforeStep3 = () => {
    if (this.rcptPenePctCheck()) {
      return;
    }
    this.updateParametersData();
    this.props
      .saveLineArchitectureData({
        projectId: this.props.match.params.id,
        payload: this.props.lineArchitectureDetails
      })
      .then(resp => {
        if (resp.success) {
          if (!this.props.projectDetails.isClusteringSkipped) {
            const monthlyBuyerTotalBudget = {};
            for (const month in this.props.lineArchitectureDetails) {
              if (
                this.props.lineArchitectureDetails.hasOwnProperty(month) &&
                this.props.lineArchitectureDetails[month].buyerTotalBudget
              ) {
                const element = this.props.lineArchitectureDetails[month];
                monthlyBuyerTotalBudget[month] = element.buyerTotalBudget;
              }
            }
            this.props
              .runClusteringAndParameter({
                projectId: this.props.match.params.id,
                level: this.props.lineReceiptlevel,
                buyerTotalBudget: monthlyBuyerTotalBudget,
                numOfClusters: this.props.userSelectedClusters
              })
              .then(resp => {
                if (resp.success) {
                  this.saveClusterAndParameterData().then(resp => {
                    resp.success &&
                      this.props.saveProjectStage(2, 3).then(resp => {
                        resp.success && this.props.history.push("/dashboard");
                      });
                  });
                }
              })
              .catch(err => {});
          } else {
            this.props.saveProjectStage(1, 1).then(resp => {
              resp.success && this.props.history.push("/dashboard");
            });
          }
        }
      });
  };
  isRCPTPenDone = () => {
    const rcptMonthlyPene = [];
    for (const month in this.props.lineArchitectureDetails) {
      if (this.props.lineArchitectureDetails.hasOwnProperty(month)) {
        const monthData = this.props.lineArchitectureDetails[month];
        const rcptTotal = monthData.data.Line_Architechture.reduce(
          (acc, lineArchObj) => {
            return acc + lineArchObj["rcpt_pen%"];
          },
          0
        );
        rcptMonthlyPene.push(rcptTotal);
      }
    }
    return rcptMonthlyPene.every(value => Math.round(value) === 100);
  };
  beforeStep5 = () => {};
  //
  //
  /*=====================================SAVE ALLOCATION FUNCTIONS=======================================*/
  //
  //
  saveClusterAndParameterData = () => {
    let payload = {
      projectId: this.props.match.params.id,
      data: this.props.clusterAndParameterDetails
    };
    return this.props.saveClusterAndParameterData(payload);
  };
  callStep2SaveDetails = () => {};
  //
  //
  /*=====================================STEPPER FUNCTIONS=======================================*/
  //
  //

  hasDataChanged = () => {
    let hasDataChanged = false;
    switch (this.state.activeStep) {
      case 0:
        hasDataChanged = !isEqual(
          {
            lineReceiptlevel: this.state.templineReceiptlevel,
            lineReceiptHighlevel: this.state.templineReceiptHighlevel,
            lineReceiptData: this.state.templineReceiptData,
            userEditableLineReceipt: this.state.tempUserEditableLineReceipt
          },
          {
            lineReceiptlevel: this.props.lineReceiptlevel,
            lineReceiptHighlevel: this.props.lineReceiptHighlevel,
            lineReceiptData: this.props.lineReceiptData,
            userEditableLineReceipt: this.props.userEditableLineReceipt
          }
        );
        hasDataChanged &&
          this.props.dataChangedInAssortment1(hasDataChanged);
        break;
      case 1:
        hasDataChanged = !isEqual(
          this.state.templineArchitectureDetails,
          this.props.lineArchitectureDetails
        );
        hasDataChanged &&
          this.props.dataChangedInAssortment2(hasDataChanged);
        break;
      case 2:
        hasDataChanged = !isEqual(
          this.state.tempclusterAndParameterDetails,
          this.props.clusterAndParameterDetails
        );
        hasDataChanged &&
          this.props.dataChangedInAssortment3(hasDataChanged);
        break;
      // case 3:
      //   hasDataChanged = !isEqual(
      //     { historicalParameterData: this.state.temphistoricalParameterData },
      //     { historicalParameterData: this.props.historicalParameterData }
      //   );
      //   break;
      case 3:
        hasDataChanged = !isEqual(
          this.state.tempclusterAndParameterDetails,
          this.props.clusterAndParameterDetails
        );
        hasDataChanged &&
          this.props.dataChangedInAssortment4(hasDataChanged);
        break;
      default:
        break;
    }
    return hasDataChanged;
  };
  isCreateFlow = () => {
    return (
      this.props.stage === 2 &&
      this.state.activeStep === this.props.subStage - 1
    );
  };
  isNextDisabledforUser = () => {
    return (
      this.isCreateFlow() &&
      !this.props.userAccess[`${this.state.activeStep + 1}`].WRITE
    );
  };
  onNextClick = () => {
    if (
      this.props.isBuyOptSkipped &&
      !this.hasDataChanged() &&
      this.state.activeStep > 2
    ) {
      this.saveClusterAndParameterData().then(resp => {
        this.updateClusterAndParameterScreenTempData(resp.data);
        if (resp.success) {
          this.props
            .runmonthlevelAggAlgo({
              projectId: this.props.match.params.id,
              isBuyOptSkipped: false
            })
            .then(resp => {
              if (resp.success) {
                this.props.saveProjectStage(3, 1).then(resp => {
                  resp.success && this.props.history.push("/dashboard");
                });
              }
            });
        }
      });
    }
    this.isCreateFlow()
      ? this.handleNext()
      : this.hasDataChanged()
      ? this.setState({ isNextAlertVisible: true })
      : this.state.activeStep === 3
      ? this.props.updateActiveTab(2, 1)
      : this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleNext = () => {
    switch (this.state.activeStep) {
      case 0:
        this.onSendPlan();
        break;
      case 1:
        this.isRCPTPenDone()
          ? this.beforeStep3()
          : this.setState({ isRcptPeneAlertVisible: true });
        break;
      case 2:
        if (this.state.depthSum <= 0 || this.state.choiceSum <= 0) {
          this.setState({
            isDepthChoiceAlertVisible: true
          });
        } else {
          this.createInputForDepthMultiplier().then(resp => {
            if (resp.success) {
              this.saveClusterAndParameterData().then(resp => {
                if (resp.success) {
                  this.updateClusterAndParameterScreenTempData(resp.data);
                  if (this.props.projectDetails.hasAttributeClustering) {
                    this.props
                      .runAttrOptimizerWedgeAlgo({
                        projectId: +this.props.match.params.id
                      })
                      .then(resp => {
                        if (resp.success) {
                          this.saveClusterAndParameterData().then(resp => {
                            if (resp.success) {
                              this.updateClusterAndParameterScreenTempData(
                                resp.data
                              );
                              this.props.saveProjectStage(2, 4).then(resp => {
                                resp.success &&
                                  this.setState({ activeStep: 3 });
                              });
                            }
                          });
                        }
                      });
                  } else {
                    this.props.saveProjectStage(2, 4).then(resp => {
                      resp.success && this.setState({ activeStep: 3 });
                    });
                  }
                }
              });
            }
          });
        }
        break;
      case 3:
        this.saveClusterAndParameterData().then(resp => {
          if (resp.success) {
            this.updateClusterAndParameterScreenTempData(resp.data);
            this.props
              .runmonthlevelAggAlgo({
                projectId: this.props.match.params.id,
                isBuyOptSkipped: false
              })
              .then(resp => {
                if (resp.success) {
                  this.props.saveProjectStage(3, 1).then(resp => {
                    resp.success && this.props.history.push("/dashboard");
                  });
                }
              });
          }
        });
        break;
      default:
        break;
    }
    this.setState({ isNextAlertVisible: false });
  };

  handleStep = step => {
    if (this.state.depthSum <= 0 && this.state.choiceSum <= 0 && step === 3) {
      return false;
    }
    if (this.props.deleteMapping && step !== 0) {
      return false;
    } else {
      this.hasDataChanged()
        ? step > this.state.activeStep
          ? this.setState({ isNextAlertVisible: true })
          : this.setState({ isSaveAlertVisible: true })
        : this.setState({ activeStep: step });
    }
  };

  onSaveClick = () => {
    this.isCreateFlow()
      ? this.handleSave()
      : this.hasDataChanged() && this.setState({ isSaveAlertVisible: true });
  };
  updateParametersData = () => {
    Object.keys(this.props.lineArchitectureDetails).forEach(month => {
      let newParameterData = this.props.lineArchitectureDetails[
        month
      ].data.Line_Architechture.map((ele, index) => {
        const obj = {
          ...this.props.lineArchitectureDetails[month].data.Parameters[index],
          ...ele,
          reg_week: 12,
          min: 0,
          ecom: this.props.projectDetails.ecomm_f ? "Y" : "N"
        };
        return obj;
      });
      this.props.lineArchitectureDetails[
        month
      ].data.Parameters = newParameterData;
      this.props.lineArchitectureDetails[month].data[
        "Auto APX"
      ] = this.props.lineArchitectureDetails[
        month
      ].data.Line_Architechture.reduce((acc, obj) => {
        let className = obj["class/vendor"];
        let subClass = obj["subclass"];

        if (acc[className]) {
          // Add subclass to existing class/vendor
          acc[className].push(subClass);
        } else {
          // Add class with subclass
          acc[className] = [subClass];
        }
        return acc;
      }, {});
    });
  };
  handleSave = () => {
    switch (this.state.activeStep) {
      case 0:
        this.props
          .saveLineReceiptPlan({
            projectId: this.props.match.params.id,
            data: {
              data: this.props.lineReceiptData,
              planned_budget: this.props.plannedBudget,
              months: this.props.rcptMonths,
              budgetProportions: this.getProportions(),
              userEditableData: this.props.userEditableLineReceipt,
              grandTotal: this.props.lineReceiptGrandTotal
            },
            otb_months: this.props.lineReceiptOtbMonths,
            level: this.props.lineReceiptlevel,
            highLevel: this.props.lineReceiptHighlevel,
            venGroup: this.props.lineReceiptVenGrouplevel,
            mapping_data: this.props.lineReceiptMappingData
          })
          .then(resp => {
            resp.success && this.props.saveProjectStage(2, 1);
          });
        break;
      case 1:
        this.updateParametersData();
        this.props
          .saveLineArchitectureData({
            projectId: this.props.match.params.id,
            payload: this.props.lineArchitectureDetails,
            subData: this.props.userEditableLineReceipt
          })
          .then(resp => {
            this.setState({
              templineArchitectureDetails: cloneDeep(resp.data.payload)
            });
            resp.success && this.props.saveProjectStage(2, 2);
          });
        break;
      case 2:
        this.saveClusterAndParameterData().then(resp => {
          if (resp.success) {
            this.updateClusterAndParameterScreenTempData(resp.data);
            this.props.saveProjectStage(2, 3);
          }
        });
        break;
      case 3:
        this.saveClusterAndParameterData().then(resp => {
          if (resp.success) {
            this.updateClusterAndParameterScreenTempData(resp.data);
            this.props.saveProjectStage(2, 4);
          }
        });
        break;

      default:
        break;
    }
    this.setState({
      isSaveAlertVisible: false
    });
  };

  handleBack = () => {
    if (this.hasDataChanged()) {
      this.setState({ isSaveAlertVisible: true });
    } else {
      if (this.state.activeStep !== 0) {
        this.setState({ activeStep: parseInt(this.state.activeStep) - 1 });
      } else this.props.updateActiveTab(0, 4);
    }
  };

  handleReset = () => {
    switch (this.state.activeStep) {
      case 0:
        this.props.resetLineReceiptScreen({
          lineReceiptlevel: this.state.templineReceiptlevel,
          lineReceiptHighlevel: this.state.templineReceiptHighlevel,
          lineReceiptData: this.state.templineReceiptData,
          plannedBudget: this.state.tempPlannedBudget,
          userEditableLineReceipt: this.state.tempUserEditableLineReceipt
        });
        this.setSubclassTotal(this.state.templineReceiptData, true);
        break;
      case 1:
        this.props.resetLineArchitectureScreen({
          lineArchitectureDetails: this.state.templineArchitectureDetails
        });
        break;
      case 2:
        this.props.resetClusterPlanAndParametersScreen({
          clusterAndParameterDetails: this.state.tempclusterAndParameterDetails
        });
        break;
      case 3:
        this.props.resetClusterPlanAndParametersScreen({
          clusterAndParameterDetails: this.state.tempclusterAndParameterDetails
        });
        break;

      default:
        break;
    }
    this.setState({ isNextAlertVisible: false, isSaveAlertVisible: false });
  };

  onSendPlan = () => {
    let monthSplitBudget = {};
    // let x = this.state.selectedBuyerEmailId.label;
    // let atposition = x.indexOf("@");
    // let dotposition = x.lastIndexOf(".");
    // if (
    //   atposition < 1 ||
    //   dotposition < atposition + 2 ||
    //   dotposition + 2 >= x.length
    // ) {
    //   toast.error(
    //     "Please enter an email address that has the correct format.",
    //     { autoClose: false }
    //   );
    //   this.setState({
    //     modalVisible: false
    //   });
    // } else {
    this.props.rcptMonths.forEach(month => {
      monthSplitBudget[month] = this.props.lineReceiptData.reduce(
        (prev, curr) => {
          let sum = 0;
          Object.entries(curr).forEach(arr => {
            if (arr[0].startsWith(month) && arr[0].endsWith("TY")) {
              sum += +arr[1];
            }
          });
          return prev + sum;
        },
        0
      );
      if (monthSplitBudget[month] <= 0) {
        delete monthSplitBudget[month];
      }
    });

    const overAllPlannedBudget = Object.values(monthSplitBudget).reduce(
      (acc, curr) => acc + curr,
      0
    );
    this.props
      .saveLineReceiptPlan({
        projectId: this.props.match.params.id,
        data: {
          data: this.props.lineReceiptData,
          planned_budget: this.props.plannedBudget,
          budgetProportions: this.getProportions(),
          months: this.props.rcptMonths,
          userEditableData: this.props.userEditableLineReceipt,
          grandTotal: this.props.lineReceiptGrandTotal
        },
        otb_months: this.props.lineReceiptOtbMonths,
        level: this.props.lineReceiptlevel,
        highLevel: this.props.lineReceiptHighlevel,
        venGroup: this.props.lineReceiptVenGrouplevel,
        mapping_data: this.props.lineReceiptMappingData
      })
      .then(resp => {
        if (resp.success) {
          if (overAllPlannedBudget) {
            this.props
              .sendPlanAndRunArchAlgo({
                projectId: this.props.match.params.id,
                buyerId: 58,
                level: this.props.lineReceiptlevel,
                plannerTotalBudget: monthSplitBudget
              })
              .then(resp => {
                if (resp.success)
                  this.props.saveProjectStage(2, 2).then(resp => {
                    resp.success && this.props.history.push("/dashboard");
                  });
              });
          } else {
            toast.error(
              "Planned budget got saved, but to proceed further budget should be greater than zero atleast in one month",
              { autoClose: false }
            );
          }
        }
      });
    // this.setState({ modalVisible: false });
    // }
  };

  basicsform = () => {};
  handleInputChange = val => {
    this.setState({ selectedBuyerEmailId: val });
  };
  isNextDisabled = val => {
    const feasibleMonths = this.getFeasibleMonths();
    switch (val) {
      case 0:
        return this.props.lineReceiptData && this.props.lineReceiptData.length
          ? false
          : true;
      case 1:
        let flag_Final = false;
        feasibleMonths.forEach(month => {
          const element = this.props.lineArchitectureDetails[month];
          if (
            element &&
            element.main_data &&
            element.main_data.Class_subclass
          ) {
            if (!element.data.Line_Architechture.length) {
              flag_Final = true;
            }
          }
        });
        return flag_Final;

      default:
        return false;
    }
  };
  isStepDisabled = index => {
    if (this.props.stage === 2) {
      return index >= this.props.subStage;
    }
  };
  alertContent = () => {
    switch (this.state.activeStep) {
      case 2:
        return "Changes have been noticed. Clicking on continue will recalculate the data for respective months.";
      default:
        return "Changes have been noticed. Clicking on continue preceding steps data will be cleared.";
    }
  };

  onSkipBuy = () => {
    if (
      this.props.deleteMapping &&
      (!(
        this.props.savedSourceMapping && this.props.savedSourceMapping.length
      ) ||
        this.props.stage < 3)
    ) {
      this.setState({
        buyerInputModal: true
      });
    } else {
      if (
        this.props.isBuyOptSkipped &&
        !(this.props.stage < 3) &&
        !this.props.dataChanged1 &&
        !this.props.dataChanged2 &&
        !this.props.dataChanged3 &&
        !this.props.dataChanged4 &&
        !this.hasDataChanged()
      ) {
        this.props.updateActiveTab(2, 3);
      } else {
        this.setState({
          buyerInputModal: true
        });
      }
    }
  };

  isOmniRcptPlanDollarFlag = flag => {
    this.setState({
      omniRcptPlanDollarFlag: flag
    });
  };

  onSubmitOmniBuy = () => {
    this.setState({
      buyerInputModal: false
    });
    let skipBuy = true;
    this.saveClusterAndParameterData().then(resp => {
      this.updateClusterAndParameterScreenTempData(resp.data);
      if (resp.success) {
        this.state.omniIncr
          ? this.props.setOmniSalesPlanIncrement(this.state.omniIncr)
          : this.props.setOmniSalesPlanIncrement(0);
        this.state.omniRcpt
          ? this.props.setOmniRcptPlan(this.state.omniRcpt)
          : this.props.setOmniRcptPlan(0);
        this.props
          .runmonthlevelAggAlgo({
            projectId: this.props.match.params.id,
            isBuyOptSkipped: true
          })
          .then(resp => {
            if (resp.success) {
              this.props.runOmniBuyAlgo(
                skipBuy,
                !this.state.omniRcptPlanDollarFlag
                  ? this.state.omniIncr ||
                      (this.props.deptPenetration &&
                        this.props.deptPenetration.toFixed(2))
                  : 0,
                this.state.omniRcptPlanDollarFlag ? this.state.omniRcpt : 0
              );
            }
          });
      }
    });
  };

  downloadFile = () => {
    this.saveClusterAndParameterData().then(resp => {
      if (resp.success) {
        this.hasDataChanged() && this.props.dataChangedInAssortment4(true);
        this.updateClusterAndParameterScreenTempData(resp.data);
        this.props.downloadAutoAPX(this.props.match.params.id).then(resp => {
          let a2 = document.createElement("A");
          a2.href = resp.data.url;
          document.body.appendChild(a2);
          a2.click();
          document.body.removeChild(a2);
        });
      }
    });
  };
  render() {
    const userDisableAccess = !this.props.userAccess[
      `${this.state.activeStep + 1}`
    ].WRITE;
    const buyDisabled =
      this.props.stage === 3 &&
      this.props.subStage !== 3 &&
      !this.hasDataChanged();
    const showSkipBuyButton = this.state.activeStep + 1 === 4;
    return (
      <div style={{ width: "100%" }}>
        <Stepper
          alternativeLabel
          nonLinear
          activeStep={this.state.activeStep}
          style={{ backgroundColor: "transparent" }}
        >
          {this.state.steps.map((label, index) => {
            const stepProps = {};
            const buttonProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepButton
                  disabled={this.isStepDisabled(index)}
                  onClick={() => this.handleStep(index)}
                  {...buttonProps}
                >
                  {label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        <div>
          <Typography component={"span"}>
            {this.getStepContent(this.state.activeStep)}
          </Typography>
          <div className="mt-3">
            <Button onClick={this.handleBack}>Back</Button>
            <span>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  this.isNextDisabled(this.state.activeStep) ||
                  this.isNextDisabledforUser()
                }
                onClick={this.onNextClick}
              >
                Next
                <i className="fa fa-chevron-right ml-2" aria-hidden="true" />
              </Button>
              {showSkipBuyButton ? (
                <Button
                  variant="contained"
                  onClick={this.onSkipBuy}
                  disabled={buyDisabled}
                  color="primary"
                >
                  Skip Buy Optimization
                </Button>
              ) : (
                ""
              )}
              <Button
                variant="contained"
                onClick={this.onSaveClick}
                disabled={userDisableAccess}
                color="primary"
              >
                Save
              </Button>
            </span>
          </div>
        </div>
        <Modal
          visible={this.state.modalVisible}
          effect="fadeInUp"
          width="40%"
          height="30%"
          onClickAway={() => this.setState({ modalVisible: false })}
        >
          <div className="p-4">
            <div className="row">
              <div className="col-md-5 mt-2" align="right">
                <label htmlFor="inputPassword4">Select Buyer Email ID:</label>
              </div>
              <div className="col-md-6">
                <Select
                  isClearable
                  disabled={!this.state.selectedBuyerEmailId}
                  value={this.state.selectedBuyerEmailId}
                  options={this.state.buyersList}
                  onChange={val => this.handleInputChange(val)}
                  placeholder="Select Email"
                />
              </div>
            </div>
            <div align="center" className="mt-4">
              <Button
                style={{ textTransform: "capitalize", marginTop: "1rem" }}
                onClick={this.onSendPlan}
                disabled={!this.state.selectedBuyerEmailId}
              >
                Send Plan To Buyer
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          visible={this.state.buyerInputModal}
          effect="fadeInUp"
          width="40%"
          height="32%"
          onClickAway={() => this.setState({ buyerInputModal: false })}
        >
          <div
            style={{
              fontSize: "14px",
              padding: "0.5rem",
              color: "white",
              backgroundColor: "#2d69eb"
            }}
          >
            <strong>Buyer Input</strong>
            {/* <Tippy
              placement={"left"}
              arrow={true}
              distance={10}
              content="Partially filled with auto-populated values from previous steps. Enter additional data like MOQ, Production min, etc. to run buy optimization"
            >
              <i
                style={{
                  color: "#fff",
                  fontSize: "16px"
                }}
                className="fa fa-info-circle"
                aria-hidden="true"
              />
            </Tippy> */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "2px solid rgba(0, 0, 0, 0.3)",
              padding: "8px 10px"
            }}
          >
            <div style={{ fontSize: "16px" }}>
              FC approved:{" "}
              {this.props.isFcApproved ? this.props.isFcApproved : " "}
            </div>
            <div style={{ fontSize: "16px" }}>
              AUC threshold: $ {this.props.aucThreshold}
            </div>
            <div style={{ fontSize: "16px" }}>
              Dept. Penetration :{" "}
              {this.props.deptPenetration &&
                this.props.deptPenetration.toFixed(2) + "%"}
            </div>
            <div style={{ fontSize: "16px" }}>Season: {this.props.season}</div>
          </div>
          <div className="mt-2" style={{ width: "90%", margin: "0 auto" }}>
            <div style={{ fontSize: "14px" }}>
              <strong>OMNI Plan(%)</strong>
              <Switch
                disabled={false}
                checked={this.state.omniRcptPlanDollarFlag}
                onChange={e => this.isOmniRcptPlanDollarFlag(e.target.checked)}
                value={this.state.omniRcptPlanDollarFlag}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <strong>OMNI RCPT Plan($)</strong>
            </div>
            {!this.state.omniRcptPlanDollarFlag ? (
              <label className="omni-input mt-4">
                OMNI Plan(%)
                <input
                  className="form-control"
                  type="number"
                  value={
                    this.state.omniIncr ||
                    (this.props.deptPenetration &&
                      this.props.deptPenetration.toFixed(2))
                  }
                  onChange={val => {
                    this.setState({ omniIncr: val.currentTarget.value });
                  }}
                />
              </label>
            ) : (
              <label className="omni-input">
                OMNI RCPT Plan($)
                <input
                  className="form-control"
                  type="number"
                  value={this.state.omniRcpt}
                  onChange={val => {
                    this.setState({ omniRcpt: val.currentTarget.value });
                  }}
                />
              </label>
            )}
          </div>
          <div style={{ marginRight: "2rem" }}>
            <Button
              variant="contained"
              onClick={this.onSubmitOmniBuy}
              color="primary"
            >
              Submit
            </Button>
          </div>
        </Modal>
        {/* Alert on user edits */}
        <Alert
          open={this.state.isNextAlertVisible}
          title="Alert"
          isResetRequired={true}
          content={this.alertContent()}
          handleAccept={this.handleNext}
          handleReset={this.handleReset}
          cancelButtonText="Cancel"
          actionButtonText="Continue"
          handleClose={() => {
            this.setState({
              isNextAlertVisible: false
            });
          }}
        />
        <Alert
          open={this.state.isRcptPeneAlertVisible}
          title="Alert"
          content="Total RCPT Pen% is not close to 100% for at least one month. Are you sure you want to proceed?"
          handleAccept={() => {
            this.setState({
              isRcptPeneAlertVisible: false
            });
            this.beforeStep3();
          }}
          cancelButtonText="Cancel"
          actionButtonText="Continue"
          handleClose={() => {
            this.setState({
              isRcptPeneAlertVisible: false
            });
          }}
        />
        <Alert
          open={this.state.isSaveAlertVisible}
          title="Alert"
          isResetRequired={true}
          content={this.alertContent()}
          handleAccept={this.handleSave}
          handleReset={this.handleReset}
          cancelButtonText="Cancel"
          actionButtonText="Save"
          handleClose={() => {
            this.setState({
              isSaveAlertVisible: false
            });
          }}
        />
        <Alert
          open={this.state.isDepthChoiceAlertVisible}
          title="Alert"
          content={
            "All the Choice or Depth count values across all clusters for all sub groups are zero Kindly fill Choice and Depth count values for atleast one cluster to proceed further"
          }
          cancelButtonText="Cancel"
          handleClose={() => {
            this.setState({
              isDepthChoiceAlertVisible: false
            });
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    projectId: store.projectReducer.projectId,
    projectDetails: store.projectReducer.projectDetails,
    selectedFilters: store.projectReducer.projectDetails.selected_filters,
    stage: store.projectReducer.projectMainStage,
    subStage: store.projectReducer.projectSubStage,

    lineReceiptData: store.assortmentPlanningReducer.lineReceiptData,
    lineReceiptMappingData:
      store.assortmentPlanningReducer.lineReceiptMappingData,
    plannedBudget: store.assortmentPlanningReducer.plannedBudget,
    lineReceiptGrandTotal:
      store.assortmentPlanningReducer.lineReceiptGrandTotal,
    rcptMonths: store.assortmentPlanningReducer.rcptMonths,
    lineReceiptlevel: store.assortmentPlanningReducer.lineReceiptlevel,
    lineReceiptHighlevel: store.assortmentPlanningReducer.lineReceiptHighlevel,
    lineReceiptVenGrouplevel:
      store.assortmentPlanningReducer.lineReceiptVenGrouplevel,
    lineReceiptOtbMonths: store.assortmentPlanningReducer.lineReceiptOtbMonths,
    userEditableLineReceipt:
      store.assortmentPlanningReducer.userEditableLineReceipt,

    lineArchitectureDetails:
      store.assortmentPlanningReducer.lineArchitectureDetails,
    clusterAndParameterDetails:
      store.assortmentPlanningReducer.clusterAndParameterDetails,

    plannerTotalBudget: store.assortmentPlanningReducer.plannerTotalBudget,
    buyerTotalBudget: store.assortmentPlanningReducer.buyerTotalBudget,
    lineArchitectureData: store.assortmentPlanningReducer.lineArchitectureData,
    mainLineArchitectureData:
      store.assortmentPlanningReducer.mainLineArchitectureData,
    styleDataForClass: store.assortmentPlanningReducer.styleDataForClass,

    clusterData: store.assortmentPlanningReducer.clusterData,
    historicalParameterData:
      store.assortmentPlanningReducer.historicalParameterData,
    clusters: store.assortmentPlanningReducer.clusters,
    maxSellThrough: store.assortmentPlanningReducer.maxSellThrough,
    minSellThrough: store.assortmentPlanningReducer.minSellThrough,
    goodBetterBestTable: store.assortmentPlanningReducer.goodBetterBestTable,
    subClassRcptPenTable: store.assortmentPlanningReducer.subClassRcptPenTable,
    clusterSummaryTable: store.assortmentPlanningReducer.clusterSummaryTable,
    userSelectedClusters: store.clusteringReducer.userSelectedClusters,
    lyFlag: store.projectReducer.lyFlag,
    selectedPrimaryAttributes:
      store.clusteringReducer.selectedPrimaryAttributes,
    optimalAttributeClusters: store.clusteringReducer.optimalAttributeClusters,
    attributesGradeChart: store.clusteringReducer.attributesGradeChart,

    omniRcpt: store.buyOptimizationReducer.omniRcpt,
    omniIncr: store.buyOptimizationReducer.omniIncr,
    omniRcptPlanDollarFlag:
      store.projectReducer.projectDetails.omni_rcpt_plan_dollar,
    isBuyOptSkipped: store.projectReducer.projectDetails.isBuyOptSkipped,
    isFcApproved: store.buyOptimizationReducer.isFcApproved,
    aucThreshold: store.buyOptimizationReducer.aucThreshold,
    deptPenetration: store.buyOptimizationReducer.deptPenetration,
    season: store.buyOptimizationReducer.season,
    isComp: store.projectReducer.projectDetails.isComp,
    isEcomm: store.projectReducer.projectDetails.isEcomm,
    savedSourceMapping: store.assortmentPlanningReducer.savedSourceMapping,
    savedTargetMapping: store.assortmentPlanningReducer.savedTargetMapping,
    dataChanged1: store.assortmentPlanningReducer.dataChanged1,
    dataChanged2: store.assortmentPlanningReducer.dataChanged2,
    dataChanged3: store.assortmentPlanningReducer.dataChanged3,
    dataChanged4: store.assortmentPlanningReducer.dataChanged4,
    deleteMapping: store.assortmentPlanningReducer.deleteMapping
  };
};
const mapActionsToProps = {
  setOmniSalesPlanIncrement: BuyOptimizationActions.setOmniSalesPlanIncrement,
  setOmniRcptPlan: BuyOptimizationActions.setOmniRcptPlan,
  getBuyers: AssortmentPlanningActions.getBuyers,
  setLineReceiptGrandTotal: AssortmentPlanningActions.setLineReceiptGrandTotal,
  saveLineReceiptPlan: AssortmentPlanningActions.saveLineReceiptPlan,
  runStoreDownload: AssortmentPlanningActions.runStoreDownload,
  sendPlanAndRunArchAlgo: AssortmentPlanningActions.sendPlanAndRunArchAlgo,
  saveLineArchitectureData: AssortmentPlanningActions.saveLineArchitectureData,
  runClusteringAndParameter:
    AssortmentPlanningActions.runClusteringAndParameter,
  saveClusterAndParameterData:
    AssortmentPlanningActions.saveClusterAndParameterData,
  runDepthMultiplier: AssortmentPlanningActions.runDepthMultiplier,
  runAttrOptimizerWedgeAlgo:
    AssortmentPlanningActions.runAttrOptimizerWedgeAlgo,
  runmonthlevelAggAlgo: AssortmentPlanningActions.runmonthlevelAggAlgo,

  resetLineReceiptScreen: AssortmentPlanningActions.resetLineReceiptScreen,
  resetLineArchitectureScreen:
    AssortmentPlanningActions.resetLineArchitectureScreen,
  resetClusterPlanAndParametersScreen:
    AssortmentPlanningActions.resetClusterPlanAndParametersScreen,
  getSubGroupStyleMapping: AssortmentPlanningActions.getSubGroupStyleMapping,
  downloadAutoAPX: AssortmentPlanningActions.downloadAutoAPX,
  dataChangedInAssortment1: AssortmentPlanningActions.dataChangedInAssortment1,
  dataChangedInAssortment2: AssortmentPlanningActions.dataChangedInAssortment2,
  dataChangedInAssortment3: AssortmentPlanningActions.dataChangedInAssortment3,
  dataChangedInAssortment4: AssortmentPlanningActions.dataChangedInAssortment4
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(AssortmentPlanning));

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import buyOptimizationAction from "../../../actions/BuyOptimizationActions";
import DataTable from "../../../services/dataTable";
import ItemLevelColumnDefs from "../colDefs/ItemLevelColumnDefs";
import ItemLevelAggregationTable from "./ItemLevelAggregationTable";
class ItemLevel extends Component {
  onCellValueChanged = val => {
    this.props.itemLevelSummary[val.rowIndex].Action = val.newValue;
    this.props.setItemLevelDataAction(this.props.itemLevelSummary);
  };

  render() {
    return (
      <div>
        <div>
          <ItemLevelAggregationTable
            itemLevelAggregate={this.props.itemLevelAggregate}
          />
        </div>
        <div
          style={{
            height: "calc(100vh - 400px)",
            minHeight: "300px",
            marginTop: "30px"
          }}
        >
          <DataTable
            height1="calc(100vh - 300px)"
            defaultExpand={true}
            passGridAPI={api => {
              this.gridApi = api;
            }}
            animateRows={true}
            floatingFilter={true}
            columnDefs={ItemLevelColumnDefs.columnDefs(
              this.props.userDisableAccess,
              this.props.selectedPrimaryAttributes
            )}
            rowData={this.props.itemLevelSummary}
            onCellValueChanged={this.onCellValueChanged}
            defaultColDef={ItemLevelColumnDefs.defaultColDef}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    itemLevelAggregate: store.buyOptimizationReducer.itemLevelAggregate
  };
};
const mapActionsToProps = {
  setItemLevelDataAction: buyOptimizationAction.setItemLevelDataAction
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(ItemLevel));

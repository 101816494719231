import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function ClusterLegends(props) {
  return (
    <FormGroup className="cluster-legend-group" row>
      {Object.keys(props.flags).map((cluster, index) => {
        return (
          <FormControlLabel
            key={cluster}
            id={cluster}
            control={
              <Checkbox
                checked={props.flags[cluster]}
                onChange={event => {
                  props.onClusterCheckChange(cluster, event.target.checked);
                }}
                style={{ color: props.chartBackgroundColor[index] }}
              />
            }
            label={cluster === "0" ? "Ecom" : `Cluster ${cluster}`}
          />
        );
      })}
    </FormGroup>
  );
}

import React, { Component } from "react";
import DataTable from "../../../services/dataTable";
import BuyerInputStyleColorLevelColumnDefs from "../colDefs/StyleColorLevelColumnDefs.js";
import "../index.css";
class BuyerInputStyleColorLevel extends Component {
  render() {
    return (
      <div
        className="mt-3"
        style={{ height: "calc(100vh - 500px)", minHeight: "300px" }}
      >
        <DataTable
          // sizeToFit={true}
          floatingFilter={true}
          defaultExpand={true}
          height1="calc(100vh - 300px)"
          columnDefs={BuyerInputStyleColorLevelColumnDefs.columnDefs(
            this.props.userDisableAccess,
            this.props.selectedPrimaryAttributes
          )}
          defaultColDef={BuyerInputStyleColorLevelColumnDefs.defaultColDef}
          rowData={this.props.data}
          onCellValueChanged={this.props.onStyleLevelColorCellValueChanged}
        />
      </div>
    );
  }
}

export default BuyerInputStyleColorLevel;

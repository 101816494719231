import { decimalFormatterwith$ } from "../../../helpers/formatters";

// const state = store.getState();
// const tempFYear = state.cluster ? state.cluster.cluster.receipt_Range : null;
// let Fyear = () => {
//   if (tempFYear) {
//     const array = new Date(tempFYear)
//       .toISOString()
//       .substr(0, 10)
//       .split("-");
//     const year = parseInt(array[1], 10) === 1 ? array[0] - 1 : array[0];
//     return parseInt(year, 10);
//   }
// };

function sum(values) {
  let sum = 0;
  values.forEach(e => {
    sum = sum + parseFloat(e);
  });
  return sum;
}

export default {
  defaultColDef: {
    animateRows: true,
    // sortable: true,
    resizable: true,
    // unSortIcon: true,
    menuTabs: []
  },
  animateRows: true,
  sortable: true,
  filter: true,
  unSortIcon: true,
  suppressCellSelection: true,
  cellClass: "no-border",

  columnDefs: [
    {
      headerName: "Total",
      sortable: true,
      unSortIcon: true,
      rowGroup: true,
      width: 81,
      hide: true,
      field: "Financial_year",
      pinned: "left"
    },
    {
      headerName: "TOTAL TY",
      cellStyle: { "text-align": "right" },
      sortable: true,
      unSortIcon: true,
      field: "TYTotal",
      valueFormatter: decimalFormatterwith$,
      aggFunc: sum,
      pinned: "left",
      width: 81
      // filter: "agNumberColumnFilter"
    },
    {
      headerName: "TOTAL LY",
      cellStyle: { "text-align": "right" },
      sortable: true,
      unSortIcon: true,
      field: "LYTotal",
      valueFormatter: decimalFormatterwith$,
      aggFunc: sum,
      pinned: "left",
      width: 81
      // filter: "agNumberColumnFilter"
    },
    {
      headerName: "CLASS/VENDOR",
      width: 84,
      sortable: true,
      tooltipField: "class_vendor",
      unSortIcon: true,
      field: "class_vendor",
      pinned: "left",
      filter: "agTextColumnFilter"
    }
    // {
    //   headerName: `FEB FY`,
    //   sortable: true,
    //   unSortIcon: true,
    //   editable: params => {
    //     return false;
    //   },
    //   aggFunc: sum,
    //   cellEditor: "numericCellEditor",
    //   cellStyle: function(params) {
    //     if (params.data && params.colDef.field in params.data) {
    //       return inputCss;
    //     } else {
    //       return tar;
    //     }
    //   },
    //   field: "2",
    //   valueFormatter: wholewith$,
    //   width: 85
    // },
    // {
    //   headerName: "MAR FY ",
    //   width: 84,
    //   sortable: true,
    //   unSortIcon: true,
    //   editable: params => {
    //     if (params.data && params.colDef.field in params.data) return true;
    //     return false;
    //   },
    //   aggFunc: sum,
    //   cellEditor: "numericCellEditor",
    //   cellStyle: function(params) {
    //     if (params.data && params.colDef.field in params.data) {
    //       return inputCss;
    //     } else {
    //       return tar;
    //     }
    //   },
    //   field: "3",
    //   valueFormatter: wholewith$
    // },
    // {
    //   headerName: "APR FY",
    //   width: 82,
    //   sortable: true,
    //   unSortIcon: true,
    //   editable: params => {
    //     if (params.data && params.colDef.field in params.data) return true;
    //     return false;
    //   },
    //   aggFunc: sum,
    //   cellEditor: "numericCellEditor",
    //   cellStyle: function(params) {
    //     if (params.data && params.colDef.field in params.data) {
    //       return inputCss;
    //     } else {
    //       return tar;
    //     }
    //   },
    //   field: "4",
    //   valueFormatter: wholewith$
    // },
    // {
    //   headerName: "MAY FY",
    //   width: 80,
    //   sortable: true,
    //   unSortIcon: true,
    //   editable: params => {
    //     if (params.data && params.colDef.field in params.data) return true;
    //     return false;
    //   },
    //   aggFunc: sum,
    //   cellEditor: "numericCellEditor",
    //   cellStyle: function(params) {
    //     if (params.data && params.colDef.field in params.data) {
    //       return inputCss;
    //     } else {
    //       return tar;
    //     }
    //   },
    //   field: "5",
    //   valueFormatter: wholewith$
    // },
    // {
    //   headerName: "JUN FY",
    //   width: 83,
    //   sortable: true,
    //   unSortIcon: true,
    //   editable: params => {
    //     if (params.data && params.colDef.field in params.data) return true;
    //     return false;
    //   },
    //   aggFunc: sum,
    //   cellEditor: "numericCellEditor",
    //   cellStyle: function(params) {
    //     if (params.data && params.colDef.field in params.data) {
    //       return inputCss;
    //     } else {
    //       return tar;
    //     }
    //   },
    //   field: "6",
    //   valueFormatter: wholewith$
    // },
    // {
    //   headerName: "JUL FY",
    //   width: 80,
    //   sortable: true,
    //   unSortIcon: true,
    //   editable: params => {
    //     if (params.data && params.colDef.field in params.data) return true;
    //     return false;
    //   },
    //   aggFunc: sum,
    //   cellEditor: "numericCellEditor",
    //   cellStyle: function(params) {
    //     if (params.data && params.colDef.field in params.data) {
    //       return inputCss;
    //     } else {
    //       return tar;
    //     }
    //   },
    //   field: "7",
    //   valueFormatter: wholewith$
    // },
    // {
    //   headerName: "AUG FY",
    //   width: 82,
    //   sortable: true,
    //   unSortIcon: true,
    //   aggFunc: sum,
    //   editable: params => {
    //     if (params.data && params.colDef.field in params.data) return true;
    //     return false;
    //   },
    //   cellEditor: "numericCellEditor",
    //   cellStyle: function(params) {
    //     if (params.data && params.colDef.field in params.data) {
    //       return inputCss;
    //     } else {
    //       return tar;
    //     }
    //   },
    //   field: "8",
    //   valueFormatter: wholewith$
    // },
    // {
    //   headerName: "SEP FY",
    //   width: 82,
    //   sortable: true,
    //   unSortIcon: true,
    //   editable: params => {
    //     if (params.data && params.colDef.field in params.data) return true;
    //     return false;
    //   },
    //   aggFunc: sum,
    //   cellEditor: "numericCellEditor",
    //   cellStyle: function(params) {
    //     if (params.data && params.colDef.field in params.data) {
    //       return inputCss;
    //     } else {
    //       return tar;
    //     }
    //   },
    //   field: "9",
    //   valueFormatter: wholewith$
    // },
    // {
    //   headerName: "OCT FY",
    //   width: 80,
    //   sortable: true,
    //   unSortIcon: true,
    //   editable: params => {
    //     if (params.data && params.colDef.field in params.data) return true;
    //     return false;
    //   },
    //   aggFunc: sum,
    //   cellEditor: "numericCellEditor",
    //   cellStyle: function(params) {
    //     if (params.data && params.colDef.field in params.data) {
    //       return inputCss;
    //     } else {
    //       return tar;
    //     }
    //   },
    //   field: "10",
    //   valueFormatter: wholewith$
    // },
    // {
    //   headerName: "NOV FY",
    //   width: 80,
    //   sortable: true,
    //   unSortIcon: true,
    //   editable: params => {
    //     if (params.data && params.colDef.field in params.data) return true;
    //     return false;
    //   },
    //   aggFunc: sum,
    //   cellEditor: "numericCellEditor",
    //   cellStyle: function(params) {
    //     if (params.data && params.colDef.field in params.data) {
    //       return inputCss;
    //     } else {
    //       return tar;
    //     }
    //   },
    //   field: "11",
    //   valueFormatter: wholewith$
    // },
    // {
    //   headerName: "DEC FY",
    //   width: 80,
    //   sortable: true,
    //   unSortIcon: true,
    //   editable: params => {
    //     if (params.data && params.colDef.field in params.data) return true;
    //     return false;
    //   },
    //   aggFunc: sum,
    //   cellEditor: "numericCellEditor",
    //   cellStyle: function(params) {
    //     if (params.data && params.colDef.field in params.data) {
    //       return inputCss;
    //     } else {
    //       return tar;
    //     }
    //   },
    //   field: "12",
    //   valueFormatter: wholewith$
    // },
    // {
    //   headerName: "JAN FY",
    //   sortable: true,
    //   width: 82,
    //   unSortIcon: true,
    //   editable: params => {
    //     if (params.data && params.colDef.field in params.data) return true;
    //     return false;
    //   },
    //   aggFunc: sum,
    //   cellEditor: "numericCellEditor",
    //   cellStyle: function(params) {
    //     if (params.data && params.colDef.field in params.data) {
    //       return inputCss;
    //     } else {
    //       return tar;
    //     }
    //   },
    //   field: "1",
    //   valueFormatter: wholewith$
    // }
  ]
};

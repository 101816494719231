import React from "react";
import Tippy from "@tippy.js/react";

const InfoRollUp3 = () => {
  return (
    <div>
      <Tippy
        placement={"top"}
        arrow={true}
        distance={10}
        content={<div>Reg Sales $ - Total Budget$</div>}
      >
        <i
          style={{
            color: "#2D69EB",
            fontSize: "25px",
            marginLeft: "0px"
          }}
          className="fa fa-info-circle"
          aria-hidden="true"
        />
      </Tippy>
    </div>
  );
};

const InfoRollUp7 = () => {
  return (
    <div>
      <Tippy
        placement={"top"}
        arrow={true}
        distance={10}
        content={<div>Excluding BOP U</div>}
      >
        <i
          style={{
            color: "#2D69EB",
            fontSize: "25px",
            marginLeft: "0px"
          }}
          className="fa fa-info-circle"
          aria-hidden="true"
        />
      </Tippy>
    </div>
  );
};

const infoObj = {
  infoRollUp3: <InfoRollUp3 />,
  infoRollUp7: <InfoRollUp7 />
};

const RollupCard = props => {
  // eslint-disable-next-line no-useless-escape
  let thisYear = props.ty && props.ty.replace(/\,/g, "");
  // eslint-disable-next-line no-useless-escape
  let lastOrLLYear = props.lyorlly && props.lyorlly.replace(/\,/g, "");
  return (
    <div
      style={{
        padding: "1rem",
        border: "2px solid #DCDCDC",
        margin: "1rem",
        width: "22rem"
      }}
    >
      <div className="col-md-6">
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            height: "2.25rem",
            width: "10rem"
          }}
        >
          {props.title}
        </div>
        <div
          style={{
            fontSize: "20px",
            fontWeight: "600",
            marginBottom: "0.25rem",
            color: "#007bff"
          }}
        >
          {props.ty}
        </div>
        <div style={{ fontSize: "14px", fontWeight: "300", color: "#6c757d" }}>
          {props.lyFlag ? "LY" : "LLY"}: {props.lyorlly}
        </div>
      </div>
      <div
        className="col-md-3 offset-md-3"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end"
        }}
      >
        <div
          style={{
            margin: "-0.5rem 0 0.5rem",
            backgroundColor: "lightblue",
            height: "2.25rem",
            width: "2.25rem",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {props.infoIcon ? (
            infoObj[props.infoIcon]
          ) : (
            <img src={props.icon} alt="" />
          )}
        </div>
        <div
          style={{
            display: "flex",
            maxWidth: "5rem",
            justifyContent: "space-around",
            alignItems: "center"
          }}
        >
          {Number(thisYear) >= Number(lastOrLLYear) ? (
            <i
              className="fa fa-arrow-up"
              aria-hidden="true"
              style={{ color: "green", fontSize: "20px" }}
            />
          ) : (
            <i
              className="fa fa-arrow-down"
              aria-hidden="true"
              style={{ color: "red", fontSize: "20px" }}
            />
          )}
          <div
            style={{
              color: Number(thisYear) >= Number(lastOrLLYear) ? "green" : "red",
              fontSize: "20px"
            }}
          >
            {isNaN(props.percentageChange) ||
            props.percentageChange === "Infinity"
              ? ""
              : props.percentageChange + "%"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RollupCard;

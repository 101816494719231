import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tippy from "@tippy.js/react";
import { filter, sumBy, debounce } from "lodash";
import numeral from "numeral";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import AssortmentPlanningActions from "../../../actions/AssortmentPlanningActions";
import {
  decimalFormatter,
  percentageFormatter,
  toPercentage,
  toUnit
} from "../../../helpers/formatters";
import Alert from "../../../services/alert";
import DataTable from "../../../services/dataTable";
import Modal from "../../../services/modal";
import {
  errorInput,
  inputCss,
  tar,
  agGridRowBorder,
  agGridCellBgColor
} from "../../../styles";
import { downloadTable } from "../../../utils/exportxlsx";
import ChoiceCountTable from "./ChoiceCountTable";
import Apt_ClusterNParameter_Parameter from "./columnDefs";
import DepthCountTable from "./DepthCountTable";
import { toast } from "react-toastify";
import { tyTotalKey } from "../../../constants/keys";

class Step3ClusterAndParameters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScaleUp: false,
      TargetRCPT: props.buyerTotalBudget,
      NumberOfCluster: props.clusters.includes("0")
        ? props.clusters.length - 1
        : props.clusters.length,
      renderTableData: false,
      AVRG_AUR: 0,
      clusterPlanModalFlag: false
      // submitBtnDisabled: true         DONT REMOVE
    };
  }
  componentDidMount() {
    if (this.props.historicalParameterData.length) {
      let AVRG_AUR = 0;
      let SUM_PEN = 0;
      this.props.historicalParameterData.forEach(ele => {
        AVRG_AUR += ele["rcpt_pen%"] * ele["aur"];
        SUM_PEN += ele["rcpt_pen%"];
      });
      AVRG_AUR = AVRG_AUR / SUM_PEN;
      this.setState({
        AVRG_AUR
      });
    }
  }
  componentDidUpdate(oldProps, prevState, snapshot) {
    if (
      this.props.historicalParameterData !== oldProps.historicalParameterData
    ) {
      let AVRG_AUR = 0;
      let SUM_PEN = 0;
      this.setState({
        TargetRCPT: this.props.buyerTotalBudget,
        NumberOfCluster: this.props.clusters.includes("0")
          ? this.props.clusters.length - 1
          : this.props.clusters.length
      });
      this.props.historicalParameterData.forEach(ele => {
        AVRG_AUR += ele["rcpt_pen%"] * ele["aur"];
        SUM_PEN += ele["rcpt_pen%"];
      });
      AVRG_AUR = AVRG_AUR / SUM_PEN;
      this.setState({
        AVRG_AUR
      });
    }
  }
  clusterPlanFormatter = params => {
    if (params) {
      if (
        params.data.keys === "TY CLUSTER RCPT PEN" ||
        params.data.keys === "LY CLUSTER RCPT PEN" ||
        params.data.keys === "TY LY RCPT$ VARIANCE" ||
        params.data.keys === "LLY CLUSTER RCPT PEN"
      ) {
        return numeral(params.value / 100).format(
          params.colDef.field === "Total" ? "0%" : "0.00%"
        );
      } else if (
        params.data.keys === "TY TTL RCPT$/CLUSTER" ||
        params.data.keys === "LY TTL RCPT$/CLUSTER" ||
        params.data.keys === "TY AVG DR RCPT $" ||
        params.data.keys === "LY AVG DR RCPT $" ||
        params.data.keys === "AVG DR RCPT $" ||
        params.data.keys === "LLY TTL RCPT$/CLUSTER"
      ) {
        return numeral(params.value).format("($0,0)");
      } else {
        return numeral(params.value).format("(0,0)");
      }
    }
  };
  onScaleUp = () => {
    this.setState({
      isScaleUp: true
    });
  };
  getColDefParameterTableData = () => {
    let tempObj = {};
    if (this.props.clusters) {
      tempObj = {
        groupRowAggNodes: nodes => {
          var obj = {
            Financial_year: null,
            ecom: null,
            class: null,
            "LY APS": null,
            "LY ST%": null,
            "TY APS": null,
            "TY ST%": null,
            min: null,
            reg_week: null,
            subclass: null,
            "rcpt_pen%": null
          };
          let tempRCPT_PEN = null;
          obj["subClasslevelCount"] = nodes.length;
          nodes.forEach(function(node, index) {
            var data = node.group ? node.aggData : node.data;
            tempRCPT_PEN += Number(data["rcpt_pen%"]);
            obj["rcpt_pen%"] += Number(data["rcpt_pen%"]);
            // obj["TY ST%"] += Number(data["TY ST%"]) * Number(data["rcpt_pen%"]);
            // obj["LY ST%"] += Number(data["LY ST%"]) * Number(data["rcpt_pen%"]);
            // obj["TY APS"] += Number(data["TY APS"]) * Number(data["rcpt_pen%"]);
            // obj["LY APS"] += Number(data["LY APS"]) * Number(data["rcpt_pen%"]);
            obj["reg_week"] +=
              Number(data["reg_week"]) * Number(data["rcpt_pen%"]);
            obj["min"] += Number(data["min"]) * Number(data["rcpt_pen%"]);
          });

          // obj["TY ST%"] = Number(obj["TY ST%"]) / tempRCPT_PEN;
          // obj["LY ST%"] = Number(obj["LY ST%"]) / tempRCPT_PEN;
          // obj["TY APS"] = Number(obj["TY APS"]) / tempRCPT_PEN;
          // obj["LY APS"] = Number(obj["LY APS"]) / tempRCPT_PEN;
          obj["reg_week"] = obj["reg_week"] / tempRCPT_PEN;
          obj["min"] = Math.round(obj["min"] / tempRCPT_PEN);
          return obj;
        },
        columnDefs: [
          {
            headerName: "Total",
            menuTabs: [],
            sortable: true,
            unSortIcon: true,
            field: "Financial_year",
            rowGroup: true,
            valueFormatter: val => {
              return "Total";
            },
            hide: true
          },
          {
            headerName: "Class/Vendor",
            menuTabs: [],
            rowGroup: true,
            hide: true,
            sortable: true,
            width: 200,
            unSortIcon: true,
            field: "class/vendor",
            pinned: "left"
          },
          {
            headerName: "SUB GRP",
            menuTabs: [],
            sortable: true,
            width: 200,
            unSortIcon: true,
            field: "subclass",
            pinned: "left",
            hide: true
          },
          {
            headerName: "APS",
            children: [
              {
                headerName: "TY",
                sortable: true,
                unSortIcon: true,
                menuTabs: [],
                field: "TY APS",
                width: 70,
                valueSetter: function(params) {
                  params.data["TY APS"] = +params.newValue || 0;
                },
                valueFormatter: decimalFormatter,
                cellStyle: params => {
                  if (!params.node.group) {
                    return inputCss;
                  } else {
                    return tar;
                  }
                },
                editable: !this.props.userAccessDisableFlag && true
              },
              {
                headerName: this.props.lyFlag ? "LY" : "LLY",
                sortable: true,
                unSortIcon: true,
                menuTabs: [],
                width: 70,
                field: "LY APS",
                cellStyle: tar,
                valueFormatter: decimalFormatter
              }
            ]
          },
          {
            headerName: "ST%",
            children: [
              {
                headerName: "TY",
                sortable: true,
                width: 70,
                menuTabs: [],
                unSortIcon: true,
                field: "TY ST%",
                valueSetter: function(params) {
                  params.data["TY ST%"] = +params.newValue;
                },
                valueFormatter: percentageFormatter,
                editable: !this.props.userAccessDisableFlag && true,
                cellStyle: params => {
                  if (params.node.group) {
                    return { ...{ "background-color": "white" }, ...tar };
                  } else if (params.data["TY ST%"] > params.data["maxST%"]) {
                    return errorInput;
                  } else if (params.data["TY ST%"] < params.data["minST%"]) {
                    return errorInput;
                  } else {
                    return inputCss;
                  }
                }
              },
              {
                headerName: this.props.lyFlag ? "LY" : "LLY",
                sortable: true,
                width: 70,
                cellStyle: tar,
                menuTabs: [],
                unSortIcon: true,
                field: "LY ST%",
                valueFormatter: percentageFormatter
              }
            ]
          },
          // {
          //   headerName: "APS",
          //   children: [
          //     {
          //       headerName: "TY",
          //       sortable: true,
          //       unSortIcon: true,
          //       menuTabs: [],
          //       field: "TY APS",
          //       valueSetter: function(params) {
          //         params.data["TY APS"] = Number(params.newValue);
          //       },
          //       width: 70,
          //       valueFormatter: decimalFormatter,
          //       cellStyle: params => {
          //         if (params.node.rowIndex !== 0) {
          //           return inputCss;
          //         } else {
          //           return tar;
          //         }
          //       },
          //       editable: !this.props.userAccessDisableFlag && true
          //     },
          //     {
          //       headerName: "LY",
          //       sortable: true,
          //       unSortIcon: true,
          //       menuTabs: [],
          //       width: 70,
          //       field: "LY APS",
          //       cellStyle: tar,
          //       valueFormatter: decimalFormatter
          //     }
          //   ]
          // },
          {
            headerName: "REG WKS",
            children: [
              {
                headerName: "TY",
                sortable: true,
                unSortIcon: true,
                menuTabs: [],
                width: 71,
                field: "reg_week",
                valueSetter: function(params) {
                  params.data["reg_week"] = Number(params.newValue);
                },
                valueFormatter: toUnit,
                editable: !this.props.userAccessDisableFlag && true,
                cellStyle: params => {
                  if (!params.node.group) {
                    return inputCss;
                  } else {
                    return tar;
                  }
                }
              },
              {
                headerName: this.props.lyFlag ? "LY" : "LLY",
                sortable: true,
                unSortIcon: true,
                menuTabs: [],
                width: 71,
                field: "ly_reg_weeks",
                valueFormatter: toUnit
              }
            ]
          },
          {
            headerName: "MIN",
            sortable: true,
            width: 70,
            menuTabs: [],
            unSortIcon: true,
            field: "min",
            valueSetter: function(params) {
              params.data["min"] = Number(params.newValue);
            },
            editable: !this.props.userAccessDisableFlag && true,
            cellStyle: params => {
              if (!params.node.group) {
                return inputCss;
              } else {
                return tar;
              }
            }
          },
          {
            headerName: "RCPT PEN%",
            sortable: true,
            menuTabs: [],
            cellStyle: tar,
            unSortIcon: true,
            width: 70,
            field: "rcpt_pen%",
            valueFormatter: percentageFormatter
          },
          {
            headerName: `MATCH ECOM CC TO CL ${this.props.clusters[0]}`,
            sortable: true,
            width: 109,
            unSortIcon: true,
            menuTabs: [],
            field: "ecom",
            editable: !this.props.userAccessDisableFlag && true,
            hide: !this.props.clusters.includes("0"),
            cellEditor: "agRichSelectCellEditor",
            cellEditorParams: {
              values: ["Y", "N"]
            },
            cellStyle: params => {
              if (!params.node.group) {
                return inputCss;
              } else {
                return tar;
              }
            }
          }
        ]
      };
    }
    return tempObj;
  };
  getColDef = changed => {
    let tempObj = [];

    tempObj.push({
      headerName: "CL PLAN",
      menuTabs: [],
      cellStyle: { textAlign: "left" },
      field: "keys",
      width: 156,
      // filter: "agTextColumnFilter",
      unSortIcon: true,
      sortable: true
    });
    tempObj.push({
      headerName: "TOTAL",
      menuTabs: [],
      width: 93,
      field: "Total",
      valueFormatter: params => this.clusterPlanFormatter(params),
      unSortIcon: true,
      cellStyle: params => {
        if (
          params.data.keys === "TY CLUSTER RCPT PEN" &&
          (params.data.Total < 99.5 || params.data.Total > 100.5)
        ) {
          return { ...errorInput, ...agGridRowBorder };
        } else {
          return { ...agGridRowBorder, ...tar, ...agGridCellBgColor };
        }
      },
      sortable: true
    });

    let ecomCluster;
    this.props.clusters.forEach((cluster, index) => {
      const clusterCol = {
        sortable: true,
        unSortIcon: true,
        menuTabs: [],
        width: 93,
        // cellStyle: tar,
        valueFormatter: params => this.clusterPlanFormatter(params),
        field: `${cluster}`
      };
      if (cluster !== "0") {
        clusterCol["headerName"] = `CL ${cluster}`;
        tempObj.push(clusterCol);
      } else {
        clusterCol["headerName"] = `Ecom`;
        ecomCluster = clusterCol;
      }
      if (index === this.props.clusters.length - 1 && ecomCluster) {
        tempObj.push(ecomCluster);
      }
    });
    return tempObj;
  };
  getColDefSubClassRcptTable = () => {
    let tempObj = [
      {
        headerName: "SUB GRP",
        menuTabs: [],
        pinned: "left",
        resizable: true,
        field: "subclass",
        filter: "agTextColumnFilter",
        width: 200,
        tooltipField: "subclass",
        unSortIcon: true,
        sortable: true,
        valueFormatter: value => {
          return value.value;
        }
      },
      {
        headerName: "CLASS/VENDOR",
        menuTabs: [],
        pinned: "left",
        resizable: true,
        field: "class/vendor",
        filter: "agTextColumnFilter",
        tooltipField: "class/vendor",
        width: 200,
        unSortIcon: true,
        sortable: true,
        valueFormatter: value => {
          return value.value;
        }
      },
      {
        headerName: "SUB GRP RCPT PENE",
        field: "rcpt_pen%",
        pinned: "left",
        menuTabs: [],
        // filter: "agTextColumnFilter",
        width: 110,
        unSortIcon: true,
        sortable: true,
        valueFormatter: toPercentage
      },
      {
        headerName: "TY TOTAL",
        field: "TYTotal",
        aggFunc: "sum",
        pinned: "left",
        // valueGetter: function (params) {
        //   let total = 0;
        //   const elements = filter(Object.keys(params.data), key => {
        //     return key !== "TYTotal" && key.substring(0, 2) === "TY";
        //   });
        //   elements.forEach(ele => {
        //     let storeCount = this.props.clusterData[0][ele[ele.length - 1]];
        //     total += params.data[ele] * storeCount;
        //   });
        //   params.data["TYTotal"] = total;
        //   return total;
        // },
        unSortIcon: true,
        menuTabs: [],
        cellStyle: params => {
          if (params.data.TYTotal < 99.5 || params.data.TYTotal > 100.5)
            return errorInput;
          else {
            return { ...{ "background-color": "white" }, ...tar };
          }
        },
        width: 83,
        sortable: true
      },
      {
        headerName: this.props.lyFlag ? "LY TOTAL" : "LLY TOTAL",
        field: "LYTotal",
        pinned: "left",
        aggFunc: "sum",
        menuTabs: [],
        width: 83,
        unSortIcon: true,
        sortable: true
      }
    ];
    this.props.clusters.forEach(cluster => {
      const displayTYKey = `DTY${cluster}`;
      const displayLYKey = `DLY${cluster}`;
      const clusterColumnObj = {
        headerName:
          +cluster !== 0
            ? `CL ${cluster} (# of doors- ${this.props.clusterData[0][cluster]})`
            : "Ecom",
        children: [
          {
            sortable: true,
            unSortIcon: true,
            menuTabs: [],
            // cellStyle: tar,
            headerName: `TY`,
            aggFunc: "sum",
            editable: !this.props.userAccessDisableFlag && true,
            cellStyle: inputCss,
            valueSetter: function(params) {
              params.data[displayTYKey] = isNaN(+params.newValue)
                ? +params.oldValue
                : +params.newValue;
              return;
            },
            width: 80,
            field: displayTYKey
          },
          {
            sortable: true,
            unSortIcon: true,
            menuTabs: [],
            // cellStyle: tar,
            aggFunc: "sum",
            width: 80,
            headerName: this.props.lyFlag ? "LY" : "LLY",
            field: displayLYKey
          }
        ]
      };
      tempObj.push(clusterColumnObj);
    });
    return tempObj;
  };
  updateClusterPlanTableTotal = clusterDataRow => {
    let clusters = this.props.clusters;
    clusterDataRow.Total = 0;
    for (let i of clusters) {
      clusterDataRow.Total =
        Number(clusterDataRow.Total) + Number(clusterDataRow[i]);
    }
  };
  onCellValueChangedParameterData = val => {
    const classSubclassKey = `${val.data["class/vendor"]}-${val.data["subclass"]}`;
    this.props.recalculateStep4OnCellValueChanged(
      this.props.month,
      classSubclassKey,
      val.data
    );
    val.api.refreshCells();
    val.api.refreshClientSideRowModel("aggregate");
  };

  getNoOfStoresForClusters = () => {
    for (const obj of this.props.clusterData) {
      if (obj.keys === "# OF DOORS") return obj;
    }
  };

  reCalcOnScaleUpDown = () => {
    const totalSubClassReceiptPene = {};
    this.props.subClassRcptPenTable.forEach((subClassRcpt, index) => {
      const classSubclassKey = `${subClassRcpt["class/vendor"]}-${subClassRcpt["subclass"]}`;

      let total = 0;
      this.props.clusters.forEach(cluster => {
        let tyTotalScaleUpValue =
          (subClassRcpt["TY" + cluster] * 100) / subClassRcpt.TYTotal;
        let displayTyTotalScaleUpValue =
          (subClassRcpt["TY" + cluster] * 100) /
          this.props.clusterData[0][cluster] /
          subClassRcpt.TYTotal;
        subClassRcpt["TY" + cluster] = tyTotalScaleUpValue || 0;
        subClassRcpt["DTY" + cluster] = displayTyTotalScaleUpValue || 0;
        total += subClassRcpt["TY" + cluster];
        if (index) {
          totalSubClassReceiptPene[cluster] +=
            subClassRcpt["TY" + cluster] * subClassRcpt["rcpt_pen%"];
        } else {
          totalSubClassReceiptPene[cluster] =
            subClassRcpt["TY" + cluster] * subClassRcpt["rcpt_pen%"];
        }
      });
      subClassRcpt[tyTotalKey] = total || 0;
      this.props.recalculateStep4OnCellValueChanged(
        this.props.month,
        classSubclassKey
      );
    });

    const subClassRcptPenTotal = sumBy(
      this.props.subClassRcptPenTable,
      "rcpt_pen%"
    );

    this.props.clusters.forEach(cluster => {
      const subClassPene =
        totalSubClassReceiptPene[cluster] / subClassRcptPenTotal;
      this.reCalcClusterData(cluster, subClassPene);
    });
    this.setState(
      {
        isScaleUp: false
      },
      () => {
        toast.info("TY Receipt Penetration scaled up/down to 100%");
      }
    );
  };

  reCalcClusterData = (selectedCellClusterNum, totalSubClassReceiptPene) => {
    let clusterData = this.props.clusterData;

    // Transforming ClusterData Array to Obj
    const clusterDataObj = {};
    clusterData.forEach(obj => {
      clusterDataObj[obj["keys"]] = obj;
    });

    // Calcuation for respective cluster
    // Formula: TY TTL RCPT$/CLUSTER = (TargetRCPT * totalSubClassReceiptPene) / 100
    clusterDataObj["TY TTL RCPT$/CLUSTER"][selectedCellClusterNum] =
      (this.state.TargetRCPT * totalSubClassReceiptPene) / 100;
    // Calculation for Total Column
    this.updateClusterPlanTableTotal(clusterDataObj["TY TTL RCPT$/CLUSTER"]);

    const lyOrLlyKey = this.props.lyFlag ? "LY" : "LLY";
    // Calcuation for respective cluster
    // Formula: TY LY RCPT$ VARIANCE = ((TY TTL RCPT$/CLUSTER - LY TTL RCPT$/CLUSTER) / LY TTL RCPT$/CLUSTER) * 100
    clusterDataObj[`TY ${lyOrLlyKey} RCPT$ VARIANCE`][selectedCellClusterNum] =
      ((clusterDataObj["TY TTL RCPT$/CLUSTER"][selectedCellClusterNum] -
        clusterDataObj[`${lyOrLlyKey} TTL RCPT$/CLUSTER`][
          selectedCellClusterNum
        ]) *
        100) /
      clusterDataObj[`${lyOrLlyKey} TTL RCPT$/CLUSTER`][selectedCellClusterNum];

    //  Calculation for Total Column
    // Formula: TY LY RCPT$ VARIANCE = ((TY TTL RCPT$/CLUSTER - LY TTL RCPT$/CLUSTER) / LY TTL RCPT$/CLUSTER) * 100
    clusterDataObj[`TY ${lyOrLlyKey} RCPT$ VARIANCE`]["Total"] =
      ((clusterDataObj["TY TTL RCPT$/CLUSTER"]["Total"] -
        clusterDataObj[`${lyOrLlyKey} TTL RCPT$/CLUSTER`]["Total"]) *
        100) /
      clusterDataObj[`${lyOrLlyKey} TTL RCPT$/CLUSTER`]["Total"];

    //  Calcuation for respective cluster
    // Formula: TY AVG DR RCPT $ = TY TTL RCPT$/CLUSTER / # OF DOORS
    clusterDataObj["TY AVG DR RCPT $"][selectedCellClusterNum] =
      clusterDataObj["TY TTL RCPT$/CLUSTER"][selectedCellClusterNum] /
      clusterDataObj["# OF DOORS"][selectedCellClusterNum];
    //  Calculation for Total Column
    clusterDataObj["TY AVG DR RCPT $"]["Total"] =
      clusterDataObj["TY TTL RCPT$/CLUSTER"]["Total"] /
      clusterDataObj["# OF DOORS"]["Total"];
    //  Calcuation for respective cluster
    // Formula: TY AVG DR RCPT U = TY TTL RCPT$/CLUSTER / AVRG_AUR
    clusterDataObj["TY AVG DR RCPT U"][selectedCellClusterNum] =
      clusterDataObj["TY AVG DR RCPT $"][selectedCellClusterNum] /
      this.state.AVRG_AUR;
    //  Calculation for Total Column
    clusterDataObj["TY AVG DR RCPT U"]["Total"] =
      clusterDataObj["TY AVG DR RCPT $"]["Total"] / this.state.AVRG_AUR;

    // Calcuation for respective cluster
    // Formula: TY LY RCPT DIFF = LY AVG DR RCPT U - TY AVG DR RCPT U
    clusterDataObj[`TY ${lyOrLlyKey} RCPT DIFF`][selectedCellClusterNum] =
      clusterDataObj[`${lyOrLlyKey} AVG DR RCPT U`][selectedCellClusterNum] -
      clusterDataObj["TY AVG DR RCPT U"][selectedCellClusterNum];

    // Calcuation for Total
    // Formula: TY LY RCPT DIFF = LY AVG DR RCPT U - TY AVG DR RCPT U
    clusterDataObj[`TY ${lyOrLlyKey} RCPT DIFF`]["Total"] =
      clusterDataObj[`${lyOrLlyKey} AVG DR RCPT U`]["Total"] -
      clusterDataObj["TY AVG DR RCPT U"]["Total"];

    // Calcuation for respective cluster
    // Formula: TY CLUSTER RCPT PEN = totalSubClassReceiptPene
    clusterDataObj["TY CLUSTER RCPT PEN"][
      selectedCellClusterNum
    ] = totalSubClassReceiptPene;
    this.updateClusterPlanTableTotal(clusterDataObj["TY CLUSTER RCPT PEN"]);
  };

  onCellValueChangedSubClassRcptTable = val => {
    if (Number(val.newValue) !== Number(val.oldValue)) {
      const classSubclassKey = `${val.data["class/vendor"]}-${val.data["subclass"]}`;

      const subClassRcptPenTotal = sumBy(
        this.props.subClassRcptPenTable,
        "rcpt_pen%"
      );

      // getting store count for all clusters level data.
      let noOfStoresPerCluster = this.props.clusterData.filter(
        clusterDataRow => clusterDataRow.keys === "# OF DOORS"
      )[0];

      //spliting the column names from the displayed fileds.
      const selectedCellName = val.column.colId.substring(1);

      // getting cluster number by the selected column name.
      const selectedCellClusterNum = val.column.colId.substring(3);

      val.data[selectedCellName] =
        Number(val.newValue) * noOfStoresPerCluster[selectedCellClusterNum];
      const elements = filter(Object.keys(val.data), key => {
        return key !== "TYTotal" && key.substring(0, 2) === "TY";
      });
      let total = 0;
      let totalSubClassReceiptPene = 0;
      //calculating total sum of all SubClass Receipt Penetration.
      this.props.subClassRcptPenTable.forEach(subClassRecpObj => {
        // checking the selected cluster condition to calculate TYTOTAL
        if (
          (val.data["class/vendor"] && val.data.subclass) ===
          (subClassRecpObj["class/vendor"] && subClassRecpObj.subclass)
        ) {
          elements.forEach(ele => {
            total += Number(val.data[ele]);
          });
          val.data["TYTotal"] = total;
          totalSubClassReceiptPene +=
            val.data[selectedCellName] * subClassRecpObj["rcpt_pen%"]; // user edited value included in the totalSubClassReceiptPene.
        } else {
          totalSubClassReceiptPene +=
            subClassRecpObj[selectedCellName] * subClassRecpObj["rcpt_pen%"];
        }
      });
      //calculating total sum of all SubClass Receipt Penetration.
      totalSubClassReceiptPene =
        totalSubClassReceiptPene / subClassRcptPenTotal;
      this.reCalcClusterData(selectedCellClusterNum, totalSubClassReceiptPene);

      this.props.recalculateStep4OnCellValueChanged(
        this.props.month,
        classSubclassKey
      );
    }
  };
  onMinSt = e => {
    let minValue = e.target.value;

    if (Number(this.props.minSellThrough) !== Number(minValue)) {
      this.props.setMinSellThrough({
        value: minValue ? Number(minValue) : "",
        month: this.props.month
      });

      this.validateMinMax(minValue, this.props.maxSellThrough, "min");
    }
  };
  onMaxSt = e => {
    let maxValue = e.target.value;

    if (Number(this.props.maxSellThrough) !== Number(maxValue)) {
      this.props.setMaxSellThrough({
        value: maxValue ? Number(maxValue) : "",
        month: this.props.month
      });

      this.validateMinMax(this.props.minSellThrough, maxValue, "max");
    }
  };

  validateMinMax = debounce(function(min, max, str) {
    let message = "";
    let value = str === "min" ? min : max;
    if (min > max)
      message =
        "minimum sell through cannot be greater than maximum sell through";
    else if (min === max)
      message = "minimum sell through cannot be equal to maximum sell through";

    if (value < 0)
      message = "Input value out of range . Cannot be lesser than 0 %";
    else if (value > 100)
      message = "Input value out of range. Cannot be greater than 100 %";

    if (message !== "") toast.error(message, { autoClose: false });
  }, 2000);

  downloadHiddenTable = fileName => {
    let hist_param = this.props.historicalParameterData;

    let hist_aps = [];
    let hist_st = [];
    let adj_aps = [];
    let adj_st = [];
    let clusters = this.props.clusters;
    let isEcom = "";
    hist_param.forEach(item => {
      if (!isEcom) {
        isEcom = item.ecom;
      }
      let hist_aps_row = {};
      let hist_sellthrough_row = {};
      let adj_aps_row = {};
      let adj_sellthrough_row = {};
      hist_aps_row["SUB CLASS"] = item.subclass;
      hist_aps_row["BM APS"] = item.BM.APS;
      if (item.ecom === "Y") {
        if (item.BM_Ecom) {
          hist_aps_row["BM ECOMM APS"] = item.BM_Ecom.APS;
          hist_sellthrough_row["BM ECOMM ST%"] = item.BM_Ecom.Sell_through;
          adj_aps_row["BM ECOMM APS"] =
            item["LY ST%"] !== 0
              ? (item["TY ST%"] / item["LY ST%"]) * item.BM_Ecom.APS
              : 0;
          adj_sellthrough_row["BM ECOMM ST%"] =
            item["LY ST%"] !== 0
              ? (item["TY ST%"] / item["LY ST%"]) * item.BM_Ecom.Sell_through
              : 0;
        }
      }
      hist_sellthrough_row["SUB CLASS"] = item.subclass;
      hist_sellthrough_row["BM ST%"] = item.BM.Sell_through;
      adj_aps_row["SUB CLASS"] = item.subclass;
      adj_aps_row["BM APS"] =
        item["LY ST%"] !== 0
          ? (item["TY ST%"] / item["LY ST%"]) * item.BM.APS
          : 0;
      adj_sellthrough_row["SUB CLASS"] = item.subclass;
      adj_sellthrough_row["BM ST%"] =
        item["LY ST%"] !== 0
          ? (item["TY ST%"] / item["LY ST%"]) * item.BM.Sell_through
          : 0;
      clusters.forEach(cl => {
        if (item.ecom === "Y" && cl === "0") {
          hist_aps_row["ECOM"] = item[cl].APS;
          hist_sellthrough_row["ECOM"] = item[cl].Sell_through;
          adj_aps_row["ECOM"] =
            item["LY APS"] !== 0
              ? (item["TY APS"] / item["LY APS"]) * item[cl].APS
              : 0;
          adj_sellthrough_row["ECOM"] =
            item["LY ST%"] !== 0
              ? (item["TY ST%"] / item["LY ST%"]) * item[cl].Sell_through
              : 0;
        } else {
          hist_aps_row[`CL ${cl}`] = item[cl].APS;
          hist_sellthrough_row[`CL ${cl}`] = item[cl].Sell_through;
          adj_aps_row[`CL ${cl}`] =
            item["LY APS"] !== 0
              ? (item["TY APS"] / item["LY APS"]) * item[cl].APS
              : 0;
          adj_sellthrough_row[`CL ${cl}`] =
            item["LY ST%"] !== 0
              ? (item["TY ST%"] / item["LY ST%"]) * item[cl].Sell_through
              : 0;
        }
      });

      hist_aps.push(hist_aps_row);
      hist_st.push(hist_sellthrough_row);
      adj_aps.push(adj_aps_row);
      adj_st.push(adj_sellthrough_row);
    });

    let doubleScaleFormat = [""];
    let percentageFormat = [""];

    let blank_obj_aps = {};
    let blank_obj_st = {};
    blank_obj_aps["SUB CLASS"] = "";
    blank_obj_aps["BM APS"] = "";
    doubleScaleFormat.push("0.00");
    if (isEcom === "Y") blank_obj_aps["BM ECOMM APS"] = "";
    doubleScaleFormat.push("0.00");

    blank_obj_st["SUB CLASS"] = "";
    blank_obj_st["BM ST%"] = "";
    percentageFormat.push("0.00%");
    if (isEcom === "Y") blank_obj_st["BM ECOMM ST%"] = "";
    percentageFormat.push("0.00%");

    clusters.forEach(cl => {
      doubleScaleFormat.push("0.00");
      percentageFormat.push("0.00%");
      if (cl === "0") blank_obj_aps["ECOM"] = "";
      else blank_obj_aps[`CL ${cl}`] = "";
      if (cl === "0") blank_obj_st["ECOM"] = "";
      else blank_obj_st[`CL ${cl}`] = "";
    });

    hist_aps.unshift(blank_obj_aps);
    hist_st.unshift(blank_obj_st);
    adj_aps.unshift(blank_obj_aps);
    adj_st.unshift(blank_obj_st);

    let sheets = [
      {
        title: "HISTORICAL APS",
        sheet_data: hist_aps,
        sheet_name: "historical_aps",
        column_format: doubleScaleFormat,
        column_width: { A: 30 }
      },
      {
        title: "HISTORICAL SELLTHROUGH",
        sheet_data: hist_st,
        sheet_name: "historical_sellthrough",
        column_format: percentageFormat,
        column_width: { A: 30 }
      },
      {
        title: "ADJUSTED APS",
        sheet_data: adj_aps,
        sheet_name: "adjusted_aps",
        column_format: doubleScaleFormat,
        column_width: { A: 30 }
      },
      {
        title: "ADJUSTED SELLTHROUGH",
        sheet_data: adj_st,
        sheet_name: "adjusted_sellthrough",
        column_format: percentageFormat,
        column_width: { A: 30 }
      }
    ];
    downloadTable(sheets, fileName);
  };

  // getRowIdParameter = () => {
  //   let id =
  //     this.gridApiParameter &&
  //     this.gridApiParameter.focusedCellController &&
  //     this.gridApiParameter.focusedCellController.focusedCellPosition &&
  //     this.gridApiParameter.focusedCellController.focusedCellPosition.rowIndex;
  //   return id;
  // };
  render() {
    return (
      <Fragment>
        <Alert
          open={this.state.isScaleUp}
          title="Alert"
          isResetRequired={false}
          content="Are you sure you want to scale up/down to 100% ?"
          handleAccept={this.reCalcOnScaleUpDown}
          actionButtonText="Yes"
          cancelButtonText="No"
          handleClose={() => {
            this.setState({
              isScaleUp: false
            });
          }}
        />
        <div className="row p-2">
          <div className="col-md-2">
            <label htmlFor="inputPassword4">Target Rcpt$ (Buyer)</label>
            <input
              className="form-control"
              disabled
              placeholder="Target Rcpt$ (Buyer)"
              value={numeral(this.state.TargetRCPT).format("0,0")}
            />
            {/* <input type="text" /> */}
          </div>
          <div className="col-md-2">
            <label htmlFor="inputPassword4"># of Clusters</label>
            <input
              disabled
              className="form-control"
              placeholder="# of CLusters"
              value={this.state.NumberOfCluster}
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="inputPassword4">Max ST%</label>
            <input
              onChange={this.onMaxSt}
              className="form-control"
              type="number"
              disabled={this.props.userAccessDisableFlag}
              placeholder="Max ST%"
              value={this.props.maxSellThrough}
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="inputPassword4">
              Min ST%
              <Tippy
                placement={"top"}
                arrow={true}
                distance={10}
                content={
                  <div>
                    Max ST% and Min ST% are used to calculated the Adjusted ST%
                    (which in turn is used to calculate the Depth & Choice
                    Counts at a Sub Grp cluster level).
                  </div>
                }
              >
                <i
                  style={{
                    color: "#2D69EB"
                  }}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                />
              </Tippy>
            </label>
            <input
              onChange={this.onMinSt}
              className="form-control"
              disabled={this.props.userAccessDisableFlag}
              type="number"
              placeholder="Min ST%"
              value={this.props.minSellThrough}
            />
          </div>
          <div
            className="col-md-2"
            style={{
              alignSelf: "flex-end"
            }}
          >
            <Button
              size="small"
              style={{ textTransform: "capitalize" }}
              disabled={this.props.userAccessDisableFlag}
              onClick={() =>
                this.props.changeMinMaxSellThroughValue(
                  this.props.minSellThrough,
                  this.props.maxSellThrough,
                  this.props.month
                )
              }
              // disabled={this.state.submitBtnDisabled} DONT REMOVE
            >
              Submit
            </Button>
          </div>
          <div
            className="col-md-2"
            style={{
              alignSelf: "flex-end"
            }}
          >
            <Button
              size="small"
              style={{ textTransform: "capitalize", float: "right" }}
              onClick={e => this.downloadHiddenTable("APS_AND_SELLTHROUGH")}
            >
              download APS and ST%
            </Button>
          </div>
        </div>
        <div className="mt-2">
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Sub Grp Cluster Receipt Penetration Table
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: "100%" }}>
                <div align="center" style={{ marginBottom: "1rem" }}>
                  <Button
                    size="small"
                    style={{ textTransform: "capitalize" }}
                    onClick={() => {
                      this.setState({ clusterPlanModalFlag: true });
                    }}
                  >
                    Cluster Plan
                  </Button>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <span className="p-1">Actions: </span>
                  <Tippy
                    placement={"left"}
                    arrow={true}
                    distance={10}
                    content={<div>Scale up or Scale down</div>}
                  >
                    <i
                      className="fa fa-arrows-v p-2 pointer"
                      style={{
                        cursor: "pointer",
                        fontSize: "15px",
                        pointerEvents:
                          this.props.userAccessDisableFlag && "none",
                        color: "#2D69EB"
                      }}
                      aria-hidden="true"
                      onClick={() => this.onScaleUp()}
                    />
                  </Tippy>
                </div>

                <div style={{ height: "350px" }}>
                  <DataTable
                    groupColDef={{
                      headerName: "SUB GRP",
                      width: 200,
                      filter: "agTextColumnFilter",
                      field: "subclass",
                      valueFormatter: value => {
                        return value.value;
                      },
                      cellRenderer: "agGroupCellRenderer"
                    }}
                    passGridAPI={api => {
                      this.gridApi = api;
                    }}
                    defaultColDef={{
                      valueFormatter: toPercentage,
                      resizable: true
                    }}
                    onCellValueChanged={
                      this.onCellValueChangedSubClassRcptTable
                    }
                    columnDefs={this.getColDefSubClassRcptTable()}
                    defaultExpand={true}
                    rowData={this.props.subClassRcptPenTable}
                  />
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Parameters
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ height: "350px", width: "100%" }}>
                <DataTable
                  defaultColDef={{
                    resizable: true
                  }}
                  groupColDef={{
                    headerName: "SUB GRP",
                    width: 200,
                    menuTabs: [],
                    filter: "agTextColumnFilter",
                    field: "subclass",
                    cellRenderer: "agGroupCellRenderer"
                    // cellRendererParams: { checkbox: true }
                  }}
                  renderTableData={this.state.renderTableData}
                  onCellValueChanged={this.onCellValueChangedParameterData}
                  // deltaRowDataMode={true}
                  // getRowNodeId={this.getRowIdParameter}
                  // passGridAPI={api => {
                  //   this.gridApiParameter = api;
                  // }}
                  floatingFilter={true}
                  resizable={true}
                  suppressColumnVirtualisation={true}
                  defaultExpand={true}
                  columnDefs={this.getColDefParameterTableData().columnDefs}
                  sizeToFit={true}
                  groupRowAggNodes={
                    Apt_ClusterNParameter_Parameter.groupRowAggNodes
                  }
                  rowData={this.props.historicalParameterData}
                  legends={[
                    {
                      color: "#f28e83",
                      text: "ST% lying out of min and max ST% range"
                    }
                  ]}
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Depth By Cluster{" "}
              <Tippy
                placement={"top"}
                arrow={true}
                distance={10}
                content={<div>avg per choice</div>}
              >
                <i
                  style={{
                    color: "#fff",
                    marginTop: "0.2rem"
                  }}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                />
              </Tippy>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <DepthCountTable
                buyerTotalBudget={this.props.buyerTotalBudget}
                userAccessDisableFlag={this.props.userAccessDisableFlag}
                subClassRcptPenObject={this.props.subClassRcptPenObject}
                historicalParameterObject={this.props.historicalParameterObject}
                month={this.props.month}
                clusterData={this.props.clusterData}
                historicalParameterData={this.props.historicalParameterData}
                clusters={this.props.clusters}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Max/Total – Choice Count by Cluster
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ChoiceCountTable
                userAccessDisableFlag={this.props.userAccessDisableFlag}
                buyerTotalBudget={this.props.buyerTotalBudget}
                subClassRcptPenObject={this.props.subClassRcptPenObject}
                historicalParameterObject={this.props.historicalParameterObject}
                month={this.props.month}
                clusterData={this.props.clusterData}
                historicalParameterData={this.props.historicalParameterData}
                clusters={this.props.clusters}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        <Modal
          open={this.state.clusterPlanModalFlag}
          onClose={() => {
            this.setState({ clusterPlanModalFlag: false });
          }}
          fullWidth={true}
          // fullScreen={true}
          maxWidth="lg"
          title="Cluster Plan"
        >
          {/* Not going to deploy in (Jan Production Build 05-02-2020) */}
          <div style={{ height: "442px" }}>
            <DataTable
              // floatingFilter={true}
              // sizeToFit={true}
              defaultColDef={{
                resizable: true
              }}
              columnDefs={this.props.clusterData ? this.getColDef() : []}
              rowData={this.props.clusterData}
              // sizeToFit={true}
              resizable={true}
            />
          </div>
        </Modal>
      </Fragment>
    );
  }
}
const mapStateToProps = store => {
  return {
    lyFlag: store.projectReducer.lyFlag
  };
};
const mapActionsToProps = {
  setMinSellThrough: AssortmentPlanningActions.setMinSellThrough,
  setMaxSellThrough: AssortmentPlanningActions.setMaxSellThrough,
  changeMinMaxSellThroughValue:
    AssortmentPlanningActions.changeMinMaxSellThroughValue,
  setClusterDataOnCellValueChanged:
    AssortmentPlanningActions.setClusterDataOnCellValueChanged,
  recalculateStep4OnCellValueChanged:
    AssortmentPlanningActions.recalculateStep4OnCellValueChanged
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(Step3ClusterAndParameters);

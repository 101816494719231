import api from "../axios";
import { toast } from "react-toastify";

const userActions = {
  resetPassword: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/users/changePassword",
        method: "POST",
        data: payload
      })
        .then(resp => {
          toast.success(resp.message);
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_RESETPASSWORD_STATUS",
            payload: resp.success
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          return err;
        });
    };
  },
  forgotPassword: payload => {
    return dispatch => {
      return api({
        url: "/users/forgotPassword",
        method: "POST",
        data: payload
      })
        .then(resp => {
          toast.success(resp.message);
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_FORGOTPASSWORD_STATUS",
            payload: resp.success
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          return err;
        });
    };
  },
  login: payload => {
    return dispatch => {
      api({
        url: "/users/login",
        method: "POST",
        data: payload
      })
        .then(resp => {
          if (resp.data) {
            dispatch({
              type: "SET_LOGIN_STATUS",
              payload: resp.success
            });
            localStorage.setItem("token", resp.data.token);
            localStorage.setItem("userType", resp.data.userData.type);
            localStorage.setItem("userEmail", resp.data.userData.email);
            localStorage.setItem("userName", resp.data.userData.name);
            dispatch({
              type: "SET_USER_DATA",
              payload: resp.data.userData
            });
          }
        })
        .catch(err => {
          toast.error(err.error[0].msg || err.message, { autoClose: false });
          return err;
        });
      // .catch(error => {
      //   toast.error(error.message , { autoClose: false });
      // });
    };
  },
  logout: props => {
    // console.log("asdasdasdasdasdasd", props)
    return dispatch => {
      props.history.push("/login");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userType");
    };
  },

  getusers: () => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: `/users/`,
        method: "GET"
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_ALL_USERS",
            payload: resp.data.map(userObject => {
              return {
                name: userObject.name,
                email: userObject.email,
                type: userObject.type
              };
            })
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },

  createUserByAdmin: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true,
          text: `Creating user profile for ${payload.name}`
        }
      });
      return api({
        url: "/users/createUserByAdmin",
        method: "POST",
        data: payload
      })
        .then(resp => {
          let msg;
          if (resp.success) {
            if (resp.data) {
              //msg = `Registration succesful for user ${resp.data["name"]}. Login information has been sent to ${resp.data["email"]}`
              msg = "Success !";
              dispatch({
                type: "ADD_NEW_USER",
                payload: {
                  name: resp.data.name,
                  email: resp.data.email,
                  type: resp.data.type
                }
              });
            }
            toast.success(msg, {
              position: toast.POSITION.BOTTOM_RIGHT
            });
          } else {
            msg = "User registration failed";
            toast.error(msg, { autoClose: false });
          }

          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
        })
        .catch(err => {
          err.error &&
            err.error.detail &&
            toast.error(err.error.detail, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
        });
    };
  }
};

export default userActions;

import React, { Component } from "react";
import DataTable from "../../../services/dataTable";
import ProgramLevelColumnDefs from "../colDefs/ProgramLevelColumnDefs";
// import RowData from "./buyDummyData.js";

class ProgramLevel extends Component {
  render() {
    return (
      <div style={{ height: "calc(100vh - 400px)", minHeight: "300px" }}>
        <DataTable
          // sizeToFit={true}
          defaultExpand={true}
          floatingFilter={true}
          height1="calc(100vh - 300px)"
          columnDefs={ProgramLevelColumnDefs.columnDefs}
          defaultColDef={ProgramLevelColumnDefs.defaultColDef}
          rowData={this.props.programLevelSummary}

          //   onCellValueChanged={this.onCellValueChanged}
        />
      </div>
    );
  }
}

export default ProgramLevel;

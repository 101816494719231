const excelStyle=[              
    {
      id:"excelExport_Format-0.00%",
      numberFormat:{
        format:"0.00\\%"
      }
    },
    {
      id:"excelExport_Format-$0",
      numberFormat:{
        format:"$#,##0;[RED]-#,##0"
      }
    },
    {
      id:"excelExport_Format-0",
      numberFormat:{
        format:"#,##0;[RED]-#,##0"
      }
    },
    {
      id:"excelExport_Format-$0.00",
      numberFormat:{
        format:"$#,##0;[RED]-#,##0.00"
      }
    },
    {
      id:"header",
      font:{
        color:"#000",
        size:15,
        bold:true
      }
    },
    {
      id: "booleanType",
      dataType: "boolean"
    }
  ];

  export default excelStyle;
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react";
import React, { Component } from "react";
import clusterStep4CellRender from "../services/cellRender/clusterStep4CellRender";
import attrClusterCellRenderer from "../services/cellRender/attrClusterCellRenderer";
import ItemLevelCellRender from "../services/cellRender/itemLevelCellRender";
import HindsightActionInvoke from "../services/cellRender/hindsightActionInvoke";
import NumericCellEditor from "./NumericCellEditor";

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.gridApi = null;
    this.frameworkComponents = {
      clusterStep4CellRender,
      attrClusterCellRenderer,
      itemLevelCellRender: ItemLevelCellRender,
      HindsightActionInvoke: HindsightActionInvoke
    };

    this.state = {
      currentCount: null,
      excelStyles: [
        {
          id: "booleanType",
          dataType: "boolean"
        },
        {
          id: "stringType",
          dataType: "string"
        },
        {
          id: "dateType",
          dataType: "dateTime"
        }
      ]
    };
  }
  componentDidUpdate() {
    if (this.gridApi) {
      this.gridApi.refreshCells();
      this.gridApi.refreshClientSideRowModel("aggregate");
    }
  }
  onGridReady = params => {
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    let tempCount = this.gridApi.getDisplayedRowCount();
    this.setState({
      currentCount: tempCount
    });
    this.props.passGridAPI && this.props.passGridAPI(params.api);
    this.props.excelRefs && this.props.excelRefs(this.ExportExcel);
    this.props.excelRefsRollup && this.props.excelRefsRollup(this.ExportExcelRollup);
    // this.props.onGridReadyInParent(params)

    if (this.props.cssEffects) {
      if (this.props.cssEffects && this.props.cssEffects.selectAllHover) {
        let elem = document.getElementsByClassName("ag-header-select-all");
        var str = "\\0000a0\\0000a0Select All";
        for (var i = 0; i < elem.length; i++) {
          elem[i].onmouseover = function() {
            document.styleSheets[0].addRule(
              ".ag-header-select-all:after",
              'content: "' + str + '";padding-left:"10px";'
            );
          };
          elem[i].onmouseout = function() {
            document.styleSheets[0].addRule(
              ".ag-header-select-all:after",
              'content: "";'
            );
          };
        }
      }
    }
  };

  handleFirstDataRendered = event => {
    this.props.sizeToFit && event.api.sizeColumnsToFit();
    // : event.columnApi.autoSizeAllColumns();
  };
  gridSizeChanged = event => {
    this.props.sizeToFit && event.api.sizeColumnsToFit();
    // : event.columnApi.autoSizeAllColumns();
  };

  ExportExcel = () => {
    var params = {
      fileName: "LineArchitecture",
      sheetName: "sheet1"
    };
    this.gridApi.exportDataAsExcel(params);
  };

  ExportExcelRollup = () => {
    var params = {
      fileName: "Buy Rollup",
      sheetName: "sheet1"
    };
    this.gridApi.exportDataAsExcel(params);
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="ag-theme-balham"
          style={{
            height: "100%",
            width: "100%"
          }}
        >
          <AgGridReact
            excelStyles={this.props.excelStyles || this.state.excelStyles}
            rowHeight="33"
            headerHeight="43"
            suppressMenuHide={false}
            singleClickEdit={true}
            animateRows={true}
            onGridReady={this.onGridReady}
            suppressAggFuncInHeader={true}
            groupDefaultExpanded={this.props.defaultExpand ? -1 : null}
            frameworkComponents={this.frameworkComponents}
            rowSelection="multiple"
            groupSelectsChildren={true}
            onFirstDataRendered={this.handleFirstDataRendered}
            onGridSizeChanged={this.gridSizeChanged}
            components={{
              numericCellEditor: NumericCellEditor
            }}
            autoGroupColumnDef={{
              pinned: "left",
              ...this.props.groupColDef
            }}
            {...this.props}
          />
          {this.props.legends && <div className="mt-2 mb-2">
          {this.props.legends.map((e, i) => {
            return (
              <div className="table-legends-container mr-3" key={i}>
              <div
                className="table-legends mr-2"
                style={{ backgroundColor: e.color }}
              ></div> {e.text} </div>
            );
          })}
        </div>}
        </div>
        
      </React.Fragment>
    );
  }
}

export default DataTable;

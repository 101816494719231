const filtersReducerDefaultState = {
  userData: null,
  allusers: [],
  status: null,
  projectNamesList: null,
  projectList: null,
  hindsightProjectNamesList: null
};

export default (state = filtersReducerDefaultState, action) => {
  switch (action.type) {
    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.payload
      };
    case "SET_ALL_USERS":
      return {
        ...state,
        allusers:action.payload
      };
    case "ADD_NEW_USER":
      return {
        ...state,
        allusers:[...state.allusers,action.payload]
      }
    case "SET_LOGIN_STATUS":
      return {
        ...state,
        status: action.payload
      };
    case "SET_RESETPASSWORD_STATUS":
      return {
        ...state,
        status: action.payload
      };
    case "SET_FORGOTPASSWORD_STATUS":
      return {
        ...state,
        status: action.payload
      };
    case "SET_PROJECT_NAMES_LIST":
      return {
        ...state,
        projectNamesList: action.payload
      };
    case "SET_PROJECT_LIST":
      return {
        ...state,
        projectList: action.payload
      };
    case "SET_HINDSIGHT_PROJECT_NAMES_LIST":
      return {
        ...state,
        hindsightProjectNamesList: action.payload
      };
    default:
      return state;
  }
};

import numeral from "numeral";
import moment from "moment";

export var dateFormatter = function(params) {
  return moment(params.value).format("MM-DD-YYYY");
};

export function roundToTwoDecimal(value) {
  if (typeof value.value === "number") {
    // console.log("value", value.value)
    return value.value.toFixed(2);
  } else {
    // console.log("value 1", value.value)
    return null;
  }
}

export var percentageFormatter = function(params) {
  if (!isNaN(params.value)) {
    return numeral(params.value / 100).format("0.00%");
  } else {
    return "-";
  }
};
export var percentageFormatterWithZero = function(params) {
  if (!params.value) {
    return numeral(params.value).format("0%");
  } else {
    return numeral(params.value / 100).format("0.00%");
  }
};
export var imupercentageFormatter = function(params) {
  if (params.value !== 0) {
    return numeral(params.value / 100).format("0.00%");
  } else {
    return "-";
  }
};
export var percentageFormatterNA = function(params) {
  return numeral(params.value / 100).format("0.00%");
};
export var percentageFormatterNA1 = function(params) {
  if (
    (params.data && params.data["%_class/vendor_rcpts"] !== 0) ||
    (params.data && params.data["%_class/vendor_rcpts_ly"] !== 0)
  ) {
    return numeral(params.value / 100).format("0.00%");
  } else {
    return " ";
  }
};
export var decimalFormatterwith$ = function(params) {
  if (!isNaN(params.value)) {
    return numeral(params.value).format("($0,00)");
  } else {
    return "-";
  }
};
export var decimalFormatterwithRound$ = function(params) {
  if (!isNaN(params.value)) {
    return numeral(params.value).format("($0,0.00)");
  } else {
    return "-";
  }
};
export var wholewith$ = function(params) {
  if (params.value) {
    return numeral(params.value).format("$0,0");
  } else {
    return "-";
  }
};
export var decimalFormatter = function(params) {
  if (params.value || params.value === 0) {
    return numeral(params.value).format("0.00");
  } else {
    return "-";
  }
};
export var decimalFormatter$ = function(params) {
  if (params.value) {
    return numeral(params.value).format("$0.00");
  } else {
    return "-";
  }
};
export var decimalFormatter$_withZero = function(params) {
  if (!params.value) {
    return numeral(params.value).format("$0");
  } else {
    return numeral(params.value).format("$0.00");
  }
};
export var toUnitNoData = function(params) {
  if (params.value) {
    return numeral(params.value).format("(0,0)");
  } else {
    return "-";
  }
};
export var toUnitEmptyData = function(params) {
  if (params.value || params.value === 0) {
    return numeral(params.value).format("0,0");
  } else {
    return " ";
  }
};
export var noData = function(params) {
  if (params.value === undefined) {
    return "-";
  } else {
    return numeral(params.value).format("$0.00");
  }
};
export var toDollar = function(params) {
  // Wrote this condition to handle issue in numeral library for -ve zero value
  if (Math.round(params.value) === -0) {
    params.value = 0;
  }
  if (!params.value) {
    return "-";
  }
  return numeral(params.value).format("($0,0)");
};
export var toUnit = function(params) {
  if (!isNaN(params.value)) {
    return numeral(params.value).format("(0,0)");
  } else {
    return "-";
  }
};
export var toPercentage = function(params) {
  return !isNaN(parseInt(params.value))
    ? numeral(params.value / 100).format("0.00%")
    : "-";
};
export var decimalToPercentage = function(params) {
  return numeral(params.value).format("0.00%");
};
export var _stringFormatter = function(params) {
  return params.value.split("_").join(" ");
};

export var toMillion = function(value) {
  return numeral(value).format("0.0a");
};

export var toMillionDollar = function(value) {
  return numeral(value).format("($ 0.00 a)");
};
export var toUnitWithZero = function(params) {
  if (params.value) return numeral(params.value).format("(0,0)");
  else return 0;
};

/****************** Array of Objects to Object Conversion ******************/

export var toUnitNormal = function(params) {
  if (!isNaN(params)) {
    return numeral(params).format("0,00");
  } else {
    return 0;
  }
};

export var decimalFormatterNormal = function(params) {
  if (!isNaN(params)) {
    return numeral(params).format("0.00");
  } else {
    return 0;
  }
};

export var decimalFormatterwithRound$Normal = function(params) {
  if (!isNaN(params)) {
    return numeral(params).format("($0,0.00)");
  } else {
    return "-";
  }
};

export var percentageFormatterNormal = function(params) {
  if (!isNaN(params)) {
    return numeral(params / 100).format("0.00%");
  } else {
    return "-";
  }
};

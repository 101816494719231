import {
  decimalFormatter,
  decimalFormatterwith$,
  decimalFormatterwithRound$,
  imupercentageFormatter,
  toUnit
} from "../../helpers/formatters";
import { tar } from "../../styles";

// function aggAur(values) {
//   var Revenue = 0;
//   var Units_Sold = 0;
//   values.forEach(function(value) {
//     if (value && value.Revenue) {
//       Revenue += value.Revenue;
//     }
//     if (value && value.Units_Sold) {
//       Units_Sold += value.Units_Sold;
//     }
//   });
//   return {
//     Revenue,
//     Units_Sold,
//     toString: function() {
//       return Revenue && Units_Sold ? Revenue / Units_Sold : 0;
//     }
//   };
// }

// function aurValueGetter(params) {
//   if (!params.node.group) {
//     const { Revenue, Units_Sold } = params.data;
//     return {
//       Revenue,
//       Units_Sold,
//       toString: function() {
//         return Revenue && Units_Sold ? Revenue / Units_Sold : 0;
//       }
//     };
//   }
// }
// function aggLYAur(values) {
//   var Revenue_LY = 0;
//   var Units_Sold_LY = 0;
//   values.forEach(function(value) {
//     if (value && value.Revenue_LY) {
//       Revenue_LY += value.Revenue_LY;
//     }
//     if (value && value.Units_Sold_LY) {
//       Units_Sold_LY += value.Units_Sold_LY;
//     }
//   });
//   return {
//     Revenue_LY,
//     Units_Sold_LY,
//     toString: function() {
//       return Revenue_LY && Units_Sold_LY ? Revenue_LY / Units_Sold_LY : 0;
//     }
//   };
// }

export default {
  defaultColDef: {
    animateRows: true,
    resizable: true,
    unSortIcon: true,
    menuTabs: []
  },
  columnDefs: function(lyFlag) {
    return [
      {
        headerName: "Total",
        tooltipField: "Financial_year",
        width: 332,
        sortable: true,
        unSortIcon: true,
        field: "Financial_Year",
        hide: true,
        rowGroup: true,
        cellStyle: tar,
        valueFormatter: val => {
          return "Total";
        }
      },
      {
        headerName: "Department",
        cellStyle: tar,
        sortable: true,
        unSortIcon: true,
        field: "dept_nm",
        rowGroup: true,
        hide: true
      },
      {
        headerName: "Sub Class",
        cellStyle: tar,
        sortable: true,
        unSortIcon: true,
        field: "subclass",
        rowGroup: true,
        hide: true
      },
      {
        headerName: "Class/Vendor",
        width: 150,
        sortable: true,
        unSortIcon: true,
        field: "Name"
        // aggFunc: (val, abc) => {
        //   console.log(val, abc);
        //   return val[0];
        // },
        // valueFormatter: val => {
        //   return val.node.rowGroupIndex === 2 ? val.value : "";
        // }
      },
      {
        headerName: "TY Budget",
        cellStyle: tar,
        width: 120,
        aggFunc: "sum",
        sortable: true,
        unSortIcon: true,
        field: "Total_Buy_Cost_TY",
        cellClass: ["excelExport_Format-0.00"],
        valueFormatter: decimalFormatterwithRound$
      },
      {
        headerName: lyFlag ? "LY Budget" : "LLY Budget",
        cellStyle: tar,
        width: 120,
        aggFunc: "sum",
        sortable: true,
        unSortIcon: true,
        cellClass: ["excelExport_Format-0.00"],
        field: lyFlag ? "Total_Buy_Cost_LY" : "Total_Buy_Cost_LLY",
        valueFormatter: decimalFormatterwithRound$
      },
      {
        headerName: "TY Units",
        cellStyle: tar,
        width: 120,
        sortable: true,
        aggFunc: "sum",
        unSortIcon: true,
        field: "Total_Buy_Units_TY",
        cellClass: ["excelExport_Format-0.00"],
        valueFormatter: toUnit
      },
      {
        headerName: lyFlag ? "LY Units " : "LLY Units",
        cellStyle: tar,
        aggFunc: "sum",
        width: 120,
        sortable: true,
        unSortIcon: true,
        field: lyFlag ? "Total_Buy_Units_LY" : "Total_Buy_Units_LLY",
        cellClass: ["excelExport_Format-0.00"],
        valueFormatter: toUnit
      },
      {
        headerName: "TY Choice Count",
        cellStyle: tar,
        aggFunc: "sum",
        width: 120,
        sortable: true,
        unSortIcon: true,
        field: "CC_TY",
        cellClass: ["excelExport_Format-0.00"],
        valueFormatter: toUnit
      },
      {
        headerName: lyFlag ? "LY Choice Count" : "LLY Choice Count",
        cellStyle: tar,
        aggFunc: "sum",
        width: 120,
        sortable: true,
        unSortIcon: true,
        field: lyFlag ? "CC_LY" : "CC_LLY",
        cellClass: ["excelExport_Format-0.00"],
        valueFormatter: toUnit
      },
      {
        headerName: "TY Door Count",
        cellStyle: tar,
        aggFunc: "max",
        width: 120,
        sortable: true,
        unSortIcon: true,
        field: "Avg_Store_Count_TY",
        cellClass: ["excelExport_Format-0.00"],
        valueFormatter: toUnit
      },
      {
        headerName: lyFlag ? "LY Door Count" : "LLY Door Count",
        cellStyle: tar,
        aggFunc: "max",
        width: 120,
        sortable: true,
        unSortIcon: true,
        field: lyFlag ? "Avg_Store_Count_LY" : "Avg_Store_Count_LLY",
        cellClass: ["excelExport_Format-0.00"],
        valueFormatter: toUnit
      },
      {
        headerName: "TY APS",
        cellStyle: tar,
        aggFunc: "avg",
        width: 120,
        sortable: true,
        unSortIcon: true,
        field: "APS_TY",
        cellClass: ["excelExport_Format-0.00"],
        valueFormatter: decimalFormatter
      },
      {
        headerName: lyFlag ? "LY APS" : "LLY APS",
        cellStyle: tar,
        aggFunc: "avg",
        width: 120,
        sortable: true,
        unSortIcon: true,
        field: lyFlag ? "APS_LY" : "APS_LLY",
        cellClass: ["excelExport_Format-0.00"],
        valueFormatter: decimalFormatter
      },
      {
        headerName: "TY Depth",
        cellStyle: tar,
        aggFunc: "avg",
        width: 120,
        sortable: true,
        unSortIcon: true,
        field: "Depth_TY",
        cellClass: ["excelExport_Format-0.00"],
        valueFormatter: toUnit
      },
      {
        headerName: lyFlag ? "LY Depth" : "LLY Depth",
        cellStyle: tar,
        aggFunc: "avg",
        width: 120,
        sortable: true,
        unSortIcon: true,
        field: lyFlag ? "Depth_LY" : "Depth_LLY",
        cellClass: ["excelExport_Format-0.00"],
        valueFormatter: toUnit
      },
      {
        headerName: "TY AUR",
        cellStyle: tar,
        aggFunc: "avg",
        width: 120,
        sortable: true,
        unSortIcon: true,
        field: "Sales_AUR_TY",
        cellClass: ["excelExport_Format-0.00"],
        valueFormatter: decimalFormatterwith$
      },
      {
        headerName: lyFlag ? "LY AUR" : "LLY AUR",
        cellStyle: tar,
        aggFunc: "avg",
        width: 120,
        sortable: true,
        unSortIcon: true,
        field: lyFlag ? "Sales_AUR_LY" : "Sales_AUR_LLY",
        cellClass: ["excelExport_Format-0.00"],
        valueFormatter: decimalFormatterwith$
      },
      {
        headerName: "TY IMU",
        cellStyle: tar,
        aggFunc: "avg",
        width: 120,
        sortable: true,
        unSortIcon: true,
        field: "IMU_TY",
        cellClass: ["excelExport_Format-0.00"],
        valueFormatter: imupercentageFormatter
      },
      {
        headerName: lyFlag ? "LY IMU" : "LLY IMU",
        cellStyle: tar,
        aggFunc: "avg",
        width: 120,
        sortable: true,
        unSortIcon: true,
        field: lyFlag ? "IMU_LY" : "IMU_LLY",
        cellClass: ["excelExport_Format-0.00"],
        valueFormatter: imupercentageFormatter
      }
    ];
  }
};

export default {
  defaultColDef: {
    animateRows: true,
    sortable: true,
    unSortIcon: true,
    resizable: true,
    menuTabs: []
  },
  animateRows: true,
  sortable: true,
  filter: true,
  unSortIcon: true,
  suppressCellSelection: true,
  cellClass: "no-border",
  columnDefs: [
    {
      resizable: true,
      headerName: "CLUSTER NAME",
      width: 155,
      filter: "agTextColumnFilter",
      field: "name",
      pinned: "left",
      checkboxSelection: true,
    },
    {
      resizable: true,
      headerName: "DEPT ID",
      tooltipField: "departments",
      filter: "agTextColumnFilter",
      field: "departments"
    },
    {
      resizable: true,
      headerName: "RCPT PERIOD",
      filter: "agTextColumnFilter",
      field: "receipt_date"
    },
    {
      resizable: true,
      headerName: "SALES PERIOD",
      // width: 125,
      filter: "agTextColumnFilter",
      field: "selling_date"
    }
    // {
    //   headerName: "ACTION",
    //   cellRenderer: "HindsightActionInvoke",
    //   width: 100
    // }
  ]
};

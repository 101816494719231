import React, { Component } from "react";
import store from "../../store";
import clusteringActions from "../../actions/ClusteringActions"

export default class ChildMessageRenderer extends Component {
  constructor(props) {
    super(props);
    this.numberOfClusters =
      store.getState().clusteringReducer.userSelectedClusters ||
      store.getState().clusteringReducer.optimalClusters;
    this.state = {
      selectedClusterNum: props.value
    };
  }
  onChangeCluster = val => {
    let selectedCluster = val.currentTarget.value;
    let selectedClusterMapping = store.getState().clusteringReducer
      .clusterMapping[this.numberOfClusters];
    this.setState(prevState => {
      selectedClusterMapping[selectedCluster].push(
        selectedClusterMapping[prevState.selectedClusterNum].splice(
          selectedClusterMapping[prevState.selectedClusterNum].indexOf(
            this.props.data.loc_id
          ),
          1
        )[0]
      );
      this.props.data.clusters = selectedCluster;
      this.props.api.redrawRows();
      store.dispatch(clusteringActions.setClusterResultStoreLevel([...store.getState().clusteringReducer.clusterResultsStoreLevel]))
      return { selectedClusterNum: selectedCluster };
    });
  };

  render() {
    let options = [];
    for (let i = 1; i <= Number(this.numberOfClusters); i++) {
      options.push(<option key={i}>{i}</option>);
    }
    return (
      <span>
        {this.props.value === 0 ? (
          "Ecom"
        ) : (
          !(this.props.value === "") ? (
          <select
            value={this.state.selectedClusterNum}
            onChange={val => this.onChangeCluster(val)}
          >
            {options}
          </select>
          ) : '-'
        )}
      </span>
    );
  }
}

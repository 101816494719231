export const BudgetOptions = {
  tooltips: {
    mode: "label",
    intersect: true,
    callbacks: {
      title: function(data) {
        return `Budget`;
      },
      afterTitle: function(tooltipItem, data) {
        return `Sub Group: ${
          data.datasets[0].subClasses[tooltipItem[0].index]
        }`;
      }
    }
  },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Sub Group"
        },
        gridLines: {
          display: false
        },
        ticks: {
          fontSize: 12,
          callback: function(value, index, values) {
            return "SG" + (value + 1);
          }
        }
      }
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true
        },
        gridLines: {
          display: false
        },
        ticks: {
          min: 0
        }
      }
    ]
  },
  responsive: true
};

export const UnitOptions = {
  tooltips: {
    mode: "label",
    intersect: true,
    callbacks: {
      title: function(data) {
        return `Units`;
      },
      afterTitle: function(tooltipItem, data) {
        return `Sub Group: ${
          data.datasets[0].subClasses[tooltipItem[0].index]
        }`;
      }
    }
  },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Sub Group"
        },
        gridLines: {
          display: false
        },
        ticks: {
          fontSize: 12,
          callback: function(value, index, values) {
            return "SG" + (value + 1);
          }
        }
      }
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true
        },
        gridLines: {
          display: false
        },
        ticks: {
          min: 0
        }
      }
    ]
  },
  responsive: true
};

export const ChoiceCountOptions = {
  tooltips: {
    mode: "label",
    intersect: true,
    callbacks: {
      title: function(data) {
        return `Choice Count`;
      },
      afterTitle: function(tooltipItem, data) {
        return `Sub Group: ${
          data.datasets[0].subClasses[tooltipItem[0].index]
        }`;
      }
    }
  },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Sub Group"
        },
        gridLines: {
          display: false
        },
        ticks: {
          fontSize: 12,
          callback: function(value, index, values) {
            return "SG" + (value + 1);
          }
        }
      }
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true
        },
        gridLines: {
          display: false
        },
        ticks: {
          min: 0
        }
      }
    ]
  },
  responsive: true
};

export const DoorCountOptions = {
  tooltips: {
    mode: "label",
    intersect: true,
    callbacks: {
      title: function(data) {
        return `Door Count`;
      },
      afterTitle: function(tooltipItem, data) {
        return `Sub Group: ${
          data.datasets[0].subClasses[tooltipItem[0].index]
        }`;
      }
    }
  },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Sub Group"
        },
        gridLines: {
          display: false
        },
        ticks: {
          fontSize: 12,
          callback: function(value, index, values) {
            return "SG" + (value + 1);
          }
        }
      }
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true
        },
        gridLines: {
          display: false
        },
        ticks: {
          min: 0
        }
      }
    ]
  },
  responsive: true
};

export const ApsOptions = {
  tooltips: {
    mode: "label",
    intersect: true,
    callbacks: {
      title: function(data) {
        return `APS`;
      },
      afterTitle: function(tooltipItem, data) {
        return `Sub Group: ${
          data.datasets[0].subClasses[tooltipItem[0].index]
        }`;
      }
    }
  },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Sub Group"
        },
        gridLines: {
          display: false
        },
        ticks: {
          fontSize: 12,
          callback: function(value, index, values) {
            return "SG" + (value + 1);
          }
        }
      }
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true
        },
        gridLines: {
          display: false
        },
        ticks: {
          min: 0
        }
      }
    ]
  },
  responsive: true
};

export const DepthOptions = {
  tooltips: {
    mode: "label",
    intersect: true,
    callbacks: {
      title: function(data) {
        return `Depth`;
      },
      afterTitle: function(tooltipItem, data) {
        return `Sub Group: ${
          data.datasets[0].subClasses[tooltipItem[0].index]
        }`;
      }
    }
  },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Sub Group"
        },
        gridLines: {
          display: false
        },
        ticks: {
          fontSize: 12,
          callback: function(value, index, values) {
            return "SG" + (value + 1);
          }
        }
      }
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true
        },
        gridLines: {
          display: false
        },
        ticks: {
          min: 0
        }
      }
    ]
  },
  responsive: true
};

import {
  decimalFormatterwith$,
  decimalFormatterwithRound$,
  percentageFormatter,
  percentageFormatterNA,
  toPercentage,
  toUnit
} from "../../../helpers/formatters";
import { errorInput, inputCss, tar } from "../../../styles";

export default {
  defaultColDef: {
    animateRows: true,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    menuTabs: []
  },
  // row selection
  rowSelection: "multiple",
  animateRows: true,
  sortable: true,
  filter: true,
  unSortIcon: true,
  suppressCellSelection: true,
  cellClass: "no-border",
  groupRowAggNodes: nodes => {
    let tempRcpt = null;
    var obj = {
      // "%_class/vendor_rcpts": null,
      // "%_class/vendor_rcpts_ly": null,
      "%chg_cc": null,
      "%chg_ly": null,
      "%chg_sty_count": null,
      aur: null,
      "TY_imu%": null,
      "LY_imu%": null,
      ly_aur: null,
      ly_avg_buy: null,
      ly_cc: null,
      ly_rcpt$: null,
      "ly_rcpt_pen%": null,
      ly_rcpt_u: null,
      ly_sty_count: null,
      plan_rcpt$: null,
      rcpt$: null,
      "rcpt_pen%": null,
      rcpt_u: null,
      ty_avg_buy: null,
      ty_cc: null,
      ty_sty_count: null
    };
    obj["subClasslevelCount"] = nodes.length;
    nodes.forEach(function(node, index) {
      var data = node.group ? node.aggData : node.data;
      if (typeof Number(data["rcpt_pen%"]) === "number") {
        // adding rcpt only if imu is there
        obj["rcpt_pen%"] += Number(data["rcpt_pen%"]);
      }
      if (typeof Number(data["rcpt_pen%"]) === "number" && data["TY_imu%"]) {
        // adding rcpt only if imu is there
        tempRcpt += Number(data["rcpt_pen%"]);
      }
      if (typeof data["ly_rcpt_pen%"] === "number") {
        obj["ly_rcpt_pen%"] += Number(data["ly_rcpt_pen%"]);
      }
      if (typeof Number(data["rcpt$"]) === "number") {
        obj["rcpt$"] += Number(data["rcpt$"]);
      }
      if (typeof Number(data["plan_rcpt$"]) === "number") {
        obj["plan_rcpt$"] += Number(data["plan_rcpt$"]);
      }
      if (typeof data["ly_rcpt$"] === "number") {
        obj["ly_rcpt$"] += data["ly_rcpt$"];
      }
      if (typeof Number(data["aur"]) === "number") {
        obj["aur"] += data["rcpt_u"] && Number(data["rcpt$"]) / data["rcpt_u"];
      }
      if (typeof Number(data["TY_imu%"]) === "number") {
        obj["TY_imu%"] += Number(data["TY_imu%"]) * Number(data["rcpt_pen%"]);
      }
      if (typeof Number(data["LY_imu%"]) === "number") {
        obj["LY_imu%"] += Number(data["LY_imu%"]) * Number(data["rcpt_pen%"]);
      }
      if (typeof data["ly_aur"] === "number") {
        obj["ly_aur"] += data["ly_aur"];
      }
      if (typeof data["rcpt_u"] === "number") {
        obj["rcpt_u"] += data["rcpt_u"];
      }
      if (typeof Number(data["ly_rcpt_u"]) === "number") {
        obj["ly_rcpt_u"] += Number(data["ly_rcpt_u"]);
        // obj["ly_rcpt_u"] += Number(data["ly_rcpt$"]) / Number(data["ly_aur"]);
      }
      if (typeof Number(data["ty_cc"]) === "number") {
        obj["ty_cc"] += Number(data["ty_cc"]);
      }
      if (typeof data["ly_cc"] === "number") {
        obj["ly_cc"] += data["ly_cc"];
      }
      if (typeof Number(data["ty_sty_count"]) === "number") {
        obj["ty_sty_count"] += Number(data["ty_sty_count"]);
      }
      if (typeof Number(data["ly_sty_count"]) === "number") {
        obj["ly_sty_count"] += Number(data["ly_sty_count"]);
      }
      if (typeof Number(data["%chg_cc"]) === "number") {
        obj["%chg_cc"] += (obj["ty_cc"] / obj["ly_cc"] - 1) / 100;
      }
      if (typeof Number(data["%chg_ly"]) === "number") {
        obj["%chg_ly"] += Number(data["%chg_ly"]);
      }
      if (typeof Number(data["%chg_sty_count"]) === "number") {
        obj["%chg_sty_count"] += Number(data["%chg_sty_count"]);
      }
      // if (typeof Number(data["%_class/vendor_rcpts"]) === "number") {
      //   obj["%_class/vendor_rcpts"] += Number(data["%_class/vendor_rcpts"]);
      // }
      // if (typeof Number(data["%_class/vendor_rcpts_ly"]) === "number") {
      //   obj["%_class/vendor_rcpts_ly"] += Number(
      //     data["%_class/vendor_rcpts_ly"]
      //   );
      // }
      if (typeof Number(data["ty_avg_buy"]) === "number") {
        obj["ty_avg_buy"] += Number(data["ty_avg_buy"]);
      }
      if (typeof Number(data["ly_avg_buy"]) === "number") {
        obj["ly_avg_buy"] += Number(data["ly_avg_buy"]);
      }
      // if (typeof Number(data["ly_rcpt_u"]) === "number") {
      //   obj["ly_rcpt_u"] += Number(data["ly_rcpt_u"]);
      // }
    });
    obj["ly_rcpt$"] &&
      (obj["%chg_ly"] = (obj["rcpt$"] / obj["ly_rcpt$"] - 1) * 100);
    obj["ly_cc"] && (obj["%chg_cc"] = (obj["ty_cc"] / obj["ly_cc"] - 1) * 100);
    obj["rcpt_u"] = Math.round(obj["rcpt_u"]);
    // obj["rcpt_pen%"] = obj["rcpt_pen%"];
    // obj["ly_rcpt_pen%"] = obj["ly_rcpt_pen%"];
    obj["ty_sty_count"] = Math.round(obj["ty_sty_count"]);
    obj["ty_cc"] = Math.round(obj["ty_cc"]);
    if (obj["subClasslevelCount"]) {
      obj["aur"] = obj["aur"] / obj["subClasslevelCount"];
      obj["ly_aur"] = obj["ly_aur"] / obj["subClasslevelCount"];
    }
    if (tempRcpt) {
      obj["TY_imu%"] = obj["TY_imu%"] / tempRcpt;
      obj["LY_imu%"] = obj["LY_imu%"] / tempRcpt;
    }
    // obj["ly_rcpt_u"] = obj["ly_rcpt_u"];
    obj["ly_cc"] && (obj["ly_avg_buy"] = obj["ly_rcpt_u"] / obj["ly_cc"]);
    obj["ty_cc"] && (obj["ty_avg_buy"] = obj["rcpt_u"] / obj["ty_cc"]);
    obj["ly_sty_count"] &&
      (obj["%chg_sty_count"] =
        (obj["ty_sty_count"] / obj["ly_sty_count"] - 1) * 100);
    // obj["%chg_cc"] = (((obj["ty_cc"]) / (obj["ly_cc"] - 1)) * 100);
    if (Number.isNaN(obj["imu%"])) obj["imu%"] = "0.00%";
    return obj;
  },
  columnDefs: function(userAccessDisableFlag, lyFlag) {
    return [
      {
        headerName: "Total",
        tooltipField: "Financial_year",
        width: 332,
        sortable: true,
        unSortIcon: true,
        field: "Financial_year",
        hide: true,
        rowGroup: true,
        cellStyle: tar,
        valueFormatter: val => {
          return "Total";
        }
      },
      {
        headerName: "Class/Vendor",
        cellStyle: tar,
        sortable: true,
        unSortIcon: true,
        field: "class/vendor",
        rowGroup: true,
        hide: true
      },
      {
        headerName: "SUB GRP",
        cellStyle: tar,
        sortable: true,
        unSortIcon: true,
        field: "subclass",
        pinned: "left",
        hide: true
      },
      {
        headerName: "RCPT PEN%",
        width: 80,
        sortable: true,
        unSortIcon: true,
        field: "rcpt_pen%",
        valueSetter: function(params) {
          params.data["rcpt_pen%"] = isNaN(+params.newValue)
            ? +params.oldValue
            : +params.newValue;
          return true;
        },
        valueFormatter: toPercentage,
        // cellEditor: "numericCellEditor",
        editable: !userAccessDisableFlag && true,
        cellStyle: function(params) {
          if (
            !params.data &&
            Math.round(params.node.aggData["rcpt_pen%"]) !== 100 &&
            params.node.rowIndex === 0
          ) {
            return errorInput;
          } else if (params.data) {
            return inputCss;
          } else {
            return { ...{ "background-color": "white" }, ...tar };
          }
        },
        cellClass: ["excelExport_Format-0.00%"]
      },
      {
        headerName: lyFlag ? "LY RCPT PEN%" : "LLY RCPT PEN%",
        cellStyle: tar,
        width: 94,
        sortable: true,
        unSortIcon: true,
        field: "ly_rcpt_pen%",
        valueFormatter: percentageFormatter,
        cellClass: ["excelExport_Format-0.00%"]
      },
      {
        headerName: "RCPT $",
        cellStyle: tar,
        width: 120,
        sortable: true,
        unSortIcon: true,
        valueFormatter: decimalFormatterwith$,
        field: "rcpt$",
        cellClass: ["excelExport_Format-$0"]
      },
      {
        headerName: "PL RCPT$",
        cellStyle: tar,
        width: 110,
        sortable: true,
        unSortIcon: true,
        valueFormatter: decimalFormatterwith$,
        field: "plan_rcpt$",
        cellClass: ["excelExport_Format-$0"]
      },
      {
        headerName: lyFlag ? " LY RCPT$" : "LLY RCPT$",
        cellStyle: tar,
        width: 115,
        sortable: true,
        unSortIcon: true,
        valueFormatter: decimalFormatterwith$,
        field: "ly_rcpt$",
        cellClass: ["excelExport_Format-$0"]
      },
      {
        headerName: lyFlag ? "LY % CHG" : "LLY % CHG",
        cellStyle: function(node) {
          if (node.data) {
            const islastYearRcptAvailable = node.data["ly_rcpt$"] !== 0;
            return islastYearRcptAvailable ? inputCss : tar;
          } else if (node.data && node.value === 0) {
            return errorInput;
          } else {
            return tar;
          }
        },
        width: 75,
        editable: function(node) {
          const islastYearRcptAvailable = node.data["ly_rcpt$"] !== 0;
          return !userAccessDisableFlag && islastYearRcptAvailable;
        },
        sortable: true,
        unSortIcon: true,
        valueSetter: function(params) {
          params.data["%chg_ly"] = isNaN(+params.newValue)
            ? +params.oldValue
            : +params.newValue;
          return true;
        },
        valueFormatter: percentageFormatter,
        field: "%chg_ly",
        cellClass: ["excelExport_Format-0.00%"]
      },
      {
        headerName: "AUR",
        width: 70,
        sortable: true,
        unSortIcon: true,
        valueFormatter: decimalFormatterwithRound$,
        // valueFormatter: roundToTwoDecimal,
        valueSetter: function(params) {
          params.data["aur"] = isNaN(+params.newValue)
            ? +params.oldValue
            : +params.newValue;
          return true;
        },
        field: "aur",
        editable: !userAccessDisableFlag && true,
        cellStyle: function(params) {
          if (params.data) {
            return inputCss;
          } else if (params.data && params.value === 0) {
            return errorInput;
          } else {
            return { ...{ "background-color": "white" }, ...tar };
          }
        },
        cellClass: ["excelExport_Format-$0.00"]
      },
      {
        headerName: lyFlag ? "LY AUR" : "LLY AUR",
        width: 70,
        sortable: true,
        unSortIcon: true,
        valueFormatter: decimalFormatterwithRound$,
        field: "ly_aur",
        cellStyle: function(params) {
          if (params.data) {
            return tar;
          } else if (params.data !== undefined && params.data["ly_aur"] === 0) {
            return errorInput;
          } else {
            return { ...{ "background-color": "white" }, ...tar };
          }
        },
        cellClass: ["excelExport_Format-$0.00"]
      },
      {
        headerName: "TY IMU%",
        width: 81,
        sortable: true,
        unSortIcon: true,
        editable: !userAccessDisableFlag && true,
        valueFormatter: percentageFormatter,
        valueSetter: function(params) {
          params.data[`TY_imu%`] = isNaN(+params.newValue)
            ? +params.oldValue
            : +params.newValue;
          return true;
        },
        cellStyle: function(params) {
          if (params.data) {
            return inputCss;
          } else {
            return { ...{ "background-color": "white" }, ...tar };
          }
        },
        field: "TY_imu%",
        cellClass: ["excelExport_Format-0.00%"]
      },
      {
        headerName: lyFlag ? "LY IMU%" : "LLY IMU%",
        width: 81,
        sortable: true,
        unSortIcon: true,
        valueFormatter: percentageFormatter,
        valueSetter: function(params) {
          params.data[`LY_imu%`] = isNaN(+params.newValue)
            ? +params.oldValue
            : +params.newValue;
          return true;
        },
        field: "LY_imu%",
        cellClass: ["excelExport_Format-0.00%"]
      },
      {
        headerName: "U RCPT",
        cellStyle: tar,
        width: 75,
        sortable: true,
        unSortIcon: true,
        field: "rcpt_u",
        valueFormatter: toUnit,
        cellClass: ["excelExport_Format-0"]
      },
      {
        headerName: lyFlag ? "LY RCPT U" : "LLY RCPT U",
        cellStyle: tar,
        width: 89,
        sortable: true,
        unSortIcon: true,
        field: "ly_rcpt_u",
        // valueGetter: function(params) {
        //   if (params.data) {
        //     const value =
        //       Number(params.data["ly_rcpt$"]) / Number(params.data["ly_aur"]);
        //     params.data["ly_rcpt_u"] = value;
        //     return value;
        //   } else {
        //     return params.node.aggData["ly_rcpt_u"];
        //   }
        // },
        valueFormatter: toUnit,
        cellClass: ["excelExport_Format-0"]
      },
      {
        headerName: "TY CC",
        width: 60,
        sortable: true,
        unSortIcon: true,
        field: "ty_cc",
        // valueFormatter: decimalFormatter,
        cellEditor: "numericCellEditor",
        editable: !userAccessDisableFlag && true,
        valueSetter: function(params) {
          params.data["ty_cc"] = isNaN(+params.newValue)
            ? +params.oldValue
            : +params.newValue;
          return true;
        },
        cellStyle: function(params) {
          if (params.data) {
            return inputCss;
          } else if (
            params.data !== undefined &&
            Number(params.value) <= Number(params.data.ty_sty_count)
          ) {
            return errorInput;
          } else {
            return { ...{ "background-color": "white" }, ...tar };
          }
        }
      },
      {
        headerName: lyFlag ? "LY CC" : "LLY CC",
        width: 60,
        sortable: true,
        unSortIcon: true,
        field: "ly_cc",
        // valueFormatter: decimalFormatter,
        cellStyle: function(params) {
          if (params.data) {
            return tar;
          } else if (
            params.data !== undefined &&
            Number(params.data["ly_cc"]) <= Number(params.data.ly_sty_count)
          ) {
            return errorInput;
          } else {
            return { ...{ "background-color": "white" }, ...tar };
          }
        }
      },
      {
        headerName: "% CHG CC",
        cellStyle: tar,
        width: 85,
        sortable: true,
        unSortIcon: true,
        valueFormatter: percentageFormatterNA,
        field: "%chg_cc",
        cellClass: ["excelExport_Format-0.00%"]
      },
      {
        headerName: "TY STY COUNT",
        width: 88,
        sortable: true,
        unSortIcon: true,
        // valueFormatter: decimalFormatter,
        field: "ty_sty_count",
        cellEditor: "numericCellEditor",
        editable: !userAccessDisableFlag && true,
        valueSetter: function(params) {
          params.data["ty_sty_count"] = isNaN(+params.newValue)
            ? +params.oldValue
            : +params.newValue;
          return true;
        },
        cellStyle: function(params) {
          if (params.data) {
            return inputCss;
          } else if (
            params.data !== undefined &&
            Number(params.data.ty_sty_count) === 0
          ) {
            return errorInput;
          } else {
            return { ...{ "background-color": "white" }, ...tar };
          }
        }
      },
      {
        headerName: lyFlag ? "LY STY COUNT" : "LLY STY COUNT",
        cellStyle: tar,
        width: 88,
        sortable: true,
        unSortIcon: true,
        // valueFormatter: decimalFormatter,
        field: "ly_sty_count"
      },
      {
        headerName: "% CHG STY COUNT",
        cellStyle: tar,
        width: 110,
        sortable: true,
        unSortIcon: true,
        valueFormatter: percentageFormatterNA,
        field: "%chg_sty_count",
        cellClass: ["excelExport_Format-0.00%"]
      },
      {
        headerName: "TY AVG BUY",
        cellStyle: tar,
        width: 89,
        sortable: true,
        unSortIcon: true,
        valueFormatter: toUnit,
        field: "ty_avg_buy",
        cellClass: ["excelExport_Format-0"]
      },
      {
        headerName: lyFlag ? "LY AVG BUY" : "LLY AVG BUY",
        cellStyle: tar,
        width: 90,
        sortable: true,
        unSortIcon: true,
        valueFormatter: toUnit,
        field: "ly_avg_buy",
        cellClass: ["excelExport_Format-0"]
      },
      {
        headerName: "COMMENTS",
        width: 115,
        sortable: true,
        unSortIcon: true,
        field: "comments",
        editable: !userAccessDisableFlag && true,
        filter: "agTextColumnFilter",
        tooltipField: "comments",
        cellStyle: function(params) {
          if (params.data) {
            delete inputCss["textAlign"];
            return inputCss;
          } else {
            return { ...{ "background-color": "white" }, ...tar };
          }
        }
      },
      {
        headerName: "% OF CLASS RCPTS",
        cellStyle: params => {
          if (Number(params.node.id) < 0)
            return { background: "white", color: "white" };
        },
        width: 84,
        sortable: true,
        unSortIcon: true,
        valueFormatter: percentageFormatterNA,
        field: "%_class/vendor_rcpts",
        cellClass: ["excelExport_Format-0.00%"]
      },
      {
        headerName: lyFlag ? "LY % OF CLASS RCPTS" : "LLY % OF CLASS RCPTS",
        cellStyle: params => {
          if (Number(params.node.id) < 0)
            return { background: "white", color: "white" };
        },
        width: 93,
        sortable: true,
        unSortIcon: true,
        valueFormatter: percentageFormatterNA,
        field: "%_class/vendor_rcpts_ly",
        cellClass: ["excelExport_Format-0.00%"]
      }
    ];
  }
};

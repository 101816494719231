import Button from "@material-ui/core/Button";
import React, { Component, Fragment } from "react";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import ClusteringActions from "../../../actions/ClusteringActions";
import ProjectActions from "../../../actions/ProjectActions";
import Alert from "../../../services/alert";
import DataTable from "../../../services/dataTable";
import volumeGroupCalculations from "./../Step3VolumeGroups/volumeGroupCalculations";
import volumeGroupCalculations1 from "./../Step3VolumeGroups/volumeGroupCalculations1";
import VolumeGroupData from "./clusterColumnDefs";
import FinalClusterSummary from "./columnDefs";
import HindsightColDef from "./hindsightColDef";
import MannualClustering from "./MannualClustering";

class Step4StoreLevelCLusters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUnassignStoresAlert: false,
      selectedHinsightCluster: null,
      isHindsightCluster: false,
      hindsightClusterData: null,
      isHindsightModal: false
    };
  }
  componentDidMount() {
    if (this.props.clusterResultsStoreLevel.length) this.calculateAggregate();
  }

  componentDidUpdate() {
    if (this.aggGridApi) {
      const assignedClusters = this.props.clusterResultsStoreLevel.filter(
        e =>
          !this.props.clusterMapping[this.props.userSelectedClusters][
            this.props.unassignedStoresIndex
          ].includes(`${+e.loc_id}`)
      );
      this.aggGridApi.setRowData(assignedClusters);
    }
  }
  calculateAggregate = () => {
    let obj = {
      choice: null,
      weeks_active: null,
      sales_u: null,
      tot_sls_qty: null,
      sales_rtl: null,
      gm: null,
      gm_asst: null,
      oh_tot: null,
      rcpt_u: null,
      rcpt_qty_st: null,
      bop_u: null,
      eop_u: null,
      u_turn_denom: null,
      sales_rtl_lost_sales: null,
      u_rec_depth: null,
      clusters: null,
      loc_count: null,
      // weeks_active: null,
      rcpts_per_door_per_cc: null,
      u_turn: null,
      sls_u_per_door: null,
      rtl_per_door: null,
      gm_per_door: null,
      gm_rate: null,
      gm_asst_per_door: null,
      gm_asst_rate: null,
      aur: null,
      aps: null,
      aps_gm: null,
      aps_gm_asst: null,
      sell_through: null,
      loc_id: null
    };
    let storeData = this.props.clusterResultsStoreLevel;
    let originalStoreData = this.props.clusterResultsStoreLevel.filter(e => {
      return !this.props.clusterMapping[this.props.userSelectedClusters][
        this.props.unassignedStoresIndex
      ].includes(`${+e.loc_id}`);
    });
    obj.loc_count = storeData.length - 1;
    storeData.forEach((row, ind) => {
      if (row.clusters !== 0) {
        volumeGroupCalculations(true, obj, row, ind);
      }
    });
    let storeLength = originalStoreData && originalStoreData.length - 1;
    obj.clusters = `Total CL's ${this.props.userSelectedClusters *
      this.props.optimalAttributeClusters} (${storeLength})`;
    this.setState({
      pinnedBottomRow: [{ ...volumeGroupCalculations1(obj) }]
    });
  };

  onApplyHindsightCluster = () => {
    let selectedCluster = this.gridApi.getSelectedRows();
    let payload = {
      clusterMasterId: this.props.clusterMasterId,
      hindSightMasterId: selectedCluster[0].id,
      hasAttributeClustering: this.props.hasAttributeClustering
    };
    this.props
      .changeMappingFromHindSighting(payload)
      .then(resp => {
        if (resp) {
          this.setState({
            isHindsightModal: false
          });
          // this.props.onSaveAndDownload({isDownload: 0})
          toast.success(
            "All the stores are remapped as per the selected cluster."
          );
        }
      })
      .catch(err => {
        toast.error("Error!!", { autoClose: false });
      });
  };
  onHindSightCluster = () => {
    // Checking the ecomm between hindsight cluster and manual cluster flow.
    if (this.props.projectMainStage < 2) {
      const { hasAttributeClustering } = this.props;
      this.props.getHindsightProjects({ hasAttributeClustering }).then(resp => {
        if (resp) {
          let hindsightCluster = resp.data.filter(
            rowData => this.props.projectDetails.ecomm_f === rowData.ecommFlag
          );
          this.setState({
            hindsightClusterData: hindsightCluster,
            isHindsightModal: true
          });
        }
      });
    }
  };
  render() {
    FinalClusterSummary(this.props.hasAttributeClustering).columnDefs.forEach(
      ele => {
        if (ele.field === "clusters") {
          if (this.props.isClusterDisabled) {
            delete ele.cellRenderer;
          } else {
            ele["cellRenderer"] = "clusterStep4CellRender";
          }
        }
        if (ele.field === "attribute_clusters") {
          if (this.props.isClusterDisabled) {
            delete ele.cellRenderer;
          } else {
            ele["cellRenderer"] = "attrClusterCellRenderer";
          }
        }
      }
    );
    return (
      <Fragment>
        {this.props.hasAttributeClustering && (
          <div className="card" style={{ margin: "1.5rem", padding: "2rem 0" }}>
            <div className="card-body">
              <div
                className="card-header-background"
                style={{
                  padding: "0.75rem"
                }}
              >
                <strong>Cluster Groups</strong>
              </div>
              <div
                style={{
                  height: "calc(100vh - 300px)"
                }}
              >
                <DataTable
                  groupColDef={{
                    headerName: "Clusters",
                    sort: "asc",
                    resizable: true,
                    pinned: "left",
                    field: "clusters",
                    width: 170
                  }}
                  floatingFilter={true}
                  rowData={this.props.clusterResultsStoreLevel.filter(
                    e =>
                      !this.props.clusterMapping[
                        this.props.userSelectedClusters
                      ][this.props.unassignedStoresIndex].includes(
                        `${+e.loc_id}`
                      )
                  )}
                  passGridAPI={api => {
                    this.aggGridApi = api;
                  }}
                  groupRowAggNodes={VolumeGroupData.groupRowAggNodes}
                  columnDefs={VolumeGroupData.columnDefs}
                  defaultColDef={VolumeGroupData.defaultColDef}
                  suppressColumnVirtualisation={true}
                  pinnedBottomRowData={this.state.pinnedBottomRow}
                />
              </div>
            </div>
          </div>
        )}
        {!this.props.isClusterDisabled && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              fontWeight: 600
            }}
          >
            Actions:
            <span>
              <i
                className="faactions fa fa-plus-circle p-2 pointer"
                onClick={this.props.switchModal}
                title="Add New Cluster"
              />
            </span>
            <span>
              <i
                className="faactions fa fa-trash p-2 pointer"
                onClick={() => {
                  if (this.gridApiStoreData.getSelectedRows().length) {
                    this.setState({ isUnassignStoresAlert: true });
                  }
                }}
                title="Remove Store(s) from Cluster"
              />
            </span>
            <span>
              {this.props.isHindsightFlag ? (
                ""
              ) : (
                <span
                  title="Apply Hindsight Cluster"
                  className="pointer"
                  onClick={this.onHindSightCluster}
                >
                  <img
                    style={{
                      width: "18px !important",
                      height: "16px",
                      marginBottom: "0.3rem",
                      marginLeft: "0.25rem"
                    }}
                    alt="hindsight"
                    src="/img/clusterIcon.PNG"
                  />
                </span>
              )}
            </span>
          </div>
        )}
        <div className="card p-3">
          <div className="card-header-background mt-4">
            {this.props.lyFlag ? (
              <strong>Final Cluster Summary (LY)</strong>
            ) : (
              <strong>Final Cluster Summary (LLY)</strong>
            )}
          </div>
          <div style={{ height: "calc(100vh - 340px)", minHeight: "350px" }}>
            <DataTable
              floatingFilter={true}
              suppressColumnVirtualisation={true}
              singleClickEdit={true}
              rowData={this.props.clusterResultsStoreLevel.filter(
                e =>
                  !this.props.clusterMapping[this.props.userSelectedClusters][
                    this.props.unassignedStoresIndex
                  ].includes(`${+e.loc_id}`)
              )}
              columnDefs={
                FinalClusterSummary(
                  (this.props.notEnoughFillRate ? false : this.props.hasAttributeClustering),
                  this.props.isClusterDisabled,
                  undefined,
                  this.props.isHindsightFlag ? true : false
                ).columnDefs
              }
              defaultColDef={
                FinalClusterSummary(this.props.notEnoughFillRate ? false : this.props.hasAttributeClustering)
                  .defaultColDef
              }
              rowSelection="multiple"
              passGridAPI={api => {
                this.gridApiStoreData = api;
              }}
            />
          </div>
        </div>

        {/* Hindsight CLuster */}
        <Modal
          visible={this.state.isHindsightModal}
          effect="fadeInUp"
          width="60%"
          height="60%"
          onClickAway={() => this.setState({ isHindsightModal: false })}
        >
          <Card>
            <CardHeader style={{ background: "#2D69EB", padding: "0.75rem" }}>
              Hindsight Cluster Details
            </CardHeader>
            <CardBody>
              <div style={{ height: "350px" }}>
                <DataTable
                  floatingFilter={true}
                  rowData={this.state.hindsightClusterData}
                  columnDefs={HindsightColDef.columnDefs}
                  defaultColDef={HindsightColDef.defaultColDef}
                  onCellClicked={this.onCellClicked}
                  sizeToFit={true}
                  rowSelection="single"
                  passGridAPI={api => {
                    this.gridApi = api;
                  }}
                />
              </div>
            </CardBody>
            <CardFooter align="right">
              <Button onClick={this.onApplyHindsightCluster}>Apply</Button>
            </CardFooter>
          </Card>
        </Modal>
        {/*  end */}

        <Modal
          visible={this.props.modalVisibleForUnassignedStores}
          width="60%"
          height="90%"
          effect="fadeInUp"
          onClickAway={this.props.switchModal}
        >
          <MannualClustering
            clusterResultsStoreLevel={this.props.clusterResultsStoreLevel.filter(
              e =>
                this.props.clusterMapping[this.props.userSelectedClusters][
                  this.props.unassignedStoresIndex
                ].includes(`${+e.loc_id}`)
            )}
            userSelectedClusters={this.props.userSelectedClusters}
            optimalAttributeClusters={this.props.optimalAttributeClusters}
            hasAttributeClustering={this.props.hasAttributeClustering}
            changeCluster={this.props.changeCluster}
            calculateAggregate={this.calculateAggregate}
          />
        </Modal>
        <Alert
          open={this.state.isUnassignStoresAlert}
          title="Alert"
          isResetRequired={false}
          content="The selected stores will be removed from the cluster. You want to continue?"
          handleAccept={() => {
            this.props.unassignStores(
              this.gridApiStoreData.getSelectedRows().map(e => e)
            );
            this.setState({
              isUnassignStoresAlert: false
            });
          }}
          cancelButtonText="No"
          actionButtonText="Yes"
          handleClose={() => {
            this.setState({
              isUnassignStoresAlert: false
            });
          }}
        />
      </Fragment>
    );
  }
}
const mapStateToProps = store => {
  return {
    lyFlag: store.projectReducer.lyFlag,
    clusterResultsStoreLevel: store.clusteringReducer.clusterResultsStoreLevel,
    notEnoughFillRate: store.clusteringReducer.notEnoughFillRate,
  };
};
const mapDispatchToProps = {
  getHindsightProjects: ProjectActions.getHindsightProjects,
  changeMappingFromHindSighting: ProjectActions.changeMappingFromHindSighting,
  getAttributesSelection: ClusteringActions.getAttributesSelection
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step4StoreLevelCLusters);

import {
  toDollar,
  decimalFormatter,
  toUnit,
  percentageFormatter
} from "../../../helpers/formatters";
import { tar } from "./inputCss";
export default {
  defaultColDef: {
    animateRows: true,
    sortable: true,
    unSortIcon: true,
    resizable: true,
    menuTabs: []
  },
  animateRows: true,
  sortable: true,
  // sortingOrder: ["desc", "asc"],
  cellClass: "no-border",
  columnDefs: function(userDisableAccess, selectedPrimaryAttributes) {
    const columns = [
      {
        headerName: "SUB GRP",
        filter: "agTextColumnFilter",
        width: 107,
        tooltipField: "Custom_Category",
        field: "Custom_Category"
      },
      {
        headerName: "STYLE NAME COLOR",
        width: 130,
        filter: "agTextColumnFilter",
        tooltipField: "Style Name Color",
        field: "Style Name Color"
      },
      {
        headerName: "STYLE #",
        pinned: "left",
        cellStyle: tar,
        width: 92,
        field: "Style_Id"
      },
      {
        headerName: "ACTIONS",
        editable: !userDisableAccess && true,
        width: 150,
        cellStyle: function(params) {
          if (params.data.Action === "Algorithm Optimized")
            return {
              "background-color": "#BBEDC0",
              "border-left": "1px solid grey",
              "border-right": "1px solid grey"
            };
          else if (params.data.Action === "Buyer Proposed") {
            return {
              "background-color": "#C1D5FF",
              "border-left": "1px solid grey",
              "border-right": "1px solid grey"
            };
          }
        },
        cellEditor: "agRichSelectCellEditor",
        field: "Action",
        pinned: "right",
        cellEditorParams: {
          values: ["Algorithm Optimized", "Buyer Proposed"]
        }
        // cellRenderer: "itemLevelCellRender"
      },
      {
        headerName: "TOTAL BUY UNITS",
        children: [
          {
            headerName: "ALGO OPT",
            width: 80,
            valueFormatter: toUnit,
            cellStyle: tar,
            field: "Algorithm Optimized with VA Total Buy Units"
          },
          {
            headerName: "BUYER PROP",
            width: 87,
            valueFormatter: toUnit,
            cellStyle: tar,
            field: "Buyer Proposed Total Buy Units"
          },
          {
            headerName: "BUYER PROP RECALC",
            valueFormatter: toUnit,
            width: 121,
            cellStyle: tar,
            field: "Buyer Proposed - Recalculated Total Buy Units"
          },
          {
            headerName: "VAR %",
            width: 83,
            unSortIcon: true,
            sortable: true,
            cellStyle: tar,
            valueFormatter: percentageFormatter,
            // sortingOrder: ["asc", "desc"],
            field: "Var% Total Buy Units"
          }
        ]
      },
      {
        headerName: "TOTAL BUY COST",
        children: [
          {
            headerName: "ALGO OPT",
            valueFormatter: toUnit,
            width: 80,
            cellStyle: tar,
            field: "Algorithm Optimized with VA Total Buy Cost"
          },
          {
            headerName: "BUYER PROP",
            valueFormatter: toUnit,
            width: 87,
            cellStyle: tar,
            field: "Buyer Proposed Total Buy Cost"
          },
          {
            headerName: "BUYER PROP RECALC",
            width: 121,
            valueFormatter: toUnit,
            cellStyle: tar,
            field: "Buyer Proposed - Recalculated Total Buy Cost"
          },
          {
            headerName: "VAR %",
            width: 83,
            unSortIcon: true,
            sortable: true,
            cellStyle: tar,
            valueFormatter: percentageFormatter,
            // sortingOrder: ["asc", "desc"],
            field: "Var% Total Buy Cost"
          }
        ]
      },
      {
        headerName: "REG APS",
        children: [
          {
            headerName: "ALGO OPT",
            valueFormatter: decimalFormatter,
            width: 80,
            cellStyle: tar,
            field: "Algorithm Optimized with VA Reg APS"
          },
          {
            headerName: "BUYER PROP",
            width: 87,
            valueFormatter: decimalFormatter,
            cellStyle: tar,
            field: "Buyer Proposed Reg APS"
          },
          {
            headerName: "BUYER PROP RECALC",
            valueFormatter: decimalFormatter,
            cellStyle: tar,
            width: 121,
            field: "Buyer Proposed - Recalculated Reg APS"
          },
          {
            headerName: "COMPARISON",
            width: 130,
            field: "Comparison Reg APS"
          }
        ]
      },
      {
        headerName: "STORE COUNT",
        children: [
          {
            headerName: "ALGO OPT",
            valueFormatter: toUnit,
            width: 80,
            cellStyle: tar,
            field: "Algorithm Optimized with VA Store Count"
          },
          {
            headerName: "BUYER PROP",
            valueFormatter: toUnit,
            width: 87,
            cellStyle: tar,
            field: "Buyer Proposed Store Count"
          },
          {
            headerName: "BUYER PROP RECALC",
            valueFormatter: toUnit,
            width: 121,
            cellStyle: tar,
            field: "Buyer Proposed - Recalculated Store Count"
          },
          {
            headerName: "COMPARISON",
            width: 130,
            field: "Comparison Store Count"
          }
        ]
      },
      {
        headerName: "REG UNIT SALES",
        children: [
          {
            headerName: "ALGO OPT",
            cellStyle: tar,
            width: 80,
            valueFormatter: toUnit,
            field: "Algorithm Optimized with VA Reg Unit Sales"
          },
          {
            headerName: "BUYER PROP",
            cellStyle: tar,
            width: 87,
            valueFormatter: toUnit,
            field: "Buyer Proposed Reg Unit Sales"
          },
          {
            headerName: "BUYER PROP RECALC",
            valueFormatter: toUnit,
            width: 121,
            cellStyle: tar,
            field: "Buyer Proposed - Recalculated Reg Unit Sales"
          },
          {
            headerName: "VAR %",
            width: 83,
            valueFormatter: percentageFormatter,
            sortable: true,
            unSortIcon: true,
            cellStyle: tar,
            field: "Var% Reg Unit Sales"
          }
        ]
      },
      {
        headerName: "REG RETAIL SALES ($)",
        children: [
          {
            headerName: "ALGO OPT",
            valueFormatter: toDollar,
            width: 80,
            cellStyle: tar,
            field: "Algorithm Optimized with VA Reg Retail Sales($)"
          },
          {
            headerName: "BUYER PROP",
            valueFormatter: toDollar,
            width: 87,
            cellStyle: tar,
            field: "Buyer Proposed Reg Retail Sales($)"
          },
          {
            headerName: "BUYER PROP RECALC",
            valueFormatter: toDollar,
            width: 121,
            cellStyle: tar,
            field: "Buyer Proposed - Recalculated Reg Retail Sales($)"
          },
          {
            headerName: "VAR %",
            width: 83,
            valueFormatter: percentageFormatter,
            sortable: true,
            cellStyle: tar,
            unSortIcon: true,
            field: "Var% Reg Retail Sales($)"
          }
        ]
      },
      {
        headerName: "REG TOTAL MARGIN ($)",
        children: [
          {
            headerName: "ALGO OPT",
            valueFormatter: toDollar,
            width: 80,
            cellStyle: tar,
            field: "Algorithm Optimized with VA Reg Total Margin ($)"
          },
          {
            headerName: "BUYER PROP",
            valueFormatter: toDollar,
            cellStyle: tar,
            width: 87,
            field: "Buyer Proposed Reg Total Margin ($)"
          },
          {
            headerName: "BUYER PROP RECALC",
            valueFormatter: toDollar,
            cellStyle: tar,
            width: 121,
            field: "Buyer Proposed - Recalculated Reg Total Margin ($)"
          },
          {
            headerName: "VAR %",
            cellStyle: tar,
            width: 83,
            valueFormatter: percentageFormatter,
            sortable: true,
            unSortIcon: true,
            field: "Var% Reg Total Margin ($)"
          }
        ]
      },
      {
        headerName: "LIFETIME MARGIN ($)",
        children: [
          {
            headerName: "ALGO OPT",
            valueFormatter: toDollar,
            width: 80,
            cellStyle: tar,
            field: "Algorithm Optimized with VA Lifetime Margin ($)"
          },
          {
            headerName: "BUYER PROP",
            valueFormatter: toDollar,
            cellStyle: tar,
            width: 87,
            field: "Buyer Proposed Lifetime Margin ($)"
          },
          {
            headerName: "BUYER PROP RECALC",
            valueFormatter: toDollar,
            cellStyle: tar,
            width: 121,
            field: "Buyer Proposed - Recalculated Lifetime Margin ($)"
          },
          {
            headerName: "VAR %",
            valueFormatter: percentageFormatter,
            sortable: true,
            width: 10,
            cellStyle: tar,
            unSortIcon: true,
            field: "Var% Lifetime Margin ($)"
          }
        ]
      }
    ];
    if (selectedPrimaryAttributes && selectedPrimaryAttributes.length) {
      const primaryAttrColumns = [];
      selectedPrimaryAttributes.forEach(element => {
        primaryAttrColumns.push({
          headerName: element,
          width: 137,
          field: element
        });
      });
      columns.splice(3, 0, {
        headerName: "Primary Attributes",
        children: primaryAttrColumns
      });
    }

    return columns;
  }
};

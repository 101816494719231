import {
  toUnit,
  toPercentage
  // roundToTwoDecimal
} from "../../../helpers/formatters";
import { tar } from "./inputCss";

export default {
  defaultColDef: {
    resizable: true,
    animateRows: true,

    menuTabs: []
  },
  animateRows: true,
  // sortable: true,
  cellClass: "no-border",
  columnDefs: function(lyFlag) {
    return [
      {
        headerName: "",
        width: 178,
        cellStyle: function(params) {
          if (
            params.data.Metric === "Number of styles" ||
            params.data.Metric === "Total Buy Units" ||
            params.data.Metric === "Total Buy Cost ($)" ||
            params.data.Metric === "Reg Unit Sales" ||
            params.data.Metric === "Reg Revenue ($)" ||
            params.data.Metric === "Reg Selling Cost ($)" ||
            params.data.Metric === "Reg Total Margin ($)" ||
            params.data.Metric === "1st MD Unit Sales" ||
            params.data.Metric === "1st MD Cost ($)" ||
            params.data.Metric === "1st MD Margin ($)" ||
            params.data.Metric === "1st MD Revenue ($)" ||
            params.data.Metric === "MOS Cost ($)"
          )
            return {
              "background-color": "#c7c7c7 !important"
            };
          else if (
            params.data.Metric === "Remaining Units" ||
            params.data.Metric === "Remaining Unit Cost ($)" ||
            params.data.Metric === "2nd MD Unit Sales" ||
            params.data.Metric === "2nd MD Cost ($)" ||
            params.data.Metric === "2nd MD Revenue ($)" ||
            params.data.Metric === "2nd MD Margin ($)" ||
            params.data.Metric === "Lifetime Retail Sales ($)" ||
            params.data.Metric === "Lifetime Unit Sales" ||
            params.data.Metric === "Lifetime Margin ($)"
          )
            return {
              "background-color": "#dcdcdc !important"
            };
        },
        field: "Metric"
      },
      {
        headerName: "ALGO OPT",
        width: 118,
        sortable: true,
        unSortIcon: true,
        valueFormatter: toUnit,
        field: "Algorithm Optimized with VA",
        cellStyle: function(params) {
          if (
            params.data.Metric === "Number of styles" ||
            params.data.Metric === "Total Buy Units" ||
            params.data.Metric === "Total Buy Cost ($)" ||
            params.data.Metric === "Reg Unit Sales" ||
            params.data.Metric === "Reg Revenue ($)" ||
            params.data.Metric === "Reg Selling Cost ($)" ||
            params.data.Metric === "Reg Total Margin ($)" ||
            params.data.Metric === "1st MD Unit Sales" ||
            params.data.Metric === "1st MD Cost ($)" ||
            params.data.Metric === "1st MD Margin ($)" ||
            params.data.Metric === "1st MD Revenue ($)" ||
            params.data.Metric === "MOS Cost ($)"
          )
            return {
              ...{ "background-color": "#c7c7c7 !important" },
              ...tar
            };
          else if (
            params.data.Metric === "Remaining Units" ||
            params.data.Metric === "Remaining Unit Cost ($)" ||
            params.data.Metric === "2nd MD Unit Sales" ||
            params.data.Metric === "2nd MD Cost ($)" ||
            params.data.Metric === "2nd MD Revenue ($)" ||
            params.data.Metric === "2nd MD Margin ($)" ||
            params.data.Metric === "Lifetime Retail Sales ($)" ||
            params.data.Metric === "Lifetime Unit Sales" ||
            params.data.Metric === "Lifetime Margin ($)"
          )
            return { ...{ "background-color": "#dcdcdc !important" }, ...tar };
        }
      },
      {
        headerName: "BUYER PROP",
        width: 117,
        valueFormatter: toUnit,
        sortable: true,
        unSortIcon: true,
        field: "Buyer Proposed",
        cellStyle: function(params) {
          if (
            params.data.Metric === "Number of styles" ||
            params.data.Metric === "Total Buy Units" ||
            params.data.Metric === "Total Buy Cost ($)" ||
            params.data.Metric === "Reg Unit Sales" ||
            params.data.Metric === "Reg Revenue ($)" ||
            params.data.Metric === "Reg Selling Cost ($)" ||
            params.data.Metric === "Reg Total Margin ($)" ||
            params.data.Metric === "1st MD Unit Sales" ||
            params.data.Metric === "1st MD Cost ($)" ||
            params.data.Metric === "1st MD Margin ($)" ||
            params.data.Metric === "1st MD Revenue ($)" ||
            params.data.Metric === "MOS Cost ($)"
          )
            return {
              ...{ "background-color": "#c7c7c7 !important" },
              ...tar
            };
          else if (
            params.data.Metric === "Remaining Units" ||
            params.data.Metric === "Remaining Unit Cost ($)" ||
            params.data.Metric === "2nd MD Unit Sales" ||
            params.data.Metric === "2nd MD Cost ($)" ||
            params.data.Metric === "2nd MD Revenue ($)" ||
            params.data.Metric === "2nd MD Margin ($)" ||
            params.data.Metric === "Lifetime Retail Sales ($)" ||
            params.data.Metric === "Lifetime Unit Sales" ||
            params.data.Metric === "Lifetime Margin ($)"
          )
            return {
              ...{ "background-color": "#dcdcdc !important" },
              ...tar
            };
        }
      },
      {
        headerName: "% VAR",
        sortable: true,
        unSortIcon: true,
        width: 118,
        // valueFormatter: roundToTwoDecimal,
        valueFormatter: toPercentage,

        valueGetter: function(params) {
          // return params.data(['Algorithm Optimized'] - ['Buyer Proposed']) - params.data["Buyer Proposed"];
          return (
            ((params.data["Algorithm Optimized"] -
              params.data["Buyer Proposed"]) /
              params.data["Buyer Proposed"]) *
            100
          );
        },
        cellStyle: function(params) {
          if (
            params.data.Metric === "Number of styles" ||
            params.data.Metric === "Total Buy Units" ||
            params.data.Metric === "Total Buy Cost ($)" ||
            params.data.Metric === "Reg Unit Sales" ||
            params.data.Metric === "Reg Revenue ($)" ||
            params.data.Metric === "Reg Selling Cost ($)" ||
            params.data.Metric === "Reg Total Margin ($)" ||
            params.data.Metric === "1st MD Unit Sales" ||
            params.data.Metric === "1st MD Cost ($)" ||
            params.data.Metric === "1st MD Margin ($)" ||
            params.data.Metric === "1st MD Revenue ($)" ||
            params.data.Metric === "MOS Cost ($)"
          )
            return {
              ...{ "background-color": "#c7c7c7 !important" },
              ...tar
            };
          else if (
            params.data.Metric === "Remaining Units" ||
            params.data.Metric === "Remaining Unit Cost ($)" ||
            params.data.Metric === "2nd MD Unit Sales" ||
            params.data.Metric === "2nd MD Cost ($)" ||
            params.data.Metric === "2nd MD Revenue ($)" ||
            params.data.Metric === "2nd MD Margin ($)" ||
            params.data.Metric === "Lifetime Retail Sales ($)" ||
            params.data.Metric === "Lifetime Unit Sales" ||
            params.data.Metric === "Lifetime Margin ($)"
          )
            return {
              ...{ "background-color": "#dcdcdc !important" },
              ...tar
            };
        }
      },
      {
        headerName: "BUYER PROP RECALC",
        sortable: true,
        unSortIcon: true,
        width: 154,
        valueFormatter: toUnit,
        cellStyle: function(params) {
          if (
            params.data.Metric === "Number of styles" ||
            params.data.Metric === "Total Buy Units" ||
            params.data.Metric === "Total Buy Cost ($)" ||
            params.data.Metric === "Reg Unit Sales" ||
            params.data.Metric === "Reg Revenue ($)" ||
            params.data.Metric === "Reg Selling Cost ($)" ||
            params.data.Metric === "Reg Total Margin ($)" ||
            params.data.Metric === "1st MD Unit Sales" ||
            params.data.Metric === "1st MD Cost ($)" ||
            params.data.Metric === "1st MD Margin ($)" ||
            params.data.Metric === "1st MD Revenue ($)" ||
            params.data.Metric === "MOS Cost ($)"
          )
            return {
              ...{ "background-color": "#c7c7c7 !important" },
              ...tar
            };
          else if (
            params.data.Metric === "Remaining Units" ||
            params.data.Metric === "Remaining Unit Cost ($)" ||
            params.data.Metric === "2nd MD Unit Sales" ||
            params.data.Metric === "2nd MD Cost ($)" ||
            params.data.Metric === "2nd MD Revenue ($)" ||
            params.data.Metric === "2nd MD Margin ($)" ||
            params.data.Metric === "Lifetime Retail Sales ($)" ||
            params.data.Metric === "Lifetime Unit Sales" ||
            params.data.Metric === "Lifetime Margin ($)"
          )
            return {
              ...{ "background-color": "#dcdcdc !important" },
              ...tar
            };
        },
        field: "Buyer Proposed - Recalculated"
      },
      {
        headerName: lyFlag ? "LY BUY" : "LLY BUY",
        sortable: true,
        unSortIcon: true,
        width: 82,
        valueFormatter: toUnit,
        cellStyle: function(params) {
          if (
            params.data.Metric === "Number of styles" ||
            params.data.Metric === "Total Buy Units" ||
            params.data.Metric === "Total Buy Cost ($)" ||
            params.data.Metric === "Reg Unit Sales" ||
            params.data.Metric === "Reg Revenue ($)" ||
            params.data.Metric === "Reg Selling Cost ($)" ||
            params.data.Metric === "Reg Total Margin ($)" ||
            params.data.Metric === "1st MD Unit Sales" ||
            params.data.Metric === "1st MD Cost ($)" ||
            params.data.Metric === "1st MD Margin ($)" ||
            params.data.Metric === "1st MD Revenue ($)" ||
            params.data.Metric === "MOS Cost ($)"
          )
            return {
              ...{ "background-color": "#c7c7c7 !important" },
              ...tar
            };
          else if (
            params.data.Metric === "Remaining Units" ||
            params.data.Metric === "Remaining Unit Cost ($)" ||
            params.data.Metric === "2nd MD Unit Sales" ||
            params.data.Metric === "2nd MD Cost ($)" ||
            params.data.Metric === "2nd MD Revenue ($)" ||
            params.data.Metric === "2nd MD Margin ($)" ||
            params.data.Metric === "Lifetime Retail Sales ($)" ||
            params.data.Metric === "Lifetime Unit Sales" ||
            params.data.Metric === "Lifetime Margin ($)"
          )
            return {
              ...{ "background-color": "#dcdcdc !important" },
              ...tar
            };
        },
        field: "LY Buy"
      }
    ];
  }
};

import { sumProduct, roundTo } from "../helpers";

export default function({
  maxVariancePerMonth,
  maxVariancePerCluster,
  storeMin,
  ecomMin,
  increments,
  repectiveClusterDoorCount,
  respectiveClusterPlannedDepth,
  monthlyPene,
  totalPlannedUnitsForMonth,
  depthForMonthSoFar,
  storeCountSoFar,
  depthForClusterSoFar,
  cluster,
  isSeasonLevel
}) {
  const minCutOffPercent = 0.75;
  const minStoreCutOff = storeMin * minCutOffPercent; // $AH$40
  const minEcomCutOff = ecomMin * minCutOffPercent; // $AH$40

  const totalUnitsPerMonthSoFar = sumProduct(
    depthForMonthSoFar,
    storeCountSoFar
  );
  const totalUnitsPerClusterSoFar = depthForClusterSoFar.reduce(
    (acc, curr) => acc + curr,
    0
  );

  const min = +cluster ? storeMin : ecomMin;
  const minCutOff = +cluster ? minStoreCutOff : minEcomCutOff;

  const respectiveMonthlyPlannedDepth =
    respectiveClusterPlannedDepth * monthlyPene;

  const minAllowedTotalUnitsPerMonth =
    totalPlannedUnitsForMonth * (1 - maxVariancePerMonth); // not required for month
  const maxAllowedTotalUnitsPerMonth =
    totalPlannedUnitsForMonth * (1 + maxVariancePerMonth); // not required for monthy
  const minAllowedTotalUnitsPerCluster =
    respectiveClusterPlannedDepth * (1 - maxVariancePerCluster);
  const maxAllowedTotalUnitsPerCluster =
    respectiveClusterPlannedDepth * (1 + maxVariancePerCluster);

  const isTotalUnitsPerMonthSoFarLessThanMinAllowedTotalUnitsPerMonth =
    totalUnitsPerMonthSoFar < minAllowedTotalUnitsPerMonth;
  const isTotalUnitsPerClusterSoFarLessThanMinAllowedTotalUnitsPerCluster =
    totalUnitsPerClusterSoFar < minAllowedTotalUnitsPerCluster;
  // const isRespectiveMonthlyPlannedDepthLessThanMinCutOff =
  //   respectiveMonthlyPlannedDepth < minCutOff;
  const isRespectiveMonthlyPlannedDepthGreaterThanMinCutOff =
    respectiveMonthlyPlannedDepth > minCutOff;
  const isRespectiveMonthlyPlannedDepthLessThanMin =
    respectiveMonthlyPlannedDepth < min;
  const isRespectiveMonthlyPlannedDepthGreaterThanMin =
    respectiveMonthlyPlannedDepth > min;

  const areTotalUnitsSoFarLessThanMinAllowedUnits =
    isTotalUnitsPerMonthSoFarLessThanMinAllowedTotalUnitsPerMonth &&
    isTotalUnitsPerClusterSoFarLessThanMinAllowedTotalUnitsPerCluster;

  const isRespectiveMonthlyPlannedDepthInBetweenMinCuttOffAndMin =
    isRespectiveMonthlyPlannedDepthGreaterThanMinCutOff &&
    isRespectiveMonthlyPlannedDepthLessThanMin;

  const a = () => {
    return isTotalUnitsPerMonthSoFarLessThanMinAllowedTotalUnitsPerMonth
      ? min
      : 0;
  };

  const b = () => {
    return isRespectiveMonthlyPlannedDepthInBetweenMinCuttOffAndMin ? min : a();
  };

  const c = () => {
    return isRespectiveMonthlyPlannedDepthGreaterThanMin
      ? roundTo(respectiveMonthlyPlannedDepth, increments)
      : b();
  };

  const d = () => {
    return isTotalUnitsPerMonthSoFarLessThanMinAllowedTotalUnitsPerMonth
      ? c()
      : 0;
  };

  const e = () => {
    return areTotalUnitsSoFarLessThanMinAllowedUnits ? c() : 0;
  };

  const f = () => {
    const totalUnitsPerClusterUntilNow = d() + totalUnitsPerClusterSoFar;
    return totalUnitsPerClusterUntilNow > maxAllowedTotalUnitsPerCluster
      ? 0
      : d();
  };

  const h = () => {
    let currentDepth = e() - increments;
    let totalUnitsPerMonthUntilNow = totalUnitsPerMonthSoFar;
    while (currentDepth >= minAllowedTotalUnitsPerCluster) {
      let obtainedUnits = currentDepth * repectiveClusterDoorCount;
      totalUnitsPerMonthUntilNow = obtainedUnits + totalUnitsPerMonthSoFar;
      currentDepth = currentDepth - increments;
      if (totalUnitsPerMonthUntilNow < maxAllowedTotalUnitsPerMonth) {
        break;
      }
    }
    let currentDepthFinal = currentDepth + increments;
    if (
      totalUnitsPerMonthUntilNow < maxAllowedTotalUnitsPerMonth &&
      currentDepthFinal >= min &&
      currentDepthFinal >= minAllowedTotalUnitsPerCluster &&
      currentDepthFinal <= maxAllowedTotalUnitsPerCluster
    ) {
      return currentDepthFinal;
    } else {
      return 0;
    }
  };

  const i = () => {
    let valLyingBtwMinMaxOfClusterAndGreaterThanMin =
      f() >= minAllowedTotalUnitsPerCluster &&
      f() <= maxAllowedTotalUnitsPerCluster &&
      f() >= min;
    if (valLyingBtwMinMaxOfClusterAndGreaterThanMin) {
      return f();
    } else {
      return 0;
    }
  };

  const g = () => {
    const obtainedUnits = e() * repectiveClusterDoorCount;
    const totalUnitsPerMonthUntilNow = obtainedUnits + totalUnitsPerMonthSoFar;
    return totalUnitsPerMonthUntilNow > maxAllowedTotalUnitsPerMonth
      ? isSeasonLevel
        ? 0
        : h()
      : isSeasonLevel
      ? f()
      : i();
  };

  return +cluster === 0 && respectiveMonthlyPlannedDepth < minEcomCutOff
    ? 0
    : g();
}

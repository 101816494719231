import React, { Component } from 'react';

import CreatableSelect from 'react-select/creatable';

export default class CreatableSelectAll extends Component {
  render() {
    return (
      <CreatableSelect
        {...this.props}
      />
    );
  }
}
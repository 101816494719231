import { toDollar, toPercentage } from "../../../helpers/formatters";
export default function(rcptMonths, isSeasonLevel) {
  let rcptMonthsColDefs = [];
  if (rcptMonths && rcptMonths.length) {
    rcptMonthsColDefs = rcptMonths.map(rcptMonth => {
      return {
        headerName: rcptMonth,
        field: rcptMonth,
        width: 100,
        sortable: true,
        unSortIcon: true,
        cellStyle: { "text-align": "right" },
        valueFormatter: toPercentage
      };
    });
  }
  return {
    defaultColDef: {
      animateRows: true,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      menuTabs: []
    },
    animateRows: true,
    suppressCellSelection: true,
    cellClass: "no-border",
    filter: true,
    columnDefs: [
      {
        headerName: "ORIN",
        field: "styleid",
        width: 120,
        sortable: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        unSortIcon: true,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Class",
        width: 130,
        field: "class",
        sortable: true,
        unSortIcon: true,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Vendor",
        width: 150,
        field: "vendor",
        sortable: true,
        unSortIcon: true,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "STYLE VPN - NAME",
        field: "stylename",
        sortable: true,
        unSortIcon: true,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "SELLING PERIOD SALES",
        field: "styleSales",
        sortable: true,
        width: 120,
        unSortIcon: true,
        cellStyle: { "text-align": "right" },
        valueFormatter: toDollar
      },
      {
        headerName: "Receipt Penetration",
        children: [...rcptMonthsColDefs]
      }
    ]
  };
}

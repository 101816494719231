
export default {
  defaultColDef: {
    animateRows: true,
    sortable: true,
    unSortIcon: true,
    resizable: true,
    menuTabs: []
  },
  animateRows: true,
  suppressCellSelection: true,
  cellClass: "no-border",
  filter: true,
  columnDefs: isFcApproved => {
    return [
      {
        headerName: "VENDOR ID",
        field: "vendor_id",
        sortable: true,
        unSortIcon: true
      },
      {
        headerName: "VENDOR DESCRIPTION",
        field: "vendor_desc",
        sortable: true,
        unSortIcon: true
      },
      {
        headerName: "ACTIONS",
        field: "action",
        sortable: true,
        editable: !isFcApproved,
        unSortIcon: true,
        cellStyle: !isFcApproved && { backgroundColor: "#C1D5FF" },
        cellEditorSelector: function(params) {
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: ["FC Approved", "Store Only"]
            }
          };
        }
      }
    ];
  }
};

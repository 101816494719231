import numeral from "numeral";

function clusterSummaryFormatter(params) {
  if (params) {
    if (params.data.keys === "CLUSTER RCPT PEN") {
      return numeral(params.value / 100).format("0.00%");
    } else if (
      params.data.keys === "AVG DR RCPT $" ||
      params.data.keys === "TY TTL RCPT$/CLUSTER" ||
      params.data.keys === "LY TTL RCPT$/CLUSTER"
    ) {
      return numeral(params.value).format("($0,0)");
    } else {
      return numeral(params.value).format("(0,0)");
    }
  }
}

export default function clusterSummaryColumnDefs(clusterData, clusters) {
  let tempObj = [];
  tempObj.push({
    headerName: "CLUSTER SUMMARY",
    menuTabs: [],
    pinned: "left",
    field: "keys",
    filter: "agTextColumnFilter",
    unSortIcon: true,
    sortable: true
  });
  if (clusterData.length && clusters.includes("0")) {
    clusters.forEach(cluster => {
      if (cluster !== "0") {
        tempObj.push({
          sortable: true,
          menuTabs: [],
          unSortIcon: true,
          width: 85,
          headerName: `CL ${cluster}`,
          valueFormatter: params => clusterSummaryFormatter(params),
          field: `${cluster}`
        });
      }
    });
    tempObj.push({
      headerName: "ECOM",
      width: 85,
      menuTabs: [],
      valueFormatter: params => clusterSummaryFormatter(params),
      field: "0"
    });
  } else {
    clusters.forEach(cluster => {
      tempObj.push({
        sortable: true,
        menuTabs: [],
        width: 85,
        unSortIcon: true,
        headerName: `CL ${cluster}`,
        valueFormatter: params => clusterSummaryFormatter(params),
        field: `${cluster}`
      });
    });
  }
  tempObj.push({
    headerName: "TOTAL",
    menuTabs: [],
    width: 150,
    field: "Total",
    valueFormatter: params => clusterSummaryFormatter(params),
    unSortIcon: true,
    sortable: true
  });
  return tempObj;
}

import { downloadTable } from "../../../utils/exportxlsx";

export default data => {
  const sheets = [
    {
      sheetHeader: `Guidelines to fill the spreadsheet:\n- The combination flag is an optional field. But all other cells must be filled\n- Reg disc % < 1st disc % < 2nd disc %\n- All the %s should have to be in % format\n- Production Min < Moq < Max Quantity`,
      sheet_data: [
        {
          "Column Name": "Vendor/Class ID",
          Description: "Vendor or Class name",
          "Pre Populated From APT - (Yes/No)": "Yes",
          "Sample Value": "HI CUT"
        },
        {
          "Column Name": "Custom Category",
          Description:
            "Sub group created in APT or existing SSD will be shown here",
          "Pre Populated From APT - (Yes/No)": "Yes",
          "Sample Value": "D233 APT FRENCH CUT 3PK"
        },
        {
          "Column Name": "Style Name Color",
          Description: "Pre-populated with style numbers generated randomly",
          "Pre Populated From APT - (Yes/No)": "Yes",
          "Sample Value": "HI CUT D233 APT FRENCH CUT 3PK Style 1"
        },
        {
          "Column Name": "Style ID",
          Description: "Unique ID of the Style",
          "Pre Populated From APT - (Yes/No)": "No",
          "Sample Value": "Style 1"
        },
        {
          "Column Name": "APS",
          Description: "TY APS calculated as function of LY APS",
          "Pre Populated From APT - (Yes/No)": "Yes",
          "Sample Value": "0.5956"
        },
        {
          "Column Name": "Weeks",
          Description: "No of weeks in reg lifecycle",
          "Pre Populated From APT - (Yes/No)": "Yes",
          "Sample Value": "12"
        },
        {
          "Column Name": "No Of Sizes",
          Description: "Total no of sizes buying ",
          "Pre Populated From APT - (Yes/No)": "No",
          "Sample Value": "3"
        },
        {
          "Column Name": "Min Per Size",
          Description: "Min buy quantity for a case pack",
          "Pre Populated From APT - (Yes/No)": "No",
          "Sample Value": "6"
        },
        {
          "Column Name": "MSRP/ Ticket",
          Description: "POS price",
          "Pre Populated From APT - (Yes/No)": "Yes",
          "Sample Value": "22"
        },
        {
          "Column Name": "IMU",
          Description: "Initial mark-up planned",
          "Pre Populated From APT - (Yes/No)": "No",
          "Sample Value": "70"
        },
        {
          "Column Name": "Reg Discount %",
          Description:
            "This is all-in disc %. It includes coupons, belk buy and other discounts. Please enter an estimate based on the marketing",
          "Pre Populated From APT - (Yes/No)": "No",
          "Sample Value": "45"
        },
        {
          "Column Name": "Production Min",
          Description: "Min production quantity given by the vendor",
          "Pre Populated From APT - (Yes/No)": "No",
          "Sample Value": "100"
        },
        {
          "Column Name": "Max Quantity",
          Description: "Maximum production quantity given by the vendor",
          "Pre Populated From APT - (Yes/No)": "No",
          "Sample Value": "10000"
        },
        {
          "Column Name": "MOQ",
          Description:
            "Min order quantity set by the vendor to not incur any surcharge",
          "Pre Populated From APT - (Yes/No)": "No",
          "Sample Value": "1000"
        },
        {
          "Column Name": "Surcharge %",
          Description:
            "Surcharge % on cost that will be applied if MOQ is not met",
          "Pre Populated From APT - (Yes/No)": "No",
          "Sample Value": "20"
        },
        {
          "Column Name": "Max Store Count",
          Description:
            "Pre-populated. Maximum store count that it will go to based on the APT",
          "Pre Populated From APT - (Yes/No)": "Yes",
          "Sample Value": "180"
        },
        {
          "Column Name": "Reg ST %",
          Description: "ST % from launch",
          "Pre Populated From APT - (Yes/No)": "Yes",
          "Sample Value": "69"
        },
        {
          "Column Name": "1st MD ST %",
          Description: "ST % from first markdown",
          "Pre Populated From APT - (Yes/No)": "No",
          "Sample Value": "70"
        },
        {
          "Column Name": "1st MD Discount %",
          Description: "Discount % on MSRP",
          "Pre Populated From APT - (Yes/No)": "No",
          "Sample Value": "50"
        },
        {
          "Column Name": "2nd MD ST %",
          Description: "ST% from second markdown",
          "Pre Populated From APT - (Yes/No)": "No",
          "Sample Value": "75"
        },
        {
          "Column Name": "2nd MD Discount %",
          Description: "Discount % on MSRP",
          "Pre Populated From APT - (Yes/No)": "No",
          "Sample Value": "55"
        },
        {
          "Column Name": "Combination Flag",
          Description:
            "To be used when two style colors have a combined a prod min, MOQ. The algorithm will combine the buy for these two style colors together while running the optimization",
          "Pre Populated From APT - (Yes/No)": "No",
          "Sample Value": "1"
        }
      ],
      sheet_name: "Introduction",
      column_width: []
    },
    {
      sheetHeader: "",
      sheet_data:
        data[0] instanceof Array && data[0].length > 0
          ? data[0]
          : [
              {
                "Vendor/Class ID": "",
                "Custom Category": "",
                "Style Name Color": "",
                "Style ID": "",
                APS: "",
                Weeks: "",
                "No Of Sizes": "",
                "Min Per Size": "",
                "MSRP/ Ticket": "",
                IMU: "",
                "Reg Discount %": "",
                "Production Min": "",
                "Max Quantity": "",
                MOQ: "",
                "Surcharge %": "",
                "Max Store Count": "",
                "Reg ST %": "",
                "1st MD ST %": "",
                "1st MD Discount %": "",
                "2nd MD ST %": "",
                "2nd MD Discount %": "",
                "Combination Flag": ""
              }
            ],
      sheet_name: "Style Color level",
      column_width: []
    },
    {
      sheetHeader: "",
      sheet_data:
        data[1] instanceof Array && data[1].length > 0
          ? data[1]
          : [
              {
                Style_Id: "",
                Production_min: "",
                moq: "",
                "surcharge %": ""
              }
            ],
      sheet_name: "Style level",
      column_width: []
    }
  ];
  downloadTable(sheets, "BuyInput");
};

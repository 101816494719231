import React, { Component } from "react";
import clusteringActions from "../../actions/ClusteringActions";
import store from "../../store";

const attributeClusters = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
export default class AttributeClusterSelectionCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.numberOfClusters = +store.getState().clusteringReducer
      .optimalAttributeClusters;
    this.state = {
      selectedClusterNum: props.value
    };
  }
  onChangeCluster = val => {
    let selectedCluster = val.currentTarget.value;
    let selectedClusterMapping = store.getState().clusteringReducer
      .attributeStoreMapping[this.numberOfClusters];
    this.setState(prevState => {
      selectedClusterMapping[selectedCluster].push(
        selectedClusterMapping[prevState.selectedClusterNum].splice(
          selectedClusterMapping[prevState.selectedClusterNum].indexOf(
            this.props.data.loc_id
          ),
          1
        )[0]
      );
      this.props.data.attribute_clusters = selectedCluster;
      this.props.api.redrawRows();
      store.dispatch(
        clusteringActions.setClusterResultStoreLevel([
          ...store.getState().clusteringReducer.clusterResultsStoreLevel
        ])
      );
      return { selectedClusterNum: selectedCluster };
    });
  };

  render() {
    const options = [];
    for (let i = 0; i <= this.numberOfClusters - 1; i++) {
      options.push(
        <option key={attributeClusters[i]}>{attributeClusters[i]}</option>
      );
    }
    return (
      <span>
        {+this.props.data.loc_id === 888 ? (
          "Ecom"
        ) : !(this.props.value === "") ? (
          <select
            value={this.state.selectedClusterNum}
            onChange={val => this.onChangeCluster(val)}
          >
            {options}
          </select>
        ) : (
          "-"
        )}
      </span>
    );
  }
}

import api from "../axios";
import { toast } from "react-toastify";

const projectActions = {
  //
  deleteProjectOrClusterById: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/deleteProjectsOrClustersByIds",
        method: "POST",
        data: payload
      })
        .then(resp => {
          // toast.success(resp.message)
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "DASHBOARD_LIST_AFTER_DELETE",
            payload: resp.data
          });
          return resp;
        })
        .catch(err => {
          toast.error(err.message, { autoClose: false });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  getClusteringDetailsById: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: `/getClusteringDetailsById/${payload.clusterMasterId}`,
        method: "GET"
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });

          if (resp.success) {
            dispatch({
              type: "SET_CLUSTER_MASTER_ID",
              payload: resp.data.clusterMasterId
            });
            dispatch({
              type: "SET_CLUSTER_DETAILS",
              payload: resp.data.clusteringDetails.clusteringInputFields
            });

            //Intelligent Clustering Setting Data
            const clusteringDetails = resp.data && resp.data.clusteringDetails;
            if (clusteringDetails) {
              // clusteringDetails.clusteringInputFields.isLostSales = !clusteringDetails
              //   .clusteringInputFields.isLostSales;
              //Converting Include Ecom Flag to Exclude Ecom Flag as Backend is handling include ecom flag and frontend is of exclude ecom
              clusteringDetails.clusteringInputFields.ecommFlag = !clusteringDetails
                .clusteringInputFields.ecommFlag;
              dispatch({
                type: "SET_CLUSTERING_INPUT_FIELDS",
                payload: clusteringDetails.clusteringInputFields
              });
              dispatch({
                type: "SET_ELBOW_CURVE",
                payload: clusteringDetails.elbowCurve
              });
              dispatch({
                type: "SET_OPTIMAL_CLUSTERS",
                payload: clusteringDetails.optimalClusters
              });
              dispatch({
                type: "SET_OPTIMAL_ATTRIBUTE_CLUSTERS",
                payload: clusteringDetails.optimalAttributeClusters
              });
              dispatch({
                type: "SET_USER_SELECTED_CLUSTERS",
                payload:
                  clusteringDetails.userSelectedClusters ||
                  clusteringDetails.optimalClusters
              });
              dispatch({
                type: "SET_CLUSTER_MAPPING",
                payload: clusteringDetails.clusterMapping
              });
              dispatch({
                type: "SET_CLUSTER_RESULTS_STORE_LEVEL",
                payload: clusteringDetails.clusterResultsStoreLevel
              });
              dispatch({
                type: "SET_ATTRIBUTES_GRADE_CHART",
                payload:
                  clusteringDetails.attributesGradeChart &&
                  clusteringDetails.attributesGradeChart.graph_data
              });
              dispatch({
                type: "SET_ATTRIBUTES_STORE_MAPPING",
                payload: clusteringDetails.attributesStoreMapping
              });
              dispatch({
                type: "SDF_SALES_SUMMARY",
                payload: clusteringDetails.sdfTableData || []
              });
              dispatch({
                type: "SET_LY_FLAG",
                payload: clusteringDetails.clusteringInputFields.lyFlag
              });
              dispatch({
                type: "SET_PROJECT_STAGE",
                payload: resp.data.clusterStage
              });
            }
            return resp;
          }
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  getProjectById: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: `/getProjectById/${payload.projectId}`,
        method: "GET"
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });

          if (resp.success) {
            try {
              dispatch({
                type: "SET_PROJECT_ID",
                payload: resp.data.projectId
              });
              dispatch({
                type: "SET_PROJECT_DETAILS",
                payload: resp.data.projectDetails
              });
              dispatch({
                type: "SET_CLUSTER_MASTER_ID",
                payload: resp.data.clusterMasterId
              });
              dispatch({
                type: "SET_LY_FLAG",
                payload: resp.data.projectDetails.lyflag
              });
              dispatch({
                type: "SET_IS_FC_APPROVED",
                payload: resp.data.projectDetails.is_fc_approved
              });
              dispatch({
                type: "SET_AUC_THRESHOLD",
                payload: resp.data.projectDetails.auc_threshold
              });

              //Intelligent Clustering Setting Data
              const clusteringDetails =
                resp.data && resp.data.clusteringDetails;
              if (clusteringDetails) {
                //Converting Include Ecom Flag to Exclude Ecom Flag as Backend is handling include ecom flag and frontend is of exclude ecom
                clusteringDetails.clusteringInputFields.ecommFlag = !clusteringDetails
                  .clusteringInputFields.ecommFlag;
                // clusteringDetails.clusteringInputFields.isLostSales = !clusteringDetails
                //   .clusteringInputFields.isLostSales;
                dispatch({
                  type: "SET_CLUSTERING_INPUT_FIELDS",
                  payload: clusteringDetails.clusteringInputFields
                });
                dispatch({
                  type: "SET_ELBOW_CURVE",
                  payload: clusteringDetails.elbowCurve
                });
                dispatch({
                  type: "SET_OPTIMAL_CLUSTERS",
                  payload: clusteringDetails.optimalClusters
                });
                dispatch({
                  type: "SET_OPTIMAL_ATTRIBUTE_CLUSTERS",
                  payload: clusteringDetails.optimalAttributeClusters
                });
                dispatch({
                  type: "SET_USER_SELECTED_CLUSTERS",
                  payload:
                    clusteringDetails.userSelectedClusters ||
                    clusteringDetails.optimalClusters
                });
                dispatch({
                  type: "SET_CLUSTER_MAPPING",
                  payload: clusteringDetails.clusterMapping
                });
                dispatch({
                  type: "SET_CLUSTER_RESULTS_STORE_LEVEL",
                  payload: clusteringDetails.clusterResultsStoreLevel
                });
                dispatch({
                  type: "SET_ATTRIBUTES_GRADE_CHART",
                  payload: clusteringDetails.attributesGradeChart
                });
                dispatch({
                  type: "SET_ATTRIBUTES_STORE_MAPPING",
                  payload: clusteringDetails.attributesStoreMapping
                });
                dispatch({
                  type: "SDF_SALES_SUMMARY",
                  payload: clusteringDetails.sdfTableData
                });
              }

              //Assortment Planning non comp mapping Data
              const assortmentNonCompDetails =
                resp.data &&
                resp.data.assortmentDetails &&
                resp.data.assortmentDetails.nonCompMapping || [];
              if (assortmentNonCompDetails && Object.keys(assortmentNonCompDetails).length) {
                let sourceInputList = [];
                for (
                  let i = 0;
                  i < assortmentNonCompDetails.source.length;
                  i++
                ) {
                  sourceInputList[i] = {
                    label: assortmentNonCompDetails.source[i],
                    value: assortmentNonCompDetails.source[i]
                  };
                }
                let targetInputList = [];
                for (
                  let i = 0;
                  i < assortmentNonCompDetails.target.length;
                  i++
                ) {
                  targetInputList[i] = {
                    label: assortmentNonCompDetails.target[i],
                    value: assortmentNonCompDetails.target[i]
                  };
                }

                dispatch({
                  type: "SET_NON_COMP_SOURCE_DATA",
                  payload: sourceInputList
                });
                dispatch({
                  type: "SET_NON_COMP_TARGET_DATA",
                  payload: targetInputList
                });
                dispatch({
                  type: "SET_NON_COMP_LEVEL",
                  payload: assortmentNonCompDetails.level
                });
              }

              //Assortment Planning Setting Data
              const assortmentLineReceiptDetails =
                resp.data &&
                resp.data.assortmentDetails &&
                resp.data.assortmentDetails.lineReceipt;
              if (assortmentLineReceiptDetails) {
                dispatch({
                  type: "SET_LINE_RECEIPT_DATA",
                  isCloneNeeded: false,
                  payload: assortmentLineReceiptDetails.levelData.data
                });
                dispatch({
                  type: "SET_LINE_RECEIPT_BUDGET_PROPORTIONS",
                  isCloneNeeded: false,
                  payload:
                    assortmentLineReceiptDetails.levelData.budgetProportions
                });
                dispatch({
                  type: "SET_LINE_RECEIPT_PLANNED_BUDGET",
                  isCloneNeeded: false,
                  payload: assortmentLineReceiptDetails.levelData.planned_budget
                });
                dispatch({
                  type: "SET_LINE_RECEIPT_MONTHS",
                  payload: assortmentLineReceiptDetails.levelData.months
                });
                dispatch({
                  type: "SET_LINE_RECEIPT_GRAND_TOTAL",
                  payload: assortmentLineReceiptDetails.levelData.grandTotal
                });
                dispatch({
                  type: "SET_LINE_RECEIPT_LEVEL",
                  payload: assortmentLineReceiptDetails.level
                });
                dispatch({
                  type: "SET_LINE_RECEIPT_HIGH_LEVEL",
                  payload: assortmentLineReceiptDetails.highLevel
                });
                dispatch({
                  type: "SET_LINE_RECEIPT_VEN_GROUP_LEVEL",
                  payload: assortmentLineReceiptDetails.venGroup
                });
                dispatch({
                  type: "SET_LINE_RECEIPT_OTB_MONTHS",
                  payload: assortmentLineReceiptDetails.otb_months
                });
                dispatch({
                  type: "SET_USER_EDITABLE_LINE_RECEIPT_DATA",
                  payload: assortmentLineReceiptDetails.levelData
                    .userEditableData
                    ? assortmentLineReceiptDetails.levelData.userEditableData
                    : []
                });
              }

              const assortmentlineArchitectureDetails =
                resp.data &&
                resp.data.assortmentDetails &&
                resp.data.assortmentDetails.lineArchitecture;
              if (assortmentlineArchitectureDetails) {
                dispatch({
                  type: "SET_LINE_ARCHITECTURE_DETAILS",
                  payload: assortmentlineArchitectureDetails
                });
              }
              const assortmentclusterAndParameterDetails =
                resp.data &&
                resp.data.assortmentDetails &&
                resp.data.assortmentDetails.clusterAndParameter;
              const assortmentClusterParameterData = assortmentclusterAndParameterDetails;
              for (const month in assortmentClusterParameterData) {
                if (assortmentClusterParameterData.hasOwnProperty(month)) {
                  // const monthData = assortmentClusterParameterData[month];
                }
              }
              if (assortmentClusterParameterData) {
                dispatch({
                  type: "SET_CLUSTER_AND_PARAMETER_DETAILS",
                  payload: assortmentClusterParameterData
                });
                // dispatch({
                //   type: "SET_SELECTED_AUTO_APX_CLASS",
                //   payload:
                //     assortmentclusterAndParameterDetails
                //       .historicalParameterData[0]["class/vendor"]
                // });
              }

              // Buy Optimization Setting Data
              const buyOptimizationDetails =
                resp.data && resp.data.buyOptimizationDetails;

              if (
                buyOptimizationDetails &&
                buyOptimizationDetails.compareAPSDetails
              ) {
                dispatch({
                  type: "SET_BO_INPUT_DATA_STYLE_COLOR_LEVEL",
                  payload:
                    buyOptimizationDetails.compareAPSDetails.boInputData
                      .StyleColorLevel
                });
                dispatch({
                  type: "SET_BO_INPUT_DATA_STYLE_LEVEL",
                  payload:
                    buyOptimizationDetails.compareAPSDetails.boInputData
                      .StyleLevel
                });
                dispatch({
                  type: "SET_BO_GRAPH_DATA",
                  payload: buyOptimizationDetails.compareAPSDetails.boGraphData
                });
                dispatch({
                  type: "SET_CONSTRAINED",
                  payload: buyOptimizationDetails.compareAPSDetails.constrained
                });
                if (
                  buyOptimizationDetails.compareAPSDetails.boInputData.omniIncr
                ) {
                  dispatch({
                    type: "SET_OMNI_SALES_PLAN_INCREMENT",
                    payload:
                      buyOptimizationDetails.compareAPSDetails.boInputData
                        .omniIncr
                  });
                }
                if (
                  buyOptimizationDetails.compareAPSDetails.boInputData.omniRcpt
                ) {
                  dispatch({
                    type: "SET_OMNI_RCPT_PLAN",
                    payload:
                      buyOptimizationDetails.compareAPSDetails.boInputData
                        .omniRcpt
                  });
                }
              }

              if (
                buyOptimizationDetails &&
                buyOptimizationDetails.optimizeBuyTablesData
              ) {
                dispatch({
                  type: "SET_BRICK_AND_MORTAR_DATA",
                  payload:
                    buyOptimizationDetails.optimizeBuyTablesData
                      .brickAndMortarSummary
                });
                dispatch({
                  type: "SET_PROGRAM_LEVEL_DATA",
                  payload:
                    buyOptimizationDetails.optimizeBuyTablesData
                      .programLevelSummary
                });
                dispatch({
                  type: "SET_ITEM_LEVEL_DATA",
                  payload:
                    buyOptimizationDetails.optimizeBuyTablesData
                      .itemLevelSummary
                });
                dispatch({
                  type: "SET_CHANNEL_SUMMARY_DATA",
                  payload:
                    buyOptimizationDetails.optimizeBuyTablesData.channelSummary
                });
                dispatch({
                  type: "SET_VENDOR_ASSISTANCE_GIVE_BACK",
                  payload:
                    buyOptimizationDetails.optimizeBuyTablesData.giveBackPerc
                });
              }
              if (
                buyOptimizationDetails &&
                buyOptimizationDetails.omniBuyData &&
                buyOptimizationDetails.omniBuyData.length
              ) {
                dispatch({
                  type: "SET_OMNI_BUY_ESTIMATION",
                  payload: buyOptimizationDetails.omniBuyData
                });
                dispatch({
                  type: "SET_DEPT_PENETRATION",
                  payload:
                    buyOptimizationDetails.omniBuyData[0].dept_penetration
                });
                dispatch({
                  type: "SET_SEASON",
                  payload: buyOptimizationDetails.omniBuyData[0].season
                });
              }
              if (
                buyOptimizationDetails &&
                buyOptimizationDetails.vendorsDataForFcBuy
              ) {
                dispatch({
                  type: "SET_VENDOR_FOR_FC_BUY",
                  payload: buyOptimizationDetails.vendorsDataForFcBuy
                });
              }
              dispatch({
                type: "SET_PROJECT_STAGE",
                payload: resp.data.projectStage
              });
            } catch (error) {
              console.log(error);
            }
            return resp;
          }
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  getProjects: () => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: `/getProjects`,
        method: "GET"
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_PROJECT_NAMES_LIST",
            payload: resp.data.map(project => project.name)
          });
          dispatch({
            type: "SET_PROJECT_LIST",
            payload: resp.data
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  getHindsightProjects: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: `/getClusterDashboard`,
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_HINDSIGHT_PROJECT_NAMES_LIST",
            payload: resp.data.map(hindsightProject => hindsightProject.name)
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  changeMappingFromHindSighting: payload => {
    return dispatch => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: false
        }
      });
      return api({
        url: "/changeMappingFromHindSighting",
        method: "POST",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          dispatch({
            type: "SET_CLUSTER_MAPPING",
            payload: resp.data.clusterMapping
          });
          dispatch({
            type: "SET_OPTIMAL_CLUSTERS",
            payload: resp.data.optimalClusters
          });
          dispatch({
            type: "SET_OPTIMAL_ATTRIBUTE_CLUSTERS",
            payload: resp.data.optimalAttributeClusters
          });
          dispatch({
            type: "SET_USER_SELECTED_CLUSTERS",
            payload: resp.data.userSelectedClusters || resp.data.optimalClusters
          });
          dispatch({
            type: "SET_CLUSTER_RESULTS_STORE_LEVEL",
            payload: resp.data.clusterResultsStoreLevel
          });
          return resp;
        })
        .catch(err => {
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
      // .then(resp => {
      //   dispatch({
      //     type: "LOADER_STATE",
      //     payload: {
      //       status: false
      //     }
      //   });
      //   dispatch({
      //     type: "SET_CLUSTER_MAPPING",
      //     payload: resp.data
      //   });
      //   return resp;
      // })
      // .catch(err => {
      //   dispatch({
      //     type: "LOADER_STATE",
      //     payload: {
      //       status: false
      //     }
      //   });
      //   return err;
      // }); // anu's push?
    };
  },
  callSaveProjectStage: payload => {
    return (dispatch, getState) => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/saveProjectStage",
        method: "PUT",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "SET_PROJECT_STAGE",
            payload: resp.data.stage
          });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          console.log(err);
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  updateHasClusteringDone: payload => {
    return dispatch => {
      return api({
        url: "/updateHasClusteringDoneFlag",
        method: "PUT",
        data: payload
      })
        .then(resp => {
          return resp;
        })
        .catch(err => {
          console.log(err);
          return err;
        });
    };
  },
  callSaveClustertStage: payload => {
    return (dispatch, getState) => {
      dispatch({
        type: "LOADER_STATE",
        payload: {
          status: true
        }
      });
      return api({
        url: "/updateClusterStage",
        method: "PUT",
        data: payload
      })
        .then(resp => {
          dispatch({
            type: "SET_PROJECT_STAGE",
            payload: resp.data.stage
          });
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return resp;
        })
        .catch(err => {
          console.log(err);
          dispatch({
            type: "LOADER_STATE",
            payload: {
              status: false
            }
          });
          return err;
        });
    };
  },
  callSaveSkipBuyFlag: payload => {
    return (dispatch, getState) => {
      dispatch({
        type: "SET_SKIP_BUY_FLAG",
        payload
      });
    };
  }
};

export default projectActions;

import {
  decimalFormatter,
  percentageFormatter
} from "../../../helpers/formatters";
// import { errorInput, inputCss } from "./inputCss";
import { inputCss, errorInput } from "../../../styles";

export default {
  defaultColDef: {
    animateRows: true,
    resizable:true,
    sortable: true,
    unSortIcon: true,
    menuTabs: []
  },
  animateRows: true,
  sortable: true,
  filter: true,
  unSortIcon: true,
  suppressCellSelection: true,
  cellClass: "no-border",
  groupRowAggNodes: nodes => {
    var obj = {
      Financial_year: null,
      ecom: null,
      class: null,
      "LY APS": null,
      "LY ST%": null,
      "TY APS": null,
      "TY ST%": null,
      min: null,
      reg_week: null,
      ly_reg_weeks: null,
      subclass: null,
      "rcpt_pen%": null
    };
    let tempRCPT_PEN = null;
    obj["subClasslevelCount"] = nodes.length;
    nodes.forEach(function(node, index) {
      var data = node.group ? node.aggData : node.data;

      /* Fixed upto 2 decimals - by Varsha */
      // if (typeof data["TY APS"] === "number") {
      //   data["TY APS"] = data["TY APS"].toFixed(2);
      // }
      // if (typeof data["TY ST%"] === "number") {
      //   data["TY ST%"] = data["TY ST%"].toFixed(2);
      // }

      tempRCPT_PEN += Number(data["rcpt_pen%"]);
      obj["rcpt_pen%"] += Number(data["rcpt_pen%"]);
      obj["TY ST%"] += Number(data["TY ST%"]) * Number(data["rcpt_pen%"]);
      obj["LY ST%"] += Number(data["LY ST%"]) * Number(data["rcpt_pen%"]);
      obj["TY APS"] += Number(data["TY APS"]) * Number(data["rcpt_pen%"]);
      obj["LY APS"] += Number(data["LY APS"]) * Number(data["rcpt_pen%"]);
      obj["reg_week"] += Number(data["reg_week"]) * Number(data["rcpt_pen%"]);
      obj["ly_reg_weeks"] += Number(data["ly_reg_weeks"]) * Number(data["rcpt_pen%"]);
      obj["min"] += Number(data["min"]) * Number(data["rcpt_pen%"]);
    });

    obj["TY ST%"] = obj["TY ST%"] / tempRCPT_PEN;
    obj["LY ST%"] = Number(obj["LY ST%"]) / tempRCPT_PEN;
    obj["TY APS"] = obj["TY APS"] / tempRCPT_PEN;
    obj["LY APS"] = Number(obj["LY APS"]) / tempRCPT_PEN;
    obj["reg_week"] = obj["reg_week"] / tempRCPT_PEN;
    obj["ly_reg_weeks"] = obj["ly_reg_weeks"] / tempRCPT_PEN;
    obj["min"] = Math.round(obj["min"] / tempRCPT_PEN);
    return obj;
  },
  columnDefs: [
    {
      headerName: "Total",
      sortable: true,
      unSortIcon: true,
      field: "Financial_year",
      rowGroup: true,
      valueFormatter: val => {
        return "Total";
      },
      hide: true
    },
    {
      headerName: "SUB GRP",
      width: 322,
      sortable: true,
      unSortIcon: true,
      field: "subclass",
      pinned: "left"
    },
    {
      headerName: "RCPT PEN%",
      width: 147,
      sortable: true,
      unSortIcon: true,
      field: "rcpt_pen%",
      valueFormatter: percentageFormatter
    },
    {
      headerName: "TY ST%",
      width: 122,
      sortable: true,
      unSortIcon: true,
      field: "TY ST%",
      valueFormatter: percentageFormatter,
      editable: true,
      cellStyle: params => {
        if (params.node.rowIndex === 0) {
          return { "background-color": "white" };
        } else if (
          params.data["maxST%"] > params.data["TY ST%"] ||
          params.data["minST%"] < params.data["TY ST%"]
        ) {
          return errorInput;
        } else {
          return inputCss;
        }
      }
    },
    {
      headerName: "LY ST%",
      width: 120,
      sortable: true,
      unSortIcon: true,
      field: "LY ST%",
      valueFormatter: percentageFormatter
    },
    {
      headerName: "TY APS",
      width: 120,
      sortable: true,
      unSortIcon: true,
      field: "TY APS",
      valueFormatter: decimalFormatter,
      cellStyle: params => {
        if (params.node.rowIndex !== 0) return inputCss;
      },
      editable: true
    },
    {
      headerName: "LY APS",
      width: 118,
      sortable: true,
      unSortIcon: true,
      field: "LY APS",
      valueFormatter: decimalFormatter
    },
    {
      headerName: "REG WEEKS",
      width: 146,
      sortable: true,
      unSortIcon: true,
      field: "reg_week",
      editable: true,
      cellStyle: params => {
        if (params.node.rowIndex !== 0) return inputCss;
      }
    },
    {
      headerName: "MIN WEEKS",
      width: 104,
      sortable: true,
      unSortIcon: true,
      field: "min",
      editable: true,
      cellStyle: params => {
        if (params.node.rowIndex !== 0) return inputCss;
      }
    },
    {
      headerName: "MATCH ECOM CC TO CL",
      sortable: true,
      width: 200,
      unSortIcon: true,
      field: "ecom",
      editable: true,
      hide: params => {
        if (params.data !== undefined && params.data.ecom === "Y") {
          return true;
        } else {
          return false;
        }
      },
      cellStyle: params => {
        if (params.node.rowIndex !== 0) return inputCss;
      }
    }
  ]
};

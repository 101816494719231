import {
  decimalFormatter,
  percentageFormatter,
  decimalFormatter$,
  decimalFormatterwithRound$,
  toUnitNoData,
  toDollar
} from "../../../helpers/formatters";
import volumeGroupCalculations from "./../Step3VolumeGroups/volumeGroupCalculations";
import volumeGroupCalculations1 from "./../Step3VolumeGroups/volumeGroupCalculations1";

export default {
  defaultColDef: {
    animateRows: true,
    sortable: true,
    unSortIcon: true,
    resizable: true,
    menuTabs: []
  },
  animateRows: true,
  sortable: true,
  filter: true,
  unSortIcon: true,
  suppressCellSelection: true,
  cellClass: "no-border",
  groupRowAggNodes: nodes => {
    var obj = {
      choice: null,
      weeks_active: null,
      sales_u: null,
      tot_sls_qty: null,
      sales_rtl: null,
      gm: null,
      gm_asst: null,
      oh_tot: null,
      rcpt_u: null,
      bop_u: null,
      eop_u: null,
      u_turn_denom: null,
      sales_rtl_lost_sales: null,
      u_rec_depth: null,
      clusters: null,
      loc_count: null,
      // weeks_active: null,
      rcpts_per_door_per_cc: null,
      u_turn: null,
      sls_u_per_door: null,
      rtl_per_door: null,
      gm_per_door: null,
      gm_rate: null,
      rcpt_qty_st: null,
      gm_asst_per_door: null,
      gm_asst_rate: null,
      aur: null,
      aps: null,
      aps_gm: null,
      aps_gm_asst: null,
      sell_through: null,
      loc_id: null
    };
    obj.loc_count = nodes.length;
    nodes.forEach(function(node, index) {
      volumeGroupCalculations(false, obj, node, index);
    });
    volumeGroupCalculations1(obj);
    return obj;
  },
  columnDefs: [
    {
      headerName: "Attr Cl's",
      field: "attribute_clusters",
      valueFormatter: val => {
        if (+val.value === 0) {
          return "Ecom " + val.value;
        } else {
          return "CL " + val.value;
        }
      },
      pinned: "left",
      hide: true
    },
    {
      headerName: "Vol Cl's",
      field: "clusters",
      width: 120,
      valueFormatter: val => {
        if (+val.value === 0) {
          return "Ecom " + val.value;
        } else {
          return "CL " + val.value;
        }
      },
      pinned: "left",
      hide: true
    },
    {
      width: 120,
      cellStyle: { "text-align": "right" },
      resizable: true,
      pinned: "left",
      filter: "agNumberColumnFilter",
      valueGetter: params => {
        if (!params.node.group)
          return +params.data.clusters
            ? "CL " + params.data.attribute_clusters + params.data.clusters
            : "Ecom";
      },
      hide: true,
      rowGroup: true
    },
    // {
    //   headerName: "STR #",
    //   pinned: "left",
    //   field: "loc_id",
    //   filter: "agTextColumnFilter",
    //   // cellStyle: { "text-align": "right" },
    //   width: 79
    // },
    {
      headerName: "STORE NAME",
      tooltipField: "store_name",
      // cellStyle: { "text-align": "right" },
      pinned: "left",
      field: "store_name",
      filter: "agTextColumnFilter",
      width: 200
    },
    {
      headerName: "RETAIL/ DR",
      cellStyle: { "text-align": "right" },
      field: "rtl_per_door",
      valueFormatter: toDollar,
      width: 90
    },
    {
      headerName: "LOST SALES / DR",
      cellStyle: { "text-align": "right" },
      sortable: true,
      resizable: true,
      valueFormatter: toDollar,
      width: 93,
      field: "sales_rtl_lost_sales"
    },
    {
      headerName: "U Rcpt Depth",
      cellStyle: { "text-align": "right" },
      sortable: true,
      resizable: true,
      valueFormatter: toUnitNoData,
      width: 93,
      field: "u_rec_depth"
    },
    {
      headerName: "U SLS",
      cellStyle: { "text-align": "right" },
      field: "sales_u",
      valueFormatter: toUnitNoData,
      width: 63
    },
    {
      headerName: "U SLS/ DR",
      cellStyle: { "text-align": "right" },
      field: "sls_u_per_door",
      // filter: "agTextColumnFilter",
      valueFormatter: toUnitNoData,
      width: 79
    },
    {
      headerName: "$GM/ DR",
      field: "gm_per_door",
      cellStyle: { "text-align": "right" },
      valueFormatter: toDollar,
      width: 76
    },
    {
      headerName: "GM RATE",
      cellStyle: { "text-align": "right" },
      field: "gm_rate",
      valueFormatter: percentageFormatter,
      width: 75
    },
    {
      headerName: "$GM Asst/ DR",
      cellStyle: { "text-align": "right" },
      field: "gm_asst_per_door",
      valueFormatter: toDollar,
      width: 97
    },
    {
      headerName: "GM ASST RATE",
      cellStyle: { "text-align": "right" },
      field: "gm_asst_rate",
      valueFormatter: percentageFormatter,
      width: 98
    },
    {
      headerName: "APS",
      cellStyle: { "text-align": "right" },
      field: "aps",
      valueFormatter: decimalFormatter,
      width: 67
    },
    {
      headerName: "APS GM",
      field: "aps_gm",
      cellStyle: { "text-align": "right" },
      valueFormatter: decimalFormatter$,
      width: 67
    },
    {
      headerName: "APS GM ASST",
      cellStyle: { "text-align": "right" },
      width: 91,
      field: "aps_gm_asst",
      valueFormatter: decimalFormatter$
    },
    {
      headerName: "ST%",
      width: 69,
      cellStyle: { "text-align": "right" },
      field: "sell_through",
      valueFormatter: percentageFormatter
    },
    {
      headerName: "AUR",
      cellStyle: { "text-align": "right" },
      field: "aur",
      valueFormatter: decimalFormatterwithRound$,
      width: 68
      // valueFormatter: roundToTwoDecimal
    },
    {
      headerName: "U TURN",
      field: "u_turn",
      cellStyle: { "text-align": "right" },
      valueFormatter: decimalFormatter,
      width: 78
    },
    {
      headerName: "U RCPT",
      cellStyle: { "text-align": "right" },
      field: "rcpt_u",
      valueFormatter: toUnitNoData,
      width: 76
    },
    {
      headerName: "RCPT/ DR",
      cellStyle: { "text-align": "right" },
      field: "rcpts_per_door_per_cc",
      valueFormatter: toUnitNoData,
      width: 82
    },
    {
      headerName: "AVG CC",
      field: "choice",
      cellStyle: { "text-align": "right" },
      valueFormatter: toUnitNoData,
      width: 74
    },
    {
      headerName: "U BOP",
      field: "bop_u",
      cellStyle: { "text-align": "right" },
      valueFormatter: toUnitNoData,
      width: 69
    },

    {
      headerName: "U EOP",
      cellStyle: { "text-align": "right" },
      field: "eop_u",
      valueFormatter: toUnitNoData,
      width: 69
    },
    {
      headerName: "AVG WKS ACTIVE",
      cellStyle: { "text-align": "right" },
      field: "avg_weeks_active",
      valueFormatter: toUnitNoData,
      width: 100
    }
  ]
};

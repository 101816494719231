import { roundToTwoDecimal, toPercentage } from "../../../helpers/formatters";
import { inputCss } from "../../../styles";

// Give  'cellEditor: "numericCellEditor"' - only if you want it as whole number (no decimal) and num editer.
export default {
  defaultColDef: {
    resizable: true,
    rowData: null,
    menuTabs: [],
    //   filter: true,
    sortable: true,
    unSortIcon: true
  },
  columnDefs: function(userDisableAccess, selectedPrimaryAttributes) {
    const columns = [
      {
        headerName: "Vendor name",
        // cellStyle: inputCss,
        filter: "agTextColumnFilter",
        field: "class/vendor",
        width: 153
        // cellEditor: "numericCellEditor",
        // editable: !userDisableAccess && true
      },
      // {
      //   resizable: true,
      //   headerName: "CLASS ID",
      //   cellEditor: "numericCellEditor",
      //   field: "classId",
      //   cellStyle: inputCss,
      //   editable: !userDisableAccess && true
      // },
      {
        headerName: "SUB GRP",
        // editable: !userDisableAccess && true,
        width: 114,
        // cellStyle: inputCss,
        filter: "agTextColumnFilter",
        field: "subclass"
      },
      {
        headerName: "Style color name",
        // editable: !userDisableAccess && true,
        filter: "agTextColumnFilter",
        width: 120,
        // cellStyle: inputCss,
        field: "styleNameColor"
      },
      {
        headerName: "APS",
        width: 69,
        cellStyle: inputCss,
        // cellEditor: "numericCellEditor",
        valueFormatter: roundToTwoDecimal,
        editable: !userDisableAccess && true,
        field: "aps"
      },
      {
        headerName: "MIN PER SIZE",
        cellStyle: inputCss,
        width: 97,
        cellEditor: "numericCellEditor",
        editable: !userDisableAccess && true,
        field: "minPerSize"
      },
      {
        headerName: " MSRP/TICKET",
        width: 123,
        cellStyle: inputCss,
        // cellEditor: "numericCellEditor",
        valueFormatter: roundToTwoDecimal,
        editable: !userDisableAccess && true,
        field: "msrp"
      },
      {
        headerName: "IMU%",
        cellStyle: inputCss,
        width: 80,
        valueFormatter: roundToTwoDecimal,
        // valueFormatter: percentageFormatter,
        // cellEditor: "numericCellEditor",
        editable: !userDisableAccess && true,
        field: "imu"
        // valueFormatter: toPercentage,
      },
      {
        headerName: "MAX STORE COUNT",
        cellStyle: inputCss,
        width: 88,
        editable: !userDisableAccess && true,
        cellEditor: "numericCellEditor",
        field: "storeCount"
      },
      {
        headerName: "REG ST",
        width: 70,
        cellStyle: inputCss,
        valueFormatter: roundToTwoDecimal,
        // cellEditor: "numericCellEditor",
        editable: !userDisableAccess && true,
        field: "regSellThroughPerc"
        // valueFormatter: toPercentage,
      },
      {
        headerName: "WEEKS",
        width: 87,
        cellStyle: inputCss,
        cellEditor: "numericCellEditor",
        editable: !userDisableAccess && true,
        field: "weeks"
      },
      {
        headerName: "STYLE #",
        editable: !userDisableAccess && true,
        width: 92,
        cellStyle: inputCss,
        field: "styleId"
      },
      {
        headerName: "NO OF SIZES ",
        width: 82,
        editable: !userDisableAccess && true,
        cellStyle: inputCss,
        cellEditor: "numericCellEditor",
        field: "noOfSizes"
      },
      {
        headerName: "REG DISC%",
        width: 73,
        cellStyle: inputCss,
        // valueFormatter: percentageFormatter,
        // cellEditor: "numericCellEditor",
        editable: !userDisableAccess && true,
        field: "regDiscount"
        // valueFormatter: toPercentage,
      },
      {
        headerName: "PROD MIN",
        cellStyle: inputCss,
        width: 77,
        cellEditor: "numericCellEditor",
        editable: !userDisableAccess && true,
        field: "productionMin"
      },
      {
        headerName: "MAX QTY",
        cellStyle: inputCss,
        width: 73,
        editable: !userDisableAccess && true,
        cellEditor: "numericCellEditor",
        field: "maxQuantity"
      },
      {
        headerName: "MOQ",
        width: 72,
        cellStyle: inputCss,
        cellEditor: "numericCellEditor",
        editable: !userDisableAccess && true,
        field: "moq"
      },
      {
        headerName: "SURCHARGE % ",
        width: 126,
        cellEditor: "numericCellEditor",
        cellStyle: inputCss,
        // valueFormatter: roundToTwoDecimal,
        editable: !userDisableAccess && true,
        field: "surchargePerc",
        valueFormatter: toPercentage
      },
      // {
      //   headerName: "SEASON",
      //   width: 92,
      //   cellStyle: inputCss,
      //   editable: !userDisableAccess && true,
      //   field: "season"
      // },
      {
        headerName: "1st MD ST",
        width: 87,
        cellStyle: inputCss,
        editable: !userDisableAccess && true,
        // valueFormatter: percentageFormatter,
        // cellEditor: "numericCellEditor",
        field: "firstMarkdownSellThrough"
        // valueFormatter: toPercentage,
      },
      {
        headerName: "1st MD DISC",
        width: 85,
        cellStyle: inputCss,
        // valueFormatter: percentageFormatter,
        // cellEditor: "numericCellEditor",
        editable: !userDisableAccess && true,
        field: "firstMarkdownDiscount"
        // valueFormatter: toPercentage,
      },
      {
        headerName: "2nd MD ST",
        cellStyle: inputCss,
        width: 84,
        // valueFormatter: percentageFormatter,
        // cellEditor: "numericCellEditor",
        editable: !userDisableAccess && true,
        field: "secondMarkdownSellThrough"
        // valueFormatter: toPercentage,
      },
      {
        headerName: "2nd MD DISC",
        cellStyle: inputCss,
        width: 111,
        // valueFormatter: percentageFormatter,
        // cellEditor: "numericCellEditor",
        editable: !userDisableAccess && true,
        field: "secondMarkdownDiscount"
        // valueFormatter: toPercentage,
      },
      {
        headerName: "COMBINATION FLAG",
        cellStyle: inputCss,
        cellEditor: "numericCellEditor",
        editable: !userDisableAccess && true,
        width: 137,
        field: "combinationFlag"
      }
    ];

    const primaryAttrColumns = [];

    selectedPrimaryAttributes &&
      selectedPrimaryAttributes.length &&
      selectedPrimaryAttributes.forEach(element => {
        primaryAttrColumns.push({
          headerName: element,
          // cellStyle: inputCss,
          width: 137,
          // editable: !userDisableAccess && true,
          field: element
        });
      });
    columns.splice(3, 0, ...primaryAttrColumns);
    return columns;
  }
};

import Tippy from "@tippy.js/react";
import moment from "moment";
import React, { Component } from "react";

class FloatingComponent extends Component {
  state = {
    class: "defaultSidebarMini",
    display: "none"
  };

  onHover = () => {
    this.setState({
      class: "active",
      display: "inline-block"
    });
  };

  onLeaveHover = () => {
    this.setState({
      class: "",
      display: ""
    });
  };

  render() {
    const receiptSdateFormat = this.props.data.otb_flag
      ? moment(
          this.props.data &&
            this.props.data.receipt_range &&
            this.props.data.receipt_range.sdate
        ).format("MM/YYYY")
      : moment(
          this.props.data &&
            this.props.data.receipt_range &&
            this.props.data.receipt_range.sdate
        ).format("MM/DD/YYYY");
    const receiptEdateFormat = this.props.data.otb_flag
      ? moment(
          this.props.data &&
            this.props.data.receipt_range &&
            this.props.data.receipt_range.edate
        ).format("MM/YYYY")
      : moment(
          this.props.data &&
            this.props.data.receipt_range &&
            this.props.data.receipt_range.edate
        ).format("MM/DD/YYYY");
    const sellingSdateFormat = moment(
      this.props.data &&
        this.props.data.selling_period &&
        this.props.data.selling_period.sdate
    ).format("MM/DD/YYYY");
    const sellingEdateFormat = moment(
      this.props.data &&
        this.props.data.selling_period &&
        this.props.data.selling_period.edate
    ).format("MM/DD/YYYY");
    return (
      <div className="wrapper2">
        <nav
          id="sidebarMini"
          className={`${this.state.class} defaultSidebarMini`}
          onMouseEnter={e => this.onHover(e)}
          onMouseLeave={e => this.onLeaveHover(e)}
        >
          {/* <div className="sidebar-header" /> */}
          <ul className="list-unstyled components sideBarList2">
            <li className={"active"}>
              {/* <a href="/ComparePromotion"> */}
              <div
                style={{
                  backgroundColor: "#2d69eb",
                  borderRadius: "5px 0px 0px 5px"
                }}
              >
                <i
                  className="fa fa-chevron-left icon-styling"
                  aria-hidden="true"
                />
                <span
                  className="fontstyling FloatingComponent-Container"
                  style={{ height: "380", overflowY: "auto", width: "150%" }}
                >
                  <div className="FloatingComponent-inputHeader">
                    <div>Plan Details</div>
                  </div>
                  <div className="FloatingComponent-ContentContainer">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Plan Name</div>
                          <input
                            value={
                              (this.props.data && this.props.data.name) || ""
                            }
                            disabled
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Department ID</div>

                          <input
                            value={
                              this.props.data && this.props.data.isComp
                                ? (this.props.data.selected_filters &&
                                    this.props.data.selected_filters
                                      .departments &&
                                    this.props.data.selected_filters
                                      .departments[0].value) ||
                                  ""
                                : (this.props.data.selected_filters_target &&
                                    this.props.data.selected_filters_target
                                      .departments &&
                                    this.props.data.selected_filters_target
                                      .departments[0].value) ||
                                  ""
                            }
                            disabled
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Class</div>

                          <Tippy
                            placement={"left"}
                            arrow={true}
                            distance={10}
                            content={
                              <div>
                                {this.props.data && this.props.data.isComp
                                  ? this.props.data.selected_filters &&
                                    this.props.data.selected_filters.classes &&
                                    this.props.data.selected_filters.classes.map(
                                      (item, index) => {
                                        return (
                                          <div key={index}>{item.label}</div>
                                        );
                                      }
                                    )
                                  : this.props.data.selected_filters_target &&
                                    this.props.data.selected_filters_target
                                      .classes &&
                                    this.props.data.selected_filters_target.classes.map(
                                      (item, index) => {
                                        return (
                                          <div key={index}>{item.label}</div>
                                        );
                                      }
                                    )}
                              </div>
                            }
                          >
                            <div className="FloatingComponent-tippy-input">
                              <input
                                disabled
                                value={
                                  this.props.data && this.props.data.isComp
                                    ? this.props.data.selected_filters &&
                                      this.props.data.selected_filters
                                        .classes &&
                                      this.props.data.selected_filters.classes
                                        .length > 1
                                      ? `${this.props.data &&
                                          this.props.data.selected_filters &&
                                          this.props.data.selected_filters &&
                                          this.props.data.selected_filters
                                            .classes[0] &&
                                          this.props.data.selected_filters
                                            .classes[0].label} + ${this.props
                                          .data.selected_filters.classes
                                          .length - 1}`
                                      : (this.props.data &&
                                          this.props.data.selected_filters &&
                                          this.props.data.selected_filters
                                            .classes &&
                                          this.props.data.selected_filters
                                            .classes[0].label) ||
                                        ""
                                    : this.props.data.selected_filters_target &&
                                      this.props.data.selected_filters_target
                                        .classes &&
                                      this.props.data.selected_filters_target
                                        .classes.length > 1
                                    ? `${this.props.data &&
                                        this.props.data
                                          .selected_filters_target &&
                                        this.props.data
                                          .selected_filters_target &&
                                        this.props.data.selected_filters_target
                                          .classes[0] &&
                                        this.props.data.selected_filters_target
                                          .classes[0].label} + ${this.props.data
                                        .selected_filters_target.classes
                                        .length - 1}`
                                    : (this.props.data &&
                                        this.props.data
                                          .selected_filters_target &&
                                        this.props.data.selected_filters_target
                                          .classes &&
                                        this.props.data.selected_filters_target
                                          .classes[0].label) ||
                                      ""
                                }
                                type="text"
                              />
                              {/* <i
                              class="fa fa-info-circle errspan"
                              aria-hidden="true"
                            /> */}
                            </div>
                          </Tippy>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Vendor</div>
                          <Tippy
                            placement={"left"}
                            arrow={true}
                            distance={10}
                            content={
                              <div>
                                {this.props.data && this.props.data.isComp
                                  ? this.props.data.selected_filters &&
                                    this.props.data.selected_filters.vendors &&
                                    this.props.data.selected_filters.vendors.map(
                                      (item, index) => {
                                        return (
                                          <div key={index}>{item.label}</div>
                                        );
                                      }
                                    )
                                  : this.props.data.selected_filters_target &&
                                    this.props.data.selected_filters_target
                                      .vendors &&
                                    this.props.data.selected_filters_target.vendors.map(
                                      (item, index) => {
                                        return (
                                          <div key={index}>{item.label}</div>
                                        );
                                      }
                                    )}
                              </div>
                            }
                          >
                            <div className="FloatingComponent-tippy-input">
                              <input
                                disabled
                                value={
                                  this.props.data && this.props.data.isComp
                                    ? this.props.data.selected_filters &&
                                      this.props.data.selected_filters
                                        .vendors &&
                                      this.props.data.selected_filters.vendors
                                        .length > 1
                                      ? `${this.props.data &&
                                          this.props.data.selected_filters &&
                                          this.props.data.selected_filters
                                            .vendors[0] &&
                                          (this.props.data.selected_filters
                                            .vendors[0].label ||
                                            this.props.data.selected_filters
                                              .vendors[1].label)} + ${this.props
                                          .data.selected_filters &&
                                          this.props.data.selected_filters
                                            .vendors.length - 1}`
                                      : (this.props.data &&
                                          this.props.data.selected_filters &&
                                          this.props.data.selected_filters
                                            .vendors &&
                                          this.props.data.selected_filters
                                            .vendors[0] &&
                                          this.props.data.selected_filters
                                            .vendors[0].label) ||
                                        ""
                                    : this.props.data.selected_filters_target &&
                                      this.props.data.selected_filters_target
                                        .vendors &&
                                      this.props.data.selected_filters_target
                                        .vendors.length > 1
                                    ? `${this.props.data &&
                                        this.props.data
                                          .selected_filters_target &&
                                        this.props.data.selected_filters_target
                                          .vendors[0] &&
                                        (this.props.data.selected_filters_target
                                          .vendors[0].label ||
                                          this.props.data
                                            .selected_filters_target.vendors[1]
                                            .label)} + ${this.props.data
                                        .selected_filters_target &&
                                        this.props.data.selected_filters_target
                                          .vendors.length - 1}`
                                    : (this.props.data &&
                                        this.props.data
                                          .selected_filters_target &&
                                        this.props.data.selected_filters_target
                                          .vendors &&
                                        this.props.data.selected_filters_target
                                          .vendors[0] &&
                                        this.props.data.selected_filters_target
                                          .vendors[0].label) ||
                                      ""
                                }
                                type="text"
                              />{" "}
                              {/* <i
                              class="fa fa-info-circle errspan"
                              aria-hidden="true"
                            /> */}
                            </div>
                          </Tippy>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Season Code</div>
                          <Tippy
                            placement={"left"}
                            arrow={true}
                            distance={10}
                            content={
                              <div>
                                {this.props.data &&
                                  this.props.data.season_ids &&
                                  this.props.data.season_ids.map(
                                    (item, index) => {
                                      return <div key={index}>{item}</div>;
                                    }
                                  )}
                              </div>
                            }
                          >
                            <div className="FloatingComponent-tippy-input">
                              <input
                                disabled
                                value={
                                  this.props.data &&
                                  this.props.data.season_ids &&
                                  this.props.data.season_ids.length > 1
                                    ? `${this.props.data &&
                                        this.props.data.season_ids[0]} + ${this
                                        .props.data.season_ids.length - 1}`
                                    : (this.props.data &&
                                        this.props.data.season_ids &&
                                        this.props.data.season_ids[0]) ||
                                      ""
                                }
                                type="text"
                              />{" "}
                              {/* <i
                              class="fa fa-info-circle errspan"
                              aria-hidden="true"
                            /> */}
                            </div>
                          </Tippy>
                        </div>
                      </div>
                      {/* <div className="FloatingComponent-inputContainer">
                        <div>Select SSD (Optional)</div>
                        <input disabled type="text" />
                      </div>
                      <div className="FloatingComponent-inputContainer">
                        <div>Select primary attribute</div>
                        <input disabled value="Sleeve length +2" type="text" />
                      </div> */}
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Future Receipt Period</div>
                          <Tippy
                            placement={"left"}
                            arrow={true}
                            distance={10}
                            content={
                              <div>
                                {receiptSdateFormat} - {receiptEdateFormat}
                              </div>
                            }
                          >
                            <div className="FloatingComponent-tippy-input">
                              <input
                                disabled
                                value={`${receiptSdateFormat} - ${receiptEdateFormat}`}
                                type="text"
                              />
                            </div>
                          </Tippy>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Future Selling Period</div>
                          <Tippy
                            placement={"left"}
                            arrow={true}
                            distance={10}
                            content={
                              <div>
                                {sellingSdateFormat} - {sellingEdateFormat}
                              </div>
                            }
                          >
                            <div className="FloatingComponent-tippy-input">
                              <input
                                disabled
                                value={`${sellingSdateFormat} - ${sellingEdateFormat}`}
                                type="text"
                              />
                            </div>
                          </Tippy>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="FloatingComponent-inputContainer">
                          <div>Include Repl ?</div>
                          <input
                            value={
                              this.props.data && this.props.data.rpl_f
                                ? "Yes"
                                : "No"
                            }
                            disabled
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="FloatingComponent-inputContainer">
                      <div>Exclude Ecom ?</div>
                      <input
                        value={
                          this.props.data && !this.props.data.ecomm_f
                            ? "Yes"
                            : "No"
                        }
                        disabled
                        type="text"
                      />
                    </div>
                  </div>
                </span>
              </div>
              {/* </a> */}
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default FloatingComponent;

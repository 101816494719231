const filtersReducerDefaultState = {
  departmentsList: [],
  classesList: [],
  classesListTarget: [],
  vendorsList: [],
  vendorsListTarget: [],
  seasonList: [],
  buyerList: [],
  departmentsListAccToBuyer: [],
  fobList: [],
  dmmList: [],
  buyerListBuyRollup: [],
  dcList: [],
  departmentsListAccDc: []
};

export default (state = filtersReducerDefaultState, action) => {
  switch (action.type) {
    case "GET_DEPARTMENTS_LIST":
      return {
        ...state,
        departmentsList: action.payload
      };
    case "GET_BUYERS_LIST":
      return {
        ...state,
        buyerList: action.payload
      };
    case "GET_CLASSES_LIST":
      return {
        ...state,
        classesList: action.payload
      };
    case "GET_CLASSES_LIST_TARGET":
      return {
        ...state,
        classesListTarget: action.payload
      };
    case "GET_VENDORS_LIST":
      return {
        ...state,
        vendorsList: action.payload
      };
    case "GET_VENDORS_LIST_TARGET":
      return {
        ...state,
        vendorsListTarget: action.payload
      };
    case "SET_SEASON_LIST":
      return {
        ...state,
        seasonList: action.payload
      };
    case "GET_DEPARTMENTS_LIST_ACC_TO_BUYER":
      return {
        ...state,
        departmentsListAccToBuyer: action.payload
      };
    case "GET_FOB_LIST":
      return {
        ...state,
        fobList: action.payload
      };
    case "CLEAR_FOB_DATA":
      return {
        ...state,
        fobList: []
      };
    case "GET_DMM_LIST":
      return {
        ...state,
        dmmList: action.payload
      };
    case "CLEAR_DMM_DATA":
      return {
        ...state,
        dmmList: []
      };
    case "GET_BUYERS_LIST_BUY_ROLLUP":
      return {
        ...state,
        buyerListBuyRollup: action.payload
      };
    case "CLEAR_BUYER_DATA_BUY_ROLLUP":
      return {
        ...state,
        buyerListBuyRollup: []
      };
    case "GET_DC_LIST":
      return {
        ...state,
        dcList: action.payload
      };
    case "clearDcData":
      return {
        ...state,
        dcList: []
      };
    case "GET_DEPARTMENTS_LIST_ACC_TO_DC":
      return {
        ...state,
        departmentsListAccDc: action.payload
      };
    case "clearDepartmentsAccToDCData":
      return {
        ...state,
        departmentsListAccDc: []
      };
    default:
      return state;
  }
};

import React, { Component } from "react";
import ResetPassword from "../ResetPassword";
// import Alert from "/../../services/alert";
import Alert from "../../services/alert";

import {
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
// import Modal from "../../../services/modal";
import Modal from "../../services/modal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import userActions from "../../actions/userActions";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      isLogout: false,
      modalVisible: false
    };
    this.toggle = this.toggle.bind(this);
  }
  handleAccept = () => {
    this.props.history.push("/login");
    window.localStorage.removeItem("token");
    this.setState({
      isLogout: false
    });
  };
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  closeModal = () => {
    this.setState({ modalVisible: false });
  };
  onLogout = () => {
    this.setState({ isLogout: true });
  };

  render() {
    return (
      <div>
        <Navbar color="light" light expand="md" className=" navbar-light">
          <NavbarBrand href="/dashboard" style={{ padding: "0" }}>
            <img
              src={window.location.origin + "/img/Belk.png"}
              alt="logo-belk"
              className="p-2"
            />
          </NavbarBrand>
          <Nav className="mx-auto" navbar>
            <NavItem>
              <NavbarBrand>
                <label>Assortment Suite</label>
              </NavbarBrand>
            </NavItem>
            {/* <NavItem>
              <NavLink>
                <i
                  onClick={this.onLogout}
                  className="fa fa-power-off navicon pointer"
                  style={{ fontSize: "20px" }}
                  aria-hidden="true"
                />
              </NavLink>
            </NavItem> */}
          </Nav>
          {/* <NavbarBrand>
            <i
              onClick={this.onLogout}
              className="fa fa-power-off navicon pointer textColor"
              style={{ fontSize: "20px" }}
              aria-hidden="true"
            />
          </NavbarBrand> */}
          <div>{localStorage.getItem("userName")}</div>
          <div style={{textTransform: "capitalize"}}>&nbsp;({localStorage.getItem("userType")})</div>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              <i
                className="fa fa-power-off navicon pointer textColor"
                style={{ fontSize: "20px", padding: "10px" }}
                aria-hidden="true"
              />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => this.setState({ modalVisible: true })}
              >
                Reset Password
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={this.onLogout}>Logout</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Navbar>
        <Modal
          open={this.state.modalVisible}
          onClose={() => this.closeModal()}
          maxWidth="lg"
          title="Reset Password"
        >
          <div
            className="p-4"
            // style={{ marginLeft: "20px", marginRight: "20px" }}
          >
            <ResetPassword
              onClose={() => this.closeModal()}
              resetPassword={this.props.resetPassword}
            />
          </div>
        </Modal>
        <Alert
          open={this.state.isLogout}
          isResetRequired={false}
          content="Are you sure you want to Logout?"
          handleAccept={this.handleAccept}
          actionButtonText="Yes"
          cancelButtonText="No"
          handleClose={() => {
            this.setState({
              isLogout: false
            });
          }}
        />
      </div>
    );
  }
}
const mapActionsToProps = {
  resetPassword: userActions.resetPassword
};
const mapStateToProps = store => {
  return {
    status: store.userReducer.status
  };
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Header));

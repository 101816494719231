import {
  rcptDollarKey,
  aurKey,
  rcptUnitsKey,
  lYRcptDollarKey,
  rcptPenePercentKey,
  lyRcptPercentChangeKey,
  subGrpRcptWeightagePercentKey,
  tyCCKey,
  lyCCKey,
  tyStyleCountKey,
  lyStyleCountKey
} from "../constants/keys";

export function subGrpRcptWeightagePercentCalc(obj, totalClassRcptDollar) {
  return (
    totalClassRcptDollar && (obj[rcptDollarKey] / totalClassRcptDollar) * 100
  );
}

export function lysubGrpRcptWeightagePercentCalc(obj, lyTotalClassRcptDollar) {
  return (
    lyTotalClassRcptDollar &&
    (obj[lYRcptDollarKey] / lyTotalClassRcptDollar) * 100
  );
}

export function rcptPenePercentCalc(obj, targetRcptBuyer) {
  return (
    ((obj[lYRcptDollarKey] * (obj[lyRcptPercentChangeKey] / 100 + 1)) /
      targetRcptBuyer) *
    100
  );
}

export function rcptDollarCalc(obj, targetRcptBuyer) {
  return (obj[rcptPenePercentKey] * targetRcptBuyer) / 100;
}

export function planRcptDollarCalc(obj, planRcptDollar) {
  return (obj[subGrpRcptWeightagePercentKey] * planRcptDollar) / 100;
}

export function rcptUnitsCalc(obj) {
  return obj[aurKey] && obj[rcptDollarKey] / obj[aurKey];
}

export function tYAvgBuyCalc(obj) {
  return obj[tyCCKey] && obj[rcptUnitsKey] / obj[tyCCKey];
}

export function lyRcptPercentChangeCalc(obj) {
  return (
    obj[lYRcptDollarKey] &&
    (obj[rcptDollarKey] / obj[lYRcptDollarKey] - 1) * 100
  );
}

export function rcptPenePercent(obj, targetRcptBuyer) {
  return (
    ((obj[lYRcptDollarKey] * (obj[lyRcptPercentChangeKey] / 100 + 1)) /
      targetRcptBuyer) *
    100
  );
}

export function percentChangeCC(obj) {
  return obj[lyCCKey] && (obj[tyCCKey] / obj[lyCCKey] - 1) * 100;
}

export function percentChangeStyleCountCalc(obj) {
  return (
    obj[lyStyleCountKey] &&
    (obj[tyStyleCountKey] / obj[lyStyleCountKey] - 1) * 100
  );
}

const mainReducerDefaultState = {
  overlayLoaderState: false,
  loaderText: null
};

export default (state = mainReducerDefaultState, action) => {
  switch (action.type) {
    case "LOADER_STATE":
      return {
        ...state,
        overlayLoaderState: action.payload.status,
        loaderText: action.payload.text
      };

    default:
      return state;
  }
};

import React, { Component } from "react";
import BarChart from "../../../services/ChartJS/BarChart";
import { isEqual, findIndex } from "lodash";
import Select from "../../../services/selectAll";
import _ from "lodash"
class AttributeGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stackColors: [
        "#646BC1",
        "#56CCB1",
        "#DDC76C",
        "#DFE3EB",
        "#F2797A",
        "#D66BDE",
        "#6CDEF8",
        "#71CB79"
      ],
      graphLegendDetails: [],
      chartData: {},
      options: {
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || "";

              if (label) {
                label += ": ";
              }
              label += Math.round(tooltipItem.yLabel * 100) / 100 + "%";
              return label;
            }
          }
        },
        legend: {
          position: "bottom",
          display: false,
          align: "end"
        },
        scales: {
          xAxes: [
            {
              barPercentage: 0.2,
              scaleLabel: {
                display: true,
                labelString: "Attribute Clusters"
              }
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "% Receipt units"
              },
              stacked: true
            }
          ]
        }
      }
    };
  }
  componentDidMount() {
    if (
      this.props.selectedAttr &&
      this.props.graphData &&
      this.props.graphData.TY &&
      this.props.graphData.LY
    ) {
      this.setGraphData(this.props.selectedAttr.value);
    }
  }
  componentDidUpdate(props, state) {
    if (
      this.props.selectedAttr &&
      this.props.graphData &&
      this.props.graphData.TY &&
      this.props.graphData.LY &&
      !isEqual(this.props.graphData, props.graphData)
    ) {
      this.setGraphData(this.props.selectedAttr.value);
    }
  }

  generateGraphDataAnnBasis = (
    graphData,
    selAttr,
    year,
    colorArr,
    stack,
    colorLegends
  ) => {
    let selAttrData = graphData[selAttr];
    let resDataObj = {};
    let legends = [];
    if (year === "ty") {
      legends = colorLegends;
    }
    const attrClusters = Object.keys(selAttrData).sort();
    for (const attrCluster of attrClusters) {
      if (selAttrData.hasOwnProperty(attrCluster)) {
        const clusterData = selAttrData[attrCluster];
        let colorInd;
        if (year === "ty" && colorLegends && colorLegends.length) {
          colorInd = legends.length;
        } else {
          colorInd = 0;
        }
        for (const attrValue in clusterData) {
          if (clusterData.hasOwnProperty(attrValue)) {
            const attrVal = clusterData[attrValue] * 100;
            let modifiedkey = attrValue.includes("_")
              ? attrValue.split("_").join(" ")
              : attrValue;
            if (
              legends.length &&
              findIndex(
                legends.length && legends,
                o => o.name === modifiedkey
              ) === -1
            ) {
              if (modifiedkey !== "undefined") {
                let legendObj = {
                  name: modifiedkey,
                  color: colorArr[colorInd]
                };
                legends.push(legendObj);
              }
            } else if (!legends.length) {
              if (modifiedkey !== "undefined") {
                let legendObj = {
                  name: modifiedkey,
                  color: colorArr[colorInd]
                };
                legends.push(legendObj);
              }
            }
            if (resDataObj.hasOwnProperty(modifiedkey)) {
              resDataObj[`${modifiedkey}`].data.push(attrVal);
            } else {
              if (modifiedkey !== "undefined") {
                resDataObj = {
                  ...resDataObj,
                  [`${modifiedkey}`]: {
                    label: `${year} ${modifiedkey}`,
                    backgroundColor:
                      legends[
                        findIndex(
                          legends.length && legends,
                          o => o.name === modifiedkey
                        )
                      ].color,
                    data: [attrVal],
                    stack: stack
                  }
                };
              }
            }
            colorInd += 1;
          }
        }
      }
    }
    return { resDataObj, legends };
  };
  setGraphData = selAttr => {
    const lyData = this.generateGraphDataAnnBasis(
      this.props.graphData.LY,
      selAttr,
      this.props.lyFlag ? "ly" : "lly",
      this.state.stackColors,
      1
    );
    const tyData = this.generateGraphDataAnnBasis(
      this.props.graphData.TY,
      selAttr,
      "ty",
      this.state.stackColors,
      2,
      lyData.legends
    );
    var sortedlyData = _.sortBy( Object.values(lyData.resDataObj), 'label' );
    var sortedtyData = _.sortBy( Object.values(tyData.resDataObj), 'label' );
    let finalDataSet = [...sortedlyData,...sortedtyData]
    let graphObj = {
      labels: Object.keys(this.props.graphData.TY[selAttr]).sort(),
      datasets: finalDataSet
    };
    this.setState({ chartData: graphObj, graphLegendDetails: lyData.legends });
  };

  onHandleAttrGrades = selItem => {
    this.setGraphData(selItem.value);
    this.props.onHandleAttrChange(selItem);
  };

  render() {
    return (
      <>
        <div className="card-header-background" style={{ marginTop: "3rem" }}>
          <strong>Attribute Grades</strong>
        </div>
        <div className="mt-2">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="inputPassword4">Attribute</label>
              <Select
                value={this.props.selectedAttr}
                onChange={this.onHandleAttrGrades}
                options={this.props.attrOptions}
                isClearable
              />
            </div>
          </div>
          <div
            className="graph-container"
            style={{
              width: "60%",
              margin: "2rem auto",
              minHeight: "1rem"
            }}
          >
            <BarChart
              type="bar"
              data={this.state.chartData}
              options={this.state.options}
            />
          </div>
          {/* Custom Legends */}
          <div className="legend-container flex-r">
            {this.state.graphLegendDetails.map(legendObj => {
              return (
                <div className="flex-r" key={legendObj.color}>
                  <div
                    className="legend-square"
                    style={{ backgroundColor: legendObj.color }}
                  ></div>
                  <div className="legend-label">{legendObj.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default AttributeGraph;

import { calculateItemLevelAggregated } from "../helpers/calculations";

const buyOptimizationReducerDefaultState = {
  boInputDataStyleColorLevel: null,
  boInputDataStyleLevel: null,
  boGraphData: null,
  constrained: 0,
  brickAndMortarSummary: [],
  programLevelSummary: [],
  itemLevelSummary: [],
  channelSummary: [],
  nearestIndex: null,
  gmGoal: null,
  vendorAssistanceSelectedBudgetRange: null,
  vendorAssistanceSimulationData: [],
  vendorAssistanceGiveBack: null,
  budgetSimulationData: [],
  itemLevelAggregate: [],
  simulateAndFinalizeDownloadURL: null,
  omniIncr: 0,
  omniRcpt: 0,
  omniBuyEstimation: [],
  vendorForFCBuy: [],
  isFcApproved: false,
  aucThreshold: null,
  deptPenetration: null,
  season: null,
  totalBMBuy: []
};

export default (state = buyOptimizationReducerDefaultState, action) => {
  switch (action.type) {
    case "SET_BO_INPUT_DATA_STYLE_COLOR_LEVEL":
      return {
        ...state,
        boInputDataStyleColorLevel: action.payload
      };
    case "SET_BO_INPUT_DATA_STYLE_LEVEL":
      return {
        ...state,
        boInputDataStyleLevel: action.payload
      };
    case "SET_BO_GRAPH_DATA":
      return {
        ...state,
        boGraphData: action.payload
      };
    case "SET_CONSTRAINED":
      return {
        ...state,
        constrained: Number(action.payload)
      };
    case "SET_GM_GOAL":
      return {
        ...state,
        gmGoal: action.payload
      };
    case "SET_VA_SELECTED_BUDGET_RANGE":
      return {
        ...state,
        vendorAssistanceSelectedBudgetRange: action.payload
      };
    case "SET_VENDOR_ASSISTANCE_SIMULATION_DATA":
      return {
        ...state,
        vendorAssistanceSimulationData: action.payload
      };
    case "SET_VENDOR_ASSISTANCE_GIVE_BACK":
      return {
        ...state,
        vendorAssistanceGiveBack: action.payload
      };
    case "SET_BRICK_AND_MORTAR_DATA":
      return {
        ...state,
        brickAndMortarSummary: action.payload
      };
    case "SET_PROGRAM_LEVEL_DATA":
      return {
        ...state,
        programLevelSummary: action.payload
      };
    case "SET_ITEM_LEVEL_DATA":
      return {
        ...state,
        itemLevelAggregate: calculateItemLevelAggregated(action.payload),
        itemLevelSummary: action.payload
      };
    case "SET_CHANNEL_SUMMARY_DATA":
      return {
        ...state,
        channelSummary: action.payload
      };
    case "SET_NEAREST_INDEX":
      return {
        ...state,
        nearestIndex: action.payload
      };
    case "SET_BUDGET_SIMULATION_DATA":
      return {
        ...state,
        budgetSimulationData: action.payload
      };
    case "CLEAR_BUY_OPTIMIZATION_DATA":
      return {
        ...buyOptimizationReducerDefaultState
      };
    case "SIMULATEANDFINALIZE_URL_DOWNLOAD":
      return {
        ...state,
        simulateAndFinalizeDownloadURL: action.payload
      };
    case "SET_OMNI_SALES_PLAN_INCREMENT":
      return {
        ...state,
        omniIncr: action.payload
      };
    case "SET_OMNI_RCPT_PLAN":
      return {
        ...state,
        omniRcpt: action.payload
      };
    case "SET_OMNI_BUY_ESTIMATION":
      return {
        ...state,
        omniBuyEstimation: action.payload
      };
    case "SET_IS_FC_APPROVED":
      return {
        ...state,
        isFcApproved: action.payload
      };
    case "SET_AUC_THRESHOLD":
      return {
        ...state,
        aucThreshold: action.payload
      };
    case "SET_DEPT_PENETRATION":
      return {
        ...state,
        deptPenetration: action.payload
      };
    case "SET_SEASON":
      return {
        ...state,
        season: action.payload
      };
    case "SET_VENDOR_FOR_FC_BUY":
      return {
        ...state,
        vendorForFCBuy: action.payload
      };
    case "SET_TOTAL_BM_BUY":
      return {
        ...state,
        totalBMBuy: action.payload
      };
    default:
      return state;
  }
};

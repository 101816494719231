import React, { Component } from "react";
import DataTable from "../../../services/dataTable";
import BrickAndMortarColumnDefs from "../colDefs/BrickAndMortarColumnDefs";

class BnM extends Component {
  state = {};
  render() {
    return (
      <div style={{ height: "calc(100vh - 400px)", minHeight: "300px" }}>
        <DataTable
          sizeToFit={true}
          height1="calc(100vh - 300px)"
          defaultExpand={true}
          columnDefs={BrickAndMortarColumnDefs.columnDefs(this.props.lyFlag)}
          rowData={this.props.brickAndMortarSummary}
          defaultColDef={BrickAndMortarColumnDefs.defaultColDef}
          //   onCellValueChanged={this.onCellValueChanged}
        />
      </div>
    );
  }
}
export default BnM;

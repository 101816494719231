import {
  decimalToPercentage,
  _stringFormatter
} from "../../../helpers/formatters";

export default {
  defaultColDef: {
    animateRows: true,
    sortable: true,
    unSortIcon: true,
    resizable: true,
    menuTabs: []
  },
  animateRows: true,
  suppressCellSelection: true,
  cellClass: "no-border",
  filter: true,
  columnDefs: [
    {
      headerName: "Attribute name",
      field: "Attribute",
      sortable: true,
      unSortIcon: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      filter: "agTextColumnFilter",
      valueFormatter: _stringFormatter
    },
    {
      headerName: "Fill rate",
      field: "Fillrate",
      sortable: true,
      unSortIcon: true,
      valueFormatter: decimalToPercentage
    },
    {
      headerName: "Rank",
      field: "Rank",
      sort: "asc",
      sortable: true,
      unSortIcon: true
    }
  ]
};

export const stages = {
  "1-1": "Inputs",
  "1-2": "Attribute Bands",
  "1-3": "Volume Groups",
  "1-4": "Clusters",
  "2-1": "Receipt Plan",
  "2-2": "Line Architecture",
  "2-3": "Cluster, Depth and Choice Counts",
  "2-4": "Assortment Wedge",
  "3-1": "Buy Input",
  "3-2": "Simulate & Finalize Budget",
  "3-3": "Omni Buy Estimation"
};

const buyRollUpReducerDefaultState = {
  rollUpData: [],
  projectList: [],
  buyRollUpDataFirst: ""
  // buyRollUpDataSecond: "",
  // buyRollUpDataThird: ""
};

export default (state = buyRollUpReducerDefaultState, action) => {
  switch (action.type) {
    case "SET_ROLL_UP_DETAILS":
      return {
        ...state,
        rollUpData: action.payload
      };
    case "CLEAR_ROLL_UP_DETAILS":
      return {
        ...state,
        rollUpData: {}
      };
    case "SET_PROJECT_LIST":
      return {
        ...state,
        projectList: action.payload
      };
    case "SET_DOWNLOAD_DATA_FIRST":
      return {
        ...state,
        buyRollUpDataFirst: action.payload
      };
    // case "SET_DOWNLOAD_DATA_SECOND":
    //   return {
    //     ...state,
    //     buyRollUpDataSecond: action.payload
    //   };
    // case "SET_DOWNLOAD_DATA_THIRD":
    //   return {
    //     ...state,
    //     buyRollUpDataThird: action.payload
    //   };
    default:
      return state;
  }
};

const projectReducerDefaultState = {
  projectId: null,
  clusterMasterId: null,
  projectMainStage: 1,
  projectSubStage: 1,
  projectDetails: {},
  clusteringDetails: {},
  dashboardProjectList: {},
  lyFlag: true,
};

export default (state = projectReducerDefaultState, action) => {
  switch (action.type) {
    case "DASHBOARD_LIST_AFTER_DELETE":
      return {
        dashboardProjectList: action.payload
      };
    case "SET_PROJECT_ID":
      return {
        ...state,
        projectId: Number(action.payload)
      };
    case "SET_CLUSTER_MASTER_ID":
      return {
        ...state,
        clusterMasterId: Number(action.payload)
      };
    case "SET_PROJECT_STAGE":
      return {
        ...state,
        projectMainStage: Number(action.payload.split("-")[0]),
        projectSubStage: Number(action.payload.split("-")[1])
      };
    case "SET_PROJECT_DETAILS":
      return {
        ...state,
        projectDetails: action.payload
      };
      case "SET_SKIP_BUY_FLAG":
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          isBuyOptSkipped: action.payload
        }
      };
    case "SET_CLUSTER_DETAILS":
      return {
        ...state,
        clusteringDetails: action.payload
      };
    case "CLEAR_PROJECT_DATA":
      return {
        ...projectReducerDefaultState
      };
    case "SET_LY_FLAG": 
      return {
        ...state,
        lyFlag: action.payload
      }
    default:
      return state;
  }
};

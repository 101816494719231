import { dateFormatter } from "../../helpers/formatters";
function levelFormatter(val) {
  return val.data.is_season_level ? "Season" : "Month";
}
function clusterTypeFormatter(val) {
  return val.data.has_attribute_clustering ? "Attribute" : "Volumetric";
}
function otbFormatter(val) {
  return val.data.otb_flag ? "Y" : "N";
}
export default {
  defaultColDef: {
    resizable: true,
    animateRows: true,
    sortable: true,
    unSortIcon: true,
    menuTabs: []
  },
  animateRows: true,
  sortable: true,
  filter: true,
  unSortIcon: true,
  suppressCellSelection: true,
  cellClass: "no-border",
  rowSelection: "multiple",

  columnDefs: [
    {
      headerName: "ASSORTMENT PLAN NAME",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: { checkbox: true },
      width: 178,
      filter: "agTextColumnFilter",
      field: "name",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      selectable: false,
      pinned: "left",
      cellClassRules: "readonly-cell",
      suppressCellSelection: true,
      cellClass: "no-border",
      cellStyle: {
        cursor: "pointer",
        color: "#5285EB"
      }
      // cellRenderer: 'agGroupCellRenderer'
    },
    {
      width: 150,
      headerName: "DEPT NAME",

      filter: "agTextColumnFilter",
      valueGetter: function(params) {
        return params.data.isComp
          ? params.data.department_name
          : params.data.department_name_target;
      }
    },
    {
      headerName: "TIME PERIOD",
      filter: "agTextColumnFilter",
      field: "time_period"
    },
    {
      headerName: "CREATION DATE",
      width: 125,
      filter: "agTextColumnFilter",
      valueFormatter: dateFormatter,
      field: "created_at"
    },
    {
      width: 125,
      headerName: "LAST EDITED ON",
      valueFormatter: dateFormatter,
      filter: "agTextColumnFilter",
      field: "updated_at"
    },
    // {
    //   headerName: "Status",
    //   filter: "agTextColumnFilter",
    //   field: "allocation_status"
    // },
    {
      headerName: "CURRENT STAGE",
      width: 117,
      filter: "agTextColumnFilter",
      field: "project_stage"
    },
    {
      headerName: "OTB",
      width: 95,

      valueGetter: otbFormatter,
      filter: "agTextColumnFilter",
      field: "otb_flag"
    },
    {
      headerName: "LEVEL",
      width: 125,
      valueGetter: levelFormatter,
      filter: "agTextColumnFilter",
      field: "is_season_level"
    },
    {
      headerName: "CLUSTERING TYPE",
      width: 125,
      valueGetter: clusterTypeFormatter,
      filter: "agTextColumnFilter",
      field: "has_attribute_clustering"
    }
    // {
    //   width: 120,
    //   headerName: "USER NAME",
    //   filter: "agTextColumnFilter",
    //   field: "username"
    // }
    // {
    //   headerName: "Pending With",
    //   filter: "agTextColumnFilter",
    //   field: "pending_with"
    // },
    // {
    //   headerName: "Target Completion Date",
    //   filter: "agTextColumnFilter",
    //   field: "Target Completion Date"
    // },
    // {
    //   headerName: "Action",
    //   filter: "agTextColumnFilter",
    //   field: "Action"
    // }
  ]
};

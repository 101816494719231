import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { RoleDefinedNavLinks } from "../UserManagement/UserAccess";
class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      class: "",
      display: "none",
      showMinipopup: true,
      showClose: true,
      hideArrow: false,
      hindSightColor: false
    };
  }
  onClickShow = () => {
    this.setState({
      class: "",
      display: "",
      showClose: true,
      hideArrow: false
    });
  };
  MinipopupShow = () => {
    this.setState({
      showMinipopup: true
    });
  };
  hideMinipopup = () => {
    this.setState({
      class: "active",
      display: "inline-block",
      hideArrow: true,
      showClose: false
    });
  };
  //   onHover = () => {
  //     this.setState({
  //       class: "active",
  //       display: "inline-block"
  //     });
  //   };

  //   onLeaveHover = () => {
  //     this.setState({
  //       class: "",
  //       display: ""
  //     });
  //   };

  changeIconColor = () => {
    this.setState({
      hindSightColor: true
    });
  };

  resetIconColor = () => {
    this.setState({
      hindSightColor: false
    });
  };

  render() {
    return (
      <nav id="sidebar" className={this.state.class}>
        <div className="sidebar-header" />
        <ul className="list-unstyled components sideBarList1">
          <li
            onClick={() => {
              this.resetIconColor();
            }}
          >
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={
                <Tooltip id="right">
                  <strong>Dashboard</strong>
                </Tooltip>
              }
            >
              <NavLink
                to="/dashboard"
                exact
                strict
                style={{ color: "#e2e2e2" }}
                activeStyle={{ color: "#2D69EB" }}
              >
                {/* <a href="/dashboard"> */}
                <i className="fa fa-tachometer fa-4x" aria-hidden="true" />{" "}
                <span
                  className="hidetext fontstyling"
                  style={{ display: this.state.display }}
                >
                  Dashboard
                </span>
                {/* </a> */}
              </NavLink>
            </OverlayTrigger>
          </li>
          <li
            onClick={() => {
              this.changeIconColor();
            }}
          >
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={
                <Tooltip id="right">
                  <strong>Hindsight Dashboard</strong>
                </Tooltip>
              }
            >
              <NavLink
                to="/hindsightdashboard"
                exact
                strict
                style={{ color: "#e2e2e2" }}
                activeStyle={{ color: "#2D69EB" }}
              >
                {/* <a href="/dashboard"> */}
                {/* <img
                  style={{ height: "25px", cursor: "pointer" }}
                  src="/img/clustericon.ico"
                  alt="Logo of the company"
                /> */}
                {/* <i className="fab fa-hubspot f3b2" aria-hidden="true"></i> */}
                {/* <i className="fa fa-share-alt fa-4x" aria-hidden="true" />{" "} */}
                <img
                  style={{
                    width: "18px !important",
                    height: "16px",
                    background: this.state.hindSightColor
                      ? "#2D69EB"
                      : "#E2E2E2",
                    border: "4px",
                    marginBottom: "0.3rem",
                    marginLeft: "0.25rem"
                  }}
                  alt="hindsight"
                  src="/img/clusterIcon.PNG"
                />
                <span
                  className="hidetext fontstyling"
                  style={{ display: this.state.display }}
                >
                  Dashboard
                </span>
                {/* </a> */}
              </NavLink>
            </OverlayTrigger>
          </li>
          <li
            onClick={() => {
              this.resetIconColor();
            }}
          >
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={
                <Tooltip id="right">
                  <strong>User Management</strong>
                </Tooltip>
              }
            >
              <RoleDefinedNavLinks
                rolevalue="admin"
                to="/usermanagement"
                exact
                strict
                style={{ color: "#e2e2e2" }}
                activeStyle={{ color: "#2D69EB" }}
              >
                <i className="fa fa-users" aria-hidden="true" />{" "}
                <span
                  className="hidetext fontstyling"
                  style={{ display: this.state.display }}
                >
                  User Management
                </span>
              </RoleDefinedNavLinks>
            </OverlayTrigger>
          </li>
          <li
            onClick={() => {
              this.resetIconColor();
            }}
          >
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={
                <Tooltip id="right">
                  <strong>Buy Rollup</strong>
                </Tooltip>
              }
            >
              <NavLink
                to="/buyrollup"
                exact
                strict
                style={{ color: "#e2e2e2" }}
                activeStyle={{ color: "#2D69EB" }}
              >
                <i className="fa fa-money fa-4x" aria-hidden="true" />{" "}
                <span
                  className="hidetext fontstyling"
                  style={{ display: this.state.display }}
                >
                  Buy Rollup
                </span>
                {/* </a> */}
              </NavLink>
            </OverlayTrigger>
          </li>
          {/* <li onClick={() => this.props.clearData()}>
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={
                <Tooltip id="right">
                  <strong>Create Plan</strong>
                </Tooltip>
              }
            >
              <NavLink
                to="/stepperContainer"
                exact
                strict
                style={{ color: "#e2e2e2" }}
                activeStyle={{ color: "#2D69EB" }}
              >
                <i
                  className="fa fa-plus-square-o fa-4x"
                  aria-hidden="true"
                />{" "}
                <span
                  className="hidetext fontstyling"
                  style={{ display: this.state.display }}
                >
                  Create APT
                </span>
              </NavLink>
            </OverlayTrigger>
          </li> */}
          {/* <li>
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={
                <Tooltip id="right">
                  <strong>Reset Password</strong>
                </Tooltip>
              }
            >
              <NavLink
                to="/resetPassword"
                exact
                strict
                activeStyle={{ color: "#2D69EB" }}
              >
                <i className="fa fa-key fa-4x" aria-hidden="true" />{" "}
                <span
                  className="hidetext fontstyling"
                  style={{ display: this.state.display }}
                >
                  Reset Password
                </span>
              </NavLink>
            </OverlayTrigger>
          </li> */}
          <div className="arrowBottom">
            {this.state.hideArrow ? (
              <i
                className="fa fa-angle-left innerIcoTop"
                aria-hidden="true"
                onClick={e => this.onClickShow(e)}
              />
            ) : null}
            {this.state.showClose ? (
              <div>
                <i
                  className="fa fa-angle-right innerIcoTop2"
                  aria-hidden="true"
                  onClick={() => {
                    this.hideMinipopup();
                  }}
                />{" "}
              </div>
            ) : null}
          </div>
        </ul>
      </nav>
    );
  }
}

export default SideBar;

import Button from "@material-ui/core/Button";
import Tippy from "@tippy.js/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import AssortmentPlanningActions from "../../../actions/AssortmentPlanningActions";
import { toUnit } from "../../../helpers/formatters";
import DataTable from "../../../services/dataTable";
import Select from "../../../services/selectAll";
import CreatableSelect from "../../../services/customSelectAll";
import Alert from "../../../services/alert";
import Modal from "../../../services/modal";
import { greyCss, inputCss, tar } from "../../../styles";
import Apt_ReceiptPlan from "./columnDefs";
import { cloneDeep, differenceBy } from "lodash";
import { TimerSharp } from "@material-ui/icons";

let levelOptions = [
  { value: "department", label: "Department" },
  { value: "vendor", label: "Vendor" },
  { value: "stretch", label: "Stretch" },
  { value: "vengroup", label: "Vendor Group" }
];

let departmentOptions = [
  { value: "class", label: "Class" },
  { value: "vendor_class", label: "Vendor Class" }
];
let vendorOptions = [
  { value: "vendor", label: "Vendor" },
  { value: "vendor_class", label: "Vendor Class" }
];
let stretchOptions = [
  { value: "stretch", label: "Stretch" },
  { value: "vendor_class", label: "Vendor Class" }
];
let venGroupOptions = [{ value: "class", label: "Class" }];

class ReceiptPlanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      userRowData: [],
      // grandTotal: [],
      level: null,
      classLevel: null,
      venGroupLevel: "",
      addMappingModal: false,
      isDeleteMapping: false,
      compRetriveDisable: true,
      sourceInputList: cloneDeep(props.sourceMapping),
      targetInputList: cloneDeep(props.targetMapping),
      inputList: [
        {
          sourceValue: "",
          targetValue: ""
        }
      ]
    };
    this.gridApi = null;
    // this.redefineColDef(this.props.lineReceiptlevel);
  }
  componentDidMount() {
    if (this.props.lineReceiptData && this.props.lineReceiptData.length) {
      this.setTotalPlannedRcptData();
      // if (!Object.keys(this.props.lineReceiptGrandTotal).length) {
      //   this.props.setGrandAndSubclassTotal({});
      // } else {
      //   this.props.setGrandAndSubclassTotal(this.props.lineReceiptGrandTotal);
      // }
    }
    if (this.props.lineReceiptlevel && this.props.lineReceiptlevel.length) {
      let level = levelOptions.filter(
        levelOj => levelOj.value === this.props.lineReceiptHighlevel
      )[0];
      let classLevel;
      let venGroupLevel = {
        label: this.props.lineReceiptVenGrouplevel,
        value: this.props.lineReceiptVenGrouplevel
      };
      switch (this.props.lineReceiptHighlevel) {
        case "department":
          classLevel = departmentOptions.filter(
            levelOj => levelOj.value === this.props.lineReceiptlevel
          )[0];
          break;
        case "vendor":
          classLevel = vendorOptions.filter(
            levelOj => levelOj.value === this.props.lineReceiptlevel
          )[0];
          break;
        case "stretch":
          classLevel = stretchOptions.filter(
            levelOj => levelOj.value === this.props.lineReceiptlevel
          )[0];
          break;
        case "vengroup":
          classLevel = venGroupOptions.filter(
            levelOj => levelOj.value === this.props.lineReceiptlevel
          )[0];
          break;
        default:
          break;
      }
      this.setState({
        classLevel,
        level,
        venGroupLevel
      });
    }
  }
  componentDidUpdate(oldProps, oldState, snapshot) {
    if (
      this.props.lineReceiptData &&
      (oldProps.lineReceiptData !== this.props.lineReceiptData ||
        oldProps.userEditableLineReceipt !== this.props.userEditableLineReceipt)
    ) {
      this.setTotalPlannedRcptData();
    }
    if (oldState.classLevel !== this.state.classLevel) {
      this.state.classLevel &&
        this.state.classLevel.value &&
        this.props
          .getMappingForLineReceipt({
            projectId: this.props.match.params.id,
            level: this.state.classLevel.value
          })
          .then(() => {
            this.setInputLIst();
          });
    }
    if (oldProps.sourceMapping !== this.props.sourceMapping) {
      this.setState({
        sourceInputList: cloneDeep(this.props.sourceMapping)
      });
    }
  }

  setInputLIst = deleteMapping => {
    let targetVal = [];
    let sourceVal = [];
    if (deleteMapping) {
      targetVal = this.props.targetMapping;
      sourceVal = "";
    } else {
      targetVal =
        this.state.classLevel.value === this.props.nonCompLevel &&
        this.state.level.value === this.props.lineReceiptHighlevel
          ? this.props.savedTargetMapping
          : this.props.targetMapping;
      sourceVal =
        this.state.classLevel.value === this.props.nonCompLevel &&
        this.state.level.value === this.props.lineReceiptHighlevel
          ? this.props.savedSourceMapping
          : "";
    }
    let inputList = [];
    for (let i = 0; i < targetVal.length; i++) {
      inputList[i] = {
        sourceValue: typeof sourceVal === "string" ? "" : sourceVal[i],
        targetValue: targetVal[i]
      };
    }
    targetVal &&
      targetVal.length &&
      this.setState(
        {
          inputList: inputList
        },
        () => this.emptyTargetInputLIst()
      );
  };

  emptyTargetInputLIst = () => {
    let savedSourceList = this.props.savedSourceMapping;
    let sourceMapping = this.props.sourceMapping;
    let diffSource = differenceBy(sourceMapping, savedSourceList, "label");
    let savedTargetList = this.props.savedTargetMapping;
    let targetMapping = this.props.targetMapping;
    let diffTarget =
      savedTargetList && savedTargetList.length
        ? differenceBy(targetMapping, savedTargetList, "label")
        : [];
    this.setState({
      targetInputList: diffTarget,
      sourceInputList: this.props.clusteringInputFields.isComp
        ? sourceMapping
        : diffSource
    });
  };

  setTotalPlannedRcptData = () => {
    let tempRowData = [];
    // getting total of all rows of user editable data
    // let tempTotalUserData = this.getTotalDataObject(
    //   this.props.userEditableLineReceipt
    // );
    // Pushing tempTotalUserData to tempRowData
    // if (Object.keys(tempTotalUserData).length)
    //   tempRowData.push(tempTotalUserData);

    // Pushing remaining userEditableLineReceipt rows to tempRowData
    tempRowData.push(...this.props.userEditableLineReceipt);
    this.setState({
      userRowData: tempRowData,
      rowData: this.props.lineReceiptData
    });
  };
  onRetrieveSetUserEditableRowData = userEditableData => {
    this.props.setUELineReceiptOnCellValueChanged(
      this.getUserEditableData(userEditableData)
    );
    this.setTotalPlannedRcptData();
    this.props.setGrandAndSubclassTotal({});
  };
  // setGrandAndSubclassTotal = lineReceiptGrandTotal => {
  //   let subTotalClass = this.getTotalDataObject(this.props.lineReceiptData);

  //   // Passing true flag in getTotalData function call for chchecking Ecomm and REPLENISHMENT condition
  //   if (
  //     this.props.lineReceiptGrandTotal &&
  //     Object.keys(this.props.lineReceiptGrandTotal).length
  //   ) {
  //     this.setState({
  //       grandTotal: [this.props.lineReceiptGrandTotal, subTotalClass]
  //     });
  //   } else {
  //     let finalObj = {};
  //     let subTotalUserData = this.getTotalDataObject(
  //       this.props.userEditableLineReceipt,
  //       true
  //     );
  //     let tempKeyArr = Object.keys(subTotalClass);
  //     tempKeyArr.forEach(ele => {
  //       if (
  //         (!this.props.projectDetails.ecomm_f && ele !== "Financial_year") ||
  //         ele !== this.props.lineReceiptlevel
  //       ) {
  //         finalObj[ele] = subTotalClass[ele] - subTotalUserData[ele];
  //       }
  //     });
  //     finalObj[this.props.lineReceiptlevel] = "GRAND TOTAL";
  //     this.setState({
  //       grandTotal: [finalObj, subTotalClass]
  //     });
  //     this.props.setLineReceiptGrandTotal(finalObj);
  //   }
  // };
  // setSubclassTotal = () => {
  //   let [grandTotal, subclassTotal] = cloneDeep(this.state.grandTotal);
  //   subclassTotal = this.getTotalDataObject(this.props.lineReceiptData);
  //   this.setState({ grandTotal: [grandTotal, subclassTotal] });
  // };
  getUserEditableData = data => {
    let rcptHeldAttributes = [
      "DROP SHIP",
      "RESERVE",
      "REPLENISHMENT",
      "OTHERS"
    ];
    let lineRcptKeys = Object.keys(this.props.lineReceiptData[0]);
    let userEditableData = [];

    rcptHeldAttributes.forEach(attr => {
      let userEditableObj = {};
      lineRcptKeys.forEach(key => {
        if (key !== "Financial_year" || key !== this.props.lineReceiptlevel) {
          userEditableObj[key] = null;
        }
      });
      //checking Replenishment condition
      if (data && data[0][this.props.lineReceiptlevel] === attr) {
        userEditableObj = { ...data[0] };
      } else {
        userEditableObj[this.props.lineReceiptlevel] = attr;
      }
      userEditableData.push(userEditableObj);
    });
    return userEditableData;
  };
  getTotalDataObject = (data, isFinalTotal) => {
    let tempObj = {};
    if (data.length) {
      let tempKeyArr = Object.keys(data[0]);
      tempKeyArr.forEach(ele => {
        if (ele !== "Financial_year" || ele !== this.props.lineReceiptlevel) {
          tempObj[ele] = 0;
        }
      });
      data.forEach(ele => {
        if (ele)
          for (let key in tempObj) {
            if (
              // Check the condition if this function is getting call for getting total of user data
              isFinalTotal ||
              // && ele[this.props.lineReceiptlevel] !== "ECOMMERCE" &&
              // ele[this.props.lineReceiptlevel] !== "REPLENISHMENT"
              (ele[this.props.lineReceiptlevel] === "OTHERS" &&
                this.props.projectDetails.ecomm_f) || //removed ecomm_f flag condition for now as per requirement explained by akshay
              (ele[this.props.lineReceiptlevel] === "REPLENISHMENT" &&
                this.props.projectDetails.rpl_f) //removed rpl_f flag condition for now as per requirement explained by akshay
            ) {
              // Adding if and only if:
              // 1. isFinalTotal = true &&
              // 2. in REPLENISHMENT case REPLENISHMENT should not  be included &&
              // 3. In Ecommerce case  ECOMMERCE should be excluded
              tempObj[key] += +ele[key];
            } else if (!isFinalTotal) {
              // Adding if and only if isFinalTotal flag is false
              tempObj[key] += +ele[key];
            }
          }
      });
      tempObj[this.props.lineReceiptlevel] =
        tempObj[this.props.lineReceiptlevel] ===
        "0DROP SHIPRESERVEREPLENISHMENTOTHERS"
          ? "TOTAL RECEIPTS HELD"
          : "TOTAL RECEIPTS PLANNED";
    }
    return tempObj;
  };
  onRetriveReceiptPlan = () => {
    // this.redefineColDef(this.props.lineReceiptlevel);
    this.props
      .retriveLineReceiptPlanData({
        projectId: this.props.match.params.id,
        highLevel: this.state.level.value,
        level: this.state.classLevel.value,
        venGroup: this.state.venGroupLevel.value
      })
      .then(resp => {
        const userEditableData = resp.data.levelData.userEditableData;
        if (resp.success) {
          if (userEditableData.length) {
            // creating userEditable rows and by default we need to show Replenishment budget data
            this.onRetrieveSetUserEditableRowData(userEditableData);
          } else {
            //creating userEditable rows
            this.onRetrieveSetUserEditableRowData();
          }
        }
      })
      .catch(err => {
        toast.error(err.message, { autoClose: false });
      });
    this.props.setLineReceiptLevel(this.state.classLevel.value);
    this.props.setLineReceiptHighLevel(this.state.level.value);
    this.props.setLineReceiptVenGroupLevel(this.state.venGroupLevel.value);
  };

  onAddMapping = () => {
    this.setState({
      addMappingModal: true
    });
  };

  onDeleteMapping = () => {
    this.setState({
      isDeleteMapping: true
    });
  };

  handleRemoveClick = index => {
    const list = [...this.state.inputList];

    const sourceList = [...this.state.sourceInputList];
    let souceItem = [];
    if (!this.props.clusteringInputFields.isComp) {
      souceItem = list[index].sourceValue;
      sourceList.splice(index, 0, souceItem);
    }

    const targetList = [...this.state.targetInputList];
    let targetItem = list[index].targetValue;
    targetList.splice(index, 0, targetItem);

    souceItem &&
      this.setState({
        sourceInputList: sourceList
      });

    targetItem &&
      this.setState({
        targetInputList: targetList
      });

    list.splice(index, 1);
    this.setState({
      inputList: list
    });
  };

  handleAddClick = () => {
    this.setState(prevState => ({
      inputList: [...prevState.inputList, { sourceValue: "", targetValue: "" }]
    }));
  };

  onSendMapping = () => {
    let sourceVal = this.state.inputList.map(el => el["sourceValue"].label);
    let targetVal = this.state.inputList.map(el =>
      el["targetValue"].label ? el["targetValue"].label : el["targetValue"]
    );
    this.props
      .saveMappingForLineReceipt({
        projectId: this.props.match.params.id,
        level: this.state.classLevel.value,
        source: sourceVal,
        target: targetVal
      })
      .then(() => {
        this.setState({
          compRetriveDisable: false
        });
      });
    this.setState({
      addMappingModal: false
    });
  };

  onSelectTargetMapping = (val, index) => {
    const list = [...this.state.inputList];
    let isDuplicate = list.map(el => el.targetValue.label);
    if (!isDuplicate.includes(val)) {
      let targetItem = list[index]["targetValue"];
      list[index]["targetValue"] = val;
      this.setState({
        inputList: list
      });
      const targetList = [...this.state.targetInputList];
      const targetIndex = targetList.indexOf(val);
      if (index > -1) {
        targetIndex >= 0 && targetList.splice(targetIndex, 1);
        targetItem &&
          targetItem.length &&
          targetList.splice(index, 0, targetItem);
      }
      this.setState({
        targetInputList: targetList
      });
    }
  };

  onSelectTargetMappingInputTag = (e, index) => {
    const list = [...this.state.inputList];
    list[index]["targetValue"] = e.target.value.toUpperCase();
    this.setState({
      inputList: list
    });
  };

  onSelectSourceMapping = (val, index) => {
    const list = [...this.state.inputList];
    let sourceItem = list[index]["sourceValue"];
    list[index]["sourceValue"] = val;
    this.setState({
      inputList: list
    });
    const sourceList = [...this.state.sourceInputList];
    const sourceIndex = sourceList.indexOf(val);
    if (index > -1 && !this.props.clusteringInputFields.isComp) {
      sourceList.splice(sourceIndex, 1);
      sourceItem && sourceList.splice(index, 0, sourceItem);
    }
    this.setState({
      sourceInputList: sourceList
    });
  };

  checkMappingDisable = () => {
    let sourceVal = this.state.inputList.map(el =>
      el["sourceValue"].value ? el["sourceValue"].value : el["sourceValue"]
    );
    let targetVal = this.state.inputList.map(el =>
      el["targetValue"].value ? el["targetValue"].value : el["targetValue"]
    );
    let isSourceEmpty = sourceVal.filter(el => el === undefined || el === "");
    let isTargetEmpty = targetVal.filter(el => el === undefined || el === "");
    if (isSourceEmpty.length === 0 && isTargetEmpty.length === 0) {
      return 0;
    } else return 1;
  };

  deleteAllMapping = () => {
    let val = cloneDeep(this.state.inputList);

    const sourceList = [...this.state.sourceInputList];
    const targetList = [...this.state.targetInputList];

    let removedVal = val.filter((ele, index) => {
      return index > 0;
    });

    let sourceVal = removedVal.map(el => el.sourceValue);
    sourceVal = sourceVal.filter(item => item);
    let targetVal = removedVal.map(el => el.targetValue);
    targetVal = targetVal.filter(item => item);

    sourceVal &&
      !this.props.clusteringInputFields.isComp &&
      this.setState({
        sourceInputList: [...sourceList, ...sourceVal]
      });

    targetVal &&
      this.setState({
        targetInputList: [...targetList, ...targetVal]
      });

    val.splice(1);

    this.setState({
      inputList: val
    });
  };

  sum(values) {
    let sum = 0;
    values.forEach(e => {
      sum = sum + parseFloat(e);
    });
    return sum;
  }
  redefineColDef = (obj, isRecieptPlan) => {
    this.gridApi && this.gridApi.redrawRows();
    let unOrderedMonths = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    let tempColDef_dummy = [];
    if (this.props.rcptMonths) {
      tempColDef_dummy.push({
        headerName: "CLASS/VENDOR",
        width: isRecieptPlan ? 200 : 440,
        filter: "agTextColumnFilter",
        sortable: true,
        unSortIcon: true,
        field: "class_vendor",
        pinned: "left",
        cellStyle: params => {
          if (
            params.data[this.props.lineReceiptlevel] ===
              "TOTAL RECEIPTS HELD" ||
            params.data[this.props.lineReceiptlevel] ===
              "TOTAL RECEIPTS PLANNED"
          ) {
            return {
              "background-color": "#d7d7db",
              "text-align": "left"
            };
          } else {
            return {
              "text-align": "left"
            };
          }
        }
      });
      isRecieptPlan &&
        tempColDef_dummy.push({
          headerName: this.props.lyFlag
            ? "RCPT PLAN LY($)"
            : "RCPT PLAN LLY($)",
          sortable: true,
          unSortIcon: true,
          valueGetter: params => {
            let rcptMonths = this.props.rcptMonths;
            let val = 0;
            for (const [key, value] of Object.entries(params.data)) {
              if (
                rcptMonths.indexOf(key.split(" ")[0]) > -1 &&
                (key.split(" ")[2] === "LY" || key.split(" ")[2] === "LLY")
              ) {
                val = val + value;
              }
            }
            return val;
          },
          valueFormatter: toUnit,
          aggFunc: this.sum,
          pinned: "left",
          width: 120,
          cellStyle: params => {
            if (
              params.data[this.props.lineReceiptlevel] ===
                "TOTAL RECEIPTS HELD" ||
              params.data[this.props.lineReceiptlevel] ===
                "TOTAL RECEIPTS PLANNED"
            ) {
              return greyCss;
            }
            return {
              "text-align": "right"
            };
          }
        });
      isRecieptPlan &&
        tempColDef_dummy.push({
          headerName: "RCPT PLAN TY($)",
          sortable: true,
          unSortIcon: true,
          valueGetter: params => {
            let rcptMonths = this.props.rcptMonths;
            let val = 0;
            for (const [key, value] of Object.entries(params.data)) {
              if (
                rcptMonths.indexOf(key.split(" ")[0]) > -1 &&
                key.split(" ")[2] === "TY"
              ) {
                val = val + value;
              }
            }
            return val;
          },
          valueFormatter: toUnit,
          aggFunc: this.sum,
          pinned: "left",
          width: 120,
          cellStyle: params => {
            if (
              params.data[this.props.lineReceiptlevel] ===
                "TOTAL RECEIPTS HELD" ||
              params.data[this.props.lineReceiptlevel] ===
                "TOTAL RECEIPTS PLANNED"
            ) {
              return greyCss;
            } else
              return {
                "text-align": "right"
              };
          }
        });
      let tempStartIndex = unOrderedMonths.indexOf(this.props.rcptMonths[0]);
      for (var i = 0; i < unOrderedMonths.length - tempStartIndex; i++) {
        unOrderedMonths.unshift(unOrderedMonths.pop());
      }
      if (
        this.props.lineReceiptData &&
        this.props.lineReceiptData.length &&
        this.props.grandTotal &&
        this.props.grandTotal.length
      ) {
        let dummyObj = this.props.lineReceiptData[0];
        let dummyMonthKey = Object.keys(dummyObj);
        unOrderedMonths.forEach(month => {
          ["LY", "TY"].forEach(lyOrTy => {
            let ele;
            for (let i = 0; i < dummyMonthKey.length; i++) {
              const element = dummyMonthKey[i];
              if (element.startsWith(month) && element.endsWith(lyOrTy)) {
                ele = element;
                break;
              }
            }
            let dummyMonthObj = {};
            let flag =
              ele.substring(9, 11) === "TY" &&
              this.props.rcptMonths.indexOf(month) >= 0
                ? true
                : false;
            dummyMonthObj.headerName = this.getMonthHeader(ele, month);
            dummyMonthObj.field = ele;
            dummyMonthObj.aggFunc = this.sum;
            dummyMonthObj.valueFormatter = toUnit;
            dummyMonthObj.width = 90;
            isRecieptPlan &&
              (dummyMonthObj.editable = params => {
                //disabling user editable rows when the month level class budget is <= zero.
                if (this.props.grandTotal[0][ele] <= 0) {
                  const editableFlagForRows =
                    flag &&
                    params.data[this.props.lineReceiptlevel] !==
                      "GRAND TOTAL" &&
                    params.data[this.props.lineReceiptlevel] !==
                      "TOTAL RECEIPTS HELD" &&
                    params.data[this.props.lineReceiptlevel] !==
                      "TOTAL RECEIPTS PLANNED" &&
                    params.data[this.props.lineReceiptlevel] !== "DROP SHIP" &&
                    params.data[this.props.lineReceiptlevel] !== "RESERVE" &&
                    params.data[this.props.lineReceiptlevel] !==
                      "REPLENISHMENT" &&
                    params.data[this.props.lineReceiptlevel] !== "OTHERS";
                  params["customEditable"] =
                    !this.props.userAccessDisableFlag && editableFlagForRows;
                  return (
                    !this.props.userAccessDisableFlag && editableFlagForRows
                  );
                } else {
                  const editableFlagForRows =
                    flag &&
                    params.data[this.props.lineReceiptlevel] !==
                      "GRAND TOTAL" &&
                    params.data[this.props.lineReceiptlevel] !==
                      "TOTAL RECEIPTS HELD" &&
                    params.data[this.props.lineReceiptlevel] !==
                      "TOTAL RECEIPTS PLANNED";
                  params["customEditable"] =
                    !this.props.userAccessDisableFlag && editableFlagForRows;
                  return (
                    !this.props.userAccessDisableFlag && editableFlagForRows
                  );
                }
              });

            // dummyMonthObj.valueSetter = function(params) {
            //   params.data[ele] = Number(params.newValue);
            // };
            dummyMonthObj.cellEditor = "numericCellEditor";
            isRecieptPlan
              ? (dummyMonthObj.cellStyle = params => {
                  if (this.props.grandTotal[0][ele] <= 0) {
                    if (
                      params.data &&
                      params.colDef.field in params.data &&
                      flag &&
                      params.data[this.props.lineReceiptlevel] !==
                        "GRAND TOTAL" &&
                      params.data[this.props.lineReceiptlevel] !==
                        "TOTAL RECEIPTS HELD" &&
                      params.data[this.props.lineReceiptlevel] !==
                        "TOTAL RECEIPTS PLANNED" &&
                      params.data[this.props.lineReceiptlevel] !==
                        "DROP SHIP" &&
                      params.data[this.props.lineReceiptlevel] !== "RESERVE" &&
                      params.data[this.props.lineReceiptlevel] !==
                        "REPLENISHMENT" &&
                      params.data[this.props.lineReceiptlevel] !== "OTHERS"
                    ) {
                      return inputCss;
                    } else if (
                      params.data[this.props.lineReceiptlevel] ===
                        "TOTAL RECEIPTS HELD" ||
                      params.data[this.props.lineReceiptlevel] ===
                        "TOTAL RECEIPTS PLANNED" ||
                      params.data[this.props.lineReceiptlevel] ===
                        "DROP SHIP" ||
                      params.data[this.props.lineReceiptlevel] === "RESERVE" ||
                      params.data[this.props.lineReceiptlevel] ===
                        "REPLENISHMENT" ||
                      params.data[this.props.lineReceiptlevel] === "OTHERS"
                    ) {
                      return greyCss;
                    } else {
                      return tar;
                    }
                  } else {
                    if (
                      params.data &&
                      params.colDef.field in params.data &&
                      flag &&
                      params.data[this.props.lineReceiptlevel] !==
                        "GRAND TOTAL" &&
                      params.data[this.props.lineReceiptlevel] !==
                        "TOTAL RECEIPTS HELD" &&
                      params.data[this.props.lineReceiptlevel] !==
                        "TOTAL RECEIPTS PLANNED"
                    ) {
                      return inputCss;
                    } else if (
                      params.data[this.props.lineReceiptlevel] ===
                        "TOTAL RECEIPTS HELD" ||
                      params.data[this.props.lineReceiptlevel] ===
                        "TOTAL RECEIPTS PLANNED"
                    ) {
                      return greyCss;
                    } else {
                      return tar;
                    }
                  }
                })
              : (dummyMonthObj.cellStyle = params => {
                  let year =
                    params.colDef.field && params.colDef.field.split(" ")[2];
                  if (
                    this.props.lineReceiptlevel !== "vendor" &&
                    this.props.lineReceiptlevel !== "stretch"
                  ) {
                    if (
                      parseInt(
                        parseFloat(params.value)
                          .toString()
                          .split(".")[0],
                        10
                      ) <
                        parseInt(
                          parseFloat(
                            this.props.grandTotal[0][params.colDef.field]
                          )
                            .toString()
                            .split(".")[0],
                          10
                        ) &&
                      year !== "LY"
                    ) {
                      return {
                        "background-color": "#f28e83",
                        ...tar
                      };
                    } else {
                      return tar;
                    }
                  } else {
                    let tempCol = false;
                    this.props.lineReceiptData.forEach(obj => {
                      if (
                        obj.vendor === params.data.vendor &&
                        obj.stretch === params.data.stretch
                      ) {
                        if (
                          parseInt(
                            parseFloat(params.value)
                              .toString()
                              .split(".")[0],
                            10
                          ) <
                            parseInt(
                              parseFloat(obj[params.colDef.field])
                                .toString()
                                .split(".")[0],
                              10
                            ) &&
                          year !== "LY"
                        ) {
                          tempCol = true;
                        }
                      }
                    });
                    if (tempCol) {
                      return {
                        "background-color": "#f28e83",
                        ...tar
                      };
                    } else {
                      return tar;
                    }
                  }
                });
            tempColDef_dummy.push(dummyMonthObj);
          });
        });
      }

      tempColDef_dummy.forEach(ele => {
        if (obj && Object.keys(obj).length && ele.field === "class_vendor") {
          if (!isRecieptPlan) {
            obj = this.props.lineReceiptHighlevel;
          }
          // if (!isRecieptPlan && (obj === "class" || obj === "vendor_class")) {
          //   obj = "department";
          // }
          let tempObj = {
            class: "CLASS",
            vendor: "VENDOR",
            vendor_class: "VENDOR CLASS",
            department: "DEPARTMENT"
          };
          ele.headerName = tempObj[obj];
          ele.field = obj;
          ele.tooltipField = obj;
        }
      });
    }
    return tempColDef_dummy;
  };
  getMonthHeader = (header, month) => {
    let year = header.slice(6, 8);
    if (month !== "Jan") {
      year = Number(year);
    }
    let lastOrThisYear = header.slice(9, 11);
    if (lastOrThisYear === "LY" && !this.props.lyFlag) {
      lastOrThisYear = "LLY";
    }
    return `${month} FY ${year} ${lastOrThisYear}($)`;
  };
  onSelectLevel = val => {
    this.setState({ level: val, classLevel: null, venGroupLevel: "" });
    // this.props.setLineReceiptLevel(val && val.value);

    if (val && val.value === "vengroup") {
      let deptVal = this.props.clusteringInputFields.isComp
        ? this.props.selectedFilters.departments[0].value
        : this.props.selectedFiltersTarget.departments &&
          this.props.selectedFiltersTarget.departments[0].value;
      this.props
        .getVenGroupOptions({
          projectId: this.props.match.params.id,
          departmentId: [deptVal]
        })
        .then(resp => {
          if (
            !(
              resp &&
              resp.data &&
              resp.data.venGroupData &&
              resp.data.venGroupData.length
            )
          ) {
            toast.error(
              "No ven group available for selected Department kindly select other level to continue",
              { autoClose: false }
            );
          }
        });
    }
  };
  onSelectVenGroupLevel = val => {
    this.setState({ venGroupLevel: val });
  };
  onSelectClassLevel = val => {
    this.setState(
      {
        classLevel: val,
        compRetriveDisable: true,
        inputList: [
          {
            sourceValue: "",
            targetValue: ""
          }
        ]
      },
      () => this.getMapping()
    );
  };

  getMapping = () => {
    this.state.classLevel &&
      this.state.classLevel.value &&
      this.props.getMappingForLineReceipt({
        projectId: this.props.match.params.id,
        level: this.state.classLevel.value
      });
  };

  classLevelProportion = (lineReceiptData, rowObject) => {
    // sum of receipts of all classes in month level
    let sumOfReceipt = lineReceiptData.reduce(
      (acc, cur) => acc + cur[rowObject.colDef.field],
      0
    );

    // proportion calculations
    let proportionCalculation = lineReceiptData.map(lineReceipt => {
      let tempOldValue = lineReceipt[rowObject.colDef.field]
        ? Number(lineReceipt[rowObject.colDef.field])
        : 0;
      let proportion =
        (lineReceipt[rowObject.colDef.field] / sumOfReceipt) *
        rowObject.newValue;
      lineReceipt[rowObject.colDef.field] =
        lineReceipt[rowObject.colDef.field] - proportion;

      // Updating TY Total value on proportion changed class/vendor/vendor_class values
      if (lineReceipt[rowObject.colDef.field] && proportion) {
        lineReceipt.TYTotal +=
          Number(lineReceipt[rowObject.colDef.field]) - tempOldValue;
      }
      return lineReceipt;
    });
    return proportionCalculation;
  };
  onCellValueChanged = val => {
    if (Number(val.oldValue) !== Number(val.newValue)) {
      val.newValue = +val.newValue;
      let selectedClassType = val.data[this.props.lineReceiptlevel];
      val.data[val.colDef.field] = val.newValue;
      const columnId = val.colDef.field;
      const { lineReceiptData, userEditableLineReceipt } = this.props;
      if (
        (val.data[this.props.lineReceiptlevel] === "DROP SHIP" ||
          val.data[this.props.lineReceiptlevel] === "RESERVE" ||
          val.data[this.props.lineReceiptlevel] === "OTHERS" ||
          val.data[this.props.lineReceiptlevel] === "REPLENISHMENT") &&
        +val.newValue >= +this.props.grandTotal[0][columnId]
      ) {
        userEditableLineReceipt.forEach(classRcpt => {
          if (selectedClassType === classRcpt[this.props.lineReceiptlevel]) {
            classRcpt[columnId] = val.oldValue;
          }
        });
        val.data[val.colDef.field] = 0;
        val.newValue = 0;
        val.data.TYTotal = 0;
      } else {
        let tempOldValue = val.oldValue ? Number(val.oldValue) : 0;
        val.data[val.colDef.field] = Number(val.newValue);
        val.data.TYTotal += Number(val.newValue) - tempOldValue;
      }
      lineReceiptData.forEach(classRcpt => {
        const keys = Object.keys(classRcpt);
        keys.forEach(key => {
          if (key !== this.props.lineReceiptlevel) {
            classRcpt[key] = +classRcpt[key];
          }
        });
      });
      if (val.rowPinned) {
        userEditableLineReceipt.forEach(classRcpt => {
          const keys = Object.keys(classRcpt);
          keys.forEach(key => {
            if (key !== this.props.lineReceiptlevel) {
              classRcpt[key] = +classRcpt[key];
            }
          });
        });
        // userEditableLineReceipt[val.rowIndex - 1] = val.data;
        this.props.setUELineReceiptOnCellValueChanged(userEditableLineReceipt);
        this.props.setLineReceiptOnCellValueChanged(
          this.classLevelProportion(lineReceiptData, val)
        );
      } else {
        this.props.setLineReceiptOnCellValueChanged(lineReceiptData);
      }

      this.setTotalPlannedRcptData();
      this.props.setSubclassTotal();
      // this.getGrandTotal();
      val.api.refreshCells();
      this.gridApi.redrawRows();
      val.api.refreshClientSideRowModel("aggregate");
    }
  };
  getRowData = () => {
    if (this.state.rowData.length) {
      return this.state.rowData;
    } else {
      return [];
    }
  };
  disableButton = () => {
    let { level, classLevel } = this.state;
    if (level && level.value) {
      if (level && classLevel) {
        return 0;
      } else return 1;
    } else {
      if (level) {
        return 0;
      } else return 1;
    }
  };

  disableButtonMapping = () => {
    let { level, classLevel } = this.state;
    if (level && level.value) {
      if (level && classLevel) {
        return 0;
      } else return 1;
    } else {
      if (level) {
        return 0;
      } else return 1;
    }
  };

  handleAccept = () => {
    this.props
      .deleteMappingForLineReceipt({
        projectId: this.props.match.params.id,
        projectStage: `${this.props.projectMainStage}-${this.props.projectSubStage}`
      })
      .then(resp => this.setInputLIst("deleteMapping"));
    this.setState({
      isDeleteMapping: false
    });
  };

  disableDeleteMapping = () => {
    let { savedSourceMapping, savedTargetMapping } = this.props;
    if (this.props.deleteMapping) {
      return 1;
    }
    if (!this.state.compRetriveDisable) {
      return 0;
    }
    if (
      savedSourceMapping.length &&
      savedTargetMapping.length &&
      this.state.classLevel &&
      this.state.classLevel.value === this.props.lineReceiptlevel &&
      this.state.level &&
      this.state.level.value === this.props.lineReceiptHighlevel
    ) {
      return 0;
    } else {
      return 1;
    }
  };

  render() {
    let { classLevel, level, venGroupLevel } = this.state;
    let levelTwoOptions = [];
    if (level) {
      switch (level.value) {
        case "department":
          levelTwoOptions = departmentOptions;
          break;
        case "vendor":
          levelTwoOptions = vendorOptions;
          break;
        case "stretch":
          levelTwoOptions = stretchOptions;
          break;
        case "vengroup":
          levelTwoOptions = venGroupOptions;
          break;

        default:
          break;
      }
    }
    return (
      <div className="card" style={{ margin: "0.5rem 0 1rem 0" }}>
        {/* <SidePopup /> */}
        <div className="card-body">
          <div className="row p-2">
            <div className="col-md-2">
              <label htmlFor="inputPassword4">Select Level *</label>
              <Select
                placeholder="Select Level"
                value={this.state.level}
                options={levelOptions}
                isClearable
                isDisabled={this.props.userAccessDisableFlag}
                onChange={val => this.onSelectLevel(val)}
                isOptionDisabled={option => option.isdisabled}
              />
            </div>
            {this.state.level && this.state.level.value === "vengroup" ? (
              <div className="col-md-2">
                <label htmlFor="inputPassword4">Select Vendor Group *</label>
                <Select
                  placeholder="Select"
                  value={venGroupLevel}
                  options={this.props.venGroupOptions || []}
                  isClearable
                  isDisabled={
                    this.props.userAccessDisableFlag || !this.state.level
                  }
                  onChange={val => this.onSelectVenGroupLevel(val)}
                />
              </div>
            ) : (
              ""
            )}
            <div className="col-md-2">
              <label htmlFor="inputPassword4">Select *</label>
              <Select
                placeholder="Select"
                value={classLevel}
                options={levelTwoOptions}
                isClearable
                isDisabled={
                  this.props.userAccessDisableFlag ||
                  !this.state.level ||
                  (this.state.level && this.state.level.value === "vengroup"
                    ? !this.state.venGroupLevel
                    : false)
                }
                onChange={val => this.onSelectClassLevel(val)}
              />
            </div>
            <div
              align="right"
              className={
                "mt-4 col-md-2 " +
                (this.state.level && this.state.level.value === "vengroup"
                  ? "offset-md-0"
                  : this.props.clusteringInputFields.isComp
                  ? "offset-md-2"
                  : "offset-md-4")
              }
            >
              <Button
                onClick={
                  this.state.level && this.state.classLevel && this.onAddMapping
                }
                style={{ textTransform: "capitalize" }}
                disabled={this.disableButtonMapping()}
              >
                Add Mapping
              </Button>
            </div>
            {this.props.clusteringInputFields.isComp ? (
              <div align="right" className={"mt-4 col-md-2"}>
                <Button
                  onClick={
                    this.state.level &&
                    this.state.classLevel &&
                    this.onDeleteMapping
                  }
                  style={{ textTransform: "capitalize" }}
                  disabled={this.disableDeleteMapping()}
                >
                  Delete Mapping
                </Button>
              </div>
            ) : (
              ""
            )}
            <div align="right" className="mt-4 col-md-2 ">
              <Button
                onClick={
                  this.state.level &&
                  this.state.classLevel &&
                  this.onRetriveReceiptPlan
                }
                style={{ textTransform: "capitalize" }}
                disabled={
                  this.props.clusteringInputFields.isComp
                    ? this.disableButton()
                    : this.state.compRetriveDisable
                }
              >
                Retrieve Receipt Plan
              </Button>
            </div>
          </div>
          <div className="mt-3">
            <div className="Step3VolumeGroups-container-chartjs">
              <strong>Planned Budget</strong>
            </div>
            <div
              style={{
                height:
                  this.props.plannedBudget &&
                  this.props.plannedBudget.length === 1
                    ? "90px"
                    : "300px"
              }}
            >
              <DataTable
                defaultExpand={true}
                defaultColDef={Apt_ReceiptPlan.defaultColDef}
                // floatingFilter={true}
                suppressColumnVirtualisation={true}
                passGridAPI={api => {
                  this.gridApi = api;
                }}
                columnDefs={
                  this.props.lineReceiptData &&
                  this.props.lineReceiptData.length
                    ? this.redefineColDef(this.props.lineReceiptlevel, false)
                    : []
                }
                rowData={this.props.plannedBudget || []}
              />
            </div>
          </div>
          <div className="mt-3">
            <div className="mb-4" style={{ position: "relative" }}>
              <div className="Step3VolumeGroups-container-chartjs">
                <strong>Gross Retail Receipt Plan</strong>
                <Tippy
                  placement={"left"}
                  arrow={true}
                  distance={10}
                  content="Below section history includes Replenishment receipts"
                >
                  <i
                    style={{
                      color: "#2c6ae8",
                      fontSize: "16px"
                    }}
                    className="fa fa-info-circle"
                    aria-hidden="true"
                  />
                </Tippy>
              </div>
              <div style={{ position: "absolute", right: 0, top: 0 }}>
                <Button
                  onClick={this.props.runStoreDownload}
                  style={{ textTransform: "capitalize" }}
                  disabled={
                    !(
                      this.props.lineReceiptData &&
                      this.props.lineReceiptData.length
                    ) || this.props.isClusteringSkipped
                  }
                >
                  Download Store Receipt
                </Button>
              </div>
            </div>
            <div style={{ height: "calc(100vh - 340px)", minHeight: "340px" }}>
              <DataTable
                defaultExpand={true}
                defaultColDef={Apt_ReceiptPlan.defaultColDef}
                floatingFilter={true}
                suppressColumnVirtualisation={true}
                columnDefs={
                  this.props.lineReceiptData &&
                  this.props.lineReceiptData.length
                    ? this.redefineColDef(this.props.lineReceiptlevel, true)
                    : []
                }
                rowData={this.state.rowData}
                onCellValueChanged={this.onCellValueChanged}
                pinnedBottomRowData={this.state.userRowData}
                pinnedTopRowData={this.props.grandTotal}
              />
            </div>
          </div>
        </div>
        <Modal
          open={this.state.addMappingModal}
          onClose={() => {
            this.setState({ addMappingModal: false });
          }}
          fullWidth={true}
          maxWidth="md"
          title="Mapping"
          className="mapping-non-comp"
        >
          <div style={{ minHeight: "40vh" }}>
            {!this.props.clusteringInputFields.isComp ? (
              <div>
                <div className="col-md-4">
                  <div style={{ fontWeight: "bold" }}>Target Department</div>
                  <div style={{ fontWeight: "bold" }}>
                    {this.props.selectedFiltersTarget &&
                      this.props.selectedFiltersTarget.departments &&
                      this.props.selectedFiltersTarget.departments[0] &&
                      this.props.selectedFiltersTarget.departments[0].label}
                  </div>
                </div>
                <div className="col-md-4">
                  <div style={{ fontWeight: "bold" }}>Source Department</div>
                  <div style={{ fontWeight: "bold" }}>
                    {this.props.selectedFilters &&
                      this.props.selectedFilters.departments &&
                      this.props.selectedFilters.departments[0] &&
                      this.props.selectedFilters.departments[0].label}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div
              style={{
                width: "72%",
                textAlign: "end",
                marginBottom: "-1.75rem"
              }}
            >
              <Tippy
                placement={"left"}
                arrow={true}
                distance={10}
                content={<div>Delete All except the first one</div>}
              >
                <i
                  className="faactions fa fa-trash p-2 pointer"
                  aria-hidden="true"
                  onClick={() => this.deleteAllMapping()}
                  style={{
                    color: "blue",
                    pointerEvents: this.props.userAccessDisableFlag && "none"
                  }}
                />
              </Tippy>
            </div>
            <div style={{ marginTop: "4rem" }}>
              {this.state.inputList.map((x, i) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "1.5rem"
                    }}
                  >
                    <div className="col-md-4">
                      <label htmlFor="inputPassword4">{`Select Target ${classLevel &&
                        classLevel.label}`}</label>
                      {!(this.props.targetMapping.length === 0) ||
                      !(this.props.savedTargetMapping.length === 0) ? (
                        <CreatableSelect
                          value={x.targetValue}
                          options={this.state.targetInputList || []}
                          onChange={val => this.onSelectTargetMapping(val, i)}
                        />
                      ) : (
                        <input
                          controls="false"
                          type="text"
                          onChange={e =>
                            this.onSelectTargetMappingInputTag(e, i)
                          }
                          className="form-control"
                          value={x.targetValue}
                        />
                      )}
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="inputPassword4">{`Select Source ${classLevel &&
                        classLevel.label}`}</label>
                      <Select
                        value={x.sourceValue}
                        options={this.state.sourceInputList || []}
                        onChange={val => this.onSelectSourceMapping(val, i)}
                      />
                    </div>
                    <div className="col-md-4 mt-4" style={{ display: "flex" }}>
                      {this.state.inputList.length !== 1 && (
                        <div
                          style={{
                            width: "2rem",
                            height: "2rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #000",
                            borderRadius: "50%",
                            fontSize: "18px",
                            cursor: "pointer"
                          }}
                          onClick={() => this.handleRemoveClick(i)}
                        >
                          X
                        </div>
                      )}
                      {this.state.inputList.length - 1 === i &&
                      (this.props.clusteringInputFields.isComp
                        ? true
                        : i + 1 < this.props.sourceMapping.length) ? (
                        <div
                          style={{
                            width: "2rem",
                            height: "2rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #000",
                            borderRadius: "50%",
                            fontSize: "24px",
                            marginLeft: "1rem",
                            cursor: "pointer"
                          }}
                          onClick={this.handleAddClick}
                        >
                          +
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              align="right"
              className="mt-4"
              style={{ marginBottom: "5rem" }}
            >
              <Button
                style={{ textTransform: "capitalize", marginTop: "1rem" }}
                onClick={this.onSendMapping}
                disabled={this.checkMappingDisable()}
              >
                Submit Mapping
              </Button>
            </div>
          </div>
        </Modal>
        <Alert
          open={this.state.isDeleteMapping}
          title="Alert"
          isResetRequired={false}
          content="Deleting mapping table will make the plan to start from Line Receipt. All the data in further screen if present will be deleted. Contine if you want to delete the mapping."
          handleAccept={this.handleAccept}
          actionButtonText="Yes"
          cancelButtonText="No"
          handleClose={() => {
            this.setState({
              isDeleteMapping: false
            });
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    lyFlag: store.projectReducer.lyFlag,
    projectMainStage: store.projectReducer.projectMainStage,
    projectSubStage: store.projectReducer.projectSubStage,
    sourceMapping: store.assortmentPlanningReducer.sourceMapping,
    targetMapping: store.clusteringReducer.clusteringInputFields.isComp
      ? store.assortmentPlanningReducer.sourceMapping
      : store.assortmentPlanningReducer.targetMapping,
    clusteringInputFields: store.clusteringReducer.clusteringInputFields,
    savedSourceMapping: store.assortmentPlanningReducer.savedSourceMapping,
    savedTargetMapping: store.assortmentPlanningReducer.savedTargetMapping,
    nonCompLevel: store.assortmentPlanningReducer.nonCompLevel,
    venGroupOptions: store.assortmentPlanningReducer.venGroupOptions,
    selectedFilters: store.projectReducer.projectDetails.selected_filters,
    selectedFiltersTarget:
      store.projectReducer.projectDetails.selected_filters_target,
    lineReceiptlevel: store.assortmentPlanningReducer.lineReceiptlevel,
    lineReceiptHighlevel: store.assortmentPlanningReducer.lineReceiptHighlevel,
    deleteMapping: store.assortmentPlanningReducer.deleteMapping
  };
};
const mapActionsToProps = {
  retriveLineReceiptPlanData:
    AssortmentPlanningActions.retriveLineReceiptPlanData,
  setLineReceiptOnCellValueChanged:
    AssortmentPlanningActions.setLineReceiptOnCellValueChanged,
  setUELineReceiptOnCellValueChanged:
    AssortmentPlanningActions.setUELineReceiptOnCellValueChanged,
  setLineReceiptLevel: AssortmentPlanningActions.setLineReceiptLevel,
  setLineReceiptHighLevel: AssortmentPlanningActions.setLineReceiptHighLevel,
  setLineReceiptVenGroupLevel:
    AssortmentPlanningActions.setLineReceiptVenGroupLevel,
  setLineReceiptGrandTotal: AssortmentPlanningActions.setLineReceiptGrandTotal,
  getMappingForLineReceipt: AssortmentPlanningActions.getMappingForLineReceipt,
  deleteMappingForLineReceipt:
    AssortmentPlanningActions.deleteMappingForLineReceipt,
  saveMappingForLineReceipt:
    AssortmentPlanningActions.saveMappingForLineReceipt,
  getVenGroupOptions: AssortmentPlanningActions.getVenGroupOptions
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(ReceiptPlanner));

import React from "react";
import Validator from "validator";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./admin.css";
import Select from "../../services/selectAll";
import userActions from "../../actions/userActions";
import DataTable from "../../services/dataTable";
import { Button } from "@material-ui/core";
import Modal from "../../services/modal";

class UserManagementDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: "",
        name: "",
        password: "",
        type: null
      },
      role_options: [
        {
          label: "Admin",
          value: "admin"
        }
      ],
      userlist: [],
      columnDefs: [
        { headerName: "Name", field: "name", filter: "agTextColumnFilter" },
        { headerName: "E-Mail", field: "email", filter: "agTextColumnFilter" }
        // { headerName: "Role", field: "type", filter: "agTextColumnFilter" }
      ],
      errors: {},
      createUserModal: false
    };
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    this.props.getusers().then(resp => {
      resp &&
        this.setState({
          ...this.state,
          userlist: this.props.userlist
        });
    });
  }

  onChange(e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      }
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let payload = { ...this.state.data, type: "admin" };
      this.props
        .createUserByAdmin(payload)
        .then(res => {
          this.setState({
            ...this.state,
            data: {
              email: "",
              name: "",
              password: "",
              type: null
            },
            userlist: this.props.userlist,
            createUserModal: false
          });
        })
        .catch(err => {
          console.log("Failed", err);
        });
    }
  }

  validate = data => {
    const errors = {};
    if (!Validator.isEmail(data.email)) errors.email = "Invalid email";
    if (!data.name) errors.name = "Name cannot be blank";
    // if (!data.type) errors.type = "Please select a user role";
    if (!data.password) errors.password = "Password cannot be blank";
    return errors;
  };

  render() {
    let { data, role_options, errors, columnDefs, userlist } = this.state;
    return (
      <div className="card">
        <div className="cardHeaderStep4 dashboardLabelAndButton">
          <label style={{ paddingTop: "0.95rem" }}>Users</label>
          <div className="actionSectionRightAligned">
            <Button
              style={{ width: "10rem" }}
              size="medium"
              onClick={() => {
                this.setState({ createUserModal: true });
              }}
            >
              Create User
            </Button>
          </div>
        </div>
        <Modal
          open={this.state.createUserModal}
          onClose={() => {
            this.setState({ createUserModal: false });
          }}
          fullWidth={true}
          maxWidth="lg"
          title="Create User"
        >
          <div className="admin-body">
            <form
              className="formWidth"
              style={{ margin: "auto", width: "60%" }}
              onSubmit={this.handleSubmit}
            >
              <div className="form-group">
                <p className="emailAddress">NAME</p>
                <input
                  id="name"
                  name="name"
                  className="form-control inputBorder"
                  placeholder="Name"
                  type="text"
                  value={data.name}
                  onChange={this.onChange}
                />
                {errors.name && (
                  <div className="input-feedback">{errors.name}</div>
                )}
              </div>

              <div className="form-group">
                <p className="emailAddress">EMAIL ADDRESS</p>
                <input
                  id="email"
                  name="email"
                  className="form-control inputBorder"
                  placeholder="Email"
                  type="text"
                  value={data.email}
                  onChange={this.onChange}
                />
                {errors.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
              </div>

              <div className="form-group">
                <p className="emailPassword">PASSWORD</p>
                <input
                  id="password"
                  name="password"
                  className="form-control inputBorder"
                  placeholder="Password"
                  type="password"
                  value={data.password}
                  onChange={this.onChange}
                />
                {errors.password && (
                  <div className="input-feedback">{errors.password}</div>
                )}
              </div>

              {/* <div className="form-group">
                <p className="emailAddress">ROLE</p>
                <Select
                  placeholder="Select Role"
                  name="type"
                  value={data.type}
                  options={role_options}
                  isClearable
                  onChange={val =>
                    this.setState({
                      ...this.state,
                      data: {
                        ...this.state.data,
                        type: val
                      }
                    })
                  }
                />
                {errors.type && (
                  <div className="input-feedback">{errors.type}</div>
                )}
              </div> */}
              <div id="btndiv2">
                <button
                  type="submit"
                  className="btn btn-primary btn-square shadow-sm btnsignin"
                >
                  CREATE
                </button>
              </div>
            </form>
          </div>
        </Modal>

        <div className="card-body">
          <div
            style={{
              height: "calc(100vh - 200px)"
            }}
          >
            <DataTable
              sizeToFit={true}
              columnDefs={columnDefs}
              rowData={userlist}
              floatingFilter={true}
            ></DataTable>
          </div>
        </div>
      </div>
    );
  }
}

const mapActionsToProps = {
  createUserByAdmin: userActions.createUserByAdmin,
  getusers: userActions.getusers
};
const mapStateToProps = store => {
  return {
    userlist: store.userReducer.allusers
  };
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(UserManagementDashboard));
